import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { TerminalsService } from '../../../terminals/terminals.service';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { ClimateEquipmentModel } from '../../../farming/climate/climate.model';
import { IrrigationEquipmentModel } from '../../../farming/irrigation/irrigation.model';
import { ConfigurableEquipmentModel } from 'src/app/farming/configurable/configurable.model';
import { SemanticVersioningService } from '../../../services/libraries/semantic-versioning.service';
import { DataloggerEquipmentModel } from '../../../farming/datalogger/datalogger.model';
import { DataloggerLibService } from '../../../farming/libraries/datalogger-lib.service';

@Component({
  selector: 'app-equipment-data',
  templateUrl: './equipment-data.component.html',
  styleUrls: ['./equipment-data.component.scss'],
})
export class EquipmentDataComponent implements OnInit {
  @Input() type: 'climate' | 'irrigation' | 'configurable' | 'datalogger';
  @Input() terminal_vid: string;
  @Input() equipmentResponse:
    | IrrigationEquipmentModel
    | ClimateEquipmentModel
    | ConfigurableEquipmentModel
    | DataloggerEquipmentModel;
  @Input() showStateLink = true;

  public hasPermissions;
  baseURL = environment.backend;
  cardClass: any;
  showNotificationsTable = false;

  constructor(
    private terminalService: TerminalsService,
    public translationsLib: TranslationsLibService,
    private semanticVersioningService: SemanticVersioningService,
    private dataloggerLib: DataloggerLibService

  ) {
    this.hasPermissions = false;
  }

  getClass() {
    return {
      'irrigation-card-border': this.type === 'irrigation',
      'climate-card-border': this.type === 'climate',
      'configurable-card-border': this.type === 'configurable',
      'datalogger-card-border': this.type === 'datalogger',
    };
  }

  ngOnInit() {
    this.cardClass = this.getClass();

    this.terminalService.getTerminal(this.terminal_vid).subscribe((res) => {
      this.hasPermissions = res.authed_user_can_admin;
    });
  }

  getSemanticVersioning(versionToFormat): string {
    return this.semanticVersioningService.getSemanticVersioning(
      versionToFormat
    );
  }

  toggleNotificationsTable() {
    this.showNotificationsTable = !this.showNotificationsTable;
  }

  signalPercent(signal) {
    return this.dataloggerLib.signalPercent(signal);
  }

  dataloggerEquipment(equipment: IrrigationEquipmentModel | ClimateEquipmentModel | ConfigurableEquipmentModel | DataloggerEquipmentModel): DataloggerEquipmentModel {
    return equipment as DataloggerEquipmentModel;
  }
}
