<app-dirty-message
  [conditions]="layoutConfiguration && !isFetchingData"
  [dirty]="mixturesForm.value.dirty || isAnyMixtureFormulaDirtyAfterPost"
></app-dirty-message>

<ng-template
  [ngIf]="
    mode === 'simple' &&
    layoutConfiguration &&
    !isFetchingData &&
    !isMixtureFormulasWithoutData
  "
>
  <form [formGroup]="mixturesForm" class="simple-form">
    <div class="mb-2">
      <div class="card">
        <div class="card-body custom-card-body">
          <div class="row">
            <div class="col-sm-5 col-md-5">
              <h6 class="fs-2">
                {{ translationsLib.get('irrigation_mixtures_formula') }}
              </h6>
              <p class="fs-5 fw-lighter">
                {{
                  translationsLib.get('irrigation_mixtures_formula_help')
                    | sanitizeText
                }}
              </p>
            </div>
            <div class="col-sm-7 col-md-7 col-inputs">
              <div class="row first-row">
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <h6 class="fs-5">
                    {{
                      translationsLib.get(
                        'irrigation_mixture_formula_ce1_ideal'
                      )
                    }}
                  </h6>
                  <div class="input-group mb-2 number-input">
                    <button
                      class="minus minus-style"
                      (click)="inputNumberService.decrementValue($event)"
                    ></button>
                    <input
                      formControlName="idealEC1"
                      type="number"
                      class="form-control"
                      placeholder="{{
                        translationsLib.get(
                          'irrigation_mixture_formula_ce1_ideal'
                        )
                      }}"
                      min="-9999"
                      max="9999"
                    />
                    <button
                      class="plus border-plus plus-style"
                      (click)="inputNumberService.incrementValue($event)"
                    ></button>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <h6 class="fs-5">
                    {{
                      translationsLib.get('irrigation_mixture_formula_ce1_max')
                    }}
                  </h6>
                  <div class="input-group mb-2 number-input">
                    <button
                      class="minus minus-style"
                      (click)="inputNumberService.decrementValue($event)"
                    ></button>
                    <input
                      formControlName="maxEC1"
                      type="number"
                      class="form-control"
                      placeholder="{{
                        translationsLib.get(
                          'irrigation_mixture_formula_ce1_max'
                        )
                      }}"
                      min="-9999"
                      max="9999"
                    />
                    <button
                      class="plus border-plus plus-style"
                      (click)="inputNumberService.incrementValue($event)"
                    ></button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <h6 class="fs-5">
                    {{
                      translationsLib.get(
                        'irrigation_mixture_formula_ce1_tolerance'
                      )
                    }}
                  </h6>
                  <div class="input-group mb-2 number-input">
                    <button
                      class="minus minus-style"
                      (click)="inputNumberService.decrementValue($event)"
                    ></button>
                    <input
                      formControlName="toleranceEC1"
                      type="number"
                      step="0.01"
                      class="form-control"
                      placeholder="{{
                        translationsLib.get(
                          'irrigation_mixture_formula_ce1_tolerance'
                        )
                      }}"
                      min="-9999"
                      max="9999"
                    />
                    <button
                      class="plus border-plus plus-style"
                      (click)="inputNumberService.incrementValue($event)"
                    ></button>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <h6 class="fs-5">
                    {{
                      translationsLib.get(
                        'irrigation_mixture_formula_increment_ce1'
                      )
                    }}
                  </h6>
                  <div class="input-group mb-2 number-input">
                    <button
                      class="minus minus-style"
                      (click)="inputNumberService.decrementValue($event)"
                    ></button>
                    <input
                      formControlName="incrementEC1"
                      type="number"
                      step="0.1"
                      class="form-control"
                      placeholder="{{
                        translationsLib.get(
                          'irrigation_mixture_formula_increment_ce1'
                        )
                      }}"
                      min="-9999"
                      max="9999"
                    />
                    <button
                      class="plus border-plus plus-style"
                      (click)="inputNumberService.incrementValue($event)"
                    ></button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <h6 class="fs-5">
                    {{
                      translationsLib.get(
                        'irrigation_mixture_formula_ce2_ideal'
                      )
                    }}
                  </h6>
                  <div class="input-group mb-2 number-input">
                    <button
                      class="minus minus-style"
                      (click)="inputNumberService.decrementValue($event)"
                    ></button>
                    <input
                      formControlName="idealEC2"
                      type="number"
                      class="form-control"
                      placeholder="{{
                        translationsLib.get(
                          'irrigation_mixture_formula_ce2_ideal'
                        )
                      }}"
                      min="-9999"
                      max="9999"
                    />
                    <button
                      class="plus border-plus plus-style"
                      (click)="inputNumberService.incrementValue($event)"
                    ></button>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <h6 class="fs-5">
                    {{
                      translationsLib.get('irrigation_mixture_formula_ce2_max')
                    }}
                  </h6>
                  <div class="input-group mb-2 number-input">
                    <button
                      class="minus minus-style"
                      (click)="inputNumberService.decrementValue($event)"
                    ></button>
                    <input
                      formControlName="maxEC2"
                      type="number"
                      class="form-control"
                      placeholder="{{
                        translationsLib.get(
                          'irrigation_mixture_formula_ce2_max'
                        )
                      }}"
                      min="-9999"
                      max="9999"
                    />
                    <button
                      class="plus border-plus plus-style"
                      (click)="inputNumberService.incrementValue($event)"
                    ></button>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <h6 class="fs-5">
                    {{
                      translationsLib.get(
                        'irrigation_mixture_formula_ce2_tolerance'
                      )
                    }}
                  </h6>
                  <div class="input-group mb-2 number-input">
                    <button
                      class="minus minus-style"
                      (click)="inputNumberService.decrementValue($event)"
                    ></button>
                    <input
                      formControlName="toleranceEC2"
                      type="number"
                      step="0.01"
                      class="form-control"
                      placeholder="{{
                        translationsLib.get(
                          'irrigation_mixture_formula_ce2_tolerance'
                        )
                      }}"
                      min="-9999"
                      max="9999"
                    />
                    <button
                      class="plus border-plus plus-style"
                      (click)="inputNumberService.incrementValue($event)"
                    ></button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <h6 class="fs-5">
                    {{
                      translationsLib.get('irrigation_mixture_formula_source_1')
                    }}
                  </h6>
                  <div class="input-group mb-2">
                    <select class="form-select" formControlName="source1">
                      <option [value]="255">
                        {{
                          translationsLib.get(
                            'irrigation_mixture_formula_no_source'
                          )
                        }}
                      </option>
                      <option
                        [value]="output.subtype"
                        *ngFor="let output of outputsList"
                      >
                        {{
                          this.translationsLib.get(
                            'irrigation_mixture_formula_source'
                          ) +
                            ' ' +
                            (output.subtype + 1)
                        }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <h6 class="fs-5">
                    {{
                      translationsLib.get('irrigation_mixture_formula_source_2')
                    }}
                  </h6>
                  <div class="input-group mb-2">
                    <select class="form-select" formControlName="source2">
                      <option [value]="255">
                        {{
                          translationsLib.get(
                            'irrigation_mixture_formula_no_source'
                          )
                        }}
                      </option>
                      <option
                        [value]="output.subtype"
                        *ngFor="let output of outputsList"
                      >
                        {{
                          this.translationsLib.get(
                            'irrigation_mixture_formula_source'
                          ) +
                            ' ' +
                            (output.subtype + 1)
                        }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <h6 class="fs-5">
                    {{
                      translationsLib.get(
                        'irrigation_mixture_formula_source_drainage'
                      )
                    }}
                  </h6>
                  <div class="input-group mb-2">
                    <select
                      class="form-select"
                      formControlName="drainageSource"
                    >
                      <option [value]="255">
                        {{
                          translationsLib.get(
                            'irrigation_mixture_formula_no_source'
                          )
                        }}
                      </option>
                      <option
                        [value]="output.subtype"
                        *ngFor="let output of outputsList"
                      >
                        {{
                          this.translationsLib.get(
                            'irrigation_mixture_formula_source'
                          ) +
                            ' ' +
                            (output.subtype + 1)
                        }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <h6 class="fs-5">
                    {{
                      translationsLib.get(
                        'irrigation_mixture_formula_drainage_sensor'
                      )
                    }}
                  </h6>
                  <div class="input-group mb-2">
                    <select
                      class="form-select"
                      formControlName="drainageSensor"
                    >
                      <option value="null">-</option>
                      <ng-container *ngFor="let sensor of sensorsList">
                        <ng-container
                          *ngIf="
                            sensor.type === 0 ||
                            sensor.type === 7 ||
                            sensor.type === 16
                          "
                        >
                          <option [value]="sensor.id">{{ sensor.name }}</option>
                        </ng-container>
                      </ng-container>
                    </select>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <h6 class="fs-5">
                    {{
                      translationsLib.get(
                        'irrigation_mixture_formula_drainage_ce_max'
                      )
                    }}
                  </h6>
                  <div class="input-group mb-2 number-input">
                    <button
                      class="minus minus-style"
                      (click)="inputNumberService.decrementValue($event)"
                    ></button>
                    <input
                      formControlName="maxDrainEC"
                      type="number"
                      class="form-control"
                      placeholder="{{
                        translationsLib.get(
                          'irrigation_mixture_formula_drainage_ce_max'
                        )
                      }}"
                      min="-9999"
                      max="9999"
                    />
                    <button
                      class="plus border-plus plus-style"
                      (click)="inputNumberService.incrementValue($event)"
                    ></button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <h6 class="fs-5">
                    {{ translationsLib.get('irrigation_valve') }} 1
                  </h6>
                  <div class="input-group mb-2">
                    <select class="form-select" formControlName="valve1">
                      <option [value]="255">
                        {{ translationsLib.get('irrigation_no_valve') }}
                      </option>
                      <option
                        [value]="valve.valve"
                        *ngFor="let valve of valvesList"
                      >
                        {{
                          valve.name
                            ? valve.name
                            : translationsLib.get('irrigation_valve') +
                              ' ' +
                              (valve.valve + 1)
                        }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <h6 class="fs-5">
                    {{ translationsLib.get('irrigation_valve') }} 2
                  </h6>
                  <div class="input-group mb-2">
                    <select class="form-select" formControlName="valve2">
                      <option [value]="255">
                        {{ translationsLib.get('irrigation_no_valve') }}
                      </option>
                      <option
                        [value]="valve.valve"
                        *ngFor="let valve of valvesList"
                      >
                        {{
                          valve.name
                            ? valve.name
                            : translationsLib.get('irrigation_valve') +
                              ' ' +
                              (valve.valve + 1)
                        }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <h6 class="fs-5">
                    {{
                      translationsLib.get(
                        'irrigation_mixture_formula_delay_alarm'
                      )
                    }}
                  </h6>
                  <div class="mb-2">
                    <ng-template #ECAlarmDelaySecondsPicker>
                      <timepicker
                        appOnlyNumbers
                        [showMeridian]="false"
                        [showSeconds]="true"
                        [minuteStep]="1"
                        [secondsStep]="1"
                        [formControlName]="'ECAlarmDelaySeconds'"
                      ></timepicker>
                    </ng-template>
                    <input
                      appOnlyNumbers
                      [ngClass]="{
                        'ng-dirty':
                          mixturesForm.controls.ECAlarmDelaySeconds.dirty
                      }"
                      class="form-control text-center"
                      type="text"
                      [popover]="ECAlarmDelaySecondsPicker"
                      [outsideClick]="true"
                      placement="bottom"
                      [value]="
                        mixturesForm.value.ECAlarmDelaySeconds | inputTime
                      "
                      (change)="
                        onChangeInputTimeWithKeyboard(
                          $event,
                          'ECAlarmDelaySeconds'
                        )
                      "
                    />
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <h6 class="fs-5">
                    {{
                      translationsLib.get(
                        'irrigation_mixture_formula_alarm_stop'
                      )
                    }}
                  </h6>
                  <div class="input-group my-3">
                    <div class="form-check form-switch">
                      <input
                        formControlName="alarmStop"
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>

<ng-template
  [ngIf]="
    mode === 'advanced' &&
    layoutConfiguration &&
    !isFetchingData &&
    !isMixtureFormulasWithoutData
  "
>
  <form [formGroup]="mixturesForm" class="advanced-form">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="card-body custom-card-body">
            <div class="row">
              <div class="col-md-12">
                <h6 class="fs-2">
                  {{ translationsLib.get('irrigation_mixtures_formula') }}
                </h6>
              </div>
            </div>
            <div class="row">
              <div class="col-6 col-md-3">
                <h6 class="fs-5">
                  {{
                    translationsLib.get('irrigation_mixture_formula_ce1_ideal')
                  }}
                </h6>
              </div>
              <div class="col-6 col-md-3">
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="idealEC1"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get(
                        'irrigation_mixture_formula_ce1_ideal'
                      )
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
              <div class="col-6 col-md-3">
                <h6 class="fs-5">
                  {{
                    translationsLib.get('irrigation_mixture_formula_ce1_max')
                  }}
                </h6>
              </div>
              <div class="col-6 col-md-3">
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="maxEC1"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_mixture_formula_ce1_max')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
              <div class="col-6 col-md-3">
                <h6 class="fs-5">
                  {{
                    translationsLib.get(
                      'irrigation_mixture_formula_ce1_tolerance'
                    )
                  }}
                </h6>
              </div>
              <div class="col-6 col-md-3">
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="toleranceEC1"
                    type="number"
                    step="0.01"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get(
                        'irrigation_mixture_formula_ce1_tolerance'
                      )
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
              <div class="col-6 col-md-3">
                <h6 class="fs-5">
                  {{
                    translationsLib.get(
                      'irrigation_mixture_formula_increment_ce1'
                    )
                  }}
                </h6>
              </div>
              <div class="col-6 col-md-3">
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="incrementEC1"
                    type="number"
                    step="0.1"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get(
                        'irrigation_mixture_formula_increment_ce1'
                      )
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6 col-md-3">
                <h6 class="fs-5">
                  {{
                    translationsLib.get('irrigation_mixture_formula_ce2_ideal')
                  }}
                </h6>
              </div>
              <div class="col-6 col-md-3">
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="idealEC2"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get(
                        'irrigation_mixture_formula_ce2_ideal'
                      )
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
              <div class="col-6 col-md-3">
                <h6 class="fs-5">
                  {{
                    translationsLib.get('irrigation_mixture_formula_ce2_max')
                  }}
                </h6>
              </div>
              <div class="col-6 col-md-3">
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="maxEC2"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_mixture_formula_ce2_max')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
              <div class="col-6 col-md-3">
                <h6 class="fs-5">
                  {{
                    translationsLib.get(
                      'irrigation_mixture_formula_ce2_tolerance'
                    )
                  }}
                </h6>
              </div>
              <div class="col-6 col-md-3">
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="toleranceEC2"
                    type="number"
                    step="0.01"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get(
                        'irrigation_mixture_formula_ce2_tolerance'
                      )
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-sm-6 col-md-3">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get(
                          'irrigation_mixture_formula_source_1'
                        )
                      }}
                    </h6>
                  </div>
                  <div class="col-sm-6 col-md-3">
                    <div class="input-group mb-2">
                      <select class="form-select" formControlName="source1">
                        <option [value]="255">
                          {{
                            translationsLib.get(
                              'irrigation_mixture_formula_no_source'
                            )
                          }}
                        </option>
                        <option
                          [value]="output.subtype"
                          *ngFor="let output of outputsList"
                        >
                          {{
                            this.translationsLib.get(
                              'irrigation_mixture_formula_source'
                            ) +
                              ' ' +
                              (output.subtype + 1)
                          }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-3">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get(
                          'irrigation_mixture_formula_source_2'
                        )
                      }}
                    </h6>
                  </div>
                  <div class="col-sm-6 col-md-3">
                    <div class="input-group mb-2">
                      <select class="form-select" formControlName="source2">
                        <option [value]="255">
                          {{
                            translationsLib.get(
                              'irrigation_mixture_formula_no_source'
                            )
                          }}
                        </option>
                        <option
                          [value]="output.subtype"
                          *ngFor="let output of outputsList"
                        >
                          {{
                            this.translationsLib.get(
                              'irrigation_mixture_formula_source'
                            ) +
                              ' ' +
                              (output.subtype + 1)
                          }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-sm-6 col-md-3">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get(
                          'irrigation_mixture_formula_source_drainage'
                        )
                      }}
                    </h6>
                  </div>
                  <div class="col-sm-6 col-md-3">
                    <div class="input-group mb-2">
                      <select
                        class="form-select"
                        formControlName="drainageSource"
                      >
                        <option [value]="255">
                          {{
                            translationsLib.get(
                              'irrigation_mixture_formula_no_source'
                            )
                          }}
                        </option>
                        <option
                          [value]="output.subtype"
                          *ngFor="let output of outputsList"
                        >
                          {{
                            this.translationsLib.get(
                              'irrigation_mixture_formula_source'
                            ) +
                              ' ' +
                              (output.subtype + 1)
                          }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-3">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get(
                          'irrigation_mixture_formula_drainage_sensor'
                        )
                      }}
                    </h6>
                  </div>
                  <div class="col-sm-6 col-md-3">
                    <div class="input-group mb-2">
                      <select
                        class="form-select"
                        formControlName="drainageSensor"
                      >
                        <option value="null">-</option>
                        <ng-container *ngFor="let sensor of sensorsList">
                          <ng-container
                            *ngIf="
                              sensor.type === 0 ||
                              sensor.type === 7 ||
                              sensor.type === 16
                            "
                          >
                            <option [value]="sensor.id">
                              {{ sensor.name }}
                            </option>
                          </ng-container>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-3">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get(
                          'irrigation_mixture_formula_drainage_ce_max'
                        )
                      }}
                    </h6>
                  </div>
                  <div class="col-sm-6 col-md-3">
                    <div class="input-group mb-2 number-input">
                      <button
                        class="minus minus-style"
                        (click)="inputNumberService.decrementValue($event)"
                      ></button>
                      <input
                        formControlName="maxDrainEC"
                        type="number"
                        class="form-control"
                        placeholder="{{
                          translationsLib.get(
                            'irrigation_mixture_formula_drainage_ce_max'
                          )
                        }}"
                        min="-9999"
                        max="9999"
                      />
                      <button
                        class="plus border-plus plus-style"
                        (click)="inputNumberService.incrementValue($event)"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-sm-6 col-md-3">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_valve') }} 1
                    </h6>
                  </div>
                  <div class="col-sm-6 col-md-3">
                    <div class="input-group mb-2">
                      <select class="form-select" formControlName="valve1">
                        <option [value]="255">
                          {{ translationsLib.get('irrigation_no_valve') }}
                        </option>
                        <option
                          [value]="valve.valve"
                          *ngFor="let valve of valvesList"
                        >
                          {{
                            valve.name
                              ? valve.name
                              : translationsLib.get('irrigation_valve') +
                                ' ' +
                                (valve.valve + 1)
                          }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-3">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_valve') }} 2
                    </h6>
                  </div>
                  <div class="col-sm-6 col-md-3">
                    <div class="input-group mb-2">
                      <select class="form-select" formControlName="valve2">
                        <option [value]="255">
                          {{ translationsLib.get('irrigation_no_valve') }}
                        </option>
                        <option
                          [value]="valve.valve"
                          *ngFor="let valve of valvesList"
                        >
                          {{
                            valve.name
                              ? valve.name
                              : translationsLib.get('irrigation_valve') +
                                ' ' +
                                (valve.valve + 1)
                          }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-sm-6 col-md-3">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get(
                          'irrigation_mixture_formula_delay_alarm'
                        )
                      }}
                    </h6>
                  </div>
                  <div class="col-sm-6 col-md-3">
                    <div class="mb-2">
                      <ng-template #ECAlarmDelaySecondsPicker>
                        <timepicker
                          appOnlyNumbers
                          [showMeridian]="false"
                          [showSeconds]="true"
                          [minuteStep]="1"
                          [secondsStep]="1"
                          [formControlName]="'ECAlarmDelaySeconds'"
                        ></timepicker>
                      </ng-template>
                      <input
                        appOnlyNumbers
                        [ngClass]="{
                          'ng-dirty':
                            mixturesForm.controls.ECAlarmDelaySeconds.dirty
                        }"
                        class="form-control text-center"
                        type="text"
                        [popover]="ECAlarmDelaySecondsPicker"
                        [outsideClick]="true"
                        placement="bottom"
                        [value]="
                          mixturesForm.value.ECAlarmDelaySeconds | inputTime
                        "
                        (change)="
                          onChangeInputTimeWithKeyboard(
                            $event,
                            'ECAlarmDelaySeconds'
                          )
                        "
                      />
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-3">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get(
                          'irrigation_mixture_formula_alarm_stop'
                        )
                      }}
                    </h6>
                  </div>
                  <div class="col-sm-6 col-md-3">
                    <div class="input-group my-3">
                      <div class="form-check form-switch">
                        <input
                          formControlName="alarmStop"
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
