import { Component, Input, OnInit } from '@angular/core';
import { TerminalDataModel } from '@nutricontrol/app360-shared';

@Component({
  selector: 'app-main-dashboard-terminals-no-meteo-pills',
  templateUrl: './main-dashboard-terminals-no-meteo-pills.component.html',
  styleUrls: ['./main-dashboard-terminals-no-meteo-pills.component.scss'],
})
export class MainDashboardTerminalsNoMeteoPillsComponent implements OnInit {
  @Input() terminals: TerminalDataModel[] = [];
  constructor() {}
  ngOnInit() {}
}
