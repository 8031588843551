import { Component, OnInit } from '@angular/core';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';
import { LegalAgreementsLibService } from '../../services/libraries/legal-agreements-lib.service';

@Component({
  selector: 'app-info-screen',
  templateUrl: './info-screen.component.html',
  styleUrls: ['./info-screen.component.scss'],
})
export class InfoScreenComponent implements OnInit {
  timeStampVersion = '';
  environmentVersion = '';
  breadcrumbs: { active: boolean; disabled: boolean; text: any; to: string }[];
  constructor(
    private helpersLib: HelpersLibService,
    public authService: AuthService,
    public legalAgreementsLib: LegalAgreementsLibService,
    public translationsLib: TranslationsLibService
  ) {}

  ngOnInit() {
    this.authService.getLegalAgreements();
    this.helpersLib.sendEventPageView('Info');
    this.helpersLib.setTitle('', 'Info', null);

    this.breadcrumbs = [
      {
        text: 'Info',
        disabled: false,
        to: ' ',
        active: true,
      },
    ];

    if (environment.production === true) {
      this.environmentVersion = 'prod';
    } else {
      this.environmentVersion = 'dev';
    }

    if (environment.timeStamp === '') {
      this.timeStampVersion = 'development stage';
    } else {
      this.timeStampVersion = environment.timeStamp;
    }
  }
}
