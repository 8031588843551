<div class="mb-4 d-flex justify-content-center mt-5" *ngIf="isFetchingData">
  <div class="spinner-border text-warning" role="status">
    <span class="visually-hidden">{{
      this.translationsLib.get('irrigation_loading')
    }}</span>
  </div>
</div>

<app-dirty-message
  [conditions]="!isFetchingData"
  [dirty]="meteoAlarmsForm.value.dirty"
></app-dirty-message>

<ng-container
  *ngIf="mode === 'simple' && !isFetchingData && !isMeteoAlarmsWithoutData"
>
  <form [formGroup]="meteoAlarmsForm" class="simple-form">
    <!-- Alarma de lluvia -->
    <div class="card">
      <div class="card-body custom-card-body">
        <div class="row align-items-start">
          <div class="col-sm-5 col-md-5 order-1 order-md-1">
            <h6 class="fs-2">
              {{ translationsLib.get('irrigation_alarm_rain') }}
            </h6>
            <p class="fs-5 fw-lighter">
              {{
                translationsLib.get('irrigation_alarm_rain_help') | sanitizeText
              }}
            </p>
          </div>
          <div class="col-sm-7 col-md-7 order-3 order-md-2 col-inputs">
            <div
              class="row first-row align-items-center justify-content-between"
            >
              <div class="col-12 col-md-4">
                <h6 class="fs-5">
                  {{
                    translationsLib.get('irrigation_alarm_confirmation_time')
                  }}
                </h6>
                <div class="mb-2">
                  <ng-template #rainDelayTimePicker>
                    <timepicker
                      appOnlyNumbers
                      [showMeridian]="false"
                      [showSeconds]="true"
                      [minuteStep]="1"
                      [secondsStep]="1"
                      [formControlName]="'rainDelayTime'"
                    ></timepicker>
                  </ng-template>
                  <input
                    appOnlyNumbers
                    [ngClass]="{
                      'ng-dirty': meteoAlarmsForm.controls.rainDelayTime.dirty
                    }"
                    class="form-control text-center"
                    type="text"
                    [popover]="rainDelayTimePicker"
                    [outsideClick]="true"
                    placement="bottom"
                    [value]="meteoAlarmsForm.value.rainDelayTime | inputTime"
                    (change)="
                      onChangeInputTimeWithKeyboard($event, 'rainDelayTime')
                    "
                  />
                </div>
              </div>
              <div class="col-12 col-md-4">
                <h6 class="fs-5">
                  {{
                    translationsLib.get('irrigation_alarm_no_confirmation_time')
                  }}
                </h6>
                <div class="mb-2">
                  <ng-template #rainNoAlarmConfirmationTimePicker>
                    <timepicker
                      appOnlyNumbers
                      [showMeridian]="false"
                      [showSeconds]="true"
                      [minuteStep]="1"
                      [secondsStep]="1"
                      [formControlName]="'rainNoAlarmConfirmationTime'"
                    ></timepicker>
                  </ng-template>
                  <input
                    appOnlyNumbers
                    [ngClass]="{
                      'ng-dirty':
                        meteoAlarmsForm.controls.rainNoAlarmConfirmationTime
                          .dirty
                    }"
                    class="form-control text-center"
                    type="text"
                    [popover]="rainNoAlarmConfirmationTimePicker"
                    [outsideClick]="true"
                    placement="bottom"
                    [value]="
                      meteoAlarmsForm.value.rainNoAlarmConfirmationTime
                        | inputTime
                    "
                    (change)="
                      onChangeInputTimeWithKeyboard(
                        $event,
                        'rainNoAlarmConfirmationTime'
                      )
                    "
                  />
                </div>
              </div>
              <div class="col-12 col-md-4">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_alarm_pluviometer_flow') }}
                </h6>
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="rainPluviometerFlow"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_alarm_pluviometer_flow')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Alarma de tormenta -->
    <div class="card">
      <div class="card-body custom-card-body">
        <div class="row align-items-start">
          <div class="col-sm-5 col-md-5 order-1 order-md-1">
            <h6 class="fs-2">
              {{ translationsLib.get('irrigation_alarm_storm') }}
            </h6>
            <p class="fs-5 fw-lighter">
              {{
                translationsLib.get('irrigation_alarm_storm_help')
                  | sanitizeText
              }}
            </p>
          </div>
          <div class="col-sm-7 col-md-7 order-3 order-md-2 col-inputs">
            <div
              class="row first-row align-items-center justify-content-between"
            >
              <div class="col-12 col-md-4">
                <h6 class="fs-5">
                  {{
                    translationsLib.get('irrigation_alarm_confirmation_time')
                  }}
                </h6>
                <div class="input-group mb-2">
                  <ng-template #stormDelayTimePicker>
                    <timepicker
                      appOnlyNumbers
                      [showMeridian]="false"
                      [showSeconds]="true"
                      [minuteStep]="1"
                      [secondsStep]="1"
                      [formControlName]="'stormDelayTime'"
                    ></timepicker>
                  </ng-template>
                  <input
                    appOnlyNumbers
                    [ngClass]="{
                      'ng-dirty': meteoAlarmsForm.controls.stormDelayTime.dirty
                    }"
                    class="form-control text-center"
                    type="text"
                    [popover]="stormDelayTimePicker"
                    [outsideClick]="true"
                    placement="bottom"
                    [value]="meteoAlarmsForm.value.stormDelayTime | inputTime"
                    (change)="
                      onChangeInputTimeWithKeyboard($event, 'stormDelayTime')
                    "
                  />
                </div>
              </div>
              <div class="col-12 col-md-4">
                <h6 class="fs-5">
                  {{
                    translationsLib.get('irrigation_alarm_no_confirmation_time')
                  }}
                </h6>
                <div class="mb-2">
                  <ng-template #stormNoAlarmConfirmationTimePicker>
                    <timepicker
                      appOnlyNumbers
                      [showMeridian]="false"
                      [showSeconds]="true"
                      [minuteStep]="1"
                      [secondsStep]="1"
                      [formControlName]="'stormNoAlarmConfirmationTime'"
                    ></timepicker>
                  </ng-template>
                  <input
                    appOnlyNumbers
                    [ngClass]="{
                      'ng-dirty':
                        meteoAlarmsForm.controls.stormNoAlarmConfirmationTime
                          .dirty
                    }"
                    class="form-control text-center"
                    type="text"
                    [popover]="stormNoAlarmConfirmationTimePicker"
                    [outsideClick]="true"
                    placement="bottom"
                    [value]="
                      meteoAlarmsForm.value.stormNoAlarmConfirmationTime
                        | inputTime
                    "
                    (change)="
                      onChangeInputTimeWithKeyboard(
                        $event,
                        'stormNoAlarmConfirmationTime'
                      )
                    "
                  />
                </div>
              </div>
              <div class="col-12 col-md-4">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_alarm_storm_wind_speed') }}
                </h6>
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="stormWindSpeed"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_alarm_storm_wind_speed')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Alarma de helada -->
    <div class="card">
      <div class="card-body custom-card-body">
        <div class="row align-items-start">
          <div class="col-sm-5 col-md-5 order-1 order-md-1">
            <h6 class="fs-2">
              {{ translationsLib.get('irrigation_alarm_frost') }}
            </h6>
            <p class="fs-5 fw-lighter">
              {{
                translationsLib.get('irrigation_alarm_frost_help')
                  | sanitizeText
              }}
            </p>
          </div>
          <div class="col-sm-7 col-md-7 order-3 order-md-2 col-inputs">
            <div
              class="row first-row align-items-center justify-content-between"
            >
              <div class="col-12 col-md-4">
                <h6 class="fs-5">
                  {{
                    translationsLib.get('irrigation_alarm_confirmation_time')
                  }}
                </h6>
                <div class="mb-2">
                  <ng-template #frostDelayTimePicker>
                    <timepicker
                      appOnlyNumbers
                      [showMeridian]="false"
                      [showSeconds]="true"
                      [minuteStep]="1"
                      [secondsStep]="1"
                      [formControlName]="'frostDelayTime'"
                    ></timepicker>
                  </ng-template>
                  <input
                    appOnlyNumbers
                    [ngClass]="{
                      'ng-dirty': meteoAlarmsForm.controls.frostDelayTime.dirty
                    }"
                    class="form-control text-center"
                    type="text"
                    [popover]="frostDelayTimePicker"
                    [outsideClick]="true"
                    placement="bottom"
                    [value]="meteoAlarmsForm.value.frostDelayTime | inputTime"
                    (change)="
                      onChangeInputTimeWithKeyboard($event, 'frostDelayTime')
                    "
                  />
                </div>
              </div>
              <div class="col-12 col-md-4">
                <h6 class="fs-5">
                  {{
                    translationsLib.get('irrigation_alarm_no_confirmation_time')
                  }}
                </h6>
                <div class="mb-2">
                  <ng-template #frostNoAlarmConfirmationTimePicker>
                    <timepicker
                      appOnlyNumbers
                      [showMeridian]="false"
                      [showSeconds]="true"
                      [minuteStep]="1"
                      [secondsStep]="1"
                      [formControlName]="'frostNoAlarmConfirmationTime'"
                    ></timepicker>
                  </ng-template>
                  <input
                    appOnlyNumbers
                    [ngClass]="{
                      'ng-dirty':
                        meteoAlarmsForm.controls.frostNoAlarmConfirmationTime
                          .dirty
                    }"
                    class="form-control text-center"
                    type="text"
                    [popover]="frostNoAlarmConfirmationTimePicker"
                    [outsideClick]="true"
                    placement="bottom"
                    [value]="
                      meteoAlarmsForm.value.frostNoAlarmConfirmationTime
                        | inputTime
                    "
                    (change)="
                      onChangeInputTimeWithKeyboard(
                        $event,
                        'frostNoAlarmConfirmationTime'
                      )
                    "
                  />
                </div>
              </div>
              <div class="col-12 col-md-4">
                <h6 class="fs-5">
                  {{
                    translationsLib.get('irrigation_alarm_frost_temperature')
                  }}
                </h6>
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="frostFrostTemperature"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_alarm_frost_temperature')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
            </div>
            <div class="row align-items-center justify-content-between">
              <div class="col-12 col-md-4">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_correction_start') }}
                  (W/m²)
                </h6>
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="frostStartRadiationCorrection"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_correction_start')
                    }} (W/m²)"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_correction_end') }} (W/m²)
                </h6>
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="frostEndRadiationCorrection"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_correction_end')
                    }} (W/m²)"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_alarm_max_correction') }}
                </h6>
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="frostMaxTemperatureCorrection"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_alarm_max_correction')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-container>

<ng-container
  *ngIf="mode === 'advanced' && !isFetchingData && !isMeteoAlarmsWithoutData"
>
  <form [formGroup]="meteoAlarmsForm" class="advanced-form">
    <div class="row">
      <div class="col">
        <!-- Alarma de lluvia -->
        <div class="card">
          <div class="card-body custom-card-body">
            <div class="row">
              <div class="col-12">
                <h6 class="fs-2 input-label">
                  {{ translationsLib.get('irrigation_alarm_rain') }}
                </h6>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-sm-12 col-md-4 col-lg-4">
                <div class="row justify-content-between align-items-center">
                  <div class="col-md-6">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get(
                          'irrigation_alarm_confirmation_time'
                        )
                      }}
                    </h6>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-2">
                      <ng-template #rainDelayTimePicker>
                        <timepicker
                          appOnlyNumbers
                          [showMeridian]="false"
                          [showSeconds]="true"
                          [minuteStep]="1"
                          [secondsStep]="1"
                          [formControlName]="'rainDelayTime'"
                        ></timepicker>
                      </ng-template>
                      <input
                        appOnlyNumbers
                        [ngClass]="{
                          'ng-dirty':
                            meteoAlarmsForm.controls.rainDelayTime.dirty
                        }"
                        class="form-control text-center"
                        type="text"
                        [popover]="rainDelayTimePicker"
                        [outsideClick]="true"
                        placement="bottom"
                        [value]="
                          meteoAlarmsForm.value.rainDelayTime | inputTime
                        "
                        (change)="
                          onChangeInputTimeWithKeyboard($event, 'rainDelayTime')
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-4 col-lg-4">
                <div class="row justify-content-between align-items-center">
                  <div class="col-md-6">
                    <h6 class="fs-5 input-label">
                      {{
                        translationsLib.get(
                          'irrigation_alarm_no_confirmation_time'
                        )
                      }}
                    </h6>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-2">
                      <ng-template #rainNoAlarmConfirmationTimePicker>
                        <timepicker
                          appOnlyNumbers
                          [showMeridian]="false"
                          [showSeconds]="true"
                          [minuteStep]="1"
                          [secondsStep]="1"
                          [formControlName]="'rainNoAlarmConfirmationTime'"
                        ></timepicker>
                      </ng-template>
                      <input
                        appOnlyNumbers
                        [ngClass]="{
                          'ng-dirty':
                            meteoAlarmsForm.controls.rainNoAlarmConfirmationTime
                              .dirty
                        }"
                        class="form-control text-center"
                        type="text"
                        [popover]="rainNoAlarmConfirmationTimePicker"
                        [outsideClick]="true"
                        placement="bottom"
                        [value]="
                          meteoAlarmsForm.value.rainNoAlarmConfirmationTime
                            | inputTime
                        "
                        (change)="
                          onChangeInputTimeWithKeyboard(
                            $event,
                            'rainNoAlarmConfirmationTime'
                          )
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-4 col-lg-4">
                <div class="row justify-content-between align-items-center">
                  <div class="col-md-6">
                    <h6 class="fs-5 input-label">
                      {{
                        translationsLib.get('irrigation_alarm_pluviometer_flow')
                      }}
                    </h6>
                  </div>
                  <div class="col-md-6">
                    <div class="input-group mb-2 number-input">
                      <button
                        class="minus minus-style"
                        (click)="inputNumberService.decrementValue($event)"
                      ></button>
                      <input
                        formControlName="rainPluviometerFlow"
                        type="number"
                        class="form-control"
                        placeholder="{{
                          translationsLib.get(
                            'irrigation_alarm_pluviometer_flow'
                          )
                        }}"
                        min="-9999"
                        max="9999"
                      />
                      <button
                        class="plus border-plus plus-style"
                        (click)="inputNumberService.incrementValue($event)"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Alarma de tormenta -->
        <div class="card">
          <div class="card-body custom-card-body">
            <div class="row">
              <div class="col-12">
                <h6 class="fs-2 input-label">
                  {{ translationsLib.get('irrigation_alarm_storm') }}
                </h6>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-sm-12 col-md-4 col-lg-4">
                <div class="row justify-content-between align-items-center">
                  <div class="col-md-6">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get(
                          'irrigation_alarm_confirmation_time'
                        )
                      }}
                    </h6>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-2">
                      <ng-template #stormDelayTimePicker>
                        <timepicker
                          appOnlyNumbers
                          [showMeridian]="false"
                          [showSeconds]="true"
                          [minuteStep]="1"
                          [secondsStep]="1"
                          [formControlName]="'stormDelayTime'"
                        ></timepicker>
                      </ng-template>
                      <input
                        appOnlyNumbers
                        [ngClass]="{
                          'ng-dirty':
                            meteoAlarmsForm.controls.stormDelayTime.dirty
                        }"
                        class="form-control text-center"
                        type="text"
                        [popover]="stormDelayTimePicker"
                        [outsideClick]="true"
                        placement="bottom"
                        [value]="
                          meteoAlarmsForm.value.stormDelayTime | inputTime
                        "
                        (change)="
                          onChangeInputTimeWithKeyboard(
                            $event,
                            'stormDelayTime'
                          )
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-4 col-lg-4">
                <div class="row justify-content-between align-items-center">
                  <div class="col-md-6">
                    <h6 class="fs-5 input-label">
                      {{
                        translationsLib.get(
                          'irrigation_alarm_no_confirmation_time'
                        )
                      }}
                    </h6>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-2">
                      <ng-template #stormNoAlarmConfirmationTimePicker>
                        <timepicker
                          appOnlyNumbers
                          [showMeridian]="false"
                          [showSeconds]="true"
                          [minuteStep]="1"
                          [secondsStep]="1"
                          [formControlName]="'stormNoAlarmConfirmationTime'"
                        ></timepicker>
                      </ng-template>
                      <input
                        appOnlyNumbers
                        [ngClass]="{
                          'ng-dirty':
                            meteoAlarmsForm.controls
                              .stormNoAlarmConfirmationTime.dirty
                        }"
                        class="form-control text-center"
                        type="text"
                        [popover]="stormNoAlarmConfirmationTimePicker"
                        [outsideClick]="true"
                        placement="bottom"
                        [value]="
                          meteoAlarmsForm.value.stormNoAlarmConfirmationTime
                            | inputTime
                        "
                        (change)="
                          onChangeInputTimeWithKeyboard(
                            $event,
                            'stormNoAlarmConfirmationTime'
                          )
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-4 col-lg-4">
                <div class="row justify-content-between align-items-center">
                  <div class="col-md-6">
                    <h6 class="fs-5 input-label">
                      {{
                        translationsLib.get('irrigation_alarm_storm_wind_speed')
                      }}
                    </h6>
                  </div>
                  <div class="col-md-6">
                    <div class="input-group mb-2 number-input">
                      <button
                        class="minus minus-style"
                        (click)="inputNumberService.decrementValue($event)"
                      ></button>
                      <input
                        formControlName="stormWindSpeed"
                        type="number"
                        class="form-control"
                        placeholder="{{
                          translationsLib.get(
                            'irrigation_alarm_storm_wind_speed'
                          )
                        }}"
                        min="-9999"
                        max="9999"
                      />
                      <button
                        class="plus border-plus plus-style"
                        (click)="inputNumberService.incrementValue($event)"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Alarma de helada -->
        <div class="card">
          <div class="card-body custom-card-body">
            <div class="row">
              <div class="col-12">
                <h6 class="fs-2 input-label">
                  {{ translationsLib.get('irrigation_alarm_frost') }}
                </h6>
              </div>
            </div>

            <div class="row align-items-center">
              <div class="col-sm-12 col-md-4 col-lg-4">
                <div class="row align-items-center justify-content-between">
                  <div class="col-md-6">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get(
                          'irrigation_alarm_confirmation_time'
                        )
                      }}
                    </h6>
                  </div>
                  <div class="col-md-6">
                    <div class="input-group mb-2">
                      <ng-template #frostDelayTimePicker>
                        <timepicker
                          appOnlyNumbers
                          [showMeridian]="false"
                          [showSeconds]="true"
                          [minuteStep]="1"
                          [secondsStep]="1"
                          [formControlName]="'frostDelayTime'"
                        ></timepicker>
                      </ng-template>
                      <input
                        appOnlyNumbers
                        [ngClass]="{
                          'ng-dirty':
                            meteoAlarmsForm.controls.frostDelayTime.dirty
                        }"
                        class="form-control text-center"
                        type="text"
                        [popover]="frostDelayTimePicker"
                        [outsideClick]="true"
                        placement="bottom"
                        [value]="
                          meteoAlarmsForm.value.frostDelayTime | inputTime
                        "
                        (change)="
                          onChangeInputTimeWithKeyboard(
                            $event,
                            'frostDelayTime'
                          )
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-4 col-lg-4">
                <div class="row justify-content-between align-items-center">
                  <div class="col-md-6">
                    <h6 class="fs-5 input-label">
                      {{
                        translationsLib.get(
                          'irrigation_alarm_no_confirmation_time'
                        )
                      }}
                    </h6>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-2">
                      <ng-template #frostNoAlarmConfirmationTimePicker>
                        <timepicker
                          appOnlyNumbers
                          [showMeridian]="false"
                          [showSeconds]="true"
                          [minuteStep]="1"
                          [secondsStep]="1"
                          [formControlName]="'frostNoAlarmConfirmationTime'"
                        ></timepicker>
                      </ng-template>
                      <input
                        appOnlyNumbers
                        [ngClass]="{
                          'ng-dirty':
                            meteoAlarmsForm.controls
                              .frostNoAlarmConfirmationTime.dirty
                        }"
                        class="form-control text-center"
                        type="text"
                        [popover]="frostNoAlarmConfirmationTimePicker"
                        [outsideClick]="true"
                        placement="bottom"
                        [value]="
                          meteoAlarmsForm.value.frostNoAlarmConfirmationTime
                            | inputTime
                        "
                        (change)="
                          onChangeInputTimeWithKeyboard(
                            $event,
                            'frostNoAlarmConfirmationTime'
                          )
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-4 col-lg-4">
                <div class="row justify-content-between align-items-center">
                  <div class="col-md-6">
                    <h6 class="fs-5 input-label">
                      {{
                        translationsLib.get(
                          'irrigation_alarm_frost_temperature'
                        )
                      }}
                    </h6>
                  </div>
                  <div class="col-md-6">
                    <div class="input-group mb-2 number-input">
                      <button
                        class="minus minus-style"
                        (click)="inputNumberService.decrementValue($event)"
                      ></button>
                      <input
                        formControlName="frostFrostTemperature"
                        type="number"
                        class="form-control"
                        placeholder="{{
                          translationsLib.get(
                            'irrigation_alarm_frost_temperature'
                          )
                        }}"
                        min="-9999"
                        max="9999"
                      />
                      <button
                        class="plus border-plus plus-style"
                        (click)="inputNumberService.incrementValue($event)"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row align-items-center">
              <div class="col-sm-12 col-md-4 col-lg-4">
                <div class="row justify-content-between align-items-center">
                  <div class="col-md-6">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_correction_start') }}
                      (W/m²)
                    </h6>
                  </div>
                  <div class="col-md-6">
                    <div class="input-group mb-2 number-input">
                      <button
                        class="minus minus-style"
                        (click)="inputNumberService.decrementValue($event)"
                      ></button>
                      <input
                        formControlName="frostStartRadiationCorrection"
                        type="number"
                        class="form-control"
                        placeholder="{{
                          translationsLib.get('irrigation_correction_start')
                        }} (W/m²)"
                        min="-9999"
                        max="9999"
                      />
                      <button
                        class="plus border-plus plus-style"
                        (click)="inputNumberService.incrementValue($event)"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-4 col-lg-4">
                <div class="row justify-content-between align-items-center">
                  <div class="col-md-6">
                    <h6 class="fs-5 input-label">
                      {{ translationsLib.get('irrigation_correction_end') }}
                      (W/m²)
                    </h6>
                  </div>
                  <div class="col-md-6">
                    <div class="input-group mb-2 number-input">
                      <button
                        class="minus minus-style"
                        (click)="inputNumberService.decrementValue($event)"
                      ></button>
                      <input
                        formControlName="frostEndRadiationCorrection"
                        type="number"
                        class="form-control"
                        placeholder="{{
                          translationsLib.get('irrigation_correction_end')
                        }} (W/m²)"
                        min="-9999"
                        max="9999"
                      />
                      <button
                        class="plus border-plus plus-style"
                        (click)="inputNumberService.incrementValue($event)"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-4 col-lg-4">
                <div class="row justify-content-between align-items-center">
                  <div class="col-md-6">
                    <h6 class="fs-5 input-label">
                      {{
                        translationsLib.get('irrigation_alarm_max_correction')
                      }}
                    </h6>
                  </div>
                  <div class="col-md-6">
                    <div class="input-group mb-2 number-input">
                      <button
                        class="minus minus-style"
                        (click)="inputNumberService.decrementValue($event)"
                      ></button>
                      <input
                        formControlName="frostMaxTemperatureCorrection"
                        type="number"
                        class="form-control"
                        placeholder="{{
                          translationsLib.get('irrigation_alarm_max_correction')
                        }}"
                        min="-9999"
                        max="9999"
                      />
                      <button
                        class="plus border-plus plus-style"
                        (click)="inputNumberService.incrementValue($event)"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-container>
