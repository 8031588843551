import { Component, Input } from '@angular/core';
import { WaterSystemFieldTriple } from '../../models/water-systems.model';

@Component({
  selector: 'app-field-card-triple',
  templateUrl: './field-card-triple.component.html',
  styleUrls: ['./field-card-triple.component.scss'],
})
export class FieldCardTripleComponent {
  @Input() field: WaterSystemFieldTriple;
  @Input() isMobile = false;

  constructor() {
    //
  }
}
