<!-- CLIMATE -->
<div
  *ngIf="isClimate; else isIrrigation"
  class="card header-container"
  [ngClass]="borderType"
>
  <div class="card-body climate-card-paddings header-paddings">
    <div class="row align-items-center">
      <div class="col-auto" *ngIf="image">
        <img [src]="image" width="48px" height="48px" />
      </div>
      <div class="col-10 col-md-10">
        <div
          class="row align-items-center justify-content-start"
          style="flex-wrap: nowrap"
        >
          <div class="col-auto ps-1 ps-md-2 pe-0 climate-button-container">
            <ion-button
              class="climate-button go-back-button btn-height"
              fill="outline"
              (click)="goBackButton(climateElements[0].zone)"
              [disabled]="selectedZone === 0"
            >
              <ion-icon
                [name]="'PLAY-SKIP-BACK' | sensorIcon : '' : '' : true"
              ></ion-icon>
            </ion-button>
            <ion-button
              class="climate-button btn-height"
              fill="outline"
              (click)="goBackButton(climateElements[selectedZone - 1].zone)"
              [disabled]="selectedZone === 0"
            >
              <ion-icon
                [name]="'CARET-BACK' | sensorIcon : '' : '' : true"
              ></ion-icon>
            </ion-button>
          </div>
          <div class="col-auto p-1 custom-flex">
            <div class="input-group climate-mat-form">
              <mat-form-field appearance="outline" style="width: 100%">
                <input
                  class="select-zone-compartment"
                  matInput
                  type="text"
                  [placeholder]="placeholder"
                  [matAutocomplete]="auto"
                  [(ngModel)]="search.value"
                  (keyup)="filterClimateElements()"
                />
                <mat-autocomplete
                  #auto="matAutocomplete"
                  (optionSelected)="changedClimate($event.option.value)"
                  [displayWith]="displayFnClimate"
                >
                  <mat-option
                    class="zone-compartment"
                    *ngFor="let elem of filteredElements; let i = index"
                    [value]="elem"
                  >
                    {{
                      elem.name === null ||
                      elem.name === '' ||
                      !elem.hasOwnProperty('name')
                        ? label + ' ' + (elem.zone + 1)
                        : elem.name
                    }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <!-- Si se prefiere usar un select normal
                <select class="form-select" [(ngModel)]="selected" (change)="changeSelected.emit(selected)">
                  <option *ngFor="let elem of elements" [ngValue]="elem">{{elem[text]}}</option>
                </select>
              -->
            </div>
          </div>
          <div class="col-auto ps-0 climate-button-container">
            <ion-button
              class="climate-button btn-height"
              fill="outline"
              (click)="goBackButton(climateElements[selectedZone + 1].zone)"
              [disabled]="selectedZone === climateElements.length - 1"
            >
              <ion-icon
                [name]="'CARET-FORWARD' | sensorIcon : '' : '' : true"
              ></ion-icon>
            </ion-button>
            <ion-button
              class="climate-button go-back-button btn-height"
              fill="outline"
              (click)="
                goBackButton(climateElements[climateElements.length - 1].zone)
              "
              [disabled]="selectedZone === climateElements.length - 1"
            >
              <ion-icon
                [name]="'PLAY-SKIP-FORWARD' | sensorIcon : '' : '' : true"
              ></ion-icon>
            </ion-button>
          </div>
        </div>
      </div>
      <div class="col-2 col-md-2 col-lg-2 pe-0 pe-md-2">
        <ng-content select="[climate]"></ng-content>
      </div>
    </div>
  </div>
</div>

<!-- IRRIGATION -->
<ng-template #isIrrigation>
  <div class="card mb-0 mt-3 header-container irrigation">
    <div class="card-body climate-card-paddings header-paddings">
      <div class="row align-items-center">
        <div class="col-auto display-none" *ngIf="image">
          <img [src]="image" width="48px" height="48px" />
        </div>
        <div
          [ngClass]="{
            col: hasButtons,
            'col-10 col-md-auto': !hasButtons,
            'custom-padding-flex--programs': isPrograms,
            'custom-padding-flex': !isPrograms
          }"
        >
          <div
            class="row align-items-center justify-content-start"
            style="flex-wrap: nowrap"
          >
            <div
              class="col-auto pe-0 irrigation-button-container"
              [ngClass]="{
                'back-button--programs': isPrograms,
                'back-button': !isPrograms
              }"
            >
              <ion-button
                class="go-back-button btn-height"
                fill="outline"
                (click)="changeSelected.emit(elements[0])"
                [disabled]="selectedIndex === 0"
              >
                <ion-icon
                  [name]="'PLAY-SKIP-BACK' | sensorIcon : '' : '' : true"
                ></ion-icon>
              </ion-button>
              <ion-button
                class="btn-height"
                fill="outline"
                (click)="changeSelected.emit(elements[selectedIndex - 1])"
                [disabled]="selectedIndex === 0"
              >
                <ion-icon
                  [name]="'CARET-BACK' | sensorIcon : '' : '' : true"
                ></ion-icon>
              </ion-button>
            </div>
            <div class="col-auto p-1 custom-flex only-input">
              <div class="input-group">
                <mat-form-field appearance="outline" class="custom-mat-flex">
                  <input
                    matInput
                    type="text"
                    [placeholder]="placeholder"
                    [matAutocomplete]="auto"
                    [(ngModel)]="search.value"
                    (keyup)="filterIrrigationElements()"
                  />
                  <mat-autocomplete
                    #auto="matAutocomplete"
                    (optionSelected)="changedIrrigation($event.option.value)"
                    [displayWith]="displayFnIrrigation"
                  >
                    <mat-option
                      *ngFor="let elem of filteredElements"
                      [value]="elem"
                    >
                      {{
                        elem[text] === null ||
                        elem[text] === '' ||
                        !elem.hasOwnProperty(text)
                          ? label + ' ' + (elem[elementSearchId] + 1)
                          : trimString(elem[text])
                      }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>
            <div class="col-auto ps-0 irrigation-button-container">
              <ion-button
                class="btn-height"
                fill="outline"
                (click)="changeSelected.emit(elements[selectedIndex + 1])"
                [disabled]="selectedIndex === elements.length - 1"
              >
                <ion-icon
                  [name]="'CARET-FORWARD' | sensorIcon : '' : '' : true"
                ></ion-icon>
              </ion-button>
              <ion-button
                class="go-back-button btn-height"
                fill="outline"
                (click)="changeSelected.emit(elements[elements.length - 1])"
                [disabled]="selectedIndex === elements.length - 1"
              >
                <ion-icon
                  [name]="'PLAY-SKIP-FORWARD' | sensorIcon : '' : '' : true"
                ></ion-icon>
              </ion-button>
            </div>
          </div>
        </div>
        <div
          [ngClass]="{
            'col-12 col-sm-6 col-md-6': hasButtons,
            'col-2': !hasButtons,
            'col-md text-end': !hasButtons,
            'custom-dinamic-content': true,
            'custom-grid': hasButtons
          }"
        >
          <ng-content select="[irrigation]"></ng-content>
        </div>
      </div>
    </div>
  </div>
</ng-template>
