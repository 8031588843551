<app-main-header [breadcrumbs]="breadcrumbs"></app-main-header>
<ion-content>
  <div class="container-fluid p-1 p-lg-2">
    <div class="mx-lg-4">
      <div class="col-12">
        <mat-tab-group class="col-12" (selectedTabChange)="onTabChange($event)" #tabGroup>
          <mat-tab label="{{ translationsLib.get('notification_notifications') }}">
            <br>
            <app-notifications></app-notifications>
          </mat-tab>
          <mat-tab label="{{ translationsLib.get('notification_invitations') }}">
            <br>
            <app-invitations></app-invitations>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</ion-content>
