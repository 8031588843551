import { Component, Input, OnInit } from '@angular/core';
import { AlarmsLibService } from '../../../services/libraries/alarms-lib.service';
import { HelpersLibService } from '../../../services/libraries/helpers-lib.service';
import { ReportsService } from '../../reports.service';
import {
  ConsumptionProgramChartReportDataModel,
  ConsumptionProgramReportItemDataModel,
  CreatedReportModel,
  ReportRequestData,
} from '../../reports.model';

import { ReportsConsumptionsService } from '../reports-consumptions.service';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
@Component({
  selector: 'app-reports-programs-consumptions',
  templateUrl: './reports-programs-consumptions.component.html',
  styleUrls: ['./reports-programs-consumptions.component.scss'],
  animations: [],
})
export class ReportsProgramsConsumptionsComponent implements OnInit {
  @Input() createdReport: CreatedReportModel;
  @Input() begin: string;
  @Input() end: string;
  @Input() prettyBegin: string;
  @Input() prettyEnd: string;
  @Input() advancedModeIsAllowed = false;
  terminalCountWithConsumptions = 0;
  loader = false;
  showCharts = false;
  reportsData: ConsumptionProgramReportItemDataModel[] = [];
  chart: ConsumptionProgramChartReportDataModel = null;
  requestData: ReportRequestData;
  constructor(
    private alarmsLib: AlarmsLibService,
    private helpersLib: HelpersLibService,
    private reportsService: ReportsService,
    public reportsConsumptionService: ReportsConsumptionsService,
    public translationsLib: TranslationsLibService
  ) {}
  ngOnInit() {
    this.reportsConsumptionService.resetAll();
    this.reportsConsumptionService.advancedModeIsAllowed =
      this.advancedModeIsAllowed;
    this.reportsConsumptionService.reportModeIsProgramConsumptions = true;
    this.reportsConsumptionService.terminalsLength =
      this.createdReport.terminals.length;
    this.processConsumptions();
  }
  public processConsumptions(): void {
    this.loader = true;
    const terminal_id_max = this.createdReport.terminals.length - 1;
    const terminals = [];
    this.createdReport.terminals.forEach((terminalReport, terminalId) => {
      terminals.push(terminalReport.vid);
    });
    this.reportsService
      .getConsumptionsProgramReport(
        { terminals_vid: terminals },
        this.begin,
        this.end,
        null
      )
      .subscribe((res) => {
        this.requestData = {
          terminals_vid: [],
          begin: this.begin,
          end: this.end,
          type: 'programs',
        };
        res.consumption_programs_reports.forEach((programReport, index) => {
          this.createdReport.terminals.forEach((terminalReport, terminalId) => {
            if (programReport.terminal_vid === terminalReport.vid) {
              programReport.terminal_field_name = terminalReport.field_name;
              programReport.terminal_field_vid = terminalReport.field_vid;
              programReport.terminal_name_vid = terminalReport.name_vid;
            }
          });
          this.helpersLib.sendEventPageView(
            'Reports | Program Consumptions | ' +
              programReport.terminal_name +
              '-' +
              programReport.terminal_vid
          );
          this.requestData.terminals_vid.push(programReport.terminal_vid);
          this.reportsData = programReport.items;
          this.chart = programReport.chart;
          programReport.items.forEach((item) => {
            if (item.programConsumptions.length > 0) {
              this.terminalCountWithConsumptions++;
              return;
            }
          });
          if (index === terminal_id_max) {
            this.loader = false;
            const self = this;
            setTimeout(() => {
              self.showCharts = true;
            }, 1000);
          }
        });
      });
  }
}
