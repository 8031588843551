<div class="configurable-groups">
  <form [formGroup]="groupForm">
    <div class="card configurable-card-bg p-4">
      <div class="row">
        <!-- Selector de grupo -->
        <div class="col-12">
          <div class="card mb-4">
            <div class="card-body custom-card-body">
              <div class="row">
                <div class="col-12">
                  <h6 class="fs-2">
                    {{ translationsLib.get('configurable_select_group') }}
                  </h6>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="input-group">
                    <mat-form-field class="col-12" appearance="fill">
                      <mat-select
                        [(value)]="selectedGroup"
                        (selectionChange)="setForm()"
                      >
                        <mat-option
                          *ngFor="let group of groups"
                          [value]="group.group"
                        >
                          {{ getGroupName(group) }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Válvulas -->
        <div class="col-12 col-xl-6">
          <div class="card mb-4">
            <div class="card-body custom-card-body">
              <div class="row">
                <div class="col-12">
                  <h6 class="fs-2">
                    {{ translationsLib.get('configurable_valves') }}
                  </h6>
                </div>
              </div>
              <div class="row" *ngIf="mode === 'simple'">
                <div class="col-12">
                  <p class="fs-5 fw-lighter">
                    {{ translationsLib.get('configurable_help_valves') }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <mat-form-field
                    class="col-12"
                    appearance="fill"
                    [ngClass]="{ dirty: isFieldDirty('valves') }"
                  >
                    <mat-select
                      multiple
                      [value]="valves"
                      [compareWith]="compareValves"
                      (selectionChange)="selectValve($event.value)"
                      [placeholder]="
                        translationsLib.get('configurable_select_valves')
                      "
                    >
                      <mat-select-trigger>
                        <mat-chip-list
                          cdkDropList
                          #valvesChipList
                          cdkDropListOrientation="horizontal"
                          (cdkDropListDropped)="moveValve($event)"
                        >
                          <mat-chip
                            cdkDrag
                            *ngFor="let valve of valves"
                            (removed)="removeValve(valve)"
                            [removable]="true"
                          >
                            {{
                              translationsLib.get('configurable_valve') +
                                ' ' +
                                (valve.valve + 1)
                            }}
                            <button matChipRemove>
                              <ion-icon
                                [name]="
                                  'CLOSE-CIRCLE-OUTLINE'
                                    | sensorIcon : '' : '' : true
                                "
                              >
                              </ion-icon>
                            </button>
                          </mat-chip>
                        </mat-chip-list>
                      </mat-select-trigger>
                      <mat-option
                        *ngFor="let valve of numValves"
                        [value]="valve"
                      >
                        {{
                          translationsLib.get('configurable_valve') +
                            ' ' +
                            (valve.valve + 1)
                        }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Bombas -->
        <div class="col-12 col-xl-6">
          <div class="card mb-4">
            <div class="card-body custom-card-body">
              <div class="row">
                <div class="col-12">
                  <h6 class="fs-2">
                    {{ translationsLib.get('configurable_pumps') }}
                  </h6>
                </div>
              </div>
              <div class="row" *ngIf="mode === 'simple'">
                <div class="col-12">
                  <p class="fs-5 fw-lighter">
                    {{ translationsLib.get('configurable_help_pumps') }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <mat-form-field
                    class="col-12"
                    appearance="fill"
                    [ngClass]="{ dirty: isFieldDirty('pumps') }"
                  >
                    <mat-select
                      multiple
                      [value]="pumps"
                      [compareWith]="comparePumps"
                      (selectionChange)="selectPump($event.value)"
                      [placeholder]="
                        translationsLib.get('configurable_select_pump')
                      "
                    >
                      <mat-select-trigger>
                        <mat-chip-list
                          cdkDropList
                          #pumpsChipList
                          cdkDropListOrientation="horizontal"
                          (cdkDropListDropped)="movePump($event)"
                        >
                          <mat-chip
                            cdkDrag
                            *ngFor="let pump of pumps"
                            (removed)="removePump(pump)"
                            [removable]="true"
                          >
                            {{
                              translationsLib.get('configurable_pump') +
                                ' ' +
                                (pump.pump + 1)
                            }}
                            <button matChipRemove>
                              <ion-icon
                                [name]="
                                  'CLOSE-CIRCLE-OUTLINE'
                                    | sensorIcon : '' : '' : true
                                "
                              >
                              </ion-icon>
                            </button>
                          </mat-chip>
                        </mat-chip-list>
                      </mat-select-trigger>
                      <mat-option *ngFor="let pump of numPumps" [value]="pump">
                        {{
                          translationsLib.get('configurable_pump') +
                            ' ' +
                            (pump.pump + 1)
                        }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Tiempos -->
        <div class="col-12 col-xl-6">
          <div class="card mb-4">
            <div class="card-body custom-card-body">
              <div class="row" *ngIf="mode === 'simple'">
                <div class="col-12">
                  <p class="fs-5 fw-lighter">
                    {{
                      translationsLib.get('configurable_help_duration_pre_post')
                    }}
                  </p>
                </div>
              </div>
              <div class="row mb-3">
                <!-- Duración -->
                <div class="col-12 col-md-4">
                  <h6 class="fs-4">
                    {{ translationsLib.get('configurable_duration') }}
                  </h6>
                  <div class="input-group">
                    <ng-template #durationTimePicker>
                      <timepicker
                        appOnlyNumbers
                        [showMeridian]="false"
                        [minuteStep]="1"
                        [formControlName]="'duration'"
                        (ngModelChange)="emitGroups()"
                      ></timepicker>
                    </ng-template>
                    <input
                      appOnlyNumbers
                      type="text"
                      class="form-control text-center px-0"
                      [value]="groupForm.value.duration | date : 'HH:mm'"
                      (change)="
                        onChangeInputTimeWithKeyboard($event, 'duration')
                      "
                      [ngClass]="{ dirty: isFieldDirty('duration') }"
                      [popover]="durationTimePicker"
                      [outsideClick]="true"
                    />
                  </div>
                </div>

                <!-- Pre-riego -->
                <div class="col-12 col-md-4">
                  <h6 class="fs-4">
                    {{ translationsLib.get('configurable_pre_irrigation') }}
                  </h6>
                  <div class="input-group">
                    <ng-template #preIrrigationTimePicker>
                      <timepicker
                        appOnlyNumbers
                        [showMeridian]="false"
                        [minuteStep]="1"
                        [formControlName]="'preIrrigationTime'"
                        (ngModelChange)="emitGroups()"
                      ></timepicker>
                    </ng-template>
                    <input
                      appOnlyNumbers
                      type="text"
                      class="form-control text-center px-0"
                      [value]="
                        groupForm.value.preIrrigationTime | date : 'HH:mm'
                      "
                      (change)="
                        onChangeInputTimeWithKeyboard(
                          $event,
                          'preIrrigationTime'
                        )
                      "
                      [ngClass]="{ dirty: isFieldDirty('preIrrigationTime') }"
                      [outsideClick]="true"
                      [popover]="preIrrigationTimePicker"
                    />
                  </div>
                </div>

                <!-- Post-riego -->
                <div class="col-12 col-md-4">
                  <h6 class="fs-4">
                    {{ translationsLib.get('configurable_post_irrigation') }}
                  </h6>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control text-center px-0"
                      [value]="
                        groupForm.value.postIrrigationTime | date : 'HH:mm'
                      "
                      readonly
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Tipo abonado -->
        <div class="col-12 col-xl-6">
          <div class="card mb-4">
            <div class="card-body custom-card-body">
              <div class="row mb-1">
                <div class="col-12">
                  <h6 class="fs-2">
                    {{ translationsLib.get('configurable_fertilizer_type') }}
                  </h6>
                </div>
              </div>
              <div class="row" *ngIf="mode === 'simple'">
                <div class="col-12">
                  <p class="fs-5 fw-lighter">
                    {{
                      translationsLib.get('configurable_help_fertilizer_type')
                    }}
                  </p>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12">
                  <div class="input-group mt-2">
                    <mat-radio-group
                      color="primary"
                      class="p-3"
                      [ngClass]="{
                        dirty:
                          groups[selectedGroup].fertilizerType !==
                          pristineGroups[selectedGroup].fertilizerType
                      }"
                      [formControlName]="'fertilizerType'"
                      (change)="emitGroups()"
                    >
                      <mat-radio-button
                        class="ms-0 me-3"
                        *ngFor="let ft of fertilizerTypes"
                        [value]="ft.number"
                      >
                        {{
                          translationsLib.get(
                            'configurable_fertilizer_type_' + ft.type
                          )
                        }}
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Abonos -->
        <div class="col-12">
          <div class="card mb-0">
            <div class="card-body custom-card-body">
              <div class="row mb-3">
                <div class="col-12">
                  <h6 class="fs-2">
                    {{ translationsLib.get('configurable_fertilizers') }}
                  </h6>
                </div>
              </div>
              <div class="row" *ngIf="isIrrigating()">
                <div class="col-12">
                  <p class="fs-5 fw-lighter text-danger">
                    {{
                      translationsLib.get(
                        'configurable_group_irrigating_no_modify'
                      )
                    }}
                  </p>
                </div>
              </div>
              <div class="row" *ngIf="mode === 'simple'">
                <div class="col-12">
                  <p class="fs-5 fw-lighter">
                    {{ translationsLib.get('configurable_help_fertilizers') }}
                  </p>
                </div>
              </div>
              <div class="row">
                <ng-container *ngFor="let fert of groupForm.value.fertilizers">
                  <div class="col-12 col-md-3 mb-2">
                    <mat-label
                      >{{ translationsLib.get('configurable_fertilizer') }}
                      {{ fert.order + 1 }}</mat-label
                    >
                    <select
                      class="form-select text-center"
                      [disabled]="isIrrigating()"
                      [ngClass]="{
                        dirty:
                          groups[selectedGroup].fertilizers[fert.order]
                            .fertilizer !==
                          pristineGroups[selectedGroup].fertilizers[fert.order]
                            .fertilizer
                      }"
                      [(ngModel)]="fert.fertilizer"
                      (ngModelChange)="emitGroups()"
                      [ngModelOptions]="{ standalone: true }"
                    >
                      <option [ngValue]="255">-</option>
                      <option
                        *ngFor="let numF of numFertilizers"
                        [ngValue]="numF"
                      >
                        {{ translationsLib.get('configurable_fertilizer') }}
                        {{ numF + 1 }}
                      </option>
                    </select>
                    <input
                      type="text"
                      class="form-control text-center px-0 mt-3"
                      *ngIf="isIrrigating()"
                      readonly
                      [value]="fert.time | date : 'H:mm'"
                    />
                    <input
                      appOnlyNumbers
                      type="text"
                      class="form-control text-center px-0 mt-3"
                      *ngIf="!isIrrigating()"
                      [ngClass]="{
                        dirty:
                          groups[selectedGroup].fertilizers[fert.order].time !==
                          pristineGroups[selectedGroup].fertilizers[fert.order]
                            .time
                      }"
                      [value]="fert.time | date : 'H:mm'"
                      [popover]="fertilizerTime"
                      [outsideClick]="true"
                      (change)="
                        onChangeInputTimeWithKeyboard(
                          $event,
                          'fertilizers',
                          fert.order,
                          'time'
                        )
                      "
                    />
                    <ng-template #fertilizerTime>
                      <timepicker
                        appOnlyNumbers
                        [max]="maxFertilizerTime"
                        [showMeridian]="false"
                        [minuteStep]="1"
                        [(ngModel)]="fert.time"
                        [ngModelOptions]="{ standalone: true }"
                        (ngModelChange)="emitGroups()"
                      ></timepicker>
                    </ng-template>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
