import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { IrrigationService } from '../../../irrigation.service';
import { ActivatedRoute } from '@angular/router';
import {
  convertFormattedTimeToSeconds,
  DateToSecondsInput,
  removeDirtyInputs,
  saveDataAndShowModal,
  saveSubmitWithTerminalStatus,
  secondsInputToDate,
} from '../../../../../commons/helpers/functions';
import Swal from 'sweetalert2';
import { PumpsIrrigationInstallerModel } from '../../../irrigation.model';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { EquipmentsLibService } from '../../../../../services/libraries/equipments-lib.service';
import { Subscription } from 'rxjs';
import { FormFieldHandlerService } from '../../../../libraries/form-field-handler.service';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-pumps-form',
  templateUrl: './pumps-form.component.html',
  styleUrls: ['./pumps-form.component.scss'],
})
export class PumpsFormComponent implements OnInit, OnDestroy {
  isFetchingData = false;
  isFormSubmitted = false;
  isPumpsWithoutData = false;
  private formFieldErrorSubscription: Subscription;
  isConnected: boolean;
  private intervalId: any;
  pumpsForm: UntypedFormGroup;
  selectedOptionArranque: number;
  selectedOptionParada: number;
  pumpsResponse: PumpsIrrigationInstallerModel[];
  arranqueStart: boolean;
  arranqueStop: boolean;
  paradaStart: boolean;
  paradaStop: boolean;
  @Input() mode: string;
  @Input() terminal_vid: string;
  @Input() pump_id: number;
  @Output() dirtyEventEmitter = new EventEmitter<boolean>();
  @Output() pumpEventEmitter =
    new EventEmitter<PumpsIrrigationInstallerModel>();
  @Output() formFieldErrorEventEmitter = new EventEmitter<boolean>();

  isErrorInPumpsPost = false;

  constructor(
    private fb: UntypedFormBuilder,
    private irrigationService: IrrigationService,
    private activatedRoute: ActivatedRoute,
    public translationsLib: TranslationsLibService,
    private equipmentLib: EquipmentsLibService,
    private formFieldHandlerService: FormFieldHandlerService
  ) {
    this.pumpsForm = this.fb.group({
      timeToStart: [null, Validators.required],
      dirty: [''],
      timeToStop: [null, Validators.required],
      pump: [null, Validators.required],
      isDirty: false,
    });
  }

  async startInterval() {
    this.intervalId = setInterval(() => {
      this.performTasks();
    }, environment.intervalDefaultTimeout);
  }

  async performTasks() {
    this.pumpsForm.reset();

    this.getPumps();

    await this.getIrrigationEquipment();

    if (!this.isConnected) {
      this.equipmentLib.showConnectivityAlert();
    }

    this.isFormSubmitted = false;
  }

  ngOnInit() {
    this.pumpsForm.reset(); // Prevent unsaved data to persist in form inputs

    this.activatedRoute.params.subscribe((_) => {
      this.getPumps();

      removeDirtyInputs(this.pumpsForm);
    });

    this.pumpsForm.valueChanges.subscribe((_) => {
      this.dirtyEventEmitter.emit(this.getIsDirty());

      if (this.getIsDirty()) {
        clearInterval(this.intervalId);
      } else {
        clearInterval(this.intervalId);
        this.startInterval();
      }
    });

    this.getIrrigationEquipment();
  }

  setDirty(dirty: boolean) {
    this.pumpsForm.patchValue({ isDirty: dirty });
  }

  getIsDirty() {
    return this.pumpsForm.dirty || this.pumpsForm.value.isDirty;
  }

  getIrrigationEquipment() {
    this.irrigationService
      .getIrrigationTerminal(this.terminal_vid)
      .subscribe((res) => {
        this.isConnected = res.connected;
      });
  }

  updateArranqueVisibility() {
    if (this.selectedOptionArranque === 1) {
      this.arranqueStop = true;
      this.arranqueStart = false;
    } else {
      this.arranqueStop = false;
      this.arranqueStart = true;
    }

    this.setDirty(true);
    this.pumpsForm.markAsDirty();
  }

  updateParadaVisibility() {
    if (this.selectedOptionParada === 1) {
      this.paradaStop = true;
      this.paradaStart = false;
    } else {
      this.paradaStop = false;
      this.paradaStart = true;
    }

    this.setDirty(true);
    this.pumpsForm.markAsDirty();
  }

  onChangeInputTimeWithKeyboard(event, formInput: string) {
    event.target.classList.add('ng-dirty');
    this.pumpsForm.markAsDirty();

    const toSeconds = convertFormattedTimeToSeconds(event.target.value);
    const toDate = secondsInputToDate(toSeconds);

    this.pumpsForm.get(formInput).setValue(toDate);
  }

  getPumps(isGetAfterPost = false) {
    if (!isGetAfterPost) this.isFetchingData = true;

    this.irrigationService
      .getIrrigationInstallerPumps(this.terminal_vid)
      .subscribe((res) => {
        this.pumpsResponse = res;
        if (this.pumpsResponse.length === 0) {
          this.isPumpsWithoutData = true;
          this.isFetchingData = false;
        } else {
          /*
          timeToStart (+) --> Retardo arranque
          timeToStart (-) --> Adelanto arranque
          timeToStop (+) --> Retardo parada
          timeToStop (-) --> Adelanto parada
           */
          this.pumpsResponse.filter((pump) => {
            if (pump.pump === this.pump_id) {
              this.pumpEventEmitter.emit(pump);

              // Arranque control
              if (pump.timeToStart > 0) {
                this.selectedOptionArranque = 1;

                this.arranqueStop = true;
                this.arranqueStart = false;
              } else {
                this.selectedOptionArranque = -1;

                this.arranqueStop = false;
                this.arranqueStart = true;
              }

              // Parada control
              if (pump.timeToStop > 0) {
                this.selectedOptionParada = 1;

                this.paradaStop = true;
                this.paradaStart = false;
              } else {
                this.selectedOptionParada = -1;

                this.paradaStop = false;
                this.paradaStart = true;
              }

              this.pumpsForm.patchValue({
                dirty: pump.dirty,
                timeToStart: secondsInputToDate(Math.abs(pump.timeToStart)),
                timeToStop: secondsInputToDate(Math.abs(pump.timeToStop)),
                pump: pump.pump,
              });

              if (!isGetAfterPost) this.isFetchingData = false;
            }
          });
        }
      });
  }

  handlePost(showModal: boolean, connected: boolean): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      this.isFormSubmitted = true;

      const pumpsAsArray = [];

      pumpsAsArray.push({
        timeToStart:
          Number(this.selectedOptionArranque) === 1
            ? DateToSecondsInput(this.pumpsForm.value.timeToStart)
            : -1 * DateToSecondsInput(this.pumpsForm.value.timeToStart),
        timeToStop:
          Number(this.selectedOptionParada) === 1
            ? DateToSecondsInput(this.pumpsForm.value.timeToStop)
            : -1 * DateToSecondsInput(this.pumpsForm.value.timeToStop),
        pump: this.pumpsForm.value.pump,
      });

      try {
        this.irrigationService
          .postIrrigationInstallerPumps(this.terminal_vid, pumpsAsArray)
          .subscribe(
            (res) => {
              if (res.error) this.isErrorInPumpsPost = true;

              if (
                connected &&
                !this.pumpsForm.value.dirty &&
                !this.isErrorInPumpsPost
              ) {
                saveDataAndShowModal(
                  res,
                  showModal,
                  this.translationsLib.get('error_updating_data'),
                  this.translationsLib.get('save_changes_success'),
                  this.translationsLib.get('accept'),
                  () => {
                    setTimeout(() => {
                      this.getPumps(true);
                    }, 4000);
                  }
                );

                this.isFormSubmitted = false;
                resolve(true);
              } else if (this.isErrorInPumpsPost) {
                Swal.fire({
                  text: this.translationsLib.get(
                    'irrigation_general_program_error'
                  ),
                  showConfirmButton: true,
                  confirmButtonText: this.translationsLib.get('accept'),
                  icon: 'error',
                });

                this.isFormSubmitted = false;
                resolve(true);
              } else {
                saveSubmitWithTerminalStatus(
                  res,
                  showModal,
                  connected,
                  this.translationsLib.get('error_updating_data'),
                  this.translationsLib.get('save_changes_success'),
                  this.translationsLib.get('accept'),
                  this.translationsLib.get(
                    'irrigation_disconnected_terminal_pending_changes'
                  )
                );

                this.isFormSubmitted = false;
                resolve(true);
              }
            },
            (_) => {
              if (showModal) {
                Swal.fire({
                  text: this.translationsLib.get('something_was_wrong'),
                  showConfirmButton: true,
                  confirmButtonText: this.translationsLib.get('accept'),
                  icon: 'error',
                });
              }
              this.isFormSubmitted = false;
              resolve(false);
            }
          );
      } catch (err) {
        throw new Error(err.message);
      }
    });
  }

  submitForm(
    showModal: boolean = true
  ): Promise<{ result: boolean; connected: boolean }> {
    return new Promise<{ result: boolean; connected: boolean }>(
      (resolve, reject) => {
        try {
          this.irrigationService
            .getIrrigationTerminal(this.terminal_vid)
            .subscribe((res) => {
              this.handlePost(showModal, res.connected).then((result) => {
                resolve({ result, connected: res.connected });
              });
            });
        } catch (err) {
          reject({ result: false, connected: false });
        } finally {
          // Remove dirty inputs due to use standalone ngModels
          removeDirtyInputs(this.pumpsForm);

          this.pumpsForm.markAsUntouched();

          this.pumpsForm.markAsPristine();
          this.pumpsForm.patchValue({ isDirty: false });
        }
      }
    );
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
    this.formFieldErrorSubscription?.unsubscribe();
  }
}
