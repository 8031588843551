import { Pipe, PipeTransform } from '@angular/core';
import { TranslationsLibService } from '@nutricontrol/app360-shared';

@Pipe({
  name: 'alarmGridTitles',
})
export class AlarmGridTitlesPipe implements PipeTransform {
  constructor(public translationsLib: TranslationsLibService) {}

  transform(field: any, type: string, equipmentResponse: any): string {
    if (type === 'climate') {
      if (field.value && field.value.hasOwnProperty('zone')) {
        const zone = equipmentResponse.climate_configuration.zones.find(
          (zone) => zone.zone === field.value.zone
        );

        if (zone)
          return `${this.translationsLib.get('notification_alarm_of')} ${
            zone.name
          }`;
        else return this.translationsLib.get('notification_general_alarm');
      } else {
        return this.translationsLib.get('notification_general_alarm');
      }
    } else {
      return this.translationsLib.get('notifications_alarms');
    }
  }
}
