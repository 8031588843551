import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SensorDataModel } from '../../irrigation/irrigation.model';
import { TranslationsLibService } from '@nutricontrol/app360-shared';

@Component({
  selector: 'app-sensors-list-item',
  templateUrl: './sensors-list-item.component.html',
  styleUrls: [
    './sensors-list-item.component.scss',
    '../sensors-list/sensors-list.component.scss',
  ],
})
export class SensorsListItemComponent implements OnInit {
  @Input() sensors: SensorDataModel[];
  @Input() type: string;
  @Output() editSensor = new EventEmitter<SensorDataModel>();

  constructor(public translationsLib: TranslationsLibService) {}

  ngOnInit(): void {}

  getExpansions(sensors: SensorDataModel[]) {
    return Array.from(new Set(sensors.map((item) => Number(item.nexp))));
  }
}
