import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

// Services
import { HttpRepositoryService } from '../repositories/http-repository.service';
import {
  CheckAuthUserDataModel,
  ConfirmationCodeModel,
  LatestLegalAgreementsVersionDataModel,
  LegalAgreementsDataModel,
  LoginModel,
  RegisterPushTokenDataModel,
  ResendCodeModel,
  ResendCodeResponseModel,
  ResetPasswordModel,
  TokenDataModel,
  UserLegalAgreementsVersionDataModel,
} from '../../auth/auth.model';
import {
  ChangePasswordDataModel,
  ChangePasswordModel,
  NotificationPreferencesModel,
  UserDataModel,
  UserDeviceNotificationPreferencesModel,
  UserNotificationPreferencesModel,
  UserTerminalNotificationPreferencesModel,
} from '../../profile/profile.model';

import { SessionLibService } from '@nutricontrol/app360-shared';
import { CropsDataModel, FieldDataModel } from '../../fields/fields.model';

import {
  CountriesDataModel,
  ET0Model,
  LanguagesDataModel,
  LayoutDataModel,
  SidebarDataModel,
} from '../../commons/app-commons.model';

import {
  AttachDataModel,
  ContactsDataModel,
  DetachDataModel,
  MasterTemplate,
  SpecificTemplateKeys,
  TerminalAdministrativeManagementIdentityModel,
  TerminalValvesDataModel,
} from '../../terminals/terminals.model';
import { TerminalDataModel } from '@nutricontrol/app360-shared';
import { PermissionListDataModel } from '../../permissions/permission.model';
import {
  AgitatorsIrrigationInstallerModel,
  DemandsDataModel,
  DemandsIrrigationInstallerModel,
  DrainageModel,
  DrainageTrayModel,
  DrainageTraysIrrigationInstallerModel,
  DrainageV3Model,
  DrainageV4Model,
  FertilizerFormulasModel,
  FiltersIrrigationInstallerModel,
  FertilizersIrrigationInstallerModel,
  FertilizersNuveIrrigationModel,
  GroupsDataModel,
  InputOutputResponseModel,
  IOReportIrrigationInstallerModel,
  MeteoAlarmsModel,
  MiscellaneousIrrigationInstallerModel,
  MixtureFormulasModel,
  MixtureValvesIrrigationInstallerModel,
  PhCeRearmAlarmsIrrigationInstallerModel,
  ProgramModel,
  PumpsDataModel,
  PumpsIrrigationInstallerModel,
  SensorDataModel,
  ValvesDataModel,
  ValvesIrrigationInstallerModel,
  WaterSystemIrrigationInstallerModel,
  IrrigationEquipmentModel,
  TerminalLayoutModelIrrigationSubmit,
  EquipmentSubmitModel,
} from '../../farming/irrigation/irrigation.model';
import {
  WeatherCalculateET0Request,
  WeatherForecastDataModel,
  WeatherHistoricDataModel,
  WeatherPredictionDataModel,
} from '../../weather/weather.model';
import { MixedReportResponseModel } from '../../reports/reports.model';
import {
  ReportTaskAvailableCountModel,
  ReportTaskAvailablePermissionsRequestModel,
  ReportTaskAvailablePermissionsResponseModel,
  ReportTaskModel,
} from '../../reports/reports-task.model';
import {
  AlarmsStormSubmitModel,
  AlarmsZoneModel,
  AlarmsZoneSubmitModel,
  CancelAlarmsModel,
  ClimateEquipmentModel,
  CO2Model,
  CO2SubmitModel,
  CurtainDarkeningModel,
  CurtainDarkeningSubmitModel,
  CurtainEnergyModel,
  CurtainEnergySubmitModel,
  CurtainGappingModel,
  CurtainGappingSubmitModel,
  CurtainRegulationModel,
  CurtainRegulationSubmitModel,
  CurtainShadeModel,
  CurtainShadeSubmitModel,
  DesktopClimateModel,
  DesktopClimateStatesStructureModel,
  FanStepsModel,
  FanStepsSubmitModel,
  FrostAlarmModel,
  HafFansAdjustingModel,
  HafFansAdjustingSubmitModel,
  HafFansProgrammingModel,
  HafFansProgrammingSubmitModel,
  HeatersModel,
  HeatersSubmitModel,
  HeatingTemperatureModel,
  HeatingTemperatureSubmitModel,
  InstallerCO2Model,
  InstallerCO2SubmitModel,
  InstallerDarkeningCurtainsModel,
  InstallerDarkeningCurtainsSubmitModel,
  InstallerMistingModel,
  InstallerMistingSubmitModel,
  InstallerMixedCurtainsModel,
  InstallerMixedCurtainsSubmitModel,
  InstallerPipeModel,
  InstallerPipesSubmitModel,
  InstallerShadingCurtainsModel,
  InstallerShadingCurtainsSubmitModel,
  InstallerVentsModel,
  InstallerVentsSubmitModel,
  InstallerZoneModel,
  InstallerZonesSubmitModel,
  MistingProgrammingModel,
  MistingProgrammingSubmitModel,
  MistingRegulationModel,
  MistingRegulationSubmitModel,
  NewDesktopClimateModel,
  PipesModel,
  PipesSubmitModel,
  RainAlarmModel,
  ShadingCurtainModel,
  ShadingCurtainSubmitModel,
  StormAlarmLevelModel,
  StormAlarmModel,
  SwitchingProgramModel,
  SwitchingProgramSubmitModel,
  ThermalInversionModel,
  ThermalInversionSubmitModel,
  TransportGroupModel,
  VentilationTemperatureModel,
  VentilationTemperatureSubmitModel,
  VentModel,
  VentSubmitModel,
} from '../../farming/climate/climate.model';
import {
  ConfigurableEquipmentModel,
  ConfigurableProgramModel,
} from 'src/app/farming/configurable/configurable.model';
import {
  ModuleDataDataModel,
  ModuleDataModel,
} from '../../modules/modules.model';
import {
  DataloggerEquipmentModel,
  FAODataModel,
} from '../../farming/datalogger/datalogger.model';
import { RPSUserIdentityDataModel } from '../../rps-user-identity/rps-user-identity.model';
import {
  InvitationsDataModel,
  InvitationsPendingDataModel,
  NotificationDataModel,
} from '../../notifications/notifications.model';
import {
  PairingActionStatusDataModel,
  PrePairingDataModel,
} from '../../terminals/pairing.model';

// Models
//import { CredentialsModel, LoginModel, UserModel } from "../users/users.model";
//import { HumidityModel, TemperatureModel } from "../analytics/analytics.model";

@Injectable({
  providedIn: 'root',
})
export class RestApiService {
  constructor(
    private httpRepositoryService: HttpRepositoryService,
    private session: SessionLibService
  ) {}
  public postRegisterPushToken(
    data: RegisterPushTokenDataModel
  ): Observable<any> {
    return this.httpRepositoryService.postRequest(
      'notifications/devices',
      data
    );
  }
  public postRegister(data: UserDataModel): Observable<UserDataModel> {
    return this.httpRepositoryService.postRequest('users', data);
  }
  public getCountries(): Observable<CountriesDataModel> {
    return this.httpRepositoryService.getRequest('common/countries');
  }
  public getTranslations(): Observable<any> {
    return this.httpRepositoryService.getRequest('common/translations');
  }
  public getUserData(): Observable<UserDataModel> {
    return this.httpRepositoryService.getRequest(
      'users/' + this.session.getSessionVid()
    );
  }
  public confirmCode(
    confirmationCode: ConfirmationCodeModel
  ): Observable<UserDataModel> {
    return this.httpRepositoryService.postRequest(
      'users/' + confirmationCode.identifier + '/confirm',
      confirmationCode.data
    );
  }
  public resendCode(
    resendCode: ResendCodeModel
  ): Observable<ResendCodeResponseModel> {
    return this.httpRepositoryService.postRequest(
      'users/' + resendCode.identifier + '/resend-confirm',
      []
    );
  }
  public resetPasswordEmail(
    data: ResendCodeModel
  ): Observable<ResendCodeModel> {
    return this.httpRepositoryService.postRequest(
      'users/' + data.email + '/reset-password',
      'users/' + data.email + '/reset-password'
    );
  }
  public resetPassword(
    data: ResetPasswordModel
  ): Observable<ResetPasswordModel> {
    return this.httpRepositoryService.postRequest(
      'users/' + data.email + '/check-reset-password',
      data
    );
  }
  public login(data: LoginModel): Observable<UserDataModel> {
    return this.httpRepositoryService.postRequest(
      'auth/' + data.email + '/login',
      { password: data.password }
    );
  }
  public refreshToken(data: TokenDataModel): Observable<TokenDataModel> {
    return this.httpRepositoryService.postRequest(
      'auth/' + data.vid + '/refresh-token',
      { token: data.token, access_token: data.access_token }
    );
  }
  public putUserData(data): Observable<UserDataModel> {
    return this.httpRepositoryService.putRequest(
      'users/' + this.session.getSessionVid(),
      data
    );
  }
  public putNewPassword(
    data: ChangePasswordModel
  ): Observable<ChangePasswordDataModel> {
    return this.httpRepositoryService.putRequest(
      'users/' + this.session.getSessionVid() + '/password',
      data
    );
  }
  public getCropsList(): Observable<CropsDataModel> {
    return this.httpRepositoryService.getRequest('common/crops');
  }
  public deleteUser(data: UserDataModel): Observable<UserDataModel> {
    return this.httpRepositoryService.postRequest(
      'users/' + this.session.getSessionVid() + '/delete',
      data
    );
  }
  public postField(data: FieldDataModel): Observable<FieldDataModel> {
    return this.httpRepositoryService.postRequest('fields', data);
  }
  public getField(data): Observable<FieldDataModel> {
    return this.httpRepositoryService.getRequest('fields/' + data);
  }
  public putFields(data, vid): Observable<FieldDataModel> {
    return this.httpRepositoryService.putRequest('fields/' + vid, data);
  }
  public deleteField(vid): Observable<FieldDataModel> {
    return this.httpRepositoryService.deleteRequest('fields/' + vid);
  }
  public getMenu(): Observable<SidebarDataModel> {
    return this.httpRepositoryService.getRequest('users/menu');
  }
  public putMenu(data): Observable<SidebarDataModel> {
    return this.httpRepositoryService.putRequest('users/menu', data);
  }
  public getPrePairing(data): Observable<PrePairingDataModel> {
    return this.httpRepositoryService.getRequest('pairing/terminal/' + data);
  }
  public postPairing(data, tVid): Observable<PairingActionStatusDataModel> {
    return this.httpRepositoryService.postRequest(
      'pairing/terminal/' + tVid,
      data
    );
  }
  public postAttaching(tVid, fVid): Observable<AttachDataModel> {
    return this.httpRepositoryService.postRequest(
      'pairing/attach-terminal/' + tVid + '/to-field/' + fVid,
      []
    );
  }
  public getPermissionList(): Observable<PermissionListDataModel> {
    return this.httpRepositoryService.getRequest('pairing/permissions-list');
  }
  public postDetaching(tVid, fVid): Observable<DetachDataModel> {
    return this.httpRepositoryService.deleteRequest(
      'pairing/detach-terminal/' + tVid + '/from-field/' + fVid
    );
  }
  public postAttachUsertoField(email, vid, data): Observable<AttachDataModel> {
    return this.httpRepositoryService.postRequest(
      'pairing/attach-user/' + email + '/to-field/' + vid,
      data
    );
  }
  public postAttachUsertoTerminal(
    email,
    vid,
    data
  ): Observable<AttachDataModel> {
    return this.httpRepositoryService.postRequest(
      'pairing/attach-user/' + email + '/to-terminal/' + vid,
      data
    );
  }
  public changeUserFieldPermissions(
    email,
    vid,
    data
  ): Observable<AttachDataModel> {
    return this.httpRepositoryService.postRequest(
      'pairing/attach-user/' + email + '/to-field/' + vid,
      data
    );
  }
  public changeUserTerminalPermissions(
    email,
    vid,
    data
  ): Observable<AttachDataModel> {
    return this.httpRepositoryService.postRequest(
      'pairing/attach-user/' + email + '/to-terminal/' + vid,
      data
    );
  }
  public detachUserFromTerminal(
    email: string,
    vid: string
  ): Observable<DetachDataModel> {
    return this.httpRepositoryService.deleteRequest(
      'pairing/detach-user/' + email + '/from-terminal/' + vid
    );
  }

  public detachInstallerFromTerminal(
    email: string,
    vid: string
  ): Observable<DetachDataModel> {
    return this.httpRepositoryService.deleteRequest(
      '/pairing/detach-installer/' + email + '/from-terminal/' + vid
    );
  }
  public detachUserFromField(
    email: string,
    vid: string
  ): Observable<DetachDataModel> {
    return this.httpRepositoryService.deleteRequest(
      '/pairing/detach-user/' + email + '/from-field/' + vid
    );
  }
  public getNonAttached(): Observable<[TerminalDataModel]> {
    return this.httpRepositoryService.getRequest('terminals/non-attached');
  }
  public getNonDemo(): Observable<[TerminalDataModel]> {
    return this.httpRepositoryService.getRequest('terminals/non-demo');
  }
  public getTerminals(): Observable<[TerminalDataModel]> {
    return this.httpRepositoryService.getRequest('terminals');
  }
  public getLegalAgreementsText(): Observable<LegalAgreementsDataModel> {
    return this.httpRepositoryService.getRequest(
      'common/legal-agreements/latest-text'
    );
  }
  public getLegalAgreementsVersion(): Observable<LatestLegalAgreementsVersionDataModel> {
    return this.httpRepositoryService.getRequest(
      'common/legal-agreements/latest-version'
    );
  }
  public getUserLegalAgreementsVersion(
    data
  ): Observable<UserLegalAgreementsVersionDataModel> {
    return this.httpRepositoryService.getRequest(
      'users/' + data + '/accepted-legal-agreements-version'
    );
  }
  public putUserLegalAgreementsVersion(
    vid,
    data
  ): Observable<UserLegalAgreementsVersionDataModel> {
    return this.httpRepositoryService.putRequest(
      'users/' + vid + '/accepted-legal-agreements-version',
      data
    );
  }
  public getUserContacts(vid): Observable<ContactsDataModel> {
    return this.httpRepositoryService.getRequest('users/' + vid + '/contacts');
  }
  public getCheckUserAuth(): Observable<CheckAuthUserDataModel> {
    return this.httpRepositoryService.getRequest('auth/check');
  }
  public getFields(): Observable<FieldDataModel[]> {
    return this.httpRepositoryService.getRequest('fields');
  }
  public getTerminalsApiInfo(data): Observable<any> {
    return this.httpRepositoryService.postRequest(
      'farming/multi-terminal/nuve/equipment?q=valves',
      data
    );
  }

  public getTerminalsApiInfoWithWaterSystems(
    data
  ): Observable<
    (
      | IrrigationEquipmentModel
      | ClimateEquipmentModel
      | ConfigurableEquipmentModel
      | DataloggerEquipmentModel
    )[]
  > {
    return this.httpRepositoryService.postRequest(
      'farming/multi-terminal/nuve/equipment?q=watersystems-status',
      data
    );
  }
  public getPickUpConfig(vid): Observable<any> {
    return this.httpRepositoryService.getRequest(
      'farming/' + vid + '/equipment/pick_up_config'
    );
  }
  public setUpConfig(vid): Observable<any> {
    return this.httpRepositoryService.postRequest(
      'farming/' + vid + '/equipment/pick_up_config',
      []
    );
  }
  public getConfigStatus(vid): Observable<any> {
    return this.httpRepositoryService.getRequest(
      'farming/' + vid + '/equipment/config_status'
    );
  }
  public postActivateTerminal(vid): Observable<TerminalDataModel> {
    return this.httpRepositoryService.postRequest(
      'terminals/' + vid + '/activate',
      []
    );
  }
  public putUpdateTerminal(data, vid): Observable<TerminalDataModel> {
    return this.httpRepositoryService.putRequest('terminals/' + vid, data);
  } /*
  public getTerminal(vid): Observable<TerminalModel>
  {
    return this.httpRepositoryService.getRequest('terminals/'+vid);
  }
  public getLayout(): Observable<LayoutDataModel>
  {
    return this.httpRepositoryService.getRequest('users/layout');
  }*/
  public getTerminalLayoutConfiguration(vid): Observable<any> {
    return this.httpRepositoryService.getRequest(
      'terminals/' + vid + '/configuration'
    );
  }
  public putTerminalLayoutConfiguration(
    vid,
    data
  ): Observable<TerminalLayoutModelIrrigationSubmit> {
    return this.httpRepositoryService.putRequest(
      'terminals/' + vid + '/configuration',
      data
    );
  }
  public getTerminalAdministrativeManagement(
    vid: string
  ): Observable<TerminalAdministrativeManagementIdentityModel> {
    return this.httpRepositoryService.getRequest(
      'terminals/' + vid + '/administrative-management'
    );
  }
  public claimTerminalAdministrativeManagement(
    vid: string
  ): Observable<TerminalAdministrativeManagementIdentityModel> {
    return this.httpRepositoryService.postRequest(
      'terminals/' + vid + '/claim-administrative-management',
      {}
    );
  }
  public transferTerminalAdministrativeManagement(
    vid: string
  ): Observable<TerminalAdministrativeManagementIdentityModel> {
    return this.httpRepositoryService.postRequest(
      'terminals/' + vid + '/transfer-administrative-management',
      {}
    );
  }
  public getInvitations(): Observable<InvitationsDataModel> {
    return this.httpRepositoryService.getRequest('users/invitations');
  }
  public getInvitationsPendent(): Observable<InvitationsPendingDataModel> {
    return this.httpRepositoryService.getRequest('users/invitations/pending');
  }
  public putInvitationResponse(vid, data): Observable<InvitationsDataModel> {
    return this.httpRepositoryService.putRequest(
      'users/invitations/' + vid,
      data
    );
  }
  public putResendEmail(vid, data): Observable<InvitationsDataModel> {
    return this.httpRepositoryService.putRequest(
      'users/invitations/' + vid + '/set-as-pending',
      data
    );
  }
  public getLanguages(): Observable<LanguagesDataModel> {
    return this.httpRepositoryService.getRequest('common/languages');
  }
  public getWeatherCurrentWeatherByMultipleElements(
    data
  ): Observable<WeatherPredictionDataModel[]> {
    return this.httpRepositoryService.postRequest(
      'analytics/meteo/current-weather-by-multiple-elements',
      data
    );
  }
  public getWeatherCurrentByField(vid): Observable<WeatherPredictionDataModel> {
    return this.httpRepositoryService.getRequest(
      'analytics/meteo/current-weather-by-field/' + vid
    );
  }

  public getCurrentWeatherByTerminal(
    vid
  ): Observable<WeatherPredictionDataModel> {
    return this.httpRepositoryService.getRequest(
      'analytics/meteo/current-weather-by-terminal/' + vid
    );
  }
  public getWeatherForecastByField(vid): Observable<WeatherForecastDataModel> {
    return this.httpRepositoryService.getRequest(
      'analytics/meteo/forecast-weather-by-field/' + vid
    );
  }
  public getWeatherHistoric(
    vid: string,
    startingDate: string,
    endingDate: string,
    noCache: boolean = false
  ): Observable<WeatherHistoricDataModel> {
    return this.httpRepositoryService.getRequest(
      'analytics/meteo/historic-weather-by-field/' +
        vid +
        '?starting_date=' +
        startingDate +
        '&ending_date=' +
        endingDate +
        (noCache ? '&noCache=true' : '')
    );
  }
  public postMultiGraph(data): Observable<any> {
    return this.httpRepositoryService.postRequest(
      'farming/concurrent-terminal/nuve/charts',
      data
    );
  }
  public postSaveGraph(data): Observable<any> {
    return this.httpRepositoryService.postRequest(
      'analytics/graphs/graph-bookmarks',
      data
    );
  }
  public putSaveGraph(vid, data): Observable<any> {
    return this.httpRepositoryService.putRequest(
      'analytics/graphs/graph-bookmarks/' + vid,
      data
    );
  }
  public getSaveGraph(): Observable<any> {
    return this.httpRepositoryService.getRequest(
      'analytics/graphs/graph-bookmarks'
    );
  }
  public deleteSavedGraph(vid): Observable<any> {
    return this.httpRepositoryService.deleteRequest(
      'analytics/graphs/graph-bookmarks/' + vid
    );
  }

  public postSavedMetric(data): Observable<any> {
    return this.httpRepositoryService.postRequest(
      'analytics/metrics/metric-bookmarks',
      data
    );
  }
  public putSavedMetric(data, vid): Observable<any> {
    return this.httpRepositoryService.putRequest(
      'analytics/metrics/metric-bookmarks/' + vid,
      data
    );
  }
  public deleteSavedMetric(vid): Observable<any> {
    return this.httpRepositoryService.deleteRequest(
      'analytics/metrics/metric-bookmarks/' + vid
    );
  }
  public getSavedMetrics(): Observable<any> {
    return this.httpRepositoryService.getRequest(
      'analytics/metrics/metric-bookmarks'
    );
  }

  /*MÉTODOS DATALOGGER*/
  public putEquipment(vid, data): Observable<any> {
    //Es un post aunque sea el de editar
    return this.httpRepositoryService.postRequest(
      'farming/' + vid + '/equipment',
      data
    );
  }
  public getConnections(vid): Observable<any> {
    return this.httpRepositoryService.getRequest(
      'farming/' + vid + '/connections'
    );
  }
  public postConnections(vid, data): Observable<any> {
    return this.httpRepositoryService.postRequest(
      'farming/' + vid + '/connections',
      data
    );
  }
  public getConnectionsAvailable(vid): Observable<any> {
    return this.httpRepositoryService.getRequest(
      'farming/' + vid + '/connections/available'
    );
  }
  /*public deleteConnections(data, vid): Observable<any>{
    return this.httpRepositoryService.deleteRequest('farming/'+vid+'/connections', data);
  }*/
  public getDataloggerData(vid): Observable<any> {
    return this.httpRepositoryService.getRequest(
      'farming/' + vid + '/nuve/equipment'
    );
  }
  public getCalculations(vid) {
    return this.httpRepositoryService.getRequest(
      'farming/' + vid + '/calculations'
    );
  }
  public postCalculations(vid, data) {
    return this.httpRepositoryService.postRequest(
      'farming/' + vid + '/calculations',
      data
    );
  }
  public postReprogram(vid, data) {
    return this.httpRepositoryService.postRequest(
      'farming/' + vid + '/nuve/reprogram_sdi12',
      data
    );
  }
  public getCalculationsAvailable(vid) {
    return this.httpRepositoryService.getRequest(
      'farming/' + vid + '/calculations/available'
    );
  }
  public getChartsData(vid, data): Observable<any> {
    return this.httpRepositoryService.getRequest(
      'farming/' +
        vid +
        '/charts/data?nexp=' +
        data.nexp +
        '&nent=' +
        data.nent +
        '&physicalType=' +
        data.physicalType +
        '&begin=' +
        data.begin +
        '&end=' +
        data.end +
        '&interval=' +
        data.interval
    );
  }
  public postChartsData(vid, data): Observable<any> {
    return this.httpRepositoryService.postRequest(
      'farming/' + vid + '/nuve/charts',
      data
    );
  }
  public getET0Prediction(vid, data): Observable<any> {
    return this.httpRepositoryService.getRequest(
      'farming/' +
        vid +
        '/nuve/et0_estimate?begin=' +
        data.begin +
        '&end=' +
        data.end
    );
  }
  public getFAOValue(vid: string): Observable<FAODataModel[]> {
    return this.httpRepositoryService.getRequest(
      'farming/' + vid + '/nuve/fao_crops'
    );
  }
  public getCalculationsdependencies(vid, data): Observable<any> {
    return this.httpRepositoryService.postRequest(
      'farming/' + vid + '/calculations/dependencies',
      data
    );
  }
  public deleteCalculation(vid, data): Observable<any> {
    return this.httpRepositoryService.postRequest(
      'farming/' + vid + '/remove_calculation',
      data
    );
  }
  public deleteConnection(vid, data): Observable<any> {
    return this.httpRepositoryService.postRequest(
      'farming/' + vid + '/disconnect',
      data
    );
  }
  public getConnectionsdependencies(vid, data): Observable<any> {
    return this.httpRepositoryService.postRequest(
      'farming/' + vid + '/connections/dependencies',
      data
    );
  }

  public putConnections(vid, data): Observable<any> {
    return this.httpRepositoryService.putRequest(
      'farming/' + vid + '/connections',
      data
    );
  }
  public putCalculations(vid, data): Observable<any> {
    return this.httpRepositoryService.putRequest(
      'farming/' + vid + '/calculations',
      data
    );
  }

  // METODOS IRRIGATION

  /*************************
   ******* IRRIGATION ******
   *************************/
  getFertilizerFormulas(
    terminalVid: string
  ): Observable<FertilizerFormulasModel[]> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/irrigation/fertilizer_formulas`
    );
  }
  getFertilizerFormula(
    terminalVid: string,
    id: number
  ): Observable<FertilizerFormulasModel[]> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/irrigation/fertilizer_formulas?formula=${id}`
    );
  }

  getPrograms(terminalVid: string): Observable<ProgramModel[]> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/irrigation/programs`
    );
  }

  getClimateVentilations(
    terminalVid: string
  ): Observable<VentilationTemperatureModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/climate/ventilation_temperature`
    );
  }

  postClimateVentilations(
    terminalVid: string,
    climateVentilations: VentilationTemperatureSubmitModel
  ): Observable<VentilationTemperatureModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/climate/ventilation_temperature`,
      climateVentilations
    );
  }

  getVents(terminalVid: string): Observable<VentModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/climate/vents`
    );
  }

  postVents(
    terminalVid: string,
    vents: VentSubmitModel
  ): Observable<VentModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/climate/vents`,
      vents
    );
  }

  getFanSteps(terminalVid: string): Observable<FanStepsModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/climate/fan_steps`
    );
  }

  postFanSteps(
    terminalVid: string,
    fanSteps: FanStepsSubmitModel
  ): Observable<FanStepsModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/climate/fan_steps`,
      fanSteps
    );
  }

  getThermalInversion(terminalVid: string): Observable<ThermalInversionModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/climate/thermal_inversion`
    );
  }

  postThermalInversion(
    terminalVid: string,
    thermalInversion: ThermalInversionSubmitModel
  ): Observable<ThermalInversionModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/climate/thermal_inversion`,
      thermalInversion
    );
  }

  getHeatingTemperature(
    terminalVid: string
  ): Observable<HeatingTemperatureModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/climate/heating_temperature`
    );
  }

  postHeatingTemperature(
    terminalVid: string,
    heatingTemperature: HeatingTemperatureSubmitModel
  ): Observable<HeatingTemperatureModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/climate/heating_temperature`,
      heatingTemperature
    );
  }

  getHeaters(terminalVid: string): Observable<HeatersModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/climate/heaters`
    );
  }

  postHeaters(
    terminalVid: string,
    heaters: HeatersSubmitModel
  ): Observable<HeatersModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/climate/heaters`,
      heaters
    );
  }

  getPipes(terminalVid: string): Observable<PipesModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/climate/pipes`
    );
  }

  postPipes(
    terminalVid: string,
    pipes: PipesSubmitModel
  ): Observable<PipesModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/climate/pipes`,
      pipes
    );
  }

  getTransportGroup(terminalVid: string): Observable<TransportGroupModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/climate/transport_group`
    );
  }

  postTransportGroup(
    terminalVid: string,
    transportGroup: TransportGroupModel
  ): Observable<TransportGroupModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/climate/transport_group`,
      transportGroup
    );
  }

  getMistingProgramming(
    terminalVid: string
  ): Observable<MistingProgrammingModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/climate/misting`
    );
  }

  postMistingProgramming(
    terminalVid: string,
    misting: MistingProgrammingSubmitModel
  ): Observable<MistingProgrammingModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/climate/misting`,
      misting
    );
  }

  postMistingRegulation(
    terminalVid: string,
    misting: MistingRegulationSubmitModel
  ): Observable<MistingRegulationModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/climate/misting_regulation`,
      misting
    );
  }

  getMistingRegulation(
    terminalVid: string
  ): Observable<MistingRegulationModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/climate/misting_regulation`
    );
  }

  getHafFansProgramming(
    terminalVid: string
  ): Observable<HafFansProgrammingModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/climate/haf_fans`
    );
  }

  postHafFansProgramming(
    terminalVid: string,
    hafFans: HafFansProgrammingSubmitModel
  ): Observable<HafFansProgrammingModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/climate/haf_fans`,
      hafFans
    );
  }

  getHafFansAdjusting(terminalVid: string): Observable<HafFansAdjustingModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/climate/haf_fans_adjusting`
    );
  }

  postHafFansAdjusting(
    terminalVid: string,
    hafFans: HafFansAdjustingSubmitModel
  ): Observable<HafFansAdjustingModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/climate/haf_fans_adjusting`,
      hafFans
    );
  }

  getCurtainEnergy(terminalVid: string): Observable<CurtainEnergyModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/climate/mixed_curtains_energy`
    );
  }

  getAlarmsZone(terminalVid: string): Observable<AlarmsZoneModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/climate/alarms_zone`
    );
  }

  postAlarmsZone(
    terminalVid: string,
    alarms: AlarmsZoneSubmitModel
  ): Observable<AlarmsZoneModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/climate/alarms_zone`,
      alarms
    );
  }

  getAlarmsFrost(terminalVid: string): Observable<FrostAlarmModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/climate/alarms_frost`
    );
  }

  postAlarmsFrost(
    terminalVid: string,
    alarms: FrostAlarmModel
  ): Observable<FrostAlarmModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/climate/alarms_frost`,
      alarms
    );
  }

  getAlarmsStorm(terminalVid: string): Observable<StormAlarmModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/climate/alarms_storm`
    );
  }

  postAlarmsStorm(
    terminalVid: string,
    alarms: AlarmsStormSubmitModel
  ): Observable<StormAlarmLevelModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/climate/alarms_storm`,
      alarms
    );
  }

  getAlarmsRain(terminalVid: string): Observable<RainAlarmModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/climate/alarms_rain`
    );
  }

  postAlarmsRain(
    terminalVid: string,
    alarms: RainAlarmModel
  ): Observable<RainAlarmModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/climate/alarms_rain`,
      alarms
    );
  }

  getCO2(terminalVid: string): Observable<CO2Model> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/climate/co2`
    );
  }

  postCO2(terminalVid: string, co2: CO2SubmitModel): Observable<CO2Model> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/climate/co2`,
      co2
    );
  }

  postCurtainEnergy(
    terminalVid: string,
    curtainEnergy: CurtainEnergySubmitModel
  ): Observable<CurtainEnergyModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/climate/mixed_curtains_energy`,
      curtainEnergy
    );
  }

  getCurtainShade(terminalVid: string): Observable<CurtainShadeModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/climate/mixed_curtains_shading`
    );
  }

  postCurtainShade(
    terminalVid: string,
    curtainShade: CurtainShadeSubmitModel
  ): Observable<CurtainShadeModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/climate/mixed_curtains_shading`,
      curtainShade
    );
  }

  getCurtainRegulation(
    terminalVid: string
  ): Observable<CurtainRegulationModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/climate/mixed_curtains_regulation`
    );
  }

  postCurtainRegulation(
    terminalVid: string,
    curtainRegulation: CurtainRegulationSubmitModel
  ): Observable<CurtainRegulationModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/climate/mixed_curtains_regulation`,
      curtainRegulation
    );
  }

  getCurtainGapping(terminalVid: string): Observable<CurtainGappingModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/climate/mixed_curtains_gapping`
    );
  }

  postCurtainGapping(
    terminalVid: string,
    curtainGapping: CurtainGappingSubmitModel
  ): Observable<CurtainGappingModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/climate/mixed_curtains_gapping`,
      curtainGapping
    );
  }

  getDarkeningCurtain(terminalVid: string): Observable<CurtainDarkeningModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/climate/darkening_curtains`
    );
  }

  postDarkeningCurtain(
    terminalVid: string,
    curtainDarkening: CurtainDarkeningSubmitModel
  ): Observable<CurtainDarkeningModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/climate/darkening_curtains`,
      curtainDarkening
    );
  }

  getShadingCurtain(terminalVid: string): Observable<ShadingCurtainModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/climate/shading_curtains`
    );
  }

  postShadingCurtain(
    terminalVid: string,
    shadingCurtain: ShadingCurtainSubmitModel
  ): Observable<ShadingCurtainModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/climate/shading_curtains`,
      shadingCurtain
    );
  }

  getSwitchingProgram(terminalVid: string): Observable<SwitchingProgramModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/climate/switching_programs`
    );
  }

  postSwitchingProgram(
    terminalVid: string,
    switchingProgram: SwitchingProgramSubmitModel
  ): Observable<SwitchingProgramModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/climate/switching_programs`,
      switchingProgram
    );
  }

  postPrograms(
    terminalVid: string,
    programs: ProgramModel[]
  ): Observable<ProgramModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/irrigation/programs`,
      programs
    );
  }

  calculateEquipmentET0(
    terminalVid: string,
    calculateET0Request: WeatherCalculateET0Request
  ): Observable<ET0Model> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/nuve/et0`,
      calculateET0Request
    );
  }

  calculateEquipmentET0Forecast(
    terminalVid: string,
    calculateET0Request: WeatherCalculateET0Request
  ): Observable<ET0Model> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/nuve/et0_forecast`,
      calculateET0Request
    );
  }

  getIrrigationEquipment(
    terminalVid: string
  ): Observable<IrrigationEquipmentModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/nuve/equipment`
    );
  }

  getIrrigationEquipmentWithWaterSystems(
    terminalVid: string
  ): Observable<IrrigationEquipmentModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/nuve/equipment?q=watersystems-status`
    );
  }

  getClimateEquipment(terminalVid: string): Observable<ClimateEquipmentModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/nuve/equipment`
    );
  }

  postIrrigatrionClimateEquipment(
    terminalVid: string,
    info: EquipmentSubmitModel
  ): Observable<WeatherCalculateET0Request> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/nuve/equipment`,
      info
    );
  }

  getDrainageTrays(terminalVid: string): Observable<DrainageTrayModel[]> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/irrigation_installer/drainage_trays`
    );
  }

  getDrainages(terminalVid: string): Observable<DrainageModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/nuve/drainages`
    );
  }

  getDrainage(
    terminalVid: string,
    drainageId: number
  ): Observable<DrainageModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/nuve/drainages?drainage=${drainageId}`
    );
  }

  getDrainagesV4(terminalVid: string): Observable<DrainageV4Model[]> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/irrigation/drainages_v4`
    );
  }

  postDrainagesV4(
    terminalVid: string,
    groups: DrainageV4Model[]
  ): Observable<DrainageV4Model> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/irrigation/drainages_v4`,
      groups
    );
  }

  getDrainagesV3(terminalVid: string): Observable<DrainageV3Model[]> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/irrigation/drainages_v3`
    );
  }

  postDrainagesV3(
    terminalVid: string,
    groups: DrainageV3Model[]
  ): Observable<DrainageV3Model> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/irrigation/drainages_v3`,
      groups
    );
  }

  getMixtureFormulas(terminalVid: string): Observable<MixtureFormulasModel[]> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/irrigation/mixture_formulas`
    );
  }

  getMixtureFormula(
    terminalVid: string,
    mixtureId: number
  ): Observable<MixtureFormulasModel[]> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/irrigation/mixture_formulas?formula=${mixtureId}`
    );
  }

  postMixtureFormulas(
    terminalVid: string,
    formulas: MixtureFormulasModel[]
  ): Observable<MixtureFormulasModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/irrigation/mixture_formulas`,
      formulas
    );
  }

  getInputOutputs(
    terminalVid: string,
    mode: string,
    type: number
  ): Observable<InputOutputResponseModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/irrigation_installer/input_output?mode=${mode}&type=${type}`
    );
  }

  getMeteoAlarms(terminalVid: string): Observable<MeteoAlarmsModel[]> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/irrigation/meteo_alarms`
    );
  }

  postMeteoAlarms(
    terminalVid: string,
    meteoAlarms: MeteoAlarmsModel[]
  ): Observable<MeteoAlarmsModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/irrigation/meteo_alarms`,
      meteoAlarms
    );
  }

  postFertilizerFormulas(
    terminalVid: string,
    fertilizerFormulas: FertilizerFormulasModel[]
  ): Observable<FertilizerFormulasModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/irrigation/fertilizer_formulas`,
      fertilizerFormulas
    );
  }

  getIrrigationGroups(terminalVid: string): Observable<GroupsDataModel[]> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/irrigation/groups`
    );
  }
  getIrrigationGroup(
    terminalVid: string,
    groupId: number
  ): Observable<GroupsDataModel[]> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/irrigation/groups?group=${groupId}`
    );
  }
  postIrrigationGroups(
    terminalVid: string,
    groups: GroupsDataModel[]
  ): Observable<GroupsDataModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/irrigation/groups`,
      groups
    );
  }
  getValves(terminalVid: string): Observable<ValvesDataModel[]> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/valves`
    );
  }
  getSensors(terminalVid: string): Observable<SensorDataModel[]> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/sensors`
    );
  }
  putSensor(
    terminalVid: string,
    sensor: SensorDataModel
  ): Observable<SensorDataModel> {
    return this.httpRepositoryService.putRequest(
      `farming/${terminalVid}/sensors`,
      { sensor }
    );
  }
  getSensorsSubtypes(terminalVid: string): Observable<SensorDataModel[]> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/nuve/magnitudes`
    );
  }
  getPumps(terminalVid: string): Observable<PumpsDataModel[]> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/irrigation_installer/pumps`
    );
  }
  getDemands(terminalVid: string): Observable<DemandsDataModel[]> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/irrigation_installer/demands`
    );
  }
  getIrrigationPrograms(terminalVid: string): Observable<ProgramModel[]> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/irrigation/programs`
    );
  }
  getActiveIrrigationPrograms(terminalVid: string): Observable<ProgramModel[]> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/irrigation/programs?active=1`
    );
  }
  getIrrigationProgram(
    terminalVid: string,
    programId: number
  ): Observable<ProgramModel[]> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/irrigation/programs?program=${programId}`
    );
  }
  postIrrigationPrograms(
    terminalVid: string,
    programs: ProgramModel[]
  ): Observable<ProgramModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/irrigation/programs`,
      programs
    );
  }
  postManualActionsIrrigationProgram(
    terminalVid: string,
    payload: object
  ): Observable<ProgramModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/manual_actions/irrigation`,
      payload
    );
  }

  /********************************
   ******* CLIMATE INSTALLER ******
   ********************************/

  getInstallerPipes(terminalVid: string): Observable<InstallerPipeModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/climate_installer/pipes`
    );
  }

  postInstallerPipes(
    terminalVid: string,
    co2: InstallerPipesSubmitModel
  ): Observable<InstallerPipeModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/climate_installer/pipes`,
      co2
    );
  }

  getInstallerMisting(terminalVid: string): Observable<InstallerMistingModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/climate_installer/misting`
    );
  }

  postInstallerMisting(
    terminalVid: string,
    misting: InstallerMistingSubmitModel
  ): Observable<InstallerMistingModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/climate_installer/misting`,
      misting
    );
  }

  getInstallerMixedCurtains(
    terminalVid: string
  ): Observable<InstallerMixedCurtainsModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/climate_installer/mixed_curtains`
    );
  }

  postInstallerMixedCurtains(
    terminalVid: string,
    mixedCurtains: InstallerMixedCurtainsSubmitModel
  ): Observable<InstallerMixedCurtainsModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/climate_installer/mixed_curtains`,
      mixedCurtains
    );
  }

  getInstallerDarkeningCurtains(
    terminalVid: string
  ): Observable<InstallerDarkeningCurtainsModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/climate_installer/darkening_curtains`
    );
  }

  postInstallerDarkeningCurtains(
    terminalVid: string,
    darkeningCurtains: InstallerDarkeningCurtainsSubmitModel
  ): Observable<InstallerMixedCurtainsModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/climate_installer/darkening_curtains`,
      darkeningCurtains
    );
  }

  getInstallerShadingCurtains(
    terminalVid: string
  ): Observable<InstallerShadingCurtainsModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/climate_installer/shading_curtains`
    );
  }

  postInstallerShadingCurtains(
    terminalVid: string,
    shadingCurtains: InstallerShadingCurtainsSubmitModel
  ): Observable<InstallerShadingCurtainsModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/climate_installer/shading_curtains`,
      shadingCurtains
    );
  }

  getInstallerZones(terminalVid: string): Observable<InstallerZoneModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/climate_installer/zones`
    );
  }

  postInstallerZones(
    terminalVid: string,
    zones: InstallerZonesSubmitModel
  ): Observable<InstallerZoneModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/climate_installer/zones`,
      zones
    );
  }

  getInstallerVents(terminalVid: string): Observable<InstallerVentsModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/climate_installer/vents`
    );
  }

  postInstallerVents(
    terminalVid: string,
    vents: InstallerVentsSubmitModel
  ): Observable<InstallerVentsModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/climate_installer/vents`,
      vents
    );
  }

  getInstallerCO2(terminalVid: string): Observable<InstallerCO2Model> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/climate_installer/co2`
    );
  }

  postInstallerCO2(
    terminalVid: string,
    co2: InstallerCO2SubmitModel
  ): Observable<InstallerCO2Model> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/climate_installer/co2`,
      co2
    );
  }

  /***********************************
   ******* IRRIGATION INSTALLER ******
   **********************************/
  getIrrigationInstallerValves(
    terminalVid: string
  ): Observable<ValvesIrrigationInstallerModel[]> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/irrigation_installer/valves`
    );
  }

  getIrrigationInstallerValve(
    terminalVid: string,
    valveId: number
  ): Observable<ValvesIrrigationInstallerModel[]> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/irrigation_installer/valves?valve=${valveId}`
    );
  }

  postIrrigationInstallerValves(
    terminalVid: string,
    valves: ValvesIrrigationInstallerModel[]
  ): Observable<ValvesIrrigationInstallerModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/irrigation_installer/valves`,
      valves
    );
  }

  getIrrigationInstallerDrainageTrays(
    terminalVid: string
  ): Observable<DrainageTraysIrrigationInstallerModel[]> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/irrigation_installer/drainage_trays`
    );
  }

  getIrrigationInstallerDrainageTray(
    terminalVid: string,
    trayId: number
  ): Observable<DrainageTraysIrrigationInstallerModel[]> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/irrigation_installer/drainage_trays?tray=${trayId}`
    );
  }

  postIrrigationInstallerDrainageTrays(
    terminalVid: string,
    drainage_trays: DrainageTraysIrrigationInstallerModel[]
  ): Observable<DrainageTraysIrrigationInstallerModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/irrigation_installer/drainage_trays`,
      drainage_trays
    );
  }

  getIrrigationInstallerDemands(
    terminalVid: string
  ): Observable<DemandsIrrigationInstallerModel[]> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/irrigation_installer/demands`
    );
  }

  getIrrigationInstallerDemand(
    terminalVid: string,
    demandId: number
  ): Observable<DemandsIrrigationInstallerModel[]> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/irrigation_installer/demands?demand=${demandId}`
    );
  }

  postIrrigationInstallerDemands(
    terminalVid: string,
    demands: DemandsIrrigationInstallerModel[]
  ): Observable<DemandsIrrigationInstallerModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/irrigation_installer/demands`,
      demands
    );
  }

  getIrrigationInstallerPHCERearmAlarms(
    terminalVid: string
  ): Observable<PhCeRearmAlarmsIrrigationInstallerModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/irrigation_installer/ph_ce_rearm_alarm`
    );
  }

  postIrrigationInstallerPHCERearmAlarms(
    terminalVid: string,
    ph_ce_rearm_alarms: PhCeRearmAlarmsIrrigationInstallerModel
  ): Observable<PhCeRearmAlarmsIrrigationInstallerModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/irrigation_installer/ph_ce_rearm_alarm`,
      ph_ce_rearm_alarms
    );
  }

  getIrrigationInstallerPump(
    terminalVid: string,
    pumpId
  ): Observable<PumpsIrrigationInstallerModel[]> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/irrigation_installer/pumps?pump=${pumpId}`
    );
  }

  getIrrigationInstallerPumps(
    terminalVid: string
  ): Observable<PumpsIrrigationInstallerModel[]> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/irrigation_installer/pumps`
    );
  }

  postIrrigationInstallerPumps(
    terminalVid: string,
    pumps: PumpsIrrigationInstallerModel[]
  ): Observable<PumpsIrrigationInstallerModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/irrigation_installer/pumps`,
      pumps
    );
  }

  getIrrigationInstallerMixtureValves(
    terminalVid: string
  ): Observable<MixtureValvesIrrigationInstallerModel[]> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/irrigation_installer/mixture_valves`
    );
  }

  getIrrigationInstallerMixtureValve(
    terminalVid: string,
    mixtureValveId
  ): Observable<MixtureValvesIrrigationInstallerModel[]> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/irrigation_installer/mixture_valves?mixtureValve=${mixtureValveId}`
    );
  }

  postIrrigationInstallerMixtureValves(
    terminalVid: string,
    mixture_valves: MixtureValvesIrrigationInstallerModel[]
  ): Observable<MixtureValvesIrrigationInstallerModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/irrigation_installer/mixture_valves`,
      mixture_valves
    );
  }

  getIrrigationInstallerWaterSystems(
    terminalVid: string
  ): Observable<WaterSystemIrrigationInstallerModel[]> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/irrigation_installer/water_systems`
    );
  }

  postIrrigationInstallerWaterSystems(
    terminalVid: string,
    waterSystem: WaterSystemIrrigationInstallerModel[]
  ): Observable<WaterSystemIrrigationInstallerModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/irrigation_installer/water_systems`,
      waterSystem
    );
  }

  getIrrigationInstallerFilters(
    terminalVid: string
  ): Observable<FiltersIrrigationInstallerModel[]> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/irrigation_installer/filters`
    );
  }

  getIrrigationInstallerFilter(
    terminalVid: string,
    filterId: number
  ): Observable<FiltersIrrigationInstallerModel[]> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/irrigation_installer/filters?filter=${filterId}`
    );
  }

  postIrrigationInstallerFilters(
    terminalVid: string,
    filters: FiltersIrrigationInstallerModel[]
  ): Observable<FiltersIrrigationInstallerModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/irrigation_installer/filters`,
      filters
    );
  }

  getIrrigationInstallerMiscellaneous(
    terminalVid: string
  ): Observable<MiscellaneousIrrigationInstallerModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/irrigation_installer/miscellaneous`
    );
  }

  postIrrigationInstallerMiscellaneous(
    terminalVid: string,
    miscellaneous: MiscellaneousIrrigationInstallerModel
  ): Observable<MiscellaneousIrrigationInstallerModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/irrigation_installer/miscellaneous`,
      miscellaneous
    );
  }

  getIrrigationInstallerFertilizers(
    terminalVid: string
  ): Observable<FertilizersIrrigationInstallerModel[]> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/irrigation_installer/fertilizers`
    );
  }

  postIrrigationInstallerFertilizers(
    terminalVid: string,
    fertilizers: FertilizersIrrigationInstallerModel[]
  ): Observable<FertilizersIrrigationInstallerModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/irrigation_installer/fertilizers`,
      fertilizers
    );
  }

  getIrrigationInstallerAgitators(
    terminalVid: string
  ): Observable<AgitatorsIrrigationInstallerModel[]> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/irrigation_installer/agitators`
    );
  }

  postIrrigationInstallerAgitators(
    terminalVid: string,
    agitators: AgitatorsIrrigationInstallerModel[]
  ): Observable<AgitatorsIrrigationInstallerModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/irrigation_installer/agitators`,
      agitators
    );
  }

  getIrrigationInstallerFertilizersNuve(
    terminalVid: string
  ): Observable<FertilizersNuveIrrigationModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/nuve/water_systems_fertilizers_agitators`
    );
  }

  postIrrigationInstallerFertilizersNuve(
    terminalVid: string,
    payload: FertilizersNuveIrrigationModel
  ): Observable<FertilizersNuveIrrigationModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/nuve/water_systems_fertilizers_agitators`,
      payload
    );
  }
  getIrrigationInstallerIOReport(
    terminalVid: string
  ): Observable<IOReportIrrigationInstallerModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/irrigation_installer/input_output`
    );
  }
  public getIrrigationValves(vid): Observable<[TerminalValvesDataModel]> {
    return this.httpRepositoryService.getRequest(
      'farming/' + vid + '/valves/management'
    );
  }
  public putIrrigationValves(vid, data): Observable<[TerminalValvesDataModel]> {
    return this.httpRepositoryService.putRequest(
      'farming/' + vid + '/valves/management',
      data
    );
  }
  public getTerminalIrrigation(vid): Observable<TerminalDataModel> {
    return this.httpRepositoryService.getRequest('terminals/' + vid);
  }
  public getTerminalClimate(vid): Observable<TerminalDataModel> {
    return this.httpRepositoryService.getRequest('terminals/' + vid);
  }
  public getTerminal(vid): Observable<TerminalDataModel> {
    return this.httpRepositoryService.getRequest('terminals/' + vid);
  }
  public getLayout(): Observable<LayoutDataModel> {
    return this.httpRepositoryService.getRequest('users/layout');
  }
  getDesktopClimateStates(
    terminalVid: string
  ): Observable<DesktopClimateModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/climate/states`
    );
  }

  getNewDesktopClimateStates(
    terminalVid: string
  ): Observable<NewDesktopClimateModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/climate/states`
    );
  }

  getNewDesktopClimateStructureStates(
    terminalVid: string
  ): Observable<DesktopClimateStatesStructureModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/nuve/climateStatesStructure`
    );
  }

  public postNewDesktopClimateStructureStates(
    terminalVid: string,
    statesStructure: DesktopClimateStatesStructureModel
  ): Observable<DesktopClimateStatesStructureModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/nuve/climateStatesStructure`,
      statesStructure
    );
  }

  /**
   * Reports
   */
  public getIrrigationReport(
    data,
    begin: string,
    end: string,
    user: string
  ): Observable<MixedReportResponseModel> {
    let user_str = '';
    if (user !== null) {
      user_str = '&user=' + user;
    }
    return this.httpRepositoryService.postRequest(
      `reports/irrigations?begin=${encodeURI(begin)}&end=${encodeURI(
        end
      )}${user_str}`,
      data
    );
  }
  public getConsumptionsProgramReport(
    data,
    begin: string,
    end: string,
    user: string
  ): Observable<MixedReportResponseModel> {
    let user_str = '';
    if (user !== null) {
      user_str = '&user=' + user;
    }
    return this.httpRepositoryService.postRequest(
      `reports/consumption/programs?begin=${encodeURI(begin)}&end=${encodeURI(
        end
      )}${user_str}`,
      data
    );
  }
  public getConsumptionsGroupsReport(
    data,
    begin: string,
    end: string,
    user: string
  ): Observable<MixedReportResponseModel> {
    let user_str = '';
    if (user !== null) {
      user_str = '&user=' + user;
    }
    return this.httpRepositoryService.postRequest(
      `reports/consumption/groups?begin=${encodeURI(begin)}&end=${encodeURI(
        end
      )}${user_str}`,
      data
    );
  }
  public getDrainageReport(
    data,
    begin: string,
    end: string,
    user: string
  ): Observable<MixedReportResponseModel> {
    let user_str = '';
    if (user !== null) {
      user_str = '&user=' + user;
    }
    return this.httpRepositoryService.postRequest(
      `reports/drainages?begin=${encodeURI(begin)}&end=${encodeURI(
        end
      )}${user_str}`,
      data
    );
  }
  public getAlarmsReport(
    data,
    begin: string,
    end: string,
    user: string
  ): Observable<MixedReportResponseModel> {
    let user_str = '';
    if (user !== null) {
      user_str = '&user=' + user;
    }
    return this.httpRepositoryService.postRequest(
      `reports/alarms?begin=${encodeURI(begin)}&end=${encodeURI(
        end
      )}${user_str}`,
      data
    );
  }
  public getReportTasks(): Observable<ReportTaskModel[]> {
    return this.httpRepositoryService.getRequest(`reports/report-tasks`);
  }
  public getReportTasksAvailableCount(): Observable<ReportTaskAvailableCountModel> {
    return this.httpRepositoryService.getRequest(
      `reports/report-tasks/available-count`
    );
  }
  public postReportTask(data: ReportTaskModel): Observable<ReportTaskModel> {
    return this.httpRepositoryService.postRequest('reports/report-tasks', data);
  }
  public postReportTaskAvailablePermissions(
    reportTaskVid: string,
    data: ReportTaskAvailablePermissionsRequestModel
  ): Observable<ReportTaskAvailablePermissionsResponseModel> {
    return this.httpRepositoryService.postRequest(
      `reports/report-tasks/${reportTaskVid}/available-permissions`,
      data
    );
  }
  public putReportTaskAvailablePermissions(
    reportTaskVid: string,
    data: ReportTaskModel
  ): Observable<ReportTaskModel> {
    return this.httpRepositoryService.putRequest(
      `reports/report-tasks/${reportTaskVid}`,
      data
    );
  }

  getConfigurableEquipment(
    terminalVid: string
  ): Observable<ConfigurableEquipmentModel> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/nuve/equipment`
    );
  }

  getConfigurablePrograms(
    terminalVid: string,
    programId: number | null = null,
    active: boolean | null = null
  ): Observable<ConfigurableProgramModel[]> {
    let url = `farming/${terminalVid}/configurable/programs`;
    const params = [];
    if (programId !== null) params.push(`program=${programId.toString()}`);
    if (active !== null) params.push(`active=1`);
    if (params.length > 0) url += '?' + params.join('&');
    return this.httpRepositoryService.getRequest(url);
  }

  postConfigurablePrograms(
    terminalVid: string,
    programs: ConfigurableProgramModel[]
  ): Observable<ConfigurableProgramModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/configurable/programs`,
      programs
    );
  }

  postManualActionsConfigurableProgram(
    terminalVid: string,
    payload: object
  ): Observable<ConfigurableProgramModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/manual_actions/configurable`,
      payload
    );
  }

  postPairingAttachToDemo(): Observable<AttachDataModel> {
    return this.httpRepositoryService.postRequest('pairing/attach-to-demo', {});
  }

  deletePairingDetachFromDemo(): Observable<DetachDataModel> {
    return this.httpRepositoryService.deleteRequest('pairing/detach-from-demo');
  }

  postCancelAlarmsIrrigation(
    terminalVid: string,
    data: CancelAlarmsModel
  ): Observable<CancelAlarmsModel> {
    return this.httpRepositoryService.postRequest(
      `farming/${terminalVid}/manual_actions/irrigation`,
      data
    );
  }
  getDevices(terminalVid: string): Observable<any> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/devices`
    );
  }

  //ASESOR DE RIEGO
  public getModules(): Observable<ModuleDataModel[]> {
    return this.httpRepositoryService.getRequest('modules');
  }
  public getModulesByVid(vid): Observable<ModuleDataModel> {
    return this.httpRepositoryService.getRequest('modules/' + vid);
  }
  public getModuleDataByVid(vid, data): Observable<ModuleDataDataModel> {
    return this.httpRepositoryService.getRequest(
      'modules/' +
        vid +
        '/data?starting_at=' +
        data.start +
        '&ending_at=' +
        data.end
    );
  }
  public getModulesByTerminalVid(termVid): Observable<ModuleDataModel[]> {
    return this.httpRepositoryService.getRequest(
      'modules/find-by-terminal/' + termVid
    );
  }

  // Notification preferences
  public getNotificationPreferences(): Observable<NotificationPreferencesModel> {
    return this.httpRepositoryService.getRequest('notifications/preferences');
  }

  public putUserNotificationPreferences(): Observable<UserNotificationPreferencesModel> {
    return this.httpRepositoryService.putRequest(
      'notifications/preferences/user',
      {}
    );
  }

  public putUserDeviceNotificationPreferences(
    deviceId: string
  ): Observable<UserDeviceNotificationPreferencesModel> {
    return this.httpRepositoryService.putRequest(
      `notifications/preferences/device/${deviceId}`,
      {}
    );
  }

  public putUserTerminalNotificationPreferences(
    terminalVid: string
  ): Observable<UserTerminalNotificationPreferencesModel> {
    return this.httpRepositoryService.putRequest(
      `notifications/preferences/terminal/${terminalVid}`,
      {}
    );
  }

  // RPS User Identity
  public getRPSUserIdentitiesByUserVid(
    userVid: string
  ): Observable<RPSUserIdentityDataModel[]> {
    return this.httpRepositoryService.getRequest(
      'common/rps-user-identities/' + userVid
    );
  }

  //TERMINAL TEMPLATES

  public getTerminalSpecificTemplates(
    terminalVid: string
  ): Observable<SpecificTemplateKeys> {
    return this.httpRepositoryService.getRequest(
      `terminals/${terminalVid}/specific-templates-configuration`
    );
  }
  public getTerminalAvailableTemplates(
    terminalVid: string
  ): Observable<[MasterTemplate]> {
    return this.httpRepositoryService.getRequest(
      `terminals/${terminalVid}/available-master-templates-configuration`
    );
  }
  public postAttachMasterTemplate(
    terminalVid,
    templateId,
    screenName
  ): Observable<TerminalDataModel> {
    return this.httpRepositoryService.postRequest(
      `terminals/${terminalVid}/attach-master-templates-configuration/${templateId}/to-screen/${screenName}`,
      []
    );
  }
  public deleteSpecificTemplate(
    terminalVid,
    specificTemplateVid
  ): Observable<TerminalDataModel> {
    return this.httpRepositoryService.deleteRequest(
      `terminals/${terminalVid}/specific-templates-configuration/${specificTemplateVid}`
    );
  }
  public getNotifications(
    startingDate: string,
    endingDate: string,
    userTimeZone: string,
    data
  ): Observable<NotificationDataModel[]> {
    return this.httpRepositoryService.postRequest(
      `notifications/push?starting_date=${startingDate}&ending_date=${endingDate}&tz=${userTimeZone}`,
      data
    );
  }
}
