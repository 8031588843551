import {
  AfterViewInit,
  Component,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Breadcrumb } from '../../shared/shared.model';
import { ActivatedRoute, Router } from '@angular/router';
import { IrrigationService } from '../../irrigation.service';
import { FieldsService } from '../../../../fields/fields.service';
import { HelpersLibService } from '../../../../services/libraries/helpers-lib.service';
import { GroupsDataModel, SafeData } from '../../irrigation.model';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import {
  TranslationsLibService,
  TerminalDataModel,
} from '@nutricontrol/app360-shared';
import { IonContent } from '@ionic/angular';

@Component({
  selector: 'app-groups-edit',
  templateUrl: './groups-edit.component.html',
  styleUrls: ['./groups-edit.component.scss'],
})
export class GroupsEditComponent implements OnInit, SafeData, AfterViewInit {
  isFetchingData = false;
  groups: GroupsDataModel[];
  isFormFieldWithErrors = false;
  groupControl = new FormControl<string | GroupsDataModel>('');
  filteredOptions: Observable<GroupsDataModel[]>;
  group_id: number;
  terminal_vid: string;
  breadcrumbs: Breadcrumb[] = [];
  groupValue: string;
  isFetchingTitle = false;
  isDirty = false;
  mode = '';
  isGroupsWithoutData = false;
  terminalData: TerminalDataModel;
  group: GroupsDataModel;
  @ViewChild(IonContent, { static: false }) private content: IonContent;
  hasScrollbar = false;
  saveButtonTimeElapsed = false;

  constructor(
    private irrigationService: IrrigationService,
    private fieldsService: FieldsService,
    private route: ActivatedRoute,
    private router: Router,
    private helpersLib: HelpersLibService,
    public translationsLib: TranslationsLibService,
    private activatedRoute: ActivatedRoute
  ) {}

  /**
   * https://stackoverflow.com/questions/56882873/how-to-detect-if-ion-content-has-a-scrollbar/58579938#58579938
   * Apply CSS class based on vertical scroll (save button)
   */

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkForScrollbar();
  }

  async checkForScrollbar() {
    const scrollElement = await this.content.getScrollElement();
    this.hasScrollbar = scrollElement.scrollHeight > scrollElement.clientHeight;
  }

  isDataSaved(): boolean {
    return this.isDirty;
  }

  hasWritePermissions(): boolean {
    return this.terminalData.authed_user_can_write;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.saveButtonTimeElapsed = true;
      this.checkForScrollbar();
    }, 3000);

    this.activatedRoute.paramMap.subscribe((_) => {
      this.helpersLib.setTitle(
        '',
        `${this.translationsLib.get('irrigation_group')} ${this.group_id + 1}`,
        null
      );
    });
  }

  async ngOnInit() {
    const [, , vid, , , data] = this.router.url.split('/');
    this.terminal_vid = vid;
    this.group_id = Number(data);

    this.filteredOptions = this.groupControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const group = typeof value === 'string' ? value : value?.name;
        return group ? this._filter(group as string) : this.groups.slice();
      })
    );

    await this.getGroupsList();
    await this.generateTitleAndBreadcrumbs();
  }

  generateTitleAndBreadcrumbs(): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      this.isFetchingTitle = true;
      this.breadcrumbs = [];
      this.irrigationService.getTerminal(this.terminal_vid).subscribe((res) => {
        this.terminalData = res;
        if (res.field) {
          this.breadcrumbs.push({
            text: res.field.name,
            disabled: false,
            to: ['/field', { vid: res.field.vid }],
            active: false,
          });
        }

        this.breadcrumbs.push(
          {
            text: res.name,
            disabled: false,
            to: `/farming/${this.terminal_vid}/irrigation`,
            active: false,
          },
          {
            text: this.groupValue
              ? this.groupValue
              : `${this.translationsLib.get('irrigation_group')} ${
                  this.group_id + 1
                }`,
            disabled: false,
            active: true,
          }
        );

        this.isFetchingTitle = false;
        resolve(true);
      });
    });
  }

  private _filter(group: string): GroupsDataModel[] {
    const filterValue = group.toLowerCase();
    const self = this;

    return this.groups.filter(
      (option) =>
        option.name?.toLowerCase().includes(filterValue) ||
        (
          self.translationsLib.get('irrigation_group') +
          ' ' +
          (option.group + 1).toString()
        )
          .toLowerCase()
          .includes(filterValue)
    );
  }

  onChangePagination(event) {
    this.router.navigate([
      `/farming/${this.terminal_vid}/irrigation/groups/${event.group}`,
    ]);
  }

  autocompleteDisplayFn(group: GroupsDataModel) {
    return group
      ? group.name
        ? group.name
        : group.group
        ? this.translationsLib.get('irrigation_group') + ' ' + (group.group + 1)
        : ''
      : '';
  }

  getGroupsList(): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      this.isFetchingData = true;
      this.irrigationService
        .getIrrigationGroups(this.terminal_vid)
        .subscribe((res) => {
          this.groups = res;

          if (this.groups.length === 0) this.isGroupsWithoutData = true;

          this.isFetchingData = false;
          resolve(true);
        });
    });
  }

  updateGroupsList(groups) {
    this.groups = groups;

    this.filteredOptions = this.groupControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const group = typeof value === 'string' ? value : value?.name;
        return group ? this._filter(group as string) : this.groups.slice();
      })
    );
  }
}
