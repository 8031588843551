<ng-template [ngIf]="conditions">
  <h3
    [ngClass]="{ 'alert alert-warning': dirty }"
    style="margin-bottom: 16px !important"
  >
    {{ dirty ? translationsLib.get('pending_dirty_changes_text') : '' }}
  </h3>
</ng-template>

<ng-template [ngIf]="isClimateArr && isAnyElementDirty()">
  <!-- Only for climate -->
  <h3 [ngClass]="{ 'alert alert-warning m-3': isAnyElementDirty() }">
    {{
      isAnyElementDirty()
        ? translationsLib.get('pending_dirty_changes_text')
        : ''
    }}
  </h3>
</ng-template>

<ng-template [ngIf]="notConditions && dirty">
  <h3 [ngClass]="{ 'alert alert-warning m-3': dirty }">
    {{ translationsLib.get('pending_dirty_changes_text') }}
  </h3>
</ng-template>
