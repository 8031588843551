import {
  AfterViewInit,
  Component,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  PhCeRearmAlarmsIrrigationInstallerModel,
  SafeData,
} from '../../../irrigation.model';
import { Breadcrumb } from '../../../shared/shared.model';
import { ActivatedRoute, Router } from '@angular/router';
import { IrrigationService } from '../../../irrigation.service';
import { HelpersLibService } from '../../../../../services/libraries/helpers-lib.service';
import {
  TranslationsLibService,
  TerminalDataModel,
} from '@nutricontrol/app360-shared';
import { IonContent } from '@ionic/angular';

@Component({
  selector: 'app-ph-ce-rearm-alarm-edit',
  templateUrl: './ph-ce-rearm-alarm-edit.component.html',
  styleUrls: ['./ph-ce-rearm-alarm-edit.component.scss'],
})
export class PhCeRearmAlarmEditComponent
  implements OnInit, AfterViewInit, SafeData
{
  mode = '';
  isFetchingData = false;
  phCeRearmAlarms: PhCeRearmAlarmsIrrigationInstallerModel;
  breadcrumbs: Breadcrumb[] = [];
  isFormFieldWithErrors = false;
  isFetchingEquipmentData = false;
  ph_ce_rearm_alarm_id: number;
  phCeRearmAlarmValue: string;

  terminal_vid: string;
  terminalData: TerminalDataModel;
  isDirty = false;
  isPHCERearmAlarmWithoutData = false;
  @ViewChild(IonContent, { static: false }) private content: IonContent;
  hasScrollbar = false;
  saveButtonTimeElapsed = false;

  constructor(
    private router: Router,
    private irrigationService: IrrigationService,
    private helpersLib: HelpersLibService,
    private activatedRoute: ActivatedRoute,
    public translationsLib: TranslationsLibService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkForScrollbar();
  }

  async checkForScrollbar() {
    const scrollElement = await this.content.getScrollElement();
    this.hasScrollbar = scrollElement.scrollHeight > scrollElement.clientHeight;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.saveButtonTimeElapsed = true;
      this.checkForScrollbar();
    }, 3000);

    this.activatedRoute.paramMap.subscribe((_) => {
      this.helpersLib.setTitle(
        '',
        this.translationsLib.get('irrigation_ph_ce_configuration'),
        null
      );
    });
  }

  isDataSaved(): boolean {
    return this.isDirty;
  }

  hasWritePermissions(): boolean {
    return this.terminalData.authed_user_can_write;
  }

  ngOnInit() {
    const [, , vid, , , data] = this.router.url.split('/');
    this.terminal_vid = vid;
    this.ph_ce_rearm_alarm_id = Number(data);

    this.generateBreadcrumbs();

    this.getPhCeRearmAlarmsList();
  }

  generateBreadcrumbs() {
    this.isFetchingEquipmentData = true;
    this.breadcrumbs = [];
    this.irrigationService.getTerminal(this.terminal_vid).subscribe((res) => {
      this.terminalData = res;
      if (res.field) {
        this.breadcrumbs.push({
          text: res.field.name,
          disabled: false,
          to: ['/field', { vid: res.field.vid }],
          active: false,
        });
      }
      this.breadcrumbs.push(
        {
          text: res.name,
          disabled: false,
          to: `/farming/${this.terminal_vid}/irrigation`,
          active: false,
        },
        {
          text: this.translationsLib.get('irrigation_configuration'),
          disabled: false,
          to: `/farming/${this.terminal_vid}/irrigation/5`,
          active: false,
        },
        {
          text: this.phCeRearmAlarmValue
            ? this.phCeRearmAlarmValue
            : this.translationsLib.get('irrigation_ph_ce_configuration'),
          disabled: false,
          active: true,
        }
      );

      this.isFetchingEquipmentData = false;
    });
  }

  getPhCeRearmAlarmsList() {
    this.isFetchingData = true;

    this.irrigationService
      .getIrrigationInstallerPHCERearmAlarms(this.terminal_vid)
      .subscribe((res) => {
        this.phCeRearmAlarms = res;

        if (Object.keys(this.phCeRearmAlarms).length === 0)
          this.isPHCERearmAlarmWithoutData = true;

        this.isFetchingData = false;
      });
  }
}
