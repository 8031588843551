<app-main-header [breadcrumbs]="breadcrumbs"></app-main-header>

<ion-content class="configurable-programs">
  <div *ngIf="isLoading; else notLoading">
    <div class="d-flex justify-content-center align-items-center h-75">
      <div class="spinner-border text-warning" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>

  <ng-template #notLoading>
    <div class="container-fluid custom-container-fluid my-2">
      <div class="row justify-content-between align-items-center">
        <div class="col-12">
          <div
            class="row justify-content-end align-items-center"
            [ngClass]="{ 'gap-3': !isConnected }"
          >
            <app-generic-header
              *ngIf="programs && program"
              [elements]="programs"
              [image]="'assets/img/Icons/icono_programa.svg'"
              [text]="'name'"
              [hasButtons]="terminalData?.authed_user_can_write"
              [selected]="program"
              [elementSearchId]="'program'"
              [placeholder]="translationsLib.get('configurable_change_program')"
              [label]="translationsLib.get('irrigation_program')"
              (changeSelected)="onChangePagination($event)"
            >
              <div irrigation>
                <div class="row justify-content-end">
                  <ng-container *ngIf="terminalData?.authed_user_can_write">
                    <div class="col-auto p-0">
                      <ion-button
                        color="success"
                        (click)="manualAction('START')"
                        [matTooltip]="
                          !isConnected
                            ? translationsLib.get(
                                'configurable_equipment_disconnected'
                              )
                            : null
                        "
                      >
                        {{
                          translationsLib.get(
                            'configurable_program_manual_start'
                          )
                        }}
                        <ion-icon
                          [name]="'PLAY' | sensorIcon : '' : '' : true"
                          class="ms-2"
                        ></ion-icon>
                      </ion-button>
                      <div
                        class="custom-badge"
                        [matBadge]="!isConnected ? '!' : null"
                      ></div>
                    </div>
                    <div class="col-auto p-0">
                      <ion-button
                        color="danger"
                        (click)="manualAction('STOP')"
                        [matTooltip]="
                          !isConnected
                            ? translationsLib.get(
                                'configurable_equipment_disconnected'
                              )
                            : null
                        "
                      >
                        {{
                          translationsLib.get(
                            'configurable_program_manual_stop'
                          )
                        }}
                        <ion-icon
                          [name]="'STOP' | sensorIcon : '' : '' : true"
                          class="ms-2"
                        ></ion-icon>
                      </ion-button>
                      <div
                        class="custom-badge"
                        [matBadge]="!isConnected ? '!' : null"
                      ></div>
                    </div>
                  </ng-container>
                  <div class="col-auto ps-0 dots">
                    <app-mode
                      [activationButtons]="terminalData?.authed_user_can_write"
                      [currentScreen]="'programas'"
                      (changeEventEmitter)="mode = $event"
                      [allowEditionButton]="false"
                    ></app-mode>
                  </div>
                </div>
              </div>
            </app-generic-header>
          </div>
        </div>
      </div>
    </div>

    <!--    <div class="border border-light border-bottom-3"></div>-->

    <div class="container-fluid custom-container-fluid">
      <div class="row">
        <app-dirty-message
          [conditions]="!isLoading && programs"
          [dirty]="programForm.value.dirty"
        ></app-dirty-message>
      </div>

      <form [formGroup]="programForm" class="advanced-form mb-4">
        <div class="mb-2">
          <!-- La cabecera es igual en modo simple y advanced -->
          <div class="card mb-4">
            <div class="card-body custom-card-body">
              <div class="row">
                <div class="col-12 col-md-12 col-lg-6 col-xl-3">
                  <h6 class="fs-5">
                    {{ translationsLib.get('configurable_name') }}
                  </h6>
                  <div class="input-group mb-2">
                    <input
                      type="text"
                      class="form-control program-selector-bg"
                      formControlName="name"
                      maxlength="20"
                      [placeholder]="translationsLib.get('configurable_name')"
                    />
                  </div>
                </div>
                <div class="col-3 col-md-2 col-lg-2 col-xl-1">
                  <h6 class="fs-5">
                    {{ translationsLib.get('configurable_program_active') }}
                  </h6>
                  <div class="input-group my-2 py-2">
                    <div class="form-check form-switch">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        role="switch"
                        formControlName="active"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-3 col-md-1 col-lg-1 col-xl-1">
                  <h6 class="fs-5 text-nowrap">
                    {{ translationsLib.get('field_status') }}
                  </h6>
                  <div class="input-group my-2 py-2">
                    <div class="badge"
                      *ngIf="currentProgramState()"
                      [class]="currentProgramState() | badgeClass : 'CONFIGURABLE'"
                    >
                      {{ translationsLib.get('configurable_state_' + currentProgramState()) }}
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-3 col-lg-3 col-xl-1">
                  <h6 class="fs-5">
                    {{ translationsLib.get('configurable_program_diesel') }}
                  </h6>
                  <div class="input-group my-2 py-2">
                    <div class="form-check form-switch">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        role="switch"
                        formControlName="diesel"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-3 col-lg-3 col-xl-2">
                  <h6 class="fs-5">
                    {{ translationsLib.get('configurable_program_start_time') }}
                  </h6>
                  <div class="input-group mb-2">
                    <ng-template #startTimeTimePicker>
                      <timepicker
                        appOnlyNumbers
                        [showMeridian]="false"
                        [minuteStep]="1"
                        [formControlName]="'startTime'"
                      ></timepicker>
                    </ng-template>
                    <input
                      type="text"
                      class="form-control text-center px-0"
                      [ngClass]="{
                        'ng-dirty': programForm.get('startTime').dirty
                      }"
                      [popover]="startTimeTimePicker"
                      [outsideClick]="true"
                      placement="bottom"
                      [value]="
                        programForm.get('startTime').value | inputTime : 'HH:mm'
                      "
                      (change)="
                        onChangeInputTimeWithKeyboard($event, 'startTime')
                      "
                    />
                  </div>
                </div>
                <div class="col-6 col-md-3 col-lg-3 col-xl-2">
                  <h6 class="fs-5">
                    {{ translationsLib.get('configurable_program_end_time') }}
                  </h6>
                  <div class="input-group mb-2">
                    <input
                      type="text"
                      class="form-control text-center px-0"
                      [value]="
                        programForm.get('endTime').value | inputTime : 'HH:mm'
                      "
                      readonly
                    />
                  </div>
                </div>
                <div class="col-12 col-md-12 col-lg-6 col-xl-2">
                  <app-week-days-selector
                    [form]="programForm"
                    class="configurable"
                  ></app-week-days-selector>
                </div>
              </div>
            </div>
          </div>

          <app-configurable-groups
            [groups]="program.groups"
            [states]="states"
            (dirty)="onDirtyGroups($event)"
            [mode]="mode"
            (clearIntervalEventEmitter)="handleClearInterval($event)"
          ></app-configurable-groups>
        </div>
      </form>
    </div>
  </ng-template>
</ion-content>

<footer
  class="footer global-save-footer mt-auto px-4 py-3 bg-light-soft border-top-3 border-light border"
>
  <div *ngIf="!terminalData?.authed_user_can_write" class="row">
    <div class="col">
      <app-cannot-write-alert></app-cannot-write-alert>
    </div>
  </div>
  <div *ngIf="terminalData?.authed_user_can_write" class="row">
    <div class="col p-0" *ngIf="isProgramDirty">
      <button class="btn btn-warning" (click)="refreshDirtyProgram()">
        {{ this.translationsLib.get('configurable_dirty_refresh') }}
      </button>
    </div>
    <div class="col">
      <button
        class="btn btn-primary float-end mx-2"
        [ngClass]="{ 'btn-danger': programForm.dirty }"
        [disabled]="!programForm.dirty || isFormSubmitted"
        (click)="submitForm()"
      >
        <span
          class="spinner-border spinner-border-sm"
          *ngIf="isFormSubmitted"
          role="status"
          aria-hidden="true"
        ></span>
        {{
          isFormSubmitted
            ? this.translationsLib.get('saving')
            : this.translationsLib.get('configurable_save_program')
        }}
      </button>
    </div>
  </div>
</footer>
