import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  DemandsDataModel,
  DrainageV4Model,
  ProgramModel,
  SensorDataModel,
  Condition,
  MixtureFormulasModel,
  GroupsDataModel,
  FertilizerFormulasModel,
  Progprog,
  SafeData,
  TerminalLayoutModelIrrigation,
  IrrigationEquipmentModel,
} from '../irrigation.model';
import { IrrigationService } from '../irrigation.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { Breadcrumb } from '../shared/shared.model';
import { GroupsComponent } from '../groups/groups.component';
import {
  convertFormattedTimeToSeconds,
  DateToSecondsInput,
  removeDirtyInputs,
  saveDataAndShowModal,
  secondsInputToDate,
} from '../../../commons/helpers/functions';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { HelpersLibService } from '../../../services/libraries/helpers-lib.service';
import { FertilizerFormulasFormComponent } from '../fertilizer-formulas/fertilizer-formulas-form/fertilizer-formulas-form.component';
import {
  TranslationsLibService,
  TerminalDataModel,
} from '@nutricontrol/app360-shared';
import { MixtureFormulasFormComponent } from '../mixture-formulas/mixture-formulas-form/mixture-formulas-form.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { EquipmentsLibService } from '../../../services/libraries/equipments-lib.service';
import { GroupsFormComponent } from '../groups/groups-form/groups-form.component';
import { IonContent } from '@ionic/angular';
import { FormFieldHandlerService } from '../../libraries/form-field-handler.service';
import { environment } from '../../../../environments/environment';
import { InputNumberService } from '../../libraries/input-number.service';

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss'],
})
export class ProgramsComponent
  implements OnInit, AfterViewInit, SafeData, OnDestroy
{
  defaultProgramLayoutConfiguration = {
    layout_configuration: {
      programs_drag_drop: {
        drag_and_drop: [],
      },
    },
  };

  isFetchingTerminalData = false;

  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  @Output() formFieldErrorEventEmitter = new EventEmitter<boolean>();

  isConnected: boolean;
  isFormFieldWithErrors = false;
  private formFieldErrorSubscription: Subscription;
  private intervalId: any;

  currentLayoutConfiguration: TerminalLayoutModelIrrigation;

  isDragItemDisabled = true;
  dirtyTimepickerBegin = false;
  dirtyTimepickerEnd = false;
  dateTimeDirty = false;
  isGroupChipDirty = false;
  isDaysPickerDirty = false;

  isErrorInProgramsPost = false;

  isAnyGroupDirtyAfterPost = false;
  isAnyFertilizerFormulaDirtyAfterPost = false;
  isAnyProgramDirtyAfterPost = false;

  isFetchingData = false;
  isFormSubmitted = false;
  programForm: UntypedFormGroup;
  program: ProgramModel;
  equipment: IrrigationEquipmentModel;
  isDirty = false;
  isGroupDirty = false;
  isFormulaDirty = false;
  isMixtureDirty = false;
  program_id: number;
  terminal_vid: string;
  mode = '';
  isPutNewLayoutConfiguration = false;
  breadcrumbs: Breadcrumb[] = [];
  demandsList: DemandsDataModel[];
  mixtureFormulasList: MixtureFormulasModel[];
  terminalLayoutConfigurationResponse: any;
  fertilizerFormulasList: FertilizerFormulasModel[];
  groupsList: GroupsDataModel[];
  drainagesList: DrainageV4Model[];
  programsList: ProgramModel[];
  programsListWithoutThis: ProgramModel[];
  sensorsList: SensorDataModel[];
  programControl = new FormControl<string | ProgramModel>('');
  filteredOptions: Observable<ProgramModel[]>;
  terminalData: TerminalDataModel;

  isIntervalClearedForGroup: boolean;
  isIntervalClearedForMixtureFormulas: boolean;
  isIntervalClearedForFertilizerFormulas: boolean;

  week_monday = false;
  week_tuesday = false;
  isProgramsWithoutData = false;

  week_wednesday = false;
  week_thursday = false;
  week_friday = false;
  week_saturday = false;
  week_sunday = false;

  begin = null;
  end = null;

  conditions: Condition[] = [];
  isFetchingTerminalLayoutConfiguration = false;

  programValue: string;
  isFetchingTitle = false;

  WEEK_TYPE = {
    WEEK: 'WEEK',
    DAYS: 'DAYS',
  };
  fertilizerFormula_type: string;
  fertilizerFormulaSelected: number;

  FERTILIZER_FORMULA_TYPE = {
    FORMULA: 'FORMULA',
    GROUP: 'GROUP',
  };

  mixtureFormulaSelected: number;

  prioritiesList = [
    {
      value: 'LOWEST',
      name: this.translationsLib.get('irrigation_lowest'),
    },
    {
      value: 'LOW',
      name: this.translationsLib.get('irrigation_low'),
    },
    {
      value: 'MEDIUM',
      name: this.translationsLib.get('irrigation_medium'),
    },
    {
      value: 'HIGH',
      name: this.translationsLib.get('irrigation_high'),
    },
    {
      value: 'HIGHEST',
      name: this.translationsLib.get('irrigation_highest'),
    },
    {
      value: 'TOTAL',
      name: this.translationsLib.get('irrigation_total'),
    },
  ];

  comparisonTypesList = ['>', '<', '=', '<>'];

  isGroupsDirty = false;

  @ViewChild(GroupsComponent) groupsComponent: GroupsComponent;
  @ViewChild(FertilizerFormulasFormComponent)
  fertilizerFormulasFormComponent: FertilizerFormulasFormComponent;
  @ViewChild(MixtureFormulasFormComponent)
  mixtureFormulasFormComponent: MixtureFormulasFormComponent;
  @ViewChild(GroupsFormComponent) groupsFormComponent: GroupsFormComponent;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  groupsCtrl = new FormControl('');
  filteredGroups: Observable<GroupsDataModel[]>;
  groupsSelected: GroupsDataModel[] = [];

  @ViewChild('groupsInput') groupsInput: ElementRef<HTMLInputElement>;

  layoutConfiguration: Progprog;
  @ViewChild(IonContent, { static: false }) private content: IonContent;
  hasScrollbar = false;
  saveButtonTimeElapsed = false;

  constructor(
    private fb: UntypedFormBuilder,
    private irrigationService: IrrigationService,
    private route: ActivatedRoute,
    private router: Router,
    private helpersLib: HelpersLibService,
    public translationsLib: TranslationsLibService,
    private equipmentLib: EquipmentsLibService,
    private formFieldHandlerService: FormFieldHandlerService,
    public inputNumberService: InputNumberService
  ) {
    this.programForm = this.fb.group({
      program: [null],
      name: [''],
      dirty: [''],
      active: [null, Validators.required],
      fertilicerFormula: [null, Validators.required],
      mixtureFormula: [null, Validators.required],
      conditionsNumber: [null, Validators.required],
      schedule: [null, Validators.required],
      demand: [null, Validators.required],
      acummulatedRadiation: [null, Validators.required],
      drainage: [null, Validators.required],
      maxActivations: [null, Validators.required],
      maxRestingTime: [null, Validators.required],
      minRestingTime: [null, Validators.required],
      linkedProgram: [null, Validators.required],
      priority: [null, Validators.required],
      begin: [null, Validators.required],
      end: [null, Validators.required],
      week_days: [null, Validators.required],
      week: [null],
      days: [null],
      stopFrost: [null, Validators.required],
      stopRain: [null, Validators.required],
      stopStorm: [null, Validators.required],
      groups: [null],
      conditions: [null],
    });
  }

  /**
   * https://stackoverflow.com/questions/56882873/how-to-detect-if-ion-content-has-a-scrollbar/58579938#58579938
   * Apply CSS class based on vertical scroll (save button)
   */

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkForScrollbar();
  }

  handleClearInterval(event: boolean) {
    this.isIntervalClearedForGroup = event;
    this.isIntervalClearedForFertilizerFormulas = event;
    this.isIntervalClearedForMixtureFormulas = event;

    if (
      this.isIntervalClearedForGroup &&
      this.isIntervalClearedForFertilizerFormulas &&
      this.isIntervalClearedForMixtureFormulas
    ) {
      clearInterval(this.intervalId);
    }
  }

  async checkForScrollbar() {
    const scrollElement = await this.content.getScrollElement();
    this.hasScrollbar = scrollElement.scrollHeight > scrollElement.clientHeight;
  }

  setDragItem(event) {
    this.isDragItemDisabled = !event;
    if (!event) {
      this.irrigationService
        .putTerminalLayoutConfiguration(this.terminal_vid, {
          layout_configuration: this.terminalLayoutConfigurationResponse,
        })
        .subscribe((_) => {
          Swal.fire({
            text: this.translationsLib.get('save_changes_success'),
            showConfirmButton: true,
            confirmButtonText: this.translationsLib.get('accept'),
            icon: 'success',
          });
        });
    }
  }

  async startInterval() {
    this.intervalId = setInterval(() => {
      this.performTasks();
    }, environment.intervalDefaultTimeout);
  }

  async performTasks() {
    this.programForm.reset();

    await this.getLayoutConfiguration();
    this.getDemandsList();
    this.getMixtureFormulasList();
    this.getFertilizerFormulasList();
    this.getDrainagesList();
    this.getSensorsList();

    this.getProgram();

    removeDirtyInputs(this.programForm);
    this.setProgramDateTimeDirty(false);
    this.setGroupChipDirty(false);
    this.setDirty(false);
    this.setDaysPickerDirty(false);
    this.dirtyTimepickerBegin = false;
    this.dirtyTimepickerEnd = false;

    this.getIrrigationEquipment();

    if (!this.isConnected) {
      this.equipmentLib.showConnectivityAlert();
    }

    this.isFormSubmitted = false;
  }

  ngOnInit() {
    this.programForm.reset(); // Prevent unsaved data to persist in form inputs

    this.terminal_vid = this.route.snapshot.paramMap.get('vid');
    this.program_id = Number(this.route.snapshot.paramMap.get('program_id'));

    if (!this.terminal_vid || this.program_id === null) {
      this.router.navigate(['/home/dashboard']);
    }

    if (this.terminal_vid === null) this.router.navigate(['/home/dashboard']);

    this.route.params.subscribe(async (_) => {
      await this.getLayoutConfiguration();
      this.getIrrigationEquipment();
      this.getDemandsList();
      this.getMixtureFormulasList();
      this.getFertilizerFormulasList();
      this.getDrainagesList();
      this.getSensorsList();

      this.getProgram();

      removeDirtyInputs(this.programForm);
      this.setProgramDateTimeDirty(false);
      this.setGroupChipDirty(false);
      this.setDaysPickerDirty(false);
      this.dirtyTimepickerBegin = false;
      this.dirtyTimepickerEnd = false;
    });

    this.programForm.valueChanges.subscribe((_) => {
      if (
        this.isDirty ||
        this.programForm.dirty ||
        this.dateTimeDirty ||
        this.isGroupChipDirty ||
        this.dateTimeDirty ||
        this.isDaysPickerDirty ||
        this.isGroupsDirty
      ) {
        clearInterval(this.intervalId);
      } else {
        clearInterval(this.intervalId);
        this.startInterval();
      }
    });

    this.filteredGroups = this.groupsCtrl.valueChanges.pipe(
      startWith(null),
      map((value) => {
        // @ts-ignore
        const group = typeof value === 'string' ? value : value?.name;
        return group ? this._filterGroups(group as string) : this.groupsList;
      })
    );

    this.filteredOptions = this.programControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const program = typeof value === 'string' ? value : value?.name;
        return program
          ? this._filterProgram(program as string)
          : this.programsList.slice();
      })
    );
  }

  drop(event: CdkDragDrop<any[]>) {
    const dragAndDropArray =
      this.terminalLayoutConfigurationResponse.programs_drag_drop.drag_and_drop;
    moveItemInArray(dragAndDropArray, event.previousIndex, event.currentIndex);

    this.currentLayoutConfiguration = this.terminalLayoutConfigurationResponse;
  }

  getCardOrder(cardName: string): number {
    if (
      this.terminalLayoutConfigurationResponse.programs_drag_drop === undefined
    ) {
      this.terminalLayoutConfigurationResponse =
        this.defaultProgramLayoutConfiguration.layout_configuration;
    }

    const cardIndex =
      this.terminalLayoutConfigurationResponse.programs_drag_drop.drag_and_drop.findIndex(
        (card) => card.name === cardName
      );
    return cardIndex !== -1 ? cardIndex : 0;
  }

  onChangeInputTimeWithKeyboard(event, formInput: string) {
    const toSeconds = convertFormattedTimeToSeconds(event.target.value);
    const toDate = secondsInputToDate(toSeconds);
    this.programForm.get(formInput).setValue(toDate);

    this.programForm.markAsDirty();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.saveButtonTimeElapsed = true;
      this.checkForScrollbar();
    }, 3000);
  }

  isDataSaved(): boolean {
    return (
      this.programForm.dirty ||
      this.isGroupDirty ||
      this.isFormulaDirty ||
      this.isMixtureDirty ||
      this.dateTimeDirty ||
      this.isGroupChipDirty ||
      this.dateTimeDirty ||
      this.isDaysPickerDirty ||
      this.isDirty
    );
  }

  hasWritePermissions(): boolean {
    return this.terminalData.authed_user_can_write;
  }

  generateBreadcrumbs() {
    this.isFetchingTerminalData = true;

    this.breadcrumbs = [];
    this.irrigationService.getTerminal(this.terminal_vid).subscribe((res) => {
      this.terminalData = res;
      if (res.field) {
        this.breadcrumbs.push({
          text: res.field.name,
          disabled: false,
          to: ['/field', { vid: res.field.vid }],
          active: false,
        });
      }

      this.breadcrumbs.push(
        {
          text: res.name,
          disabled: false,
          to: `/farming/${this.terminal_vid}/irrigation`,
          active: false,
        },
        {
          text: this.programValue
            ? this.programValue
            : `${this.translationsLib.get('irrigation_program')} ${
                this.program_id + 1
              }`,
          disabled: false,
          active: true,
        }
      );

      this.isFetchingTerminalData = false;
    });
  }

  setGroupChipDirty(dirty) {
    if (dirty) clearInterval(this.intervalId);

    this.isGroupChipDirty = dirty;
  }

  private _filterGroups(group: string): GroupsDataModel[] {
    const filterValue = group.toLowerCase();
    return this.groupsList.filter((option) =>
      option.name?.toLowerCase().includes(filterValue)
    );
  }
  addGroup(event: MatChipInputEvent): void {
    this.isGroupsDirty = true;
    clearInterval(this.intervalId);

    const value = event.value || '';
    // Add
    if (value) {
      const group = this.groupsList.find(
        // @ts-ignore
        (option) => option.name?.toLowerCase().includes(value.toLowerCase())
      );
      if (group !== undefined) {
        const index = this.groupsSelected.indexOf(group);
        if (index < 0) {
          this.groupsSelected.push(group);
          this.groupsComponent.updateGroupsList(this.groupsSelected);
          this.setDirty(true);
        }
      }
    }
    // Clear the input value
    event.chipInput?.clear();

    this.groupsCtrl.setValue(null);
  }
  removeGroup(group: GroupsDataModel): void {
    this.isGroupsDirty = true;
    clearInterval(this.intervalId);

    const index = this.groupsSelected.indexOf(group);
    if (index >= 0) {
      this.groupsSelected.splice(index, 1);
      this.groupsComponent.updateGroupsList(this.groupsSelected);
      this.setDirty(true);
    }
  }
  selectedGroup(event: MatAutocompleteSelectedEvent): void {
    this.isGroupsDirty = true;
    clearInterval(this.intervalId);

    const index = this.groupsSelected.indexOf(event.option.value);
    if (index < 0) {
      this.groupsSelected.push(event.option.value);

      const lastGroupAdded =
        this.groupsSelected[this.groupsSelected.length - 1];
      this.groupsComponent.onChangePagination(lastGroupAdded.group);

      this.groupsComponent.updateGroupsList(this.groupsSelected);
      this.setDirty(true);
      this.setGroupChipDirty(true);
    }
    this.groupsInput.nativeElement.value = '';
    this.groupsCtrl.setValue(null);
  }

  onChangeFormulaType(event) {
    if (this.fertilizerFormula_type !== event.target.value) {
      this.fertilizerFormula_type = event.target.value;
      // si pasamos de formula a grupo
      if (this.fertilizerFormula_type === this.FERTILIZER_FORMULA_TYPE.GROUP) {
        this.fertilizerFormulaSelected = 254;
      }
      // si pasamos de grupo a formula
      if (
        this.fertilizerFormula_type === this.FERTILIZER_FORMULA_TYPE.FORMULA
      ) {
        this.fertilizerFormulaSelected = 255;
        /* this.fertilizerFormulaSelected = 0;
        setTimeout(() => {
          this.fertilizerFormulasFormComponent.updateFertilizerFormulaId(
            this.fertilizerFormulaSelected
          );
        }, 200); */
      }
      this.setDirty(true);
    }
  }

  onChangeFormula() {
    if (this.fertilizerFormulaSelected !== 255) {
      setTimeout(() => {
        this.fertilizerFormulasFormComponent.updateFertilizerFormulaId(
          this.fertilizerFormulaSelected
        );
      }, 200);
    }
    this.setDirty(true);
  }

  onChangeMixture(event) {
    if (this.mixtureFormulaSelected !== event.target.value) {
      this.mixtureFormulaSelected = Number(event.target.value);
      if (this.mixtureFormulaSelected !== 255) {
        setTimeout(() => {
          this.mixtureFormulasFormComponent.updateMixtureId(
            this.mixtureFormulaSelected
          );
        }, 200);
      }
      this.setDirty(true);
    }
  }

  onChangeGroupListData(groups) {
    this.groupsList = groups;

    this.isAnyGroupDirtyAfterPost = this.groupsList.some(
      (group) => group.dirty === true
    );

    this.groupsSelected = this.groupsSelected.map((value) => {
      const group = this.groupsList.find(
        // @ts-ignore
        (option) => option.group === value.group
      );
      return group !== undefined ? group : null;
    });
    this.groupsSelected = this.groupsSelected.filter((el) => el != null);
    this.groupsComponent.updateGroupsList(this.groupsSelected);
  }

  updateFertilizerFormulaSelectedValue(value) {
    setTimeout(() => {
      this.fertilizerFormulaSelected = value;
    }, 8000);
  }

  onChangeFertilizerFormulasListData(formulas) {
    this.fertilizerFormulasList = formulas;

    this.isAnyFertilizerFormulaDirtyAfterPost =
      this.fertilizerFormulasList.some((formula) => formula.dirty === true);

    const formula = this.fertilizerFormulasList.find((f) => {
      return f.formula === this.fertilizerFormulaSelected;
    });

    this.fertilizerFormulaSelected = formula.formula;
  }

  onChangeGroupDirty(event) {
    this.isGroupDirty = event;
  }

  onChangeFormulaDirty(event) {
    this.isFormulaDirty = event;
  }

  onChangeMixtureDirty(event) {
    this.isMixtureDirty = event;
  }

  setDirty(dirty) {
    this.isDirty = dirty;
  }

  setProgramDateTimeDirty(dirty) {
    this.dateTimeDirty = dirty;
  }

  setDaysPickerDirty(dirty) {
    this.isDaysPickerDirty = dirty;
  }

  changeMode(mode) {
    this.mode = mode;

    if (this.isDaysPickerDirty) {
      setTimeout(() => {
        document
          .querySelector('#week_days_week_simple')
          ?.classList.add('ng-dirty');

        document
          .querySelector('#week_days_week_advance')
          ?.classList.add('ng-dirty');
      }, 500);
    }
  }

  checkWeekDay(event) {
    this[event.target.id] = event.target.checked;
    this.setDirty(true);
    this.setDaysPickerDirty(true);

    document.querySelector('#week_days_week_simple')?.classList.add('ng-dirty');

    document
      .querySelector('#week_days_week_advance')
      ?.classList.add('ng-dirty');
  }

  changeDatetime(value, id) {
    const splitId = id.split('-')[0];

    if (splitId === 'begin') this.dirtyTimepickerBegin = true;
    if (splitId === 'end') this.dirtyTimepickerEnd = true;

    if (value !== this[id] || this[id] !== undefined)
      this.setProgramDateTimeDirty(true);

    this[id] = value;
  }

  formatWeek() {
    return {
      monday: this.week_monday,
      tuesday: this.week_tuesday,
      wednesday: this.week_wednesday,
      thursday: this.week_thursday,
      friday: this.week_friday,
      saturday: this.week_saturday,
      sunday: this.week_sunday,
    };
  }

  private _filterProgram(program: string): ProgramModel[] {
    const filterValue = program.toLowerCase();

    return this.programsList.filter(
      (option) =>
        option.name?.toLowerCase().includes(filterValue) ||
        (
          this.translationsLib.get('irrigation_program') +
          ' ' +
          (option.program + 1).toString()
        )
          .toLowerCase()
          .includes(filterValue)
    );
  }

  onChangeProgram(event) {
    this.router.navigate([
      `/farming/${this.terminal_vid}/irrigation/program/${event.program}`,
    ]);

    setTimeout(() => {
      this.autocomplete.closePanel();
    }, 1000);
  }

  getDemandsList() {
    this.irrigationService.getDemands(this.terminal_vid).subscribe((res) => {
      this.demandsList = res;
    });
  }

  getMixtureFormulasList() {
    this.irrigationService
      .getMixtureFormulas(this.terminal_vid)
      .subscribe((res) => {
        this.mixtureFormulasList = res;
      });
  }

  updateTerminalLayoutConfiguration(): Promise<boolean> {
    return new Promise<boolean>(async (resolve, _) => {
      this.isPutNewLayoutConfiguration = true;

      if (this.layoutConfiguration.blocks.program.show) {
        this.defaultProgramLayoutConfiguration.layout_configuration.programs_drag_drop.drag_and_drop.push(
          {
            name: 'fertilizer_formulas',
          }
        );

        if (this.layoutConfiguration.blocks.program.sub_blocks[0].groups.show) {
          this.defaultProgramLayoutConfiguration.layout_configuration.programs_drag_drop.drag_and_drop.push(
            {
              name: 'groups',
            }
          );
        }

        if (
          this.layoutConfiguration.blocks.program.sub_blocks[0].recipes.fields
            .progprogramam3k_recetamezcla.show
        ) {
          this.defaultProgramLayoutConfiguration.layout_configuration.programs_drag_drop.drag_and_drop.push(
            {
              name: 'mixture_formulas',
            }
          );
        }

        if (this.layoutConfiguration.blocks.activation.show) {
          this.defaultProgramLayoutConfiguration.layout_configuration.programs_drag_drop.drag_and_drop.push(
            {
              name: 'activation',
            }
          );
        }

        if (this.layoutConfiguration.blocks.irrigation_behaviour.show) {
          this.defaultProgramLayoutConfiguration.layout_configuration.programs_drag_drop.drag_and_drop.push(
            {
              name: 'irrigation_behavior',
            }
          );
        }

        if (this.layoutConfiguration.blocks.deactivation.show) {
          this.defaultProgramLayoutConfiguration.layout_configuration.programs_drag_drop.drag_and_drop.push(
            {
              name: 'deactivation',
            }
          );
        }
      }

      if (
        this.terminalLayoutConfigurationResponse.length === 0 ||
        !this.terminalLayoutConfigurationResponse
      ) {
        this.terminalLayoutConfigurationResponse = {
          layout_configuration: {
            ...this.terminalLayoutConfigurationResponse,
          },
        };
      } else {
        this.terminalLayoutConfigurationResponse = {
          layout_configuration: {
            ...this.defaultProgramLayoutConfiguration.layout_configuration,
            ...this.terminalLayoutConfigurationResponse,
          },
        };
      }

      // IMPORTANT: You need to subscribe to an event to make the put method executed
      await this.irrigationService
        .putTerminalLayoutConfiguration(
          this.terminal_vid,
          this.terminalLayoutConfigurationResponse
        )
        .subscribe((res) => {
          this.isPutNewLayoutConfiguration = false;
          this.terminalLayoutConfigurationResponse = res.layout_configuration;
        });
      resolve(true);
    });
  }

  getTerminalLayoutConfiguration(): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      this.isFetchingTerminalLayoutConfiguration = true;
      this.irrigationService
        .getTerminalLayoutConfiguration(this.terminal_vid)
        .subscribe((res) => {
          this.terminalLayoutConfigurationResponse = res;

          this.isFetchingTerminalLayoutConfiguration = false;
          resolve(true);
        });
    });
  }

  getFertilizerFormulasList() {
    this.irrigationService
      .getFertilizerFormulas(this.terminal_vid)
      .subscribe((res) => {
        this.fertilizerFormulasList = res;
      });
  }

  getDrainagesList() {
    this.irrigationService
      .getDrainagesV4(this.terminal_vid)
      .subscribe((res) => {
        this.drainagesList = res;
      });
  }

  getSensorsList() {
    this.irrigationService.getSensors(this.terminal_vid).subscribe((res) => {
      res.forEach((sensor) => {
        sensor.id = sensor.nexp + '_' + sensor.nent + '_' + sensor.physicalType;
      });
      this.sensorsList = res;
    });
  }

  getLayoutConfiguration(): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      this.irrigationService
        .getTerminalLayoutConfiguration(this.terminal_vid)
        .subscribe(async (res) => {
          this.layoutConfiguration = res.screens_configuration.progprog;
          this.terminalLayoutConfigurationResponse = res.layout_configuration;

          if (
            !this.terminalLayoutConfigurationResponse ||
            this.terminalLayoutConfigurationResponse?.length === 0
          ) {
            await this.updateTerminalLayoutConfiguration();
          }

          if (!this.layoutConfiguration.enabled) {
            await this.router.navigate(['/home/dashboard']);
          }

          resolve(true);
        });
    });
  }

  getProgram(isGetAfterPost = false) {
    if (!isGetAfterPost) this.isFetchingData = true;
    this.isFetchingTitle = true;

    this.irrigationService.getPrograms(this.terminal_vid).subscribe((res) => {
      this.programsList = res;

      const foundProgram = res.find(
        (program) =>
          program.program === this.program_id && program.dirty === true
      );
      this.isAnyProgramDirtyAfterPost = Boolean(foundProgram);

      if (this.programsList.length === 0) {
        this.isProgramsWithoutData = true;
        this.groupsList = [];
      } else {
        this.programsListWithoutThis = res.filter(
          (el) => el.program !== this.program_id
        );

        const program = this.programsList.find(
          // @ts-ignore
          (elem) => elem.program === this.program_id
        );
        if (program === undefined) {
          this.router.navigate([`/farming/${this.terminal_vid}/irrigation`]);
        }
        this.program = program;

        this.programValue = program.name
          ? program.name
          : `${this.translationsLib.get('irrigation_program')} ${
              this.program_id + 1
            }`;
        this.generateBreadcrumbs();
        this.helpersLib.setTitle('', this.programValue, null);

        if (program.week_days === this.WEEK_TYPE.WEEK) {
          this.week_monday = program.week ? program.week.monday : null;
          this.week_tuesday = program.week ? program.week.tuesday : null;
          this.week_wednesday = program.week ? program.week.wednesday : null;
          this.week_thursday = program.week ? program.week.thursday : null;
          this.week_friday = program.week ? program.week.friday : null;
          this.week_saturday = program.week ? program.week.saturday : null;
          this.week_sunday = program.week ? program.week.sunday : null;
        }

        this.begin = moment(moment().year() + '/' + program.begin).toISOString(
          true
        );
        this.end = moment(moment().year() + '/' + program.end).toISOString(
          true
        );

        program.conditions.forEach((sensor) => {
          sensor.id =
            sensor.nexp_sensor +
            '_' +
            sensor.nent_sensor +
            '_' +
            sensor.physicalType_sensor;
          this.conditions.push(sensor);
        });

        this.conditions = program.conditions;

        this.irrigationService
          .getIrrigationGroups(this.terminal_vid)
          .subscribe((res) => {
            this.groupsList = res;
            const mappedGroups = this.program.groups.map((value) => {
              const group = this.groupsList.find(
                (option) => option.group === value.group
              );
              return group !== undefined ? group : null;
            });

            const filteredGroups = mappedGroups.filter((el) => el !== null);

            filteredGroups.sort((a, b) => {
              const orderA = this.program.groups.find(
                (group) => group.group === a.group
              );
              const orderB = this.program.groups.find(
                (group) => group.group === b.group
              );

              if (orderA && orderB) {
                return orderA.order - orderB.order;
              } else {
                return 0;
              }
            });

            this.groupsSelected = filteredGroups;
          });

        this.fertilizerFormula_type =
          program.fertilicerFormula === 254
            ? this.FERTILIZER_FORMULA_TYPE.GROUP
            : this.FERTILIZER_FORMULA_TYPE.FORMULA;

        this.fertilizerFormulaSelected = program.fertilicerFormula;

        this.mixtureFormulaSelected = program.mixtureFormula;

        this.programForm.patchValue({
          program: program.program,
          dirty: program.dirty,
          name: program.name,
          active: program.active,
          fertilicerFormula: program.fertilicerFormula,
          mixtureFormula: program.mixtureFormula,
          conditionsNumber: program.conditionsNumber,
          schedule: program.schedule,
          demand: program.demand,
          acummulatedRadiation: program.acummulatedRadiation,
          drainage: program.drainage,
          maxActivations: program.maxActivations,
          maxRestingTime: secondsInputToDate(program.maxRestingTime),
          minRestingTime: secondsInputToDate(program.minRestingTime),
          linkedProgram: program.linkedProgram,
          priority: program.priority,
          begin: program.begin,
          end: program.end,
          week_days: program.week_days,
          week: program.week,
          days: program.days,
          stopFrost: program.stopFrost,
          stopRain: program.stopRain,
          stopStorm: program.stopStorm,
          groups: program.groups,
          conditions: program.conditions,
        });
      }

      if (!isGetAfterPost) this.isFetchingData = false;
      this.isFetchingTitle = false;
    });
  }

  getIrrigationEquipment() {
    this.irrigationService
      .getIrrigationTerminal(this.terminal_vid)
      .subscribe((res) => {
        this.equipment = res;
        this.isConnected = res.connected;
      });
  }

  async submitProgram(showModal: boolean = true): Promise<boolean> {
    this.isFormSubmitted = true;

    const conditions = this.conditions.map((cond) => {
      const [nexp_sensor, nent_sensor, physicalType_sensor] =
        cond.id.split('_');
      return {
        // eslint-disable-next-line id-blacklist
        number: cond.number,
        nexp_sensor: Number(nexp_sensor),
        nent_sensor: Number(nent_sensor),
        physicalType_sensor: Number(physicalType_sensor),
        comparisonType: cond.comparisonType,
        value: cond.value,
      };
    });

    return new Promise<boolean>((resolve, reject) => {
      try {
        const formAsArray = [];
        formAsArray.push({
          program: this.programForm.value.program,
          name: this.programForm.value.name,
          active: this.programForm.value.active,
          fertilicerFormula: this.fertilizerFormulaSelected,
          mixtureFormula: this.mixtureFormulaSelected,
          conditionsNumber: this.programForm.value.conditionsNumber,
          schedule: this.programForm.value.schedule,
          demand: this.programForm.value.demand,
          acummulatedRadiation: this.programForm.value.acummulatedRadiation,
          drainage: this.programForm.value.drainage,
          maxActivations: this.programForm.value.maxActivations,
          maxRestingTime: DateToSecondsInput(
            this.programForm.value.maxRestingTime
          ),
          minRestingTime: DateToSecondsInput(
            this.programForm.value.minRestingTime
          ),
          linkedProgram: Number(this.programForm.value.linkedProgram),
          priority: this.programForm.value.priority,
          begin: moment(this.begin).format('MM/DD HH:mm:ss'),
          end: moment(this.end).format('MM/DD HH:mm:ss'),
          week_days: this.programForm.value.week_days,
          week:
            this.programForm.value.week_days === this.WEEK_TYPE.WEEK
              ? this.formatWeek()
              : null,
          days: this.programForm.value.days,
          stopFrost: this.programForm.value.stopFrost,
          stopRain: this.programForm.value.stopRain,
          stopStorm: this.programForm.value.stopStorm,
          // groups: this.programForm.value.groups,
          groups: this.groupsSelected.map((value, index) => {
            return { group: Number(value.group), order: index };
          }),
          conditions,
        });

        this.irrigationService
          .postIrrigationPrograms(this.terminal_vid, formAsArray)
          .subscribe(
            (res) => {
              saveDataAndShowModal(
                res,
                showModal,
                this.translationsLib.get('error_updating_data'),
                this.translationsLib.get('save_changes_success'),
                this.translationsLib.get('accept')
              );

              this.irrigationService
                .getPrograms(this.terminal_vid)
                .subscribe((res) => {
                  const foundProgram = res.find(
                    (program) =>
                      program.program === this.program_id &&
                      program.dirty === true
                  );
                  this.isAnyProgramDirtyAfterPost = Boolean(foundProgram);
                });

              if (res.error) this.isErrorInProgramsPost = true;

              this.isFormSubmitted = false;
              resolve(true);
            },
            (_) => {
              Swal.fire({
                text: this.translationsLib.get('something_was_wrong'),
                showConfirmButton: true,
                confirmButtonText: this.translationsLib.get('accept'),
                icon: 'error',
              });

              Object.values(this.programForm.controls).forEach((control) => {
                if (control.invalid) {
                  control.markAsDirty();
                  control.updateValueAndValidity({ onlySelf: true });
                }
              });

              this.isFormSubmitted = false;
              resolve(false);
            }
          );
      } catch (err) {
        reject(false);
      } finally {
        // Remove dirty inputs due to use standalone ngModels
        removeDirtyInputs(this.programForm);
        this.setProgramDateTimeDirty(false);
        this.setGroupChipDirty(false);

        this.programForm.markAsPristine();
        this.isDirty = false;
      }
    });
  }

  async submitForm(showModal: boolean = true) {
    this.isFormSubmitted = true;

    let isGroupSubmittedOk: {
      result: boolean;
      connected: boolean;
      error: boolean;
    } = {
      result: true,
      connected: true,
      error: false,
    };
    let isFormulaSubmittedOk: {
      result: boolean;
      connected: boolean;
      error: boolean;
    } = {
      result: true,
      connected: true,
      error: false,
    };
    let isMixtureSubmittedOk: {
      result: boolean;
      connected: boolean;
      error: boolean;
    } = {
      result: true,
      connected: true,
      error: false,
    };

    if (this.isGroupDirty) {
      isGroupSubmittedOk = await this.groupsComponent.submitGroupForm(false);
    }
    if (this.isFormulaDirty) {
      isFormulaSubmittedOk =
        await this.fertilizerFormulasFormComponent.submitForm(false);
    }
    if (this.isMixtureDirty) {
      isMixtureSubmittedOk = await this.mixtureFormulasFormComponent.submitForm(
        false
      );
    }

    const isProgramSubmittedOk = await this.submitProgram(false);

    if (
      !isFormulaSubmittedOk.result ||
      isFormulaSubmittedOk.error ||
      !isGroupSubmittedOk.result ||
      isGroupSubmittedOk.error ||
      !isMixtureSubmittedOk.result ||
      isMixtureSubmittedOk.error ||
      !isProgramSubmittedOk ||
      this.isErrorInProgramsPost
    ) {
      setTimeout(() => {
        Swal.fire({
          text: this.translationsLib.get('something_was_wrong'),
          showConfirmButton: true,
          confirmButtonText: this.translationsLib.get('accept'),
          icon: 'error',
        });
      }, 1000);
    } else {
      if (
        isFormulaSubmittedOk.connected &&
        isGroupSubmittedOk.connected &&
        isMixtureSubmittedOk.connected &&
        !this.isErrorInProgramsPost
      ) {
        setTimeout(() => {
          Swal.fire({
            text: this.translationsLib.get('save_changes_success'),
            showConfirmButton: true,
            confirmButtonText: this.translationsLib.get('accept'),
            icon: 'success',
          }).then(() => {
            setTimeout(() => {
              this.fertilizerFormulasFormComponent?.getFertilizerFormula(true);
              this.mixtureFormulasFormComponent?.getMixture(true);
              this.getProgram(true);
            }, 4000);
          });
        }, 1000);
      } else if (this.isErrorInProgramsPost) {
        Swal.fire({
          text: this.translationsLib.get('irrigation_general_program_error'),
          showConfirmButton: true,
          confirmButtonText: this.translationsLib.get('accept'),
          icon: 'error',
        });
      } else {
        setTimeout(() => {
          Swal.fire({
            text: this.translationsLib.get(
              'irrigation_disconnected_terminal_pending_changes'
            ),
            showConfirmButton: true,
            confirmButtonText: this.translationsLib.get('accept'),
            icon: 'error',
          });
        }, 1000);
      }
    }

    this.isFormSubmitted = false;
    this.isGroupsDirty = false;
  }

  manualActionActivate() {
    Swal.fire({
      title: this.translationsLib.get('cannot_be_undone1'),
      text: this.translationsLib.get('irrigation_program_activate_warning'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translationsLib.get(
        'irrigation_program_activate'
      ),
      cancelButtonText: this.translationsLib.get('cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          action: 'PROGRAM_START',
          // eslint-disable-next-line id-blacklist
          number: this.program_id,
        };
        this.irrigationService
          .postManualActionsIrrigationProgram(this.terminal_vid, payload)
          .subscribe(
            (_) => {
              Swal.fire({
                icon: 'success',
                title: this.translationsLib.get('success'),
                text: this.translationsLib.get(
                  'irrigation_program_activate_done'
                ),
                confirmButtonText: this.translationsLib.get('accept'),
              }).then((response) => {
                if (response.isConfirmed) {
                  location.reload();
                }
              });
            },
            (_) => {
              Swal.fire({
                icon: 'error',
                text: this.translationsLib.get('something_was_wrong'),
                showConfirmButton: true,
                confirmButtonText: this.translationsLib.get('accept'),
              });
            }
          );
      }
    });
  }

  manualActionStop() {
    Swal.fire({
      title: this.translationsLib.get('cannot_be_undone1'),
      text: this.translationsLib.get('irrigation_program_deactivate_warning'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translationsLib.get(
        'irrigation_program_deactivate'
      ),
      cancelButtonText: this.translationsLib.get('cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          action: 'PROGRAM_STOP',
          // eslint-disable-next-line id-blacklist
          number: this.program_id,
        };
        this.irrigationService
          .postManualActionsIrrigationProgram(this.terminal_vid, payload)
          .subscribe(
            (_) => {
              Swal.fire({
                icon: 'success',
                title: this.translationsLib.get('success'),
                text: this.translationsLib.get(
                  'irrigation_program_deactivate_done'
                ),
                confirmButtonText: this.translationsLib.get('accept'),
              }).then((response) => {
                if (response.isConfirmed) {
                  location.reload();
                }
              });
            },
            (_) => {
              Swal.fire({
                icon: 'error',
                text: this.translationsLib.get('something_was_wrong'),
                showConfirmButton: true,
                confirmButtonText: this.translationsLib.get('accept'),
              });
            }
          );
      }
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
    this.formFieldErrorSubscription?.unsubscribe();
  }

  currentProgramState() {
    return (
      this.equipment?.states?.programs?.filter(
        (program) => program.program === this.program_id
      )[0]?.state ?? 'STOPPED'
    );
  }

  currentProgramIsActive(): boolean {
    return Boolean(this.program.active);
  }
}
