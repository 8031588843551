import { Injectable } from '@angular/core';
import * as moment from 'moment/moment';

@Injectable({
  providedIn: 'root',
})
export class WaterSystemUtilsService {
  constructor() {}

  formatSeconds(seconds: number): string {
    return moment().startOf('day').add(seconds, 'seconds').format('HH:mm:ss');
  }

  convertSecondsToHHMM(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const remainingSeconds = seconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}`;
  }
}
