import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestApiService } from '../../services/api/rest-api.service';
import {
  AlarmsStormSubmitModel,
  AlarmsZoneModel,
  AlarmsZoneSubmitModel,
  ClimateEquipmentModel,
  CO2Model,
  CO2SubmitModel,
  CurtainDarkeningModel,
  CurtainDarkeningSubmitModel,
  CurtainEnergyModel,
  CurtainEnergySubmitModel,
  CurtainGappingModel,
  CurtainGappingSubmitModel,
  CurtainRegulationModel,
  CurtainRegulationSubmitModel,
  CurtainShadeModel,
  CurtainShadeSubmitModel,
  DesktopClimateModel,
  DesktopClimateStatesStructureModel,
  FanStepsModel,
  FanStepsSubmitModel,
  FrostAlarmModel,
  HafFansAdjustingModel,
  HafFansAdjustingSubmitModel,
  HafFansProgrammingModel,
  HafFansProgrammingSubmitModel,
  HeatersModel,
  HeatersSubmitModel,
  HeatingTemperatureModel,
  HeatingTemperatureSubmitModel,
  InstallerCO2Model,
  InstallerCO2SubmitModel,
  InstallerDarkeningCurtainsModel,
  InstallerDarkeningCurtainsSubmitModel,
  InstallerMistingModel,
  InstallerMistingSubmitModel,
  InstallerMixedCurtainsModel,
  InstallerMixedCurtainsSubmitModel,
  InstallerPipeModel,
  InstallerPipesSubmitModel,
  InstallerShadingCurtainsModel,
  InstallerShadingCurtainsSubmitModel,
  InstallerVentsModel,
  InstallerVentsSubmitModel,
  InstallerZoneModel,
  InstallerZonesSubmitModel,
  MistingProgrammingModel,
  MistingProgrammingSubmitModel,
  MistingRegulationModel,
  MistingRegulationSubmitModel,
  NewDesktopClimateModel,
  PipesModel,
  PipesSubmitModel,
  RainAlarmModel,
  ShadingCurtainModel,
  ShadingCurtainSubmitModel,
  StormAlarmLevelModel,
  StormAlarmModel,
  SwitchingProgramModel,
  SwitchingProgramSubmitModel,
  TerminalLayoutModeClimate,
  TerminalLayoutModelClimateSubmit,
  ThermalInversionModel,
  ThermalInversionSubmitModel,
  TransportGroupModel,
  VentilationTemperatureModel,
  VentilationTemperatureSubmitModel,
  VentModel,
  VentSubmitModel,
} from './climate.model';
import { TerminalDataModel } from '@nutricontrol/app360-shared';
import { EquipmentSubmitModel } from '../irrigation/irrigation.model';
import { WeatherCalculateET0Request } from '../../weather/weather.model';

@Injectable({
  providedIn: 'root',
})
export class ClimateService {
  private restApiService: RestApiService;

  constructor(restApiService: RestApiService) {
    this.restApiService = restApiService;
  }

  /******** DESKTOP STATES *******/

  public getDesktopClimateStates(
    terminalVid: string
  ): Observable<DesktopClimateModel> {
    return new Observable((observer) => {
      this.restApiService.getDesktopClimateStates(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public getNewDesktopClimateStates(
    terminalVid: string
  ): Observable<NewDesktopClimateModel> {
    return new Observable((observer) => {
      this.restApiService.getNewDesktopClimateStates(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postClimateTerminal(
    terminalVid: string,
    info: EquipmentSubmitModel
  ): Observable<WeatherCalculateET0Request> {
    return new Observable((observer) => {
      this.restApiService
        .postIrrigatrionClimateEquipment(terminalVid, info)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  public getNewDesktopClimateStructureStates(
    terminalVid: string
  ): Observable<DesktopClimateStatesStructureModel> {
    return new Observable((observer) => {
      this.restApiService
        .getNewDesktopClimateStructureStates(terminalVid)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  public postNewDesktopClimateStructureStates(
    terminalVid: string,
    statesStructure: DesktopClimateStatesStructureModel
  ): Observable<DesktopClimateStatesStructureModel> {
    return new Observable((observer) => {
      this.restApiService
        .postNewDesktopClimateStructureStates(terminalVid, statesStructure)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  /******** TERMINAL *******/

  public getTerminal(terminalVid: string): Observable<TerminalDataModel> {
    return new Observable((observer) => {
      this.restApiService.getTerminalClimate(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  /******** EQUIPMENT *******/

  public getClimateEquipment(
    terminalVid: string
  ): Observable<ClimateEquipmentModel> {
    return new Observable((observer) => {
      this.restApiService.getClimateEquipment(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  /******** TERMINAL LAYOUT *******/

  public getTerminalLayoutConfiguration(
    terminalVid: string
  ): Observable<TerminalLayoutModeClimate> {
    return new Observable((observer) => {
      this.restApiService.getTerminalLayoutConfiguration(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public putTerminalLayoutConfiguration(
    terminalVid: string,
    config: TerminalLayoutModelClimateSubmit
  ): Observable<TerminalLayoutModelClimateSubmit> {
    return new Observable((observer) => {
      this.restApiService
        .putTerminalLayoutConfiguration(terminalVid, config)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  /******** VENTILATION TEMPERATURE *******/

  public getClimateVentilations(
    terminalVid: string
  ): Observable<VentilationTemperatureModel> {
    return new Observable((observer) => {
      this.restApiService.getClimateVentilations(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postClimateVentilations(
    terminalVid: string,
    climateVentilations: VentilationTemperatureSubmitModel
  ): Observable<VentilationTemperatureModel> {
    return new Observable((observer) => {
      this.restApiService
        .postClimateVentilations(terminalVid, climateVentilations)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  /******** VENTS *******/

  public getVents(terminalVid: string): Observable<VentModel> {
    return new Observable((observer) => {
      this.restApiService.getVents(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postVents(
    terminalVid: string,
    vents: VentSubmitModel
  ): Observable<VentModel> {
    return new Observable((observer) => {
      this.restApiService.postVents(terminalVid, vents).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  /******** FAN STEPS *******/

  public getFanSteps(terminalVid: string): Observable<FanStepsModel> {
    return new Observable((observer) => {
      this.restApiService.getFanSteps(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postFanSteps(
    terminalVid: string,
    fanSteps: FanStepsSubmitModel
  ): Observable<FanStepsModel> {
    return new Observable((observer) => {
      this.restApiService.postFanSteps(terminalVid, fanSteps).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  /******** THERMAL INVERSION *******/

  public getThermalInversion(
    terminalVid: string
  ): Observable<ThermalInversionModel> {
    return new Observable((observer) => {
      this.restApiService.getThermalInversion(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postThermalInversion(
    terminalVid: string,
    thermalInversion: ThermalInversionSubmitModel
  ): Observable<ThermalInversionModel> {
    return new Observable((observer) => {
      this.restApiService
        .postThermalInversion(terminalVid, thermalInversion)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  /******** HEATING TEMPERATURE *******/

  public getHeatingTemperature(
    terminalVid: string
  ): Observable<HeatingTemperatureModel> {
    return new Observable((observer) => {
      this.restApiService.getHeatingTemperature(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postHeatingTemperature(
    terminalVid: string,
    heatingTemperature: HeatingTemperatureSubmitModel
  ): Observable<HeatingTemperatureModel> {
    return new Observable((observer) => {
      this.restApiService
        .postHeatingTemperature(terminalVid, heatingTemperature)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  /******** HEATERS *******/

  public getHeaters(terminalVid: string): Observable<HeatersModel> {
    return new Observable((observer) => {
      this.restApiService.getHeaters(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postHeaters(
    terminalVid: string,
    heaters: HeatersSubmitModel
  ): Observable<HeatersModel> {
    return new Observable((observer) => {
      this.restApiService.postHeaters(terminalVid, heaters).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  /******** PIPES *******/

  public getPipes(terminalVid: string): Observable<PipesModel> {
    return new Observable((observer) => {
      this.restApiService.getPipes(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postPipes(
    terminalVid: string,
    pipes: PipesSubmitModel
  ): Observable<PipesModel> {
    return new Observable((observer) => {
      this.restApiService.postPipes(terminalVid, pipes).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  /******** TRANSPORT GROUP *******/

  public getTransportGroup(
    terminalVid: string
  ): Observable<TransportGroupModel> {
    return new Observable((observer) => {
      this.restApiService.getTransportGroup(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postTransportGroup(
    terminalVid: string,
    transportGroup: TransportGroupModel
  ): Observable<TransportGroupModel> {
    return new Observable((observer) => {
      this.restApiService
        .postTransportGroup(terminalVid, transportGroup)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  /******** CURTAIN ENERGY *******/

  public getCurtainEnergy(terminalVid: string): Observable<CurtainEnergyModel> {
    return new Observable((observer) => {
      this.restApiService.getCurtainEnergy(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postCurtainEnergy(
    terminalVid: string,
    curtainEnergy: CurtainEnergySubmitModel
  ): Observable<CurtainEnergyModel> {
    return new Observable((observer) => {
      this.restApiService
        .postCurtainEnergy(terminalVid, curtainEnergy)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  /******** CURTAIN SHADE *******/

  public getCurtainShade(terminalVid: string): Observable<CurtainShadeModel> {
    return new Observable((observer) => {
      this.restApiService.getCurtainShade(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postCurtainShade(
    terminalVid: string,
    curtainShade: CurtainShadeSubmitModel
  ): Observable<CurtainShadeModel> {
    return new Observable((observer) => {
      this.restApiService.postCurtainShade(terminalVid, curtainShade).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  /******** CURTAIN REGULATION *******/

  public getCurtainRegulation(
    terminalVid: string
  ): Observable<CurtainRegulationModel> {
    return new Observable((observer) => {
      this.restApiService.getCurtainRegulation(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postCurtainRegulation(
    terminalVid: string,
    curtainRegulation: CurtainRegulationSubmitModel
  ): Observable<CurtainRegulationModel> {
    return new Observable((observer) => {
      this.restApiService
        .postCurtainRegulation(terminalVid, curtainRegulation)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  /******** CURTAIN GAPPING *******/

  public getCurtainGapping(
    terminalVid: string
  ): Observable<CurtainGappingModel> {
    return new Observable((observer) => {
      this.restApiService.getCurtainGapping(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postCurtainGapping(
    terminalVid: string,
    curtainGapping: CurtainGappingSubmitModel
  ): Observable<CurtainGappingModel> {
    return new Observable((observer) => {
      this.restApiService
        .postCurtainGapping(terminalVid, curtainGapping)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  /******** DARKENING CURTAIN *******/

  public getDarkeningCurtain(
    terminalVid: string
  ): Observable<CurtainDarkeningModel> {
    return new Observable((observer) => {
      this.restApiService.getDarkeningCurtain(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postDarkeningCurtain(
    terminalVid: string,
    curtainDarkening: CurtainDarkeningSubmitModel
  ): Observable<CurtainDarkeningModel> {
    return new Observable((observer) => {
      this.restApiService
        .postDarkeningCurtain(terminalVid, curtainDarkening)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  /******** SHADING CURTAIN *******/

  public getShadingCurtain(
    terminalVid: string
  ): Observable<ShadingCurtainModel> {
    return new Observable((observer) => {
      this.restApiService.getShadingCurtain(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postShadingCurtain(
    terminalVid: string,
    shadingCurtain: ShadingCurtainSubmitModel
  ): Observable<ShadingCurtainModel> {
    return new Observable((observer) => {
      this.restApiService
        .postShadingCurtain(terminalVid, shadingCurtain)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  /******** Switching Program *******/

  public getSwitchingProgram(
    terminalVid: string
  ): Observable<SwitchingProgramModel> {
    return new Observable((observer) => {
      this.restApiService.getSwitchingProgram(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postSwitchingProgram(
    terminalVid: string,
    switchingProgram: SwitchingProgramSubmitModel
  ): Observable<SwitchingProgramModel> {
    return new Observable((observer) => {
      this.restApiService
        .postSwitchingProgram(terminalVid, switchingProgram)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  /******** MISTING *******/

  public getMistingProgramming(
    terminalVid: string
  ): Observable<MistingProgrammingModel> {
    return new Observable((observer) => {
      this.restApiService.getMistingProgramming(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postMistingProgramming(
    terminalVid: string,
    misting: MistingProgrammingSubmitModel
  ): Observable<MistingProgrammingModel> {
    return new Observable((observer) => {
      this.restApiService
        .postMistingProgramming(terminalVid, misting)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  public getMistingRegulation(
    terminalVid: string
  ): Observable<MistingRegulationModel> {
    return new Observable((observer) => {
      this.restApiService.getMistingRegulation(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postMistingRegulation(
    terminalVid: string,
    misting: MistingRegulationSubmitModel
  ): Observable<MistingRegulationModel> {
    return new Observable((observer) => {
      this.restApiService.postMistingRegulation(terminalVid, misting).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  /******** HAF FANS *******/

  public getHafFansProgramming(
    terminalVid: string
  ): Observable<HafFansProgrammingModel> {
    return new Observable((observer) => {
      this.restApiService.getHafFansProgramming(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postHafFansProgramming(
    terminalVid: string,
    hafFans: HafFansProgrammingSubmitModel
  ): Observable<HafFansProgrammingModel> {
    return new Observable((observer) => {
      this.restApiService
        .postHafFansProgramming(terminalVid, hafFans)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  public getHafFansAdjusting(
    terminalVid: string
  ): Observable<HafFansAdjustingModel> {
    return new Observable((observer) => {
      this.restApiService.getHafFansAdjusting(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postHafFansAdjusting(
    terminalVid: string,
    hafFans: HafFansAdjustingSubmitModel
  ): Observable<HafFansAdjustingModel> {
    return new Observable((observer) => {
      this.restApiService.postHafFansAdjusting(terminalVid, hafFans).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  /******** ALARMS ZONE *******/

  public getAlarmsZone(terminalVid: string): Observable<AlarmsZoneModel> {
    return new Observable((observer) => {
      this.restApiService.getAlarmsZone(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postAlarmsZone(
    terminalVid: string,
    alarms: AlarmsZoneSubmitModel
  ): Observable<AlarmsZoneModel> {
    return new Observable((observer) => {
      this.restApiService.postAlarmsZone(terminalVid, alarms).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  /******** ALARMS FROST *******/

  public getAlarmsFrost(terminalVid: string): Observable<FrostAlarmModel> {
    return new Observable((observer) => {
      this.restApiService.getAlarmsFrost(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postAlarmsFrost(
    terminalVid: string,
    alarms: FrostAlarmModel
  ): Observable<FrostAlarmModel> {
    return new Observable((observer) => {
      this.restApiService.postAlarmsFrost(terminalVid, alarms).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  /******** ALARMS STORM *******/

  public getAlarmsStorm(terminalVid: string): Observable<StormAlarmModel> {
    return new Observable((observer) => {
      this.restApiService.getAlarmsStorm(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postAlarmsStorm(
    terminalVid: string,
    alarms: AlarmsStormSubmitModel
  ): Observable<StormAlarmLevelModel> {
    return new Observable((observer) => {
      this.restApiService.postAlarmsStorm(terminalVid, alarms).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  /******** ALARMS RAIN *******/

  public getAlarmsRain(terminalVid: string): Observable<RainAlarmModel> {
    return new Observable((observer) => {
      this.restApiService.getAlarmsRain(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postAlarmsRain(
    terminalVid: string,
    alarms: RainAlarmModel
  ): Observable<RainAlarmModel> {
    return new Observable((observer) => {
      this.restApiService.postAlarmsRain(terminalVid, alarms).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  /******** CO2 *******/

  public getCO2(terminalVid: string): Observable<CO2Model> {
    return new Observable((observer) => {
      this.restApiService.getCO2(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postCO2(
    terminalVid: string,
    co2: CO2SubmitModel
  ): Observable<CO2Model> {
    return new Observable((observer) => {
      this.restApiService.postCO2(terminalVid, co2).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  /******** INSTALLER PIPE *******/

  public getInstallerPipes(
    terminalVid: string
  ): Observable<InstallerPipeModel> {
    return new Observable((observer) => {
      this.restApiService.getInstallerPipes(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postInstallerPipes(
    terminalVid: string,
    pipes: InstallerPipesSubmitModel
  ): Observable<InstallerPipeModel> {
    return new Observable((observer) => {
      this.restApiService.postInstallerPipes(terminalVid, pipes).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  /******** INSTALLER MISTING *******/

  public getInstallerMisting(
    terminalVid: string
  ): Observable<InstallerMistingModel> {
    return new Observable((observer) => {
      this.restApiService.getInstallerMisting(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postInstallerMisting(
    terminalVid: string,
    misting: InstallerMistingSubmitModel
  ): Observable<InstallerMistingModel> {
    return new Observable((observer) => {
      this.restApiService.postInstallerMisting(terminalVid, misting).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  /******** INSTALLER MIXED CURTAINS *******/

  public getInstallerMixedCurtains(
    terminalVid: string
  ): Observable<InstallerMixedCurtainsModel> {
    return new Observable((observer) => {
      this.restApiService.getInstallerMixedCurtains(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postInstallerMixedCurtains(
    terminalVid: string,
    mixedCurtains: InstallerMixedCurtainsSubmitModel
  ): Observable<InstallerMixedCurtainsModel> {
    return new Observable((observer) => {
      this.restApiService
        .postInstallerMixedCurtains(terminalVid, mixedCurtains)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  /******** INSTALLER DARKENING CURTAINS *******/

  public getInstallerDarkeningCurtains(
    terminalVid: string
  ): Observable<InstallerDarkeningCurtainsModel> {
    return new Observable((observer) => {
      this.restApiService.getInstallerDarkeningCurtains(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postInstallerDarkeningCurtains(
    terminalVid: string,
    darkeningCurtains: InstallerDarkeningCurtainsSubmitModel
  ): Observable<InstallerMixedCurtainsModel> {
    return new Observable((observer) => {
      this.restApiService
        .postInstallerDarkeningCurtains(terminalVid, darkeningCurtains)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  /******** INSTALLER SHADING CURTAINS *******/

  public getInstallerShadingCurtains(
    terminalVid: string
  ): Observable<InstallerShadingCurtainsModel> {
    return new Observable((observer) => {
      this.restApiService.getInstallerShadingCurtains(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postInstallerShadingCurtains(
    terminalVid: string,
    shadingCurtains: InstallerShadingCurtainsSubmitModel
  ): Observable<InstallerShadingCurtainsModel> {
    return new Observable((observer) => {
      this.restApiService
        .postInstallerShadingCurtains(terminalVid, shadingCurtains)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  /******** INSTALLER ZONES *******/

  public getInstallerZones(
    terminalVid: string
  ): Observable<InstallerZoneModel> {
    return new Observable((observer) => {
      this.restApiService.getInstallerZones(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postInstallerZones(
    terminalVid: string,
    vents: InstallerZonesSubmitModel
  ): Observable<InstallerZoneModel> {
    return new Observable((observer) => {
      this.restApiService.postInstallerZones(terminalVid, vents).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  /******** INSTALLER VENTS *******/

  public getInstallerVents(
    terminalVid: string
  ): Observable<InstallerVentsModel> {
    return new Observable((observer) => {
      this.restApiService.getInstallerVents(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postInstallerVens(
    terminalVid: string,
    vents: InstallerVentsSubmitModel
  ): Observable<InstallerVentsModel> {
    return new Observable((observer) => {
      this.restApiService.postInstallerVents(terminalVid, vents).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  /******** INSTALLER CO2 *******/

  public getInstallerCO2(terminalVid: string): Observable<InstallerCO2Model> {
    return new Observable((observer) => {
      this.restApiService.getInstallerCO2(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postInstallerCO2(
    terminalVid: string,
    co2: InstallerCO2SubmitModel
  ): Observable<InstallerCO2Model> {
    return new Observable((observer) => {
      this.restApiService.postInstallerCO2(terminalVid, co2).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
}
