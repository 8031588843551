import { EventEmitter, Inject, Injectable } from '@angular/core';

import {
  NavigationStart,
  Router,
  Scroll,
} from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class RouteLib {
  navigationLifecycleIsRunning: EventEmitter<boolean> = new EventEmitter();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router
  ) {}

  navigationStart() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.emitNavigationLifecycleIsRunning(true);
      } else if (event instanceof Scroll) {
        const that = this;
        // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
        setTimeout(function () {
          that.emitNavigationLifecycleIsRunning(false);
        }, 200);
      }
    });
  }
  emitNavigationLifecycleIsRunning(running) {
    this.navigationLifecycleIsRunning.emit(running);
  }
  getNavigationLifecycleIsRunningEmitter() {
    return this.navigationLifecycleIsRunning;
  }
}
