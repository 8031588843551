<div class="reports-irrigations-table-container pt-3 pb-3" *ngIf="ready === true">
  <form [formGroup]="filterForm">
    <div mat-dialog-content class="filter__form">
      <div class="row">
        <div class="col-12">
          <h4 class="mb-0">{{ translationsLib.get('reports_filter') }}</h4>
        </div>
        <div class="col-6 col-lg-3 form-group mb-0">
          <mat-form-field>
            <mat-label>{{ translationsLib.get('reports_programName') }}</mat-label>
            <mat-select formControlName="programName" multiple>
              <mat-option *ngFor="let programName of filterProgramList" [value]="programName">{{programName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-6 col-lg-2 form-group mb-0">
          <mat-form-field>
            <input matInput formControlName="timeBegin" placeholder="{{ translationsLib.get('reports_timeBegin') }}">
          </mat-form-field>
        </div>
        <div class="col-6 col-lg-2 form-group mb-0">
          <mat-form-field>
            <input matInput formControlName="timeEnd" placeholder="{{ translationsLib.get('reports_timeEnd') }}">
          </mat-form-field>
        </div>
        <div class="col-6 col-lg-2 form-group mb-0">
          <mat-form-field>
            <input matInput formControlName="activationTypeStr" placeholder="{{ translationsLib.get('reports_activationTypeStr') }}">
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-3 form-group mb-0 text-end pt-3">
          <a [matMenuTriggerFor]="menu">
            <span>{{ translationsLib.get('reports_data_tools') }}</span>
            <button mat-icon-button>
              <ion-icon name="ellipsis-vertical-outline"></ion-icon>
            </button>
          </a>
          <mat-menu #menu="matMenu">
            <button mat-menu-item>
              <ion-icon name="reader-outline"></ion-icon>
              <button mat-button (click)="exportToXLSX()">
                {{ translationsLib.get('highcharts_download_xls') }}
              </button>
            </button>
            <button mat-menu-item>
              <ion-icon name="document-outline"></ion-icon>
              <button mat-button (click)="exportToPDF()">
                {{ translationsLib.get('highcharts_download_pdf') }}
              </button>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </form>
  <div style="overflow-x: auto; max-width: 100%;">
    <table mat-table
           [dataSource]="dataSource"
           multiTemplateDataRows matSort
           matTableExporter #exporter="matTableExporter" [hiddenColumns]="[]"
           class="mat-elevation-z8 irrigation-table"
    >
      <ng-container matColumnDef="{{ column }}" *ngFor="let column of irrigationColumnsToDisplay">
        <th class="px-2" mat-header-cell mat-sort-header *matHeaderCellDef> {{ translationsLib.get('reports_' + column) }}</th>
        <td class="px-2" mat-cell *matCellDef="let element" [ngClass]="{'warning' : element.incidence === true, 'ps-5': column === 'num_activation'}">
          <ng-container *ngIf="!column.includes('Water')">
            {{ element[column] }}
          </ng-container>
          <ng-container *ngIf="column.includes('Water')">
            {{ element[column] }} m3
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="expand">
        <th class="px-2" mat-header-cell *matHeaderCellDef aria-label="row actions"></th>
        <td class="px-2" mat-cell *matCellDef="let element" [ngClass]="{'warning' : element.incidence === true}">
          <button *ngIf="element.fertilizationReport.items.length > 0" mat-icon-button aria-label="expand row" (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
            <mat-icon *ngIf="expandedElement !== element">
              <ion-icon name="caret-down-circle-outline"></ion-icon>
            </mat-icon>
            <mat-icon *ngIf="expandedElement === element">
              <ion-icon name="caret-up-circle-outline"></ion-icon>
            </mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td class="px-2" mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length" [ngClass]="{'warning' : element.incidence === true}">
          <div class="table-detail"
               [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">

            <!-- only load when clicking on show icon -->
            <aside class="mb-4 fertilizer-container" *ngIf="element.fertilizationReport.items.length > 0">
              <app-reports-fertilizers-table
                [data]="element.fertilizationReport.items"
                [fertilizerColumnsToDisplay]="fertilizerColumnsToDisplay"
              ></app-reports-fertilizers-table>
            </aside>

          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
      <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;"
          class="table-row"
          [class.table-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
    </table>
  </div>
  <mat-paginator
    [length]="count"
    [pageSize]="tablePageItemNumber"
    showFirstLastButtons
  >
  </mat-paginator>
</div>
<app-reports-download-post-open-window [requestData]="this.requestData" *ngIf="this.downloadPDF === true"></app-reports-download-post-open-window>
