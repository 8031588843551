import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslationsLibService } from '@nutricontrol/app360-shared';

@Component({
  selector: 'app-generic-header',
  templateUrl: './generic-header.component.html',
  styleUrls: ['./generic-header.component.scss'],
})
export class GenericHeaderComponent implements OnInit {
  @Input() isClimate = false;
  @Input() hasButtons = false;
  @Input() elements: any[];
  @Input() text: string;
  @Input() elementSearchId: string;
  @Input() selected: any;
  @Input() placeholder: string;
  @Input() image: string;
  @Input() label: string;
  @Output() changeSelected = new EventEmitter();
  @Input() borderType: string[];
  @Input() climateElements;
  @Input() isPrograms = false;
  @Input() zoneId;
  @Input() selectedZone;
  @Input() filterProp: string;
  selectedClimateZone;
  indexToReturn = 0;

  filteredElements: any[];

  search = { value: '' };

  constructor(
    private activatedRoute: ActivatedRoute,
    public translationsLib: TranslationsLibService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((_) => {
      if (this.isClimate) {
        this.selectedClimateZone = this.climateElements.find(
          (elem: any) => elem.zone === this.selectedZone
        );

        if (
          this.selectedClimateZone.name === null ||
          this.selectedClimateZone.name === '' ||
          !this.selectedClimateZone.hasOwnProperty('name')
        ) {
          this.search.value = `${this.label} ${this.selectedZone + 1}`;
        } else {
          this.search.value = this.selectedClimateZone.name.trim();
        }

        this.filteredElements = this.climateElements;
      } else {
        if (
          this.selected[this.text] === null ||
          this.selected[this.text] === '' ||
          !this.selected.hasOwnProperty(this.text)
        ) {
          this.search.value = `${this.label} ${
            this.selected[this.elementSearchId] + 1
          }`;
        } else {
          this.search.value = this.selected[this.text].trim();
        }

        this.filteredElements = this.elements;
      }
    });
  }

  filterClimateElements() {
    const filter = this.search.value.toLowerCase();
    this.filteredElements = this.climateElements.filter((option) => {
      if (
        option.name === null ||
        option.name === '' ||
        !option.hasOwnProperty('name')
      ) {
        return `${this.label} ${option.zone + 1}`
          .toLowerCase()
          .includes(filter);
      } else {
        return option.name.toLowerCase().includes(filter);
      }
    });
  }

  filterIrrigationElements() {
    const filter = this.search.value.toLowerCase();
    this.filteredElements = this.elements.filter((option) => {
      if (
        option[this.text] === null ||
        option[this.text] === '' ||
        !option.hasOwnProperty(this.text)
      ) {
        return `${this.label} ${option[this.elementSearchId] + 1}`
          .toLowerCase()
          .includes(filter);
      } else {
        return option[this.text].toLowerCase().includes(filter);
      }
    });
  }

  displayFnIrrigation = (element: any) => {
    /**
     * Not the best code. You need to do the code below due to
     * the possibility of null values, and empty ones.
     * So you need to add every screen name one by one (even installer)
     * This is to prevent the error [Object object] when select
     * the same element.
     */
    if (element && typeof element === 'object' && element.name) {
      return element.name.trim();
    } else if (element) {
      if (
        element.name === '' ||
        element.name === null ||
        !element.hasOwnProperty('name')
      ) {
        if (element.group !== null && element.group !== undefined) {
          return `${this.translationsLib.get('irrigation_group')} ${
            element.group + 1
          }`;
        } else if (element.program !== null && element.program !== undefined) {
          return `${this.translationsLib.get('irrigation_program')} ${
            element.program + 1
          }`;
        } else if (
          element.formula !== null &&
          element.formula !== undefined &&
          element.hasOwnProperty('valve1') &&
          element.hasOwnProperty('valve2')
        ) {
          return `${this.translationsLib.get('irrigation_mixture_formula')} ${
            element.formula + 1
          }`;
        } else if (element.formula !== null && element.formula !== undefined) {
          return `${this.translationsLib.get(
            'irrigation_fertilizer_formula'
          )} ${element.formula + 1}`;
        } else if (
          element.drainage !== null &&
          element.drainage !== undefined
        ) {
          return `${this.translationsLib.get('irrigation_drainage')} ${
            element.drainage + 1
          }`;
        } else if (
          element.valve !== null &&
          element.valve !== undefined &&
          element.hasOwnProperty('counterPulse')
        ) {
          return `${this.translationsLib.get('irrigation_valve')} ${
            element.valve + 1
          }`;
        } else if (
          element.pump !== null &&
          element.pump !== undefined &&
          element.hasOwnProperty('timeToStart')
        ) {
          return `${this.translationsLib.get('irrigation_pump')} ${
            element.pump + 1
          }`;
        } else if (
          element.waterSystem !== null &&
          element.waterSystem !== undefined &&
          element.hasOwnProperty('pressureAndWashingTimes')
        ) {
          return `${this.translationsLib.get('irrigation_water_system')} ${
            element.waterSystem + 1
          }`;
        } else if (
          element.tray !== null &&
          element.tray !== undefined &&
          element.hasOwnProperty('drainageOpeningTime')
        ) {
          return `${this.translationsLib.get('irrigation_drainage_tray')} ${
            element.tray + 1
          }`;
        } else if (
          element.mixtureValve !== null &&
          element.mixtureValve !== undefined &&
          element.hasOwnProperty('verificationTime')
        ) {
          return `${this.translationsLib.get('irrigation_mixture_valve')} ${
            element.mixtureValve + 1
          }`;
        }
      }
      return element;
    } else {
      return '';
    }
  };

  displayFnClimate(element: any) {
    if (element && typeof element === 'object' && element.name) {
      return element.name;
    } else if (element) {
      return element;
    } else {
      return '';
    }
  }

  get selectedIndex() {
    this.elements.forEach((elem, index) => {
      if (
        elem.formula &&
        JSON.stringify(elem.formula) === JSON.stringify(this.selected.formula)
      ) {
        this.indexToReturn = index;
      } else {
        if (
          elem.valve &&
          JSON.stringify(elem.valve) === JSON.stringify(this.selected.valve)
        ) {
          this.indexToReturn = index;
        } else {
          if (
            !elem.valve &&
            JSON.stringify(elem) === JSON.stringify(this.selected)
          ) {
            this.indexToReturn = index;
          }
        }
      }
    });
    return this.indexToReturn;
    /* return this.elements.findIndex(
      (elem) =>
        JSON.stringify(elem.valve) === JSON.stringify(this.selected.valve)
    );*/
  }

  changedClimate(element: any) {
    if (
      element.name === null ||
      element.name === '' ||
      !this.selectedClimateZone.hasOwnProperty('name')
    ) {
      this.search.value = `${this.label} ${element.zone + 1}`;
    } else {
      this.search.value = element.name.trim();
    }

    this.changeSelected.emit(element.zone);
    this.filteredElements = this.climateElements;
  }

  trimString(string: string): string {
    if (typeof string === 'string') {
      return string.trim();
    } else {
      return string;
    }
  }

  changedIrrigation(element: any) {
    if (
      element[this.text] === null ||
      element[this.text] === '' ||
      !this.selected.hasOwnProperty(this.text)
    ) {
      this.search.value = `${this.label} ${element[this.elementSearchId] + 1}`;
    } else {
      this.search.value = element[this.text].trim();
    }

    this.changeSelected.emit(element);
  }

  goBackButton(zoneIndex: number) {
    const selectedZone = this.climateElements.find(
      (element: any) => element.zone === zoneIndex
    );

    if (selectedZone) {
      if (selectedZone.name) {
        this.search.value = `${selectedZone.name}`;
      } else {
        this.search.value = `${this.label} ${zoneIndex + 1}`;
      }

      this.changeSelected.emit(zoneIndex);
    }
  }
}
