import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment/moment';

@Pipe({
  name: 'programDateTable',
})
export class ProgramDateTablePipe implements PipeTransform {
  transform(date: string): string {
    return moment.utc(date, 'MM/DD HH:mm:ss').format('DD/MM HH:mm');
  }
}
