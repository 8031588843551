<app-main-header [breadcrumbs]="breadcrumbs"></app-main-header>
<ion-content>
  <div class="container-fluid p-1 p-lg-2">
    <div class="mx-lg-4">
      <div class="col-12">
        <mat-tab-group class="col-12" (selectedTabChange)="onTabChange($event)" #tabGroup>
          <mat-tab label="{{ translationsLib.get(tabs.general) }}">
            <br>
            <app-account></app-account>
          </mat-tab>
          <mat-tab label="{{ translationsLib.get(tabs.notifications) }}" *ngIf="sessionLib.getUserHasNonDemoTerminals() === 'true'">
            <br>
            <app-notifications></app-notifications>
          </mat-tab>
          <mat-tab label="{{ translationsLib.get(tabs.permissions) }}" *ngIf="sessionLib.getUserHasNonDemoTerminals() === 'true'">
            <br>
            <app-account-permissions *ngIf="layout!== undefined" [showDemoField]="layout.general.show_attach_to_demo"></app-account-permissions>
          </mat-tab>
          <mat-tab label="{{ translationsLib.get(tabs.side_menu) }}">
            <br>
            <app-config-menu></app-config-menu>
          </mat-tab>
          <mat-tab [label]="translationsLib.get(tabs.myucrop)" *ngIf="sessionLib.getUserHasNonDemoTerminals() === 'true'">
            <br>
            <app-myucrop></app-myucrop>
          </mat-tab>
          <mat-tab [label]="translationsLib.get(tabs.administrative_management)" *ngIf="sessionLib.getUserHasNonDemoTerminals() === 'true'">
            <br>
            <div style="min-height: 120px">
              <lib-administrative-management [platform]="'ucrop'"></lib-administrative-management>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>

</ion-content>
