<div class="mb-4 d-flex justify-content-center mt-5" *ngIf="isFetchingData">
  <div class="spinner-border text-warning" role="status">
    <span class="visually-hidden">{{
      this.translationsLib.get('irrigation_loading')
    }}</span>
  </div>
</div>

<app-dirty-message
  [conditions]="layoutConfiguration && !isFetchingData"
  [dirty]="demandsForm.value.dirty"
></app-dirty-message>

<ng-template
  [ngIf]="
    mode === 'simple' &&
    layoutConfiguration &&
    !isFetchingData &&
    !isDemandsWithoutData
  "
>
  <form [formGroup]="demandsForm" class="simple-form">
    <div class="mb-2">
      <div class="card">
        <div class="card-body custom-card-body">
          <div class="row">
            <div class="col-sm-5 col-md-5">
              <h6 class="fs-2">
                {{ translationsLib.get('irrigation_demands') }}
              </h6>
              <p class="fs-5 fw-lighter">
                {{
                  translationsLib.get('irrigation_demands_help') | sanitizeText
                }}
              </p>
            </div>
            <div class="col-sm-7 col-md-7 col-inputs">
              <div formArrayName="demands">
                <div
                  class="row align-items-center"
                  *ngFor="let demand of demandsData.controls; let i = index"
                  [formGroupName]="i"
                >
                  <div class="col-12 col-md-6">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_demand') }} {{ i + 1 }}
                    </h6>
                  </div>
                  <div class="col-12 col-md-6">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_demand_input_type') }}
                    </h6>
                    <div class="input-group mb-2">
                      <select class="form-select" formControlName="type">
                        <option
                          [value]="type.value"
                          *ngFor="let type of typesList"
                        >
                          {{ type.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>

<ng-template
  [ngIf]="
    mode === 'advanced' &&
    layoutConfiguration &&
    !isFetchingData &&
    !isDemandsWithoutData
  "
>
  <form [formGroup]="demandsForm" class="advanced-form">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="card-body custom-card-body">
            <div class="row">
              <div class="col-md-12">
                <h6 class="fs-2">
                  {{ translationsLib.get('irrigation_demands') }}
                </h6>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-md-6">
                <div formArrayName="demands">
                  <div
                    class="row align-items-center"
                    *ngFor="let demand of demandsData.controls; let i = index"
                    [formGroupName]="i"
                  >
                    <div class="col-12 col-md">
                      <h6 class="fs-5">
                        {{ translationsLib.get('irrigation_demand') }}
                        {{ i + 1 }}
                      </h6>
                    </div>
                    <div class="col-12 col-md">
                      <h6 class="fs-5">
                        {{
                          translationsLib.get('irrigation_demand_input_type')
                        }}
                      </h6>
                      <div class="input-group mb-2">
                        <select class="form-select" formControlName="type">
                          <option
                            [value]="type.value"
                            *ngFor="let type of typesList"
                          >
                            {{ type.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
