import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { IrrigationService } from '../../../irrigation.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  convertFormattedTimeToSeconds,
  DateToSecondsInput,
  removeDirtyInputs,
  saveDataAndShowModal,
  saveSubmitWithTerminalStatus,
  secondsInputToDate,
} from '../../../../../commons/helpers/functions';
import Swal from 'sweetalert2';
import {
  MiscellaneousIrrigationInstallerModel,
  Progval,
  WaterSystemIrrigationInstallerModel,
} from '../../../irrigation.model';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { EquipmentsLibService } from '../../../../../services/libraries/equipments-lib.service';
import { Subscription } from 'rxjs';
import { FormFieldHandlerService } from '../../../../libraries/form-field-handler.service';
import { environment } from '../../../../../../environments/environment';
import { InputNumberService } from '../../../../libraries/input-number.service';

@Component({
  selector: 'app-miscellaneous-form',
  templateUrl: './miscellaneous-form.component.html',
  styleUrls: ['./miscellaneous-form.component.scss'],
})
export class MiscellaneousFormComponent implements OnInit, OnDestroy {
  isFetchingData = false;
  isFormSubmitted = false;
  private formFieldErrorSubscription: Subscription;
  isConnected: boolean;
  private intervalId: any;
  miscellaneousForm: UntypedFormGroup;
  miscellaneousResponse: MiscellaneousIrrigationInstallerModel;
  waterSystemsResponse: WaterSystemIrrigationInstallerModel[];
  @Input() mode: string;
  @Input() terminal_vid: string;
  isMiscellaneousWithoutData = false;
  @Input() miscellaneous_id: number;
  @Output() dirtyEventEmitter = new EventEmitter<boolean>();
  @Output() formFieldErrorEventEmitter = new EventEmitter<boolean>();
  layoutConfiguration: Progval;

  isErrorInMiscellaneousPost = false;
  isErrorInWatersystemsPost = false;

  constructor(
    private fb: UntypedFormBuilder,
    private irrigationService: IrrigationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public translationsLib: TranslationsLibService,
    private equipmentLib: EquipmentsLibService,
    private formFieldHandlerService: FormFieldHandlerService,
    public inputNumberService: InputNumberService
  ) {
    this.miscellaneousForm = this.fb.group({
      flowAlarm: [null, Validators.required],
      dirty: [''],
      accumulateRadiation: [null, Validators.required],
      timeBetweenPumps: [null, Validators.required],
      overlapTime: [null, Validators.required],
      respectMinPressure: [null, Validators.required],
      radiationDawn: [null, Validators.required],
      simultaneity: [null, Validators.required],
      simplified: [null, Validators.required],
      advancedInterface: [null, Validators.required],
      distributeSpecialContributions: [null, Validators.required],
      drainType: [null, Validators.required],
      waterSystems: this.fb.group({}),
      isDirty: false,
    });
  }

  async startInterval() {
    this.intervalId = setInterval(() => {
      this.performTasks();
    }, environment.intervalDefaultTimeout);
  }

  async performTasks() {
    await this.getLayoutConfiguration();
    this.getWaterSystemsList();
    setTimeout(() => {
      this.getMiscellaneous();
      this.getLayoutConfiguration();
    }, 2000);

    await this.getIrrigationEquipment();

    if (!this.isConnected) {
      this.equipmentLib.showConnectivityAlert();
    }

    this.isFormSubmitted = false;
  }

  ngOnInit() {
    this.miscellaneousForm.reset(); // Prevent unsaved data to persist in form inputs

    this.activatedRoute.params.subscribe((_) => {
      this.getWaterSystemsList();
      setTimeout(() => {
        this.getMiscellaneous();
        this.getLayoutConfiguration();
      }, 2000);

      removeDirtyInputs(this.miscellaneousForm);
    });

    this.miscellaneousForm.valueChanges.subscribe((_) => {
      this.dirtyEventEmitter.emit(this.getIsDirty());

      if (this.getIsDirty()) {
        clearInterval(this.intervalId);
      } else {
        clearInterval(this.intervalId);
        this.startInterval();
      }
    });

    this.getIrrigationEquipment();
  }

  getIrrigationEquipment() {
    this.irrigationService
      .getIrrigationTerminal(this.terminal_vid)
      .subscribe((res) => {
        this.isConnected = res.connected;
      });
  }

  getLayoutConfiguration() {
    this.irrigationService
      .getTerminalLayoutConfiguration(this.terminal_vid)
      .subscribe((res) => {
        this.layoutConfiguration = res.screens_configuration.progval;
        if (!this.layoutConfiguration.enabled) {
          this.router.navigate(['/home/dashboard']);
        }
      });
  }

  setDirty(dirty: boolean) {
    this.miscellaneousForm.patchValue({ isDirty: dirty });
  }

  getIsDirty() {
    return this.miscellaneousForm.dirty || this.miscellaneousForm.value.isDirty;
  }

  getWaterSystemsList(isGetAfterPost = false) {
    this.irrigationService
      .getIrrigationInstallerWaterSystems(this.terminal_vid)
      .subscribe((res) => {
        this.waterSystemsResponse = res;
      });
  }

  getMiscellaneous(isGetAfterPost = false) {
    if (!isGetAfterPost) this.isFetchingData = true;

    this.irrigationService
      .getIrrigationInstallerMiscellaneous(this.terminal_vid)
      .subscribe((res) => {
        this.miscellaneousResponse = res;

        if (Object.keys(this.miscellaneousResponse).length === 0) {
          this.isMiscellaneousWithoutData = true;
        } else {
          if (
            this.waterSystemsResponse &&
            Array.isArray(this.waterSystemsResponse)
          ) {
            for (const system of this.waterSystemsResponse) {
              // Create form for every waterSystem
              const systemForm = this.fb.group({
                waterSystem: [system.waterSystem, Validators.required],
                waterPulse: [system.waterPulse, Validators.required],
                maxFlow: [system.maxFlow, Validators.required],
                minFlow: [system.minFlow, Validators.required],
                flowAlarmDelay: [
                  secondsInputToDate(system.flowAlarmDelay),
                  Validators.required,
                ],
                maxPressureSwitchDelay: [
                  secondsInputToDate(system.maxPressureSwitchDelay),
                  Validators.required,
                ],
                minPressureSwitchDelay: [
                  secondsInputToDate(system.minPressureSwitchDelay),
                  Validators.required,
                ],
                fertilizerPulses: this.fb.array([]),
              });

              // Add waterSystem form to general form
              const waterSystemsFormGroup = this.miscellaneousForm.get(
                'waterSystems'
              ) as FormGroup;
              waterSystemsFormGroup.addControl(
                `system_${system.waterSystem}`,
                systemForm
              );
            }
          } else {
            console.error('this.waterSystemsResponse is not an iterable array');
          }

          this.miscellaneousForm.patchValue({
            flowAlarm: res.flowAlarm,
            dirty: res.dirty,
            accumulateRadiation: res.accumulateRadiation,
            timeBetweenPumps: secondsInputToDate(res.timeBetweenPumps),
            overlapTime: secondsInputToDate(res.overlapTime),
            respectMinPressure: res.respectMinPressure,
            radiationDawn: res.radiationDawn,
            simultaneity: res.simultaneity,
            simplified: res.simplified,
            advancedInterface: res.advancedInterface,
            distributeSpecialContributions: res.distributeSpecialContributions,
            drainType: res.drainType,
          });
        }

        if (!isGetAfterPost) this.isFetchingData = false;
      });
  }

  onChangeInputTimeWithKeyboard(event, formInput: string, system?) {
    event.target.classList.add('ng-dirty');
    this.miscellaneousForm.markAsDirty();

    if (
      formInput === 'flowAlarmDelay' ||
      formInput === 'maxPressureSwitchDelay' ||
      formInput === 'minPressureSwitchDelay'
    ) {
      const toSeconds = convertFormattedTimeToSeconds(event.target.value);
      const toDate = secondsInputToDate(toSeconds);
      this.miscellaneousForm
        .get('waterSystems.system_' + system)
        .get(formInput)
        .setValue(toDate);
    } else {
      const toSeconds = convertFormattedTimeToSeconds(event.target.value);
      const toDate = secondsInputToDate(toSeconds);
      this.miscellaneousForm.get(formInput).setValue(toDate);
    }
  }

  handleWaterSystemsPost(
    showModal: boolean,
    connected: boolean
  ): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      const waterSystemsAsArray = [];

      if (
        this.waterSystemsResponse &&
        Array.isArray(this.waterSystemsResponse)
      ) {
        for (const system of this.waterSystemsResponse) {
          waterSystemsAsArray.push({
            waterSystem:
              this.miscellaneousForm.value.waterSystems[
                'system_' + system.waterSystem
              ].waterSystem,
            waterPulse:
              this.miscellaneousForm.value.waterSystems[
                'system_' + system.waterSystem
              ].waterPulse,
            maxFlow:
              this.miscellaneousForm.value.waterSystems[
                'system_' + system.waterSystem
              ].maxFlow,
            minFlow:
              this.miscellaneousForm.value.waterSystems[
                'system_' + system.waterSystem
              ].minFlow,
            flowAlarmDelay: DateToSecondsInput(
              this.miscellaneousForm.value.waterSystems[
                'system_' + system.waterSystem
              ].flowAlarmDelay
            ),
            maxPressureSwitchDelay: DateToSecondsInput(
              this.miscellaneousForm.value.waterSystems[
                'system_' + system.waterSystem
              ].maxPressureSwitchDelay
            ),
            minPressureSwitchDelay: DateToSecondsInput(
              this.miscellaneousForm.value.waterSystems[
                'system_' + system.waterSystem
              ].minPressureSwitchDelay
            ),
          });
        }
      } else {
        console.error('this.waterSystemsResponse no es un array iterable');
      }

      try {
        this.irrigationService
          .postIrrigationInstallerWaterSystems(
            this.terminal_vid,
            waterSystemsAsArray
          )
          .subscribe(
            (res) => {
              if (res.error) this.isErrorInWatersystemsPost = true;

              if (
                connected &&
                !this.miscellaneousForm.value.dirty &&
                !this.isErrorInWatersystemsPost
              ) {
                saveDataAndShowModal(
                  res,
                  showModal,
                  this.translationsLib.get('error_updating_data'),
                  this.translationsLib.get('save_changes_success'),
                  this.translationsLib.get('accept')
                );

                this.isFormSubmitted = false;
                resolve(true);
              } else if (this.isErrorInWatersystemsPost) {
                Swal.fire({
                  text: this.translationsLib.get(
                    'irrigation_general_program_error'
                  ),
                  showConfirmButton: true,
                  confirmButtonText: this.translationsLib.get('accept'),
                  icon: 'error',
                });

                this.isFormSubmitted = false;
                resolve(false);
              } else {
                saveSubmitWithTerminalStatus(
                  res,
                  showModal,
                  connected,
                  this.translationsLib.get('error_updating_data'),
                  this.translationsLib.get('save_changes_success'),
                  this.translationsLib.get('accept'),
                  this.translationsLib.get(
                    'irrigation_disconnected_terminal_pending_changes'
                  )
                );

                this.isFormSubmitted = false;
                resolve(false);
              }
            },
            (_) => {
              if (showModal) {
                Swal.fire({
                  text: this.translationsLib.get('something_was_wrong'),
                  showConfirmButton: true,
                  confirmButtonText: this.translationsLib.get('accept'),
                  icon: 'error',
                });
              }
              this.isFormSubmitted = false;
              resolve(false);
            }
          );
      } catch (err) {
        throw new Error(err.message);
      }
    });
  }

  submitWaterSystems(showModal = true): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      try {
        const invalidDates = this.formFieldHandlerService.checkInvalidDate(
          this.miscellaneousForm
        );
        if (invalidDates.hasInvalid) {
          this.formFieldHandlerService.showInvalidDateOrNullsPopUp(
            invalidDates.invalidFields
          );
        } else if (this.miscellaneousForm.valid) {
          this.irrigationService
            .getIrrigationTerminal(this.terminal_vid)
            .subscribe((res) => {
              this.handleWaterSystemsPost(showModal, res.connected).then(
                (result) => {
                  resolve(result);
                }
              );
            });
        } else {
          this.formFieldHandlerService.showErrorsAndAlerts(
            this.miscellaneousForm
          );
          this.formFieldErrorSubscription =
            this.formFieldHandlerService.formFieldError$.subscribe(
              (hasError: boolean) => {
                if (hasError) this.formFieldErrorEventEmitter.emit(true);
              }
            );
        }
      } catch (err) {
        reject(false);
      } finally {
        // Remove dirty inputs due to use standalone ngModels
        removeDirtyInputs(this.miscellaneousForm);

        this.miscellaneousForm.markAsUntouched();

        this.miscellaneousForm.markAsPristine();
        this.miscellaneousForm.patchValue({ isDirty: false });
      }
    });
  }

  handleMiscellaneousPost(
    showModal: boolean,
    connected: boolean
  ): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      const miscellaneousAsObj = {
        flowAlarm: this.miscellaneousForm.value.flowAlarm,
        accumulateRadiation: this.miscellaneousForm.value.accumulateRadiation,
        timeBetweenPumps: DateToSecondsInput(
          this.miscellaneousForm.value.timeBetweenPumps
        ),
        overlapTime: DateToSecondsInput(
          this.miscellaneousForm.value.overlapTime
        ),
        respectMinPressure: this.miscellaneousForm.value.respectMinPressure,
        radiationDawn: this.miscellaneousForm.value.radiationDawn,
        simultaneity: this.miscellaneousForm.value.simultaneity,
        simplified: this.miscellaneousForm.value.simplified,
        advancedInterface: this.miscellaneousForm.value.advancedInterface,
        distributeSpecialContributions:
          this.miscellaneousForm.value.distributeSpecialContributions,
        drainType: this.miscellaneousForm.value.drainType,
      };

      try {
        this.irrigationService
          .postIrrigationInstallerMiscellaneous(
            this.terminal_vid,
            miscellaneousAsObj
          )
          .subscribe(
            (res) => {
              if (res.error) this.isErrorInMiscellaneousPost = true;

              if (
                connected &&
                !this.miscellaneousForm.value.dirty &&
                !this.isErrorInMiscellaneousPost
              ) {
                saveDataAndShowModal(
                  res,
                  showModal,
                  this.translationsLib.get('error_updating_data'),
                  this.translationsLib.get('save_changes_success'),
                  this.translationsLib.get('accept')
                );

                resolve(true);
              } else if (this.isErrorInMiscellaneousPost) {
                Swal.fire({
                  text: this.translationsLib.get(
                    'irrigation_general_program_error'
                  ),
                  showConfirmButton: true,
                  confirmButtonText: this.translationsLib.get('accept'),
                  icon: 'error',
                });

                this.isFormSubmitted = false;
                resolve(false);
              } else {
                saveSubmitWithTerminalStatus(
                  res,
                  showModal,
                  connected,
                  this.translationsLib.get('error_updating_data'),
                  this.translationsLib.get('save_changes_success'),
                  this.translationsLib.get('accept'),
                  this.translationsLib.get(
                    'irrigation_disconnected_terminal_pending_changes'
                  )
                );

                this.isFormSubmitted = false;
                resolve(false);
              }
            },
            (_) => {
              if (showModal) {
                Swal.fire({
                  text: this.translationsLib.get('something_was_wrong'),
                  showConfirmButton: true,
                  confirmButtonText: this.translationsLib.get('accept'),
                  icon: 'error',
                });
              }
              this.isFormSubmitted = false;
              resolve(false);
            }
          );
      } catch (err) {
        throw new Error(err.message);
      }
    });
  }

  submitMiscellaneous(showModal = true): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      try {
        this.irrigationService
          .getIrrigationTerminal(this.terminal_vid)
          .subscribe((res) => {
            this.handleMiscellaneousPost(showModal, res.connected).then(
              (result) => {
                resolve(result);
              }
            );
          });
      } catch (err) {
        reject(false);
      } finally {
        // Remove dirty inputs due to use standalone ngModels
        removeDirtyInputs(this.miscellaneousForm);

        this.miscellaneousForm.markAsUntouched();

        this.miscellaneousForm.markAsPristine();
        this.miscellaneousForm.patchValue({ isDirty: false });
      }
    });
  }

  submitForm(showModal: boolean = true) {
    this.isFormSubmitted = true;

    const waterSystemsPromise = this.submitWaterSystems(showModal);
    const miscellaneousPromise = this.submitMiscellaneous(showModal);

    return Promise.all([waterSystemsPromise, miscellaneousPromise]).then(
      (results) => {
        const allSuccess = results.every((result) => result);

        if (
          allSuccess &&
          !this.isErrorInMiscellaneousPost &&
          !this.isErrorInWatersystemsPost
        ) {
          Swal.fire({
            text: this.translationsLib.get('save_changes_success'),
            showConfirmButton: true,
            confirmButtonText: this.translationsLib.get('accept'),
            icon: 'success',
          }).then(() => {
            setTimeout(() => {
              this.getWaterSystemsList(true);
              this.getMiscellaneous(true);
            }, 4000);
          });

          this.isFormSubmitted = false;
        } else if (
          this.isErrorInMiscellaneousPost ||
          this.isErrorInWatersystemsPost
        ) {
          Swal.fire({
            text: this.translationsLib.get('irrigation_general_program_error'),
            showConfirmButton: true,
            confirmButtonText: this.translationsLib.get('accept'),
            icon: 'error',
          });

          this.isFormSubmitted = false;
        } else if (!allSuccess[0] || !allSuccess[1]) {
          Swal.fire({
            text: this.translationsLib.get(
              'irrigation_disconnected_terminal_pending_changes'
            ),
            showConfirmButton: true,
            confirmButtonText: this.translationsLib.get('accept'),
            icon: 'error',
          });

          this.isFormSubmitted = false;
        } else {
          Swal.fire({
            text: this.translationsLib.get('something_was_wrong'),
            showConfirmButton: true,
            confirmButtonText: this.translationsLib.get('accept'),
            icon: 'error',
          });

          this.isFormSubmitted = false;
        }
      }
    );
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
    this.formFieldErrorSubscription?.unsubscribe();
  }
}
