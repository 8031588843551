import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { IrrigationService } from '../irrigation.service';
import {
  DrainageV4Model,
  FertilizerFormulasModel,
  GroupsDataModel,
  IrrigationEquipmentModel,
  MixtureFormulasModel,
  ProgramModel,
  ScreensConfigurationIrrigation,
} from '../irrigation.model';
import { Breadcrumb } from '../shared/shared.model';
import { ActivatedRoute, Router } from '@angular/router';
import { HelpersLibService } from '../../../services/libraries/helpers-lib.service';
import { PermissionsTerminalsService } from '../../../services/libraries/permissions-terminals.service';
import {
  SessionLibService,
  TranslationsLibService,
  TerminalDataModel,
} from '@nutricontrol/app360-shared';
import {
  generateAlarmGrid,
  generateMeteoGrid,
} from '../../../commons/helpers/functions';
import { TimeLibService } from '../../../services/libraries/time-lib.service';
import { AppCommonsService } from '../../../commons/app-commons.service';
import { WeatherService } from '../../../weather/weather.service';
import {
  WeatherCalculateET0Request,
  WeatherPredictionDataModel,
} from '../../../weather/weather.model';
import { TerminalsLibService } from '../../../services/libraries/terminals-lib.service';
import { CancelAlarmsType } from '../../climate/climate.model';
import Swal from 'sweetalert2';
import { Et0LibService } from '../../libraries/et0-lib.service';
import { TerminalTableWrapperIrrigationComponent } from 'src/app/commons/components/terminal-table-wrapper-irrigation/terminal-table-wrapper-irrigation.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-irrigation-terminal-dashboard',
  templateUrl: './irrigation-terminal-dashboard.component.html',
  styleUrls: ['./irrigation-terminal-dashboard.component.scss'],
})
export class IrrigationTerminalDashboardComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  selectedTab = 0;
  isPostCancelAlarms = false;
  programsResponse: ProgramModel[];
  loadingEquipment = true;
  weather: WeatherPredictionDataModel;
  groupsResponse: GroupsDataModel[];
  equipmentResponse: IrrigationEquipmentModel;
  irrigationEquipmentVirtualPostResponseTerminal:
    | WeatherCalculateET0Request
    | IrrigationEquipmentModel;
  drainagesResponse: DrainageV4Model[];
  calculateET0Request: WeatherCalculateET0Request;
  irrigationEquipmentVirtualPostResponse: WeatherCalculateET0Request;
  yesterday_ET0 = '-';
  mixtureFormulasResponse: MixtureFormulasModel[];
  terminalData: TerminalDataModel;
  fertilizerFormulasResponse: FertilizerFormulasModel[];
  terminal_vid: string;
  isFetchingProgramData = false;
  isFetchingET0 = false;
  isFetchingEquipmentData = false;
  isFetchingGroupData = false;
  isFetchingDrainageData = false;
  isFetchingMixtureFormulasData = false;
  isFetchingFertilizerFormulasData = false;
  breadcrumbs: Breadcrumb[] = [];
  terminalValue: string;
  meteoRows = [];
  alarmRows = [];
  screenConfiguration: ScreensConfigurationIrrigation;
  authedUserCanAdmin: boolean;
  isFetchingTerminalData = false;
  isDataAvailable = false;
  isMobile = false;

  private intervalId: any;

  @ViewChild(TerminalTableWrapperIrrigationComponent)
  wrapper: TerminalTableWrapperIrrigationComponent;

  constructor(
    private irrigationService: IrrigationService,
    private activatedRoute: ActivatedRoute,
    private helpersLib: HelpersLibService,
    private permissionsTerminal: PermissionsTerminalsService,
    private router: Router,
    public translationsLib: TranslationsLibService,
    private timeLib: TimeLibService,
    public terminalsLib: TerminalsLibService,
    public commonService: AppCommonsService,
    public weatherService: WeatherService,
    public et0Service: Et0LibService,
    private breakpointObserver: BreakpointObserver,
    public sessionLib: SessionLibService
  ) {}

  generateTooltip(groups: any[]): string {
    let tooltipContent = '';
    groups.forEach((group, index) => {
      tooltipContent +=
        this.translationsLib.get('irrigation_group') + ' ' + (group.group + 1);
      if (index < groups.length - 1) {
        tooltipContent += ', ';
      }
    });
    return tooltipContent;
  }

  ngAfterViewInit(): void {
    this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.XSmall])
      .subscribe((result) => {
        this.isMobile = result.matches;
      });
  }

  getGroupName(elem: any): string {
    if (elem.group_info.name === '' || elem.group_info.name === null) {
      return (
        this.translationsLib.get('irrigation_group') +
        ' ' +
        (elem.group_info.group + 1)
      );
    } else {
      return elem.group_info.name;
    }
  }

  getProgramTrimmedName(program: any): string {
    if (
      program.name.toString().trim() !== '' &&
      program.name.toString() !== null
    ) {
      return program.name;
    } else {
      return (
        this.translationsLib.get('irrigation_program') +
        ' ' +
        (program.program + 1)
      );
    }
  }

  removeInterval() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  getFertilicerFormulaText(elem: any): string {
    if (elem.fertilicerFormula === 255) {
      return '-';
    } else if (elem.fertilicerFormula === null) {
      return '-';
    } else if (elem.fertilicerFormula === 254) {
      return this.translationsLib.get('irrigation_group_selected');
    } else {
      if (
        elem.fertilizer_formula &&
        elem.fertilizer_formula.name !== '' &&
        elem.fertilizer_formula.name !== null
      ) {
        return elem.fertilizer_formula.name;
      } else {
        return (
          this.translationsLib.get('irrigation_fertilizer_formula') +
          ' ' +
          (elem.fertilicerFormula + 1)
        );
      }
    }
  }

  async ngOnInit() {
    this.terminal_vid = this.activatedRoute.snapshot.paramMap?.get('vid');

    this.activatedRoute.params.subscribe(async (_) => {
      if (this.activatedRoute.snapshot.paramMap?.get('tab')) {
        this.selectedTab = Number(
          this.activatedRoute.snapshot.paramMap?.get('tab')
        );
      }

      await this.getScreenLayoutConfig();
      await this.getTerminalData();

      this.wrapper?.loadData();

      if (this.terminalData.field === null) {
        await this.getCurrentWeatherByTerminal();
      } else {
        await this.getWeatherCurrentByField();
      }

      await this.postTerminalDataForVirtualInfo();

      this.isDataAvailable = true;

      this.intervalId = setInterval(async () => {
        await this.getScreenLayoutConfig();
        await this.getTerminalData();

        this.wrapper?.loadData();

        if (this.terminalData.field === null) {
          await this.getCurrentWeatherByTerminal();
        } else {
          await this.getWeatherCurrentByField();
        }

        await this.postTerminalDataForVirtualInfo();

        this.isDataAvailable = true;
      }, environment.intervalDefaultTimeout);
    });
  }

  getWeatherCurrentByField(): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      try {
        this.weatherService
          .getWeatherCurrentByField(this.terminalData.field.vid)
          .subscribe((res) => {
            this.weather = res;

            resolve(true);
          });
      } catch (err) {
        throw new Error(err);
      }
    });
  }

  getCurrentWeatherByTerminal(): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      try {
        this.weatherService
          .getCurrentWeatherByTerminal(this.terminal_vid)
          .subscribe(
            (res) => {
              this.weather = res;

              // @ts-ignore
              if (res?.error) resolve(false);
              else resolve(true);
            },
            (error) => {
              console.error(error.status);
              resolve(false);
            }
          );
      } catch (err) {
        throw new Error(err);
      }
    });
  }

  getTerminalData(): Promise<boolean> {
    this.isFetchingTerminalData = true;

    return new Promise<boolean>((resolve, _) => {
      this.breadcrumbs = [];
      this.irrigationService.getTerminal(this.terminal_vid).subscribe(
        async (res) => {
          this.terminalData = res;
          this.permissionsTerminal.setPermissions(this.terminal_vid, {
            authed_user_can_admin: res.authed_user_can_admin,
            authed_user_can_read: res.authed_user_can_read,
            authed_user_can_write: res.authed_user_can_write,
          });

          this.authedUserCanAdmin = res.authed_user_can_admin;

          if (res.field) {
            this.breadcrumbs.push({
              text: res.field.name,
              disabled: false,
              to: ['/field', { vid: res.field.vid }],
              active: false,
            });
          }
          this.breadcrumbs.push({
            text: res.name,
            disabled: false,
            active: true,
          });

          this.et0Service
            .getHistoricWeather(this.terminalData)
            .then((result) => {
              this.loadingEquipment = result.loadingEquipment;
              this.calculateET0Request = result.calculateET0Request;
            })
            .catch((error) => {
              console.log(error);
            });
          resolve(true);
          this.isFetchingTerminalData = false;
        },
        (_) => {
          this.terminalsLib.terminalNoRights();
          resolve(false);
        }
      );
    });
  }

  postTerminalDataForVirtualInfo(): Promise<boolean> {
    this.isFetchingEquipmentData = true;

    return new Promise<boolean>((resolve, _) => {
      try {
        const virtualInfo = {
          info: {
            meteo: {
              date: this.weather?.date,
              main_temp: this.weather?.main_temp,
              main_pressure: this.weather?.main_pressure,
              wind_speed: this.weather?.wind_speed,
              solar_rad: this.weather?.solar_rad,
              precip: this.weather?.precip,
              main_humidity: this.weather?.main_humidity,
              wind_direction: this.weather?.wind_deg,
            },
          },
        };

        this.irrigationService
          .postIrrigationTerminal(this.terminal_vid, virtualInfo)
          .subscribe(
            async (res) => {
              this.irrigationEquipmentVirtualPostResponse = res;
              this.irrigationEquipmentVirtualPostResponseTerminal = res;

              this.equipmentResponse = res as any;

              if (
                (res as any).alarms instanceof Array &&
                (res as any).alarms.length === 0
              ) {
                this.alarmRows = generateAlarmGrid([]);
              } else {
                this.alarmRows = generateAlarmGrid((res as any).alarms);
              }

              // Subscribe to route event to change title between route navigations
              this.activatedRoute.paramMap.subscribe((_) => {
                this.helpersLib.setTitle('', (res as any).name, null);
              });

              // Sensor/Virtual meteo list
              if (
                this.terminalData.field === null ||
                (!this.equipmentResponse.latitude &&
                  !this.equipmentResponse.longitude)
              ) {
                if (
                  this.terminalData.latitude !== null ||
                  this.terminalData.longitude !== null
                ) {
                  const isGetDataSuccess =
                    await this.getCurrentWeatherByTerminal();

                  if (!isGetDataSuccess) {
                    this.irrigationEquipmentVirtualPostResponseTerminal =
                      this.equipmentResponse;
                  }
                } else {
                  this.irrigationEquipmentVirtualPostResponseTerminal =
                    this.equipmentResponse;
                }
              } else {
                await this.et0Service
                  .getET0(this.calculateET0Request, this.equipmentResponse)
                  .then((result) => {
                    this.yesterday_ET0 = result.yesterday_ET0;
                    this.isFetchingET0 = result.isFetchingET0;

                    if (this.calculateET0Request !== undefined) {
                      if (this.terminalData.field === null) {
                        this.updateMeteoRows(
                          this.irrigationEquipmentVirtualPostResponseTerminal,
                          this.calculateET0Request
                        );
                      } else {
                        this.updateMeteoRows(
                          this.irrigationEquipmentVirtualPostResponse,
                          this.calculateET0Request
                        );
                      }
                    } else {
                      if (this.terminalData.field === null) {
                        this.updateMeteoRows(
                          this.irrigationEquipmentVirtualPostResponseTerminal,
                          this.calculateET0Request
                        );
                      } else {
                        this.updateMeteoRows(
                          this.irrigationEquipmentVirtualPostResponse,
                          this.calculateET0Request
                        );
                      }
                    }
                  });
              }

              resolve(true);
            },
            (_) => {
              this.terminalsLib.terminalNoRights();
              resolve(false);
            }
          );
      } catch (err) {
        throw new Error(err);
      } finally {
        this.isFetchingEquipmentData = false;
      }
    });
  }

  getPrograms(): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      this.isFetchingProgramData = true;

      try {
        this.irrigationService
          .getPrograms(this.terminal_vid)
          .subscribe((res) => {
            this.programsResponse = res;

            this.isFetchingProgramData = false;
            resolve(true);
          });
      } catch (err) {
        throw new Error(err);
      }
    });
  }

  getGroups(): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      this.isFetchingGroupData = true;

      try {
        this.irrigationService
          .getIrrigationGroups(this.terminal_vid)
          .subscribe((res) => {
            this.groupsResponse = res;

            this.isFetchingGroupData = false;
            resolve(true);
          });
      } catch (err) {
        throw new Error(err);
      }
    });
  }

  getFertilizerFormulas(): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      this.isFetchingFertilizerFormulasData = true;

      try {
        this.irrigationService
          .getFertilizerFormulas(this.terminal_vid)
          .subscribe((res) => {
            this.fertilizerFormulasResponse = res;

            this.isFetchingFertilizerFormulasData = false;
            resolve(true);
          });
      } catch (err) {
        throw new Error(err);
      }
    });
  }

  getDrainagesV4(): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      this.isFetchingDrainageData = true;

      try {
        this.irrigationService
          .getDrainagesV4(this.terminal_vid)
          .subscribe((res) => {
            this.drainagesResponse = res;

            this.isFetchingDrainageData = false;
            resolve(true);
          });
      } catch (err) {
        throw new Error(err);
      }
    });
  }

  getMixtureFormulas(): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      this.isFetchingMixtureFormulasData = true;

      try {
        this.irrigationService
          .getMixtureFormulas(this.terminal_vid)
          .subscribe((res) => {
            this.mixtureFormulasResponse = res;

            this.isFetchingMixtureFormulasData = false;
            resolve(true);
          });
      } catch (err) {
        throw new Error(err);
      }
    });
  }

  getScreenLayoutConfig(): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      this.irrigationService
        .getTerminalLayoutConfiguration(this.terminal_vid)
        .subscribe(async (res) => {
          this.screenConfiguration = res.screens_configuration;

          await this.getPrograms();
          await this.getGroups();
          await this.getFertilizerFormulas();
          await this.getDrainagesV4();
          await this.getMixtureFormulas();
        });

      resolve(true);
    });
  }

  getState(state: number): string {
    const DEFAULT_STATE = false;

    const STATES = {
      false: this.translationsLib.get('irrigation_program_inactive'),
      true: this.translationsLib.get('irrigation_program_active'),
    };

    return STATES[state] || DEFAULT_STATE;
  }

  updateMeteoRows(res: any, calculateET0Request: any) {
    if (res?.meteo === null || res?.meteo === []) res = this.equipmentResponse;

    if (res.meteo instanceof Array && res.meteo.length === 0) {
      this.meteoRows = generateMeteoGrid([
        {
          key: 'ET0',
          value: Number(this.yesterday_ET0),
          name: null,
          unit: 'mm',
          origin: null,
        },
      ]);
    } else {
      if (
        calculateET0Request !== undefined &&
        this.terminalData.field_vid !== undefined
      ) {
        if (this.yesterday_ET0 !== 'N/A') {
          res.meteo.unshift({
            key: 'ET0',
            value: Number(this.yesterday_ET0),
            name: 'ET0',
            unit: 'mm',
            origin: null,
          });
        }
      }

      this.meteoRows = generateMeteoGrid(res.meteo);
    }
  }

  postCancelAlarms(): Promise<boolean> {
    return new Promise((resolve, _) => {
      this.isPostCancelAlarms = true;

      try {
        this.irrigationService
          .postCancelAlarmsIrrigation(this.terminal_vid, {
            action: CancelAlarmsType.CANCEL_ALARMS,
          })
          .subscribe((_) => {
            this.isPostCancelAlarms = false;

            setTimeout(() => {
              Swal.fire({
                text: this.translationsLib.get('data_updated'),
                showConfirmButton: true,
                confirmButtonText: this.translationsLib.get('accept'),
                icon: 'success',
              });
            }, 1000);

            resolve(true);
          });
      } catch (err) {
        throw new Error(err);
      }
    });
  }

  // https://ionicframework.com/docs/api/router-outlet#life-cycle-hooks
  ionViewDidLeave() {
    this.removeInterval();
  }

  ngOnDestroy(): void {
    this.removeInterval();
  }
}
