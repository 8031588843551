import { Injectable } from '@angular/core';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
@Injectable({
  providedIn: 'root',
})
export class AlarmsLibService {
  public translationsLib: TranslationsLibService;
  constructor(translationsLib: TranslationsLibService) {
    this.translationsLib = translationsLib;
  }

  public getName(rawAlarm: string): string {
    let alarm = '';
    if (typeof rawAlarm === 'string') {
      rawAlarm.split(' ').forEach((ra) => {
        if (isNaN(parseInt(ra, 10))) {
          alarm += this.translationsLib.get('alarms_' + ra) + ' ';
        } else {
          alarm += ra + ' ';
        }
      });
      alarm = alarm.trim();
    }
    return alarm;
  }
}
