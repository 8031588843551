import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InputNumberService {
  constructor() {}

  public decrementValue(event: MouseEvent, decimals: number = 2): void {
    this.adjustInputValue(event, -1, decimals);
  }

  public incrementValue(event: MouseEvent, decimals: number = 2): void {
    this.adjustInputValue(event, 1, decimals);
  }

  private adjustInputValue(
    event: MouseEvent,
    delta: number,
    decimals: number
  ): void {
    const input = this.getInputElement(event);
    if (!input) return;

    const currentValue = parseFloat(input.value);
    const newValue = this.calculateNewValue(
      currentValue,
      delta,
      decimals,
      input
    );

    input.value = newValue.toString();
    input.dispatchEvent(new Event('input'));
  }

  private getInputElement(event: MouseEvent): HTMLInputElement | null {
    const button = event.target as HTMLButtonElement;
    return (
      (button?.parentNode?.querySelector(
        'input[type="number"]'
      ) as HTMLInputElement) || null
    );
  }

  private calculateNewValue(
    currentValue: number,
    delta: number,
    decimals: number,
    input: HTMLInputElement
  ): number {
    return decimals === 0
      ? this.applyStep(input, delta)
      : this.adjustValue(currentValue, delta, decimals, input);
  }

  private applyStep(input: HTMLInputElement, delta: number): number {
    if (delta > 0) {
      input.stepUp();
    } else {
      input.stepDown();
    }
    return parseFloat(input.value);
  }

  private adjustValue(
    currentValue: number,
    delta: number,
    decimals: number,
    input: HTMLInputElement
  ): number {
    const min = parseFloat(input.min);
    const max = parseFloat(input.max);

    let newValue = currentValue + delta;

    const needsSignChange =
      (currentValue > 0 && newValue < 0) || (currentValue < 0 && newValue > 0);
    newValue = needsSignChange
      ? parseFloat((-currentValue).toFixed(decimals))
      : parseFloat(newValue.toFixed(decimals));

    return Math.min(Math.max(newValue, min), max);
  }

  private getDecimalPart(value: number): number {
    const decimalIndex = value.toString().indexOf('.');
    return decimalIndex !== -1
      ? parseFloat('0.' + value.toString().substring(decimalIndex + 1))
      : 0;
  }
}
