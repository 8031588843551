export enum EquipmentGeneralTypes {
  irrigation = 'IRRIGATION',
  climate = 'CLIMATE',
  datalogger = 'DATALOGGER',
  configurable = 'CONFIGURABLE',
  unknown = 'UNKNOWN',
}

export interface EquipmentModel {
  connected: boolean;
  firstCommunication: string;
  img: string;
  lastMeasure: string; // TODO check configurable is any
  latitude: number;
  longitude: number;
  name: string;
  type: string;
  systemHour: string;
  timezone: string;
  warp: string;
}
