import { Component, Inject, OnInit } from '@angular/core';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { FanStepsModel, HeatersModel, VentModel } from '../../climate.model';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-generate-copy-modal',
  templateUrl: './generate-copy-modal.component.html',
  styleUrls: ['./generate-copy-modal.component.scss'],
  standalone: true,
  imports: [MatDialogModule, FormsModule, CommonModule, MatButtonModule],
})
export class GenerateCopyModalComponent implements OnInit {
  compartment = '';
  isCollapsed = false;

  zones = [];
  vents: VentModel;
  heaters: HeatersModel;
  fanStepsVentilationTemperature: FanStepsModel;
  currentVentilationFanStep: number;
  currentHeatingFanStep: number;
  currentZone: number;
  currentVentilationVent: number;
  selectedCheckboxes: boolean[] = [];
  ventsVentilationTemperatureSelectedCheckboxes: boolean[][] = [];
  fanStepsVentilationTemperatureSelectedCheckboxes: boolean[][] = [];
  fanStepsHeatingSelectedCheckboxes: boolean[][] = [];

  selectedOptionToCopy = '';
  public showMore: boolean[] = [];

  routeType: string;

  constructor(
    public translationsLib: TranslationsLibService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.compartment = data.compartment;
    this.zones = data.zones;
    this.currentZone = data.currentZone;
    this.routeType = data.routeType;
    this.vents = data.vents;
    this.currentVentilationVent = data.currentVent;
    this.currentVentilationFanStep = data.currentFanStep;
    this.fanStepsVentilationTemperature = data.fanSteps;
    this.heaters = data.heaters;
    this.currentHeatingFanStep = data.selectedStep;
  }

  onChangeOption(event) {
    this.selectedCheckboxes = [];
    this.selectedOptionToCopy = event.target.value;
  }

  toggleAllChildCheckboxesInVentilationTemperatureVents(index: number) {
    const isAnyElementInCheckboxesSelected = this.selectedCheckboxes[index];

    if (isAnyElementInCheckboxesSelected) {
      this.ventsVentilationTemperatureSelectedCheckboxes[index] = new Array(
        this.vents.zones[0].vents.length
      ).fill(true);
    } else {
      this.ventsVentilationTemperatureSelectedCheckboxes[index] = new Array(
        this.vents.zones[0].vents.length
      ).fill(false);
    }
  }

  toggleAllChildCheckboxesInVentilationTemperatureFanSteps(index: number) {
    const isAnyElementInCheckboxesSelected = this.selectedCheckboxes[index];

    if (isAnyElementInCheckboxesSelected) {
      this.fanStepsVentilationTemperatureSelectedCheckboxes[index] = new Array(
        this.fanStepsVentilationTemperature.zones[0].steps.length
      ).fill(true);
    } else {
      this.fanStepsVentilationTemperatureSelectedCheckboxes[index] = new Array(
        this.fanStepsVentilationTemperature.zones[0].steps.length
      ).fill(false);
    }
  }

  toggleAllChildCheckboxesInHeatingFanSteps(index: number) {
    const isAnyElementInCheckboxesSelected = this.selectedCheckboxes[index];

    if (isAnyElementInCheckboxesSelected) {
      this.fanStepsHeatingSelectedCheckboxes[index] = new Array(
        this.heaters.zones[0].steps.length
      ).fill(true);
    } else {
      this.fanStepsHeatingSelectedCheckboxes[index] = new Array(
        this.heaters.zones[0].steps.length
      ).fill(false);
    }
  }

  ngOnInit() {
    for (let i = 0; i < this.zones.length; i++) {
      this.showMore[i] = false;

      this.ventsVentilationTemperatureSelectedCheckboxes[i] = [];
      this.fanStepsVentilationTemperatureSelectedCheckboxes[i] = [];
      this.fanStepsHeatingSelectedCheckboxes[i] = [];
    }
  }

  updateCheckboxStatusVentilationTemperatureForVents(i: number) {
    const checkedIndexes = this.ventsVentilationTemperatureSelectedCheckboxes[i]
      .map((checked, index) => (checked ? index + 1 : null))
      .filter((value) => value !== null);

    if (this.ventsVentilationTemperatureSelectedCheckboxes[i].some((e) => e)) {
      this.selectedCheckboxes[i] = true;
    } else {
      this.selectedCheckboxes[i] = false;
    }
  }

  updateCheckboxStatusVentilationTemperatureForFanSteps(i: number) {
    const checkedIndexes =
      this.fanStepsVentilationTemperatureSelectedCheckboxes[i]
        .map((checked, index) => (checked ? index + 1 : null))
        .filter((value) => value !== null);

    if (
      this.fanStepsVentilationTemperatureSelectedCheckboxes[i].some((e) => e)
    ) {
      this.selectedCheckboxes[i] = true;
    } else {
      this.selectedCheckboxes[i] = false;
    }
  }

  updateCheckboxStatusHeatingForFanSteps(i: number) {
    const checkedIndexes = this.fanStepsHeatingSelectedCheckboxes[i]
      .map((checked, index) => (checked ? index + 1 : null))
      .filter((value) => value !== null);

    if (this.fanStepsHeatingSelectedCheckboxes[i].some((e) => e)) {
      this.selectedCheckboxes[i] = true;
    } else {
      this.selectedCheckboxes[i] = false;
    }
  }
}
