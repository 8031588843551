<app-main-header [breadcrumbs]="breadcrumbs"></app-main-header>
<ion-content>
  <div class="container-fluid p-1 p-lg-2">
    <div class="header-body border-0" *ngIf="!loading && fields.length === 0">
      <div class="row align-items-end">
        <div class="col-12 text-end">
          <a *ngIf="fields.length === 0" class="btn btn-secondary mx-3" (click)="showAttachDemoFieldAlert()">{{translationsLib.get('field_attach_demo_field')}}</a>
          <a *ngIf="terminalsWithoutField.length > 0" id="addField" class="btn btn-success" (click)="router.navigate(['./create-field'])">
            {{ translationsLib.get('field_add_field') }}
          </a>
          <a *ngIf="userTerminals.length === 0" id="addTerminal" class="btn btn-success" (click)="router.navigate(['./pair-new-terminal'])">
            {{ translationsLib.get('terminal_add_terminal') }}
          </a>
        </div>
      </div>
    </div>
    <div class="mx-lg-4">
      <div class="col-12" *ngIf="loading">
        <lib-loader></lib-loader>
      </div>
      <div class="col-12" id="noFields" *ngIf="!loading">
        <!-- NO TERMINALS AND NO FIELDS - Imagen añade equipo -->
        <div *ngIf="fields.length === 0 && userTerminals.length === 0">
          <div class="card">
            <div class="row">
              <div class="offset-2 offset-sm-3 col-8 col-sm-6 text-center">
                <h1 class="d-none d-sm-block img-title">{{ translationsLib.get('field_no_terminal_upper_title') }}</h1>
                <h1 class="d-sm-none img-title-small">{{ translationsLib.get('field_no_terminal_upper_title') }}</h1>
                <h2 class="d-none d-sm-block img-subtitle" [innerHTML]="translationsLib.get('field_no_terminal_bottom_text')"></h2>
                <h2 class="d-sm-none img-subtitle-small" [innerHTML]="translationsLib.get('field_no_terminal_bottom_text')"></h2>
              </div>
              <div class="col-2 col-sm-3">
                <img class="d-none d-sm-block"
                  src="assets/img/fields_and_terminals_empty/Flecha%20versión%20pc.svg" />
                <img class="d-sm-none"
                  src="assets/img/fields_and_terminals_empty/Flecha%20versión%20móvil.svg" />
              </div>
            </div>
            <div class="row">
              <div class="col text-center m-auto">
                <img class="d-none d-sm-block m-auto" style="max-width: 30vh"
                  src="assets/img/fields_and_terminals_empty/Sin%20equipos.svg"/>
                <img class="d-sm-none m-auto" style="max-width: 20vh"
                  src="assets/img/fields_and_terminals_empty/Sin%20equipos%20versión%20móvil.svg"
                />
              </div>
            </div>
          </div>
          <div class="mt-4">
            <app-demo-field-card></app-demo-field-card>
          </div>
        </div>

        <!-- NO FIELDS - Imagen sin fincas -->
        <div *ngIf="fields.length === 0">
          <div class="card">
            <div class="row">
              <div class="offset-2 offset-sm-3 col-8 col-sm-6 text-center">
                <ng-container *ngIf="terminalsWithoutField.length > 0">
                  <h1 class="d-none d-sm-block img-title">{{ translationsLib.get('field_no_field_upper_title') }}</h1>
                  <h1 class="d-sm-none img-title-small">{{ translationsLib.get('field_no_field_upper_title') }}</h1>
                  <h2 class="d-none d-sm-block img-subtitle"[innerHTML]="translationsLib.get('field_no_field_bottom_text')"></h2>
                  <h2 class="d-sm-none img-subtitle-small" [innerHTML]="translationsLib.get('field_no_field_bottom_text')"></h2>
                </ng-container>
                <ng-container *ngIf="terminalsWithoutField.length === 0">
                  <h1 class="d-none d-sm-block img-title">{{ translationsLib.get('field_no_field_upper_title') }}</h1>
                  <h1 class="d-sm-none img-title-small">{{ translationsLib.get('field_no_field_upper_title') }}</h1>
                  <h2 class="d-none d-sm-block img-subtitle"[innerHTML]="translationsLib.get('field_no_terminals_without_field')"></h2>
                  <h2 class="d-sm-none img-subtitle-small" [innerHTML]="translationsLib.get('field_no_terminals_without_field')"></h2>
                </ng-container>
              </div>
              <div class="col-2 col-sm-3" *ngIf="terminalsWithoutField.length > 0">
                <img class="d-none d-sm-block" src="assets/img/fields_and_terminals_empty/Flecha%20versión%20pc.svg" />
                <img class="d-sm-none" src="assets/img/fields_and_terminals_empty/Flecha%20versión%20móvil.svg" />
              </div>
            </div>
            <div class="row">
              <div class="col text-center m-auto">
                <img class="d-none d-sm-block m-auto" style="max-width: 50vh"
                  src="assets/img/fields_and_terminals_empty/Sin%20fincas%20versión%20pc.svg" />
                <img class="d-sm-none m-auto" style="max-width: 40vh"
                  src="assets/img/fields_and_terminals_empty/Sin%20fincas%20versión%20pc.svg" />
              </div>
            </div>
          </div>
        </div>

        <!-- HAS FIELDS - Mapa y demás -->
        <div *ngIf="fields.length > 0" id="container-fluid">
          <div class="row px-0">
            <!--<app-webapp-maps *ngIf="platform === false" [fields]="fields" [coming]="'fields-general'"></app-webapp-maps>-->
            <!--<app-native-maps *ngIf="platform === true" [fields]="fields" [coming]="'fields-general'"></app-native-maps>-->
            <lib-leaflet-maps [fields]="fields" [coming]="'fields-general'"></lib-leaflet-maps>
          </div>
          <div class="row">
            <div class="col-4">
              <h1 class="main-title p-0">{{ translationsLib.get('field_main_table_upper_text') }}</h1>
              <h1 class="p-0 mt-0">{{ translationsLib.get('field_main_table_bottom_text') }}</h1>
            </div>
            <div class="col-8 align-self-center text-end">
              <a *ngIf="fields.length === 0" class="btn btn-secondary mx-3" (click)="showAttachDemoFieldAlert()">{{translationsLib.get('field_attach_demo_field')}}</a>
              <a *ngIf="terminalsWithoutField.length > 0" class="btn btn-success" (click)="router.navigate(['./create-field'])">
                {{ translationsLib.get('field_add_field') }}
              </a>
              <a *ngIf="userTerminals.length === 0" class="btn btn-success" (click)="router.navigate(['./pair-new-terminal'])">
                {{ translationsLib.get('terminal_add_terminal') }}
              </a>
            </div>
            <app-fields-table></app-fields-table>
          </div>
        </div>

        <!-- Texto explicativo de por qué no aparece el botón añadir finca -->
        <div *ngIf="fields.length > 0 && terminalsWithoutField.length === 0">
          <div class="card">
            <div class="row">
              <div class="col text-center m-auto">
                <i>{{ translationsLib.get('field_no_terminals_without_field') }}</i>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</ion-content>
