import { Pipe, PipeTransform } from '@angular/core';
import { TranslationsLibService } from '@nutricontrol/app360-shared';

@Pipe({
  name: 'desktopMeteoKey',
})
export class DesktopMeteoKeyPipe implements PipeTransform {
  constructor(public translationsLib: TranslationsLibService) {}
  transform(meteoArray: any[], searchMeteoText: string): any[] {
    if (!meteoArray || !searchMeteoText) {
      return meteoArray;
    }

    return meteoArray.filter((meteo) => {
      return this.translationsLib
        .get('climate_' + meteo.key)
        .toLowerCase()
        .includes(searchMeteoText.toLowerCase());
    });
  }
}
