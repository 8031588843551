import { EquipmentModel } from '../farming.model';

export interface ConfigurableEquipmentModel extends EquipmentModel {
  pendingChanges: boolean;
  serialNumber: string;
  synchroHour: string;
  terminal: number;
  lastMeasure: any;
  states: ConfigurableStatesModel;
  alarms: ConfigurableAlarmModel[];
  meteo: [];
  et0: any;
}

export const MAX_VALVES = 32;
export const MAX_PUMPS = 9;
export const MAX_FERTILIZERS = 9;
export const MAX_CONFIG_VALVES = 7;
export const MAX_CONFIG_PUMPS = 3;
export const MAX_CONFIG_FERTILIZERS = 4;

export const FERTILIZER_TYPES = [
  { type: 'sequential', number: 1 },
  { type: 'proportional', number: 2 },
  { type: 'simultaneous', number: 3 },
];

export interface ConfigurableProgramModel {
  program: number;
  dirty?: boolean;
  name: string;
  active: boolean;
  startTime: number;
  endTime: number;
  diesel: boolean;
  week_days: string;
  week: Week;
  days: number;
  lastDayIrrigated?;
  demandTray?;
  idealPH?;
  maxCE?;
  pHControl?;
  prevProgram?;
  groups: ConfigurableGroupModel[];
}

export interface ConfigurableGroupModel {
  program: number;
  group: number;
  duration: number;
  preIrrigationTime: number;
  postIrrigationTime: number;
  fertilizerType: number;
  injections: number;
  fertilizerCycleTime: number;
  dirty: boolean;
  valves: ConfigurableValveModel[];
  pumps: ConfigurablePumpModel[];
  fertilizers: ConfigurableFertilizerModel[];
}

export interface ConfigurableFertilizerModel {
  program: number;
  group: number;
  order: number;
  fertilizer: number;
  time: number;
  dirty: boolean;
}

export interface ConfigurableValveModel {
  program: number;
  group: number;
  order: number;
  dirty: boolean;
  valve: number;
}

export interface ConfigurablePumpModel {
  program: number;
  group: number;
  order: number;
  pump: number;
  dirty: boolean;
}

export interface Week {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

export interface ConfigurableStatesModel {
  readingPH: number;
  readingCE: number;
  programs: ConfigurableProgramStateModel[];
  valves: ConfigurableValveStateModel[];
  timestamp: number;
  irrigating: boolean;
}

export interface ConfigurableProgramStateModel {
  program: number;
  state: string;
  name: string;
  actualPhase: number;
  actualTime: number;
  todayTime: number;
  actualGroup: number;
  actualVolume: number;
  todayVolume: number;
  activations: number;
  avgPH: number;
  flow: number;
  fertilizers: ConfigurableFertilizerStateModel[];
  TAbonado?: null;
  TPostRiego?: null;
  TPreRiego?: null;
  TRiegoTotal?: null;
  VAbonado?: null;
  VPostRiego?: null;
  VPreRiego?: null;
  VRiegoTotal?;
  currentGroup?: number;
  currentPhase?: number;
  currentTime?: number;
  currentVolume?: number;
}

export interface ConfigurableValveStateModel {
  valve: number;
  state: number;
}

export interface ConfigurableFertilizerStateModel {
  fertilizer: number;
  state: number;
}

export interface ConfigurableAlarmModel {
  code: number;
  raw: string;
  tag: string;
}

export interface EquipmentSubmitModel {
  info: EquipmentSubmitInfo;
}

export interface EquipmentSubmitInfo {
  meteo: EquipmentSubmitMeteo;
}

export interface EquipmentSubmitMeteo {
  date: string;
  main_temp: number;
  main_pressure: number;
  wind_speed: number;
  solar_rad: number;
  precip: number;
  main_humidity: number;
  wind_direction: number;
}
