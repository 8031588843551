<section id="events-calendar">
  <div class="{{ mode }}">
    <div class="row text-center">
      <div class="col-md-4 calendar-day-selector" *ngIf="mode !== 'widget'">
        <div class="btn-group">
          <div
            class="btn btn-secondary"
            mwlCalendarPreviousView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay($event)"
          >
            {{ translationsLib.get('previous') }}
          </div>
          <div
            class="btn btn-outline-secondary"
            mwlCalendarToday
            [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay($event)"
          >
            {{ translationsLib.get('calendar_today') }}
          </div>
          <div
            [ngClass]="{disabledNextDiv: !isActiveNext}"
            class="btn btn-secondary"
            mwlCalendarNextView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay($event)"
          >
            {{ translationsLib.get('next') }}
          </div>
        </div>
      </div>
      <div class="col-md-4 calendar-month-selector">
        <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'): this.sessionLib.getLanguage() }}</h3>
      </div>
      <div class="col-md-4 calendar-view-selector d-none d-xxl-block text-start">
        <div class="btn-group">
          <div
            class="btn btn-primary"
            (click)="setView(CalendarView.Month)"
            [class.active]="view === CalendarView.Month"
          >
            {{ translationsLib.get('calendar_month') }}
          </div>
          <div
            class="btn btn-primary"
            (click)="setView(CalendarView.Week)"
            [class.active]="view === CalendarView.Week"
          >
            {{ translationsLib.get('calendar_week') }}
          </div>
          <div
            class="btn btn-primary"
            (click)="setView(CalendarView.Day)"
            [class.active]="view === CalendarView.Day"
          >
            {{ translationsLib.get('calendar_day') }}
          </div>
        </div>
      </div>
      <div [ngClass]="{'col-12 text-center': mode !== 'widget', 'col-md-8 col-12 text-end': mode === 'widget' }">
        <div class="legend me-2" style="background-color: #777777;">
          {{ translationsLib.get('calendar_past_events') }}
        </div>
        <div class="legend me-2" style="background-color: #F3BD55;">
          {{ translationsLib.get('calendar_current_events') }}
        </div>
        <div class="legend" style="background-color: #66B9BF;">
          {{ translationsLib.get('calendar_future_events') }}
        </div>
      </div>
    </div>
    <div *ngIf="mode === 'screen'" class="my-md-4 text-center">
      <p class="m-0" *ngIf="switching_days_warning === true">
        <i>{{ translationsLib.get('calendar_switching_days_warning') }}</i>
      </p>
    </div>
    <ng-template #customCellTemplate let-day="day" let-locale="locale">
      <div class="cal-cell-top">
          <span class="cal-day-badge is-past" *ngIf="day.badgeTotal > 0 && day.isPast === true">{{ day.badgeTotal }}</span>
          <span class="cal-day-badge is-today" *ngIf="day.badgeTotal > 0 && day.isToday === true">{{ day.badgeTotal }}</span>
          <span class="cal-day-badge is-future" *ngIf="day.badgeTotal > 0 && day.isFuture === true">{{ day.badgeTotal }}</span>
        <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
      </div>
      <div class="cell-totals">
      </div>
    </ng-template>

    <ng-template
      #customOpenDayEventsTemplate
      let-events="events"
      let-isOpen="isOpen"
      let-eventClicked="eventClicked">
      <div class="cal-open-day-events p-2 p-lg-4" [@collapse] *ngIf="isOpen">

        <span class="p-3 text-muted" *ngIf="events.length === 0">
          {{ this.translationsLib.get('reports_no_data') }}
        </span>

        <!-- SCREEN -->
        <aside *ngIf="mode === 'screen'">
          <section class="calendar-open-day-container">
            <app-events-calendar-table
              [events]="events"
              dataHeaderLabel1="{{ translationsLib.get('calendar_event') }}"
              dataHeaderLabel2="{{ translationsLib.get('calendar_start') }}"
              dataHeaderLabel3="{{ translationsLib.get('calendar_end') }}"
              [showData2]="'true'"
              [showData3]="'true'"
              [showFilter]="'false'"
              [defaultPaginationItemsNumber]="5"
              [dateTimeFormat]="'HH:mm:ss'"
              *ngIf="events.length > 0"
            ></app-events-calendar-table>
          </section>
        </aside>

        <div
          *ngFor="let event of events; let i=index"
          [ngClass]="event?.cssClass"
          mwlDraggable
          [dropData]="{event: event}"
          [dragAxis]="{x: event.draggable, y: event.draggable}">

          <!-- WIDGET -->
          <aside *ngIf="mode === 'widget'">
            <aside *ngIf="i <= 5">
              <span
                class="cal-event"
                [style.backgroundColor]="event.color.primary">
              </span>
              <mwl-calendar-event-title
                [event]="event"
                view="month"
                (mwlClick)="eventClicked.emit({event: event})">
              </mwl-calendar-event-title>
              <mwl-calendar-event-actions [event]="event"></mwl-calendar-event-actions>
            </aside>
            <aside *ngIf="i === 5" class="text-end">
              <b>
                <a [routerLink]="['/terminal-calendar', {vid: terminal_vid}]">
                  {{ translationsLib.get('view_more') }}
                  <!--
                  CarlosG: no se puede calcular en este punto, el ngIf i === 5 hace que esté limitado al quinto elemento
                  del array
                  (+ {{ event.badgeTotal + 1 }})
                  -->
                </a>
              </b>
            </aside>
          </aside>

        </div>
      </div>
    </ng-template>

    <div [ngSwitch]="view">
      <mwl-calendar-month-view
        *ngSwitchCase="CalendarView.Month"
        [weekStartsOn]="1"
        [locale]="this.sessionLib.getLanguage()"
        [viewDate]="viewDate"
        [events]="events"
        [cellTemplate]="customCellTemplate"
        [openDayEventsTemplate]="customOpenDayEventsTemplate"
        [refresh]="refresh"
        [activeDayIsOpen]="activeDayIsOpen"
        (dayClicked)="dayClicked($event.day)"
        (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)"
      >
      </mwl-calendar-month-view>
      <mwl-calendar-week-view
        *ngSwitchCase="CalendarView.Week"
        [weekStartsOn]="1"
        [locale]="this.sessionLib.getLanguage()"
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)"
      >
      </mwl-calendar-week-view>
      <mwl-calendar-day-view
        *ngSwitchCase="CalendarView.Day"
        [locale]="this.sessionLib.getLanguage()"
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)"
      >
      </mwl-calendar-day-view>
    </div>

    <!-- CALENDAR TABLE -->
    <section *ngIf="mode === 'screen'">
      <app-events-calendar-log
        [past_events]="past_events"
        [future_events]="future_events"
        [current_events]="current_events"
      >
      </app-events-calendar-log>
    </section>

  </div>
</section>

