<app-main-header [breadcrumbs]="breadcrumbs"></app-main-header>

<ion-content>
  <div class="mx-3 mt-4" *ngIf="terminalData?.is_demo">
    <div class="row align-items-end">
      <div class="col">
        <app-demo-alert></app-demo-alert>
        <app-non-verified-installer-alert></app-non-verified-installer-alert>
      </div>
    </div>
  </div>

  <div class="container-fluid custom-container-fluid my-2">
    <div
      class="mb-4 d-flex justify-content-center mt-5"
      *ngIf="mixture_formula_form.isFetchingData"
    >
      <div class="spinner-border text-warning" role="status">
        <span class="visually-hidden">{{
          this.translationsLib.get('irrigation_loading')
        }}</span>
      </div>
    </div>
    <div
      *ngIf="
        !isFetchingData &&
        !mixture_formula_form.isFetchingData &&
        !isMixtureFormulasWithoutData
      "
    >
      <div class="mb-2">
        <div class="row">
          <app-generic-header
            *ngIf="mixtureFormulas && mixtureFormula"
            [elements]="mixtureFormulas"
            [text]="'name'"
            [selected]="mixtureFormula"
            [placeholder]="
              translationsLib.get('irrigation_change_mixture_formula')
            "
            [elementSearchId]="'formula'"
            [label]="translationsLib.get('irrigation_mixture_formula')"
            (changeSelected)="onChangePagination($event)"
          >
            <div irrigation>
              <app-mode
                *ngIf="!isMixtureFormulasWithoutData"
                (changeEventEmitter)="mode = $event"
              ></app-mode>
            </div>
          </app-generic-header>
        </div>
      </div>
    </div>
    <div
      [style.display]="mixture_formula_form.isFetchingData ? 'none' : 'block'"
    >
      <app-mixture-formulas-form
        [mode]="mode"
        [terminal_vid]="terminal_vid"
        [mixture_id]="formula_id"
        [reload]="true"
        (dirtyEventEmitter)="isDirty = $event"
        (nameEventEmitter)="mixtureFormulaValue = $event"
        (mixtureFormulaEventEmitter)="mixtureFormula = $event"
        #mixture_formula_form
      ></app-mixture-formulas-form>
    </div>
  </div>
</ion-content>

<footer
  *ngIf="!isMixtureFormulasWithoutData && !isFetchingTitle"
  class="footer mt-auto px-4 py-3 bg-light-soft border-top-3 border-light border global-irrigation-save-button"
>
  <div
    *ngIf="!terminalData?.authed_user_can_write && saveButtonTimeElapsed"
    class="row"
  >
    <div class="col">
      <app-cannot-write-alert></app-cannot-write-alert>
    </div>
  </div>
  <div class="d-flex justify-content-end" *ngIf="!saveButtonTimeElapsed">
    <div class="spinner-border text-warning" role="status">
      <span class="visually-hidden">{{
        translationsLib.get('climate_loading')
      }}</span>
    </div>
  </div>
  <div
    *ngIf="terminalData?.authed_user_can_write && saveButtonTimeElapsed"
    class="row"
  >
    <div
      class="col irrigation-save-button"
      [ngClass]="{ 'p-0': !hasScrollbar }"
    >
      <button
        (click)="mixture_formula_form.submitForm()"
        [disabled]="
          !mixture_formula_form.mixturesForm.value.isDirty &&
          !mixture_formula_form.mixturesForm.dirty
        "
        class="btn btn-primary float-end"
        [ngClass]="{
          'btn-danger':
            mixture_formula_form.mixturesForm.value.isDirty ||
            mixture_formula_form.mixturesForm.dirty
        }"
      >
        <span
          *ngIf="mixture_formula_form.isFormSubmitted"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        {{
          mixture_formula_form.isFormSubmitted
            ? this.translationsLib.get('saving')
            : this.translationsLib.get('irrigation_save_mixture_formula')
        }}
      </button>
    </div>
  </div>
</footer>
