<div class="mb-4 d-flex justify-content-center mt-5" *ngIf="isFetchingData">
  <div class="spinner-border text-warning" role="status">
    <span class="visually-hidden">{{
      this.translationsLib.get('irrigation_loading')
    }}</span>
  </div>
</div>

<app-dirty-message
  [conditions]="!isFetchingData"
  [dirty]="phCeRearmAlarmForm.value.dirty"
></app-dirty-message>

<ng-container
  *ngIf="mode === 'simple' && !isFetchingData && !isPHCERearmAlarmWithoutData"
>
  <form [formGroup]="phCeRearmAlarmForm" class="simple-form">
    <!-- Comunes pH -->
    <div class="card">
      <div class="card-body custom-card-body">
        <div class="row">
          <div class="col-sm-5 col-md-5 order-1 order-md-1">
            <h6 class="fs-2">
              {{ translationsLib.get('irrigation_common_ph') }}
            </h6>
            <p class="fs-5 fw-lighter">
              {{
                translationsLib.get('irrigation_common_ph_help') | sanitizeText
              }}
            </p>
          </div>
          <div class="col-sm-7 col-md-7 order-3 order-md-2 col-inputs">
            <div class="row first-row">
              <!-- Tolerancia pH -->
              <div class="col-sm-6 col-md-6">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_common_ph_tolerance') }}
                </h6>
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="tolerancePH"
                    type="number"
                    step="0.01"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_common_ph_tolerance')
                    }}"
                    step="0.01"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
              <!-- pH Máximo -->
              <div class="col-sm-6 col-md-6">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_common_ph_max') }}
                </h6>
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="PHMax"
                    type="number"
                    step="0.1"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_common_ph_max')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
            </div>
            <div class="row">
              <!-- Límite doble pH -->
              <div class="col-sm-6 col-md-6">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_common_double_ph_limit') }}
                </h6>
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="limitDoublePH"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_common_double_ph_limit')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
              <!-- Parada doble pH -->
              <div class="col-sm-6 col-md-6">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_common_double_ph_stop') }}
                </h6>
                <div class="input-group mb-2">
                  <select
                    class="form-select"
                    (change)="onChangeStopDoublePH()"
                    [(ngModel)]="selectedStopDoublePH"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option value="0">
                      {{ translationsLib.get('irrigation_total') }}
                    </option>
                    <option value="1">
                      {{ translationsLib.get('irrigation_common_inject') }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Comunes CE -->
    <div class="card">
      <div class="card-body custom-card-body">
        <div class="row">
          <div class="col-sm-5 col-md-5 order-1 order-md-1">
            <h6 class="fs-2">
              {{ translationsLib.get('irrigation_common_ce') }}
            </h6>
            <p class="fs-5 fw-lighter">
              {{
                translationsLib.get('irrigation_common_ce_help') | sanitizeText
              }}
            </p>
          </div>
          <div class="col-sm-7 col-md-7 order-3 order-md-2 col-inputs">
            <div class="row first-row">
              <!-- Tolerancia CE -->
              <div class="col-sm-6 col-md-6">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_common_ce_tolerance') }}
                </h6>
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="toleranceCE"
                    type="number"
                    step="0.01"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_common_ce_tolerance')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
              <!-- CE Mínima -->
              <div class="col-sm-6 col-md-6">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_common_ce_min') }}
                </h6>
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="CEMin"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_common_ce_min')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
            </div>
            <div class="row">
              <!-- Límite doble CE -->
              <div class="col-sm-6 col-md-6">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_common_double_ce_limit') }}
                </h6>
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="limitDoubleCE"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_common_double_ce_limit')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
              <!-- Parada doble CE -->
              <div class="col-sm-6 col-md-6">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_common_double_ce_stop') }}
                </h6>
                <div class="input-group mb-2">
                  <select
                    (change)="onChangeStopDoubleCE()"
                    class="form-select"
                    [(ngModel)]="selectedStopDoubleCE"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option value="0">
                      {{ translationsLib.get('irrigation_total') }}
                    </option>
                    <option value="1">
                      {{ translationsLib.get('irrigation_common_inject') }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <!-- Parada alarma CE -->
              <div class="col-sm-6 col-md-6">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_common_ce_alarm_stop') }}
                </h6>
              </div>
              <!-- Aportaciones especiales -->
              <div class="col-sm-6 col-md-6" formGroupName="stopAlarmCE">
                <div class="row align-items-center justify-content-between">
                  <div class="col">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get(
                          'irrigation_common_special_contributions'
                        )
                      }}
                    </h6>
                  </div>
                  <div class="col">
                    <div class="input-group">
                      <div class="form-check form-switch">
                        <input
                          formControlName="specialContributions"
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <!-- Volumen -->
              <div class="col-sm-6 col-md-6" formGroupName="stopAlarmCE">
                <div class="row align-items-center justify-content-between">
                  <div class="col">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_volume') }}
                    </h6>
                  </div>
                  <div class="col">
                    <div class="input-group">
                      <div class="form-check form-switch">
                        <input
                          formControlName="volume"
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Porcentaje -->
              <div class="col-sm-6 col-md-6" formGroupName="stopAlarmCE">
                <div class="row align-items-center justify-content-between">
                  <div class="col">
                    <h6 class="fs-5">
                      {{ this.translationsLib.get('percentage') }}
                    </h6>
                  </div>
                  <div class="col">
                    <div class="input-group">
                      <div class="form-check form-switch">
                        <input
                          formControlName="percent"
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Rearme alarma -->
    <div class="card">
      <div class="card-body custom-card-body">
        <div class="row">
          <div class="col-sm-5 col-md-5 order-1 order-md-1">
            <h6 class="fs-2">
              {{ translationsLib.get('irrigation_common_rearm_alarm') }}
            </h6>
            <p class="fs-5 fw-lighter">
              {{
                translationsLib.get('irrigation_common_rearm_alarm_help')
                  | sanitizeText
              }}
            </p>
          </div>
          <div class="col-sm-7 col-md-7 order-3 order-md-2 col-inputs">
            <div class="row first-row">
              <!-- Rearme alarma absoluta -->
              <div class="col-sm-6 col-md-6">
                <h6 class="fs-5">
                  {{
                    translationsLib.get(
                      'irrigation_common_rearm_alarm_absolute'
                    )
                  }}
                </h6>
                <div class="input-group mb-2">
                  <select
                    (change)="onChangeRearmAlarm()"
                    class="form-select"
                    [(ngModel)]="selectedRearmAlarm"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option value="0">
                      {{ this.translationsLib.get('never') }}
                    </option>
                    <option value="1">
                      {{
                        this.translationsLib.get(
                          'irrigation_common_three_times'
                        )
                      }}
                    </option>
                    <option value="2">
                      {{ this.translationsLib.get('always') }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-container>

<ng-container
  *ngIf="mode === 'advanced' && !isFetchingData && !isPHCERearmAlarmWithoutData"
>
  <form [formGroup]="phCeRearmAlarmForm" class="advanced-form">
    <div class="row">
      <div class="col">
        <!-- Comunes pH -->
        <div class="card">
          <div class="card-body custom-card-body">
            <div class="row">
              <div class="col-md-12">
                <h6 class="fs-2 input-label">
                  {{ translationsLib.get('irrigation_common_ph') }}
                </h6>
              </div>
            </div>
            <div class="row align-items-center">
              <!-- Tolerancia pH -->
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="row justify-content-between align-items-center">
                  <div class="col-md-6">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get('irrigation_common_ph_tolerance')
                      }}
                    </h6>
                  </div>
                  <div class="col-md-6">
                    <div class="input-group mb-2 number-input">
                      <button
                        class="minus minus-style"
                        (click)="inputNumberService.decrementValue($event)"
                      ></button>
                      <input
                        formControlName="tolerancePH"
                        type="number"
                        step="0.01"
                        class="form-control"
                        placeholder="{{
                          translationsLib.get('irrigation_common_ph_tolerance')
                        }}"
                        step="0.01"
                        min="-9999"
                        max="9999"
                      />
                      <button
                        class="plus border-plus plus-style"
                        (click)="inputNumberService.incrementValue($event)"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- pH Máximo -->
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="row align-items-center justify-content-between">
                  <div class="col-md-6">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_common_ph_max') }}
                    </h6>
                  </div>
                  <div class="col-md-6">
                    <div class="input-group mb-2 number-input">
                      <button
                        class="minus minus-style"
                        (click)="inputNumberService.decrementValue($event)"
                      ></button>
                      <input
                        formControlName="PHMax"
                        type="number"
                        step="0.1"
                        class="form-control"
                        placeholder="{{
                          translationsLib.get('irrigation_common_ph_max')
                        }}"
                        min="-9999"
                        max="9999"
                      />
                      <button
                        class="plus border-plus plus-style"
                        (click)="inputNumberService.incrementValue($event)"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center">
              <!-- Límite doble pH -->
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="row justify-content-between align-items-center">
                  <div class="col-md-6">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get('irrigation_common_double_ph_limit')
                      }}
                    </h6>
                  </div>
                  <div class="col-md-6">
                    <div class="input-group mb-2 number-input">
                      <button
                        class="minus minus-style"
                        (click)="inputNumberService.decrementValue($event)"
                      ></button>
                      <input
                        formControlName="limitDoublePH"
                        type="number"
                        class="form-control"
                        placeholder="{{
                          translationsLib.get(
                            'irrigation_common_double_ph_limit'
                          )
                        }}"
                        min="-9999"
                        max="9999"
                      />
                      <button
                        class="plus border-plus plus-style"
                        (click)="inputNumberService.incrementValue($event)"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Parada doble pH -->
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="row align-items-center justify-content-between">
                  <div class="col-md-6">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get('irrigation_common_double_ph_stop')
                      }}
                    </h6>
                  </div>
                  <div class="col-md-6">
                    <div class="input-group mb-2">
                      <select
                        class="form-select"
                        (change)="onChangeStopDoublePH()"
                        [(ngModel)]="selectedStopDoublePH"
                        [ngModelOptions]="{ standalone: true }"
                      >
                        <option value="0">
                          {{ translationsLib.get('irrigation_total') }}
                        </option>
                        <option value="1">
                          {{ translationsLib.get('irrigation_common_inject') }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Comunes CE -->
        <div class="card">
          <div class="card-body custom-card-body">
            <div class="row">
              <div class="col-md-12">
                <h6 class="fs-2 input-label">
                  {{ translationsLib.get('irrigation_common_ce') }}
                </h6>
              </div>
            </div>
            <div class="row align-items-center">
              <!-- Tolerancia CE -->
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="row justify-content-between align-items-center">
                  <div class="col-md-6">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get('irrigation_common_ce_tolerance')
                      }}
                    </h6>
                  </div>
                  <div class="col-md-6">
                    <div class="input-group mb-2 number-input">
                      <button
                        class="minus minus-style"
                        (click)="inputNumberService.decrementValue($event)"
                      ></button>
                      <input
                        formControlName="toleranceCE"
                        type="number"
                        step="0.01"
                        class="form-control"
                        placeholder="{{
                          translationsLib.get('irrigation_common_ce_tolerance')
                        }}"
                        min="-9999"
                        max="9999"
                      />
                      <button
                        class="plus border-plus plus-style"
                        (click)="inputNumberService.incrementValue($event)"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- CE Mínima -->
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="row justify-content-between align-items-center">
                  <div class="col-md-6">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_common_ce_min') }}
                    </h6>
                  </div>
                  <div class="col-md-6">
                    <div class="input-group mb-2 number-input">
                      <button
                        class="minus minus-style"
                        (click)="inputNumberService.decrementValue($event)"
                      ></button>
                      <input
                        formControlName="CEMin"
                        type="number"
                        class="form-control"
                        placeholder="{{
                          translationsLib.get('irrigation_common_ce_min')
                        }}"
                        min="-9999"
                        max="9999"
                      />
                      <button
                        class="plus border-plus plus-style"
                        (click)="inputNumberService.incrementValue($event)"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center">
              <!-- Límite doble CE -->
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="row justify-content-between align-items-center">
                  <div class="col-md-6">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get('irrigation_common_double_ce_limit')
                      }}
                    </h6>
                  </div>
                  <div class="col-md-6">
                    <div class="input-group mb-2 number-input">
                      <button
                        class="minus minus-style"
                        (click)="inputNumberService.decrementValue($event)"
                      ></button>
                      <input
                        formControlName="limitDoubleCE"
                        type="number"
                        class="form-control"
                        placeholder="{{
                          translationsLib.get(
                            'irrigation_common_double_ce_limit'
                          )
                        }}"
                        min="0"
                      />
                      <button
                        class="plus border-plus plus-style"
                        (click)="inputNumberService.incrementValue($event)"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Parada doble CE -->
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="row justify-content-between align-items-center">
                  <div class="col-md-6">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get('irrigation_common_double_ce_stop')
                      }}
                    </h6>
                  </div>
                  <div class="col-md-6">
                    <div class="input-group mb-2">
                      <select
                        (change)="onChangeStopDoubleCE()"
                        class="form-select"
                        [(ngModel)]="selectedStopDoubleCE"
                        [ngModelOptions]="{ standalone: true }"
                      >
                        <option value="0">
                          {{ translationsLib.get('irrigation_total') }}
                        </option>
                        <option value="1">
                          {{ translationsLib.get('irrigation_common_inject') }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center">
              <!-- Parada alarma CE -->
              <div class="col-sm-12 col-md-3 col-lg-3">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_common_ce_alarm_stop') }}
                </h6>
              </div>
              <!-- Aportaciones especiales -->
              <div class="col-sm-12 col-md-3 col-lg-3">
                <div class="row align-items-center justify-content-between">
                  <div class="col">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get(
                          'irrigation_common_special_contributions'
                        )
                      }}
                    </h6>
                  </div>
                  <div class="col" formGroupName="stopAlarmCE">
                    <div class="input-group">
                      <div class="form-check form-switch">
                        <input
                          formControlName="specialContributions"
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Volumen -->
              <div class="col-sm-12 col-md-3 col-lg-3">
                <div class="row align-items-center justify-content-between">
                  <div class="col">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_volume') }}
                    </h6>
                  </div>
                  <div class="col" formGroupName="stopAlarmCE">
                    <div class="input-group">
                      <div class="form-check form-switch">
                        <input
                          formControlName="volume"
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Porcentaje -->
              <div class="col-sm-12 col-md-3 col-lg-3">
                <div class="row align-items-center justify-content-between">
                  <div class="col">
                    <h6 class="fs-5">
                      {{ this.translationsLib.get('percentage') }}
                    </h6>
                  </div>
                  <div class="col" formGroupName="stopAlarmCE">
                    <div class="input-group">
                      <div class="form-check form-switch">
                        <input
                          formControlName="percent"
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Rearme alarma absoluta -->
        <div class="card">
          <div class="card-body custom-card-body">
            <div class="row">
              <div class="col-md-12">
                <h6 class="fs-2 input-label">
                  {{ translationsLib.get('irrigation_common_rearm_alarm') }}
                </h6>
              </div>
            </div>
            <div class="row align-items-center">
              <!-- Rearme alarma absoluta -->
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="row justify-content-between align-items-center">
                  <div class="col-md-6">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get(
                          'irrigation_common_rearm_alarm_absolute'
                        )
                      }}
                    </h6>
                  </div>
                  <div class="col-md-6">
                    <div class="input-group mb-2">
                      <select
                        (change)="onChangeRearmAlarm()"
                        class="form-select"
                        [(ngModel)]="selectedRearmAlarm"
                        [ngModelOptions]="{ standalone: true }"
                      >
                        <option value="0">
                          {{ this.translationsLib.get('never') }}
                        </option>
                        <option value="1">
                          {{
                            this.translationsLib.get(
                              'irrigation_common_three_times'
                            )
                          }}
                        </option>
                        <option value="2">
                          {{ this.translationsLib.get('always') }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-container>
