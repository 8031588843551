<div class="equipment-data-component equipment-{{ type }} position-relative" [ngClass]="cardClass">
  <div class="row g-0 g-md-4 mt-0 p-3 pb-lg-3">
    <div class="col-lg-1 d-none d-lg-block align-self-center m-md-auto">
      <img *ngIf="equipmentResponse?.img"
           src="{{ baseURL + '/' + equipmentResponse.img }}"
           class="terminal-image"
           alt="{{ equipmentResponse?.serialNumber }}"
      />
    </div>
    <div class="col-12 col-lg-9 col-md-10 mt-md-0">
      <div class="row g-0 g-md-4">
        <div class="col-6 col-md-5 mb-2 order-md-1">
          <div class="text-uppercase equipment-data-label fs-6 fs-md-5">
            {{ translationsLib.get('terminal_terminal') }}
          </div>
          <div class="equipment-data-value fw-bold fs-4 fs-md-1">
            {{ equipmentResponse?.name }}
          </div>
        </div>

        <div class="col-6 col-md-4 mb-2 order-md-2">
          <div class="text-uppercase equipment-data-label fs-6 fs-md-5">
            {{ translationsLib.get('terminal_serial_number') }}
          </div>
          <div class="equipment-data-value fs-4">
            {{ equipmentResponse?.serialNumber }}
          </div>
        </div>

        <div class="col-6 col-md-5 mb-2 order-md-4 mt-md-0">
          <div class="text-uppercase equipment-data-label fs-6 fs-md-5">
            <ng-container *ngIf="type !== 'datalogger'">
              {{ translationsLib.get('terminal_system_time') }}
            </ng-container>
            <ng-container *ngIf="type === 'datalogger'">
              {{ translationsLib.get('DL_PILLS_BATTERY') }} /
              {{ translationsLib.get('DL_PILLS_SIGNAL') }}
            </ng-container>
          </div>
          <div class="equipment-data-value fs-4">
            <ng-container *ngIf="type !== 'datalogger'">
              {{ equipmentResponse?.systemHour | transformDateTime }}
            </ng-container>
            <ng-container *ngIf="type === 'datalogger'">
              {{ dataloggerEquipment(equipmentResponse).datalogger_configuration.BATTERY }} mV /
              {{
              signalPercent(dataloggerEquipment(equipmentResponse).datalogger_configuration.SIGNAL)
              }} %
            </ng-container>
          </div>
        </div>

        <div class="col-6 col-md-4 mb-2 order-md-5 mt-md-0">
          <div class="text-uppercase equipment-data-label fs-6 fs-md-5">
            {{ translationsLib.get('terminal_model') }}
            /
            {{ translationsLib.get('terminal_version') }}
          </div>
          <div class="equipment-data-value fs-4">
            {{ equipmentResponse?.type }}
            /
            {{ getSemanticVersioning(equipmentResponse?.warp) }}
          </div>
        </div>

        <div class="col-6 col-md-3 mb-2 order-md-3">
          <div class="text-uppercase equipment-data-label fs-6 fs-md-5">
            {{ translationsLib.get('terminal_last_connection') }}
          </div>
          <div class="equipment-data-value fs-4">
            {{ equipmentResponse?.lastMeasure | transformDateTime }}
          </div>
        </div>

        <div class="col-6 col-md-3 mb-2 order-md-last mt-md-0">
          <div class="text-uppercase equipment-data-label fs-6 fs-md-5">
            {{ translationsLib.get('terminal_status') }}
          </div>
          <div class="equipment-data-value fs-4">
            <span class="badge"
                  [ngClass]="{
                    'bg-danger': !equipmentResponse?.connected,
                    'bg-success': equipmentResponse?.connected
                  }"
            >
              {{ equipmentResponse?.connected
              ? translationsLib.get('terminal_status_connected')
              : translationsLib.get('terminal_status_disconnected')
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-2 m-0 d-flex">
      <div
        class="d-flex flex-md-column flex-wrap align-items-end w-100 justify-content-end justify-content-md-between"
      >
        <a
          [routerLink]="['/edit-terminal', { vid: terminal_vid }]"
          *ngIf="hasPermissions"
          class="mb-3 col-6 col-md-auto pe-3 p-md-0"
        >
          <!-- Mobile button -->
          <ion-button
            size="small"
            class="m-0 d-flex d-md-none"
          >
            <ion-label>{{
              translationsLib.get('configuration_title')
              }}
            </ion-label>
          </ion-button>

          <!-- Desktop button -->
          <ion-button class="d-none d-md-flex m-0">
            <ion-icon name="cog-outline"></ion-icon>
          </ion-button>
        </a>

        <a
          [routerLink]="['desktop']"
          *ngIf="type === 'climate' && showStateLink"
          class="mb-3 col-6 col-md-auto"
          [ngClass]="{ 'pe-3 p-md-0': !hasPermissions }"
        >
          <!-- Mobile button -->
          <ion-button color="warning" size="small" class="d-flex d-md-none m-0">
            {{ translationsLib.get('climate_states') }}
          </ion-button>

          <!-- Desktop button -->
          <ion-button color="warning" class="d-none d-md-flex m-0">
            {{ translationsLib.get('climate_states') }}
          </ion-button>
        </a>

        <div class="mb-3 mb-md-0 col-6 col-md-auto">
          <!-- Mobile button -->
          <ion-button
            size="small"
            class="m-0 d-flex d-md-none"
            [ngClass]="
              showNotificationsTable
                ? 'ion-button-selected'
                : 'ion-button-light'
            "
            (click)="toggleNotificationsTable()"
          >
            <ion-label>{{
              translationsLib.get('notification_notifications')
              }}
            </ion-label>
          </ion-button>

          <!-- Desktop button -->
          <ion-button
            class="d-none d-md-flex m-0"
            [ngClass]="
              showNotificationsTable
                ? 'ion-button-selected'
                : 'ion-button-light'
            "
            (click)="toggleNotificationsTable()"
          >
            <ion-icon name="notifications-outline"></ion-icon>
          </ion-button>
        </div>
      </div>
    </div>
  </div>
  <app-notifications-table
    *ngIf="showNotificationsTable"
    [terminalVids]="[terminal_vid]"
    [type]="type"
  ></app-notifications-table>
</div>
