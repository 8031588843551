<lib-loader *ngIf="loading === true"></lib-loader>
<div class="container-fluid p-0">
  <div class="row px-1 g-2 mb-2 mt-2" *ngIf="loading === false">
    <div class="col-12 col-lg-6 mb-3"
         *ngIf="module !== null  && tensiometer !== undefined && tensiometer !== null">
      <app-terminal-irrigation-consulting-pill
        [irrigationBand]="irrigationBand"
        [status]="module.status"
        [height]="'55px'"
        [tensiometer]="tensiometer"></app-terminal-irrigation-consulting-pill>
    </div>

    <ng-container *ngFor="let meteo of pills">
      <div class="col-6 col-lg-3">
        <app-info-pill
          [class]="'mb-3'"
          [height]="'55px'"
          [type]="'DATALOGGER'"
          [value]="meteo.value | magnitudeValuePrecision: meteo.key"
          [magnitudeLabel]="pillMagnitudeLabel(meteo)"
          [magnitudeUnit]="meteo.unit"
          [magnitudeTooltip]="pillToolTip(meteo)"
          [icon]="meteo.key"
        >
        </app-info-pill>
      </div>
    </ng-container>
  </div>
  <div class="row mx-1" *ngIf="loading === false && elementsDisposition === 1 && tableSensors.length>0">
    <div class="card">
      <h4 class="col text-uppercase mx-4">{{translationsLib.get('DL_multipoints_sensor')}} </h4>
      <mat-form-field class="mx-4">
        <mat-label>{{translationsLib.get('search')}}</mat-label>
        <input matInput (keyup)="applyFilter($event)" #input>
      </mat-form-field>
      <div class="responsive">
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort class="mat-elevation-z8 drainages-table mx-2 w-100"
               matTableExporter #exporter="matTableExporter">
          <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns; index as i">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ translationsLib.get(column)}} </th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="i === 0 ">
                {{element.name}}
              </ng-container>
              <ng-container *ngIf="i === 1 ">
                {{element.readingPoint + 1}}
              </ng-container>
              <ng-container *ngIf="i === 2 ">
                {{element.magnitude}}
              </ng-container>
              <ng-container *ngIf="i === 3 ">
                {{element.value||'-'}}
              </ng-container>
              <ng-container *ngIf="i === 4 ">
                {{element.unit}}
              </ng-container>
              <ng-container *ngIf="i === 5 ">
                {{ element.datetime | transformDate }}
              </ng-container>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let row; columns: columnsToDisplay;">
          </tr>
        </table>

      </div>
    </div>
  </div>
  <div class="row mx-1" *ngIf="loading === false && elementsDisposition === 1">
    <div class="card mt-2" [hidden]="temp===null">
      <h4 class="col text-uppercase mx-4">{{translationsLib.get('DL_temp_graph')}} </h4>
      <app-graphs-with-day-selector *ngIf="sensoresConMagnitud.length>0" [terminal_vid]="terminal_vid" [connections]="sensoresConMagnitud" [equipment]="data"  [sensor]="['TEMP']"></app-graphs-with-day-selector>
    </div>
    <div class="card" *ngIf="data.datalogger_configuration.ET0 !== null" >
      <h4 class="col text-uppercase m-4">{{translationsLib.get('DL_et0_graph')}}</h4>
      <div class="alert alert-danger d-flex align-items-center" role="alert" *ngIf="gotETC===null">
          {{translationsLib.get('DL_alert_no_ETC')}}
      </div>
      <app-datalogger-eto-graph [equipment]="data"  [terminal_vid]="terminal_vid"></app-datalogger-eto-graph>
    </div>
  </div>
  <div class="row mx-1" *ngIf="loading === false && elementsDisposition === 2">
    <ng-container *ngIf="data.datalogger_configuration.ET0 === null" >
      <div class="col-12 col-md-12 graph px-md-1" [hidden]="temp===null">
        <div class="card ps-0 pe-0" *ngIf="this.module=== null || this.module.status !== 1">
          <h4 class="text-uppercase mx-4 mb-2">
            {{translationsLib.get('DL_temp_hr')}}
          </h4>
          <app-charts-component [equipment]="data" [terminal_vid]="terminal_vid" [time]="7" [sensorConnected]="temp" [elementsDisposition]="elementsDisposition"></app-charts-component>
        </div>
        <div class="ps-0 pe-0" *ngIf="this.module!== null && this.module.status === 1">
          <app-irrigation-consulting-main-chart [vid]="this.module.vid" [module]="this.module" [viewMode]="2"></app-irrigation-consulting-main-chart>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="data.datalogger_configuration.ET0 !== null && temp !== null">
      <div class="col-12 col-md-6 graph mb-0 mb-md-0 px-md-1">
        <div class="card ps-0 pe-0" *ngIf="this.module === null || this.module.status !== 1">
          <h4 class="text-uppercase mx-4 mb-2">
            {{translationsLib.get('DL_temp_hr')}}
          </h4>
          <app-charts-component [hidden]="data.length===0" [equipment]="data" [terminal_vid]="terminal_vid" [time]="7" [sensorConnected]="temp" [elementsDisposition]="elementsDisposition"></app-charts-component>
        </div>
        <div class="card ps-0 pe-0"  *ngIf="this.module !== null && this.module.status === 1">
          <h4 class="text-uppercase mx-4 mb-2">
            {{translationsLib.get('modules_irrigation_advisor')}}
          </h4>
          <app-irrigation-consulting-main-chart [vid]="this.module.vid" [module]="this.module" [viewMode]="2"></app-irrigation-consulting-main-chart>
        </div>
      </div>
      <div class="col-12 col-md-6 graph px-md-1">
        <div class="card ps-0 pe-0">
          <h4 class="text-uppercase mx-4 mb-2">{{translationsLib.get('DL_et0_graph')}}</h4>
          <div class="alert alert-danger d-flex align-items-center" role="alert" *ngIf="gotETC===null" >
              {{translationsLib.get('DL_alert_no_ETC')}}
          </div>
          <app-datalogger-eto-graph [equipment]="data"  [terminal_vid]="terminal_vid" [elementsDisposition]="elementsDisposition"></app-datalogger-eto-graph>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="data.datalogger_configuration.ET0 !== null && temp===null">
      <div class="col-12 mb-0 mb-md-0 graph px-md-1">
        <div class="card ps-0 pe-0">
          <h4 class="text-uppercase mx-4 mb-2">{{translationsLib.get('DL_et0_graph')}}</h4>
          <div class="alert alert-danger d-flex align-items-center cartel-alarma" role="alert" *ngIf="gotETC === null">
              {{translationsLib.get('DL_alert_no_ETC')}}
          </div>
          <app-datalogger-eto-graph [equipment]="data" [terminal_vid]="terminal_vid" [elementsDisposition]="elementsDisposition"></app-datalogger-eto-graph>
        </div>
      </div>

    </ng-container>
  </div>
</div>
