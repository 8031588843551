<section>
  <app-reports-programs-consumptions
    [createdReport]="createdReport"
    [begin]="begin"
    [end]="end"
    [prettyBegin]="prettyBegin"
    [prettyEnd]="prettyEnd"
    [advancedModeIsAllowed]="advancedModeIsAllowed"
    *ngIf="this.createdReport.report === 'programs_consumptions'"
  >
  </app-reports-programs-consumptions>
  <app-reports-grouping-consumptions
    [createdReport]="createdReport"
    [begin]="begin"
    [end]="end"
    [prettyBegin]="prettyBegin"
    [prettyEnd]="prettyEnd"
    [advancedModeIsAllowed]="advancedModeIsAllowed"
    *ngIf="this.createdReport.report === 'groups_consumptions'"
  >
  </app-reports-grouping-consumptions>
</section>
