import { Component, Input, OnInit } from '@angular/core';
import { DataloggerService } from '../datalogger.service';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import {
  App360SharedModule,
  TranslationsLibService,
} from '@nutricontrol/app360-shared';
import { DataloggerLibService } from 'src/app/farming/libraries/datalogger-lib.service';
import { IrrigationService } from '../../irrigation/irrigation.service';
import { GraphsWithDaySelectorComponent } from '../graphs-with-day-selector/graphs-with-day-selector.component';
import { DataloggerEtoGraphComponent } from '../datalogger-eto-graph/datalogger-eto-graph.component';
import { ChartsComponentComponent } from '../../../graphics/charts-component/charts-component.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { JsonPipe, NgForOf, NgIf } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { AuthModule } from '../../../auth/auth.module';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatInputModule } from '@angular/material/input';
import { IrrigationConsultingModule } from '../../../modules/irrigation-consulting/irrigation-consulting.module';
import {
  IrrigationBandDataModel,
  ModuleDataModel,
} from '../../../modules/modules.model';
import { MatSortModule } from '@angular/material/sort';
import { ModulesLibService } from '../../../services/libraries/modules-lib.service';
import { DashboardModule } from '../../../dashboard/dashboard.module';
import { AppCommonsModule } from '../../../commons/app-commons.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  standalone: true,
  selector: 'app-datalogger-dashboard-terminals-general',
  templateUrl: './datalogger-dashboard-terminals-general.component.html',
  styleUrls: ['./datalogger-dashboard-terminals-general.component.scss'],
  imports: [
    GraphsWithDaySelectorComponent,
    DataloggerEtoGraphComponent,
    ChartsComponentComponent,
    MatTableModule,
    MatFormFieldModule,
    NgIf,
    IonicModule,
    NgForOf,
    AuthModule,
    MatTableExporterModule,
    MatInputModule,
    IrrigationConsultingModule,
    JsonPipe,
    MatSortModule,
    DashboardModule,
    AppCommonsModule,
    MatTooltipModule,
    App360SharedModule,
  ],
})
export class DataloggerDashboardTerminalsGeneralComponent implements OnInit {
  @Input() terminal_vid = '';
  @Input() elementsDisposition = 1;
  @Input() data: any = [];
  @Input() connections: any = null;
  @Input() module: ModuleDataModel = null;
  irrigationBand: IrrigationBandDataModel = null;
  loading;
  pills = [];
  temp = null;
  gotETC = null;
  tensiometer = null;
  tableSensors = [];
  sensoresConMagnitud = [];
  constructor(
    public translationsLib: TranslationsLibService,
    private dataloggerService: DataloggerService,
    private dataloggerLib: DataloggerLibService,
    private irrigationService: IrrigationService,
    private modulesLibService: ModulesLibService
  ) {}
  displayedColumns: string[] = [
    'DL-sensor',
    'DL_lecture_point',
    'DL_magnitude',
    'DL_value',
    'DL_unit',
    'DL_last_connection',
  ];
  pillsOrder = [
    'SOIL_TENSION_SUP',
    'SOIL_TENSION_PROF',
    'SOIL_TENSION',
    'SOIL_HUM_SUP',
    'SOIL_HUM_PROF',
    'VWC',
    'SOIL_TEMP_SUP',
    'SOIL_TEMP_PROF',
    'SOIL_TEMP',
    'CE_SOIL_SUP',
    'CE_SOIL_PROF',
    'SOIL_EC',
    'TEMPERATURE',
    'HUMIDITY',
    'VPD',
    'ET0',
    'DWP',
    'WBT',
    'RADIATION',
    'WIND_VELOCITY',
    'PLUVIOMETER',
    'OTHER',
  ];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  dataSource: MatTableDataSource<any[]> = new MatTableDataSource<any[]>([]);

  ngOnInit() {
    if (this.module !== null) {
      this.modulesLibService
        .getModuleDataByModule(this.module)
        .subscribe((moduleData) => {
          this.irrigationBand =
            this.modulesLibService.getIrrigationBandFromModuleData(moduleData);
        });
      this.getModule();
    } else {
      this.createScreen();
    }
    if (this.elementsDisposition !== 1) {
      this.pillsOrder.push('VOLTAGE');
      this.pillsOrder.push('SIGNAL');
    }
  }

  createScreen() {
    if (this.elementsDisposition === 2) {
      this.getData();
    } else {
      this.getModel();
      this.getSensor('TEMP');
      this.irrigationService
        .getSensorsSubtypes(this.terminal_vid)
        .subscribe((res) => {
          this.sensoresConMagnitud = res;
          this.sensoresConMagnitud.forEach((sen, index) => {
            if (sen.physicalTypeName === 'SDI12' && sen.readingPoints) {
              this.sensoresConMagnitud.concat(sen.readingPoints);
            }
          });
        });
    }
  }
  getModule() {
    this.modulesLibService
      .getModuleByTerminalVidAndType(this.terminal_vid, 'irrigation_consulting')
      .subscribe((module) => {
        this.module = module;
        this.createScreen();
      });
  }
  getModel() {
    /*this.pills = this.data.meteo;
    console.log(this.pills, this.data.states);*/
    this.data.states.forEach((calc) => {
      this.data.meteo.forEach((meteo) => {
        if (calc.name === meteo.name && calc.value === meteo.value) {
          meteo.direction = calc.sensorNumber;
          if (calc.model === 'WBT') {
            meteo.key = 'WBT';
          }
          if (calc.model === 'DWP') {
            meteo.key = 'DWP';
          }
          this.pills.push(meteo);
        }
      });
      if (calc.model === 'ETc') {
        this.gotETC = calc;
      }
    });
    if (this.data.datalogger_configuration.ET0 != null) {
      this.pills.push({
        key: 'ET0',
        origin: 'VIRTUAL',
        unit: 'mm',
        value: this.data.datalogger_configuration.ET0,
      });
    }

    /*this.data.states.forEach((probe) => {
      if (
        probe.model !== 'ETc' &&
        probe.model !== 'ET0' &&
        probe.model !== 'SIGNAL' &&
        probe.model !== 'SUPPLY_VOLTAGE' &&
        probe.model !== 'BATTERY_VOLTAGE'
      ) {
        // Manage drill & drop probes!
        if (probe.readingPoints && probe.readingPoints.length > 3) {
          probe.readingPoints.forEach((point) => {
            point.magnitude = this.translationsLib.get(
              'DL_table_' + point.magnitude
            );
            this.tableSensors.push(point);
          });
        }
      }
    });*/
    this.pills = this.dataloggerLib.statesToPills(this.pills, this.data.states);
    this.pills = this.dataloggerLib.orderPills(this.pills, this.pillsOrder);
    this.tensiometer = this.dataloggerLib.getTensiometerForIrrigationConsulting(
      this.module,
      this.data.states
    );
    this.dataSource = new MatTableDataSource(this.tableSensors);
    this.loading = false;
  }
  getSensor(sensorString) {
    const sensorData = [];
    /*this.dataloggerService.getConnections(this.terminal_vid).subscribe(res=>{*/
    const sensors = this.dataloggerLib.getArrayOfsensors(this.connections);
    sensors.forEach((sensorConnected) => {
      if (typeof sensorString === 'string') {
        if (sensorConnected.magnitude === sensorString) {
          sensorData.push(sensorConnected);
        }
      } else {
        sensorString.forEach((sensorDeseado) => {
          if (sensorConnected.magnitude === sensorDeseado) {
            sensorData.push(sensorConnected);
          }
        });
      }
    });
    if (sensorData.length > 0) {
      this.temp = sensorData;
    }
    /*});*/
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getData() {
    this.dataloggerService
      .getDataloggerData(this.terminal_vid)
      .subscribe((res) => {
        this.data = res;
        this.getModel();
      });
    this.dataloggerService
      .getConnections(this.terminal_vid)
      .subscribe((res) => {
        this.connections = res;
        this.getSensor(['TEMP', 'RH']);
      });
  }

  pillMagnitudeLabel(pill): string {
    return pill.origin === 'SENSORCONNECTED'
      ? pill.name
      : this.translationsLib.get('DL_PILLS_' + pill.key);
  }

  pillHasTooltip(pill): boolean {
    return pill.origin !== 'SENSORCONNECTED' && !!pill.name;
  }

  pillToolTip(pill): string | undefined {
    if (!this.pillHasTooltip(pill)) {
      return undefined;
    }

    const pillTranslation = this.translationsLib.get('DL_PILLS_' + pill.key);
    return pill.name && pillTranslation !== pill.name.toUpperCase()
      ? pill.name
      : `${this.translationsLib.get('DL-sensor')}: ${pill.name}`;
  }
}
