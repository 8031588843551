<app-main-header [breadcrumbs]="breadcrumbs"></app-main-header>

<ion-content>
  <div
    *ngIf="!isDemandsWithoutData"
    class="container-fluid custom-container-fluid custom-margins"
  >
    <div class="card">
      <div class="card-body">
        <div class="mb-2">
          <div class="row justify-content-center align-items-center">
            <app-mode (changeEventEmitter)="mode = $event"></app-mode>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid custom-container-fluid">
    <div
      class="mb-4 d-flex justify-content-center mt-5"
      *ngIf="demand_form.isFetchingData && !isDemandsWithoutData"
    >
      <div class="spinner-border text-warning" role="status">
        <span class="visually-hidden">{{
          this.translationsLib.get('irrigation_loading')
        }}</span>
      </div>
    </div>
    <div [style.display]="demand_form.isFetchingData ? 'none' : 'block'">
      <app-demands-form
        [mode]="mode"
        [terminal_vid]="terminal_vid"
        [demand_id]="demand_id"
        (dirtyEventEmitter)="isDirty = $event"
        #demand_form
      ></app-demands-form>
    </div>
  </div>
</ion-content>

<footer
  *ngIf="!isDemandsWithoutData && !isFetchingTitle"
  class="footer mt-auto px-4 py-3 bg-light-soft border-top-3 border-light border global-irrigation-save-button"
>
  <div
    *ngIf="!terminalData?.authed_user_can_write && saveButtonTimeElapsed"
    class="row"
  >
    <div class="col">
      <app-cannot-write-alert></app-cannot-write-alert>
    </div>
  </div>
  <div class="d-flex justify-content-end" *ngIf="!saveButtonTimeElapsed">
    <div class="spinner-border text-warning" role="status">
      <span class="visually-hidden">{{
        translationsLib.get('climate_loading')
      }}</span>
    </div>
  </div>
  <div
    *ngIf="terminalData?.authed_user_can_write && saveButtonTimeElapsed"
    class="row"
  >
    <div
      class="col irrigation-save-button"
      [ngClass]="{ 'p-0': !hasScrollbar }"
    >
      <button
        (click)="demand_form.submitForm()"
        [disabled]="
          !demand_form.demandsForm.value.isDirty &&
          !demand_form.demandsForm.dirty
        "
        class="btn btn-primary float-end"
        [ngClass]="{
          'btn-danger':
            demand_form.demandsForm.value.isDirty ||
            demand_form.demandsForm.dirty
        }"
      >
        <span
          *ngIf="demand_form.isFormSubmitted"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        {{
          demand_form.isFormSubmitted
            ? this.translationsLib.get('saving')
            : this.translationsLib.get('irrigation_save_demand')
        }}
      </button>
    </div>
  </div>
</footer>
