import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, tap, share, finalize } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { SessionLibService } from '@nutricontrol/app360-shared';
import { SanitizeLibService } from '@nutricontrol/app360-shared';

@Injectable({
  providedIn: 'root',
})
export class HttpRepositoryService {
  private cache: any = {};

  private cachedObservable: any = {};

  private host = environment.backend;

  constructor(
    private http: HttpClient,
    private sessionApiService: SessionLibService,
    private sanitizeApiService: SanitizeLibService,
    private session: SessionLibService
  ) {}

  // Generic Get Request
  public getRequest(url: string): Observable<any> {
    //@TO-DO: Comprobar si la sesión ha caducado

    const cacheIndex = this.sanitizeApiService.kebab(url);

    let observableResponse: Observable<any>;

    if (
      typeof this.cache[cacheIndex] !== 'undefined' &&
      this.cache[cacheIndex]
    ) {
      observableResponse = of(this.cache[cacheIndex]);
    } else if (
      typeof this.cachedObservable[cacheIndex] !== 'undefined' &&
      this.cachedObservable[cacheIndex]
    ) {
      observableResponse = this.cachedObservable[cacheIndex];
    } else {
      const url_call = this.host + '/' + url;

      this.cachedObservable[cacheIndex] = this.http
        .get<any>(url_call, this.getHTTPOptions())
        .pipe(
          tap((response) => {
            this.cache[cacheIndex] = response;
          }),
          share(),
          finalize(() => {
            this.cache[cacheIndex] = this.cachedObservable[cacheIndex] = null;
          }),
          catchError(this.errorOutput)
        );
      observableResponse = this.cachedObservable[cacheIndex];
    }
    return observableResponse;
  }
  //
  // // Generic Post Request
  public postRequest(url: string, data: any): Observable<any> {
    //@TO-DO: Comprobar si la sesión ha caducado
    const urlCall = this.host + '/' + url;
    // @ts-ignore
    return this.http
      .post<any>(urlCall, JSON.stringify(data), this.getHTTPOptions())
      .pipe(retry(1), catchError(this.errorOutput));
  }
  //Generic Put Request
  public putRequest(url: string, data: any): Observable<any> {
    //@TO-DO: Comprobar si la sesión ha caducado
    const urlCall = this.host + '/' + url;
    // @ts-ignore
    return this.http
      .put<any>(urlCall, JSON.stringify(data), this.getHTTPOptions())
      .pipe(retry(1), catchError(this.errorOutput));
  }
  //Generic Delete request
  public deleteRequest(url: string): Observable<any> {
    //@TO-DO: Comprobar si la sesión ha caducado
    const urlCall = this.host + '/' + url;
    // @ts-ignore
    return this.http
      .delete<any>(urlCall, this.getHTTPOptions())
      .pipe(retry(1), catchError(this.errorOutput));
  }
  // Error output to console + screen
  private errorOutput(error: any) {
    let errorMessage = '';

    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage =
        'Error Status: ' + error.status + '\nError Message: ' + error.message;
    }

    console.error('RestAPI Service Error!', errorMessage);

    return throwError(error.error);
  }

  /**
   * JWT Interceptor overrides this headers
   *
   * @private
   */
  private getHTTPOptions(): any {
    const HTTPHeaders = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Language: this.session.getLanguage(),
    };

    const sessionToken = this.sessionApiService.getSessionToken();

    if (sessionToken && sessionToken !== '') {
      HTTPHeaders['Authorization'] = sessionToken;
    }
    //HTTP Options
    return {
      headers: new HttpHeaders(HTTPHeaders),
    };
  }
}
