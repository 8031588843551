import {
  Component,
  Input,
  OnInit,
  OnChanges,
  OnDestroy,
  AfterViewInit,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { WaterSystemsService } from './services/water-systems.service';
import { WaterSystemsDataService } from './services/water-systems-data.service';
import { WaterSystemsTransformerService } from './services/water-systems-transformer.service';
import {
  IrrigationEquipmentModel,
  IrrigationEquipmentProgram,
  IrrigationEquipmentWaterSystem,
} from '../../../farming/irrigation/irrigation.model';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { interval, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { WaterSystem, WaterSystems } from './models/water-systems.model';
import {
  WaterSystemsFieldConfig,
  WaterSystemsFieldsConfig,
} from './models/water-systems-config.model';

@Component({
  selector: 'app-water-systems',
  templateUrl: './water-systems.component.html',
  styleUrls: ['./water-systems.component.scss'],
})
export class WaterSystemsComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  @Input() equipment: IrrigationEquipmentModel;
  @Input() terminalVid: string;
  @Input() showLastConnection = true;
  protected isMobile = false;
  protected tabs: WaterSystems;
  protected fieldsConfigs: WaterSystemsFieldsConfig;
  protected configLoaded = false;
  protected equipmentLoaded = false;
  private intervalSubscription: Subscription;
  protected visibleTabIndex: number = undefined;
  protected showMore = false;

  constructor(
    private waterSystemsService: WaterSystemsService,
    private waterSystemsDataService: WaterSystemsDataService,
    private waterSystemsTransformerService: WaterSystemsTransformerService,
    private breakpointObserver: BreakpointObserver,
    private cdRef: ChangeDetectorRef,
    protected translationsLib: TranslationsLibService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.equipment) {
      this.tabs = this.waterSystemsTransformerService.extractWaterSystemsTabs(
        changes.equipment.currentValue
      );
      this.setVisibleTabIndex(
        changes.equipment.currentValue.states.waterSystems ?? [],
        changes.equipment.currentValue.states.programs ?? []
      );
      this.equipmentLoaded = true;
    }
    if (changes.terminalVid) {
      this.loadEquipment(changes.terminalVid.currentValue);

      // Reloads
      this.intervalSubscription = interval(
        environment.intervalDefaultTimeout
      ).subscribe(() => {
        this.loadEquipment(changes.terminalVid.currentValue);
      });
    }
  }

  ngOnInit() {
    this.loadConfig();
  }

  ngAfterViewInit() {
    this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.XSmall])
      .subscribe((result) => {
        this.isMobile = result.matches;
        this.cdRef.detectChanges();
      });
  }

  ngOnDestroy() {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }

  private loadConfig(): void {
    this.waterSystemsDataService.fetchConfig().subscribe({
      next: (configs) => {
        this.fieldsConfigs = configs;
        this.configLoaded = true;
      },
      error: (error) => {
        console.error('fetchConfig error:', error);
      },
    });
  }

  private loadEquipment(terminalVid: string) {
    this.waterSystemsDataService.fetchEquipment(terminalVid).subscribe({
      next: (irrigationEquipment) => {
        this.tabs =
          this.waterSystemsTransformerService.extractWaterSystemsTabs(
            irrigationEquipment
          );

        this.setVisibleTabIndex(
          irrigationEquipment.states.waterSystems,
          irrigationEquipment.states.programs
        );
        this.equipmentLoaded = true;
      },
      error: (error) => {
        console.error('FetchEquipment error:', error);
      },
    });
  }

  protected onTabChanged(event: MatTabChangeEvent) {
    this.visibleTabIndex = event.index;
  }

  protected setVisibleTabIndex(
    waterSystems: IrrigationEquipmentWaterSystem[],
    programStates: IrrigationEquipmentProgram[]
  ): void {
    if (this.visibleTabIndex !== undefined) {
      return;
    }
    this.visibleTabIndex =
      waterSystems.find((waterSystem) =>
        this.waterSystemsService.waterSystemHasActiveProgram(
          waterSystem,
          programStates
        )
      )?.waterSystem ?? 0;
    this.visibleTabIndex = 0;
  }

  protected isAnyFieldVisibleInRow(
    rowConfig: WaterSystemsFieldConfig[],
    tab: WaterSystem
  ): boolean {
    if (!rowConfig || !tab || !tab.fields) {
      return false;
    }
    return rowConfig.some(
      (fieldConfig) => tab.fields[fieldConfig.field]?.visible
    );
  }

  protected getFirstVisibleRows(
    configs: WaterSystemsFieldConfig[][],
    tab: WaterSystem,
    count: number
  ): WaterSystemsFieldConfig[][] {
    const visibleRows = configs.filter((rowConfig) =>
      this.isAnyFieldVisibleInRow(rowConfig, tab)
    );
    return visibleRows.slice(0, count);
  }

  protected getRemainingVisibleRows(
    configs: WaterSystemsFieldConfig[][],
    tab: WaterSystem,
    count: number
  ): WaterSystemsFieldConfig[][] {
    const visibleRows = configs.filter((rowConfig) =>
      this.isAnyFieldVisibleInRow(rowConfig, tab)
    );
    return visibleRows.slice(count);
  }

  protected hasRemainingVisibleRows(
    configs: WaterSystemsFieldConfig[][],
    tab: WaterSystem,
    count: number
  ): boolean {
    return this.getRemainingVisibleRows(configs, tab, count).length > 0;
  }

  protected toggleShowMore() {
    this.showMore = !this.showMore;
  }

  protected toTab(tab: WaterSystem): WaterSystem {
    return tab;
  }

  protected toRowConfig(
    rowConfig: WaterSystemsFieldConfig[]
  ): WaterSystemsFieldConfig[] {
    return rowConfig;
  }
}
