import { Injectable } from '@angular/core';
import { TranslationsLibService } from '@nutricontrol/app360-shared';

@Injectable({
  providedIn: 'root',
})
export class UsersLibService {
  translationsLib: TranslationsLibService;
  constructor(translationsLib: TranslationsLibService) {
    this.translationsLib = translationsLib;
  }
  isBackoffice(type: number) {
    return type >= 20 && type <= 29;
  }
  isBackofficeSuperAdmin(type: number) {
    return type === 20 || type === 21;
  }
  isInstaller(type: number) {
    return type === 2 || type === 52;
  }
  isNonConfirmedInstaller(type: number) {
    return type === 52;
  }
  getRolText(type: number): string {
    return this.translationsLib.get('user_type_' + type);
  }
  public getStatus(status: number): string {
    switch (status) {
      case 0: {
        return 'UNKNOWN';
      }
      case 1: {
        return 'OK';
      }
      case 50: {
        return 'Pendiente de invitar';
      }
      case 51: {
        return 'Invitación enviada no aceptada';
      }
    }
  }
  public statusIsInvitable(status: number): boolean {
    return status === 50 || status === 51;
  }
}
