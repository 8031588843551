import { Component } from '@angular/core';
import { SessionLibService } from '@nutricontrol/app360-shared';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { UserType } from '../../../commons/app-commons.model';

@Component({
  selector: 'app-non-verified-installer-alert',
  templateUrl: './non-verified-installer-alert.component.html',
  styleUrls: ['./non-verified-installer-alert.component.scss'],
})
export class NonVerifiedInstallerAlertComponent {
  nonVerifiedInstaller = UserType.nonVerifiedInstaller;
  constructor(
    public sessionLib: SessionLibService,
    public translationsLib: TranslationsLibService
  ) {}

  public refresh() {
    location.reload();
  }
}
