<app-main-header [breadcrumbs]="breadcrumbs"></app-main-header>

<ion-content>
  <div class="mx-3 my-4" *ngIf="terminalData?.is_demo">
    <div class="row align-items-end">
      <div class="col">
        <app-demo-alert></app-demo-alert>
        <app-non-verified-installer-alert></app-non-verified-installer-alert>
      </div>
    </div>
  </div>

  <ng-container>
    <div class="container-fluid custom-container-fluid my-2">
      <div *ngIf="!isFetchingData && !isFertilizerFormulasWithoutData">
        <div class="mb-2">
          <div class="row">
            <app-generic-header
              *ngIf="formulas && formula"
              [elements]="formulas"
              [text]="'name'"
              [selected]="formula"
              [placeholder]="
                translationsLib.get('irrigation_change_fertilizer_formula')
              "
              [elementSearchId]="'formula'"
              [label]="translationsLib.get('irrigation_fertilizer_formula')"
              (changeSelected)="onChangePagination($event)"
            >
              <div irrigation>
                <div class="row justify-content-end">
                  <div class="col-auto ps-0 dots">
                    <app-mode
                      *ngIf="!isFertilizerFormulasWithoutData"
                      (changeEventEmitter)="mode = $event"
                    ></app-mode>
                  </div>
                </div>
              </div>
            </app-generic-header>
          </div>
        </div>
      </div>
      <app-fertilizer-formulas-form
        [mode]="mode"
        [terminal_vid]="terminal_vid"
        [fertilizer_formula_id]="fertilizer_formula_id"
        [reload]="true"
        (nameEventEmitter)="fertilizerFormulaValue = $event"
        (dirtyEventEmitter)="isDirty = $event"
        (updatedFertilizerFormulasEventEmitter)="updateFormulasList($event)"
        (formulaEventEmitter)="formula = $event"
        #fertilizer_formulas_form
      ></app-fertilizer-formulas-form>
    </div>
  </ng-container>
</ion-content>

<footer
  *ngIf="!isFertilizerFormulasWithoutData && !isFetchingEquipmentData"
  class="footer mt-auto px-4 py-3 bg-light-soft border-top-3 border-light border global-irrigation-save-button"
>
  <div
    *ngIf="!terminalData?.authed_user_can_write && saveButtonTimeElapsed"
    class="row"
  >
    <div class="col">
      <app-cannot-write-alert></app-cannot-write-alert>
    </div>
  </div>
  <div class="d-flex justify-content-end" *ngIf="!saveButtonTimeElapsed">
    <div class="spinner-border text-warning" role="status">
      <span class="visually-hidden">{{
        translationsLib.get('climate_loading')
      }}</span>
    </div>
  </div>
  <div
    *ngIf="terminalData?.authed_user_can_write && saveButtonTimeElapsed"
    class="row"
  >
    <div
      class="col irrigation-save-button"
      [ngClass]="{ 'p-0': !hasScrollbar }"
    >
      <button
        (click)="fertilizer_formulas_form.confirmSave()"
        [disabled]="
          !fertilizer_formulas_form.fertilizerForm.value.isDirty &&
          !fertilizer_formulas_form.fertilizerForm.dirty
        "
        class="btn btn-primary float-end"
        [ngClass]="{
          'btn-danger':
            fertilizer_formulas_form.fertilizerForm.value.isDirty ||
            fertilizer_formulas_form.fertilizerForm.dirty
        }"
      >
        <span
          *ngIf="fertilizer_formulas_form.isFormSubmitted"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        {{
          fertilizer_formulas_form.isFormSubmitted
            ? this.translationsLib.get('saving')
            : this.translationsLib.get('irrigation_save_fertilizer_formula')
        }}
      </button>
    </div>
  </div>
</footer>
