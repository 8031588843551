import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  AgitatorsFertilizersNuve,
  AgitatorsIrrigationInstallerModel,
  FertilizerFertilizersNuve,
  Progab,
  PumpsIrrigationInstallerModel,
  WaterSystemFertilizersNuve,
} from '../../../irrigation.model';
import { IrrigationService } from '../../../irrigation.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  convertFormattedTimeToSeconds,
  DateToSecondsInput,
  removeDirtyInputs,
  saveDataAndShowModal,
  saveSubmitWithTerminalStatus,
  secondsInputToDate,
} from '../../../../../commons/helpers/functions';
import Swal from 'sweetalert2';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { EquipmentsLibService } from '../../../../../services/libraries/equipments-lib.service';
import { Subscription } from 'rxjs';
import { FormFieldHandlerService } from '../../../../libraries/form-field-handler.service';
import { InputNumberService } from '../../../../libraries/input-number.service';

@Component({
  selector: 'app-fertilizers-form',
  templateUrl: './fertilizers-form.component.html',
  styleUrls: ['./fertilizers-form.component.scss'],
})
export class FertilizersFormComponent implements OnInit, OnDestroy {
  isFetchingData = false;
  isFormSubmitted = false;
  isAgitatorFormSubmitted = false;
  isFertilizersWithoutData = false;
  isConnected: boolean;
  private intervalId: any;
  private formFieldErrorSubscription: Subscription;
  fertilizersForm: UntypedFormGroup;
  fertilizerForms: UntypedFormGroup[] = [];
  fertilizersResponse: FertilizerFertilizersNuve[];
  waterSystemsResponse: WaterSystemFertilizersNuve[];
  agitatorsResponse: AgitatorsFertilizersNuve[];
  pumpsResponse: PumpsIrrigationInstallerModel[];
  @Input() mode: string;
  @Input() terminal_vid: string;
  @Input() fertilizer_id: number;
  @Output() dirtyEventEmitter = new EventEmitter<boolean>();
  @Output() agitatordirtyEventEmitter = new EventEmitter<boolean>();
  @Output() formFieldErrorEventEmitter = new EventEmitter<boolean>();
  layoutConfiguration: Progab;
  filteredFertilizers: any[];
  filteredAgitators: any[];
  filteredForms: UntypedFormGroup[];
  isAgitatorIntervalDirty = false;
  isErrorInFertilizersPost = false;
  isErrorInAgitators = false;
  page = 1;

  constructor(
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private irrigationService: IrrigationService,
    private router: Router,
    public translationsLib: TranslationsLibService,
    private equipmentLib: EquipmentsLibService,
    private formFieldHandlerService: FormFieldHandlerService,
    public inputNumberService: InputNumberService
  ) {
    this.fertilizersForm = this.fb.group({
      fertilizer: [null, Validators.required],
      waterSystem: [null, Validators.required],
      dirty: [''],
      flow: [null, Validators.required],
      minInjec: [null, Validators.required],
      agitator: [null, Validators.required],
      injector: [null, Validators.required],
      name: [null],
      agitatorObject: this.fb.group({
        agitator: [null, Validators.required],
        TAgitON: [null, Validators.required],
        TAgitOFF: [null, Validators.required],
        agitatorInPreFertilized: [null, Validators.required],
        agitatorInFertilized: [null, Validators.required],
        agitatorInPostFertilized: [null, Validators.required],
        isDirty: false,
      }),
      isDirty: false,
    });
  }

  async startInterval() {
    this.intervalId = setInterval(() => {
      this.performTasks();
    }, 5000);
  }

  async performTasks() {
    this.fertilizersForm.reset();

    this.getWaterSystemsFertilizersAgitators();
    this.getPumps();
    this.getLayoutConfiguration();

    await this.getIrrigationEquipment();

    if (!this.isConnected) {
      this.equipmentLib.showConnectivityAlert();
    }

    this.isFormSubmitted = false;
  }

  async ngOnInit() {
    this.fertilizersForm.reset(); // Prevent unsaved data to persist in form inputs

    this.activatedRoute.params.subscribe((_) => {
      this.getWaterSystemsFertilizersAgitators();
      this.getPumps();
      this.getLayoutConfiguration();

      removeDirtyInputs(this.fertilizersForm);
    });

    this.fertilizersForm.valueChanges.subscribe((_) => {
      this.dirtyEventEmitter.emit(this.getIsDirty());

      if (this.getIsDirty() || this.isAgitatorIntervalDirty) {
        clearInterval(this.intervalId);
      } else {
        clearInterval(this.intervalId);
        this.startInterval();
      }
    });

    this.getIrrigationEquipment();
  }

  getIrrigationEquipment() {
    this.irrigationService
      .getIrrigationTerminal(this.terminal_vid)
      .subscribe((res) => {
        this.isConnected = res.connected;
      });
  }

  onWaterSystemChange(event) {
    this.filteredFertilizers = this.fertilizersResponse.filter((f) => {
      return f.waterSystem == event.target.value;
    });

    // Prevent mark the waterSystem select as dirty
    this.fertilizersForm.get('waterSystem').markAsPristine();
  }

  onAgitatorsChange(event) {
    this.filteredAgitators = this.agitatorsResponse.filter((a) => {
      return a.agitator === Number(event.target.value);
    });
  }

  getLayoutConfiguration() {
    this.irrigationService
      .getTerminalLayoutConfiguration(this.terminal_vid)
      .subscribe((res) => {
        this.layoutConfiguration = res.screens_configuration.progab;
        if (!this.layoutConfiguration.enabled) {
          this.router.navigate(['/home/dashboard']);
        }
      });
  }

  setDirty(dirty: boolean) {
    this.fertilizersForm.patchValue({ isDirty: dirty });
  }

  setAgitatorDirty(dirty: boolean) {
    this.isAgitatorIntervalDirty = dirty;

    if (this.isAgitatorIntervalDirty || this.getIsDirty()) {
      clearInterval(this.intervalId);
    } else {
      clearInterval(this.intervalId);
      this.startInterval();
    }

    this.fertilizerForms[this.page - 1].get('agitatorObject').patchValue({
      isDirty: dirty,
    });
  }

  getIsDirty() {
    return this.fertilizersForm.dirty || this.fertilizersForm.value.isDirty;
  }

  getAgitatorDirty() {
    return this.fertilizerForms[this.page - 1].value.agitatorObject.isDirty;
  }

  getPumps() {
    this.irrigationService.getPumps(this.terminal_vid).subscribe((res) => {
      this.pumpsResponse = res;
    });
  }

  async getAgitators(): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      try {
        this.irrigationService
          .getIrrigationInstallerAgitators(this.terminal_vid)
          .subscribe((res) => {
            this.agitatorsResponse = res;
            resolve(true);
          });
      } catch (err) {
        console.log(err);
        resolve(false);
      }
    });
  }

  async getFertilizers(): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      try {
        this.irrigationService
          .getIrrigationInstallerFertilizers(this.terminal_vid)
          .subscribe((res) => {
            this.fertilizersResponse = res;

            if (this.fertilizersResponse.length === 0)
              this.isFertilizersWithoutData = true;

            resolve(true);
          });
      } catch (err) {
        console.log(err);
        resolve(false);
      }
    });
  }

  async getWaterSystems(): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      try {
        this.irrigationService
          .getIrrigationInstallerWaterSystems(this.terminal_vid)
          .subscribe((res) => {
            this.waterSystemsResponse = res;
            resolve(true);
          });
      } catch (err) {
        console.log(err);
        resolve(false);
      }
    });
  }

  agitatorPlusQuantity(agitator, quantity) {
    return Number(agitator) + quantity;
  }

  onPageChange(event) {
    this.page = event;
  }

  createFormWithData(data: any, currentAgitator): FormGroup {
    const currentFertilizer = data;

    return this.fb.group({
      fertilizer: [currentFertilizer.fertilizer, Validators.required],
      waterSystem: [currentFertilizer.waterSystem, Validators.required],
      dirty: [currentFertilizer.dirty],
      flow: [currentFertilizer.flow, Validators.required],
      minInjec: [currentFertilizer.minInjec, Validators.required],
      agitator: [currentFertilizer.agitator, Validators.required],
      injector: [currentFertilizer.injector, Validators.required],
      name: [currentFertilizer.name],
      agitatorObject: this.fb.group({
        agitator: [currentFertilizer.agitator, Validators.required],
        TAgitON: [
          secondsInputToDate(Number(currentAgitator.TAgitON)),
          Validators.required,
        ],
        TAgitOFF: [
          secondsInputToDate(Number(currentAgitator.TAgitOFF)),
          Validators.required,
        ],
        agitatorInPreFertilized: [
          currentAgitator.agitatorInPreFertilized,
          Validators.required,
        ],
        agitatorInFertilized: [
          currentAgitator.agitatorInFertilized,
          Validators.required,
        ],
        agitatorInPostFertilized: [
          currentAgitator.agitatorInPostFertilized,
          Validators.required,
        ],
        isDirty: false,
      }),
      isDirty: false,
    });
  }

  getWaterSystemsFertilizersAgitators(isGetAfterPost = false) {
    if (!isGetAfterPost) this.isFetchingData = true;
    const pagination = 0;

    const agitatorsPromise = this.getAgitators();
    const waterSystemsPromise = this.getWaterSystems();
    const fertilizersPromise = this.getFertilizers();

    Promise.all([
      agitatorsPromise,
      waterSystemsPromise,
      fertilizersPromise,
    ]).then((results) => {
      const allSuccess = results.every((result) => result);

      if (!this.isFertilizersWithoutData) {
        if (allSuccess) {
          this.filteredAgitators = this.agitatorsResponse.filter(
            (a) => a.agitator === this.fertilizersResponse[pagination].agitator
          );

          const currentAgitator = this.agitatorsResponse.find(
            (ag) =>
              ag.agitator === this.fertilizersResponse[pagination].agitator
          );

          for (const fertilizer of this.fertilizersResponse) {
            const form = this.createFormWithData(fertilizer, currentAgitator);

            this.fertilizerForms.push(form);
          }

          for (const fe of this.fertilizerForms) {
            this.filteredFertilizers = this.fertilizersResponse.filter((f) => {
              return (
                f.waterSystem ===
                this.fertilizersResponse[pagination].waterSystem
              );
            });
          }
          const prevFilter = this.fertilizerForms
            .map((form) => form.value)
            .filter(
              (val) =>
                val.waterSystem ===
                this.fertilizersResponse[pagination].waterSystem
            );
          this.filteredForms = prevFilter.map((v) =>
            this.createFormWithData(v, currentAgitator)
          );

          if (!isGetAfterPost) this.isFetchingData = false;
          this.setDirty(false);
          this.setAgitatorDirty(false);
        } else {
          this.setDirty(false);
          this.setAgitatorDirty(false);
        }
      } else {
        this.isFetchingData = false;
      }
    });
  }

  onChangeInputTimeWithKeyboard(event, formInput: string, isAgitator = false) {
    if (isAgitator) {
      this.setAgitatorDirty(true);
    }

    event.target.classList.add('ng-dirty');
    this.fertilizersForm.markAsDirty();

    const toSeconds = convertFormattedTimeToSeconds(event.target.value);
    const toDate = secondsInputToDate(toSeconds);

    this.filteredForms[this.page - 1].get(formInput).setValue(toDate);
  }

  handlePostAgitators(
    showModal: boolean,
    connected: boolean
  ): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      this.isAgitatorFormSubmitted = true;

      const agitatorAsArr: AgitatorsIrrigationInstallerModel[] = [
        {
          agitator: Number(this.filteredForms[this.page - 1].value.agitator),
          TAgitON: DateToSecondsInput(
            this.filteredForms[this.page - 1].value.agitatorObject.TAgitON
          ),
          TAgitOFF: DateToSecondsInput(
            this.filteredForms[this.page - 1].value.agitatorObject.TAgitOFF
          ),
          agitatorInPreFertilized:
            this.filteredForms[this.page - 1].value.agitatorObject
              .agitatorInPreFertilized,
          agitatorInFertilized:
            this.filteredForms[this.page - 1].value.agitatorObject
              .agitatorInFertilized,
          agitatorInPostFertilized:
            this.filteredForms[this.page - 1].value.agitatorObject
              .agitatorInPostFertilized,
        },
      ];

      try {
        this.irrigationService
          .postIrrigationInstallerAgitators(this.terminal_vid, agitatorAsArr)
          .subscribe(
            (res) => {
              if (res.error) this.isErrorInAgitators = true;

              if (
                connected &&
                !this.filteredForms[this.page - 1].value.dirty &&
                !this.isErrorInAgitators
              ) {
                saveDataAndShowModal(
                  res,
                  showModal,
                  this.translationsLib.get('error_updating_data'),
                  this.translationsLib.get('save_changes_success'),
                  this.translationsLib.get('accept'),
                  () => {
                    setTimeout(() => {
                      this.getWaterSystemsFertilizersAgitators(true);
                    }, 4000);
                  }
                );

                this.isAgitatorFormSubmitted = false;
                resolve(true);
              } else if (this.isErrorInAgitators) {
                Swal.fire({
                  text: this.translationsLib.get(
                    'irrigation_general_program_error'
                  ),
                  showConfirmButton: true,
                  confirmButtonText: this.translationsLib.get('accept'),
                  icon: 'error',
                });

                this.isAgitatorFormSubmitted = false;
                resolve(true);
              } else {
                saveSubmitWithTerminalStatus(
                  res,
                  showModal,
                  connected,
                  this.translationsLib.get('error_updating_data'),
                  this.translationsLib.get('save_changes_success'),
                  this.translationsLib.get('accept'),
                  this.translationsLib.get(
                    'irrigation_disconnected_terminal_pending_changes'
                  )
                );

                this.isAgitatorFormSubmitted = false;
                resolve(true);
              }
            },
            (_) => {
              if (showModal) {
                Swal.fire({
                  text: this.translationsLib.get('something_was_wrong'),
                  showConfirmButton: true,
                  confirmButtonText: this.translationsLib.get('accept'),
                  icon: 'error',
                });
              }
              this.isFormSubmitted = false;
              resolve(false);
            }
          );
      } catch (err) {
        throw new Error(err.message);
      }
    });
  }

  submitAgitatorForm(
    showModal = true
  ): Promise<{ result: boolean; connected: boolean }> {
    return new Promise<{ result: boolean; connected: boolean }>(
      (resolve, reject) => {
        try {
          if (this.filteredForms[this.page - 1].valid) {
            this.irrigationService
              .getIrrigationTerminal(this.terminal_vid)
              .subscribe((res) => {
                this.handlePostAgitators(showModal, res.connected).then(
                  (result) => {
                    resolve({ result, connected: res.connected });
                  }
                );
              });
          } else {
            this.formFieldHandlerService.showErrorsAndAlerts(
              this.filteredForms[this.page - 1].valid
            );
            this.formFieldErrorSubscription =
              this.formFieldHandlerService.formFieldError$.subscribe(
                (hasError: boolean) => {
                  if (hasError) this.formFieldErrorEventEmitter.emit(true);
                }
              );
          }
        } catch (err) {
          reject({ result: false, connected: false });
        } finally {
          this.setAgitatorDirty(false);
        }
      }
    );
  }

  handlePostFertilizers(
    showModal: boolean,
    connected: boolean
  ): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      this.isFormSubmitted = true;

      this.waterSystemsResponse.find(
        (ws) =>
          ws.waterSystem === this.filteredForms[this.page - 1].value.waterSystem
      );

      const fertilizersAsArr: FertilizerFertilizersNuve[] = [
        {
          fertilizer: this.filteredForms[this.page - 1].value.fertilizer,
          waterSystem: this.filteredForms[this.page - 1].value.waterSystem,
          flow: this.filteredForms[this.page - 1].value.flow,
          minInjec: this.filteredForms[this.page - 1].value.minInjec,
          agitator: this.filteredForms[this.page - 1].value.agitator,
          injector: Number(this.filteredForms[this.page - 1].value.injector),
          name: this.filteredForms[this.page - 1].value.name,
          fertilizerPulse:
            this.filteredForms[this.page - 1].value.fertilizerPulse,
        },
      ];

      try {
        this.irrigationService
          .postIrrigationInstallerFertilizers(
            this.terminal_vid,
            fertilizersAsArr
          )
          .subscribe(
            (res) => {
              if (res.error) this.isErrorInFertilizersPost = true;

              if (
                connected &&
                !this.fertilizersForm.dirty &&
                !this.isErrorInFertilizersPost
              ) {
                saveDataAndShowModal(
                  res,
                  showModal,
                  this.translationsLib.get('error_updating_data'),
                  this.translationsLib.get('save_changes_success'),
                  this.translationsLib.get('accept'),
                  () => {
                    setTimeout(() => {
                      this.getWaterSystemsFertilizersAgitators(true);
                    }, 4000);
                  }
                );

                this.isFormSubmitted = false;
                resolve(true);
              } else if (this.isErrorInFertilizersPost) {
                Swal.fire({
                  text: this.translationsLib.get(
                    'irrigation_general_program_error'
                  ),
                  showConfirmButton: true,
                  confirmButtonText: this.translationsLib.get('accept'),
                  icon: 'error',
                });

                this.isFormSubmitted = false;
                resolve(true);
              } else {
                saveSubmitWithTerminalStatus(
                  res,
                  showModal,
                  connected,
                  this.translationsLib.get('error_updating_data'),
                  this.translationsLib.get('save_changes_success'),
                  this.translationsLib.get('accept'),
                  this.translationsLib.get(
                    'irrigation_disconnected_terminal_pending_changes'
                  )
                );

                this.isFormSubmitted = false;
                resolve(true);
              }
            },
            (_) => {
              if (showModal) {
                Swal.fire({
                  text: this.translationsLib.get('something_was_wrong'),
                  showConfirmButton: true,
                  confirmButtonText: this.translationsLib.get('accept'),
                  icon: 'error',
                });
              }
              this.isFormSubmitted = false;
              resolve(false);
            }
          );
      } catch (err) {
        throw new Error(err.message);
      }
    });
  }

  submitFertilizerForm(
    showModal = true
  ): Promise<{ result: boolean; connected: boolean }> {
    return new Promise<{ result: boolean; connected: boolean }>(
      (resolve, reject) => {
        try {
          this.irrigationService
            .getIrrigationTerminal(this.terminal_vid)
            .subscribe((res) => {
              this.handlePostFertilizers(showModal, res.connected).then(
                (result) => {
                  resolve({ result, connected: res.connected });
                }
              );
            });
        } catch (err) {
          reject({ result: false, connected: false });
        } finally {
          // Remove dirty inputs due to use standalone ngModels
          removeDirtyInputs(this.fertilizersForm);

          this.fertilizersForm.markAsUntouched();

          this.fertilizersForm.markAsPristine();
          this.fertilizersForm.patchValue({ isDirty: false });

          for (const f of this.filteredForms) {
            removeDirtyInputs(f);

            f.markAsUntouched();

            f.markAsPristine();
            f.patchValue({ isDirty: false });
          }
        }
      }
    );
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
    this.formFieldErrorSubscription?.unsubscribe();
  }
}
