import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Breadcrumb } from '../../../shared/shared.model';
import { ActivatedRoute, Router } from '@angular/router';
import { IrrigationService } from '../../../irrigation.service';
import { HelpersLibService } from '../../../../../services/libraries/helpers-lib.service';
import { DrainageV3, SafeData } from '../../../irrigation.model';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import {
  TranslationsLibService,
  TerminalDataModel,
} from '@nutricontrol/app360-shared';

@Component({
  selector: 'app-drainages-v3-edit',
  templateUrl: './drainages-v3-edit.component.html',
  styleUrls: ['./drainages-v3-edit.component.scss'],
})
export class DrainagesV3EditComponent
  implements OnInit, AfterViewInit, SafeData
{
  isFetchingData = false;
  drainages: DrainageV3[];
  isFormFieldWithErrors = false;
  drainagesControl = new FormControl<string | DrainageV3>('');
  filteredOptions: Observable<DrainageV3[]>;

  mode = '';
  isDrainagesV3WithoutData = false;
  breadcrumbs: Breadcrumb[] = [];
  isFetchingEquipmentData = false;
  drainage_v3_id: number;
  drainageV3Value: number;
  drainage: any;
  terminal_vid: string;
  isDirty = false;
  terminalData: TerminalDataModel;

  constructor(
    private router: Router,
    private irrigationService: IrrigationService,
    private helpersLib: HelpersLibService,
    public translationsLib: TranslationsLibService,
    private activatedRoute: ActivatedRoute
  ) {}

  isDataSaved(): boolean {
    return this.isDirty;
  }

  hasWritePermissions(): boolean {
    return this.terminalData.authed_user_can_write;
  }

  ngAfterViewInit() {
    this.activatedRoute.paramMap.subscribe((_) => {
      this.helpersLib.setTitle(
        '',
        `${this.translationsLib.get('irrigation_drainage')} ${
          this.drainage_v3_id + 1
        }`,
        null
      );
    });
  }

  async ngOnInit() {
    const [, , vid, , , data] = this.router.url.split('/');
    this.terminal_vid = vid;
    this.drainage_v3_id = Number(data);

    this.filteredOptions = this.drainagesControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const drainage = typeof value === 'string' ? value : value?.drainage;
        return drainage
          ? this._filter(drainage as string)
          : this.drainages.slice();
      })
    );

    await this.getDrainagesList();
    await this.generateBreadcrumbs();
  }

  generateBreadcrumbs(): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      this.isFetchingEquipmentData = true;
      this.breadcrumbs = [];
      this.irrigationService.getTerminal(this.terminal_vid).subscribe((res) => {
        this.terminalData = res;
        if (res.field) {
          this.breadcrumbs.push({
            text: res.field.name,
            disabled: false,
            to: ['/field', { vid: res.field.vid }],
            active: false,
          });
        }
        this.breadcrumbs.push(
          {
            text: res.name,
            disabled: false,
            to: `/farming/${this.terminal_vid}/irrigation`,
            active: false,
          },
          {
            text: this.drainageV3Value
              ? `${this.translationsLib.get('irrigation_drainage')} ${
                  this.drainage_v3_id + 1
                }`
              : `${this.translationsLib.get('irrigation_drainage')} ${
                  this.drainage_v3_id + 1
                }`,
            disabled: false,
            active: true,
          }
        );

        this.isFetchingEquipmentData = false;
        resolve(true);
      });
    });
  }

  private _filter(drainage: string): DrainageV3[] {
    const filterValue = drainage.toLowerCase();

    return this.drainages.filter((option) =>
      (
        this.translationsLib.get('irrigation_drainage') +
        ' ' +
        (option.drainage + 1).toString()
      )
        .toLowerCase()
        .includes(filterValue)
    );
  }

  onChangePagination(event) {
    // console.log('onChangePagination', event.option.value);
    this.router.navigate([
      `/farming/${this.terminal_vid}/irrigation/drainages_v3/${event.drainage}`,
    ]);
  }

  autocompleteDisplayFn(drainage: DrainageV3) {
    return drainage && drainage.drainage
      ? this.translationsLib.get('irrigation_drainage') +
          ' ' +
          (drainage.drainage + 1)
      : '';
  }

  getDrainagesList(): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      this.isFetchingData = true;
      this.irrigationService
        .getDrainagesV3(this.terminal_vid)
        .subscribe((res) => {
          this.drainages = res;

          if (this.drainages.length === 0) this.isDrainagesV3WithoutData = true;

          this.isFetchingData = false;
          resolve(true);
        });
    });
  }
}
