<div class="card">
  <div class="card-header">
    <h2 class="text-header-primary m-0">{{ translationsLib.get('notification_invitations') }}</h2>
    <a (click)="reload()" class="btn btn-primary p-2 lh-1">
      <ion-icon name="refresh-outline" class="d-flex fs-2"></ion-icon>
    </a>
  </div>
  <div class="card-body px-2 px-sm-4">
    <div class="row">
      <div class="col-12 col-md-4 col-xxl-3" *ngIf="this.showSideHelp === true">
        <div class="card d-none d-md-block mb-0" style="position: sticky; top: 1.5rem">
          <img src="assets/img/notifications/invitations.svg" class="card-img-top" alt="...">
          <div class="card-body">
            <h2 class="text-center mt-0 mb-4" style="color: var(--warning-color)">{{ translationsLib.get('notification_invitations_question') }}</h2>
            <p class="card-text" style="text-align: justify">{{ translationsLib.get('notification_invitations_explain') }}</p>
          </div>
        </div>
        <div class="card d-md-none">
          <div class="row align-items-center">
            <div class="d-none d-sm-block col-4">
              <img src="assets/img/notifications/invitations.svg" class="img-fluid" alt="...">
            </div>
            <div class="col-12 col-sm-8">
              <div class="card-body">
                <h2 class="text-center mt-0 mb-4" style="color: var(--warning-color)">{{ translationsLib.get('notification_invitations_question') }}</h2>
                <p class="card-text" style="text-align: justify">{{ translationsLib.get('notification_invitations_explain') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-8 col-xxl-9" [ngClass]="{'col-md-12 col-xxl-12': this.showSideHelp === false}">
        <lib-loader *ngIf="ready === false"></lib-loader>
        <div class="card mb-0" *ngIf="ready === true">
          <div class="card-body py-2">
            <div *ngIf="invitations.length === 0">
              <h4 class="my-4 text-center text-muted">{{ translationsLib.get('notification_no_invitations') }}</h4>
            </div>
            <div class="row" [ngClass]="{'border-bottom': !isLast}" style="min-height: 47px" *ngFor="let invitation of invitations; let isLast = last">

              <!-- REVERSE INVITATION: Invitation received -->
              <div class="align-self-center col-8 col-sm-9 col-xxl-10 my-2"
                   *ngIf="(invitation.target_user_email === userEmail && invitation.reverse_invitation === true)">
                <div class="row">
                  <div class="col-auto align-self-center d-none d-sm-block">
                    <img src="assets/img/notifications/received.svg" style="width: 25px;" alt="...">
                  </div>
                  <div class="col">
                    <strong>{{invitation.source_user_name}} <{{ invitation.source_user_email }}></strong>&nbsp;
                    <span>{{ translationsLib.get('notification_you_have_requested_to_be') }}</span>&nbsp;
                    <strong>{{getRolText(invitation.element_permission_type)}}</strong>&nbsp;
                    <span>{{ translationsLib.get('notification_of_the_terminal') }}</span>&nbsp;
                    <strong>{{invitation.element_name}}
                    </strong>
                  </div>
                </div>
              </div>

              <!-- REVERSE INVITATION: Invitation received -->
              <div class="align-self-center col-8 col-sm-9 col-xxl-10 my-2"
                   *ngIf="invitation.target_user_email !== userEmail && invitation.reverse_invitation === true">
                <div class="row">
                  <div class="col-auto align-self-center d-none d-sm-block">
                    <img src="assets/img/notifications/received.svg" style="width: 25px;" alt="...">
                  </div>
                  <div class="col">
                    <strong>{{invitation.target_user_name}} <{{ invitation.target_user_email }}></strong>&nbsp;
                    <span>{{ translationsLib.get('notification_you_have_received_a_request') }}</span>&nbsp;
                    <strong>{{getRolText(invitation.element_permission_type)}}</strong>&nbsp;
                    <span>{{ translationsLib.get('notification_of_the_terminal') }}</span>&nbsp;
                    <strong>{{invitation.element_name}}</strong>
                  </div>
                </div>
              </div>

              <!-- REGULAR INVITATION: Invitation sent -->
              <div class="align-self-center col-8 col-sm-9 col-xxl-10 my-2"
                   *ngIf="invitation.target_user_email !== userEmail && invitation.reverse_invitation === false">
                <div class="row">
                  <div class="col-auto align-self-center d-none d-sm-block">
                    <img src="assets/img/notifications/sent.svg" style="width: 25px;" alt="...">
                  </div>
                  <div class="col">
                    <span>{{ translationsLib.get('notification_you_have_invited1') }}</span>&nbsp;
                    <strong>{{invitation.target_user_name}} <{{ invitation.target_user_email }}></strong>&nbsp;
                    <span>{{ translationsLib.get('notification_you_have_invited2') }}</span>&nbsp;
                    <strong>{{getRolText(invitation.element_permission_type)}}</strong>&nbsp;
                    <span>{{ translationsLib.get('notification_you_have_invited3') }}</span>&nbsp;
                    <strong>{{invitation.element_name}}</strong>
                  </div>
                </div>
              </div>

              <!-- REGULAR INVITATION: Invitation received -->
              <div class="align-self-center col-8 col-sm-9 col-xxl-10 my-2"
                   *ngIf="invitation.target_user_email === userEmail && invitation.reverse_invitation === false">
                <div class="row">
                  <div class="col-auto align-self-center d-none d-sm-block">
                    <img src="assets/img/notifications/received.svg" style="width: 25px;" alt="...">
                  </div>
                  <div class="col">
                    <strong>{{invitation.source_user_name}} <{{ invitation.source_user_email }}></strong>&nbsp;
                    <span>{{ translationsLib.get('notification_you_have_been_invited1') }}</span>&nbsp;
                    <strong>{{getRolText(invitation.element_permission_type)}}</strong>&nbsp;
                    <span>{{ translationsLib.get('notification_you_have_been_invited2') }}</span>&nbsp;
                    <strong>{{invitation.element_name}}</strong>
                  </div>
                </div>
              </div>

              <div class="col-4 col-sm-3 col-xxl-2 my-2 align-self-center text-end p-1">
                <b *ngIf="invitation.invitation_status === 1">{{ translationsLib.get('notification_accepted') }}</b>
                <b *ngIf="invitation.invitation_status === 2">{{ translationsLib.get('notification_rejected') }}</b>
                <b *ngIf="invitation.invitation_status === 3">{{ translationsLib.get('notification_cancelled') }}</b>

                <button title="{{ translationsLib.get('notification_accept') }}" type="button" class="btn btn-success ms-2 p-2 lh-1"
                        *ngIf="invitation.can_be_accepted" (click)="putInvitationResponse(invitation.vid, invitation.invitation_type, 1)">
                  <ion-icon name="checkmark-circle-outline" class="d-flex fs-2"></ion-icon>
                </button>
                <button title="{{ translationsLib.get('notification_reject') }}" type="button" class="btn btn-danger ms-2 p-2 lh-1"
                        *ngIf="invitation.can_be_rejected" (click)="putInvitationResponse(invitation.vid, invitation.invitation_type, 2)">
                  <ion-icon name="close-outline" class="d-flex fs-2"></ion-icon>
                </button>
                <button title="{{ translationsLib.get('notification_resend_email') }}" type="button" class="btn btn-warning ms-2 p-2 lh-1"
                        *ngIf="invitation.can_be_resent" (click)="putResendEmail(invitation.vid, invitation.invitation_type)">
                  <ion-icon name="arrow-redo-outline" class="d-flex fs-2"></ion-icon>
                </button>
                <button title="{{ translationsLib.get('notification_cancel') }}" type="button" class="btn btn-secondary ms-2 p-2 lh-1"
                        *ngIf="invitation.can_be_cancelled" (click)="putInvitationResponse(invitation.vid, invitation.invitation_type, 3)">
                  <ion-icon name="trash-outline" class="d-flex fs-2"></ion-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
