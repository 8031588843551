<div class="container-fluid px-0 p-lg-2">
  <div class="row m-0 m-lg-2">
    <div class="col-12 my-2" *ngFor="let terminal of terminals; let i = index; let first = first ;let last = last;">
      <div class="row">
        <div class="col-12 col-xxl-3 px-1 mb-4 mb-xxl-0">
          <lib-terminal-header
            [equipment]="terminal" [showButton]="false"
            [showStatusIcon]="true"
            [connected]="isConnected(terminal)"
            [irrigating]="isIrrigating(terminal)"
          >
          </lib-terminal-header>
        </div>
        <div class="col-12 col-xxl-9 meteo-pills-container">
            <ng-container *ngIf="orderedByIndexEquipmentsPills[i] && orderedByIndexEquipmentsPills[i].length > 0">

              <ng-container *ngIf="terminalsLib.getType(terminal.type) === equipmentGeneralTypes.irrigation">
                <div class="row">
                  <div class="col-12 p-1 meteo-pills">
                    <app-water-systems
                      [equipment]="getIrrigationEquipment(terminal.vid)"
                      [showLastConnection]="false"
                    ></app-water-systems>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="this.terminalsLib.getType(terminal.type) === this.equipmentGeneralTypes.configurable">
                <div class="row">
                  <div class="col-12 p-1 meteo-pills">
                    <app-configurable-water-system
                      [equipment]="getConfigurableEquipment(terminal.vid)"
                      [class]="'mb-1'"
                      [showLastConnection]="false"
                    >
                    </app-configurable-water-system>
                  </div>
                </div>
              </ng-container>


              <ng-container *ngIf="terminalsLib.getType(terminal.type) === 'UNKNOWN'">
                <div class="row">
                  <div class="col-12 my-2">
                    <i>{{ translationsLib.get('no_results') }}</i>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="terminalsLib.getType(terminal.type) !== 'UNKNOWN'">
                <a class="row meteo-pills"
                   [href]="terminalsLib.generateRoute(terminal.vid, terminal.type)"
                   (click)="terminalsLib.goToRoute(terminal)">
                  <!-- IRRIGATION CONSULTING PILLS -->
                  <div class="col-12 col-lg-4 mb-2 px-1"
                       *ngIf="(orderedByIndexIrrigationConsultingBand[i] && orderedByIndexIrrigationConsultingTensiometer[i]) || orderedByIndexStatus[i] ===2 "
                  >
                    <app-terminal-irrigation-consulting-pill
                      [irrigationBand]="orderedByIndexIrrigationConsultingBand[i]"
                      [tensiometer]="orderedByIndexIrrigationConsultingTensiometer[i]"
                      [status]="orderedByIndexStatus[i]"
                    ></app-terminal-irrigation-consulting-pill>
                  </div>
                  <!-- REGULAR PILLS -->
                  <div class="col-auto mb-2 px-1" *ngFor="let pill of orderedByIndexEquipmentsPills[i]">
                    <app-info-pill
                      [type]="terminalsLib.getType(terminal.type)"
                      [magnitudeLabel]="pillMagnitudeLabel(pill, terminal)"
                      [value]="pill.value | magnitudeValuePrecision: pill.key"
                      [magnitudeUnit]="pill.unit"
                      [icon]="pill.key"
                    >
                    </app-info-pill>
                  </div>
                  <!-- LAST MEASURE -->
                  <div class="col-12 px-2 last-measure text-muted">
                      <b>
                        {{ translationsLib.get('terminal_last_connection') }}
                      </b> {{ orderedByIndexEquipmentsLastMeasure[i] | transformDateTime }}
                  </div>
                </a>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!orderedByIndexEquipmentsPills[i] || orderedByIndexEquipmentsPills[i].length === 0">
              <div class="row">
                <div class="col-12 my-2 px-1">
                  <i>{{ translationsLib.get('no_results') }}</i>
                </div>
              </div>
            </ng-container>
        </div>
      </div>
      <div class="row" *ngIf="!last">
        <div class="col-12 mb-2"></div>
      </div>
    </div>
  </div>
</div>

