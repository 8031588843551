import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MixtureFormulasModel, SafeData } from '../../irrigation.model';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Breadcrumb } from '../../shared/shared.model';
import { IrrigationService } from '../../irrigation.service';
import { FieldsService } from '../../../../fields/fields.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HelpersLibService } from '../../../../services/libraries/helpers-lib.service';
import { map, startWith } from 'rxjs/operators';
import {
  TranslationsLibService,
  TerminalDataModel,
} from '@nutricontrol/app360-shared';
import { EquipmentsLibService } from '../../../../services/libraries/equipments-lib.service';
import { IonContent } from '@ionic/angular';
import { environment } from '../../../../../environments/environment';
import { MixtureFormulasFormComponent } from '../mixture-formulas-form/mixture-formulas-form.component';

@Component({
  selector: 'app-mixture-formulas-edit',
  templateUrl: './mixture-formulas-edit.component.html',
  styleUrls: ['./mixture-formulas-edit.component.scss'],
})
export class MixtureFormulasEditComponent
  implements OnInit, SafeData, AfterViewInit, OnDestroy
{
  isFetchingData = false;
  isConnected: boolean;
  private intervalId: any;
  isFormFieldWithErrors = false;
  mixtureFormulas: MixtureFormulasModel[];
  mixtureControl = new FormControl<string | MixtureFormulasModel>('');
  filteredOptions: Observable<MixtureFormulasModel[]>;

  formula_id: number;
  terminal_vid: string;
  breadcrumbs: Breadcrumb[] = [];
  mixtureFormula: any;
  mixtureFormulaValue: number;
  isFetchingTitle = false;
  isDirty = false;
  mode = '';
  isMixtureFormulasWithoutData = false;
  terminalData: TerminalDataModel;
  @ViewChild(IonContent, { static: false }) private content: IonContent;
  hasScrollbar = false;
  saveButtonTimeElapsed = false;

  @ViewChild(MixtureFormulasFormComponent)
  mixtureFormulasFormComponent: MixtureFormulasFormComponent;

  constructor(
    private irrigationService: IrrigationService,
    private fieldsService: FieldsService,
    private route: ActivatedRoute,
    private router: Router,
    private helpersLib: HelpersLibService,
    public translationsLib: TranslationsLibService,
    private activatedRoute: ActivatedRoute,
    private equipmentLib: EquipmentsLibService
  ) {}

  /**
   * https://stackoverflow.com/questions/56882873/how-to-detect-if-ion-content-has-a-scrollbar/58579938#58579938
   * Apply CSS class based on vertical scroll (save button)
   */

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkForScrollbar();
  }

  async checkForScrollbar() {
    const scrollElement = await this.content.getScrollElement();
    this.hasScrollbar = scrollElement.scrollHeight > scrollElement.clientHeight;
  }

  isDataSaved(): boolean {
    return this.isDirty;
  }

  hasWritePermissions(): boolean {
    return this.terminalData.authed_user_can_write;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.saveButtonTimeElapsed = true;
      this.checkForScrollbar();
    }, 3000);

    this.activatedRoute.paramMap.subscribe((_) => {
      this.helpersLib.setTitle(
        '',
        `${this.translationsLib.get('irrigation_mixture_formula')} ${
          this.formula_id + 1
        }`,
        null
      );
    });
  }

  getIrrigationEquipment(): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      this.irrigationService
        .getIrrigationTerminal(this.terminal_vid)
        .subscribe((res) => {
          this.isConnected = res.connected;
          resolve(true);
        });
    });
  }

  async ngOnInit() {
    const [, , vid, , , data] = this.router.url.split('/');
    this.terminal_vid = vid;
    this.formula_id = Number(data);

    this.filteredOptions = this.mixtureControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const mixtureFormula =
          typeof value === 'string' ? value : value?.formula;
        return mixtureFormula
          ? this._filter(mixtureFormula as string)
          : this.mixtureFormulas.slice();
      })
    );

    await this.getMixturesList();
    await this.getIrrigationEquipment();
    await this.generateTitleAndBreadcrumbs();

    this.intervalId = setInterval(async () => {
      await this.getIrrigationEquipment();

      if (!this.isConnected) {
        this.equipmentLib.showConnectivityAlert();
      }

      this.mixtureFormulasFormComponent.isFormSubmitted = false;
    }, environment.intervalDefaultTimeout);
  }

  generateTitleAndBreadcrumbs(): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      this.isFetchingTitle = true;
      this.breadcrumbs = [];
      this.irrigationService.getTerminal(this.terminal_vid).subscribe((res) => {
        this.terminalData = res;
        if (res.field) {
          this.breadcrumbs.push({
            text: res.field.name,
            disabled: false,
            to: ['/field', { vid: res.field.vid }],
            active: false,
          });
        }

        this.breadcrumbs.push(
          {
            text: res.name,
            disabled: false,
            to: `/farming/${this.terminal_vid}/irrigation`,
            active: false,
          },
          {
            text: this.mixtureFormulaValue
              ? `${this.translationsLib.get('irrigation_mixture_formula')} ${
                  this.formula_id + 1
                }`
              : `${this.translationsLib.get('irrigation_mixture_formula')} ${
                  this.formula_id + 1
                }`,
            disabled: false,
            active: true,
          }
        );

        this.isFetchingTitle = false;
        resolve(true);
      });
    });
  }

  onChangeMode(event) {
    this.mode = event;
  }

  private _filter(mixtureFormula: string): MixtureFormulasModel[] {
    const filterValue = mixtureFormula.toLowerCase();

    return this.mixtureFormulas.filter((option) =>
      (
        this.translationsLib.get('irrigation_mixture_formula') +
        ' ' +
        (option.formula + 1).toString()
      )
        .toLowerCase()
        .includes(filterValue)
    );
  }

  onChangePagination(event) {
    // console.log('onChangePagination', event.option.value);
    this.router.navigate([
      `/farming/${this.terminal_vid}/irrigation/mixture_formulas/${event.formula}`,
    ]);
  }

  autocompleteDisplayFn(mixtureFormula: MixtureFormulasModel) {
    return mixtureFormula && mixtureFormula.formula
      ? this.translationsLib.get('irrigation_mixture_formula') +
          ' ' +
          (mixtureFormula.formula + 1)
      : '';
  }

  getMixturesList(): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      this.isFetchingData = true;
      this.irrigationService
        .getMixtureFormulas(this.terminal_vid)
        .subscribe((res) => {
          this.mixtureFormulas = res;

          if (this.mixtureFormulas.length === 0)
            this.isMixtureFormulasWithoutData = true;

          this.isFetchingData = false;
          resolve(true);
        });
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }
}
