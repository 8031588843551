<div class="card p-3 shadow-none">
  <div class="card-header p-0">
    <div class="card-header-title">
      <div class="row">
        <div class="col-6">
          <h1 class="text-start">{{ translationsLib.get('permission_main_permissions') }}</h1>
        </div>
        <div class="col-6 align-self-center">

        </div>
      </div>
    </div>
  </div>
  <div class="card-body p-0">
    <div class="row" *ngIf="isMobile">
      <!-- MOBILE MAIN PERMISSIONS: INSTALLER -->
      <div class="col-12 mt-2" *ngIf="mode === 'terminal'">
        <div>
          {{ translationsLib.get('permission_installer') }}
        </div>
        <div class="fw-bold mt-2">
          <ng-container *ngIf="installer">
            <div class="fw-bold">
              {{ installer.name }} {{ installer.surname }}
            </div>
            <div class="text-muted">
              {{ installer.email }}
            </div>
          </ng-container>
          <ng-container *ngIf="!installer">
            <div class="fw-bold">
              {{ translationsLib.get('permission_no_installer') }}
            </div>
          </ng-container>
        </div>
        <div class="fw-normal text-muted mt-1" *ngIf="!installer">
          {{ translationsLib.get('permission_invitation_pending') }}
        </div>
        <div class="mt-3" *ngIf="authedUserCanAdminInstaller">
          <ng-container *ngTemplateOutlet="installerAction"></ng-container>
        </div>
      </div>
      <hr *ngIf="mode === 'terminal'" class="mt-3 mb-2">
      <!-- MOBILE MAIN PERMISSIONS: OWNER -->
      <div class="col-12">
        <div>
          {{ translationsLib.get('permission_owner') }}
        </div>
        <div class="fw-bold mt-2">
          <ng-container *ngIf="owner">
            <div class="fw-bold">
              {{ owner.name }} {{ owner.surname }}
            </div>
            <div class="text-muted">
              {{ owner.email }}
            </div>
          </ng-container>
          <ng-container *ngIf="!owner">
            <div class="fw-bold">
              {{ translationsLib.get('permission_no_owner') }}
            </div>
          </ng-container>
        </div>
        <div class="d-block d-sm-none text-muted mt-1" *ngIf="!owner">
          {{ translationsLib.get('permission_invitation_pending') }}
        </div>
        <div class="mt-3" *ngIf="authedUserCanAdminOwner">
          <ng-container *ngTemplateOutlet="ownerAction"></ng-container>
        </div>
      </div>
    </div>

    <!-- DESKTOP MAIN PERMISSIONS TABLE -->
    <table *ngIf="!isMobile"
           class="table table-responsive-sm card-table">
      <thead>
      <tr>
        <th class="col-4 px-1 px-sm-2">
          {{ translationsLib.get('permission_user_s') }}
        </th>
        <th class="col-2 px-1 px-sm-2">
          {{ translationsLib.get('permission_type') }}
        </th>
        <th class="col-2 col-sm-4 px-1 px-sm-2 text-center">
          {{ translationsLib.get('status') }}
        </th>
        <th class="col-4 col-sm-2 px-1 px-sm-2 text-center"
            *ngIf="authedUserCanAdminInstaller === true || authedUserCanAdminOwner === true">
          {{ translationsLib.get('permission_action') }}
        </th>
      </tr>
      </thead>
      <tbody>
      <!-- DESKTOP MAIN PERMISSIONS: INSTALLER -->
      <tr *ngIf="mode === 'terminal'">
        <td class="px-1 px-sm-2">
          <ng-container *ngIf="installer">
            <div class="fw-bold">
              {{ installer.name }} {{ installer.surname }}
            </div>
            <div class="text-muted fw-normal">
              {{ installer.email }}
            </div>
          </ng-container>
          <ng-container *ngIf="!installer">
            <div class="fw-bold">
              {{ translationsLib.get('permission_no_installer') }}
            </div>
            <div class="fw-normal text-muted">
              {{ translationsLib.get('permission_invitation_pending') }}
            </div>
          </ng-container>
        </td>
        <td class="px-1 px-sm-2">
          {{ translationsLib.get('permission_installer') }}
        </td>
        <td class="px-1 px-sm-2 text-center">
          <div class="checkbox-ok" *ngIf="installer">
            <ion-icon name="checkmark-outline"></ion-icon>
          </div>
          <div class="checkbox-ko" *ngIf="!installer">
            <ion-icon name="close-outline"></ion-icon>
          </div>
        </td>
        <td class="px-1 px-sm-2 text-center" *ngIf="authedUserCanAdminInstaller === true">
          <ng-container *ngTemplateOutlet="installerAction"></ng-container>
        </td>
      </tr>
      <!-- DESKTOP MAIN PERMISSIONS: OWNER -->
      <tr>
        <td class="px-1 px-sm-2">
          <ng-container *ngIf="owner">
            <div class="fw-bold">
              {{ owner.name }} {{ owner.surname }}
            </div>
            <div class="text-muted">
              {{ owner.email }}
            </div>
          </ng-container>
          <ng-container *ngIf="!owner">
            <div class="fw-bold">
              {{ translationsLib.get('permission_no_owner') }}
            </div>
            <div class="d-block d-sm-none text-muted">
              {{ translationsLib.get('permission_invitation_pending') }}
            </div>
          </ng-container>
        </td>
        <td class="px-1 px-sm-2">
          {{ translationsLib.get('permission_owner') }}
        </td>
        <td class="px-1 px-sm-2 text-center">
          <div class="checkbox-ok" *ngIf="owner">
            <ion-icon name="checkmark-outline"></ion-icon>
          </div>
          <div class="checkbox-ko" *ngIf="!owner">
            <ion-icon name="close-outline"></ion-icon>
          </div>
        </td>
        <td class="px-1 px-sm-2 text-center" *ngIf="authedUserCanAdminOwner === true">
          <ng-container *ngTemplateOutlet="ownerAction"></ng-container>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #installerAction>
  <button class="btn btn-sm btn-primary"
          (click)="showAttachInstaller = true"
          *ngIf="showAttachInstaller !== true">
    {{ translationsLib.get('permission_add_installer') }}
  </button>
  <div *ngIf="showAttachInstaller === true"
       class="align-self-center">
    <div>
      {{ translationsLib.get('permission_invite_installer') }}
    </div>
    <input type="email"
           matInput
           class="form-control my-2 py-2"
           aria-label="Text"
           placeholder="Email"
           [formControl]="attachInstallerForm"
    >
    <button class="btn btn-sm btn-primary"
            (click)="attachInstallerToTerminal()">
      {{ translationsLib.get('add') }}
    </button>
  </div>
</ng-template>
<ng-template #ownerAction>
  <button class="btn btn-sm btn-primary"
          (click)="showAttachOwner = true"
          *ngIf="showAttachOwner !== true">
    {{ translationsLib.get('permission_add_owner') }}
  </button>
  <div *ngIf="showAttachOwner === true"
       class="align-self-center">
    <div>
      {{ translationsLib.get('permission_invite_owner') }}
    </div>
    <input type="email"
           matInput
           class="form-control my-2 py-2"
           aria-label="Text"
           placeholder="Email"
           [formControl]="attachOwnerForm"
    >
    <button class="btn btn-sm btn-primary"
            (click)="attachOwnerToTerminal()">
      {{ translationsLib.get('add') }}
    </button>
  </div>
</ng-template>
