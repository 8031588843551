<app-main-header [breadcrumbs]="breadcrumbs"></app-main-header>

<ion-content>
  <div class="mx-3 mt-4" *ngIf="terminalData?.is_demo">
    <div class="row align-items-end">
      <div class="col">
        <app-demo-alert></app-demo-alert>
        <app-non-verified-installer-alert></app-non-verified-installer-alert>
      </div>
    </div>
  </div>

  <div class="container-fluid custom-container-fluid my-2">
    <div
      class="mb-4 d-flex justify-content-center mt-5"
      *ngIf="group_form.isFetchingData"
    >
      <div class="spinner-border text-warning" role="status">
        <span class="visually-hidden">{{
          this.translationsLib.get('irrigation_loading')
        }}</span>
      </div>
    </div>
    <div *ngIf="!isFetchingData && !isGroupsWithoutData">
      <div class="mb-2">
        <div class="row">
          <app-generic-header
            *ngIf="groups && group"
            [elements]="groups"
            [text]="'name'"
            [selected]="group"
            [placeholder]="translationsLib.get('irrigation_change_grouping')"
            [elementSearchId]="'group'"
            [label]="translationsLib.get('irrigation_group')"
            (changeSelected)="onChangePagination($event)"
          >
            <div irrigation class="text-end">
              <div class="col text-end ps-0 dots">
                <app-mode
                  *ngIf="!isGroupsWithoutData"
                  (changeEventEmitter)="mode = $event"
                ></app-mode>
              </div>
            </div>
          </app-generic-header>
        </div>
      </div>
    </div>
    <div [style.display]="group_form.isFetchingData ? 'none' : 'block'">
      <app-groups-form
        (groupEventEmitter)="group = $event"
        [mode]="mode"
        [terminal_vid]="terminal_vid"
        [group_id]="group_id"
        [reload]="true"
        (dirtyEventEmitter)="isDirty = $event"
        (updatedGroupsEventEmitter)="updateGroupsList($event)"
        (nameEventEmitter)="groupValue = $event"
        #group_form
      ></app-groups-form>
    </div>
  </div>
</ion-content>

<footer
  *ngIf="!isGroupsWithoutData && !isFetchingTitle"
  class="footer mt-auto px-4 py-3 bg-light-soft border-top-3 border-light border global-irrigation-save-button"
>
  <div
    *ngIf="!terminalData?.authed_user_can_write && saveButtonTimeElapsed"
    class="row"
  >
    <div class="col">
      <app-cannot-write-alert></app-cannot-write-alert>
    </div>
  </div>
  <div class="d-flex justify-content-end" *ngIf="!saveButtonTimeElapsed">
    <div class="spinner-border text-warning" role="status">
      <span class="visually-hidden">{{
        translationsLib.get('climate_loading')
      }}</span>
    </div>
  </div>
  <div
    *ngIf="terminalData?.authed_user_can_write && saveButtonTimeElapsed"
    class="row"
  >
    <div
      class="col irrigation-save-button"
      [ngClass]="{ 'p-0': !hasScrollbar }"
    >
      <button
        (click)="group_form.submitForm()"
        [disabled]="
          !group_form.groupsForm.value.isDirty && !group_form.groupsForm.dirty
        "
        class="btn btn-primary float-end"
        [ngClass]="{
          'btn-danger':
            group_form.groupsForm.value.isDirty || group_form.groupsForm.dirty
        }"
      >
        <span
          *ngIf="group_form.isFormSubmitted"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        {{
          group_form.isFormSubmitted
            ? this.translationsLib.get('saving')
            : this.translationsLib.get('irrigation_save_group')
        }}
      </button>
    </div>
  </div>
</footer>
