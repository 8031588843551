<app-main-header [breadcrumbs]="breadcrumbs"></app-main-header>

<lib-billing-popup-terminal [terminalVid]="terminal_vid" *ngIf="sessionLib.getUserHasNonDemoTerminals() === 'true'"></lib-billing-popup-terminal>

<ion-content>
  <div class="container-fluid">
    <div class="my-4" *ngIf="terminalData?.is_demo">
      <div class="row align-items-end">
        <div class="col">
          <app-demo-alert></app-demo-alert>
          <app-non-verified-installer-alert></app-non-verified-installer-alert>
        </div>
      </div>
    </div>
    <div class="my-4">
      <div class="col-12">
        <div
          class="d-flex justify-content-center align-items-center h-75"
          *ngIf="!isDataAvailable; else notFetching"
        >
          <div class="spinner-border text-warning" role="status">
            <span class="visually-hidden">{{
              this.translationsLib.get('irrigation_loading')
            }}</span>
          </div>
        </div>

        <ng-template #notFetching>
          <div
            class="d-flex justify-content-center align-items-center h-75"
            *ngIf="
              !screenConfiguration &&
                !equipmentResponse &&
                !programsResponse &&
                !groupsResponse &&
                !fertilizerFormulasResponse &&
                !drainagesResponse &&
                !mixtureFormulasResponse &&
                !isFetchingTerminalData &&
                !isFetchingEquipmentData &&
                isDataAvailable;
              else isData
            "
          >
            <div class="spinner-border text-warning" role="status">
              <span class="visually-hidden">{{
                this.translationsLib.get('irrigation_loading')
              }}</span>
            </div>
          </div>
          <ng-template #isData>
            <div>
              <div class="my-4">
                <app-equipment-data
                  [equipmentResponse]="equipmentResponse"
                  [terminal_vid]="terminal_vid"
                  [type]="'irrigation'"
                ></app-equipment-data>
              </div>

              <!-- METEO GRID -->
              <div
                class="d-flex justify-content-center align-items-center h-75 mt-4"
                *ngIf="isFetchingET0"
              >
                <div class="spinner-border text-warning" role="status">
                  <span class="visually-hidden">{{
                    this.translationsLib.get('irrigation_loading')
                  }}</span>
                </div>
              </div>

              <ng-container *ngIf="!isFetchingET0">
                <app-meteo-grid
                  [meteoRows]="meteoRows"
                  [terminalType]="'irrigation'"
                  [yesterday_ET0]="yesterday_ET0"
                  [isFetchingET0]="isFetchingET0"
                  [calculateET0Request]="calculateET0Request"
                >
                </app-meteo-grid>

                <div [ngClass]="meteoRows.length === 0 ? 'mt-4' : ''"></div>

                <!-- WaterSystems -->
                <div
                  class="d-flex justify-content-center align-items-center h-75 my-4"
                  *ngIf="
                    !fertilizerFormulasResponse || !mixtureFormulasResponse
                  "
                >
                  <div class="spinner-border text-warning" role="status">
                    <span class="visually-hidden">{{
                      this.translationsLib.get('irrigation_loading')
                    }}</span>
                  </div>
                </div>
                <div class="col-12 mb-4">
                  <app-water-systems
                    [terminalVid]="terminalData.vid"
                  >
                  </app-water-systems>
                </div>

                <!-- ALARM GRID -->
                <app-alarms-grid
                  [alarmRows]="alarmRows"
                  [type]="'irrigation'"
                  (cancelAlarmsEventEmitter)="postCancelAlarms()"
                ></app-alarms-grid>
              </ng-container>

              <mat-tab-group
                [selectedIndex]="selectedTab"
                class="my-4 tab-container"
                *ngIf="screenConfiguration"
                matTabGroupGesture
                [swipeLimitWidth]="80"
                [connectEdges]="true"
              >
                <mat-tab
                  label="{{ translationsLib.get('irrigation_programs') }}"
                  *ngIf="screenConfiguration?.progprog.enabled"
                >
                  <app-terminal-table-wrapper-irrigation
                    [data]="programsResponse"
                    [screenType]="'irrigation'"
                    [states]="equipmentResponse"
                    [fertilizerFormulas]="fertilizerFormulasResponse"
                    [name]="'programs'"
                    [searchPlaceholder]="translationsLib.get('irrigation_name')"
                    [isFetchingData]="isFetchingProgramData"
                    [terminal_vid]="terminal_vid"
                    [tableHeaders]="[
                      {
                        name: 'states',
                        key: translationsLib.get('irrigation_status')
                      },
                      {
                        name: 'name',
                        key: translationsLib.get('irrigation_name')
                      },
                      {
                        name: 'date',
                        keys: [
                          translationsLib.get('irrigation_program_week'),
                          translationsLib.get('irrigation_start'),
                          translationsLib.get('irrigation_end')
                        ]
                      },
                      {
                        name: 'groups',
                        key: translationsLib.get('irrigation_groups')
                      },
                      {
                        name: 'fertilizer',
                        key: translationsLib.get(
                          'irrigation_fertilizer_formulas'
                        )
                      }
                    ]"
                    [type]="'irrigation'"
                  >
                    <tr
                      *appTerminalTableData="let elem"
                      [routerLink]="[
                        '/farming',
                        terminal_vid,
                        'irrigation',
                        'program',
                        elem.program
                      ]"
                      class="row-pointer responsive-terminal-row"
                    >
                      <td
                        class="terminal-table-data"
                        *ngIf="elem.states; else notStates"
                      >
                        <span
                          class="badge wrap-badge"
                          [ngClass]="{
                            'bg-secondary': elem.active === false,
                            'bg-success': elem.active === true
                          }"
                          >{{
                            elem.active
                              ? translationsLib.get('irrigation_program_active')
                              : translationsLib.get(
                                  'irrigation_program_inactive'
                                )
                          }}</span
                        >
                        &nbsp;
                        <span
                          class="wrap-badge"
                          [ngClass]="{
                            'wrap-badge-margin':
                              elem.states[0].state === 'TIME_MIN_VERIFYING'
                          }"
                          [class]="
                            elem.states[0].state | badgeClass : 'IRRIGATION'
                          "
                          >{{
                            translationsLib.get(
                              'irrigation_state_' + elem.states[0].state
                                | lowercase
                            )
                          }}</span
                        >
                      </td>
                      <ng-template #notStates>
                        <td #notStates class="terminal-table-data">
                          <span
                            class="badge wrap-badge"
                            [ngClass]="{
                              'bg-secondary': elem.active === false,
                              'bg-success': elem.active === true
                            }"
                            >{{
                              elem.active
                                ? translationsLib.get(
                                    'irrigation_program_active'
                                  )
                                : translationsLib.get(
                                    'irrigation_program_inactive'
                                  )
                            }}</span
                          >
                        </td>
                      </ng-template>
                      <td class="terminal-table-data">
                        {{ getProgramTrimmedName(elem) }}
                      </td>
                      <td class="terminal-table-data">
                        <ng-container *ngIf="isMobile; else isNotMobile">
                          <span class="span-container">
                            <span class="isMobile">{{
                              elem | convertWeekDays
                            }}</span>
                            <span class="isMobile">{{
                              elem.begin | programDateTable
                            }}</span>
                            <span class="isMobile">{{
                              elem.end | programDateTable
                            }}</span>
                          </span>
                        </ng-container>
                        <ng-template #isNotMobile>
                          {{ elem | convertWeekDays }} •
                          {{ elem.begin | programDateTable }} •
                          {{ elem.end | programDateTable }}
                        </ng-template>
                      </td>
                      <td class="terminal-table-data">
                        <ng-container
                          *ngIf="
                            elem.groups && elem.groups.length > 0;
                            else emptyGroups
                          "
                        >
                          <div>
                            <ng-container *ngFor="let g of elem.groups">
                              <span>{{ getGroupName(g) }}</span>
                              <br />
                            </ng-container>
                          </div>
                        </ng-container>
                        <ng-template #emptyGroups>
                          <span>-</span>
                        </ng-template>
                      </td>
                      <td class="terminal-table-data">
                        <ng-container
                          *ngIf="isMobile; else isNotMobileFertilizer"
                        >
                          <span
                            class="span-container"
                            style="word-break: break-all"
                          >
                            <span
                              class="isMobile"
                              style="word-break: break-all"
                              [ngClass]="{
                                'not-border':
                                  getFertilicerFormulaText(elem) === '-'
                              }"
                            >
                              {{ getFertilicerFormulaText(elem) }}
                            </span>
                            <span
                              class="isMobile"
                              *ngIf="
                                elem.formulaData && elem.formulaData.phControl
                              "
                            >
                              {{
                                translationsLib.get('irrigation_ws_idealPH') +
                                  ': ' +
                                  elem.formulaData.idealPH
                              }}
                            </span>
                            <span
                              class="isMobile"
                              *ngIf="
                                elem.formulaData && elem.formulaData.ECControl
                              "
                            >
                              {{
                                translationsLib.get('irrigation_single_ce') +
                                  ': ' +
                                  elem.formulaData.idealEC +
                                  ' mS/cm'
                              }}
                            </span>
                          </span>
                        </ng-container>
                        <ng-template #isNotMobileFertilizer>
                          {{ getFertilicerFormulaText(elem) }}
                          <span
                            *ngIf="
                              elem.formulaData && elem.formulaData.phControl
                            "
                          >
                            •
                            {{
                              translationsLib.get('irrigation_ws_idealPH') +
                                ': ' +
                                elem.formulaData.idealPH
                            }}
                          </span>
                          <span
                            *ngIf="
                              elem.formulaData && elem.formulaData.ECControl
                            "
                          >
                            •
                            {{
                              translationsLib.get('irrigation_single_ce') +
                                ': ' +
                                +elem.formulaData.idealEC +
                                ' mS/cm'
                            }}
                          </span>
                        </ng-template>
                      </td>
                    </tr>
                  </app-terminal-table-wrapper-irrigation>
                </mat-tab>
                <mat-tab
                  label="{{ translationsLib.get('irrigation_groups') }}"
                  *ngIf="screenConfiguration?.progagrup.enabled"
                >
                  <app-terminal-table-wrapper-irrigation
                    [data]="groupsResponse"
                    [name]="'groups'"
                    [screenType]="'irrigation'"
                    [searchPlaceholder]="translationsLib.get('irrigation_name')"
                    [isFetchingData]="isFetchingGroupData"
                    [terminal_vid]="terminal_vid"
                    [tableHeaders]="[
                      {
                        name: 'name',
                        key: translationsLib.get('irrigation_name')
                      },
                      {
                        name: 'time',
                        key: translationsLib.get('irrigation_time')
                      },
                      {
                        name: 'volume',
                        key: translationsLib.get('irrigation_volume')
                      }
                    ]"
                    [type]="'irrigation'"
                  >
                    <tr
                      *appTerminalTableData="let elem"
                      [routerLink]="[
                        '/farming',
                        terminal_vid,
                        'irrigation',
                        'groups',
                        elem.group
                      ]"
                      class="row-pointer"
                    >
                      <td class="terminal-table-data">
                        {{
                          elem.name
                            ? elem.name
                            : translationsLib.get('irrigation_group') +
                              ' ' +
                              (elem.group + 1)
                        }}
                      </td>
                      <td class="terminal-table-data">
                        {{ elem.fertilizationTime | secondsToTime }}
                      </td>
                      <td class="terminal-table-data">
                        {{ elem.fertilizationVolume }}
                      </td>
                    </tr>
                  </app-terminal-table-wrapper-irrigation>
                </mat-tab>
                <mat-tab
                  label="{{
                    translationsLib.get('irrigation_fertilizer_formulas')
                  }}"
                  *ngIf="screenConfiguration?.progrec.enabled"
                >
                  <app-terminal-table-wrapper-irrigation
                    [data]="fertilizerFormulasResponse"
                    [name]="'fertilizer'"
                    [screenType]="'irrigation'"
                    [searchPlaceholder]="translationsLib.get('irrigation_name')"
                    [isFetchingData]="isFetchingFertilizerFormulasData"
                    [terminal_vid]="terminal_vid"
                    [tableHeaders]="[
                      {
                        name: 'name',
                        key: translationsLib.get('irrigation_name')
                      },
                      {
                        name: 'idealCE',
                        key: translationsLib.get('irrigation_ws_idealCE')
                      },
                      {
                        name: 'idealPH',
                        key: translationsLib.get('irrigation_ideal_ph')
                      }
                    ]"
                    [type]="'irrigation'"
                  >
                    <tr
                      *appTerminalTableData="let elem"
                      [routerLink]="[
                        '/farming',
                        terminal_vid,
                        'irrigation',
                        'fertilizer_formula',
                        elem.formula
                      ]"
                      class="row-pointer"
                    >
                      <td class="terminal-table-data">
                        {{
                          elem.name
                            ? elem.name
                            : this.translationsLib.get(
                                'irrigation_fertilizer_formula'
                              ) +
                              ' ' +
                              (elem.formula + 1)
                        }}
                      </td>
                      <td class="terminal-table-data">
                        {{ elem.ECControl ? elem.idealEC : '-' }}
                      </td>
                      <td class="terminal-table-data">
                        {{ elem.phControl ? elem.idealPH : '-' }}
                      </td>
                    </tr>
                  </app-terminal-table-wrapper-irrigation>
                </mat-tab>
                <mat-tab
                  label="{{ translationsLib.get('irrigation_drainages') }}"
                  *ngIf="screenConfiguration?.progdren.enabled"
                >
                  <app-terminal-table-wrapper-irrigation
                    [data]="drainagesResponse"
                    [name]="'drainages'"
                    [screenType]="'irrigation'"
                    [searchPlaceholder]="translationsLib.get('irrigation_name')"
                    [isFetchingData]="isFetchingDrainageData"
                    [terminal_vid]="terminal_vid"
                    [tableHeaders]="[
                      {
                        name: 'name',
                        key: translationsLib.get('irrigation_name')
                      }
                    ]"
                    [filterProperty]="'drainage'"
                    [type]="'irrigation'"
                    [includeText]="translationsLib.get('irrigation_drainage')"
                  >
                    <tr
                      *appTerminalTableData="let elem"
                      [routerLink]="[
                        '/farming',
                        terminal_vid,
                        'irrigation',
                        'drainages_v4',
                        elem.drainage
                      ]"
                      class="row-pointer"
                    >
                      <td class="terminal-table-data">
                        {{
                          translationsLib.get('irrigation_drainage') +
                            (elem.drainage + 1)
                        }}
                      </td>
                    </tr>
                  </app-terminal-table-wrapper-irrigation>
                </mat-tab>
                <mat-tab
                  label="{{
                    translationsLib.get('irrigation_mixtures_formula')
                  }}"
                  *ngIf="screenConfiguration?.progrmezcla.enabled"
                >
                  <app-terminal-table-wrapper-irrigation
                    [data]="mixtureFormulasResponse"
                    [name]="'mixture'"
                    [screenType]="'irrigation'"
                    [searchPlaceholder]="translationsLib.get('irrigation_name')"
                    [isFetchingData]="isFetchingMixtureFormulasData"
                    [terminal_vid]="terminal_vid"
                    [tableHeaders]="[
                      {
                        name: 'name',
                        key: translationsLib.get('irrigation_name')
                      }
                    ]"
                    [filterProperty]="'formula'"
                    [type]="'irrigation'"
                    [includeText]="
                      translationsLib.get('irrigation_mixture_formula')
                    "
                  >
                    <tr
                      *appTerminalTableData="let elem"
                      [routerLink]="[
                        '/farming',
                        terminal_vid,
                        'irrigation',
                        'mixture_formulas',
                        elem.formula
                      ]"
                      class="row-pointer"
                    >
                      <td class="terminal-table-data">
                        {{
                          translationsLib.get('irrigation_mixture_formula') +
                            ' ' +
                            (elem.formula + 1)
                        }}
                      </td>
                    </tr>
                  </app-terminal-table-wrapper-irrigation>
                </mat-tab>
                <mat-tab
                  label="{{ translationsLib.get('irrigation_configuration') }}"
                >
                  <app-configuration-grid
                    [authedUserCanAdmin]="authedUserCanAdmin"
                    [screenConfiguration]="screenConfiguration"
                  ></app-configuration-grid>
                </mat-tab>
              </mat-tab-group>
            </div>
          </ng-template>
        </ng-template>
      </div>
    </div>
  </div>
</ion-content>
