<app-main-header [breadcrumbs]="breadcrumbs" [name]="translationsLib.get('reports_reports')"></app-main-header>
<ion-content>
  <div class="container-fluid p-1 p-lg-2 mt-4">
    <div class="mx-lg-4">
      <mat-tab-group mat-align-tabs="start">
        <mat-tab label="{{ translationsLib.get('reports_reports') }}">
          <lib-loader *ngIf="ready === false"></lib-loader>
          <ng-container *ngIf="reportSearched === false && ready === true">
            <div class="row mt-4">
              <div class="col-12 col-md-4">
                <div class="card">
                  <div class="card-body">
                    <aside class="d-none d-lg-block">
                      <img src="assets/img/reports/welcome_reports.svg" style="max-height: 365px">
                      <hr class="hr hr-blurry mx-4">
                    </aside>
                    <section>
                      <h2
                        class="text-center mt-0 mb-4 text-header-primary">{{translationsLib.get('reports_what_are')}}</h2>
                      <p class="m-0">{{translationsLib.get('reports_explain')}}</p>
                    </section>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-8">
                <div class="card">
                  <div class="card-header">
                    <div class="card-header-title">
                      <h2 style="color: #224261">{{translationsLib.get('create_your_report')}}</h2>
                    </div>
                  </div>
                  <div class="card-body px-2 px-lg-4">
                    <mat-stepper [linear]="true" [orientation]="stepperOrientation" #stepper>
                      <ng-template matStepperIcon="edit" let-index="index">
                        {{ index + 1 }}
                      </ng-template>
                      <mat-step>
                        <ng-template matStepLabel>{{translationsLib.get('choose_your_report')}}</ng-template>
                        <mat-radio-group class="example-radio-group" [formControl]="report"
                                         [(ngModel)]="selectedReport">
                          <mat-radio-button class="example-radio-button" *ngFor="let item of availableReports"
                                            [value]="item.id">
                            {{item.name}}
                          </mat-radio-button>
                        </mat-radio-group>
                        <div class="float-end">
                          <button matStepperNext class="btn btn-terciary mx-2" [disabled]="selectedReport === ''">
                            {{translationsLib.get('next')}}
                            <ion-icon style="vertical-align: middle" name="arrow-forward-circle-outline"></ion-icon>
                          </button>
                        </div>
                      </mat-step>
                      <mat-step [label]="translationsLib.get('reports_choose_devices')">
                        <span class="example-list-section">
                         <ng-container *ngFor="let field of irrigationTerminalsByField">
                           <h4 class="m-1" style="color:#224261">{{field.key}}</h4>
                           <hr class="hr mt-2 mb-3 hr-blurry">
                           <ng-container *ngFor="let terminal of field.terminals">
                             <mat-checkbox class="ms-0 ms-lg-2" [(ngModel)]="terminal.completed"
                                           (ngModelChange)="updateAllComplete()">
                                {{ terminal.name_vid}}
                              </mat-checkbox><br/>
                           </ng-container><br/>
                         </ng-container>
                        </span>
                        <div class="float-end">
                          <button class="btn btn-terciary mx-1" matStepperPrevious>
                            <ion-icon style="vertical-align: middle" name="arrow-back-circle-outline"></ion-icon>
                            {{translationsLib.get('previous')}}
                          </button>
                          <button class="btn btn-terciary mx-2" matStepperNext
                                  [disabled]="selectedIrrigationTerminals.length===0">{{translationsLib.get('next')}}
                            <ion-icon style="vertical-align: middle" name="arrow-forward-circle-outline"></ion-icon>
                          </button>
                        </div>
                      </mat-step>
                      <mat-step [label]="translationsLib.get('reports_date_range')">
                        <div class="row my-lg-6 align-content-center">
                          <div class=" col-12 col-lg-3 mb-2 mb-lg-0">
                            <button class="btn btn-light 24hrs" id="24h"
                                    (click)="putDates('24h')">{{translationsLib.get('graphics_24h')}}</button>
                          </div>
                          <div class="col-12 col-lg-3 mb-2 mb-lg-0">
                            <button class="btn btn-light 48hrs" id="48h"
                                    (click)="putDates('48h')">{{translationsLib.get('graphics_48h')}}</button>
                          </div>
                          <div class="col-12 col-lg-2 mb-2 mb-lg-0">
                            <button class="btn btn-light 7days" id="7d"
                                    (click)="putDates('7d')">{{translationsLib.get('graphics_7d')}}</button>
                          </div>
                          <div class="col-12 col-lg-4 ">
                            <mat-form-field appearance="fill"
                                            class="range-container mb-2 mb-lg-0 mt-0 text-center w-100">
                              <mat-label>{{ translationsLib.get('reports_date_range') }}</mat-label>
                              <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [max]="maxDate">
                                <input matStartDate formControlName="start"
                                       placeholder="{{ translationsLib.get('start_date') }}">
                                <input matEndDate formControlName="end"
                                       placeholder="{{ translationsLib.get('end_date') }}">
                              </mat-date-range-input>
                              <mat-datepicker-toggle matIconSuffix [for]="picker"
                                                     (click)="putDates(null)"></mat-datepicker-toggle>
                              <mat-date-range-picker #picker></mat-date-range-picker>
                              <mat-error
                                *ngIf="range.controls.start.hasError('matStartDateInvalid')">{{ translationsLib.get('reports_date_range_invalid_start_date') }}</mat-error>
                              <mat-error
                                *ngIf="range.controls.end.hasError('matEndDateInvalid')">{{ translationsLib.get('reports_date_range_invalid_end_date') }}</mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="float-end text-end">
                          <button matStepperPrevious class="btn btn-terciary">
                            <ion-icon style="vertical-align: middle" name="arrow-back-circle-outline"></ion-icon>
                            {{translationsLib.get('previous')}}
                          </button>
                          <button class="btn btn-danger ms-3" (click)="createReport()"
                                  [disabled]="range.value.start===null && range.value.end === null">{{ translationsLib.get('reports_create_report') }}
                            <ion-icon style="position: relative; top: 3px;" name="open-outline"></ion-icon>
                          </button>
                        </div>
                      </mat-step>
                    </mat-stepper>
                  </div>
                </div>
              </div>
            </div>
            <app-reports-suggested
              class="d-none d-lg-block"
              [irrigationTerminals]="this.irrigationTerminals"
              [availableReports]="this.availableReports"
              [dates]="this.dates"
              (parentPutReportData)="putReportData($event)"
            ></app-reports-suggested>
          </ng-container>
          <!--*ngIf="reportSearched === true"-->
          <div class="card mt-4 mb-2" style="min-height: 100px" *ngIf="reportSearched === true">
            <div class="row mx-2">
              <div class="col-12 col-lg-2 d-flex align-items-center d-none d-lg-block">
                <img src="assets/img/reports/welcome_reports.svg" style="max-height: 130px;">
              </div>
              <div class="col-12 col-lg-10 align-content-center">
                <div class="row align-content-center my-4">
                  <div class="col-12 col-md-4">
                    <h2 style="color: #ee7869"
                        class="mb-0 report-titles">{{translationsLib.get('reports_selected')}}</h2>
                    <h2 class="mt-2" style="color: #224261">{{translationsLib.get('reports_' + this.report.value)}}</h2>
                  </div>
                  <div class="col-12 col-md-4">
                    <h2 style="color: #ee7869"
                        class="mt-4 mt-lg-2 mb-0 report-titles">{{translationsLib.get('datalaogger_device')}}</h2>
                    <h4 class="mt-2" style="color: #224261"
                        *ngFor="let terminal of selectedIrrigationTerminals">{{terminal.name_vid}}</h4>
                  </div>
                  <div class="col-12 col-md-4">
                    <h2 style="color: #ee7869"
                        class="mt-4 mt-lg-2 mb-0 report-titles">{{translationsLib.get('reports_selected_dates')}}</h2>
                    <h4 class="mt-2" style="color: #224261; max-height: 15px " *ngIf="otherDates === false">
                      {{ this.createdReportBeginString | transformDate }}
                      - {{ this.createdReportEndString | transformDate }}
                      <ion-icon class="mx-2" (click)="otherDates = true" style="cursor: pointer"
                                name="create-outline"></ion-icon>
                    </h4>
                    <mat-form-field appearance="fill" class="range-container mb-2 mb-lg-0 mt-0 text-center w-100"
                                    *ngIf="otherDates === true">
                      <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [max]="maxDate">
                        <input matStartDate formControlName="start"
                               placeholder="{{ translationsLib.get('start_date') }}">
                        <input matEndDate formControlName="end" placeholder="{{ translationsLib.get('end_date') }}">
                      </mat-date-range-input>
                      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-date-range-picker #picker></mat-date-range-picker>

                      <mat-error
                        *ngIf="range.controls.start.hasError('matStartDateInvalid')">{{ translationsLib.get('reports_date_range_invalid_start_date') }}</mat-error>
                      <mat-error
                        *ngIf="range.controls.end.hasError('matEndDateInvalid')">{{ translationsLib.get('reports_date_range_invalid_end_date') }}</mat-error>
                    </mat-form-field>
                    <button class="btn btn-sm btn-terciary float-end" *ngIf="otherDates === true"
                            (click)="this.createReport(); otherDates = false">{{translationsLib.get('reports_refresh')}}</button>
                  </div>
                  <div class="col-8 d-none d-md-block" *ngIf="otherDates === false">

                  </div>
                  <div class="col-12 col-md-4 mt-4 mt-lg-0 text-end" *ngIf="otherDates === false">
                    <button class="btn btn-sm btn-danger" (click)="resetAll()">
                      <ion-icon name="arrow-back-circle-outline" style="position: relative; top: 3px;"></ion-icon>
                      <span>{{translationsLib.get('reports_new_report')}}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section id="report-section" class="mt-4" *ngIf="reportSearched === true">
            <app-reports-irrigations class="reports-main-component-container" *ngIf="selectedReport === 'irrigations'"
                                     [createdReport]="createdReport"></app-reports-irrigations>
            <app-reports-drainages class="reports-main-component-container" *ngIf="selectedReport === 'drainages'"
                                   [createdReport]="createdReport"></app-reports-drainages>
            <app-reports-alarms class="reports-main-component-container" *ngIf="selectedReport === 'alarms'"
                                [createdReport]="createdReport"></app-reports-alarms>
            <app-reports-consumptions class="reports-main-component-container"
                                      *ngIf="selectedReport === 'programs_consumptions'"
                                      [createdReport]="createdReport"></app-reports-consumptions>
            <app-reports-consumptions class="reports-main-component-container"
                                      *ngIf="selectedReport === 'groups_consumptions'"
                                      [createdReport]="createdReport"></app-reports-consumptions>
          </section>

        </mat-tab>

        <!-- Needs to be shown when ready === true to be sure that input parameters are loaded -->
        <mat-tab label="{{ translationsLib.get('reports_reports_tasks') }}" *ngIf="ready === true">
          <app-reports-tasks
            [availableReports]="availableReports"
            [irrigationTerminals]="irrigationTerminals"
          ></app-reports-tasks>
        </mat-tab>

      </mat-tab-group>
    </div>
  </div>
</ion-content>
