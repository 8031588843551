import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import { ReportsService } from '../reports.service';
import { DateAdapter } from '@angular/material/core';
import { AvailableReportModel, TerminalReportModel } from '../reports.model';
import { ReportTaskModel } from '../reports-task.model';
import { ViewportScroller } from '@angular/common';
import {
  SessionLibService,
  TranslationsLibService,
} from '@nutricontrol/app360-shared';

@Component({
  selector: 'app-reports-tasks',
  templateUrl: './reports-tasks.component.html',
  styleUrls: ['./reports-tasks.component.scss'],
})
export class ReportsTasksComponent implements OnInit {
  @Input() irrigationTerminals: TerminalReportModel[] = [];
  @Input() availableReports: AvailableReportModel[] = [];

  loader = true;
  showCreateReportTask = false;
  reportsTasks: ReportTaskModel[] = [];
  reportsTasksAvailableCount = 0;

  constructor(
    private scroller: ViewportScroller,
    private dateAdapter: DateAdapter<Date>,
    private helpersLib: HelpersLibService,
    public router: Router,
    private reportsService: ReportsService,
    private sessionLib: SessionLibService,
    public translationsLib: TranslationsLibService
  ) {
    this.dateAdapter.setLocale(sessionLib.getLanguage());
    this.dateAdapter.getFirstDayOfWeek = () => 1;
  }

  ngOnInit() {
    /*
    this.helpersLib.sendEventPageView('Reports');
    this.helpersLib.setTitle(
      '',
      this.translationsLib.get('reports_reports'),
      null
    );
    this.breadcrumbs = [
      {
        text: this.translationsLib.get('reports_reports'),
        disabled: false,
        to: ' ',
        active: true,
      },
    ];
     */
    this.load();
  }
  load() {
    this.reportsService.getReportsTasksAvailableCount().subscribe((res) => {
      this.reportsTasksAvailableCount = res.available_count;
    });
    this.reportsService.getReportsTasks().subscribe((res) => {
      res.forEach((reportTask) => {
        this.reportsTasks.push(reportTask);
      });
      this.loader = false;
    });
  }
  reloadParent(event) {
    if (event === true) {
      this.loader = true;
      this.reportsTasks = [];
      this.load();
    }
  }
  createReportTask() {
    this.showCreateReportTask = !this.showCreateReportTask;
    if (this.showCreateReportTask === true) {
      setTimeout(() => {
        //self.scroller.scrollToAnchor('newReportTask');
        document.getElementById('newReportTask').scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        });
      }, 1000);
    }
  }
}
