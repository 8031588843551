import { Component, Input, OnInit } from '@angular/core';
import { AlarmsLibService } from '../../../services/libraries/alarms-lib.service';
import { HelpersLibService } from '../../../services/libraries/helpers-lib.service';
import { ReportsService } from '../../reports.service';
import {
  ConsumptionGroupingChartReportDataModel,
  ConsumptionGroupingReportItemDataModel,
  CreatedReportModel,
  ReportRequestData,
} from '../../reports.model';
import { ReportsConsumptionsService } from '../reports-consumptions.service';
import { TranslationsLibService } from '@nutricontrol/app360-shared';

@Component({
  selector: 'app-reports-grouping-consumptions',
  templateUrl: './reports-grouping-consumptions.component.html',
  styleUrls: ['./reports-grouping-consumptions.component.scss'],
  animations: [],
})
export class ReportsGroupingConsumptionsComponent implements OnInit {
  @Input() createdReport: CreatedReportModel;
  @Input() begin: string;
  @Input() end: string;
  @Input() prettyBegin: string;
  @Input() prettyEnd: string;
  @Input() advancedModeIsAllowed = false;
  terminalCountWithConsumptions = 0;
  loader = false;
  showCharts = false;
  reportsData: ConsumptionGroupingReportItemDataModel[] = [];
  chart: ConsumptionGroupingChartReportDataModel = null;
  requestData: ReportRequestData;
  constructor(
    private alarmsLib: AlarmsLibService,
    private helpersLib: HelpersLibService,
    private reportsService: ReportsService,
    public reportsConsumptionService: ReportsConsumptionsService,
    public translationsLib: TranslationsLibService
  ) {}
  ngOnInit() {
    this.reportsConsumptionService.resetAll();
    this.reportsConsumptionService.advancedModeIsAllowed =
      this.advancedModeIsAllowed;
    this.reportsConsumptionService.reportModeIsGroupingConsumptions = true;
    this.reportsConsumptionService.terminalsLength =
      this.createdReport.terminals.length;
    this.processConsumptions();
  }

  public processConsumptions(): void {
    this.loader = true;
    const terminal_id_max = this.createdReport.terminals.length - 1;
    const terminals = [];
    this.createdReport.terminals.forEach((terminalReport) => {
      terminals.push(terminalReport.vid);
    });
    this.reportsService
      .getConsumptionsGroupsReport(
        { terminals_vid: terminals },
        this.begin,
        this.end,
        null
      )
      .subscribe((res) => {
        this.requestData = {
          terminals_vid: [],
          begin: this.begin,
          end: this.end,
          type: 'groups',
        };
        res.consumption_groups_reports.forEach((groupingReport, index) => {
          this.createdReport.terminals.forEach((terminalReport) => {
            if (groupingReport.terminal_vid === terminalReport.vid) {
              groupingReport.terminal_field_name = terminalReport.field_name;
              groupingReport.terminal_field_vid = terminalReport.field_vid;
              groupingReport.terminal_name_vid = terminalReport.name_vid;
            }
          });
          this.helpersLib.sendEventPageView(
            'Reports | Grouping Consumptions | ' +
              groupingReport.terminal_name +
              '-' +
              groupingReport.terminal_vid
          );
          this.reportsData = groupingReport.items;
          this.chart = groupingReport.chart;
          this.requestData.terminals_vid.push(groupingReport.terminal_vid);
          groupingReport.items.forEach((item) => {
            if (item.groupingConsumptions.length > 0) {
              this.terminalCountWithConsumptions++;
              return;
            }
          });
          if (index === terminal_id_max) {
            this.loader = false;
            const self = this;
            setTimeout(() => {
              self.showCharts = true;
            }, 1000);
          }
        });
      });
  }
}
