import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { HelpersLibService } from '../../../services/libraries/helpers-lib.service';
import { ReportsService } from '../../reports.service';
import { FormControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import {
  ReportTaskAvailablePermissionModel,
  ReportTaskAvailablePermissionsRequestModel,
  ReportTaskAvailablePermissionsResponseModel,
  ReportTaskModel,
} from '../../reports-task.model';
import {
  AvailablePeriodModel,
  AvailableReportModel,
  TerminalReportModel,
} from '../../reports.model';
import Swal from 'sweetalert2';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import {
  SessionLibService,
  TranslationsLibService,
} from '@nutricontrol/app360-shared';

@Component({
  selector: 'app-reports-tasks-items',
  templateUrl: './reports-tasks-items.component.html',
  styleUrls: ['./reports-tasks-items.component.scss'],
})
export class ReportsTasksItemsComponent implements OnInit {
  @Input() availableReports: AvailableReportModel[] = [];
  @Input() irrigationTerminals: TerminalReportModel[] = [];
  @Input() reportTask: ReportTaskModel = null;
  @Output() reloadParentEvent = new EventEmitter<boolean>();
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  availablePeriods: AvailablePeriodModel[] = [];
  name = '';
  active = false;
  selectedIrrigationTerminals: TerminalReportModel[] = [];
  ready = false;
  isNew = false;
  reportTaskVid = '';
  showPermissions = false;
  report = new FormControl('');
  period = new FormControl('');
  panelOpenState = true;
  reportTaskAvailablePermissionRequest: ReportTaskAvailablePermissionsRequestModel =
    {
      terminals_vid: [],
    };
  reportTaskAvailablePermissionsResponse: ReportTaskAvailablePermissionsResponseModel =
    null;

  constructor(
    private dateAdapter: DateAdapter<Date>,
    private helpersLib: HelpersLibService,
    public router: Router,
    private reportsService: ReportsService,
    private sessionLib: SessionLibService,
    public translationsLib: TranslationsLibService
  ) {
    this.dateAdapter.setLocale(sessionLib.getLanguage());
    this.dateAdapter.getFirstDayOfWeek = () => 1;
  }
  ngOnInit() {
    setTimeout(() => {
      this.ready = true;
    }, 500);

    if (this.reportTask !== null) {
      this.name = this.reportTask.name;
      this.active = this.reportTask.active;
      this.report.setValue(this.reportTask.report_type);
      this.period.setValue(this.reportTask.report_period);
      this.reportTaskVid = this.reportTask.vid;
      if (this.reportTask.terminals_vid.length > 0) {
        this.showPermissions = true;
        this.reportTask.terminals_vid.forEach((t_vid) => {
          this.reportTaskAvailablePermissionRequest.terminals_vid.push(t_vid);
          this.irrigationTerminals.forEach((itRes) => {
            if (itRes.vid === t_vid) {
              this.selectedIrrigationTerminals.push(itRes);
            }
          });
        });
      }
    } else {
      this.reportTaskVid = 'null';
      this.isNew = true;
    }

    this.updateReportTaskAvailablePermissions();

    this.availablePeriods = this.reportsService.getAvailablePeriods();
  }
  updateReportTaskAvailablePermissions() {
    this.reportsService
      .postReportTaskAvailablePermissions(
        this.reportTaskVid,
        this.reportTaskAvailablePermissionRequest
      )
      .subscribe((res) => {
        this.reportTaskAvailablePermissionsResponse = res;
      });
  }
  selectedIrrigationTerminalsCallback() {
    this.reportTaskAvailablePermissionRequest.terminals_vid = [];
    this.selectedIrrigationTerminals.forEach((res) => {
      this.reportTaskAvailablePermissionRequest.terminals_vid.push(res.vid);
    });
    this.updateReportTaskAvailablePermissions();
  }
  saveReportTask() {
    const reportTask: ReportTaskModel = {
      vid: this.reportTaskVid,
      name: this.name,
      terminals_vid: this.reportTaskAvailablePermissionRequest.terminals_vid,
      report_type: this.report.value,
      report_period: this.period.value,
      active: this.active,
      permissions: [],
    };

    this.reportTaskAvailablePermissionsResponse.report_task_available_user_permissions.forEach(
      (up) => {
        if (up.active === true) {
          reportTask.permissions.push(up.user_identity.vid);
        }
      }
    );
    this.reportTaskAvailablePermissionsResponse.report_task_available_email_permissions.forEach(
      (ep) => {
        if (ep.active === true) {
          reportTask.permissions.push(ep.user_email);
        }
      }
    );

    if (this.isNew) {
      this.helpersLib.sendEventPageView('Report Task | Creation');
      this.reportsService.postReportTask(reportTask).subscribe((res) => {
        if ('vid' in res) {
          this.reportTask = res;
          this.saveReportTaskSuccessCallback();
        } else {
          this.saveReportTaskErrorCallback();
        }
      });
    } else {
      this.reportsService
        .putReportTask(this.reportTaskVid, reportTask)
        .subscribe((res) => {
          if ('vid' in res) {
            this.reportTask = res;
            this.saveReportTaskSuccessCallback();
          } else {
            this.saveReportTaskErrorCallback();
          }
        });
    }
  }
  saveReportTaskSuccessCallback() {
    Swal.fire({
      text: this.translationsLib.get('save_changes_success'),
      showConfirmButton: true,
      confirmButtonText: this.translationsLib.get('accept'),
      icon: 'success',
      confirmButtonColor: '#224261',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.reloadParentEvent.emit(true);
      }
    });
  }
  saveReportTaskErrorCallback() {
    Swal.fire({
      text: this.translationsLib.get('fill_in_all_inputs_alert'),
      showConfirmButton: true,
      confirmButtonText: this.translationsLib.get('accept'),
      icon: 'error',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // do nothing!
      }
    });
  }
  userPermissionCheckbox(event, item, i) {
    this.reportTaskAvailablePermissionsResponse.report_task_available_user_permissions[
      i
    ].active =
      !this.reportTaskAvailablePermissionsResponse
        .report_task_available_user_permissions[i].active;
  }
  emailPermissionAddEvent(event: MatChipInputEvent): void {
    if (
      this.reportTaskAvailablePermissionsResponse
        .report_task_available_email_permissions.length >=
      this.reportTaskAvailablePermissionsResponse
        .report_task_available_email_permissions_number
    ) {
      return;
    }

    const value = (event.value || '').trim();
    if (this.helpersLib.validateEmail(value)) {
      this.reportTaskAvailablePermissionsResponse.report_task_available_email_permissions.push(
        {
          user_email: value,
          report_permission: 4, // Read
          active: true,
        }
      );
    }

    // Clear the input value
    event.chipInput!.clear();
  }
  emailPermissionRemoveEvent(item: ReportTaskAvailablePermissionModel) {
    console.log('emailPermissionRemoveEvent', item);
    this.reportTaskAvailablePermissionsResponse.report_task_available_email_permissions.forEach(
      (rte, i) => {
        if (rte.user_email === item.user_email) {
          this.reportTaskAvailablePermissionsResponse.report_task_available_email_permissions.splice(
            i,
            1
          );
        }
      }
    );
  }
  compareTerminalReportModel = (item, selected) => {
    if (selected.field_vid && item.field_vid) {
      return item.field_vid === selected.field_vid;
    }
    if (item.vid && selected.vid) {
      return item.vid === selected.vid;
    }
    return false;
  };
}
