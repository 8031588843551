import { Component, OnInit } from '@angular/core';
import { SessionLibService } from '@nutricontrol/app360-shared';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { environment } from 'src/environments/environment';
import { Browser } from '@capacitor/browser';

@Component({
  selector: 'app-myucrop',
  templateUrl: './myucrop.component.html',
  styleUrls: ['./myucrop.component.scss'],
})
export class MyucropComponent implements OnInit {
  constructor(
    public translationsLib: TranslationsLibService,
    public sessionLib: SessionLibService
  ) {}

  ngOnInit(): void {}

  /**
   * Only used at UCROP and not used at Backoffice, so showAlert must be false
   */
  async goToMyUCROP() {
    const url =
      environment.my_ucrop +
      '/login?accesstoken=' +
      encodeURI(this.sessionLib.getAccessToken()) +
      '&token=' +
      encodeURI(this.sessionLib.getSessionToken()) +
      '&email=' +
      encodeURI(this.sessionLib.getSessionEmail()) +
      '&name=' +
      encodeURI(this.sessionLib.getName()) +
      '&vid=' +
      encodeURI(this.sessionLib.getSessionVid()) +
      '&type=' +
      encodeURI(this.sessionLib.getUserType().toString()) +
      '&showAlert=false';

    await Browser.open({ url });
  }
}
