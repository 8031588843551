import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormArray,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  FertilizerFormulasModel,
  FertilizersModel,
  Progrec,
  SensorDataModel,
} from '../../irrigation.model';
import { IrrigationService } from '../../irrigation.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import {
  convertFormattedTimeToSeconds,
  DateToSecondsInput,
  removeDirtyInputs,
  saveDataAndShowModal,
  saveSubmitWithTerminalStatus,
  secondsInputToDate,
} from '../../../../commons/helpers/functions';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { ShepherdService } from 'angular-shepherd';
import {
  steps as defaultSteps,
  defaultStepOptions,
} from './fertilizer-formulas.tour.config';
import { FormFieldHandlerService } from '../../../libraries/form-field-handler.service';
import { Subscription } from 'rxjs';
import { EquipmentsLibService } from 'src/app/services/libraries/equipments-lib.service';
import { InputNumberService } from '../../../libraries/input-number.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-fertilizer-formulas-form',
  templateUrl: './fertilizer-formulas-form.component.html',
  styleUrls: ['./fertilizer-formulas-form.component.scss'],
})
export class FertilizerFormulasFormComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  isFetchingData = false;
  isFormSubmitted = false;
  fertilizerForm: UntypedFormGroup;
  private intervalId: any;
  isErrorInFertilizerFormulasPost = false;
  fertilizers: FertilizersModel[];
  selectedSensor;
  sensorsList: SensorDataModel[];
  isFertilizerFormulasWithoutData = false;
  fertilizerTypes = [
    'PERCENTAGE',
    'SPECIAL_CONTRIBUTION',
    'SEQUENTIAL',
    'DISCREET',
    'TIME',
    'VOLUME',
  ];
  selectedPagination;
  fertilizersResponse;
  @Input() mode: string;
  @Input() terminal_vid: string;
  @Input() fertilizer_formula_id: number;
  @Input() reload = false;
  private formFieldErrorSubscription: Subscription;
  @Input() isProgramsScreen = false;

  isAnyFertilizerFormulaDirtyAfterPost = false;

  isConnected: boolean;

  @Output() dirtyEventEmitter = new EventEmitter<boolean>();
  @Output() nameEventEmitter = new EventEmitter<string>();
  @Output() updateSelectedValueEventEmitter = new EventEmitter<number>();
  @Output() updatedFertilizerFormulasEventEmitter = new EventEmitter<
    FertilizerFormulasModel[]
  >();
  @Output() formulaEventEmitter = new EventEmitter<FertilizerFormulasModel>();
  @Output() formFieldErrorEventEmitter = new EventEmitter<boolean>();

  @Output() clearIntervalEventEmitter = new EventEmitter<boolean>();

  layoutConfiguration: Progrec;

  constructor(
    private fb: UntypedFormBuilder,
    private irrigationService: IrrigationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public translationsLib: TranslationsLibService,
    private shepherdService: ShepherdService,
    private formFieldHandlerService: FormFieldHandlerService,
    private equipmentLib: EquipmentsLibService,
    public inputNumberService: InputNumberService
  ) {
    this.fertilizerForm = this.fb.group({
      formula: [null, Validators.required],
      name: ['', Validators.required],
      dirty: [''],
      idealEC: [null, Validators.required],
      relMaxEC: [null, Validators.required],
      idealPH: [null, Validators.required],
      relMinPH: [null, Validators.required],
      nexpSondaCE: [null, Validators.required],
      nentSondaCE: [null, Validators.required],
      idealECCorrection: [null, Validators.required],
      startECCorrection: [null, Validators.required],
      endECCorrection: [null, Validators.required],
      incrECCorrection: [null, Validators.required],
      startIncrECCorrection: [null, Validators.required],
      endIncrECCorrection: [null, Validators.required],
      prevIrrVolume: [null, Validators.required],
      phControl: [null, Validators.required],
      ECControl: [null, Validators.required],
      ideal_incremental: ['', Validators.required],
      fertilizers: this.fb.array([]),
      isDirty: false,
    });
  }

  ngAfterViewInit() {
    // @ts-ignore
    this.shepherdService.defaultStepOptions = defaultStepOptions;
    this.shepherdService.modal = true;
    this.shepherdService.confirmCancel = false;
    // @ts-ignore
    this.shepherdService.addSteps(defaultSteps);
  }

  getIrrigationEquipment() {
    this.irrigationService
      .getIrrigationTerminal(this.terminal_vid)
      .subscribe((res) => {
        this.isConnected = res.connected;
      });
  }

  startTour() {
    this.shepherdService.start();
  }

  async startInterval() {
    this.intervalId = setInterval(() => {
      this.performTasks();
    }, environment.intervalDefaultTimeout);
  }

  async performTasks() {
    this.fertilizerForm.reset();

    this.getLayoutConfiguration();
    this.getFertilizerFormula();
    this.getSensorsList();

    this.getIrrigationEquipment();

    if (!this.isConnected) {
      this.equipmentLib.showConnectivityAlert();
    }

    this.isFormSubmitted = false;
  }

  ngOnInit() {
    this.fertilizerForm.reset(); // Prevent unsaved data to persist in form inputs

    this.activatedRoute.params.subscribe((_) => {
      this.getIrrigationEquipment();
      this.getLayoutConfiguration();
      this.getFertilizerFormula();
      this.getSensorsList();

      removeDirtyInputs(this.fertilizerForm);
    });

    this.fertilizerForm.valueChanges.subscribe((_) => {
      this.dirtyEventEmitter.emit(this.getIsDirty());

      if (this.getIsDirty()) {
        this.clearIntervalEventEmitter.emit(true);
        clearInterval(this.intervalId);
      } else {
        this.clearIntervalEventEmitter.emit(false);
        clearInterval(this.intervalId);
        this.startInterval();
      }
    });
  }

  getLayoutConfiguration() {
    this.irrigationService
      .getTerminalLayoutConfiguration(this.terminal_vid)
      .subscribe((res) => {
        this.layoutConfiguration = res.screens_configuration.progrec;
        if (!this.layoutConfiguration.enabled) {
          this.router.navigate(['/home/dashboard']);
        }
      });
  }

  updateFertilizerFormulasList() {
    this.irrigationService
      .getFertilizerFormulas(this.terminal_vid)
      .subscribe((res) => {
        this.fertilizersResponse = res;

        this.isAnyFertilizerFormulaDirtyAfterPost = res.some(
          (formula) => formula.dirty === true
        );

        this.updatedFertilizerFormulasEventEmitter.emit(res);

        this.updateSelectedValueEventEmitter.emit(this.fertilizer_formula_id);
      });
  }

  getSensorsList() {
    this.irrigationService.getSensors(this.terminal_vid).subscribe((res) => {
      res.forEach((sensor) => {
        sensor.id = sensor.nexp + '_' + sensor.nent;
      });
      this.sensorsList = res;
    });
  }

  setDirty(dirty: boolean) {
    this.fertilizerForm.patchValue({ isDirty: dirty });
  }

  getIsDirty() {
    return this.fertilizerForm.dirty || this.fertilizerForm.value.isDirty;
  }

  getFertilizerType(fertilizerType, isVisibleObject) {
    const FERTILIZER_DEFAULT_VALUE = 'PERCENTAGE';

    const HASH_TABLE = {
      enable_absolute_volume: this.translationsLib.get(
        'irrigation_special_addition'
      ), // Aportación especial
      enable_ce_ideal: this.translationsLib.get('percentage'), // Porcentaje: Tipo CE Absoluto
      enable_l_m3_volume: this.translationsLib.get('irrigation_volume'), // Volumen l/m3
      enable_ce_incremental: this.translationsLib.get('percentage'), // Porcentaje: Tipo CE Incremental
      enable_time: this.translationsLib.get('irrigation_time'), // Tiempo
      enable_seq_time: this.translationsLib.get('irrigation_sequential'), // Secuencial
      enable_discrete_volume: this.translationsLib.get('irrigation_discrete'), // Discreto
    };

    const FERTILIZER_VALUES = {
      PERCENTAGE: this.translationsLib.get('percentage'),
      VOLUME: this.translationsLib.get('irrigation_volume'),
      SPECIAL_CONTRIBUTION: this.translationsLib.get(
        'irrigation_special_addition'
      ),
      TIME: this.translationsLib.get('irrigation_time'),
      SEQUENTIAL: this.translationsLib.get('irrigation_sequential'),
      DISCREET: this.translationsLib.get('irrigation_discrete'),
    };

    for (const [key, value] of Object.entries(isVisibleObject)) {
      if (
        HASH_TABLE[key] !== undefined &&
        HASH_TABLE[key] === FERTILIZER_VALUES[fertilizerType] &&
        value === true
      ) {
        return FERTILIZER_VALUES[fertilizerType] || FERTILIZER_DEFAULT_VALUE;
      }
    }
  }

  onChangeInputTimeWithKeyboard(event, control) {
    const toSeconds = convertFormattedTimeToSeconds(event.target.value);
    const toDate = secondsInputToDate(toSeconds);

    const fertilizerGroup = this.fertilizersArr.at(
      control.value.fertilizer
    ) as FormGroup;
    fertilizerGroup.patchValue({
      myTime: toDate,
    });

    if (
      control.value.fertilizerType === 'TIME' ||
      control.value.fertilizerType === 'SEQUENTIAL'
    ) {
      control.value.quantity = DateToSecondsInput(control.value.myTime);
    }

    control.markAsDirty();
    this.fertilizerForm.markAsDirty();
  }

  handleTimeValue(value, control) {
    const toSeconds = convertFormattedTimeToSeconds(value);
    const toDate = secondsInputToDate(toSeconds);

    const fertilizerGroup = this.fertilizersArr.at(
      control.value.fertilizer
    ) as FormGroup;
    fertilizerGroup.patchValue({
      myTime: toDate,
    });

    if (
      control.value.fertilizerType === 'TIME' ||
      control.value.fertilizerType === 'SEQUENTIAL'
    ) {
      control.value.quantity = DateToSecondsInput(control.value.myTime);
    }

    return value;
  }

  updateFertilizerFormulaId(id) {
    this.fertilizer_formula_id = id;
    this.getFertilizerFormula();
  }

  clearFertilizersArr() {
    while (this.fertilizersArr.length !== 0) {
      this.fertilizersArr.removeAt(0);
    }
  }

  get fertilizersArr() {
    return this.fertilizerForm.get('fertilizers') as FormArray;
  }

  addFertilizerFormGroup(fertilizer: FertilizersModel) {
    this.fertilizersArr.push(
      this.fb.group({
        fertilizer: this.fb.control(fertilizer.fertilizer),
        fertilizerType: this.fb.control(fertilizer.fertilizerType),
        quantity: this.fb.control(fertilizer.quantity),
        myTime: this.fb.control(secondsInputToDate(fertilizer.quantity)),
      })
    );
  }

  getFertilizerFormula(isGetAfterPost = false) {
    if (!isGetAfterPost) this.isFetchingData = true;

    this.irrigationService
      .getFertilizerFormulas(this.terminal_vid)
      .subscribe((res) => {
        if (res.length === 0) {
          this.isFertilizerFormulasWithoutData = true;
          this.isFetchingData = false;
        } else {
          this.fertilizersResponse = res;

          this.isAnyFertilizerFormulaDirtyAfterPost = res.some(
            (formula) => formula.dirty === true
          );

          this.fertilizersResponse.filter((formula) => {
            if (formula.formula === this.fertilizer_formula_id) {
              this.selectedSensor =
                formula.nexpSondaCE + '_' + formula.nentSondaCE;

              this.nameEventEmitter.emit(formula.name);

              this.clearFertilizersArr();
              for (const fertilizer of formula.fertilizers) {
                this.addFertilizerFormGroup(fertilizer);
              }

              this.formulaEventEmitter.emit(formula);

              this.selectedPagination = formula.formula;

              this.fertilizerForm.patchValue({
                formula: formula.formula,
                name: formula.name,
                dirty: formula.dirty,
                idealEC: formula.idealEC,
                relMaxEC: formula.relMaxEC,
                idealPH: formula.idealPH,
                relMinPH: formula.relMinPH,
                nexpSondaCE: formula.nexpSondaCE,
                nentSondaCE: formula.nentSondaCE,
                idealECCorrection: formula.idealECCorrection,
                startECCorrection: formula.startECCorrection,
                endECCorrection: formula.endECCorrection,
                incrECCorrection: formula.incrECCorrection,
                startIncrECCorrection: formula.startIncrECCorrection,
                endIncrECCorrection: formula.endIncrECCorrection,
                prevIrrVolume: formula.prevIrrVolume,
                phControl: formula.phControl,
                ECControl: formula.ECControl,
                ideal_incremental: formula.ideal_incremental,
              });

              if (!isGetAfterPost) this.isFetchingData = false;
            }
          });
        }
      });
  }

  confirmSave() {
    Swal.fire({
      title: this.translationsLib.get('cannot_be_undone1'),
      text: this.translationsLib.get('irrigation_program_context_warning'),
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: this.translationsLib.get('accept'),
      denyButtonText: this.translationsLib.get('cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.submitForm();
      } else if (result.isDenied) {
        Swal.fire(this.translationsLib.get('something_was_wrong'), '', 'info');
      }
    });
  }

  handlePost(showModal: boolean, connected: boolean): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      const [nexpSondaCE, nentSondaCE] = this.selectedSensor.split('_');

      const fertilizerFormAsArray = [];
      fertilizerFormAsArray.push({
        formula: this.fertilizerForm.value.formula,
        name: this.fertilizerForm.value.name,
        idealEC: this.fertilizerForm.value.idealEC,
        relMaxEC: this.fertilizerForm.value.relMaxEC,
        idealPH: this.fertilizerForm.value.idealPH,
        relMinPH: this.fertilizerForm.value.relMinPH,
        nexpSondaCE: Number(nexpSondaCE),
        nentSondaCE: Number(nentSondaCE),
        idealECCorrection: this.fertilizerForm.value.idealECCorrection,
        startECCorrection: this.fertilizerForm.value.startECCorrection,
        endECCorrection: this.fertilizerForm.value.endECCorrection,
        incrECCorrection: this.fertilizerForm.value.incrECCorrection,
        startIncrECCorrection: this.fertilizerForm.value.startIncrECCorrection,
        endIncrECCorrection: this.fertilizerForm.value.endIncrECCorrection,
        prevIrrVolume: this.fertilizerForm.value.prevIrrVolume,
        phControl: this.fertilizerForm.value.phControl,
        ECControl: this.fertilizerForm.value.ECControl,
        ideal_incremental: this.fertilizerForm.value.ideal_incremental,
        fertilizers: this.fertilizersArr.value.map((data) => {
          const { myTime, ...rest } = data;
          return rest;
        }),
      });

      try {
        this.irrigationService
          .postFertilizerFormulas(this.terminal_vid, fertilizerFormAsArray)
          .subscribe(
            (res) => {
              if (res.error) this.isErrorInFertilizerFormulasPost = true;

              if (
                connected &&
                !this.fertilizerForm.value.dirty &&
                !this.isErrorInFertilizerFormulasPost
              ) {
                saveDataAndShowModal(
                  res,
                  showModal,
                  this.translationsLib.get('error_updating_data'),
                  this.translationsLib.get('save_changes_success'),
                  this.translationsLib.get('accept'),
                  () => {
                    setTimeout(() => {
                      this.getFertilizerFormula(true);
                    }, 4000);
                  }
                );

                this.updateFertilizerFormulasList();
                this.isFormSubmitted = false;
                resolve(true);
              } else if (
                this.isErrorInFertilizerFormulasPost &&
                !this.isProgramsScreen
              ) {
                Swal.fire({
                  text: this.translationsLib.get(
                    'irrigation_general_program_error'
                  ),
                  showConfirmButton: true,
                  confirmButtonText: this.translationsLib.get('accept'),
                  icon: 'error',
                });

                this.updateFertilizerFormulasList();
                this.isFormSubmitted = false;
                resolve(true);
              } else {
                saveSubmitWithTerminalStatus(
                  res,
                  showModal,
                  connected,
                  this.translationsLib.get('error_updating_data'),
                  this.translationsLib.get('save_changes_success'),
                  this.translationsLib.get('accept'),
                  this.translationsLib.get(
                    'irrigation_disconnected_terminal_pending_changes'
                  )
                );

                this.updateFertilizerFormulasList();
                this.isFormSubmitted = false;
                resolve(true);
              }
            },
            (_) => {
              if (showModal) {
                Swal.fire({
                  text: this.translationsLib.get('something_was_wrong'),
                  showConfirmButton: true,
                  confirmButtonText: this.translationsLib.get('accept'),
                  icon: 'error',
                });
              }
              this.isFormSubmitted = false;
              resolve(false);
            }
          );
      } catch (err) {
        throw new Error(err.message);
      }
    });
  }

  submitForm(
    showModal: boolean = true
  ): Promise<{ result: boolean; connected: boolean; error: boolean }> {
    this.isFormSubmitted = true;

    return new Promise<{ result: boolean; connected: boolean; error: boolean }>(
      (resolve, reject) => {
        try {
          this.irrigationService
            .getIrrigationTerminal(this.terminal_vid)
            .subscribe((res) => {
              this.handlePost(showModal, res.connected).then((result) => {
                resolve({
                  result,
                  connected: res.connected,
                  error: this.isErrorInFertilizerFormulasPost,
                });
              });
            });
        } catch (err) {
          reject({ result: false, connected: false });
        } finally {
          // Remove dirty inputs due to use standalone ngModels
          removeDirtyInputs(this.fertilizerForm);

          this.fertilizerForm.markAsUntouched();

          this.fertilizerForm.markAsPristine();
          this.fertilizerForm.patchValue({ isDirty: false });
        }
      }
    );
  }

  ngOnDestroy() {
    this.formFieldErrorSubscription?.unsubscribe();
  }
}
