import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  ChangeDetectorRef,
} from '@angular/core';

@Directive({
  selector: '[appTerminalTableData]',
})
export class TerminalTableDataDirective {
  private _context: any;

  @Input() set appTerminalTableData(newContext: any) {
    this._context = newContext;
    this.updateView();
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private cdr: ChangeDetectorRef
  ) {}

  private updateView() {
    this.viewContainer.clear();
    this.viewContainer.createEmbeddedView(this.templateRef, {
      $implicit: this._context,
    });
    this.cdr.detectChanges();
  }
}
