import { Component, Input, OnInit } from '@angular/core';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import { ReportsService } from '../reports.service';
import {
  CreatedReportModel,
  DrainageReportModel,
  ReportRequestData,
} from '../reports.model';
import { TimeLibService } from '../../services/libraries/time-lib.service';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
@Component({
  selector: 'app-reports-drainages',
  templateUrl: './reports-drainages.component.html',
  styleUrls: ['./reports-drainages.component.scss'],
  animations: [],
})
export class ReportsDrainagesComponent implements OnInit {
  reportsData: DrainageReportModel[] = [];
  @Input() createdReport: CreatedReportModel;
  drainageColumnsToDisplay = [
    //'drainage', // Emilio 08/10/2024
    'tray',
    'dateTime',
    'nActivation',

    'irrigatedLtr',
    'drainedLtr',
    'drainagePercent',

    'accumulatedRadiation',
    'pulses',
    'standby',
    'standbyDra',

    'volAcumIrrig',
    'volAcumDrain',

    'avgDrain',
  ];
  loader = false;
  requestData: ReportRequestData;
  constructor(
    private helpersLib: HelpersLibService,
    private reportsService: ReportsService,
    private timeLib: TimeLibService,
    public translationsLib: TranslationsLibService
  ) {}
  ngOnInit() {
    this.processDrainage();
  }
  public processDrainage(): void {
    const begin = this.timeLib.beginDate(
      this.createdReport.dateRange.start,
      0,
      'days'
    );
    const end = this.timeLib.endDate(
      this.createdReport.dateRange.end,
      0,
      'days'
    );
    this.loader = true;
    const terminals = [];
    this.createdReport.terminals.forEach((terminalReport, terminalId) => {
      terminals.push(terminalReport.vid);
    });
    this.reportsService
      .getDrainageReport({ terminals_vid: terminals }, begin, end, null)
      .subscribe((res) => {
        res.drainages_reports.forEach((drainage, index) => {
          this.createdReport.terminals.forEach((terminalReport, terminalId) => {
            if (drainage.terminal_vid === terminalReport.vid) {
              drainage.terminal_field_name = terminalReport.field_name;
              drainage.terminal_field_vid = terminalReport.field_vid;
              drainage.terminal_name_vid = terminalReport.name_vid;
            }
          });
          this.helpersLib.sendEventPageView(
            'Reports | Drainages | ' +
              drainage.terminal_name +
              '-' +
              drainage.terminal_vid
          );
          this.requestData = {
            terminals_vid: [drainage.terminal_vid],
            begin,
            end,
            type: 'drainage',
          };
          this.reportsData.push({
            reportName: drainage.terminal_name,
            terminalVid: drainage.terminal_vid,
            terminalNameVid: drainage.terminal_name_vid,
            terminalFieldName: drainage.terminal_field_name,
            terminalFieldVid: drainage.terminal_field_vid,
            begin: this.requestData.begin,
            end: this.requestData.end,
            ready: true,
            drainages: drainage.items,
          });
          if (index === res.drainages_reports.length - 1) {
            this.loader = false;
          }
        });
      });
  }
}
