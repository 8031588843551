import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProgramsComponent } from './programs/programs.component';
import { CanActivateViaAuthGuard } from '../../services/guards/guards';
import { UnsavedFormChanges } from '../../commons/guards/unsaved-form-changes.guard';
import { FertilizerFormulasEditComponent } from './fertilizer-formulas/fertilizer-formulas-edit/fertilizer-formulas-edit.component';
import { DrainagesV4EditComponent } from './drainages/drainages-v4/drainages-v4-edit/drainages-v4-edit.component';
import { DrainagesV3EditComponent } from './drainages/drainages-v3/drainages-v3-edit/drainages-v3-edit.component';
import { MixtureFormulasEditComponent } from './mixture-formulas/mixture-formulas-edit/mixture-formulas-edit.component';
import { GroupsEditComponent } from './groups/groups-edit/groups-edit.component';
import { MeteoAlarmsEditComponent } from './installer/meteo-alarms/meteo-alarms-edit/meteo-alarms-edit.component';
import { MixtureValvesEditComponent } from './installer/mixture-valves/mixture-valves-edit/mixture-valves-edit.component';
import { DemandsEditComponent } from './installer/demands/demands-edit/demands-edit.component';
import { PumpsEditComponent } from './installer/pumps/pumps-edit/pumps-edit.component';
import { ValvesEditComponent } from './installer/valves/valves-edit/valves-edit.component';
import { DrainageTraysEditComponent } from './installer/drainage-trays/drainage-trays-edit/drainage-trays-edit.component';
import { PhCeRearmAlarmEditComponent } from './installer/ph-ce-rearm-alarm/ph-ce-rearm-alarm-edit/ph-ce-rearm-alarm-edit.component';
import { FiltersEditComponent } from './installer/filters/filters-edit/filters-edit.component';
import { MiscellaneousEditComponent } from './installer/miscellaneous/miscellaneous-edit/miscellaneous-edit.component';
import { FertilizersEditComponent } from './installer/fertilizers/fertilizers-edit/fertilizers-edit.component';
import { InputOutputComponent } from './installer/input-output/input-output.component';
import { IrrigationTerminalDashboardComponent } from './irrigation-terminal-dashboard/irrigation-terminal-dashboard.component';
import { SensorsScreenComponent } from './installer/sensors/sensors-screen.component';

const routes: Routes = [
  {
    path: '',
    component: IrrigationTerminalDashboardComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: ':tab',
    component: IrrigationTerminalDashboardComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: 'program/:program_id',
    component: ProgramsComponent,
    canActivate: [CanActivateViaAuthGuard],
    canDeactivate: [UnsavedFormChanges],
  },
  {
    path: 'fertilizer_formula/:fertilizer_formula',
    component: FertilizerFormulasEditComponent,
    canDeactivate: [UnsavedFormChanges],
  },
  {
    path: 'drainages_v4/:drainage',
    component: DrainagesV4EditComponent,
    canActivate: [CanActivateViaAuthGuard],
    canDeactivate: [UnsavedFormChanges],
  },
  {
    path: 'drainages_v3/:drainage',
    component: DrainagesV3EditComponent,
    canActivate: [CanActivateViaAuthGuard],
    canDeactivate: [UnsavedFormChanges],
  },
  {
    path: 'mixture_formulas/:mixture',
    component: MixtureFormulasEditComponent,
    canActivate: [CanActivateViaAuthGuard],
    canDeactivate: [UnsavedFormChanges],
  },
  {
    path: 'groups/:group',
    component: GroupsEditComponent,
    canActivate: [CanActivateViaAuthGuard],
    canDeactivate: [UnsavedFormChanges],
  },
  {
    path: 'irrigation_installer/meteo_alarms',
    component: MeteoAlarmsEditComponent,
    canActivate: [CanActivateViaAuthGuard],
    canDeactivate: [UnsavedFormChanges],
  },
  {
    path: 'irrigation_installer/mixture_valves/:mixture_valve',
    component: MixtureValvesEditComponent,
    canActivate: [CanActivateViaAuthGuard],
    canDeactivate: [UnsavedFormChanges],
  },
  {
    path: 'irrigation_installer/demands',
    component: DemandsEditComponent,
    canActivate: [CanActivateViaAuthGuard],
    canDeactivate: [UnsavedFormChanges],
  },
  {
    path: 'irrigation_installer/pumps/:pump',
    component: PumpsEditComponent,
    canActivate: [CanActivateViaAuthGuard],
    canDeactivate: [UnsavedFormChanges],
  },
  {
    path: 'irrigation_installer/valves/:valve',
    component: ValvesEditComponent,
    canActivate: [CanActivateViaAuthGuard],
    canDeactivate: [UnsavedFormChanges],
  },
  {
    path: 'irrigation_installer/drainage_trays/:drainage_tray',
    component: DrainageTraysEditComponent,
    canActivate: [CanActivateViaAuthGuard],
    canDeactivate: [UnsavedFormChanges],
  },
  {
    path: 'irrigation_installer/ph_ce_rearm_alarm',
    component: PhCeRearmAlarmEditComponent,
    canActivate: [CanActivateViaAuthGuard],
    canDeactivate: [UnsavedFormChanges],
  },
  {
    path: 'irrigation_installer/filters/:filter',
    component: FiltersEditComponent,
    canActivate: [CanActivateViaAuthGuard],
    canDeactivate: [UnsavedFormChanges],
  },
  {
    path: 'irrigation_installer/miscellaneous',
    component: MiscellaneousEditComponent,
    canActivate: [CanActivateViaAuthGuard],
    canDeactivate: [UnsavedFormChanges],
  },
  {
    path: 'irrigation_installer/fertilizers',
    component: FertilizersEditComponent,
    canActivate: [CanActivateViaAuthGuard],
    canDeactivate: [UnsavedFormChanges],
  },
  {
    path: 'irrigation_installer/input_output',
    component: InputOutputComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: 'irrigation_installer/sensors',
    component: SensorsScreenComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class IrrigationRoutingModule {}
