import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { HighchartsLibService } from '../../../services/libraries/highcharts-lib.service';
import {
  ConsumptionsReportChartDataModel,
  consumptionSunburstConfigModel,
  consumptionBarsConfigModel,
} from '../../reports-charts.model';

import * as Highcharts from 'highcharts';
import HC_sunburst from 'highcharts/modules/sunburst';
import theme from 'highcharts/themes/avocado';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import {
  PlatformLibService,
  TranslationsLibService,
} from '@nutricontrol/app360-shared';
theme(Highcharts);
HC_sunburst(Highcharts);
Exporting(Highcharts);
ExportData(Highcharts);

@Component({
  selector: 'app-reports-consumptions-charts',
  templateUrl: './reports-consumptions-charts.component.html',
  styleUrls: ['./reports-consumptions-charts.component.scss'],
  animations: [],
})
export class ReportsConsumptionsChartsComponent implements OnInit {
  @Input()
  basicIrrigationConsumptionsReportChartData: ConsumptionsReportChartDataModel[] =
    [];
  @Input()
  basicFertilizerConsumptionsReportChartData: ConsumptionsReportChartDataModel[] =
    [];
  @Input()
  irrigationConsumptionsReportChartData: ConsumptionsReportChartDataModel[] =
    [];
  @Input()
  fertilizerConsumptionsReportChartData: ConsumptionsReportChartDataModel[] =
    [];
  @Input() basicIrrigationConsumptionsReportBarsData: any[] = [];
  @Input() basicFertilizerConsumptionsReportBarsData: any[] = [];
  @Input() prettyBegin: string;
  @Input() prettyEnd: string;
  @Input() selectorModeIsAllowed = false;
  @Input() advancedModeIsAllowed = false;
  basicMode = true;
  chartMode = '';
  @Input() terminalCountWithConsumptions = 0;
  Highcharts = Highcharts;
  chart;
  updateFlag = false;
  chartConstructor = 'chart';
  chartCallback;
  irrigationConsumptionsReportChart = {};
  fertilizerConsumptionsReportChart = {};
  basicIrrigationConsumptionsReportChart = {};
  basicFertilizerConsumptionsReportChart = {};
  basicIrrigationConsumptionsReportBars = {};
  basicFertilizerConsumptionsReportBars = {};
  loader = false;
  totalFertValue: any = 0;
  totalIrrigValue: any = 0;
  constructor(
    private highchartsLib: HighchartsLibService,
    public translationsLib: TranslationsLibService,
    private platformLib: PlatformLibService
  ) {}
  ngOnInit() {
    Highcharts.setOptions({ lang: this.highchartsLib.getLangOptions() });

    this.getTotalSeriesData();

    this.setupCharts(this.prettyBegin, this.prettyEnd);

    this.chartMode = this.getDefaultChartMode();

    this.updateFlag = true;

    this.chartCallback = (chart) => {};
  }
  setupCharts(prettyBegin: string, prettyEnd: string) {
    // BASIC IRRIGATION BARS CHART!
    const basicIrrigationConsumptionsReportBars = consumptionBarsConfigModel;
    if (this.platformLib.isNative()) {
      basicIrrigationConsumptionsReportBars.exporting.enabled = false;
    }
    basicIrrigationConsumptionsReportBars.series[0].data =
      this.basicIrrigationConsumptionsReportBarsData;
    basicIrrigationConsumptionsReportBars.title.text =
      this.translationsLib.get('reports_irrigation_consumptions') +
      ' - ' +
      this.translationsLib.get('reports_water_m3');
    basicIrrigationConsumptionsReportBars.series[0].colors =
      this.highchartsLib.getGlobalBluePaletteColors();
    const baseFormat = '{point.y:.2f}';
    const baseTooltip = basicIrrigationConsumptionsReportBars.tooltip.format;
    basicIrrigationConsumptionsReportBars.series[0].dataLabels.format =
      baseFormat + ' m3';
    basicIrrigationConsumptionsReportBars.tooltip.format = baseTooltip +' m3'
    basicIrrigationConsumptionsReportBars.yAxis.title =
      this.translationsLib.get('reports_water_m3');
    this.basicIrrigationConsumptionsReportBars =
      basicIrrigationConsumptionsReportBars;

    // BASIC FERTILIZER BARS CHART!
    const basicFertilizerConsumptionsReportBars = JSON.parse(
      JSON.stringify(consumptionBarsConfigModel)
    );
    if (this.platformLib.isNative()) {
      basicFertilizerConsumptionsReportBars.exporting.enabled = false;
    }
    basicFertilizerConsumptionsReportBars.series[0].data =
      this.basicFertilizerConsumptionsReportBarsData;
    basicFertilizerConsumptionsReportBars.series[0].dataLabels.format =
      baseFormat +' L';
    basicFertilizerConsumptionsReportBars.tooltip.format = baseTooltip + ' L';
    basicFertilizerConsumptionsReportBars.title.text =
      this.translationsLib.get('reports_fertilizer_consumptions') +
      ' - ' +
      this.translationsLib.get('reports_liters');
    basicFertilizerConsumptionsReportBars.series[0].colors =
      this.highchartsLib.getGlobalGreenPaletteColors();
    basicFertilizerConsumptionsReportBars.yAxis.title =
      this.translationsLib.get('reports_liters');
    this.basicFertilizerConsumptionsReportBars =
      basicFertilizerConsumptionsReportBars;

    // BASIC IRRIGATION SUNBURST CHART!
    const basicIrrigationConsumptionsReportChart =
      consumptionSunburstConfigModel;
    const baseSunburstFormat = '{point.name}: {point.value}';
    const baseSunburstTooltip = '{point.name}: {point.value}';
    if (this.platformLib.isNative()) {
      basicIrrigationConsumptionsReportChart.exporting.enabled = false;
    }
    basicIrrigationConsumptionsReportChart.series[0].data =
      this.basicIrrigationConsumptionsReportChartData;
    basicIrrigationConsumptionsReportChart.title.text =
      this.translationsLib.get('reports_irrigation_consumptions') +
      ' - ' +
      this.translationsLib.get('reports_water_m3');
    basicIrrigationConsumptionsReportChart.series[0].dataLabels.format = baseSunburstFormat +' m3';
    basicIrrigationConsumptionsReportChart.tooltip.format = baseSunburstTooltip + ' m3'
    basicIrrigationConsumptionsReportChart.colors =
      this.highchartsLib.getGlobalBluePaletteColors();
    this.basicIrrigationConsumptionsReportChart =
      basicIrrigationConsumptionsReportChart;

    // BASIC FERTILIZER SUNBURST CHART!, clone object to avoid previous irrigation configuration
    const basicFertilizerConsumptionsReportChart = JSON.parse(
      JSON.stringify(consumptionSunburstConfigModel)
    );
    if (this.platformLib.isNative()) {
      basicFertilizerConsumptionsReportChart.exporting.enabled = false;
    }
    basicFertilizerConsumptionsReportChart.series[0].data =
      this.basicFertilizerConsumptionsReportChartData;
    basicFertilizerConsumptionsReportChart.title.text =
      this.translationsLib.get('reports_fertilizer_consumptions') +
      ' - ' +
      this.translationsLib.get('reports_liters');
    basicFertilizerConsumptionsReportChart.series[0].dataLabels.format = baseSunburstFormat +' L';
    basicFertilizerConsumptionsReportChart.tooltip.format = baseSunburstTooltip + ' L'
    basicFertilizerConsumptionsReportChart.colors =
      this.highchartsLib.getGlobalGreenPaletteColors();
    this.basicFertilizerConsumptionsReportChart =
      basicFertilizerConsumptionsReportChart;

    // ADVANCED IRRIGATION SUNBURST CHART!
    const irrigationConsumptionsReportChart = JSON.parse(
      JSON.stringify(consumptionSunburstConfigModel)
    );
    if (this.platformLib.isNative()) {
      irrigationConsumptionsReportChart.exporting.enabled = false;
    }
    irrigationConsumptionsReportChart.series[0].data =
      this.irrigationConsumptionsReportChartData;
    irrigationConsumptionsReportChart.title.text =
      this.translationsLib.get('reports_irrigation_consumptions') +
      ' - ' +
      this.translationsLib.get('reports_water_m3');
    irrigationConsumptionsReportChart.colors =
      this.highchartsLib.getGlobalBluePaletteColors();
    this.irrigationConsumptionsReportChart = irrigationConsumptionsReportChart;

    // ADVANCED FERTILIZER SUNBURST CHART!, clone object to avoid previous irrigation configuration
    const fertilizerConsumptionsReportChart = JSON.parse(
      JSON.stringify(consumptionSunburstConfigModel)
    );
    if (this.platformLib.isNative()) {
      fertilizerConsumptionsReportChart.exporting.enabled = false;
    }
    fertilizerConsumptionsReportChart.series[0].data =
      this.fertilizerConsumptionsReportChartData;
    fertilizerConsumptionsReportChart.title.text =
      this.translationsLib.get('reports_fertilizer_consumptions') +
      ' - ' +
      this.translationsLib.get('reports_liters');
    fertilizerConsumptionsReportChart.colors =
      this.highchartsLib.getGlobalGreenPaletteColors();
    this.fertilizerConsumptionsReportChart = fertilizerConsumptionsReportChart;
  }

  getDefaultChartMode(): string {
    if (this.terminalCountWithConsumptions > 1) {
      return 'sunburst';
    } else {
      return 'bars';
    }
  }

  changeMode(basicMode: boolean, chartMode: string) {
    this.basicMode = basicMode;
    this.chartMode = chartMode;
  }

  getTotalSeriesData() {
    this.basicFertilizerConsumptionsReportChartData.forEach((fert) => {
      if (fert.value && fert.value !== null) {
        this.totalFertValue = this.totalFertValue + fert.value;
      }
    });
    this.basicIrrigationConsumptionsReportChartData.forEach((irrig) => {
      if (irrig.value && irrig.value !== null) {
        this.totalIrrigValue = this.totalIrrigValue + irrig.value;
      }
    });
  }
}
