<app-main-header [breadcrumbs]="breadcrumbs"></app-main-header>
<ion-content>
  <div class="container-fluid p-1 p-lg-2">
    <div class="mx-lg-4">
      <div class="col-12" *ngIf="loading">
        <lib-loader></lib-loader>
      </div>
      <div class="col-12" *ngIf="!loading">
        <mat-tab-group mat-align-tabs="start" [selectedIndex]="0">
          <mat-tab label="{{ translationsLib.get('field_field') }}">
            <div class="my-4">
              <div class="card p-2 p-lg-4">
                <div class="card-body p-2 p-lg-0">
                  <form  [formGroup]="editField">
                    <div class="row justify-content-between">
                      <div class="col-12 col-md-6">
                        <div class="form-group row">
                          <div class="col-12 col-md-6 col-xxl-8">
                            <label class="form-label">
                              {{ translationsLib.get('field_name') }}
                            </label>
                            <input id="fieldName" type="text"
                             class="form-control"
                             formControlName="name"
                             [value]="editField.value.name"
                            />
                          </div>
                          <div class="col-12 col-md-6 col-xxl-4 mt-4 mt-md-0 d-flex align-items-center justify-content-between d-md-block text-end">
                            <label class="form-label">
                              {{ translationsLib.get('field_color') }}
                            </label>
                            <div>
                              <input type="color"
                               class="form-control d-inline-block bg-light"
                               formControlName="color"
                               [value]="editField.value.color"
                              />
                            </div>
                          </div>
                        </div>

                        <app-multicrop [multicrops]="multicrops" (updateMulticrops)="multicropsEvent($event)"></app-multicrop>

                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group" id="mapa">
                          <img id="static" src="{{staticMap}}" *ngIf="chargeMap===false" class="rounded w-100">
                          <button class="btn btn-primary mb-3" id="editMap" (click)="chargeMap=true" *ngIf="chargeMap===false">
                            {{ translationsLib.get('field_edit_map') }}
                          </button>
                          <button readonly class="btn" id="fieldSize" *ngIf="chargeMap===true">
                            {{ translationsLib.get('field_size') }}: {{fieldSize}} ha
                          </button>
                          <!--<div id="map" *ngIf="chargeMap===true" class="rounded"></div>-->
                          <lib-leaflet-maps *ngIf="chargeMap===true" [coming]="'fields-edit'"
                                            (editedFieldCoordinates)="patchCoordinates($event)"
                                            (fieldSize)="fieldSize = $event"
                                            [field]="{
                                              color: editField.value.color,
                                              coordinates: coords,
                                              allValves: null,
                                              terminals: null,
                                              field: null,
                                              type: null,
                                              longitude: null,
                                              latitude: null,
                                              irrgationStatus: null
                                            }"></lib-leaflet-maps>
                          <!--<button class="btn" id="deletePolygon" *ngIf="chargeMap===true">
                            <ion-icon name="trash-outline"></ion-icon>
                          </button>-->
                        </div>
                      </div>
                    </div>
                    <div class="row" id="buttonAlign">
                      <div class="col-12">
                        <button class="btn btn-lg btn-primary" id="saveField" (click)="saveData()">
                          {{ translationsLib.get('save') }}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <hr class="my-4">
              <div class="card p-2 p-lg-4">
                <div class="card-body p-2 p-lg-0">
                  <div class="row">
                    <div class="col-12 col-md-9">
                      <!-- Heading -->
                      <h4 class="title">
                        {{ translationsLib.get('field_delete_field') }}
                      </h4>
                      <!-- Text -->
                      <div class="small text-muted" [innerHTML]="translationsLib.get('field_delete_field_help')"></div>
                    </div>
                    <div class="col-12 col-md-3 text-end">
                      <button class="btn btn-lg btn-danger" id="deleteField" (click)="deleteField()" [disabled]="terminals.length>0">
                        {{ translationsLib.get('delete') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="{{ translationsLib.get('terminal_terminals') }}">
            <div class="my-4 overflow-hidden">
              <div class="card p-3">
                <table class="table table-responsive-sm card-table">
                  <thead>
                  <tr>
                    <th>
                      <a class="text-muted list-sort">
                        {{ translationsLib.get('terminal_name') }}
                      </a>
                    </th>
                    <th class="d-none d-md-table-cell">
                      <a class="text-muted list-sort">
                        {{ translationsLib.get('terminal_serial_number') }}
                      </a>
                    </th>
                    <th class="d-none d-md-table-cell">
                      <a class="text-muted list-sort">
                        {{ translationsLib.get('terminal_model') }}
                      </a>
                    </th>
                    <th class="text-end">
                      {{ translationsLib.get('terminal_actions') }}
                    </th>
                  </tr>
                  </thead>
                  <tbody class="list">
                  <tr *ngFor="let term of terminals" style="cursor: pointer;">
                    <td (click)="editTerminal(term.vid, term.type)">
                      {{term.name}}
                    </td>
                    <td (click)="editTerminal(term.vid, term.type)" class="d-none d-md-table-cell">
                      {{term.vid}}
                    </td>
                    <td (click)="editTerminal(term.vid, term.type)" class="d-none d-md-table-cell">
                      {{term.pretty_type_name}}
                    </td>
                    <td>
                      <button
                        id="{{term.name}}"
                        *ngIf="field.authed_user_can_admin === true"
                        class="btn btn-danger"
                        (click)="detachTerminal(term.vid)"
                      >
                        <ion-icon name="trash-outline"></ion-icon>
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <p class="text pt-3 mt-4 text-center" *ngIf="terminals.length === 0">
                  <i [innerHTML]="translationsLib.get('terminal_field_with_no_terminals_text')"></i>
                </p>
                <div class="row mt-4" *ngIf="nonAttached !== null && addDevice === false">
                  <div class="col-12 text-end">
                    <button class="btn btn-primary" (click)="addDevice = true; getNonAttachedList()">
                      {{ translationsLib.get('field_add_terminal') }}
                    </button>
                  </div>
                </div>
                <div class="row mt-4" *ngIf="addDevice === false">
                  <div class="col-12 col-lg-10 text-start text-lg-end align-self-center">
                    <span class="text-muted">
                      {{ translationsLib.get('field_force_reload_after_adding_new_terminal') }}
                    </span>
                  </div>
                  <div class="col-12 col-lg-2 text-end">
                    <button class="btn btn-warning text-black" (click)="forceReload();">
                      {{ translationsLib.get('force_reload') }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="card" *ngIf="addDevice === true">
                <mat-tab-group mat-align-tabs="start" [selectedIndex]="0">
                  <mat-tab label="{{ translationsLib.get('terminal_non_attached_terminals') }}"  *ngIf="nonAttached !== null">
                    <br>
                    <div class="col-12 col-lg-6">
                      <form>
                        <div class="form-group">
                          <!-- Label -->
                          <label class="form-label">
                            {{ translationsLib.get('terminal_choose_terminal') }}
                          </label>
                          <!-- Input -->
                          <select class="form-control select" #vidSelected>
                            <option [value]=""></option>
                            <option id="vidNonAttached" *ngFor="let term of nonAttached" [value]="term.vid" >{{term.name}}</option>
                          </select>
                        </div>
                        <button id="botondcha" class="btn btn-lg btn-primary mb-3" (click)="searchPrePairingNonAttached(vidSelected.value)">
                          {{ translationsLib.get('search') }}
                        </button>
                      </form>
                    </div>
                  </mat-tab>
                </mat-tab-group>
                <mat-tab-group mat-align-tabs="start" *ngIf="terminalFound === true;" style="overflow-x: hidden;">
                  <mat-tab label="{{ translationsLib.get('field_attach_terminal_results') }}">
                    <br>
                    <div class="card" id="deviceInfo" *ngIf="terminalFound === true;">
                      <div class="row">
                        <div class="d-none d-md-block col-12 col-md-2">
                          <img src="{{image_path}}" style="max-height: 150px;">
                        </div>
                        <div class="col-12 col-md-10">
                          <div class="row">
                            <label class="form-label-title text-uppercase">
                              {{ translationsLib.get('terminal_model') }}
                            </label>
                            <h2>{{results.value.nombre_equipo}}</h2>
                          </div>
                          <div class="row">
                            <label class="form-label-title text-uppercase">
                              {{ translationsLib.get('terminal_serial_number') }}
                            </label>
                            <h5>{{serialNumberRes}}</h5>
                          </div>
                          <div class="row">
                            <label class="form-label-title text-uppercase">
                              {{ translationsLib.get('terminal_number') }}
                            </label>
                            <h5>{{results.value.terminal}}</h5>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-md-6 help-text">
                          <p [innerHTML]="translationsLib.get('field_attach_terminal_results_text')"></p>
                        </div>
                        <div class="col-12 col-md-6 text-end">
                          <button id="botondcha" class="btn btn-lg btn-primary mb-3" (click)="attach()" *ngIf="terminalFound === true;">
                            {{ translationsLib.get('save') }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="{{ translationsLib.get('permission_permissions') }}">
            <div class="my-4 overflow-hidden">
              <div class="card p-3">
                <app-field-permissions [vid]="this.vid"></app-field-permissions>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</ion-content>
