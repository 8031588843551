import { Component, Input, OnInit } from '@angular/core';
import { SessionLibService } from '@nutricontrol/app360-shared';
import { Router } from '@angular/router';
import { Breadcrumb } from '../../../farming/irrigation/shared/shared.model';
import { PlatformLibService } from '@nutricontrol/app360-shared';
import { environment } from '../../../../environments/environment';
import { NotificationsService } from '../../../notifications/notifications.service';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
})
export class MainHeaderComponent implements OnInit {
  @Input() name = '';
  @Input() breadcrumbs: Breadcrumb[];
  public avatar;
  public pending_number = 0;
  isNative = false;
  constructor(
    private session: SessionLibService,
    public router: Router,
    private platformLib: PlatformLibService,
    private notificationsService: NotificationsService
  ) {}

  ngOnInit() {
    if (this.platformLib.isNative()) {
      this.isNative = true;
    }
    // TODO: Create an Avatar Model
    this.avatar = this.session.getAvatar();
    this.getPending();
  }
  getPending() {
    this.notificationsService.getPendingInvitations().subscribe((res) => {
      this.pending_number = res.pending_invitations;
    });
    // eslint-disable-next-line @typescript-eslint/dot-notation
    if (typeof window['mainHeaderNotificationsInterval'] !== undefined) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      clearInterval(window['mainHeaderNotificationsInterval']);
    }
    // eslint-disable-next-line @typescript-eslint/dot-notation
    window['mainHeaderNotificationsInterval'] = setInterval(() => {
      this.getPending();
    }, environment.intervalDefaultTimeout);
  }
}
