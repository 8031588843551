import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

// @ts-ignore
export class NavigationPreferencesLibService {
  // Navigation Methods
  // ===================

  /* public createNavigationPreferences(mode: string): void {
    localStorage.setItem('mode', mode);
  } */
  public setMode(mode: string): void {
    localStorage.setItem('mode', mode);
  }
  public getMode() {
    return localStorage.getItem('mode');
  }
}
