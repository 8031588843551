import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ReportRequestData } from '../../reports.model';
import { SessionLibService } from '@nutricontrol/app360-shared';

@Component({
  selector: 'app-reports-download-post-open-window',
  templateUrl: './reports-download-post-open-window.component.html',
  styleUrls: ['./reports-download-post-open-window.component.scss'],
})
export class ReportsDownloadPostOpenWindowComponent implements OnInit {
  @Input() requestData!: ReportRequestData;
  @ViewChild('modalContent', { static: true }) modalContent!: TemplateRef<any>;
  modal = null;
  constructor(public session: SessionLibService) {}
  ngOnInit() {
    if (
      this.requestData.terminals_vid.length > 0 &&
      this.requestData.type &&
      this.requestData.begin &&
      this.requestData.end
    ) {
      this.openPostWindow();
    }
  }
  openPostWindow() {
    let type = this.requestData.type;
    if (type === 'groups') {
      type = 'consumption/groups';
    }
    if (type === 'programs') {
      type = 'consumption/programs';
    }
    const url =
      environment.backend +
      `/reports/${type}/download?api_key=${this.session.getSessionToken()}&begin=${encodeURI(
        this.requestData.begin
      )}&end=${encodeURI(
        this.requestData.end
      )}&language=${this.session.getLanguage()}`;

    const data = {
      terminals_vid: this.requestData.terminals_vid,
    };

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain',
      },
      body: JSON.stringify(data),
    })
      .then(async (res) => ({
        filename: 'report', // @TODO: Retrieve from header!
        blob: await res.blob(),
      }))
      .then((resObj) => {
        // It is necessary to create a new blob object with mime-type explicitly set for all browsers except Chrome, but it works for Chrome too.
        const newBlob = new Blob([resObj.blob], { type: 'application/pdf' });

        // Method 1: window.open
        const pdfUrl = window.URL.createObjectURL(newBlob);
        window.open(pdfUrl, '_blank');

        // Method 2: create a blob link and download it

        // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
        //if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //  window.navigator.msSaveOrOpenBlob(newBlob);
        //} else {
        // For other browsers: create a link pointing to the ObjectURL containing the blob.
        /*
          const objUrl = window.URL.createObjectURL(newBlob);

          const link = document.createElement('a');
          link.href = objUrl;
          link.download = resObj.filename;
          link.click();

          // For Firefox it is necessary to delay revoking the ObjectURL.
          setTimeout(() => {
            window.URL.revokeObjectURL(objUrl);
          }, 250);
           */
        //}
      })
      .catch((error) => {
        console.log('DOWNLOAD ERROR', error);
      });
  }
}

@Component({
  selector: 'app-reports-download-loader-template',
  template: `
    <ion-content class="ion-padding">
      <ng-container *ngTemplateOutlet="modalTemplate"></ng-container>
    </ion-content>
  `,
  styleUrls: ['./reports-download-post-open-window.component.scss'],
})
export class ReportsDownloadLoaderTemplateComponent {
  @Input() modalTemplate!: TemplateRef<any>;
}
