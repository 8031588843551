import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { IrrigationService } from '../../../irrigation.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import {
  DemandsIrrigationInstallerModel,
  Progdem,
} from '../../../irrigation.model';
import {
  removeDirtyInputs,
  saveDataAndShowModal,
  saveSubmitWithTerminalStatus,
} from '../../../../../commons/helpers/functions';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { EquipmentsLibService } from '../../../../../services/libraries/equipments-lib.service';
import { Subscription } from 'rxjs';
import { FormFieldHandlerService } from '../../../../libraries/form-field-handler.service';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-demands-form',
  templateUrl: './demands-form.component.html',
  styleUrls: ['./demands-form.component.scss'],
})
export class DemandsFormComponent implements OnInit, OnDestroy {
  isFetchingData = false;
  private formFieldErrorSubscription: Subscription;
  isFormSubmitted = false;
  demandsForm: UntypedFormGroup;
  demandsResponse: DemandsIrrigationInstallerModel[];
  layoutConfiguration: Progdem;
  isConnected: boolean;
  private intervalId: any;
  isDemandsWithoutData = false;

  isErrorInDemandsPost = false;

  @Input() mode: string;
  @Input() terminal_vid: string;
  @Input() demand_id: number;

  @Output() dirtyEventEmitter = new EventEmitter<boolean>();
  @Output() formFieldErrorEventEmitter = new EventEmitter<boolean>();

  typesList = [
    {
      value: 'PULSE',
      name: this.translationsLib.get('irrigation_pulse'),
    },
    {
      value: 'SUSTAINED',
      name: this.translationsLib.get('irrigation_sustained'),
    },
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private irrigationService: IrrigationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public translationsLib: TranslationsLibService,
    private equipmentLib: EquipmentsLibService,
    private formFieldHandlerService: FormFieldHandlerService
  ) {
    this.demandsForm = this.fb.group({ demands: this.fb.array([]) });
  }

  async startInterval() {
    this.intervalId = setInterval(() => {
      this.performTasks();
    }, environment.intervalDefaultTimeout);
  }

  async performTasks() {
    this.demandsForm.reset();

    await this.getLayoutConfiguration();
    this.getLayoutConfiguration();
    this.getDemand();

    await this.getIrrigationEquipment();

    if (!this.isConnected) {
      this.equipmentLib.showConnectivityAlert();
    }

    this.isFormSubmitted = false;
  }

  ngOnInit() {
    this.demandsForm.reset(); // Prevent unsaved data to persist in form inputs

    this.activatedRoute.params.subscribe((_) => {
      this.getLayoutConfiguration();
      this.getDemand();

      removeDirtyInputs(this.demandsForm);
    });

    this.demandsForm.valueChanges.subscribe((_) => {
      this.dirtyEventEmitter.emit(this.getIsDirty());

      if (this.getIsDirty()) {
        clearInterval(this.intervalId);
      } else {
        clearInterval(this.intervalId);
        this.startInterval();
      }
    });

    this.getIrrigationEquipment();
  }

  get demandsData() {
    return this.demandsForm.get('demands') as FormArray;
  }

  getIrrigationEquipment() {
    this.irrigationService
      .getIrrigationTerminal(this.terminal_vid)
      .subscribe((res) => {
        this.isConnected = res.connected;
      });
  }

  getLayoutConfiguration() {
    this.irrigationService
      .getTerminalLayoutConfiguration(this.terminal_vid)
      .subscribe((res) => {
        this.layoutConfiguration = res.screens_configuration.progdem;
        if (!this.layoutConfiguration.enabled) {
          this.router.navigate(['/home/dashboard']);
        }
      });
  }

  setDirty(dirty) {
    this.demandsForm.patchValue({ isDirty: dirty });
  }

  getIsDirty() {
    return this.demandsForm.dirty || this.demandsForm.value.isDirty;
  }

  getDemand(isGetAfterPost = false) {
    if (!isGetAfterPost) this.isFetchingData = true;

    this.irrigationService
      .getIrrigationInstallerDemands(this.terminal_vid)
      .subscribe((res) => {
        this.demandsResponse = res;

        if (this.demandsResponse.length === 0) {
          this.isDemandsWithoutData = true;
        } else {
          const demandsArray = this.demandsForm.get('demands') as FormArray;
          demandsArray.clear();
          this.demandsResponse
            .slice(0, this.layoutConfiguration?.max)
            .forEach((item) => {
              demandsArray.push(
                this.fb.group({
                  demand: [item.demand],
                  type: [item.type],
                  dirty: [item.dirty],
                })
              );
            });
        }

        if (!isGetAfterPost) this.isFetchingData = false;
      });
  }

  handlePost(showModal: boolean, connected: boolean): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      this.isFormSubmitted = true;

      const demandsArray = this.demandsForm.get('demands') as FormArray;
      const newDemandsArray = [];
      demandsArray.value.forEach((val) => {
        newDemandsArray.push(val);
      });

      newDemandsArray.forEach((elem) => {
        if (elem.hasOwnProperty('dirty')) {
          delete elem.dirty;
        }
      });

      try {
        this.irrigationService
          .postIrrigationInstallerDemands(this.terminal_vid, newDemandsArray)
          .subscribe(
            (res) => {
              if (res.error) this.isErrorInDemandsPost = true;

              if (
                connected &&
                !this.demandsForm.value.dirty &&
                !this.isErrorInDemandsPost
              ) {
                saveDataAndShowModal(
                  res,
                  showModal,
                  this.translationsLib.get('error_updating_data'),
                  this.translationsLib.get('save_changes_success'),
                  this.translationsLib.get('accept'),
                  () => {
                    setTimeout(() => {
                      this.getDemand(true);
                    }, 4000);
                  }
                );

                this.isFormSubmitted = false;
                resolve(true);
              } else if (this.isErrorInDemandsPost) {
                Swal.fire({
                  text: this.translationsLib.get(
                    'irrigation_general_program_error'
                  ),
                  showConfirmButton: true,
                  confirmButtonText: this.translationsLib.get('accept'),
                  icon: 'error',
                });

                this.isFormSubmitted = false;
                resolve(true);
              } else {
                saveSubmitWithTerminalStatus(
                  res,
                  showModal,
                  connected,
                  this.translationsLib.get('error_updating_data'),
                  this.translationsLib.get('save_changes_success'),
                  this.translationsLib.get('accept'),
                  this.translationsLib.get(
                    'irrigation_disconnected_terminal_pending_changes'
                  )
                );

                this.isFormSubmitted = false;
                resolve(true);
              }
            },
            (_) => {
              if (showModal) {
                Swal.fire({
                  text: this.translationsLib.get('something_was_wrong'),
                  showConfirmButton: true,
                  confirmButtonText: this.translationsLib.get('accept'),
                  icon: 'error',
                });
              }
              this.isFormSubmitted = false;
              resolve(false);
            }
          );
      } catch (err) {
        throw new Error(err.message);
      }
    });
  }

  submitForm(
    showModal: boolean = true
  ): Promise<{ result: boolean; connected: boolean }> {
    return new Promise<{ result: boolean; connected: boolean }>(
      (resolve, reject) => {
        try {
          this.irrigationService
            .getIrrigationTerminal(this.terminal_vid)
            .subscribe((res) => {
              this.handlePost(showModal, res.connected).then((result) => {
                resolve({ result, connected: res.connected });
              });
            });
        } catch (err) {
          reject({ result: false, connected: false });
        } finally {
          // Remove dirty inputs due to use standalone ngModels
          removeDirtyInputs(this.demandsForm);

          this.demandsForm.markAsUntouched();

          this.demandsForm.markAsPristine();
          this.demandsForm.patchValue({ isDirty: false });
        }
      }
    );
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
    this.formFieldErrorSubscription?.unsubscribe();
  }
}
