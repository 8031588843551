export interface WaterSystemsFieldsConfig {
  activeConfig: WaterSystemsFieldConfig[][];
  inactiveConfig: WaterSystemsFieldConfig[][];
}

export interface WaterSystemsFieldConfig {
  field: string;
  type: WaterSystemFieldsConfigType;
  colSpan: number;
  rowSpan: number;
  colXsSpan: number;
  rowXsSpan: number;
}

export enum WaterSystemFieldsConfigType {
  card = 'card',
  cardDouble = 'cardDouble',
  cardTriple = 'cardTriple',
  progressBar = 'progressBar',
  chips = 'chips',
  drainageTable = 'drainageTable',
  cardFertilizer = 'cardFertilizer',
}

export const defaultFieldsConfig: WaterSystemsFieldsConfig = {
  activeConfig: [
    [
      {
        field: 'program',
        type: WaterSystemFieldsConfigType.card,
        colSpan: 2,
        rowSpan: 1,
        colXsSpan: 3,
        rowXsSpan: 1,
      },
      {
        field: 'group',
        type: WaterSystemFieldsConfigType.card,
        colSpan: 2,
        rowSpan: 1,
        colXsSpan: 3,
        rowXsSpan: 1,
      },
      {
        field: 'state',
        type: WaterSystemFieldsConfigType.card,
        colSpan: 2,
        rowSpan: 1,
        colXsSpan: 3,
        rowXsSpan: 1,
      },
      {
        field: 'irrigationVolume',
        type: WaterSystemFieldsConfigType.card,
        colSpan: 2,
        rowSpan: 1,
        colXsSpan: 3,
        rowXsSpan: 1,
      },
      {
        field: 'activation',
        type: WaterSystemFieldsConfigType.card,
        colSpan: 2,
        rowSpan: 1,
        colXsSpan: 3,
        rowXsSpan: 1,
      },
      {
        field: 'flow',
        type: WaterSystemFieldsConfigType.card,
        colSpan: 2,
        rowSpan: 1,
        colXsSpan: 3,
        rowXsSpan: 1,
      },
    ],
    [
      {
        field: 'irrigationProgress',
        type: WaterSystemFieldsConfigType.progressBar,
        colSpan: 4,
        rowSpan: 2,
        colXsSpan: 6,
        rowXsSpan: 2,
      },
      {
        field: 'valves',
        type: WaterSystemFieldsConfigType.chips,
        colSpan: 4,
        rowSpan: 1,
        colXsSpan: 3,
        rowXsSpan: 1,
      },
      {
        field: 'pump',
        type: WaterSystemFieldsConfigType.card,
        colSpan: 4,
        rowSpan: 1,
        colXsSpan: 3,
        rowXsSpan: 1,
      },
      {
        field: 'irrigationTime',
        type: WaterSystemFieldsConfigType.cardTriple,
        colSpan: 4,
        rowSpan: 1,
        colXsSpan: 6,
        rowXsSpan: 1,
      },
      {
        field: 'groupVolumes',
        type: WaterSystemFieldsConfigType.cardTriple,
        colSpan: 4,
        rowSpan: 1,
        colXsSpan: 6,
        rowXsSpan: 1,
      },
    ],
    [
      {
        field: 'fertilizerFormula',
        type: WaterSystemFieldsConfigType.cardFertilizer,
        colSpan: 12,
        rowSpan: 1,
        colXsSpan: 12,
        rowXsSpan: 2,
      },
    ],
    [
      {
        field: 'idealCE',
        type: WaterSystemFieldsConfigType.card,
        colSpan: 3,
        rowSpan: 1,
        colXsSpan: 2,
        rowXsSpan: 1,
      },
      {
        field: 'cE',
        type: WaterSystemFieldsConfigType.cardDouble,
        colSpan: 3,
        rowSpan: 1,
        colXsSpan: 4,
        rowXsSpan: 1,
      },
      {
        field: 'idealPH',
        type: WaterSystemFieldsConfigType.card,
        colSpan: 3,
        rowSpan: 1,
        colXsSpan: 3,
        rowXsSpan: 1,
      },
      {
        field: 'readPH',
        type: WaterSystemFieldsConfigType.card,
        colSpan: 3,
        rowSpan: 1,
        colXsSpan: 3,
        rowXsSpan: 1,
      },
    ],
    [
      {
        field: 'mixtureFormula',
        type: WaterSystemFieldsConfigType.card,
        colSpan: 2,
        rowSpan: 1,
        colXsSpan: 2,
        rowXsSpan: 1,
      },
      {
        field: 'mixtureValve',
        type: WaterSystemFieldsConfigType.card,
        colSpan: 2,
        rowSpan: 1,
        colXsSpan: 2,
        rowXsSpan: 1,
      },
      {
        field: 'mixtureValvePosition',
        type: WaterSystemFieldsConfigType.card,
        colSpan: 2,
        rowSpan: 1,
        colXsSpan: 2,
        rowXsSpan: 1,
      },
      {
        field: 'mixtureIdealCE1',
        type: WaterSystemFieldsConfigType.card,
        colSpan: 2,
        rowSpan: 1,
        colXsSpan: 2,
        rowXsSpan: 1,
      },
      {
        field: 'mixtureIdealCE2',
        type: WaterSystemFieldsConfigType.card,
        colSpan: 2,
        rowSpan: 1,
        colXsSpan: 2,
        rowXsSpan: 1,
      },
    ],
    [
      {
        field: 'drainage1',
        type: WaterSystemFieldsConfigType.drainageTable,
        colSpan: 3,
        rowSpan: 4,
        colXsSpan: 6,
        rowXsSpan: 4,
      },
      {
        field: 'drainage2',
        type: WaterSystemFieldsConfigType.drainageTable,
        colSpan: 3,
        rowSpan: 4,
        colXsSpan: 6,
        rowXsSpan: 4,
      },
      {
        field: 'drainage3',
        type: WaterSystemFieldsConfigType.drainageTable,
        colSpan: 3,
        rowSpan: 4,
        colXsSpan: 6,
        rowXsSpan: 4,
      },
      {
        field: 'drainage4',
        type: WaterSystemFieldsConfigType.drainageTable,
        colSpan: 3,
        rowSpan: 4,
        colXsSpan: 6,
        rowXsSpan: 4,
      },
    ],
    [
      {
        field: 'drainage5',
        type: WaterSystemFieldsConfigType.drainageTable,
        colSpan: 3,
        rowSpan: 4,
        colXsSpan: 6,
        rowXsSpan: 4,
      },
      {
        field: 'drainage6',
        type: WaterSystemFieldsConfigType.drainageTable,
        colSpan: 3,
        rowSpan: 4,
        colXsSpan: 6,
        rowXsSpan: 4,
      },
      {
        field: 'drainage7',
        type: WaterSystemFieldsConfigType.drainageTable,
        colSpan: 3,
        rowSpan: 4,
        colXsSpan: 6,
        rowXsSpan: 4,
      },
      {
        field: 'drainage8',
        type: WaterSystemFieldsConfigType.drainageTable,
        colSpan: 3,
        rowSpan: 4,
        colXsSpan: 6,
        rowXsSpan: 4,
      },
    ],
    [
      {
        field: 'waitingList',
        type: WaterSystemFieldsConfigType.chips,
        colSpan: 12,
        rowSpan: 1,
        colXsSpan: 6,
        rowXsSpan: 2,
      },
    ],
  ],
  inactiveConfig: [
    [
      {
        field: 'program',
        type: WaterSystemFieldsConfigType.card,
        colSpan: 3,
        rowSpan: 1,
        colXsSpan: 3,
        rowXsSpan: 1,
      },
      {
        field: 'readPH',
        type: WaterSystemFieldsConfigType.card,
        colSpan: 3,
        rowSpan: 1,
        colXsSpan: 3,
        rowXsSpan: 1,
      },
      {
        field: 'cE',
        type: WaterSystemFieldsConfigType.card,
        colSpan: 6,
        rowSpan: 1,
        colXsSpan: 6,
        rowXsSpan: 1,
      },
    ],
    [
      {
        field: 'drainage1',
        type: WaterSystemFieldsConfigType.drainageTable,
        colSpan: 3,
        rowSpan: 4,
        colXsSpan: 6,
        rowXsSpan: 4,
      },
      {
        field: 'drainage2',
        type: WaterSystemFieldsConfigType.drainageTable,
        colSpan: 3,
        rowSpan: 4,
        colXsSpan: 6,
        rowXsSpan: 4,
      },
      {
        field: 'drainage3',
        type: WaterSystemFieldsConfigType.drainageTable,
        colSpan: 3,
        rowSpan: 4,
        colXsSpan: 6,
        rowXsSpan: 4,
      },
      {
        field: 'drainage4',
        type: WaterSystemFieldsConfigType.drainageTable,
        colSpan: 3,
        rowSpan: 4,
        colXsSpan: 6,
        rowXsSpan: 4,
      },
    ],
    [
      {
        field: 'drainage5',
        type: WaterSystemFieldsConfigType.drainageTable,
        colSpan: 3,
        rowSpan: 4,
        colXsSpan: 6,
        rowXsSpan: 4,
      },
      {
        field: 'drainage6',
        type: WaterSystemFieldsConfigType.drainageTable,
        colSpan: 3,
        rowSpan: 4,
        colXsSpan: 6,
        rowXsSpan: 4,
      },
      {
        field: 'drainage7',
        type: WaterSystemFieldsConfigType.drainageTable,
        colSpan: 3,
        rowSpan: 4,
        colXsSpan: 6,
        rowXsSpan: 4,
      },
      {
        field: 'drainage8',
        type: WaterSystemFieldsConfigType.drainageTable,
        colSpan: 3,
        rowSpan: 4,
        colXsSpan: 6,
        rowXsSpan: 4,
      },
    ],
  ],
};
