import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationPreferencesLibService } from '../../../services/libraries/navigation-preferences-lib.service';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { MatDialog } from '@angular/material/dialog';
import { GenerateCopyModalComponent } from '../../../farming/climate/shared/generate-copy-modal/generate-copy-modal.component';
import { ClimateService } from '../../../farming/climate/climate.service';
import Swal from 'sweetalert2';
import {
  AlarmsZone,
  CO2Zone,
  CurtainDarkeningZone,
  CurtainEnergyZone,
  CurtainGappingZone,
  CurtainRegulationZone,
  CurtainShadeZone,
  FanStepsModel,
  HafFansAdjustingZone,
  HafFansProgrammingZone,
  HeatersModel,
  HeatingTemperatureZone,
  MistingRegulationZone,
  MistingZone,
  PipesZone,
  ShadingCurtainSubmitModel,
  ShadingCurtainZone,
  SwitchingProgramZone,
  ThermalInversionZone,
  TransportGroupModel,
  VentilationTemperatureZone,
  VentModel,
} from '../../../farming/climate/climate.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mode',
  templateUrl: './mode.component.html',
  styleUrls: ['./mode.component.scss'],
})
export class ModeComponent implements OnInit {
  mode: string;
  @Output() changeEventEmitter = new EventEmitter<string>();
  @Output() submitCopyEventEmitter = new EventEmitter<boolean>();
  isCopySubmitted = false;
  isToggle = false;
  isClicked = true;
  @Input() currentScreen: string;
  @Input() currentZone: number;
  @Input() showSettings = true;
  @Input() ventilationTemperature: VentilationTemperatureZone[];
  @Input() vents: VentModel;
  @Input() selectedVent: number;
  @Input() selectedStep: number;
  @Input() fanSteps: FanStepsModel;
  @Input() thermalInversion: ThermalInversionZone[];
  @Input() heatingTemperature: HeatingTemperatureZone[];
  @Input() heaters: HeatersModel;
  @Input() currentVent: number;
  @Input() currentFanStep: number;
  @Input() pipes: PipesZone[];
  @Input() activationButtons = false;
  @Input() transportGroup: TransportGroupModel;
  @Input() energy: CurtainEnergyZone[];
  @Input() energyShading: CurtainShadeZone[];
  @Input() energyRegulation: CurtainRegulationZone[];
  @Input() energyGapping: CurtainGappingZone[];
  @Input() darkening: CurtainDarkeningZone[];
  @Input() shading: ShadingCurtainZone[];
  @Input() misting: MistingZone[];
  @Input() mistingRegulation: MistingRegulationZone[];
  @Input() hafFans: HafFansProgrammingZone[];
  @Input() hafFansAdjusting: HafFansAdjustingZone[];
  @Input() alarms: AlarmsZone[];
  @Input() switchingPrograms: SwitchingProgramZone[];
  @Input() co2: CO2Zone[];
  domain: string;
  @Input() allowEditionButton = false;
  allowEdition = false;

  @Input() terminal_id: string;
  @Input() routeType: string;

  selectedOptionToCopy: string;
  @Output() newEditionEventEmitter = new EventEmitter<boolean>();

  constructor(
    private navigationPreferences: NavigationPreferencesLibService,
    public translationsLib: TranslationsLibService,
    private dialog: MatDialog,
    private climateService: ClimateService,
    private router: Router
  ) {}

  ngOnInit() {
    const [, , vid, domain, type, data] = this.router.url.split('/');
    this.domain = domain;

    if (!this.navigationPreferences.getMode()) {
      this.navigationPreferences.setMode('advanced');
    }

    this.mode = this.navigationPreferences.getMode();
    if (this.mode === 'advanced') {
      this.isToggle = true;
    }

    this.changeEventEmitter.emit(this.mode);
  }

  toggle() {
    this.isToggle = !this.isToggle;
    this.isClicked = true;

    // DANGER !!!!
    // The handler of this button is hardcoded, so its depends on the value of the Input 'mode'
    if (this.mode === 'advanced') this.mode = 'simple';
    else this.mode = 'advanced';

    this.navigationPreferences.setMode(this.mode);
    this.changeEventEmitter.emit(this.mode);
  }

  setEditionLayout() {
    this.allowEdition = !this.allowEdition;
    this.newEditionEventEmitter.emit(this.allowEdition);
  }

  generateCopy() {
    const dialogRef = this.dialog.open(GenerateCopyModalComponent, {
      data: {
        compartment: 'Ventilación',
        zones:
          this.ventilationTemperature ||
          this.heatingTemperature ||
          this.energy ||
          this.misting ||
          this.hafFans ||
          this.alarms ||
          this.switchingPrograms ||
          this.co2,
        currentZone: this.currentZone,
        currentVent: this.currentVent,
        routeType: this.routeType,
        vents: this.vents,
        fanSteps: this.fanSteps,
        currentFanStep: this.currentFanStep,
        heaters: this.heaters,
        selectedStep: this.selectedStep,
      },
      width: '500px',
    });

    dialogRef
      .afterClosed()
      .subscribe(
        (result: {
          checkboxes: boolean[];
          selectedOptionToCopy: string;
          ventsVentilationTemperatureSelectedCheckboxes: boolean[][];
          fanStepsVentilationTemperatureSelectedCheckboxes: boolean[][];
          fanStepsHeatingSelectedCheckboxes: boolean[][];
        }) => {
          if (result === undefined) return; // Control when user click outside modal

          const {
            checkboxes,
            selectedOptionToCopy,
            ventsVentilationTemperatureSelectedCheckboxes,
            fanStepsVentilationTemperatureSelectedCheckboxes,
            fanStepsHeatingSelectedCheckboxes,
          } = result;
          this.selectedOptionToCopy = selectedOptionToCopy;

          if (checkboxes && checkboxes.length > 0) {
            const indexesChecked = [];
            checkboxes.forEach((value, index) => {
              if (value) indexesChecked.push(index);
            });

            if (this.routeType === 'ventilation')
              this.submitVentilationCopy(
                indexesChecked,
                ventsVentilationTemperatureSelectedCheckboxes,
                fanStepsVentilationTemperatureSelectedCheckboxes
              );

            if (this.routeType === 'heating')
              this.submitHeatingCopy(
                indexesChecked,
                fanStepsHeatingSelectedCheckboxes
              );

            if (this.routeType === 'curtains')
              this.submitCurtainsCopy(indexesChecked);

            if (this.routeType === 'misting')
              this.submitMistingCopy(indexesChecked);

            if (this.routeType === 'haf_fans')
              this.submitHafFansCopy(indexesChecked);

            if (this.routeType === 'alarms')
              this.submitAlarmsCopy(indexesChecked);

            if (this.routeType === 'switching_programs')
              this.submitSwitchingProgramsCopy(indexesChecked);

            if (this.routeType === 'co2') this.submitCO2Copy(indexesChecked);
          }
        }
      );
  }

  submitVentilationCopy(
    indexesChecked: number[],
    ventsVentilationTemperatureSelectedCheckboxes: boolean[][],
    fanStepsVentilationTemperatureSelectedCheckboxes: boolean[][]
  ) {
    if (
      this.selectedOptionToCopy === 'ventilation_temperature' &&
      indexesChecked.length > 0
    ) {
      const allPromises: Promise<boolean>[] = [];
      indexesChecked.forEach((idx) => {
        for (const period of this.ventilationTemperature[this.currentZone]
          .periods) {
          period.zone = idx;
        }

        this.isCopySubmitted = true;
        this.submitCopyEventEmitter.emit(this.isCopySubmitted);
        // POST HERE
        const promise = new Promise<boolean>((resolve, _) => {
          try {
            this.climateService
              .postClimateVentilations(this.terminal_id, {
                zones: [
                  {
                    periods:
                      this.ventilationTemperature[this.currentZone].periods,
                  },
                ],
              })
              .subscribe((_) => {
                resolve(true);
                this.isCopySubmitted = false;
              });
          } catch (err) {
            resolve(false);
            this.isCopySubmitted = false;
          }
        });

        allPromises.push(promise);
      });

      Promise.all(allPromises).then((results) => {
        const allSuccess = results.every((result) => result);

        if (allSuccess) {
          Swal.fire({
            text: this.translationsLib.get('save_changes_success'),
            showConfirmButton: true,
            confirmButtonText: this.translationsLib.get('accept'),
            icon: 'success',
          }).then((result) => {
            if (result.isConfirmed) window.location.reload();
          });
        } else {
          Swal.fire({
            text: this.translationsLib.get('something_was_wrong'),
            showConfirmButton: true,
            confirmButtonText: this.translationsLib.get('accept'),
            icon: 'error',
          });
        }
      });
      this.submitCopyEventEmitter.emit(this.isCopySubmitted);
    }

    if (this.selectedOptionToCopy === 'vents' && this.selectedVent !== 255) {
      const allPromises: Promise<boolean>[] = [];

      for (
        let i = 0;
        i < ventsVentilationTemperatureSelectedCheckboxes.length;
        i++
      ) {
        const innerArray = ventsVentilationTemperatureSelectedCheckboxes[i];

        for (let j = 0; j < innerArray.length; j++) {
          if (innerArray[j] === true) {
            // POST HERE
            this.isCopySubmitted = true;
            this.submitCopyEventEmitter.emit(this.isCopySubmitted);

            for (const period of this.vents.zones[this.currentZone].vents[
              this.currentVent
            ].periods) {
              period.zone = i;
              period.vent = j;
            }

            this.vents.zones[this.currentZone].vents[
              this.currentVent
            ].settings.zone = i;
            this.vents.zones[this.currentZone].vents[
              this.currentVent
            ].settings.vent = j;

            const promise = new Promise<boolean>((resolve, _) => {
              try {
                this.climateService
                  .postVents(this.terminal_id, {
                    zones: [
                      {
                        vents: [
                          {
                            periods:
                              this.vents.zones[this.currentZone].vents[
                                this.currentVent
                              ].periods,
                            settings:
                              this.vents.zones[this.currentZone].vents[
                                this.currentVent
                              ].settings,
                          },
                        ],
                      },
                    ],
                  })
                  .subscribe((_) => {
                    resolve(true);
                    this.isCopySubmitted = false;
                  });
              } catch (err) {
                resolve(false);
                this.isCopySubmitted = false;
              }
            });

            allPromises.push(promise);
          }
        }
      }

      Promise.all(allPromises).then((results) => {
        const allSuccess = results.every((result) => result);

        if (allSuccess) {
          Swal.fire({
            text: this.translationsLib.get('save_changes_success'),
            showConfirmButton: true,
            confirmButtonText: this.translationsLib.get('accept'),
            icon: 'success',
          }).then((result) => {
            if (result.isConfirmed) window.location.reload();
          });
        } else {
          Swal.fire({
            text: this.translationsLib.get('something_was_wrong'),
            showConfirmButton: true,
            confirmButtonText: this.translationsLib.get('accept'),
            icon: 'error',
          });
        }
      });

      this.submitCopyEventEmitter.emit(this.isCopySubmitted);
    }

    if (
      this.selectedOptionToCopy === 'fan_steps' &&
      this.selectedStep !== 255
    ) {
      const allPromises: Promise<boolean>[] = [];

      for (
        let i = 0;
        i < fanStepsVentilationTemperatureSelectedCheckboxes.length;
        i++
      ) {
        const innerArray = fanStepsVentilationTemperatureSelectedCheckboxes[i];

        for (let j = 0; j < innerArray.length; j++) {
          if (innerArray[j] === true) {
            // POST HERE
            this.isCopySubmitted = true;
            this.submitCopyEventEmitter.emit(this.isCopySubmitted);

            for (const period of this.fanSteps.zones[this.currentZone].steps[
              this.currentFanStep
            ].periods) {
              delete period.dirty;
              period.zone = i;
              period.step = j;
            }

            const promise = new Promise<boolean>((resolve, _) => {
              try {
                this.climateService
                  .postFanSteps(this.terminal_id, {
                    zones: [
                      {
                        steps: [
                          {
                            periods:
                              this.fanSteps.zones[this.currentZone].steps[
                                this.currentFanStep
                              ].periods,
                          },
                        ],
                      },
                    ],
                  })
                  .subscribe((_) => {
                    resolve(true);
                    this.isCopySubmitted = false;
                  });
              } catch (err) {
                resolve(false);
                this.isCopySubmitted = false;
              }
            });

            allPromises.push(promise);
          }
        }
      }

      Promise.all(allPromises).then((results) => {
        const allSuccess = results.every((result) => result);

        if (allSuccess) {
          Swal.fire({
            text: this.translationsLib.get('save_changes_success'),
            showConfirmButton: true,
            confirmButtonText: this.translationsLib.get('accept'),
            icon: 'success',
          }).then((result) => {
            if (result.isConfirmed) window.location.reload();
          });
        } else {
          Swal.fire({
            text: this.translationsLib.get('something_was_wrong'),
            showConfirmButton: true,
            confirmButtonText: this.translationsLib.get('accept'),
            icon: 'error',
          });
        }
      });

      this.submitCopyEventEmitter.emit(this.isCopySubmitted);
    }

    if (
      this.selectedOptionToCopy === 'thermal_inversion' &&
      indexesChecked.length > 0
    ) {
      const allPromises: Promise<boolean>[] = [];
      indexesChecked.forEach((idx) => {
        this.thermalInversion[this.currentZone].zone = idx;

        this.isCopySubmitted = true;
        this.submitCopyEventEmitter.emit(this.isCopySubmitted);
        // POST HERE
        const promise = new Promise<boolean>((resolve, _) => {
          try {
            this.climateService
              .postThermalInversion(this.terminal_id, {
                zones: [this.thermalInversion[this.currentZone]],
              })
              .subscribe((_) => {
                resolve(true);
                this.isCopySubmitted = false;
              });
          } catch (err) {
            resolve(false);
            this.isCopySubmitted = false;
          }
        });

        allPromises.push(promise);
      });

      Promise.all(allPromises).then((results) => {
        const allSuccess = results.every((result) => result);

        if (allSuccess) {
          Swal.fire({
            text: this.translationsLib.get('save_changes_success'),
            showConfirmButton: true,
            confirmButtonText: this.translationsLib.get('accept'),
            icon: 'success',
          }).then((result) => {
            if (result.isConfirmed) window.location.reload();
          });
        } else {
          Swal.fire({
            text: this.translationsLib.get('something_was_wrong'),
            showConfirmButton: true,
            confirmButtonText: this.translationsLib.get('accept'),
            icon: 'error',
          });
        }
      });
      this.submitCopyEventEmitter.emit(this.isCopySubmitted);
    }
  }

  submitHeatingCopy(
    indexesChecked: number[],
    fanStepsHeatingSelectedCheckboxes: boolean[][]
  ) {
    if (
      this.selectedOptionToCopy === 'heating_temperature' &&
      indexesChecked.length > 0
    ) {
      const allPromises: Promise<boolean>[] = [];
      indexesChecked.forEach((idx) => {
        for (const period of this.heatingTemperature[this.currentZone]
          .periods) {
          period.zone = idx;
        }

        this.isCopySubmitted = true;
        this.submitCopyEventEmitter.emit(this.isCopySubmitted);
        // POST HERE
        const promise = new Promise<boolean>((resolve, _) => {
          try {
            this.climateService
              .postHeatingTemperature(this.terminal_id, {
                zones: [
                  {
                    periods: this.heatingTemperature[this.currentZone].periods,
                  },
                ],
              })
              .subscribe((_) => {
                resolve(true);
                this.isCopySubmitted = false;
              });
          } catch (err) {
            resolve(false);
            this.isCopySubmitted = false;
          }
        });

        allPromises.push(promise);
      });

      Promise.all(allPromises).then((results) => {
        const allSuccess = results.every((result) => result);

        if (allSuccess) {
          Swal.fire({
            text: this.translationsLib.get('save_changes_success'),
            showConfirmButton: true,
            confirmButtonText: this.translationsLib.get('accept'),
            icon: 'success',
          }).then((result) => {
            if (result.isConfirmed) window.location.reload();
          });
        } else {
          Swal.fire({
            text: this.translationsLib.get('something_was_wrong'),
            showConfirmButton: true,
            confirmButtonText: this.translationsLib.get('accept'),
            icon: 'error',
          });
        }
      });
      this.submitCopyEventEmitter.emit(this.isCopySubmitted);
    }

    if (this.selectedOptionToCopy === 'heaters' && this.selectedStep !== 255) {
      const allPromises: Promise<boolean>[] = [];

      for (let i = 0; i < fanStepsHeatingSelectedCheckboxes.length; i++) {
        const innerArray = fanStepsHeatingSelectedCheckboxes[i];

        for (let j = 0; j < innerArray.length; j++) {
          if (innerArray[j] === true) {
            // POST HERE
            this.isCopySubmitted = true;
            this.submitCopyEventEmitter.emit(this.isCopySubmitted);

            for (const period of this.heaters.zones[this.currentZone].steps[
              this.selectedStep
            ].periods) {
              delete period.dirty;
              period.zone = i;
              period.step = j;
            }

            const promise = new Promise<boolean>((resolve, _) => {
              try {
                this.climateService
                  .postHeaters(this.terminal_id, {
                    zones: [
                      {
                        steps: [
                          {
                            periods:
                              this.heaters.zones[this.currentZone].steps[
                                this.selectedStep
                              ].periods,
                          },
                        ],
                      },
                    ],
                  })
                  .subscribe((_) => {
                    resolve(true);
                    this.isCopySubmitted = false;
                  });
              } catch (err) {
                resolve(false);
                this.isCopySubmitted = false;
              }
            });

            allPromises.push(promise);
          }
        }
      }

      Promise.all(allPromises).then((results) => {
        const allSuccess = results.every((result) => result);

        if (allSuccess) {
          Swal.fire({
            text: this.translationsLib.get('save_changes_success'),
            showConfirmButton: true,
            confirmButtonText: this.translationsLib.get('accept'),
            icon: 'success',
          }).then((result) => {
            if (result.isConfirmed) window.location.reload();
          });
        } else {
          Swal.fire({
            text: this.translationsLib.get('something_was_wrong'),
            showConfirmButton: true,
            confirmButtonText: this.translationsLib.get('accept'),
            icon: 'error',
          });
        }
      });

      this.submitCopyEventEmitter.emit(this.isCopySubmitted);
    }

    if (this.selectedOptionToCopy === 'pipes' && indexesChecked.length > 0) {
      const allPromises: Promise<boolean>[] = [];
      indexesChecked.forEach((idx) => {
        for (const period of this.pipes[this.currentZone].periods) {
          period.zone = idx;
        }

        this.isCopySubmitted = true;
        this.submitCopyEventEmitter.emit(this.isCopySubmitted);
        // POST HERE
        const promise = new Promise<boolean>((resolve, _) => {
          try {
            this.climateService
              .postPipes(this.terminal_id, {
                zones: [
                  {
                    periods: this.pipes[this.currentZone].periods,
                  },
                ],
              })
              .subscribe((_) => {
                resolve(true);
                this.isCopySubmitted = false;
              });
          } catch (err) {
            resolve(false);
            this.isCopySubmitted = false;
          }
        });

        allPromises.push(promise);
      });

      Promise.all(allPromises).then((results) => {
        const allSuccess = results.every((result) => result);

        if (allSuccess) {
          Swal.fire({
            text: this.translationsLib.get('save_changes_success'),
            showConfirmButton: true,
            confirmButtonText: this.translationsLib.get('accept'),
            icon: 'success',
          }).then((result) => {
            if (result.isConfirmed) window.location.reload();
          });
        } else {
          Swal.fire({
            text: this.translationsLib.get('something_was_wrong'),
            showConfirmButton: true,
            confirmButtonText: this.translationsLib.get('accept'),
            icon: 'error',
          });
        }
      });
      this.submitCopyEventEmitter.emit(this.isCopySubmitted);
    }
  }

  submitCurtainsCopy(indexesChecked: number[]) {
    if (this.selectedOptionToCopy === 'energy' && indexesChecked.length > 0) {
      const allEneryPromises: Promise<boolean>[] = [];
      const allShadingPromises: Promise<boolean>[] = [];
      const allRegulationPromises: Promise<boolean>[] = [];
      const allGappingPromises: Promise<boolean>[] = [];

      // ENERGY
      indexesChecked.forEach((idx) => {
        this.energy[this.currentZone].zone = idx;

        this.isCopySubmitted = true;
        this.submitCopyEventEmitter.emit(this.isCopySubmitted);
        // POST HERE
        const promise = new Promise<boolean>((resolve, _) => {
          try {
            this.climateService
              .postCurtainEnergy(this.terminal_id, {
                zones: [this.energy[this.currentZone]],
              })
              .subscribe((_) => {
                resolve(true);
                this.isCopySubmitted = false;
              });
          } catch (err) {
            resolve(false);
            this.isCopySubmitted = false;
          }
        });

        allEneryPromises.push(promise);
      });

      // SHADING
      indexesChecked.forEach((idx) => {
        this.energyShading[this.currentZone].zone = idx;

        this.isCopySubmitted = true;
        this.submitCopyEventEmitter.emit(this.isCopySubmitted);
        // POST HERE
        const promise = new Promise<boolean>((resolve, _) => {
          try {
            this.climateService
              .postCurtainShade(this.terminal_id, {
                zones: [this.energyShading[this.currentZone]],
              })
              .subscribe((_) => {
                resolve(true);
                this.isCopySubmitted = false;
              });
          } catch (err) {
            resolve(false);
            this.isCopySubmitted = false;
          }
        });

        allShadingPromises.push(promise);
      });

      // REGULATION
      indexesChecked.forEach((idx) => {
        this.energyRegulation[this.currentZone].zone = idx;

        this.isCopySubmitted = true;
        this.submitCopyEventEmitter.emit(this.isCopySubmitted);
        // POST HERE
        const promise = new Promise<boolean>((resolve, _) => {
          try {
            this.climateService
              .postCurtainRegulation(this.terminal_id, {
                zones: [this.energyRegulation[this.currentZone]],
              })
              .subscribe((_) => {
                resolve(true);
                this.isCopySubmitted = false;
              });
          } catch (err) {
            resolve(false);
            this.isCopySubmitted = false;
          }
        });

        allRegulationPromises.push(promise);
      });

      // GAPPING
      indexesChecked.forEach((idx) => {
        this.energyGapping[this.currentZone].zone = idx;

        this.isCopySubmitted = true;
        this.submitCopyEventEmitter.emit(this.isCopySubmitted);
        // POST HERE
        const promise = new Promise<boolean>((resolve, _) => {
          try {
            this.climateService
              .postCurtainGapping(this.terminal_id, {
                zones: [this.energyGapping[this.currentZone]],
              })
              .subscribe((_) => {
                resolve(true);
                this.isCopySubmitted = false;
              });
          } catch (err) {
            resolve(false);
            this.isCopySubmitted = false;
          }
        });

        allGappingPromises.push(promise);
      });

      Promise.all([
        allEneryPromises,
        allShadingPromises,
        allRegulationPromises,
        allGappingPromises,
      ]).then((results) => {
        const allSuccess = results.every((result) => result);

        if (allSuccess) {
          Swal.fire({
            text: this.translationsLib.get('save_changes_success'),
            showConfirmButton: true,
            confirmButtonText: this.translationsLib.get('accept'),
            icon: 'success',
          }).then((result) => {
            if (result.isConfirmed) window.location.reload();
          });
        } else {
          Swal.fire({
            text: this.translationsLib.get('something_was_wrong'),
            showConfirmButton: true,
            confirmButtonText: this.translationsLib.get('accept'),
            icon: 'error',
          });
        }
      });
      this.submitCopyEventEmitter.emit(this.isCopySubmitted);
    }

    if (
      this.selectedOptionToCopy === 'darkening' &&
      indexesChecked.length > 0
    ) {
      const allDarkeningPromises: Promise<boolean>[] = [];

      // DARKENING
      indexesChecked.forEach((idx) => {
        this.darkening[this.currentZone].zone = idx;

        this.isCopySubmitted = true;
        this.submitCopyEventEmitter.emit(this.isCopySubmitted);
        // POST HERE
        const promise = new Promise<boolean>((resolve, _) => {
          try {
            this.climateService
              .postDarkeningCurtain(this.terminal_id, {
                zones: [this.darkening[this.currentZone]],
              })
              .subscribe((_) => {
                resolve(true);
                this.isCopySubmitted = false;
              });
          } catch (err) {
            resolve(false);
            this.isCopySubmitted = false;
          }
        });

        allDarkeningPromises.push(promise);
      });

      Promise.all([allDarkeningPromises]).then((results) => {
        const allSuccess = results.every((result) => result);

        if (allSuccess) {
          Swal.fire({
            text: this.translationsLib.get('save_changes_success'),
            showConfirmButton: true,
            confirmButtonText: this.translationsLib.get('accept'),
            icon: 'success',
          }).then((result) => {
            if (result.isConfirmed) window.location.reload();
          });
        } else {
          Swal.fire({
            text: this.translationsLib.get('something_was_wrong'),
            showConfirmButton: true,
            confirmButtonText: this.translationsLib.get('accept'),
            icon: 'error',
          });
        }
      });
      this.submitCopyEventEmitter.emit(this.isCopySubmitted);
    }

    if (this.selectedOptionToCopy === 'shading' && indexesChecked.length > 0) {
      const allShadingPromises: Promise<boolean>[] = [];

      // SHADING
      indexesChecked.forEach((idx) => {
        this.shading[this.currentZone].shading_1.zone = idx;
        this.shading[this.currentZone].shading_2.zone = idx;

        const shadingAsArray: ShadingCurtainSubmitModel = {
          zones: [
            {
              shading_1: this.shading[this.currentZone].shading_1,
              shading_2: this.shading[this.currentZone].shading_2,
            },
          ],
        };

        this.isCopySubmitted = true;
        this.submitCopyEventEmitter.emit(this.isCopySubmitted);
        // POST HERE
        const promise = new Promise<boolean>((resolve, _) => {
          try {
            this.climateService
              .postShadingCurtain(this.terminal_id, shadingAsArray)
              .subscribe((_) => {
                resolve(true);
                this.isCopySubmitted = false;
              });
          } catch (err) {
            resolve(false);
            this.isCopySubmitted = false;
          }
        });

        allShadingPromises.push(promise);
      });

      Promise.all([allShadingPromises]).then((results) => {
        const allSuccess = results.every((result) => result);

        if (allSuccess) {
          Swal.fire({
            text: this.translationsLib.get('save_changes_success'),
            showConfirmButton: true,
            confirmButtonText: this.translationsLib.get('accept'),
            icon: 'success',
          }).then((result) => {
            if (result.isConfirmed) window.location.reload();
          });
        } else {
          Swal.fire({
            text: this.translationsLib.get('something_was_wrong'),
            showConfirmButton: true,
            confirmButtonText: this.translationsLib.get('accept'),
            icon: 'error',
          });
        }
      });
      this.submitCopyEventEmitter.emit(this.isCopySubmitted);
    }
  }

  submitMistingCopy(indexesChecked: number[]) {
    if (this.selectedOptionToCopy === 'misting' && indexesChecked.length > 0) {
      const allMistingPromises: Promise<boolean>[] = [];
      const allMistingRegulationPromises: Promise<boolean>[] = [];

      // MISTING
      indexesChecked.forEach((idx) => {
        for (const period of this.misting[this.currentZone].periods) {
          period.zone = idx;
        }

        this.isCopySubmitted = true;
        this.submitCopyEventEmitter.emit(this.isCopySubmitted);
        // POST HERE
        const promise = new Promise<boolean>((resolve, _) => {
          try {
            this.climateService
              .postMistingProgramming(this.terminal_id, {
                zones: [
                  {
                    periods: this.misting[this.currentZone].periods,
                  },
                ],
              })
              .subscribe((_) => {
                resolve(true);
                this.isCopySubmitted = false;
              });
          } catch (err) {
            resolve(false);
            this.isCopySubmitted = false;
          }
        });

        allMistingPromises.push(promise);
      });

      // MISTING REGULATION
      indexesChecked.forEach((idx) => {
        this.mistingRegulation[this.currentZone].zone = idx;

        this.isCopySubmitted = true;
        this.submitCopyEventEmitter.emit(this.isCopySubmitted);
        // POST HERE
        const promise = new Promise<boolean>((resolve, _) => {
          try {
            this.climateService
              .postMistingRegulation(this.terminal_id, {
                zones: [this.mistingRegulation[this.currentZone]],
              })
              .subscribe((_) => {
                resolve(true);
                this.isCopySubmitted = false;
              });
          } catch (err) {
            resolve(false);
            this.isCopySubmitted = false;
          }
        });

        allMistingPromises.push(promise);
      });

      Promise.all([allMistingPromises, allMistingRegulationPromises]).then(
        (results) => {
          const allSuccess = results.every((result) => result);

          if (allSuccess) {
            Swal.fire({
              text: this.translationsLib.get('save_changes_success'),
              showConfirmButton: true,
              confirmButtonText: this.translationsLib.get('accept'),
              icon: 'success',
            }).then((result) => {
              if (result.isConfirmed) window.location.reload();
            });
          } else {
            Swal.fire({
              text: this.translationsLib.get('something_was_wrong'),
              showConfirmButton: true,
              confirmButtonText: this.translationsLib.get('accept'),
              icon: 'error',
            });
          }
        }
      );
      this.submitCopyEventEmitter.emit(this.isCopySubmitted);
    }
  }

  submitHafFansCopy(indexesChecked: number[]) {
    if (this.selectedOptionToCopy === 'haf_fans' && indexesChecked.length > 0) {
      const allHafFansPromises: Promise<boolean>[] = [];
      const allHafFansAdjustingPromises: Promise<boolean>[] = [];

      // HAF FANS
      indexesChecked.forEach((idx) => {
        for (const period of this.hafFans[this.currentZone].periods) {
          period.zone = idx;
        }

        this.isCopySubmitted = true;
        this.submitCopyEventEmitter.emit(this.isCopySubmitted);
        // POST HERE
        const promise = new Promise<boolean>((resolve, _) => {
          try {
            this.climateService
              .postHafFansProgramming(this.terminal_id, {
                zones: [
                  {
                    periods: this.hafFans[this.currentZone].periods,
                  },
                ],
              })
              .subscribe((_) => {
                resolve(true);
                this.isCopySubmitted = false;
              });
          } catch (err) {
            resolve(false);
            this.isCopySubmitted = false;
          }
        });

        allHafFansPromises.push(promise);
      });

      // HAF FANS ADJUSTING
      indexesChecked.forEach((idx) => {
        for (const period of this.hafFansAdjusting[this.currentZone].periods) {
          period.zone = idx;
        }

        this.isCopySubmitted = true;
        this.submitCopyEventEmitter.emit(this.isCopySubmitted);
        // POST HERE
        const promise = new Promise<boolean>((resolve, _) => {
          try {
            this.climateService
              .postHafFansAdjusting(this.terminal_id, {
                zones: [
                  {
                    periods: this.hafFansAdjusting[this.currentZone].periods,
                  },
                ],
              })
              .subscribe((_) => {
                resolve(true);
                this.isCopySubmitted = false;
              });
          } catch (err) {
            resolve(false);
            this.isCopySubmitted = false;
          }
        });

        allHafFansPromises.push(promise);
      });

      Promise.all([allHafFansPromises, allHafFansAdjustingPromises]).then(
        (results) => {
          const allSuccess = results.every((result) => result);

          if (allSuccess) {
            Swal.fire({
              text: this.translationsLib.get('save_changes_success'),
              showConfirmButton: true,
              confirmButtonText: this.translationsLib.get('accept'),
              icon: 'success',
            }).then((result) => {
              if (result.isConfirmed) window.location.reload();
            });
          } else {
            Swal.fire({
              text: this.translationsLib.get('something_was_wrong'),
              showConfirmButton: true,
              confirmButtonText: this.translationsLib.get('accept'),
              icon: 'error',
            });
          }
        }
      );
      this.submitCopyEventEmitter.emit(this.isCopySubmitted);
    }
  }

  submitAlarmsCopy(indexesChecked: number[]) {
    if (this.selectedOptionToCopy === 'alarms' && indexesChecked.length > 0) {
      const allAlarmsPromises: Promise<boolean>[] = [];

      // ALARMS
      indexesChecked.forEach((idx) => {
        this.alarms[this.currentZone].zone = idx;

        this.isCopySubmitted = true;
        this.submitCopyEventEmitter.emit(this.isCopySubmitted);
        // POST HERE
        const promise = new Promise<boolean>((resolve, _) => {
          try {
            this.climateService
              .postAlarmsZone(this.terminal_id, {
                zones: [this.alarms[this.currentZone]],
              })
              .subscribe((_) => {
                resolve(true);
                this.isCopySubmitted = false;
              });
          } catch (err) {
            resolve(false);
            this.isCopySubmitted = false;
          }
        });

        allAlarmsPromises.push(promise);
      });

      Promise.all([allAlarmsPromises]).then((results) => {
        const allSuccess = results.every((result) => result);

        if (allSuccess) {
          Swal.fire({
            text: this.translationsLib.get('save_changes_success'),
            showConfirmButton: true,
            confirmButtonText: this.translationsLib.get('accept'),
            icon: 'success',
          }).then((result) => {
            if (result.isConfirmed) window.location.reload();
          });
        } else {
          Swal.fire({
            text: this.translationsLib.get('something_was_wrong'),
            showConfirmButton: true,
            confirmButtonText: this.translationsLib.get('accept'),
            icon: 'error',
          });
        }
      });
      this.submitCopyEventEmitter.emit(this.isCopySubmitted);
    }
  }

  submitSwitchingProgramsCopy(indexesChecked: number[]) {
    if (
      this.selectedOptionToCopy === 'switching_programs' &&
      indexesChecked.length > 0
    ) {
      const allSwitchingProgramsPromises: Promise<boolean>[] = [];

      // SWITCHING PROGRAMS
      indexesChecked.forEach((idx) => {
        this.switchingPrograms[this.currentZone].zone = idx;

        this.isCopySubmitted = true;
        this.submitCopyEventEmitter.emit(this.isCopySubmitted);
        // POST HERE
        const promise = new Promise<boolean>((resolve, _) => {
          try {
            this.climateService
              .postSwitchingProgram(this.terminal_id, {
                zones: [this.switchingPrograms[this.currentZone]],
              })
              .subscribe((_) => {
                resolve(true);
                this.isCopySubmitted = false;
              });
          } catch (err) {
            resolve(false);
            this.isCopySubmitted = false;
          }
        });

        allSwitchingProgramsPromises.push(promise);
      });

      Promise.all([allSwitchingProgramsPromises]).then((results) => {
        const allSuccess = results.every((result) => result);

        if (allSuccess) {
          Swal.fire({
            text: this.translationsLib.get('save_changes_success'),
            showConfirmButton: true,
            confirmButtonText: this.translationsLib.get('accept'),
            icon: 'success',
          }).then((result) => {
            if (result.isConfirmed) window.location.reload();
          });
        } else {
          Swal.fire({
            text: this.translationsLib.get('something_was_wrong'),
            showConfirmButton: true,
            confirmButtonText: this.translationsLib.get('accept'),
            icon: 'error',
          });
        }
      });
      this.submitCopyEventEmitter.emit(this.isCopySubmitted);
    }
  }

  submitCO2Copy(indexesChecked: number[]) {
    if (this.selectedOptionToCopy === 'co2' && indexesChecked.length > 0) {
      const allCO2Promises: Promise<boolean>[] = [];

      // CO2
      indexesChecked.forEach((idx) => {
        for (const period of this.co2[this.currentZone].periods) {
          period.zone = idx;
        }

        this.isCopySubmitted = true;
        this.submitCopyEventEmitter.emit(this.isCopySubmitted);
        // POST HERE
        const promise = new Promise<boolean>((resolve, _) => {
          try {
            this.climateService
              .postCO2(this.terminal_id, {
                zones: [
                  {
                    periods: this.co2[this.currentZone].periods,
                  },
                ],
              })
              .subscribe((_) => {
                resolve(true);
                this.isCopySubmitted = false;
              });
          } catch (err) {
            resolve(false);
            this.isCopySubmitted = false;
          }
        });

        allCO2Promises.push(promise);
      });

      Promise.all([allCO2Promises]).then((results) => {
        const allSuccess = results.every((result) => result);

        if (allSuccess) {
          Swal.fire({
            text: this.translationsLib.get('save_changes_success'),
            showConfirmButton: true,
            confirmButtonText: this.translationsLib.get('accept'),
            icon: 'success',
          }).then((result) => {
            if (result.isConfirmed) window.location.reload();
          });
        } else {
          Swal.fire({
            text: this.translationsLib.get('something_was_wrong'),
            showConfirmButton: true,
            confirmButtonText: this.translationsLib.get('accept'),
            icon: 'error',
          });
        }
      });
      this.submitCopyEventEmitter.emit(this.isCopySubmitted);
    }
  }
}
