import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionLibService } from '@nutricontrol/app360-shared';
import { FormBuilder } from '@angular/forms';
import { ProfileService } from '../profile.service';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import { AppCommonsService } from '../../commons/app-commons.service';
import { environment } from '../../../environments/environment';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { AuthService } from '../../auth/auth.service';
import { OrganizationDataModel } from '../../organizations/organization.model';
import { UsersLibService } from '../../services/libraries/users-lib.service';
import { CountryDataModel } from '../../commons/app-commons.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {
  private fb: FormBuilder = new FormBuilder();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  editData;
  selectedCountry = 0;
  selectedProvinceId = 0;
  avatar;
  color: any;
  avatarRef;
  countries: CountryDataModel[];
  provinces = null;
  loading = true;
  changeAvatar = false;
  textcolor: any;
  languages;
  organizations: OrganizationDataModel[] = [];
  userTypeNumber = 0;
  userTypeText = '';
  userIsInstaller = false;
  constructor(
    public router: Router,
    private authService: AuthService,
    private profService: ProfileService,
    public session: SessionLibService,
    private helpersLib: HelpersLibService,
    private commonsService: AppCommonsService,
    public sessionLib: SessionLibService,
    public translationsLib: TranslationsLibService,
    public usersLib: UsersLibService
  ) {
    this.editData = this.fb.group({
      name: [''],
      surname: [''],
      company_name: [''],
      phone: [''],
      email: [''],
      address: [''],
      country_id: [0],
      province_id: [0],
      province_name: [''],
      language: [''],
    });
  }

  async ngOnInit() {
    this.helpersLib.setTitle(
      '',
      this.translationsLib.get('general'),
      this.translationsLib.get('profile_profile')
    );
    this.avatar = this.session.getAvatar();
    this.getData();
    this.getLanguages();
    /*this.getProvince(this.data.province_id);*/
    this.color = this.avatar.background;
    this.textcolor = this.avatar.color;
  }
  setBgColor() {
    const avatar = document
      .getElementById('modifyavatar')
      .getElementsByClassName('u-text-avatar');
    /*contenido.style.backgroundColor=this.color;*/
    /*avatar[2].style.backgroundColor= this.color;*/
    const changeAvatar = avatar[0] as HTMLElement;
    changeAvatar.style.backgroundColor = this.color;
    /*console.log(avatar);*/
  }
  setTextColor() {
    const avatar = document
      .getElementById('modifyavatar')
      .getElementsByClassName('u-text-avatar');
    /*contenido.style.backgroundColor=this.color;*/
    /*avatar[2].style.backgroundColor= this.color;*/
    const changeAvatar = avatar[0] as HTMLElement;
    changeAvatar.style.color = this.textcolor;
    /*console.log(avatar);*/
  }

  getData() {
    this.authService.getCountries().subscribe((response) => {
      this.countries = response.items;
      this.authService.getUserData().subscribe((response) => {
        this.userTypeNumber = response.type;
        this.userTypeText = this.usersLib.getRolText(response.type);
        this.userIsInstaller = this.usersLib.isInstaller(response.type);
        this.organizations = response.organizations;

        this.editData.setValue({
          name: response.name,
          surname: response.surname,
          company_name: response.company_name,
          phone: response.phone,
          email: response.email,
          address: response.address,
          country_id: response.country_id || 0,
          province_id: response.province_id || 0,
          province_name: response.province_name,
          language: response.language,
        });
        this.selectedCountry = response.country_id;
        this.selectedProvinceId = response.province_id;
        this.loading = false;
        this.getProvinces();
      });
    });
  }

  onSubmit() {
    this.profService.putUsersData(this.editData.value).subscribe(
      (response) => {
        const avatar = {
          name: (response.name + ' ' + response.surname).trim(),
          background: response.avatar_colors[0],
          color: response.avatar_colors[1],
        };
        this.sessionLib.setAvatar(avatar);

        environment.language = this.editData.value.language;
        this.session.setLanguage(environment.language);
        location.reload();
      },
      (error) => {
        Swal.fire({
          icon: 'error',
          text: this.translationsLib.get('error_updating_data'),
          confirmButtonColor: '#224261',
        });
      }
    );
  }

  getProvinces() {
    this.countries.filter((country) => {
      if (country.id === Number(this.editData.value.country_id)) {
        if (country.provinces.length > 0) {
          this.provinces = country.provinces;
        } else {
          this.provinces = null;
        }
      }
    });
  }
  getProvince(id) {
    this.provinces.filter((province) => {
      if (province.province_id === id) {
        this.editData.patchValue({
          province_name: province.name,
        });
      }
    });
  }
  changePassword() {
    Swal.fire({
      html: this.translationsLib.get(
        'profile_account_change_password_raw_html'
      ),
      confirmButtonText: this.translationsLib.get('save'),
      confirmButtonColor: '#224261',
      focusConfirm: false,
      preConfirm: () => {
        const oldPass = Swal.getPopup().querySelector(
          '#oldpass'
        ) as HTMLInputElement;
        const oldPassValue = oldPass.value;
        const newPass = Swal.getPopup().querySelector(
          '#newpass'
        ) as HTMLInputElement;
        const newPassValue = newPass.value;
        if (!oldPassValue || !newPassValue) {
          Swal.showValidationMessage(
            this.translationsLib.get('profile_account_wrong_data')
          );
        } else {
          if (newPassValue.length < 6) {
            Swal.showValidationMessage(
              this.translationsLib.get('auth_register_password_length')
            );
          }
        }
        return { oldPassValue, newPassValue };
      },
    }).then(
      (result) => {
        const data = {
          old_password: result.value.oldPassValue,
          new_password: result.value.newPassValue,
        };
        this.profService.putNewPassword(data).subscribe(
          (response) => {
            Swal.fire({
              title: this.translationsLib.get(
                'profile_account_password_changed'
              ),
              icon: 'success',
              confirmButtonColor: '#224261',
            });
          },
          (error) => {
            Swal.fire({
              icon: 'error',
              title: this.translationsLib.get('error'),
              text: this.translationsLib.get('profile_account_password_error'),
              confirmButtonColor: '#224261',
            }).then(() => {
              this.changePassword();
            });
          }
        );
      },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: this.translationsLib.get('error'),
          text: this.translationsLib.get('profile_account_password_error'),
          confirmButtonColor: '#224261',
        });
      }
    );
  }
  refreshAvatar() {
    const data = {
      name: this.avatar.name,
      background: this.color,
      color: this.textcolor,
    };
    this.session.setAvatar(data);
    this.authService.getUserData().subscribe((response) => {
      this.avatarRef = {
        name: response.name,
        surname: response.surname,
        company_name: response.company_name,
        phone: response.phone,
        email: response.email,
        address: response.address,
        country_id: response.country_id,
        province_id: response.province_id || '',
        province_name: response.province_name,
        avatar_colors: [this.color, this.textcolor],
      };
      this.profService.putUsersData(this.avatarRef).subscribe(
        (response) => {
          location.reload();
        },
        (error) => {
          Swal.fire({
            icon: 'error',
            title: this.translationsLib.get('error'),
            text: this.translationsLib.get('error_updating_data'),
            confirmButtonColor: '#224261',
          });
        }
      );
    });
  }

  deleteUser() {
    Swal.fire({
      html: this.translationsLib.get('profile_account_delete_raw_html'),
      confirmButtonText: this.translationsLib.get('save'),
      confirmButtonColor: '#224261',
      showCancelButton: true,
      cancelButtonText: this.translationsLib.get('cancel'),
      cancelButtonColor: '#FF0000',
      focusConfirm: false,
      preConfirm: () => {
        const email = Swal.getPopup().querySelector(
          '#email'
        ) as HTMLInputElement;
        const emailValue = email.value;
        const pass = Swal.getPopup().querySelector('#pass') as HTMLInputElement;
        const passValue = pass.value;

        if (!emailValue || !passValue) {
          Swal.showValidationMessage(
            this.translationsLib.get(
              'profile_account_delete_account_need_more_info'
            )
          );
        }
        return { emailValue, passValue };
      },
    }).then(
      (result) => {
        const data = {
          email: result.value.emailValue,
          password: result.value.passValue,
        };
        this.profService.postDeleteUser(data).subscribe((response) => {
          Swal.fire({
            text: this.translationsLib.get(
              'profile_account_deleted_account_successfully'
            ),
            icon: 'success',
            confirmButtonColor: '#224261',
          });
          this.session.destroySession();
        });
      },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: this.translationsLib.get('error'),
          text: this.translationsLib.get('error_updating_data'),
          confirmButtonColor: '#224261',
        });
      }
    );
  }

  getLanguages() {
    this.commonsService.getLanguages().subscribe((res) => {
      this.languages = res;
    });
  }
  setCountry(countryId) {
    this.selectedCountry = countryId;
    this.editData.patchValue({
      country_id: countryId,
    });
    this.getProvinces();
  }
  setProvince(provinceId: string) {
    this.editData.patchValue({
      province_id: Number(provinceId),
    });
    this.getProvince(Number(provinceId));
  }
}
