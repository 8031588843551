import { Injectable } from '@angular/core';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { WaterSystemUtilsService } from './water-system-utils.service';
import { WaterSystemsService } from './water-system.service';
import {
  WaterSystem,
  WaterSystemFields,
  WaterSystemField,
  WaterSystemFieldChips,
  WaterSystemFieldDouble,
  WaterSystemFieldTriple,
  WaterSystemFieldFertilizer,
  WaterSystemFieldProgress,
  WaterSystemFieldProgressType,
  WaterSystemFieldFertilizerValue,
} from '../models/water-system.model';
import {
  ConfigurableEquipmentModel,
  ConfigurableGroupModel,
  ConfigurableProgramModel,
  ConfigurableProgramStateModel,
} from '../../configurable.model';
import { WaterSystemDataService } from './water-system-data.service';

@Injectable({
  providedIn: 'root',
})
export class WaterSystemTransformerService {
  private equipment: ConfigurableEquipmentModel;
  private programsStates: ConfigurableProgramStateModel[];
  private programs: ConfigurableProgramModel[];
  private programState: ConfigurableProgramStateModel | undefined;
  private program: ConfigurableProgramModel | undefined;
  private group: ConfigurableGroupModel | undefined;

  constructor(
    private waterSystemsService: WaterSystemsService,
    private waterSystemsUtilsService: WaterSystemUtilsService,
    private waterSystemDataService: WaterSystemDataService,
    private translationsLibService: TranslationsLibService
  ) {}

  public async extractWaterSystem(
    equipment: ConfigurableEquipmentModel
  ): Promise<WaterSystem> {
    this.equipment = equipment;

    this.programs = await new Promise<ConfigurableProgramModel[]>(
      (resolve, reject) => {
        this.waterSystemDataService
          .fetchPrograms(this.equipment.serialNumber)
          .subscribe({
            next: (programs) => {
              resolve(programs);
            },
            error: (error) => {
              reject(error);
            },
          });
      }
    );

    this.programsStates = equipment.states.programs;
    this.programState = this.waterSystemsService.getWaterSystemProgramState(
      this.programsStates
    );
    this.program = this.programs.find((program) => {
      if (this.programState && program) {
        return this.programState.program === program.program;
      }
      return false;
    });
    if (this.program && this.programState) {
    }
    this.group =
      this.program && this.programState
        ? this.programState.currentGroup !== 255
          ? this.program.groups.find((group) => {
              return this.programState.currentGroup === group.group;
            })
          : undefined
        : undefined;

    return {
      lastUpdate: this.equipment.lastMeasure,
      active: this.waterSystemsService.hasActiveProgram(this.programsStates),
      enabled: this.waterSystemsService.waterSystemIsEnabled(
        this.programsStates
      ),
      badgeLabel: this.waterSystemsService.getBadgeLabel(this.programsStates),
      programState:
        this.waterSystemsService.getWaterSystemProgramState(this.programsStates)
          ?.state ?? '',
      fields: this.getFields(),
    };
  }

  private getFields(): WaterSystemFields {
    return {
      program: this.getProgram(),
      group: this.getGroup(),
      state: this.getState(),
      activation: this.getActivation(),
      flow: this.getFlow(),
      irrigationProgress: this.getIrrigationProgress(),
      valves: this.getValves(),
      pump: this.getPump(),
      irrigationTime: this.getIrrigationTime(),
      readCE: this.getReadCE(),
      readPH: this.getReadPH(),
      idealPH: this.getIdealPH(),
      fertilizerFormula: this.getFertilizerFormula(),
    };
  }

  private invisibleField(): WaterSystemField {
    return {
      type: 'card',
      label: undefined,
      unit: undefined,
      value: undefined,
      visible: false,
    };
  }

  private invisibleFieldDouble(): WaterSystemFieldDouble {
    return {
      type: 'cardDouble',
      labelLeft: undefined,
      labelRight: undefined,
      unit: undefined,
      valueLeft: undefined,
      valueRight: undefined,
      visible: false,
    };
  }

  private invisibleFieldTriple(): WaterSystemFieldTriple {
    return {
      type: 'cardTriple',
      labelLeft: undefined,
      labelMiddle: undefined,
      labelRight: undefined,
      unit: undefined,
      valueLeft: undefined,
      valueMiddle: undefined,
      valueRight: undefined,
      visible: false,
    };
  }

  private invisibleChipField(): WaterSystemFieldChips {
    return {
      type: 'chips',
      label: undefined,
      values: [],
      visible: false,
    };
  }

  private invisibleFertilizerField(): WaterSystemFieldFertilizer {
    return {
      type: 'cardFertilizer',
      label: undefined,
      fertilizers: [],
      visible: false,
    };
  }

  private getProgram(): WaterSystemField {
    if (!this.programState) return this.invisibleField();

    return {
      type: 'card',
      label: this.translationsLibService.get('irrigation_active_program'),
      unit: undefined,
      value: this.programState
        ? this.programState.name
        : this.translationsLibService.get('irrigation_non_active_program'),
      visible: true,
    };
  }

  private getGroup(): WaterSystemField {
    if (!this.group) return this.invisibleField();

    return {
      type: 'card',
      label: this.translationsLibService.get('irrigation_group'),
      unit: undefined,
      value:
        this.translationsLibService.get('irrigation_group') +
        ' ' +
        (this.group.group + 1),
      visible: true,
    };
  }

  private getState(): WaterSystemField {
    if (!this.programState) return this.invisibleField();

    return {
      type: 'card',
      label: this.translationsLibService.get('configurable_phase'),
      unit: undefined,
      value: this.translationsLibService.get(
        'configurable_state_' + this.programState.state
      ),
      visible: true,
    };
  }

  private getActivation(): WaterSystemField {
    if (!this.programState) return this.invisibleField();

    return {
      type: 'card',
      label: this.translationsLibService.get('irrigation_program_activation'),
      unit: undefined,
      value: this.programState.activations.toString(),
      visible: true,
    };
  }

  private getFlow(): WaterSystemField {
    if (!this.programState) return this.invisibleField();

    return {
      type: 'card',
      label: this.translationsLibService.get('irrigation_ws_flow'),
      unit: 'm³/h',
      value:
        this.programState.flow !== -1
          ? this.programState.flow.toString()
          : undefined,
      visible: true,
    };
  }

  private getIrrigationProgress(): WaterSystemFieldProgress {
    if (this.program === undefined || this.group === undefined) {
      return {
        type: 'progressBar',
        irrigation: undefined,
        visible: false,
      };
    }

    const irrigationData = this.getProgressIrrigation();

    return {
      type: 'progressBar',
      irrigation: irrigationData.totalTime > 0 ? irrigationData : undefined,
      visible: irrigationData.totalTime > 0,
    };
  }

  private getProgressIrrigation(): WaterSystemFieldProgressType | undefined {
    const sortedRanges = [
      this.group.preIrrigationTime,
      this.group.preIrrigationTime +
        this.group.duration -
        this.group.preIrrigationTime -
        this.group.postIrrigationTime,
      this.programState.actualTime,
    ].sort((a, b) => a - b);

    return {
      phase: 1,
      totalTime: this.group.duration,
      currentTime: 0,
      ranges: sortedRanges,
      timeLabel:
        this.waterSystemsUtilsService.formatSeconds(0) +
        ' / ' +
        this.waterSystemsUtilsService.formatSeconds(this.group.duration),
    };
  }

  private getValves(): WaterSystemFieldChips {
    if (!this.group) return this.invisibleChipField();

    const valvesNames = this.group.valves.map((groupValve) => {
      return (
        this.translationsLibService.get('irrigation_valve') +
        ' ' +
        (groupValve.valve + 1)
      );
    });

    return {
      type: 'chips',
      label: this.translationsLibService.get('irrigation_valves'),
      values: valvesNames,
      visible: true,
    };
  }

  private getPump(): WaterSystemField {
    if (!this.group) {
      return {
        type: 'card',
        label: this.translationsLibService.get('irrigation_pump'),
        unit: undefined,
        value: undefined,
        visible: true,
      };
    }
    const pumps = this.group.pumps
      .filter((pump) => pump.pump !== 255)
      .map((pump) => pump.pump + 1);
    return {
      type: 'card',
      label: this.translationsLibService.get('irrigation_pump'),
      unit: undefined,
      value: pumps.length > 0 ? pumps.join(', ') : undefined,
      visible: true,
    };
  }

  private getIrrigationTime(): WaterSystemFieldTriple {
    if (!this.group) return this.invisibleFieldTriple();
    return {
      type: 'cardTriple',
      labelLeft: this.translationsLibService.get(
        'irrigation_pre_fertilization_time'
      ),
      labelMiddle: this.translationsLibService.get(
        'irrigation_fertilization_time'
      ),
      labelRight: this.translationsLibService.get(
        'irrigation_post_fertilization_time'
      ),
      unit: undefined,
      valueLeft: this.waterSystemsUtilsService.convertSecondsToHHMM(
        this.group.preIrrigationTime
      ),
      valueMiddle: this.waterSystemsUtilsService.convertSecondsToHHMM(
        this.group.duration -
          this.group.preIrrigationTime -
          this.group.postIrrigationTime
      ),
      valueRight: this.waterSystemsUtilsService.convertSecondsToHHMM(
        this.group.postIrrigationTime
      ),
      visible: this.group.duration > 0,
    };
  }

  private getReadCE(): WaterSystemField {
    return {
      type: 'card',
      label: this.translationsLibService.get('irrigation_ws_OutEC'),
      unit: 'mS/cm',
      value:
        this.equipment.states.readingCE !== -1
          ? this.equipment.states.readingCE.toString()
          : undefined,
      visible: true,
    };
  }

  private getReadPH(): WaterSystemField {
    return {
      type: 'card',
      label: this.translationsLibService.get('irrigation_ws_readPH'),
      unit: undefined,
      value:
        this.equipment.states.readingPH !== -1
          ? this.equipment.states.readingPH.toString()
          : undefined,
      visible: true,
    };
  }

  private getIdealPH(): WaterSystemField {
    if (!this.group) return this.invisibleField();

    return {
      type: 'card',
      label: this.translationsLibService.get('irrigation_ideal_ph'),
      unit: 'mS/cm',
      value:
        this.program.idealPH !== -1
          ? this.program.idealPH.toString()
          : undefined,
      visible: true,
    };
  }

  private getFertilizerFormula(): WaterSystemFieldFertilizer {
    if (!this.group) return this.invisibleFertilizerField();

    const fertilizersData: WaterSystemFieldFertilizerValue[] = [];
    for (let fertilizerNumber = 1; fertilizerNumber <= 4; fertilizerNumber++) {
      const fertilizer = this.group.fertilizers[fertilizerNumber - 1];
      if (fertilizer.fertilizer !== 255) {
        fertilizersData.push({
          fertilizer: fertilizer.fertilizer + 1,
          value: this.waterSystemsUtilsService.convertSecondsToHHMM(
            fertilizer.time
          ),
        });
      }
    }

    return {
      type: 'cardFertilizer',
      label: this.translationsLibService.get('irrigation_fertilizer_formula'),
      fertilizers: fertilizersData,
      visible: true,
    };
  }
}
