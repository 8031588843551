import { Pipe, PipeTransform } from '@angular/core';
import { ProgramModel } from '../irrigation/irrigation.model';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
@Pipe({
  name: 'convertWeekDays',
})
export class ConvertWeekDaysPipe implements PipeTransform {
  constructor(public translationsLib: TranslationsLibService) {}
  transform(program: ProgramModel) {
    if (program.week_days === 'WEEK' && program.week !== null) {
      const days = {
        monday: this.translationsLib.get('calendar_monday_short'),
        tuesday: this.translationsLib.get('calendar_tuesday_short'),
        wednesday: this.translationsLib.get('calendar_wednesday_short'),
        thursday: this.translationsLib.get('calendar_thursday_short'),
        friday: this.translationsLib.get('calendar_friday_short'),
        saturday: this.translationsLib.get('calendar_saturday_short'),
        sunday: this.translationsLib.get('calendar_sunday_short'),
      };
      const selectedDays = Object.keys(program.week).filter(
        (day) => program.week[day]
      );
      if (selectedDays.length === 0) {
        return this.translationsLib.get('irrigation_program_inactive');
      } else if (selectedDays.length === 7) {
        return this.translationsLib.get('calendar_whole_week');
      } else {
        return selectedDays.map((day) => days[day]).join(', ');
      }
    } else if (program.week_days === 'DAYS' && program.days !== null) {
      if (program.days === 1) {
        return `${this.translationsLib.get('every')} ${
          program.days
        } ${this.translationsLib.get('single_day')}`;
      } else {
        return `${this.translationsLib.get('every')} ${
          program.days
        }  ${this.translationsLib.get('days')}`;
      }
    }
    return '';
  }
}
