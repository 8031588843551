<app-dirty-message
  [conditions]="layoutConfiguration && !isFetchingData"
  [dirty]="traysForm.value.dirty"
></app-dirty-message>

<ng-template
  [ngIf]="
    mode === 'simple' &&
    layoutConfiguration &&
    !isFetchingData &&
    !isDrainageTraysWithoutData
  "
>
  <form [formGroup]="traysForm" class="simple-form">
    <div class="mb-2">
      <div class="card">
        <div class="card-body custom-card-body">
          <div class="row">
            <div class="col-sm-5 col-md-5">
              <h6 class="fs-2">
                {{ translationsLib.get('irrigation_drainage_tray') }}
              </h6>
              <p class="fs-5 fw-lighter">
                {{
                  translationsLib.get('irrigation_drainage_tray_help')
                    | sanitizeText
                }}
              </p>
            </div>
            <div class="col-sm-7 col-md-7 col-inputs">
              <div class="row first-row">
                <div class="col-sm-12 col-md-6">
                  <h6 class="fs-5">
                    {{ translationsLib.get('irrigation_attached_valve') }}
                  </h6>
                  <div class="input-group mb-2">
                    <select class="form-select" formControlName="valve">
                      <option [value]="255">
                        {{ translationsLib.get('irrigation_no_valve') }}
                      </option>
                      <option
                        [value]="valve.valve"
                        *ngFor="let valve of valvesList"
                      >
                        {{
                          valve.name
                            ? valve.name
                            : translationsLib.get('irrigation_valve') +
                              ' ' +
                              (valve.valve + 1)
                        }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <h6 class="fs-5">
                    {{ translationsLib.get('irrigation_pick_tray') }}
                  </h6>
                  <div class="input-group mb-2 number-input">
                    <button
                      class="minus minus-style"
                      (click)="inputNumberService.decrementValue($event)"
                    ></button>
                    <input
                      formControlName="pickTray"
                      min="1"
                      max="100"
                      type="number"
                      class="form-control"
                      placeholder="{{
                        translationsLib.get('irrigation_pick_tray')
                      }}"
                    />
                    <button
                      class="plus border-plus plus-style"
                      (click)="inputNumberService.incrementValue($event)"
                    ></button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <h6 class="fs-5">
                    {{ translationsLib.get('irrigation_pick_flow') }}
                  </h6>
                  <div class="input-group mb-2 number-input">
                    <button
                      class="minus minus-style"
                      (click)="inputNumberService.decrementValue($event)"
                    ></button>
                    <input
                      formControlName="pickFlow"
                      min="0"
                      max="10"
                      step="0.1"
                      type="number"
                      class="form-control"
                      placeholder="{{
                        translationsLib.get('irrigation_pick_flow')
                      }}"
                    />
                    <button
                      class="plus border-plus plus-style"
                      (click)="inputNumberService.incrementValue($event)"
                    ></button>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <h6 class="fs-5">
                    {{ translationsLib.get('irrigation_liters_pulse') }}
                  </h6>
                  <div class="input-group mb-2 number-input">
                    <button
                      class="minus minus-style"
                      (click)="inputNumberService.decrementValue($event)"
                    ></button>
                    <input
                      formControlName="litresPulse"
                      min="0"
                      max="10"
                      step="0.01"
                      type="number"
                      class="form-control"
                      placeholder="{{
                        translationsLib.get('irrigation_liters_pulse')
                      }}"
                    />
                    <button
                      class="plus border-plus plus-style"
                      (click)="inputNumberService.incrementValue($event)"
                    ></button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <h6 class="fs-5">
                    {{
                      translationsLib.get('irrigation_drainage_opening_time')
                    }}
                  </h6>
                  <div class="mb-2">
                    <ng-template #drainageOpeningTimePicker>
                      <timepicker
                        appOnlyNumbers
                        [showMeridian]="false"
                        [showSeconds]="true"
                        [minuteStep]="1"
                        [secondsStep]="1"
                        [formControlName]="'drainageOpeningTime'"
                      ></timepicker>
                    </ng-template>
                    <input
                      appOnlyNumbers
                      [ngClass]="{
                        'ng-dirty': traysForm.controls.drainageOpeningTime.dirty
                      }"
                      class="form-control text-center"
                      type="text"
                      [popover]="drainageOpeningTimePicker"
                      [outsideClick]="true"
                      placement="bottom"
                      [value]="traysForm.value.drainageOpeningTime | inputTime"
                      (change)="
                        onChangeInputTimeWithKeyboard(
                          $event,
                          'drainageOpeningTime'
                        )
                      "
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <h6 class="fs-5">
                    {{ translationsLib.get('irrigation_ce_sensor') }}
                  </h6>
                  <div class="input-group mb-2">
                    <select class="form-select" formControlName="ceSensor">
                      <option value="null">-</option>
                      <ng-container *ngFor="let sensor of sensorsList">
                        <ng-container
                          *ngIf="
                            sensor.type === 0 ||
                            sensor.type === 7 ||
                            sensor.type === 16
                          "
                        >
                          <option [value]="sensor.id">{{ sensor.name }}</option>
                        </ng-container>
                      </ng-container>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>

<ng-template
  [ngIf]="
    mode === 'advanced' &&
    layoutConfiguration &&
    !isFetchingData &&
    !isDrainageTraysWithoutData
  "
>
  <form [formGroup]="traysForm" class="advanced-form">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="card-body custom-card-body">
            <div class="row">
              <div class="col-md-12">
                <h6 class="fs-2">
                  {{ translationsLib.get('irrigation_drainage_tray') }}
                </h6>
              </div>
            </div>
            <div class="row">
              <div class="col-6 col-md-3">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_attached_valve') }}
                </h6>
              </div>
              <div class="col-6 col-md-3">
                <div class="input-group mb-2">
                  <select class="form-select" formControlName="valve">
                    <option [value]="255">
                      {{ translationsLib.get('irrigation_no_valve') }}
                    </option>
                    <option
                      [value]="valve.valve"
                      *ngFor="let valve of valvesList"
                    >
                      {{
                        valve.name
                          ? valve.name
                          : translationsLib.get('irrigation_valve') +
                            ' ' +
                            (valve.valve + 1)
                      }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6 col-md-3">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_pick_tray') }}
                </h6>
              </div>
              <div class="col-6 col-md-3">
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="pickTray"
                    min="1"
                    max="100"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_pick_tray')
                    }}"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6 col-md-3">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_pick_flow') }}
                </h6>
              </div>
              <div class="col-6 col-md-3">
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="pickFlow"
                    min="0"
                    max="10"
                    step="0.1"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_pick_flow')
                    }}"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
              <div class="col-6 col-md-3">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_liters_pulse') }}
                </h6>
              </div>
              <div class="col-6 col-md-3">
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="litresPulse"
                    min="0"
                    max="10"
                    step="0.01"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_liters_pulse')
                    }}"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-sm-6 col-md-3">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get('irrigation_drainage_opening_time')
                      }}
                    </h6>
                  </div>
                  <div class="col-sm-6 col-md-3">
                    <div class="mb-2">
                      <ng-template #drainageOpeningTimePicker>
                        <timepicker
                          appOnlyNumbers
                          [showMeridian]="false"
                          [showSeconds]="true"
                          [minuteStep]="1"
                          [secondsStep]="1"
                          [formControlName]="'drainageOpeningTime'"
                        ></timepicker>
                      </ng-template>
                      <input
                        appOnlyNumbers
                        [ngClass]="{
                          'ng-dirty':
                            traysForm.controls.drainageOpeningTime.dirty
                        }"
                        class="form-control text-center"
                        type="text"
                        [popover]="drainageOpeningTimePicker"
                        [outsideClick]="true"
                        placement="bottom"
                        [value]="
                          traysForm.value.drainageOpeningTime | inputTime
                        "
                        (change)="
                          onChangeInputTimeWithKeyboard(
                            $event,
                            'drainageOpeningTime'
                          )
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-sm-6 col-md-3">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_ce_sensor') }}
                    </h6>
                  </div>
                  <div class="col-sm-6 col-md-3">
                    <div class="input-group mb-2">
                      <select class="form-select" formControlName="ceSensor">
                        <option value="null">-</option>
                        <ng-container *ngFor="let sensor of sensorsList">
                          <ng-container
                            *ngIf="
                              sensor.type === 0 ||
                              sensor.type === 7 ||
                              sensor.type === 16
                            "
                          >
                            <option [value]="sensor.id">
                              {{ sensor.name }}
                            </option>
                          </ng-container>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
