import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../profile.service';
import {
  NotificationPreferencesModel,
  UserDeviceNotificationPreferencesModel,
  UserTerminalNotificationPreferencesModel,
} from '../profile.model';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  notificationPreferences: NotificationPreferencesModel;
  loaded = false;
  constructor(
    private profService: ProfileService,
    public translationsLib: TranslationsLibService,
    private toastController: ToastController
  ) {}

  ngOnInit() {
    this.getNotificationPreferences();
  }

  getNotificationPreferences() {
    this.profService.getNotificationPreferences().subscribe((preferences) => {
      this.notificationPreferences = preferences;
      this.loaded = true;
    });
  }

  async userNotificationChanged() {
    this.profService.putUserNotificationPreferences().subscribe();

    // Message for the Toast depending on the switch state
    const message = this.notificationPreferences.user_preferences.enabled
      ? this.translationsLib.get('notifications_user_enable')
      : this.translationsLib.get('notifications_user_disable');

    // Show the Toast
    await this.presentToast(message);
  }

  async userDeviceNotificationChanged(
    device: UserDeviceNotificationPreferencesModel
  ) {
    this.profService
      .putUserDeviceNotificationPreferences(device.device_id)
      .subscribe();

    // Message for the Toast depending on the switch state
    let message = device.notification_enabled
      ? this.translationsLib.get('notifications_device_enable')
      : this.translationsLib.get('notifications_device_disable');

    message += ' ' + device.model;

    // Show the Toast
    await this.presentToast(message);
  }

  async userTerminalNotificationChanged(
    terminal: UserTerminalNotificationPreferencesModel
  ) {
    this.profService
      .putUserTerminalNotificationPreferences(terminal.terminal_vid)
      .subscribe();

    // Message for the Toast depending on the switch state
    let message = terminal.enabled
      ? this.translationsLib.get('notifications_terminal_enable')
      : this.translationsLib.get('notifications_terminal_disable');

    message += ' ' + terminal.terminal_name;

    // Show the Toast
    await this.presentToast(message);
  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 3000,
    });

    await toast.present();
  }
}
