import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import {
  ConfigurableFertilizerModel,
  ConfigurableGroupModel,
  ConfigurablePumpModel,
  ConfigurableStatesModel,
  ConfigurableValveModel,
  FERTILIZER_TYPES,
  MAX_CONFIG_FERTILIZERS,
  MAX_CONFIG_PUMPS,
  MAX_CONFIG_VALVES,
  MAX_FERTILIZERS,
  MAX_PUMPS,
  MAX_VALVES,
} from '../configurable.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  DateToSecondsInput,
  convertFormattedTimeToSeconds,
  secondsInputToDate,
} from 'src/app/commons/helpers/functions';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-configurable-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss'],
})
export class GroupsComponent implements OnInit {
  @Input() groups: ConfigurableGroupModel[];
  @Input() mode = '';
  @Input() states: ConfigurableStatesModel;
  @Output() dirty = new EventEmitter<ConfigurableGroupModel[]>();

  pristineGroups: ConfigurableGroupModel[];
  groupForm: FormGroup;
  selectedGroup = 0;

  @Output() clearIntervalEventEmitter = new EventEmitter<boolean>();

  valvesDirty = false;
  pumpsDirty = false;
  fertilizersDirty = [];

  numValves: ConfigurableValveModel[];
  numPumps: ConfigurablePumpModel[];
  numFertilizers = [...Array(MAX_FERTILIZERS).keys()];
  numConfigFertilizers = [...Array(MAX_CONFIG_FERTILIZERS).keys()];

  fertilizerTypes = FERTILIZER_TYPES;
  fertilizerSelected: any;
  maxFertilizerTime = new Date('1970-01-01T08:59:00.000Z'); // 09:59

  constructor(
    public translationsLib: TranslationsLibService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private location: Location
  ) {
    this.groupForm = this.fb.group<ConfigurableGroupModel>({
      program: 0,
      group: 0,
      duration: 0,
      preIrrigationTime: 0,
      postIrrigationTime: 0,
      fertilizerType: 0,
      injections: 0,
      fertilizerCycleTime: 0,
      dirty: false,
      valves: [],
      pumps: [],
      fertilizers: [],
    });
  }

  ngOnInit(): void {
    this.selectedGroup =
      Number(this.route.snapshot.paramMap.get('group_id')) || 0;
    this.pristineGroups = JSON.parse(JSON.stringify(this.groups));
    this.setForm();

    this.groupForm.valueChanges.subscribe((_) => {
      const isDirty = this.groupForm.pristine ? false : true;

      if (isDirty) {
        this.clearIntervalEventEmitter.emit(true);
      } else {
        this.clearIntervalEventEmitter.emit(false);
      }
    });
  }

  setForm() {
    this.location.go(
      '/farming/' +
        this.route.snapshot.paramMap.get('vid') +
        '/configurable/program/' +
        this.route.snapshot.paramMap.get('program_id') +
        '/group/' +
        this.selectedGroup
    );
    const group = this.groups[this.selectedGroup];
    const { duration, preIrrigationTime, postIrrigationTime, fertilizers } =
      group;
    this.groupForm.patchValue({
      ...group,
      duration: secondsInputToDate(duration),
      preIrrigationTime: secondsInputToDate(preIrrigationTime),
      postIrrigationTime: secondsInputToDate(postIrrigationTime),
      fertilizers: fertilizers.map((fertilizer) => ({
        ...fertilizer,
        time: secondsInputToDate(fertilizer.time),
      })),
    });
    this.numValves = Array.from({ length: MAX_VALVES }, (_, i) => ({
      program: group.program,
      group: group.group,
      order: i,
      valve: i,
      dirty: false,
    }));
    this.numPumps = Array.from({ length: MAX_PUMPS }, (_, i) => ({
      program: group.program,
      group: group.group,
      order: i,
      pump: i,
      dirty: false,
    }));
    this.fertilizersDirty = Array.from(
      { length: MAX_CONFIG_FERTILIZERS },
      (_, i) => ({
        dirty: false,
        value: group.fertilizers[i]?.time,
      })
    );
  }

  getGroupName(group: ConfigurableGroupModel): string {
    return group
      ? this.translationsLib.get('configurable_group') +
          ' ' +
          (+group.group + 1)
      : '-';
  }

  onChangeInputTimeWithKeyboard(
    event,
    formInput: string,
    arrayIndex?: number,
    arrayProp?: string
  ) {
    let timeString = event.target.value;
    if (timeString.split(':').length === 2) timeString += ':00';
    const toSeconds = convertFormattedTimeToSeconds(timeString);
    const toDate = secondsInputToDate(toSeconds);
    if (arrayIndex !== undefined && arrayProp !== undefined) {
      const _array = [...this.groupForm.get(formInput).value];
      _array[arrayIndex][arrayProp] = toDate;
      this.groupForm.get(formInput).setValue(_array);
    } else {
      this.groupForm.get(formInput).setValue(toDate);
    }
    this.groupForm.markAsDirty();
    this.emitGroups();
  }

  // Válvulas
  get valves() {
    return this.groupForm.get('valves').value;
  }
  compareValves(
    valve1: ConfigurableValveModel,
    valve2: ConfigurableValveModel
  ) {
    return valve1.valve === valve2.valve;
  }
  selectValve(valves: ConfigurableValveModel[]) {
    if (valves.length > MAX_CONFIG_VALVES) valves.pop();
    this.groupForm.patchValue({ valves });
    this.groupForm.controls.valves.markAsDirty();
    this.groups[this.selectedGroup].valves = valves;
    this.emitGroups();
  }
  removeValve(valve: ConfigurableValveModel): void {
    const index = this.valves.indexOf(valve);
    if (index >= 0) {
      const valves = [...this.valves];
      valves.forEach((v) => {
        if (v.order > valve.order) v.order--;
      });
      valves.splice(index, 1);
      this.groupForm.patchValue({ valves });
      this.groupForm.controls.valves.markAsDirty();
      this.groups[this.selectedGroup].valves = valves;
      this.emitGroups();
    }
  }
  moveValve(event: CdkDragDrop<ConfigurableGroupModel[]>) {
    const { previousIndex, currentIndex } = event;
    const valves = [...this.valves];
    valves[currentIndex].order = previousIndex;
    valves[previousIndex].order = currentIndex;
    moveItemInArray(valves, previousIndex, currentIndex);
    this.groupForm.patchValue({ valves });
    this.groupForm.controls.valves.markAsDirty();
    this.groups[this.selectedGroup].valves = valves;
    this.emitGroups();
  }

  // Bombas
  get pumps() {
    return this.groupForm.get('pumps').value;
  }
  comparePumps(pump1: ConfigurablePumpModel, pump2: ConfigurablePumpModel) {
    return pump1.pump === pump2.pump;
  }
  selectPump(pumps: ConfigurablePumpModel[]) {
    if (pumps.length > MAX_CONFIG_PUMPS) pumps.pop();
    this.groupForm.patchValue({ pumps });
    this.groupForm.controls.pumps.markAsDirty();
    this.groups[this.selectedGroup].pumps = pumps;
    this.emitGroups();
  }
  removePump(pump: ConfigurablePumpModel): void {
    const index = this.pumps.indexOf(pump);
    if (index >= 0) {
      const pumps = [...this.pumps];
      pumps.forEach((_pump) => {
        if (_pump.order > pump.order) _pump.order--;
      });
      pumps.splice(index, 1);
      this.groupForm.patchValue({ pumps });
      this.groupForm.controls.pumps.markAsDirty();
      this.groups[this.selectedGroup].pumps = pumps;
      this.emitGroups();
    }
  }
  movePump(event: CdkDragDrop<ConfigurableGroupModel[]>) {
    const { previousIndex, currentIndex } = event;
    const pumps = [...this.pumps];
    pumps[currentIndex].order = previousIndex;
    pumps[previousIndex].order = currentIndex;
    moveItemInArray(pumps, previousIndex, currentIndex);
    this.groupForm.patchValue({ pumps });
    this.groupForm.controls.pumps.markAsDirty();
    this.groups[this.selectedGroup].pumps = pumps;
    this.emitGroups();
  }

  isFieldDirty(field: string): boolean {
    return (
      JSON.stringify(this.groups[this.selectedGroup][field]) !==
      JSON.stringify(this.pristineGroups[this.selectedGroup][field])
    );
  }

  emitGroups() {
    // Pasamos los Date a segundos
    this.groups[this.selectedGroup].duration = DateToSecondsInput(
      this.groupForm.get('duration').value
    );
    this.groups[this.selectedGroup].preIrrigationTime = DateToSecondsInput(
      this.groupForm.get('preIrrigationTime').value
    );
    this.groups[this.selectedGroup].postIrrigationTime = DateToSecondsInput(
      this.groupForm.get('postIrrigationTime').value
    );
    const fertilizers = this.groupForm.get('fertilizers').value.map((fert) => ({
      ...fert,
      time: DateToSecondsInput(fert.time),
      fertilizer: fert.fertilizer,
    }));
    this.groups[this.selectedGroup].fertilizers = [...fertilizers];
    this.groups[this.selectedGroup].fertilizerType =
      this.groupForm.get('fertilizerType').value;
    this.dirty.emit(this.groups); // Tendré q emitir todos los grupos
  }

  isIrrigating() {
    return this.states.programs.some(
      (prog) =>
        this.groups.length > 0 &&
        prog.program === this.groups[0].program &&
        prog.actualGroup === this.selectedGroup &&
        prog.state === 'IRRIGATING'
    );
  }
}
