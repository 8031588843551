<div class="card mx-2 my-4" *ngIf="viewMode ===1">
  <!--<div class="row mx-2">
    <div class="col-9">
      <mat-form-field  appearance="fill" style="border:0px; padding: 0px;">
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
          <input matStartDate formControlName="start" placeholder="{{translationsLib.get('graphics_init_date')}}">
          <input matEndDate formControlName="end" placeholder="{{translationsLib.get('graphics_end_date')}}">
        </mat-date-range-input>
        <mat-datepicker-toggle matIconSuffix [for]="picker" ></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">{{translationsLib.get('graphics_invalid_start_date')}}</mat-error>
        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">{{translationsLib.get('graphics_invalid_end_date')}}</mat-error>
      </mat-form-field>
    </div>
    <div class="col-3 text-end">
      <div class="btn btn-primary" (click)="createGraph()">
        {{translationsLib.get('graphics_create_graph')}}
      </div>
    </div>
  </div>-->
  <h4 class="mx-4 text-uppercase" style="color: #ee7869">{{translationsLib.get('modules_irrigation_advisor')}}</h4>
  <lib-loader *ngIf="loadedGraph!== true"></lib-loader>
  <highcharts-chart id="graphics"
                    [Highcharts]="Highcharts"
                    [constructorType]="chartConstructor"
                    [options]="irrigationChart"
                    [(update)]="updateFlag"
                    [callbackFunction]="chartCallback"
                    [oneToOne]="true"
                    style="height: 450px; display: block;"
                    class="mb-2"
  >
  </highcharts-chart>
</div>
<div  *ngIf="viewMode !== 1">
  <lib-loader *ngIf="loadedGraph!== true"></lib-loader>
  <highcharts-chart id="graphics"
                    [Highcharts]="Highcharts"
                    [constructorType]="chartConstructor"
                    [options]="irrigationChart"
                    [(update)]="updateFlag"
                    [callbackFunction]="chartCallback"
                    [oneToOne]="true"
                    style="height: 450px; display: block;"
                    class="mb-2"
  >
  </highcharts-chart>
</div>
