import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UnsavedFormChanges } from 'src/app/commons/guards/unsaved-form-changes.guard';
import { CanActivateViaAuthGuard } from 'src/app/services/guards/guards';
import { TerminalDashboardComponent } from './terminal-dashboard/terminal-dashboard.component';
import { ProgramsComponent } from './programs/programs.component';

const routes: Routes = [
  {
    path: '',
    component: TerminalDashboardComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: ':tab',
    component: TerminalDashboardComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: 'program/:program_id',
    component: ProgramsComponent,
    canActivate: [CanActivateViaAuthGuard],
    canDeactivate: [UnsavedFormChanges],
  },
  {
    path: 'program/:program_id/group/:group_id',
    component: ProgramsComponent,
    canActivate: [CanActivateViaAuthGuard],
    canDeactivate: [UnsavedFormChanges],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ConfigurableRoutingModule {}
