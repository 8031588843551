import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FieldsService } from '../fields.service';
import { FormBuilder, Validators } from '@angular/forms';
import {
  SessionLibService,
  TranslationsLibService,
  PlatformLibService,
  TerminalDataModel,
} from '@nutricontrol/app360-shared';
import { AppCommonsService } from '../../commons/app-commons.service';
import { TerminalsService } from '../../terminals/terminals.service';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import { environment } from '../../../environments/environment';
import { TerminalsLibService } from '../../services/libraries/terminals-lib.service';
import { FieldDataModel, MulticropDataModel } from '../fields.model';
import Swal from 'sweetalert2';
import { MapsLibService } from '../../services/libraries/maps-lib.service';

@Component({
  selector: 'app-fields-edit',
  templateUrl: './fields-edit.component.html',
  styleUrls: ['./fields-edit.component.scss'],
})
export class FieldsEditComponent implements OnInit {
  private fb: FormBuilder = new FormBuilder();
  field: FieldDataModel = null;
  editField;
  vid = '';
  coords;
  cropsList;
  private avatar;
  fieldName;
  staticMap: string;
  private center: { lng: number; lat: number };
  chargeMap: boolean;
  terminals: Array<TerminalDataModel> = [];
  fieldSize;
  private zoom;
  addDevice = false;
  breadcrumbs: any = [];
  nonAttached;
  serialNumber = this.fb.group({
    serial: [''],
  });
  serialNumberRes;
  terminalFound;
  image_path;
  results = this.fb.group({
    nombre_equipo: [''],
    terminal: [''],
  });
  isNative;
  loading = true;
  multicrops: MulticropDataModel[] = [];

  constructor(
    private route: ActivatedRoute,
    private fieldsService: FieldsService,
    private session: SessionLibService,
    private router: Router,
    private commonsService: AppCommonsService,
    private terminalService: TerminalsService,
    private helpersLib: HelpersLibService,
    public translationsLib: TranslationsLibService,
    private terminalsLib: TerminalsLibService,
    private platformLib: PlatformLibService,
    private mapsLib: MapsLibService
  ) {
    this.editField = this.fb.group({
      name: ['', Validators.required],
      multicrops: [],
      color: [''],
      coordinates: [],
    });

    this.chargeMap = false;
    /*this.fieldSize = 0;*/
    route.params.subscribe((val) => {
      const divMap = document.getElementById('map') as HTMLElement;
      if (divMap !== null) {
        location.reload();
      }
    });
  }

  ngOnInit() {
    this.isNative = this.platformLib.isNative();
    this.avatar = this.session.getAvatar();
    this.vid = this.route.snapshot.paramMap.get('vid');
    this.getCrops();
    this.getTerminals();
    setTimeout(() => {
      this.helpersLib.sendEventFieldEdit(this.fieldName, this.vid);
    }, 200);
  }

  async getField() {
    this.fieldsService.getField(this.vid).subscribe(
      async (response) => {
        this.loading = false;
        this.field = response;
        this.fieldSize = (response.coordinates_area / 10000).toFixed(2);
        this.fieldName = response.name;
        this.breadcrumbs = [
          {
            text: this.fieldName,
            disabled: false,
            to: ['/field', { vid: this.vid }],
            active: false,
          },
          {
            text: this.translationsLib.get('edit'),
            disabled: false,
            to: ' ',
            active: true,
          },
        ];
        this.helpersLib.setTitle(
          this.translationsLib.get('field_field'),
          this.fieldName,
          this.translationsLib.get('edit')
        );
        this.coords = response.coordinates;
        this.getCenterCoords();
        /*this.terminals = response.terminals_info;*/
        // eslint-disable-next-line @typescript-eslint/dot-notation
        this.zoom = response.config['map_zoom'];
        if (
          response.multicrops === undefined ||
          (response.multicrops.length === 0 &&
            response.crop_id !== undefined &&
            response.crop_id !== null)
        ) {
          this.multicrops = [
            {
              vid: null,
              crop_id: response.crop_id,
              crop_typology: '',
              farm_type: '',
              irrigation_method: '',
              irrigation_source: '',
              distance_between_rows: 0,
              distance_between_plants_in_row: 0,
              drippers_per_plant: 0,
              emitter_type: 0,
            },
          ];
        } else this.multicrops = response.multicrops;
        this.editField.setValue({
          name: response.name,
          multicrops: this.multicrops,
          color: response.color,
          coordinates: response.coordinates,
        });
        let coordenadasString = '';

        this.coords.forEach((coord) => {
          coordenadasString += coord.latitude + ',' + coord.longitude + '%7C';
        });
        coordenadasString = coordenadasString.slice(
          0,
          coordenadasString.length - 3
        ); //quito la última coma
        this.staticMapString(
          coordenadasString,
          this.center,
          this.field.coordinates_area / 10000
        );
      },
      (error) => {
        this.router.navigate(['/home/dashboard']);
      }
    );
  }
  getTerminals() {
    this.terminalService.getTerminals().subscribe((response) => {
      let fields = [];
      // @ts-ignore
      fields = response;
      fields.forEach((terminal) => {
        if (terminal.field_vid === this.vid) {
          this.terminals.push(terminal);
        }
      });
    });
  }
  getCrops() {
    this.commonsService.getCrops().subscribe((response) => {
      this.cropsList = response;
      this.getField();
    });
  }

  getCenterCoords() {
    let lat = 0;
    let lng = 0;

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < this.coords.length; ++i) {
      lat += this.coords[i].latitude;
      lng += this.coords[i].longitude;
    }

    lat /= this.coords.length;
    lng /= this.coords.length;

    this.center = { lat, lng };
  }

  staticMapString(coordenadasString, centro, size) {
    let color = this.editField.value.color;
    color = color.slice(1);
    this.staticMap = this.mapsLib.staticMapString(
      coordenadasString,
      centro,
      color,
      size,
      this.zoom
    );
  }

  saveData() {
    if (this.editField.value.name === '') {
      Swal.fire({
        text: this.translationsLib.get('field_error_creating_new_field1'),
        icon: 'error',
        confirmButtonColor: '#224261',
      });
    } else if (this.coords === undefined) {
      Swal.fire({
        text: this.translationsLib.get(
          'field_error_creating_new_field_need_polygon_points'
        ),
        icon: 'error',
        confirmButtonColor: '#224261',
      });
    } else if (this.multicrops === undefined || this.multicrops.length === 0) {
      Swal.fire({
        text: this.translationsLib.get('field_error_creating_new_field_field3'),
        icon: 'error',
        confirmButtonColor: '#224261',
      });
    } else {
      const data = {
        name: this.editField.value.name,
        color: this.editField.value.color,
        multicrops: this.multicrops,
        coordinates: this.editField.value.coordinates,
        config: { map_zoom: this.zoom },
      };
      this.fieldsService.putFields(data, this.vid).subscribe(
        (response) => {
          this.commonsService.getMenu().subscribe(async (res) => {
            // await this.router.navigate(['./field', { vid: this.vid }]);
            location.reload();
          });
        },
        (error) => {
          Swal.fire({
            icon: 'error',
            title: this.translationsLib.get('error'),
            text: this.translationsLib.get('error_updating_data'),
            confirmButtonColor: '#2c7be5',
          });
        }
      );
    }
  }
  deleteField() {
    event.preventDefault();
    Swal.fire({
      text: this.translationsLib.get('field_delete_field_text'),
      showCancelButton: true,
      confirmButtonText: this.translationsLib.get('delete'),
      cancelButtonText: this.translationsLib.get('cancel'),
      confirmButtonColor: '#e63757',
      cancelButtonColor: '#224261',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.fieldsService.deleteField(this.vid).subscribe((response) => {
          Swal.fire({
            text: this.translationsLib.get('field_delete_field_success'),
            icon: 'success',
          }).then((res) => {
            window.location.href = '/';
          });
        });
      }
    });
  }

  editTerminal(vid, type) {
    this.router.navigate([this.terminalsLib.generateRoute(vid, type)]);
  }

  detachTerminal(tVid) {
    Swal.fire({
      text: this.translationsLib.get('field_detach_terminal_from_field'),
      confirmButtonText: this.translationsLib.get('accept'),
      cancelButtonText: this.translationsLib.get('cancel'),
      confirmButtonColor: '#224261',
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) {
        this.terminalService.detachTerminalfromField(tVid, this.vid).subscribe(
          (response) => {
            this.terminals = [];
            this.getTerminals();
          },
          (error) => {
            Swal.fire({
              text: this.translationsLib.get(
                'field_detach_terminal_from_field_error'
              ),
              confirmButtonText: this.translationsLib.get('accept'),
              showConfirmButton: true,
            });
          }
        );
      }
    });
  }
  getNonAttachedList() {
    this.terminalService.getNonAttached().subscribe((response) => {
      this.nonAttached = response;
      if (this.nonAttached.length === 0) {
        this.nonAttached = null;
      }
    });
  }
  searchPrePairingNonAttached(vid) {
    event.preventDefault();
    this.serialNumberRes = vid;
    const response = this.nonAttached.filter(
      (term) => term.vid === this.serialNumberRes
    );
    this.terminalFound = true;
    this.image_path = environment.backend + '/' + response[0].image_path;
    this.results.setValue({
      nombre_equipo: response[0].name,
      terminal: response[0].metadata.terminal,
    });
  }
  attach() {
    this.terminalService
      .postAttaching(this.serialNumberRes, this.vid)
      .subscribe((attach) => {
        this.addDevice = false;
        this.terminals = [];
        this.getTerminals();
      });
  }
  forceReload() {
    location.reload();
  }

  multicropsEvent(multicrops: MulticropDataModel[]) {
    this.multicrops = multicrops;
  }

  patchCoordinates(event) {
    this.editField.patchValue({coordinates: event,});
  }
}
