import { Component, Input, OnInit } from '@angular/core';
import { TerminalsLibService } from '../../../services/libraries/terminals-lib.service';
import { EquipmentsLibService } from '../../../services/libraries/equipments-lib.service';
import {
  TranslationsLibService,
  TerminalDataModel,
} from '@nutricontrol/app360-shared';
import { EquipmentGeneralTypes } from '../../../farming/farming.model';
import { DataloggerLibService } from '../../../farming/libraries/datalogger-lib.service';
import {
  IrrigationBandDataModel,
  ModuleDataModel,
} from '../../../modules/modules.model';
import { ModulesLibService } from '../../../services/libraries/modules-lib.service';
import { Et0LibService } from '../../../farming/libraries/et0-lib.service';
import {
  IrrigationEquipmentModel,
  MeteoIrrigationNew,
} from '../../../farming/irrigation/irrigation.model';
import {
  ClimateEquipmentModel,
  MeteoClimateNew,
} from '../../../farming/climate/climate.model';
import { ConfigurableEquipmentModel } from '../../../farming/configurable/configurable.model';
import {
  DataloggerEquipmentModel,
  DataloggerMeteoModel,
} from '../../../farming/datalogger/datalogger.model';

@Component({
  selector: 'app-main-dashboard-terminals-simple-meteo-pills',
  templateUrl: './main-dashboard-terminals-simple-meteo-pills.component.html',
  styleUrls: ['./main-dashboard-terminals-simple-meteo-pills.component.scss'],
})
export class MainDashboardTerminalsSimpleMeteoPillsComponent implements OnInit {
  @Input() irrigationConsultingModules: ModuleDataModel[] = [];
  @Input() terminals: TerminalDataModel[] = [];
  @Input() equipmentsData: (
    | IrrigationEquipmentModel
    | ClimateEquipmentModel
    | ConfigurableEquipmentModel
    | DataloggerEquipmentModel
  )[] = [];
  orderedByIndexEquipmentsPills: (
    | MeteoClimateNew
    | MeteoIrrigationNew
    | DataloggerMeteoModel
  )[][] = [];
  orderedByIndexEquipmentsLastMeasure: string[] = [];
  orderedByIndexIrrigationConsultingBand: IrrigationBandDataModel[] = [];
  orderedByIndexIrrigationConsultingTensiometer: any[] = []; // TODO: Type definition
  orderedByIndexStatus: (number | null)[] = [];
  pillsOrderDatalogger = [
    'SOIL_TENSION',
    'VWC',
    'SOIL_TEMP',
    'SOIL_CE',
    'TEMPERATURE',
    'HUMIDITY',
    'VPD',
    'ET0',
    'DWP',
    'WBT',
    'RADIATION',
    'WIND_VELOCITY',
    'PLUVIOMETER',
    'VOLTAGE',
    'SIGNAL',
  ];
  equipmentGeneralTypes = EquipmentGeneralTypes;
  constructor(
    private et0Lib: Et0LibService,
    private modulesLib: ModulesLibService,
    public terminalsLib: TerminalsLibService,
    public equipmentsLib: EquipmentsLibService,
    public translationsLib: TranslationsLibService,
    public dataloggerLib: DataloggerLibService
  ) {}

  ngOnInit() {
    this.terminals.forEach((terminal, index) => {
      this.processTerminal(terminal, index);
    });
  }

  private processTerminal(terminal: TerminalDataModel, index: number): void {
    const equipment = this.findEquipment(terminal);
    if (!equipment) {
      return;
    }

    if (this.isDatalogger(equipment)) {
      this.processDataloggerEquipment(equipment as DataloggerEquipmentModel);
    }

    this.orderedByIndexEquipmentsLastMeasure[index] = equipment.lastMeasure;

    this.processET0Data(terminal, equipment, index);

    this.orderedByIndexEquipmentsPills[index] = this.getPills(equipment);

    this.processIrrigationConsultingModule(terminal, index, equipment);
  }

  private findEquipment(
    terminal: TerminalDataModel
  ):
    | IrrigationEquipmentModel
    | ClimateEquipmentModel
    | ConfigurableEquipmentModel
    | DataloggerEquipmentModel
    | undefined {
    console.log(
      'this.equipmentsData',
      this.equipmentsData,
      Array.isArray(this.equipmentsData)
    );
    if (Array.isArray(this.equipmentsData)) {
      return this.equipmentsData.find((equipmentData) => {
        let serialNumber = 'error';
        if ('serialNumber' in equipmentData) {
          // Datalogger API return serialNumber as an integer
          serialNumber = String(equipmentData.serialNumber).toString();
        }
        return terminal.vid === serialNumber;
      });
    }
  }

  private isDatalogger(
    equipment:
      | IrrigationEquipmentModel
      | ClimateEquipmentModel
      | ConfigurableEquipmentModel
      | DataloggerEquipmentModel
  ): boolean {
    return (
      this.equipmentsLib.getTypeByEquipmentData(equipment) ===
      EquipmentGeneralTypes.datalogger
    );
  }

  private isIrrigation(
    equipment:
      | IrrigationEquipmentModel
      | ClimateEquipmentModel
      | ConfigurableEquipmentModel
      | DataloggerEquipmentModel
  ): boolean {
    return (
      this.equipmentsLib.getTypeByEquipmentData(equipment) ===
      EquipmentGeneralTypes.irrigation
    );
  }

  private processDataloggerEquipment(
    dataloggerEquipment: DataloggerEquipmentModel
  ): void {
    if (dataloggerEquipment.states.length <= 0) {
      return;
    }
    dataloggerEquipment.meteo.forEach((data) => {
      dataloggerEquipment.states.forEach((sensor) => {
        if (sensor.name === data.name && sensor.value === data.value) {
          if (sensor.model === 'WBT') {
            data.key = 'WBT';
          }
          if (sensor.model === 'DWP') {
            data.key = 'DWP';
          }
        }
      });
    });
  }

  private processET0Data(
    terminal: TerminalDataModel,
    equipment:
      | IrrigationEquipmentModel
      | ClimateEquipmentModel
      | ConfigurableEquipmentModel
      | DataloggerEquipmentModel,
    index: number
  ): void {
    this.et0Lib
      .getHistoricWeather(terminal)
      .then((historicWeatherResult) => {
        this.et0Lib
          .getET0(historicWeatherResult.calculateET0Request, equipment)
          .then((et0Result) => {
            if (et0Result.yesterday_ET0 !== 'N/A') {
              // Add first isolated of et0 webservice latency
              this.orderedByIndexEquipmentsPills[index] = [
                {
                  key: 'et0',
                  unit: 'mm',
                  value: Number(et0Result.yesterday_ET0),
                  origin: 'VIRTUAL',
                } as
                  | MeteoClimateNew
                  | MeteoIrrigationNew
                  | DataloggerMeteoModel,
              ].concat(this.orderedByIndexEquipmentsPills[index]);
            }
          });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  private getPills(
    equipment:
      | IrrigationEquipmentModel
      | ClimateEquipmentModel
      | ConfigurableEquipmentModel
      | DataloggerEquipmentModel
  ): (MeteoClimateNew | MeteoIrrigationNew | DataloggerMeteoModel)[] {
    let pills: (MeteoClimateNew | MeteoIrrigationNew | DataloggerMeteoModel)[] =
      equipment.meteo;

    if (this.isDatalogger(equipment)) {
      const dataloggerEquipment = equipment as DataloggerEquipmentModel;
      pills = this.dataloggerLib.statesToPills(
        pills,
        dataloggerEquipment.states
      );
      pills = this.dataloggerLib.orderPills(pills, this.pillsOrderDatalogger);
    }

    return pills;
  }

  private processIrrigationConsultingModule(
    terminal: TerminalDataModel,
    index: number,
    equipment:
      | IrrigationEquipmentModel
      | ClimateEquipmentModel
      | ConfigurableEquipmentModel
      | DataloggerEquipmentModel
  ): void {
    const irrigationConsultingModule = this.irrigationConsultingModules.find(
      (irrigationConsultingModule) => {
        return terminal.vid === irrigationConsultingModule.terminal_vid;
      }
    );
    if (!irrigationConsultingModule) {
      this.orderedByIndexStatus[index] = null;
      return;
    }
    this.orderedByIndexStatus[index] = irrigationConsultingModule.status;
    this.modulesLib
      .getModuleDataByModule(irrigationConsultingModule)
      .subscribe((moduleData) => {
        this.orderedByIndexIrrigationConsultingBand[index] =
          this.modulesLib.getIrrigationBandFromModuleData(moduleData);
      });

    if (this.isDatalogger(equipment)) {
      const dataloggerEquipment = equipment as DataloggerEquipmentModel;
      this.orderedByIndexIrrigationConsultingTensiometer[index] =
        this.dataloggerLib.getTensiometerForIrrigationConsulting(
          irrigationConsultingModule,
          dataloggerEquipment.states
        );
    }
  }

  protected getIrrigationEquipment(
    terminalVid: string
  ): IrrigationEquipmentModel {
    const irrigationEquipments = this.equipmentsData.filter(
      (equipment): equipment is IrrigationEquipmentModel => {
        return (
          (equipment as IrrigationEquipmentModel).serialNumber !== undefined
        );
      }
    );

    return irrigationEquipments.find(
      (equipment) => equipment.serialNumber === terminalVid
    );
  }

  protected getConfigurableEquipment(
    terminalVid: string
  ): ConfigurableEquipmentModel {
    const configurableEquipments = this.equipmentsData.filter(
      (equipment): equipment is ConfigurableEquipmentModel => {
        return (
          (equipment as ConfigurableEquipmentModel).serialNumber !== undefined
        );
      }
    );

    return configurableEquipments.find(
      (equipment) => equipment.serialNumber === terminalVid
    );
  }

  pillMagnitudeLabel(
    pill: MeteoClimateNew | MeteoIrrigationNew | DataloggerMeteoModel,
    terminal: TerminalDataModel
  ): string {
    if (pill.origin === 'SENSORCONNECTED') {
      return pill.name;
    }

    if (
      this.terminalsLib.getType(terminal.type) ===
      EquipmentGeneralTypes.datalogger
    ) {
      return this.translationsLib.get('DL_PILLS_' + pill.key);
    }

    return this.translationsLib.get(pill.key);
  }
  isConnected(terminal: TerminalDataModel): boolean {
    if (Array.isArray(this.equipmentsData)) {
      const equipment = this.equipmentsData.find(
        (equipment) => terminal.vid === equipment.serialNumber.toString()
      );
      return equipment ? equipment.connected : false;
    }
    return false;
  }
  isIrrigating(terminal: TerminalDataModel): boolean {
    if (Array.isArray(this.equipmentsData)) {
      const equipment = this.equipmentsData.find(
        (equipment) => terminal.vid === equipment.serialNumber.toString()
      );
      if (!equipment || !this.isIrrigation(equipment)) {
        return false;
      }

      const {
        states: { programs },
      } = equipment as IrrigationEquipmentModel;
      return programs.some((program) => program.state === 'IRRIGATING');
    }
    return false;
  }
}
