import { Component, Input, OnInit } from '@angular/core';
import { MeteoChartsModel } from '../meteo-charts.model';
import { WeatherPredictionDataModel } from '../../weather.model';
import theme from 'highcharts/themes/high-contrast-light';
import * as Highcharts from 'highcharts/highstock';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import HC_accessibility from 'highcharts/modules/accessibility';
import highchartsMore from 'highcharts/highcharts-more';
import highchartsWindbarb from 'highcharts/modules/windbarb';
import * as moment from 'moment-timezone';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { HighchartsLibService } from '../../../services/libraries/highcharts-lib.service';

theme(Highcharts);
Exporting(Highcharts);
ExportData(Highcharts);
HC_accessibility(Highcharts);
highchartsMore(Highcharts);
highchartsWindbarb(Highcharts);

@Component({
  selector: 'app-meteogram-large-mobile',
  templateUrl: './meteogram-large-mobile.component.html',
  styleUrls: ['./meteogram-large-mobile.component.scss'],
})
export class MeteogramLargeMobileComponent implements OnInit {
  chart;
  updateFlag = false;
  chartConstructor = 'chart';
  chartCallback;
  Highcharts = Highcharts;

  // Copy of MeteoChartsModel
  meteoChartMobile: any = JSON.parse(JSON.stringify(MeteoChartsModel));
  @Input() forecast: WeatherPredictionDataModel[];
  loaded = false;
  constructor(
    public translationsLib: TranslationsLibService,
    private highchartsLib: HighchartsLibService
  ) {}

  ngOnInit() {
    const xAxis = [];
    const temperatureSeries = [];
    const humiditySeries = [];
    const rainSeries = [];
    const windSeries = [];
    const solarRadSeries = [];
    const zone = this.forecast[0].prediction_time_zone;

    Highcharts.setOptions({
      time: {
        /**
         * Use moment-timezone.js to return the timezone offset for individual
         * timestamps, used in the X axis labels and the tooltip header.
         */
        getTimezoneOffset: (timestamp) => {
          return -moment.tz(timestamp, zone).utcOffset();
        },
      },
      lang: this.highchartsLib.getLangOptions(),
    });

    for (const hourlyForecast of this.forecast) {
      const seriesTime = new Date(hourlyForecast.prediction_date).getTime();

      xAxis.push(new Date(hourlyForecast.prediction_date).getTime());

      temperatureSeries.push([seriesTime, hourlyForecast.main_temp]);

      humiditySeries.push([seriesTime, hourlyForecast.main_humidity]);

      solarRadSeries.push([seriesTime, hourlyForecast.solar_rad]);

      rainSeries.push([seriesTime, Number(hourlyForecast.precip.toFixed(1))]);

      windSeries.push([seriesTime, hourlyForecast.wind_speed]);
    }

    this.meteoChartMobile.title.text = this.translationsLib.get(
      'meteo_detailed_pred'
    );

    this.meteoChartMobile.series[0].name =
      this.translationsLib.get('climate_temp') + ' (ºC)';
    this.meteoChartMobile.series[0].data = temperatureSeries;

    this.meteoChartMobile.series[1].name =
      this.translationsLib.get('climate_hum') + ' (%)';
    this.meteoChartMobile.series[1].data = humiditySeries;

    this.meteoChartMobile.series[2].name =
      this.translationsLib.get('climate_rad') + ' (W/m²)';
    this.meteoChartMobile.series[2].data = solarRadSeries;

    this.meteoChartMobile.series[3].name =
      this.translationsLib.get('climate_rain') + ' (mm)';
    this.meteoChartMobile.series[3].data = rainSeries;

    // Do not show windbarb, changed to transparent line
    this.meteoChartMobile.series[4].type = 'line';
    this.meteoChartMobile.series[4].color = 'transparent';
    this.meteoChartMobile.series[4].yOffset = 0;
    this.meteoChartMobile.series[4].name =
      this.translationsLib.get('climate_wind') + ' (Km/h)';
    this.meteoChartMobile.series[4].data = windSeries;
    this.meteoChartMobile.series[4].tooltip.pointFormat =
      '<span style="color: #544fc5">●</span> {series.name}: <b>{point.y}</b><br/>';
    this.meteoChartMobile.series[4].showInLegend = false;

    this.meteoChartMobile.series[5].name = this.translationsLib.get('ET0');

    this.meteoChartMobile.xAxis[0].offset = 0;
    this.meteoChartMobile.xAxis[0].data = xAxis;

    this.meteoChartMobile.yAxis[0].title.text =
      this.meteoChartMobile.yAxis[1].title.text =
      this.meteoChartMobile.yAxis[2].title.text =
        null;

    this.meteoChartMobile.yAxis[1].offset = 0;
    this.meteoChartMobile.yAxis[2].offset = 6;

    setTimeout(() => {
      this.updateFlag = true;
      this.loaded = true;
    }, 200);
  }

  loadEt0Serie(et0Forecast: any): void {
    if (this.meteoChartMobile) {
      const et0Series = [];

      for (let i = 0; i < et0Forecast.length; i++) {
        // Today and after 12
        if (i === 0 && new Date().getHours() >= 12) {
          et0Series.push([
            new Date().setHours(new Date().getHours() + 1, 0, 0, 0),
            et0Forecast[i].ET0,
          ]);
        } else {
          et0Series.push([
            new Date(et0Forecast[i].startDate).setHours(12, 0, 0, 0),
            et0Forecast[i].ET0,
          ]);
        }
      }
      this.meteoChartMobile.series[5].data = et0Series;

      this.meteoChartMobile.yAxis[4].offset = 6;

      // Creating a new instance ensures that the graph is updated.
      this.meteoChartMobile = { ...this.meteoChartMobile };
    }
  }
}
