<div [ngClass]="type"
     [style.height]="height"
     class="card pill p-2 m-0 {{ class }}">
  <div class="row">
    <div class="col text-uppercase lh-1 h-auto fw-bold fs-6 pill-label">
      <div class="d-inline">
        {{ magnitudeLabel }}
      </div>
      <div [ngClass]="{ 'float-end': virtual }" class="d-inline">
        <span *ngIf="virtual">
          {{ translationsLib.get('dashboard_meteo_virtual')}}
        </span>
        <a *ngIf="magnitudeTooltip"
           [matTooltip]="magnitudeTooltip"
           #tooltip="matTooltip"
           (click)="tooltip.toggle()"
           matTooltipPosition="above"
           class="pill-tooltip ms-3">
          <ion-icon name="information-circle-outline"></ion-icon>
        </a>
      </div>
    </div>
  </div>
  <div class="row h-100">
    <div class="col-auto d-flex align-items-center">
      <div>
        <span class="fs-2 magnitude-value">
          {{ value || '-'}}
        </span>
        <span class="text-muted fs-5 magnitude-unit">
          &nbsp;{{ magnitudeUnit }}
        </span>
      </div>
    </div>
    <div [ngClass]="{ 'd-none': hideIconOnMobile }" class="d-lg-block text-end col px-0 me-3">
      <span [innerHTML]="icon | sensorIcon"></span>
    </div>
  </div>
</div>
