<div class="mb-4 d-flex justify-content-center mt-5" *ngIf="isFetchingData">
  <div class="spinner-border text-warning" role="status">
    <span class="visually-hidden">{{
      this.translationsLib.get('irrigation_loading')
    }}</span>
  </div>
</div>

<ng-container
  *ngIf="
    mode === 'simple' &&
    layoutConfiguration &&
    !isFetchingData &&
    !isFertilizersWithoutData
  "
>
  <app-dirty-message
    [conditions]="!isFetchingData && layoutConfiguration"
    [dirty]="filteredForms[page - 1].value.dirty"
  ></app-dirty-message>

  <form [formGroup]="filteredForms[page - 1]" class="simple-form">
    <mat-tab-group class="tab-container mb-0">
      <mat-tab label="Abonos">
        <!-- Cabezal -->
        <div class="card mt-4">
          <div class="card-body custom-card-body">
            <div class="row">
              <div class="col-sm-5 col-md-5 order-1 order-md-1">
                <h6 class="fs-2">
                  {{ translationsLib.get('irrigation_choose_water_system') }}
                </h6>
                <p class="fs-5 fw-lighter">
                  {{
                    translationsLib.get('irrigation_choose_water_system_help')
                      | sanitizeText
                  }}
                </p>
              </div>
              <div class="col-sm-7 col-md-7 order-3 order-md-2 col-inputs">
                <div class="row first-row">
                  <div class="col-12 col-md-6">
                    <div class="input-group mb-2">
                      <select
                        formControlName="waterSystem"
                        (change)="
                          onWaterSystemChange($event); onAgitatorsChange($event)
                        "
                        class="form-select"
                      >
                        <option
                          *ngFor="let ws of waterSystemsResponse"
                          [value]="ws.waterSystem"
                        >
                          {{
                            translationsLib.get('irrigation_water_system') +
                              ' ' +
                              (ws.waterSystem + 1)
                          }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Abonos -->
        <div class="fertilizers-installer">
          <div
            class="card"
            *ngFor="
              let f of filteredFertilizers
                | paginate
                  : {
                      id: 'fertilizersSimple',
                      itemsPerPage: 1,
                      currentPage: page
                    }
            "
          >
            <div class="card-body custom-card-body">
              <div class="row">
                <div class="col-sm-5 col-md-5 order-1 order-md-1">
                  <h6 class="fs-2">
                    {{
                      translationsLib.get('irrigation_water_system') +
                        ' ' +
                        (f.waterSystem + 1) +
                        ' ' +
                        translationsLib.get('irrigation_fertilizer') +
                        ' ' +
                        (f.fertilizer + 1)
                    }}
                  </h6>
                  <p class="fs-5 fw-lighter">
                    {{
                      translationsLib.get(
                        'irrigation_water_system_fertilizer_help'
                      ) | sanitizeText
                    }}
                  </p>
                </div>
                <div class="col-sm-7 col-md-7 order-3 order-md-2 col-inputs">
                  <div class="row first-row">
                    <!-- Nombre -->
                    <div class="col-12 col-md-6">
                      <h6 class="fs-5">
                        {{ translationsLib.get('irrigation_name') }}
                      </h6>
                      <div class="input-group mb-2">
                        <input
                          formControlName="name"
                          type="text"
                          class="form-control"
                          placeholder="{{
                            translationsLib.get('irrigation_name')
                          }}"
                          (input)="setDirty(true)"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <!-- Bomba de abono -->
                    <div class="col-12 col-md-6">
                      <h6 class="fs-5">
                        {{ translationsLib.get('irrigation_fertilizer_pump') }}
                      </h6>
                      <div class="input-group mb-2">
                        <select
                          formControlName="injector"
                          class="form-select"
                          (change)="setDirty(true)"
                        >
                          <option [value]="255">
                            {{
                              translationsLib.get(
                                'irrigation_no_fertilizer_pump'
                              )
                            }}
                          </option>
                          <option
                            *ngFor="let pump of pumpsResponse"
                            [value]="pump.pump"
                          >
                            {{
                              translationsLib.get('irrigation_pump') +
                                ' ' +
                                (pump.pump + 1)
                            }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <!-- Mínima Inyección (centésimas) -->
                    <div class="col-12 col-md-6">
                      <h6 class="fs-5">
                        {{ translationsLib.get('irrigation_min_inject') }}
                      </h6>
                      <div class="input-group mb-2 number-input">
                        <button
                          class="minus minus-style"
                          (click)="inputNumberService.decrementValue($event)"
                        ></button>
                        <input
                          formControlName="minInjec"
                          type="number"
                          class="form-control"
                          placeholder="{{
                            translationsLib.get('irrigation_min_inject')
                          }}"
                          min="-9999"
                          max="9999"
                          (input)="setDirty(true)"
                        />
                        <button
                          class="plus border-plus plus-style"
                          (click)="inputNumberService.incrementValue($event)"
                        ></button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <!-- Caudal -->
                    <div class="col-12 col-md-6">
                      <h6 class="fs-5">
                        {{ translationsLib.get('irrigation_flow') }}
                      </h6>
                      <div class="input-group mb-2 number-input">
                        <button
                          class="minus minus-style"
                          (click)="inputNumberService.decrementValue($event)"
                        ></button>
                        <input
                          formControlName="flow"
                          type="number"
                          class="form-control"
                          placeholder="{{
                            translationsLib.get('irrigation_flow')
                          }}"
                          min="-9999"
                          max="9999"
                          (input)="setDirty(true)"
                        />
                        <button
                          class="plus border-plus plus-style"
                          (click)="inputNumberService.incrementValue($event)"
                        ></button>
                      </div>
                    </div>
                  </div>
                  <div class="row first-row">
                    <div class="col-12">
                      <h6 class="fs-5">
                        {{ translationsLib.get('irrigation_agitator') }}
                      </h6>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="input-group mb-2">
                        <select
                          formControlName="agitator"
                          class="form-select"
                          (change)="onAgitatorsChange($event); setDirty(true)"
                        >
                          <option
                            *ngFor="let ag of agitatorsResponse"
                            [value]="ag.agitator"
                          >
                            {{
                              translationsLib.get('irrigation_agitator') +
                                ' ' +
                                (ag.agitator + 1)
                            }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div formGroupName="agitatorObject" class="readonly-border">
                    <span class="text-muted">
                      {{
                        translationsLib.get(
                          'irrigation_installer_readonly_text'
                        )
                      }}
                    </span>
                    <div class="row">
                      <div class="col-12 col-md-6">
                        <h6 class="fs-4">
                          {{
                            translationsLib.get('irrigation_agitator') +
                              ' ' +
                              agitatorPlusQuantity(
                                filteredForms[page - 1].value.agitator,
                                1
                              )
                          }}
                        </h6>
                      </div>
                    </div>
                    <div class="row">
                      <!-- Tiempo en marcha -->
                      <div class="col-12 col-md-6">
                        <h6 class="fs-5">
                          {{
                            translationsLib.get('irrigation_agitator_time_on')
                          }}
                        </h6>
                        <div class="mb-2">
                          <ng-template #onAdvanceTimePicker>
                            <timepicker
                              appOnlyNumbers
                              [showMeridian]="false"
                              [showSeconds]="true"
                              [minuteStep]="1"
                              [secondsStep]="1"
                              [formControlName]="'TAgitON'"
                            ></timepicker>
                          </ng-template>
                          <input
                            appOnlyNumbers
                            [ngClass]="{
                              'ng-dirty': this.filteredForms[this.page - 1]
                                .get('agitatorObject')
                                .get('TAgitON').dirty
                            }"
                            class="form-control text-center readonly-input"
                            type="text"
                            [popover]="onAdvanceTimePicker"
                            [outsideClick]="true"
                            placement="bottom"
                            [value]="
                              filteredForms[page - 1].value.agitatorObject
                                .TAgitON | inputTime
                            "
                            (change)="
                              onChangeInputTimeWithKeyboard(
                                $event,
                                'agitatorObject.TAgitON'
                              )
                            "
                            [attr.disabled]="true"
                          />
                        </div>
                      </div>
                      <!-- Tiempo parado -->
                      <div class="col-12 col-md-6">
                        <h6 class="fs-5">
                          {{
                            translationsLib.get('irrigation_agitator_time_off')
                          }}
                        </h6>
                        <div class="mb-2">
                          <ng-template #offAdvanceTimePicker>
                            <timepicker
                              appOnlyNumbers
                              [showMeridian]="false"
                              [showSeconds]="true"
                              [minuteStep]="1"
                              [secondsStep]="1"
                              [formControlName]="'TAgitOFF'"
                            ></timepicker>
                          </ng-template>
                          <input
                            appOnlyNumbers
                            [ngClass]="{
                              'ng-dirty': this.filteredForms[this.page - 1]
                                .get('agitatorObject')
                                .get('TAgitOFF').dirty
                            }"
                            class="form-control text-center readonly-input"
                            type="text"
                            [popover]="offAdvanceTimePicker"
                            [outsideClick]="true"
                            placement="bottom"
                            [value]="
                              filteredForms[page - 1].value.agitatorObject
                                .TAgitOFF | inputTime
                            "
                            (change)="
                              onChangeInputTimeWithKeyboard(
                                $event,
                                'agitatorObject.TAgitOFF'
                              )
                            "
                            [attr.disabled]="true"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- Agitar en pre abonado -->
                    <div class="row justify-content-between align-items-center">
                      <div class="col-6">
                        <h6 class="fs-5">
                          {{
                            translationsLib.get(
                              'irrigation_agitator_pre_fertilizer'
                            )
                          }}
                        </h6>
                      </div>
                      <div class="col-6">
                        <div class="input-group">
                          <div class="form-check form-switch">
                            <input
                              formControlName="agitatorInPreFertilized"
                              class="form-check-input"
                              type="checkbox"
                              role="switch"
                              [attr.disabled]="true"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Agitar en abonado -->
                    <div class="row justify-content-between align-items-center">
                      <div class="col-6">
                        <h6 class="fs-5">
                          {{
                            translationsLib.get(
                              'irrigation_agitator_fertilizer'
                            )
                          }}
                        </h6>
                      </div>
                      <div class="col-6">
                        <div class="input-group">
                          <div class="form-check form-switch">
                            <input
                              formControlName="agitatorInFertilized"
                              class="form-check-input"
                              type="checkbox"
                              role="switch"
                              [attr.disabled]="true"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Agitar en post abonado -->
                    <div class="row justify-content-between align-items-center">
                      <div class="col-6">
                        <h6 class="fs-5">
                          {{
                            translationsLib.get(
                              'irrigation_agitator_post_fertilizer'
                            )
                          }}
                        </h6>
                      </div>
                      <div class="col-6">
                        <div class="input-group">
                          <div class="form-check form-switch">
                            <input
                              formControlName="agitatorInPostFertilized"
                              class="form-check-input"
                              type="checkbox"
                              role="switch"
                              [attr.disabled]="true"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <pagination-controls
              id="fertilizersSimple"
              class="mt-4 text-center"
              (pageChange)="onPageChange($event)"
              previousLabel="{{ translationsLib.get('previous') }}"
              nextLabel="{{ translationsLib.get('next') }}"
              [responsive]="true"
            ></pagination-controls>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Agitador">
        <!-- Agitadores -->
        <div class="card mt-4" *ngFor="let a of filteredAgitators">
          <div class="card-body custom-card-body">
            <div class="row">
              <div class="col-sm-5 col-md-5 order-1 order-md-1">
                <h6 class="fs-2">
                  {{
                    translationsLib.get('irrigation_agitator') +
                      ' ' +
                      agitatorPlusQuantity(
                        filteredForms[page - 1].value.agitator,
                        1
                      )
                  }}
                </h6>
                <p class="fs-5 fw-lighter">
                  {{
                    translationsLib.get('irrigation_agitator_help')
                      | sanitizeText
                  }}
                </p>
              </div>
              <div class="col-sm-7 col-md-7 order-3 order-md-2 col-inputs">
                <div formGroupName="agitatorObject">
                  <div class="row">
                    <!-- Tiempo en marcha -->
                    <div class="col-12 col-md-6">
                      <h6 class="fs-5">
                        {{ translationsLib.get('irrigation_agitator_time_on') }}
                      </h6>
                      <div class="mb-2">
                        <ng-template #onAdvanceTimePicker>
                          <timepicker
                            appOnlyNumbers
                            [showMeridian]="false"
                            [showSeconds]="true"
                            [minuteStep]="1"
                            [secondsStep]="1"
                            [formControlName]="'TAgitON'"
                            (ngModelChange)="
                              setAgitatorDirty(true);
                              this.agitatordirtyEventEmitter.emit(true)
                            "
                          ></timepicker>
                        </ng-template>
                        <input
                          appOnlyNumbers
                          [ngClass]="{
                            'ng-dirty': this.filteredForms[this.page - 1]
                              .get('agitatorObject')
                              .get('TAgitON').dirty
                          }"
                          class="form-control text-center"
                          type="text"
                          [popover]="onAdvanceTimePicker"
                          [outsideClick]="true"
                          placement="bottom"
                          [value]="
                            filteredForms[page - 1].value.agitatorObject.TAgitON
                              | inputTime
                          "
                          (change)="
                            onChangeInputTimeWithKeyboard(
                              $event,
                              'agitatorObject.TAgitON'
                            )
                          "
                        />
                      </div>
                    </div>
                    <!-- Tiempo parado -->
                    <div class="col-12 col-md-6">
                      <h6 class="fs-5">
                        {{
                          translationsLib.get('irrigation_agitator_time_off')
                        }}
                      </h6>
                      <div class="mb-2">
                        <ng-template #offAdvanceTimePicker>
                          <timepicker
                            appOnlyNumbers
                            [showMeridian]="false"
                            [showSeconds]="true"
                            [minuteStep]="1"
                            [secondsStep]="1"
                            [formControlName]="'TAgitOFF'"
                            (ngModelChange)="
                              setAgitatorDirty(true);
                              this.agitatordirtyEventEmitter.emit(true)
                            "
                          ></timepicker>
                        </ng-template>
                        <input
                          appOnlyNumbers
                          [ngClass]="{
                            'ng-dirty': this.filteredForms[this.page - 1]
                              .get('agitatorObject')
                              .get('TAgitOFF').dirty
                          }"
                          class="form-control text-center"
                          type="text"
                          [popover]="offAdvanceTimePicker"
                          [outsideClick]="true"
                          placement="bottom"
                          [value]="
                            filteredForms[page - 1].value.agitatorObject
                              .TAgitOFF | inputTime
                          "
                          (change)="
                            onChangeInputTimeWithKeyboard(
                              $event,
                              'agitatorObject.TAgitOFF'
                            )
                          "
                        />
                      </div>
                    </div>
                  </div>
                  <!-- Agitar en pre abonado -->
                  <div class="row justify-content-between align-items-center">
                    <div class="col-6">
                      <h6 class="fs-5">
                        {{
                          translationsLib.get(
                            'irrigation_agitator_pre_fertilizer'
                          )
                        }}
                      </h6>
                    </div>
                    <div class="col-6">
                      <div class="input-group">
                        <div class="form-check form-switch">
                          <input
                            formControlName="agitatorInPreFertilized"
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            (change)="
                              setAgitatorDirty(true);
                              this.agitatordirtyEventEmitter.emit(true)
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Agitar en abonado -->
                  <div class="row justify-content-between align-items-center">
                    <div class="col-6">
                      <h6 class="fs-5">
                        {{
                          translationsLib.get('irrigation_agitator_fertilizer')
                        }}
                      </h6>
                    </div>
                    <div class="col-6">
                      <div class="input-group">
                        <div class="form-check form-switch">
                          <input
                            formControlName="agitatorInFertilized"
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            (change)="
                              setAgitatorDirty(true);
                              this.agitatordirtyEventEmitter.emit(true)
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Agitar en post abonado -->
                  <div class="row justify-content-between align-items-center">
                    <div class="col-6">
                      <h6 class="fs-5">
                        {{
                          translationsLib.get(
                            'irrigation_agitator_post_fertilizer'
                          )
                        }}
                      </h6>
                    </div>
                    <div class="col-6">
                      <div class="input-group">
                        <div class="form-check form-switch">
                          <input
                            formControlName="agitatorInPostFertilized"
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            (change)="
                              setAgitatorDirty(true);
                              this.agitatordirtyEventEmitter.emit(true)
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-end">
              <div class="col-4">
                <button
                  class="btn btn-primary m-3 float-end"
                  (click)="submitAgitatorForm()"
                  [disabled]="!getAgitatorDirty()"
                  [ngClass]="{
                    'btn-danger': getAgitatorDirty()
                  }"
                >
                  <span
                    *ngIf="isAgitatorFormSubmitted"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  {{ translationsLib.get('irrigation_agitator_save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </form>
</ng-container>

<ng-container
  *ngIf="
    mode === 'advanced' &&
    layoutConfiguration &&
    !isFetchingData &&
    !isFertilizersWithoutData
  "
>
  <app-dirty-message
    [conditions]="!isFetchingData && layoutConfiguration"
    [dirty]="filteredForms[page - 1].value.dirty"
  ></app-dirty-message>
  <form [formGroup]="filteredForms[page - 1]" class="advanced-form">
    <mat-tab-group class="tab-container mb-0">
      <mat-tab label="Abonos">
        <!-- Cabezal -->
        <div class="card mt-4">
          <div class="card-body custom-card-body">
            <div class="row">
              <div class="col-md-12">
                <h6 class="fs-2 input-label">
                  {{ translationsLib.get('irrigation_water_system') }}
                </h6>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-md-8">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_choose_water_system') }}
                </h6>
              </div>
              <div class="col-md-4">
                <div class="input-group mb-2">
                  <select
                    formControlName="waterSystem"
                    (change)="
                      onWaterSystemChange($event); onAgitatorsChange($event)
                    "
                    class="form-select"
                  >
                    <option
                      *ngFor="let ws of waterSystemsResponse"
                      [value]="ws.waterSystem"
                    >
                      {{
                        translationsLib.get('irrigation_water_system') +
                          ' ' +
                          (ws.waterSystem + 1)
                      }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Abonos -->
        <div class="fertilizers-installer">
          <div
            class="card"
            *ngFor="
              let f of filteredFertilizers
                | paginate
                  : {
                      id: 'fertilizersAdvanced',
                      itemsPerPage: 1,
                      currentPage: page
                    }
            "
          >
            <div class="card-body custom-card-body">
              <div class="row">
                <div class="col-md-12">
                  <h6 class="fs-2 input-label">
                    {{
                      translationsLib.get('irrigation_water_system') +
                        ' ' +
                        (f.waterSystem + 1) +
                        ' ' +
                        translationsLib.get('irrigation_fertilizer') +
                        ' ' +
                        ' ' +
                        (f.fertilizer + 1)
                    }}
                  </h6>
                </div>
              </div>

              <!-- Nombre -->
              <div class="row align-items-center">
                <div class="col-md-8">
                  <h6 class="fs-5">
                    {{ translationsLib.get('irrigation_name') }}
                  </h6>
                </div>
                <div class="col-md-4">
                  <div class="input-group mb-2">
                    <input
                      formControlName="name"
                      type="text"
                      class="form-control"
                      placeholder="{{ translationsLib.get('irrigation_name') }}"
                      (input)="setDirty(true)"
                    />
                  </div>
                </div>
              </div>
              <!-- Bomba de abono -->
              <div class="row align-items-center">
                <!-- TODO: change to pump but the object doesn't have this key -->
                <div class="col-md-8">
                  <h6 class="fs-5">
                    {{ translationsLib.get('irrigation_fertilizer_pump') }}
                  </h6>
                </div>
                <div class="col-md-4">
                  <div class="input-group mb-2">
                    <select
                      formControlName="injector"
                      class="form-select"
                      (change)="setDirty(true)"
                    >
                      <option [value]="255">
                        {{
                          translationsLib.get('irrigation_no_fertilizer_pump')
                        }}
                      </option>
                      <option
                        *ngFor="let pump of pumpsResponse"
                        [value]="pump.pump"
                      >
                        {{
                          translationsLib.get('irrigation_pump') +
                            ' ' +
                            (pump.pump + 1)
                        }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <!-- Mínima Inyección (centésimas) -->
              <div class="row align-items-center">
                <div class="col-md-8">
                  <h6 class="fs-5">
                    {{ translationsLib.get('irrigation_min_inject') }}
                  </h6>
                </div>
                <div class="col-md-4">
                  <div class="input-group mb-2 number-input">
                    <button
                      class="minus minus-style"
                      (click)="inputNumberService.decrementValue($event)"
                    ></button>
                    <input
                      formControlName="minInjec"
                      type="number"
                      class="form-control"
                      placeholder="{{
                        translationsLib.get('irrigation_min_inject')
                      }}"
                      min="-9999"
                      max="9999"
                      (input)="setDirty(true)"
                    />
                    <button
                      class="plus border-plus plus-style"
                      (click)="inputNumberService.incrementValue($event)"
                    ></button>
                  </div>
                </div>
              </div>
              <!-- Caudal -->
              <div class="row align-items-center">
                <div class="col-md-8">
                  <h6 class="fs-5">
                    {{ translationsLib.get('irrigation_flow') }}
                  </h6>
                </div>
                <div class="col-md-4">
                  <div class="input-group mb-2 number-input">
                    <button
                      class="minus minus-style"
                      (click)="inputNumberService.decrementValue($event)"
                    ></button>
                    <input
                      formControlName="flow"
                      type="number"
                      class="form-control"
                      placeholder="{{ translationsLib.get('irrigation_flow') }}"
                      min="-9999"
                      max="9999"
                      (input)="setDirty(true)"
                    />
                    <button
                      class="plus border-plus plus-style"
                      (click)="inputNumberService.incrementValue($event)"
                    ></button>
                  </div>
                </div>
              </div>
              <!-- Agitator -->
              <div class="row align-items-center">
                <div class="col-md-8">
                  <h6 class="fs-5">
                    {{ translationsLib.get('irrigation_agitator') }}
                  </h6>
                </div>
                <div class="col-md-4">
                  <div class="input-group mb-2">
                    <select
                      formControlName="agitator"
                      class="form-select"
                      (change)="onAgitatorsChange($event); setDirty(true)"
                    >
                      <option
                        *ngFor="let ag of agitatorsResponse"
                        [value]="ag.agitator"
                      >
                        {{
                          translationsLib.get('irrigation_agitator') +
                            ' ' +
                            (ag.agitator + 1)
                        }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div formGroupName="agitatorObject" class="my-3 readonly-border">
                <span class="text-muted">
                  {{
                    translationsLib.get('irrigation_installer_readonly_text')
                  }}
                </span>
                <div class="row">
                  <div class="col-md-12">
                    <h6 class="fs-4 input-label">
                      {{
                        translationsLib.get('irrigation_agitator') +
                          ' ' +
                          agitatorPlusQuantity(
                            filteredForms[page - 1].value.agitator,
                            1
                          )
                      }}
                    </h6>
                  </div>
                </div>
                <div class="row">
                  <!-- Tiempo en marcha -->
                  <div class="col-sm-12 col-md-6 col-lg-6">
                    <div class="row justify-content-between align-items-center">
                      <div class="col-md-6">
                        <h6 class="fs-5">
                          {{
                            translationsLib.get('irrigation_agitator_time_on')
                          }}
                        </h6>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-2">
                          <ng-template #onAdvanceTimePicker>
                            <timepicker
                              appOnlyNumbers
                              [showMeridian]="false"
                              [showSeconds]="true"
                              [minuteStep]="1"
                              [secondsStep]="1"
                              [formControlName]="'TAgitON'"
                            ></timepicker>
                          </ng-template>
                          <input
                            appOnlyNumbers
                            [ngClass]="{
                              'ng-dirty': this.filteredForms[this.page - 1]
                                .get('agitatorObject')
                                .get('TAgitON').dirty
                            }"
                            class="form-control text-center readonly-input"
                            type="text"
                            [popover]="onAdvanceTimePicker"
                            [outsideClick]="true"
                            placement="bottom"
                            [value]="
                              filteredForms[page - 1].value.agitatorObject
                                .TAgitON | inputTime
                            "
                            [attr.disabled]="true"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Tiempo parado -->
                  <div class="col-sm-12 col-md-6 col-lg-6">
                    <div class="row justify-content-between align-items-center">
                      <div class="col-md-6">
                        <h6 class="fs-5">
                          {{
                            translationsLib.get('irrigation_agitator_time_off')
                          }}
                        </h6>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-2">
                          <ng-template #offAdvanceTimePicker>
                            <timepicker
                              appOnlyNumbers
                              [showMeridian]="false"
                              [showSeconds]="true"
                              [minuteStep]="1"
                              [secondsStep]="1"
                              [formControlName]="'TAgitOFF'"
                            ></timepicker>
                          </ng-template>
                          <input
                            appOnlyNumbers
                            [ngClass]="{
                              'ng-dirty': this.filteredForms[this.page - 1]
                                .get('agitatorObject')
                                .get('TAgitOFF').dirty
                            }"
                            class="form-control text-center readonly-input"
                            type="text"
                            [popover]="offAdvanceTimePicker"
                            [outsideClick]="true"
                            placement="bottom"
                            [value]="
                              filteredForms[page - 1].value.agitatorObject
                                .TAgitOFF | inputTime
                            "
                            (change)="
                              onChangeInputTimeWithKeyboard(
                                $event,
                                'agitatorObject.TAgitOFF'
                              )
                            "
                            [attr.disabled]="true"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <!-- Agitar en pre abonado -->
                  <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="row align-items-center justify-content-between">
                      <div class="col-6">
                        <h6 class="fs-5">
                          {{
                            translationsLib.get(
                              'irrigation_agitator_pre_fertilizer'
                            )
                          }}
                        </h6>
                      </div>
                      <div class="col-6">
                        <div class="input-group">
                          <div class="form-check form-switch">
                            <input
                              formControlName="agitatorInPreFertilized"
                              class="form-check-input"
                              type="checkbox"
                              role="switch"
                              [attr.disabled]="true"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Agitar en abonado -->
                  <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="row align-items-center justify-content-between">
                      <div class="col-6">
                        <h6 class="fs-5">
                          {{
                            translationsLib.get(
                              'irrigation_agitator_fertilizer'
                            )
                          }}
                        </h6>
                      </div>
                      <div class="col-6">
                        <div class="input-group">
                          <div class="form-check form-switch">
                            <input
                              formControlName="agitatorInFertilized"
                              class="form-check-input"
                              type="checkbox"
                              role="switch"
                              [attr.disabled]="true"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Agitar en post abonado -->
                  <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="row align-items-center justify-content-between">
                      <div class="col-6">
                        <h6 class="fs-5">
                          {{
                            translationsLib.get(
                              'irrigation_agitator_post_fertilizer'
                            )
                          }}
                        </h6>
                      </div>
                      <div class="col-6">
                        <div class="input-group">
                          <div class="form-check form-switch">
                            <input
                              formControlName="agitatorInPostFertilized"
                              class="form-check-input"
                              type="checkbox"
                              role="switch"
                              [attr.disabled]="true"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <pagination-controls
              id="fertilizersAdvanced"
              class="mt-4 text-center"
              (pageChange)="onPageChange($event)"
              previousLabel="{{ translationsLib.get('previous') }}"
              nextLabel="{{ translationsLib.get('next') }}"
              [responsive]="true"
            ></pagination-controls>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Agitador">
        <!-- Agitadores -->
        <div class="card mt-4">
          <div formGroupName="agitatorObject">
            <div class="card-body custom-card-body">
              <!-- Agitador -->
              <div class="row">
                <div class="col-md-12">
                  <h6 class="fs-2 input-label">
                    {{
                      translationsLib.get('irrigation_agitator') +
                        ' ' +
                        agitatorPlusQuantity(
                          filteredForms[page - 1].value.agitator,
                          1
                        )
                    }}
                  </h6>
                </div>
              </div>
              <div class="row">
                <!-- Tiempo en marcha -->
                <div class="col-sm-12 col-md-6 col-lg-6">
                  <div class="row justify-content-between align-items-center">
                    <div class="col-md-6">
                      <h6 class="fs-5">
                        {{ translationsLib.get('irrigation_agitator_time_on') }}
                      </h6>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-2">
                        <ng-template #onAdvanceTimePicker>
                          <timepicker
                            appOnlyNumbers
                            [showMeridian]="false"
                            [showSeconds]="true"
                            [minuteStep]="1"
                            [secondsStep]="1"
                            [formControlName]="'TAgitON'"
                            (ngModelChange)="
                              setAgitatorDirty(true);
                              this.agitatordirtyEventEmitter.emit(true)
                            "
                          ></timepicker>
                        </ng-template>
                        <input
                          appOnlyNumbers
                          [ngClass]="{
                            'ng-dirty': this.filteredForms[this.page - 1]
                              .get('agitatorObject')
                              .get('TAgitON').dirty
                          }"
                          class="form-control text-center"
                          type="text"
                          [popover]="onAdvanceTimePicker"
                          [outsideClick]="true"
                          placement="bottom"
                          [value]="
                            filteredForms[page - 1].value.agitatorObject.TAgitON
                              | inputTime
                          "
                          (change)="
                            onChangeInputTimeWithKeyboard(
                              $event,
                              'agitatorObject.TAgitON',
                              true
                            )
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Tiempo parado -->
                <div class="col-sm-12 col-md-6 col-lg-6">
                  <div class="row justify-content-between align-items-center">
                    <div class="col-md-6">
                      <h6 class="fs-5">
                        {{
                          translationsLib.get('irrigation_agitator_time_off')
                        }}
                      </h6>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-2">
                        <ng-template #offAdvanceTimePicker>
                          <timepicker
                            appOnlyNumbers
                            [showMeridian]="false"
                            [showSeconds]="true"
                            [minuteStep]="1"
                            [secondsStep]="1"
                            [formControlName]="'TAgitOFF'"
                            (ngModelChange)="
                              setAgitatorDirty(true);
                              this.agitatordirtyEventEmitter.emit(true)
                            "
                          ></timepicker>
                        </ng-template>
                        <input
                          appOnlyNumbers
                          [ngClass]="{
                            'ng-dirty': this.filteredForms[this.page - 1]
                              .get('agitatorObject')
                              .get('TAgitOFF').dirty
                          }"
                          class="form-control text-center"
                          type="text"
                          [popover]="offAdvanceTimePicker"
                          [outsideClick]="true"
                          placement="bottom"
                          [value]="
                            filteredForms[page - 1].value.agitatorObject
                              .TAgitOFF | inputTime
                          "
                          (change)="
                            onChangeInputTimeWithKeyboard(
                              $event,
                              'agitatorObject.TAgitOFF',
                              true
                            )
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <!-- Agitar en pre abonado -->
                <div class="col-sm-12 col-md-4 col-lg-4">
                  <div class="row align-items-center justify-content-between">
                    <div class="col-6">
                      <h6 class="fs-5">
                        {{
                          translationsLib.get(
                            'irrigation_agitator_pre_fertilizer'
                          )
                        }}
                      </h6>
                    </div>
                    <div class="col-6">
                      <div class="input-group">
                        <div class="form-check form-switch">
                          <input
                            formControlName="agitatorInPreFertilized"
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            (change)="
                              setAgitatorDirty(true);
                              this.agitatordirtyEventEmitter.emit(true)
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Agitar en abonado -->
                <div class="col-sm-12 col-md-4 col-lg-4">
                  <div class="row align-items-center justify-content-between">
                    <div class="col-6">
                      <h6 class="fs-5">
                        {{
                          translationsLib.get('irrigation_agitator_fertilizer')
                        }}
                      </h6>
                    </div>
                    <div class="col-6">
                      <div class="input-group">
                        <div class="form-check form-switch">
                          <input
                            formControlName="agitatorInFertilized"
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            (change)="
                              setAgitatorDirty(true);
                              this.agitatordirtyEventEmitter.emit(true)
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Agitar en post abonado -->
                <div class="col-sm-12 col-md-4 col-lg-4">
                  <div class="row align-items-center justify-content-between">
                    <div class="col-6">
                      <h6 class="fs-5">
                        {{
                          translationsLib.get(
                            'irrigation_agitator_post_fertilizer'
                          )
                        }}
                      </h6>
                    </div>
                    <div class="col-6">
                      <div class="input-group">
                        <div class="form-check form-switch">
                          <input
                            formControlName="agitatorInPostFertilized"
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            (change)="
                              setAgitatorDirty(true);
                              this.agitatordirtyEventEmitter.emit(true)
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              class="btn btn-primary m-3 float-end"
              (click)="submitAgitatorForm()"
              [disabled]="!getAgitatorDirty()"
              [ngClass]="{
                'btn-danger': getAgitatorDirty()
              }"
            >
              <span
                *ngIf="isAgitatorFormSubmitted"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Guardar Agitador
            </button>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </form>
</ng-container>
