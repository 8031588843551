import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { startOfDay, endOfDay, isSameDay, isSameMonth } from 'date-fns';
import { Subject } from 'rxjs';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import { EventColor } from 'calendar-utils';
import { ActivatedRoute, Router } from '@angular/router';
import { EventsCalendarService } from '../events-calendar.service';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { registerLocaleData } from '@angular/common';
import { SessionLibService } from '@nutricontrol/app360-shared';
import { TimeLibService } from '../../services/libraries/time-lib.service';
import { EventCalendarDataModel } from '../events-calendar.model';
import { collapseAnimation } from 'angular-calendar';

import * as moment from 'moment';

// @TO-DO: To be improved! EN is already imported
import localeEs from '@angular/common/locales/es';
registerLocaleData(localeEs);
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);
import localeDe from '@angular/common/locales/de';
registerLocaleData(localeDe);

const colors: Record<string, EventColor> = {
  red: {
    primary: '#777',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#66B9BF',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#F3BD55',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-events-calendar-widget',
  templateUrl: './events-calendar-widget.component.html',
  styleUrls: ['./events-calendar-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [collapseAnimation],
})
export class EventsCalendarWidgetComponent implements OnInit {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  isActiveNext = true;
  moment: any = moment;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];

  refresh = new Subject<void>();
  switching_days_warning = false;
  events: CalendarEvent[] = [];
  past_events: CalendarEvent[] = [];
  future_events: CalendarEvent[] = [];
  current_events: CalendarEvent[] = [];
  retrieved_months = [];
  events_counter_by_day = {};
  activeDayIsOpen = true;
  @Input() mode: string;
  @Input() terminal_vid: string;

  constructor(
    //private modal: NgbModal,
    private route: ActivatedRoute,
    private calendarService: EventsCalendarService,
    public sessionLib: SessionLibService,
    public translationsLib: TranslationsLibService,
    private router: Router,
    private timeLib: TimeLibService
  ) {}

  ngOnInit() {
    const d = moment(new Date());
    this.retrieved_months[d.format('YYYY-MM')] = true;
    this.getPastEvents(d.toDate(), true);
  }

  addEventToCounterByDay(timeBegin: string): number {
    const index = moment(timeBegin, 'YYYY-MM-DD HH:mm:ss').format('YYYY_MM_DD');
    if (!(index in this.events_counter_by_day)) {
      this.events_counter_by_day[index] = 0;
    } else {
      this.events_counter_by_day[index]++;
    }
    return this.events_counter_by_day[index];
  }

  getEventsCounterByDay(timeBegin: string): number {
    const index = moment(timeBegin, 'YYYY-MM-DD HH:mm:ss').format('YYYY_MM_DD');
    return this.events_counter_by_day[index];
  }

  getPastEvents(date: Date, add_future: boolean) {
    this.calendarService
      .getPastEvents(
        this.terminal_vid,
        this.timeLib.firstDayOfMonth(date),
        this.timeLib.lastDayOfMonth(date),
        null
      )
      .subscribe((res) => {
        if (res.irrigation_reports.length > 0) {
          const d = new Date();
          res.irrigation_reports[0].items.forEach((irrigation, index) => {
            this.addEventToCounterByDay(irrigation.timeBegin);
            const today = moment(
              irrigation.timeBegin,
              'DD/MM/YYYY HH:mm:ss'
            ).isSame(d, 'day');

            let isPast = true;
            let isPresent = false;
            let event_color = 'red';
            if (today === true) {
              event_color = 'yellow';
              isPast = false;
              isPresent = true;
            }

            const events_count_for_this_day = this.getEventsCounterByDay(
              irrigation.timeBegin
            ); // Add one event to this day

            const event = {
              id: index,
              start: moment(
                irrigation.timeBegin,
                'DD/MM/YYYY HH:mm:ss'
              ).toDate(),
              end: moment(irrigation.timeEnd, 'DD/MM/YYYY HH:mm:ss').toDate(),
              url:
                '/farming/' +
                this.terminal_vid +
                '/irrigation/program/' +
                irrigation.program,
              title:
                this.translationsLib.get('irrigation_program') +
                ' ' +
                irrigation.progName.trim() +
                ': ' +
                this.translationsLib.get('irrigation_program_activation') +
                ' ' +
                irrigation.numActivation,
              color: { ...colors[event_color] },
              actions: this.actions,
              resizable: {
                beforeStart: false,
                afterEnd: false,
              },
              draggable: false,
              badgeTotal: events_count_for_this_day,
              isPast,
              isPresent,
            };

            this.events.push(event);
            if (today === true) {
              this.current_events.push(event);
            } else {
              this.past_events.push(event);
            }
          });
        }

        this.refresh.next();

        if (add_future) {
          this.getFutureEvents();
        }
      });
  }

  getFutureEvents() {
    this.calendarService.getFutureEvents(this.terminal_vid).subscribe((res) => {
      const d = new Date();

      // Two full months
      for (let x = 0; x < 67; x++) {
        res.forEach((futureEvent) => {
          const proposedX = this.getProposedX(x, futureEvent);
          this.addEventToCounterByDay(
            proposedX.proposedBeginHourX.format('DD/MM/YYYY HH:mm:ss')
          );
        });
        res.forEach((futureEvent) => {
          if (futureEvent.week_days === 'DAYS') {
            // SWITCHING DAYS
            this.switching_days_warning = true;
          } else {
            // WEEK
            let to_be_added = false;
            const proposedX = this.getProposedX(x, futureEvent);

            const proposedBeginHourX = proposedX.proposedBeginHourX;
            const proposedEndHourX = proposedX.proposedEndHourX;
            const weekday = proposedX.weekday;

            if (
              (futureEvent.week.sunday === true && weekday === 0) ||
              (futureEvent.week.monday === true && weekday === 1) ||
              (futureEvent.week.tuesday === true && weekday === 2) ||
              (futureEvent.week.wednesday === true && weekday === 3) ||
              (futureEvent.week.thursday === true && weekday === 4) ||
              (futureEvent.week.friday === true && weekday === 5) ||
              (futureEvent.week.saturday === true && weekday === 6)
            ) {
              to_be_added = true;
            }

            // Avoid duplicate today-future events
            if (to_be_added) {
              if (proposedEndHourX.isSame(d, 'day')) {
                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for (let x = 0; x < this.current_events.length; x++) {
                  if (
                    parseInt(futureEvent.id, 10) === this.current_events[x].id
                  ) {
                    to_be_added = false;
                  }
                }
              }
            }

            if (to_be_added) {
              const events_count_for_this_day = this.getEventsCounterByDay(
                proposedBeginHourX.format('DD/MM/YYYY HH:mm:ss')
              ); // Get events count for this day

              const event = {
                id: futureEvent.id,
                start: proposedBeginHourX.toDate(),
                end: proposedEndHourX.toDate(),
                url: futureEvent.url,
                title: futureEvent.description,
                color: { ...colors.blue },
                actions: this.actions,
                resizable: {
                  beforeStart: false,
                  afterEnd: false,
                },
                draggable: false,
                badgeTotal: events_count_for_this_day,
                isFuture: true,
              };
              this.events.push(event);
              this.future_events.push(event);
            }
          }
        });
      }
      this.refresh.next();
    });
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        // Disable close day, no sense for this purpose!
        //this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    if (action === 'Clicked') {
      // @ts-ignore
      this.router.navigate([event.url]);
    }
    //this.modalData = { event, action };
    //this.modal.open(this.modalContent, { size: 'lg' });
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      },
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay(date: Date) {
    this.activeDayIsOpen = false;
    if (moment(date).month() - moment(new Date()).month() >= 1) {
      this.isActiveNext = false;
    } else {
      this.isActiveNext = true;
    }
    const d = moment(date);
    if (!(d.format('YYYY-MM') in this.retrieved_months)) {
      this.getPastEvents(date, false);
      this.retrieved_months[d.format('YYYY-MM')] = true;
    }
  }

  getProposedX(x: number, futureEvent: EventCalendarDataModel) {
    const beginHour_arr = futureEvent.beginHour.split(':');
    const endHour_arr = futureEvent.endHour.split(':');
    const proposedBeginHour = moment().set({
      hour: parseInt(beginHour_arr[0], 10),
      minute: parseInt(beginHour_arr[1], 10),
      second: parseInt(beginHour_arr[2], 10),
    });
    const proposedEndHour = moment().set({
      hour: parseInt(endHour_arr[0], 10),
      minute: parseInt(endHour_arr[1], 10),
      second: parseInt(endHour_arr[2], 10),
    });
    const proposedBeginHourX = proposedBeginHour.add(x, 'days');
    const proposedEndHourX = proposedEndHour.add(x, 'days');
    const weekday = proposedBeginHourX.weekday();

    return {
      proposedBeginHour,
      proposedEndHour,
      proposedBeginHourX,
      proposedEndHourX,
      weekday,
    };
  }
}
