import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { IonModal } from '@ionic/angular';
import { CropsDataModel, MulticropDataModel } from '../../fields.model';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { AppCommonsService } from '../../../commons/app-commons.service';

@Component({
  selector: 'app-multicrop',
  templateUrl: './multicrop.component.html',
  styleUrls: ['./multicrop.component.scss'],
})
export class MulticropComponent implements OnInit {
  @ViewChild(IonModal) modal: IonModal;

  @Input()
  multicrops: MulticropDataModel[] = [];

  @Output()
  updateMulticrops = new EventEmitter<MulticropDataModel[]>();

  crops: CropsDataModel['items'];
  selectedMulticrop: MulticropDataModel | null;
  selectedMulticropIndex: number | null;

  constructor(
    public translationsLib: TranslationsLibService,
    private commonService: AppCommonsService
  ) {}

  ngOnInit(): void {
    this.getCropsList();
  }

  getCropsList() {
    this.commonService.getCrops().subscribe((response) => {
      this.crops = response;
    });
  }

  getCropNameById(id: number) {
    if (this.crops) {
      return this.crops.find((crop) => crop.id === id)?.name;
    }
  }

  newMulticrop() {
    this.selectedMulticropIndex = null;
    this.selectedMulticrop = null;
    this.modal.present();
  }

  editMulticrop(multicrop: number) {
    this.selectedMulticropIndex = multicrop;
    this.selectedMulticrop = this.multicrops[multicrop];
    this.modal.present();
  }

  removeMulticrop(multicrop: number) {
    this.multicrops.splice(multicrop, 1);
    this.updateMulticrops.emit(this.multicrops);
  }

  multicropEvent(multicrop: MulticropDataModel) {
    if (this.selectedMulticrop === null) this.multicrops.push(multicrop);
    else this.multicrops[this.selectedMulticropIndex] = multicrop;
    this.selectedMulticropIndex = null;
    this.selectedMulticrop = null;
    this.modal.dismiss();
    this.updateMulticrops.emit(this.multicrops);
  }
}
