<h1 mat-dialog-title class="text-uppercase">Copiar Programación</h1>
<mat-dialog-content>
  <!-- VENTILATION -->
  <div class="mb-4" *ngIf="routeType === 'ventilation'">
    <select
      class="form-select"
      [ngModel]="selectedOptionToCopy"
      (change)="onChangeOption($event)"
    >
      <option value="">
        {{ translationsLib.get('climate_modal_copy_message') }}
      </option>
      <option value="ventilation_temperature">
        {{
          translationsLib.get(
            'climate_ventilation_ventilation_temperature_title'
          )
        }}
      </option>
      <option value="vents">
        {{ translationsLib.get('climate_ventilation_vents_title') }}
      </option>
      <option value="fan_steps">
        {{ translationsLib.get('climate_ventilation_fan_steps_title') }}
      </option>
      <option value="thermal_inversion">
        {{ translationsLib.get('climate_ventilation_thermal_inversion_title') }}
      </option>
    </select>
  </div>

  <!-- HEATING -->
  <div class="mb-4" *ngIf="routeType === 'heating'">
    <select
      class="form-select"
      [ngModel]="selectedOptionToCopy"
      (change)="onChangeOption($event)"
    >
      <option value="">
        {{ translationsLib.get('climate_modal_copy_message') }}
      </option>
      <option value="heating_temperature">
        {{ translationsLib.get('climate_heating_heating_temperature_title') }}
      </option>
      <option value="heaters">
        {{ translationsLib.get('climate_heating_heaters_title') }}
      </option>
      <option value="pipes">
        {{ translationsLib.get('climate_heating_pipes_title') }}
      </option>
    </select>
  </div>

  <!-- CURTAINS -->
  <div class="mb-4" *ngIf="routeType === 'curtains'">
    <select
      class="form-select"
      [ngModel]="selectedOptionToCopy"
      (change)="onChangeOption($event)"
    >
      <option value="">
        {{ translationsLib.get('climate_modal_copy_message') }}
      </option>
      <option value="energy">
        {{ translationsLib.get('climate_curtains_mixed_curtains_title') }}
      </option>
      <option value="darkening">
        {{ translationsLib.get('climate_curtains_darkening_curtains_title') }}
      </option>
      <option value="shading">
        {{ translationsLib.get('climate_curtains_shading_curtains_title') }}
      </option>
    </select>
  </div>

  <!-- MISTING -->
  <div class="mb-4" *ngIf="routeType === 'misting'">
    <select
      class="form-select"
      [ngModel]="selectedOptionToCopy"
      (change)="onChangeOption($event)"
    >
      <option value="">
        {{ translationsLib.get('climate_modal_copy_message') }}
      </option>
      <option value="misting">
        {{ translationsLib.get('climate_misting_title') }}
      </option>
    </select>
  </div>

  <!-- HAF FANS -->
  <div class="mb-4" *ngIf="routeType === 'haf_fans'">
    <select
      class="form-select"
      [ngModel]="selectedOptionToCopy"
      (change)="onChangeOption($event)"
    >
      <option value="">
        {{ translationsLib.get('climate_modal_copy_message') }}
      </option>
      <option value="haf_fans">
        {{ translationsLib.get('climate_haf_fans_title') }}
      </option>
    </select>
  </div>

  <!-- ALARMS -->
  <div class="mb-4" *ngIf="routeType === 'alarms'">
    <select
      class="form-select"
      [ngModel]="selectedOptionToCopy"
      (change)="onChangeOption($event)"
    >
      <option value="">
        {{ translationsLib.get('climate_modal_copy_message') }}
      </option>
      <option value="alarms">
        {{ translationsLib.get('climate_alarms_title') }}
      </option>
    </select>
  </div>

  <!-- SWITCHING PROGRAMS -->
  <div class="mb-4" *ngIf="routeType === 'switching_programs'">
    <select
      class="form-select"
      [ngModel]="selectedOptionToCopy"
      (change)="onChangeOption($event)"
    >
      <option value="">
        {{ translationsLib.get('climate_modal_copy_message') }}
      </option>
      <option value="switching_programs">
        {{ translationsLib.get('climate_switching_programs_title') }}
      </option>
    </select>
  </div>

  <!-- CO2 -->
  <div class="mb-4" *ngIf="routeType === 'co2'">
    <select
      class="form-select"
      [ngModel]="selectedOptionToCopy"
      (change)="onChangeOption($event)"
    >
      <option value="">
        {{ translationsLib.get('climate_modal_copy_message') }}
      </option>
      <option value="co2">{{ translationsLib.get('climate_CO2_text') }}</option>
    </select>
  </div>

  <!-- VENTS -->
  <div *ngIf="selectedOptionToCopy === 'vents'">
    <div class="form-check" *ngFor="let zone of zones; let i = index">
      <input
        class="form-check-input"
        type="checkbox"
        [(ngModel)]="selectedCheckboxes[i]"
        id="flexCheckDefault{{ i }}"
        (change)="toggleAllChildCheckboxesInVentilationTemperatureVents(i)"
      />
      <label class="form-check-label" for="flexCheckDefault{{ i }}">
        {{ translationsLib.get('climate_zone_title') }} {{ i + 1 }}
        <button
          type="button"
          class="showMore-btn"
          (click)="showMore[i] = !showMore[i]"
        >
          {{
            showMore[i]
              ? translationsLib.get('climate_show_less')
              : translationsLib.get('climate_show_more')
          }}
        </button>
      </label>
      <div *ngIf="showMore[i]">
        <div
          class="form-check"
          *ngFor="let vent of this.vents.zones[0].vents; let j = index"
        >
          <input
            class="form-check-input"
            type="checkbox"
            [(ngModel)]="ventsVentilationTemperatureSelectedCheckboxes[i][j]"
            id="flexCheckDefault{{ i }}"
            [disabled]="
              this.currentZone === i && this.currentVentilationVent === j
            "
            (ngModelChange)="
              updateCheckboxStatusVentilationTemperatureForVents(i)
            "
          />
          <label class="form-check-label" for="flexCheckDefault{{ i }}">
            {{ translationsLib.get('climate_vent') }} {{ j + 1 }}
          </label>
        </div>
      </div>
    </div>
  </div>

  <!-- FAN STEPS -->
  <div *ngIf="selectedOptionToCopy === 'fan_steps'">
    <div class="form-check" *ngFor="let zone of zones; let i = index">
      <input
        class="form-check-input"
        type="checkbox"
        [(ngModel)]="selectedCheckboxes[i]"
        id="flexCheckDefault{{ i }}"
        (change)="toggleAllChildCheckboxesInVentilationTemperatureFanSteps(i)"
      />
      <label class="form-check-label" for="flexCheckDefault{{ i }}">
        {{ translationsLib.get('climate_zone_title') }} {{ i + 1 }}
        <button
          type="button"
          class="showMore-btn"
          (click)="showMore[i] = !showMore[i]"
        >
          {{
            showMore[i]
              ? translationsLib.get('climate_show_less')
              : translationsLib.get('climate_show_more')
          }}
        </button>
      </label>
      <div *ngIf="showMore[i]">
        <div
          class="form-check"
          *ngFor="
            let step of this.fanStepsVentilationTemperature.zones[0].steps;
            let j = index
          "
        >
          <input
            class="form-check-input"
            type="checkbox"
            [(ngModel)]="fanStepsVentilationTemperatureSelectedCheckboxes[i][j]"
            id="flexCheckDefault{{ i }}"
            [disabled]="
              this.currentZone === i && this.currentVentilationFanStep === j
            "
            (ngModelChange)="
              updateCheckboxStatusVentilationTemperatureForFanSteps(i)
            "
          />
          <label class="form-check-label" for="flexCheckDefault{{ i }}">
            {{ translationsLib.get('climate_step') }} {{ j + 1 }}
          </label>
        </div>
      </div>
    </div>
  </div>

  <!-- HEATERS -->
  <div *ngIf="selectedOptionToCopy === 'heaters'">
    <div class="form-check" *ngFor="let zone of zones; let i = index">
      <input
        class="form-check-input"
        type="checkbox"
        [(ngModel)]="selectedCheckboxes[i]"
        id="flexCheckDefault{{ i }}"
        (change)="toggleAllChildCheckboxesInHeatingFanSteps(i)"
      />
      <label class="form-check-label" for="flexCheckDefault{{ i }}">
        {{ translationsLib.get('climate_zone_title') }} {{ i + 1 }}
        <button
          type="button"
          class="showMore-btn"
          (click)="showMore[i] = !showMore[i]"
        >
          {{
            showMore[i]
              ? translationsLib.get('climate_show_less')
              : translationsLib.get('climate_show_more')
          }}
        </button>
      </label>
      <div *ngIf="showMore[i]">
        <div
          class="form-check"
          *ngFor="let step of this.heaters.zones[0].steps; let j = index"
        >
          <input
            class="form-check-input"
            type="checkbox"
            [(ngModel)]="fanStepsHeatingSelectedCheckboxes[i][j]"
            id="flexCheckDefault{{ i }}"
            [disabled]="
              this.currentZone === i && this.currentHeatingFanStep === j
            "
            (ngModelChange)="updateCheckboxStatusHeatingForFanSteps(i)"
          />
          <label class="form-check-label" for="flexCheckDefault{{ i }}">
            {{ translationsLib.get('climate_step') }} {{ j + 1 }}
          </label>
        </div>
      </div>
    </div>
  </div>

  <!-- DEFAULT OPTION -->
  <ng-template
    [ngIf]="
      selectedOptionToCopy !== '' &&
      selectedOptionToCopy !== 'vents' &&
      selectedOptionToCopy !== 'fan_steps' &&
      selectedOptionToCopy !== 'heaters'
    "
  >
    <div class="form-check" *ngFor="let zone of zones; let i = index">
      <input
        class="form-check-input"
        type="checkbox"
        [(ngModel)]="selectedCheckboxes[i]"
        id="flexCheckDefault{{ i }}"
        [disabled]="this.currentZone === i"
      />
      <label class="form-check-label" for="flexCheckDefault{{ i }}">
        {{ translationsLib.get('climate_zone_title') }} {{ i + 1 }}
      </label>
    </div>
  </ng-template>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close color="warn">
    {{ translationsLib.get('cancel') }}
  </button>
  <button
    mat-button
    [mat-dialog-close]="{
      checkboxes: selectedCheckboxes,
      selectedOptionToCopy: selectedOptionToCopy,
      ventsVentilationTemperatureSelectedCheckboxes:
        ventsVentilationTemperatureSelectedCheckboxes,
      fanStepsVentilationTemperatureSelectedCheckboxes:
        fanStepsVentilationTemperatureSelectedCheckboxes,
      fanStepsHeatingSelectedCheckboxes: fanStepsHeatingSelectedCheckboxes
    }"
    cdkFocusInitial
    color="primary"
  >
    {{ translationsLib.get('climate_copy_save') }}
  </button>
</mat-dialog-actions>
