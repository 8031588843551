import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { ConfirmLeaveComponent } from '../../farming/irrigation/shared/confirm-leave/confirm-leave.component';
import { MatDialog } from '@angular/material/dialog';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SafeData } from '../../farming/irrigation/irrigation.model';

@Injectable({
  providedIn: 'root',
})
export class UnsavedFormChanges implements CanDeactivate<SafeData> {
  constructor(private dialog: MatDialog) {}
  canDeactivate(component: SafeData) {
    if (component.isDataSaved() && component.hasWritePermissions()) {
      const dialogRef = this.dialog.open(ConfirmLeaveComponent);

      return dialogRef.afterClosed().pipe(
        map((result) => {
          if (result === true) {
            return true; // User select 'Aceptar'
          } else {
            return false; // User select 'Cancelar'
          }
        })
      );
    }

    return of(true);
  }
}
