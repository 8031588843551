<div class="water-systems-field-chips card h-100 py-2 px-3">
  <div class="text-nowrap text-uppercase fs-6 fw-bold"
       style="color: var(--primary-color)">
    {{ field.label }}
  </div>
  <div [class]="isMobile ? 'fs-6' : 'fs-5'" class="fw-bold">
    <mat-chip-list>
      <mat-chip *ngFor="let programName of field.values">
        <span class="fs-6">
          {{ programName }}
        </span>
      </mat-chip>
    </mat-chip-list>
  </div>
</div>
