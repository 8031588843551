<div class="consumption-charts-container card p-3">
  <aside class="visualization-mode" *ngIf="selectorModeIsAllowed === true">
    <a [matMenuTriggerFor]="menu">
      <span>{{ translationsLib.get('reports_chart_tools') }}</span>
      <button mat-icon-button>
        <ion-icon name="ellipsis-vertical-outline"></ion-icon>
      </button>
    </a>
    <mat-menu #menu="matMenu">
      <button mat-menu-item *ngIf="chartMode !== 'bars'" (click)="changeMode(true, 'bars')">
        <ion-icon name="bar-chart-outline"></ion-icon>
        <b>
          {{ translationsLib.get('reports_change_to_bars_visualization') }}
        </b>
      </button>
      <button mat-menu-item *ngIf="chartMode !== 'sunburst' || basicMode === false" (click)="changeMode(true, 'sunburst')">
        <ion-icon name="color-filter-outline"></ion-icon>
        <b>
          {{ translationsLib.get('reports_change_to_basic_visualization_rings') }}
        </b>
      </button>
      <button mat-menu-item *ngIf="basicMode === true" (click)="changeMode(false, 'sunburst')">
        <ion-icon name="color-filter-outline"></ion-icon>
        <b>
          {{ translationsLib.get('reports_change_to_advanced_visualization_rings') }}
        </b>
      </button>
    </mat-menu>
  </aside>
  <div class="row pt-5">
    <div class="col-12" *ngIf="basicMode === false && advancedModeIsAllowed === false">
      <div class="alert alert-warning alert-dismissible fade show" role="alert">
        <strong>{{ this.translationsLib.get('reports_consumptions_advanced_mode_disabled') }}</strong>
      </div>
    </div>
    <!-- BARS -->
    <div class="col-12 col-lg-6 text-center" *ngIf="chartMode === 'bars'">
      <picture *ngIf="basicMode === true">
        <highcharts-chart
          [Highcharts]="Highcharts"
          [constructorType]="chartConstructor"
          [options]="basicIrrigationConsumptionsReportBars"
          [(update)]="updateFlag"
          [callbackFunction]="chartCallback"
          [oneToOne]="true"
          style="width: 100%;display: block;"
        >
        </highcharts-chart>
      </picture>
    </div>
    <div class="col-12 col-lg-6 text-center" *ngIf="chartMode === 'bars'">
      <picture *ngIf="basicMode === true">
        <highcharts-chart
          [Highcharts]="Highcharts"
          [constructorType]="chartConstructor"
          [options]="basicFertilizerConsumptionsReportBars"
          [(update)]="updateFlag"
          [callbackFunction]="chartCallback"
          [oneToOne]="true"
          style="width: 100%;display: block;"
        >
        </highcharts-chart>
      </picture>
    </div>
    <!-- SUNBURST WATER CONSUMPTION -->
    <div [ngClass]="{'col-lg-12': totalFertValue === 0, 'col-lg-6': totalFertValue !== 0}" class="col-12 text-center" *ngIf="chartMode === 'sunburst' && totalIrrigValue !== 0">
      <ng-container *ngIf="totalIrrigValue !== 0">
        <picture *ngIf="basicMode === true">
          <highcharts-chart
            [Highcharts]="Highcharts"
            [constructorType]="chartConstructor"
            [options]="basicIrrigationConsumptionsReportChart"
            [(update)]="updateFlag"
            [callbackFunction]="chartCallback"
            [oneToOne]="true"
            style="width: 100%;display: block;"
          >
          </highcharts-chart>
        </picture>
        <picture *ngIf="basicMode === false">
          <highcharts-chart
            *ngIf="advancedModeIsAllowed === true"
            [Highcharts]="Highcharts"
            [constructorType]="chartConstructor"
            [options]="irrigationConsumptionsReportChart"
            [(update)]="updateFlag"
            [callbackFunction]="chartCallback"
            [oneToOne]="true"
            style="width: 100%;display: block;"
          >
          </highcharts-chart>
        </picture>
      </ng-container>
    </div>
    <!-- SUNBURST FERTILIZER CONSUMPTION -->
    <div [ngClass]="{'col-lg-12': totalIrrigValue === 0, 'col-lg-6': totalIrrigValue !== 0}" class="col-12 text-center" *ngIf="chartMode === 'sunburst' && totalFertValue !== 0">
      <picture *ngIf="basicMode === true">
        <highcharts-chart
          [Highcharts]="Highcharts"
          [constructorType]="chartConstructor"
          [options]="basicFertilizerConsumptionsReportChart"
          [(update)]="updateFlag"
          [callbackFunction]="chartCallback"
          [oneToOne]="true"
          style="width: 100%;display: block;"
        >
        </highcharts-chart>
      </picture>
      <picture *ngIf="basicMode === false">
        <highcharts-chart
          *ngIf="advancedModeIsAllowed === true"
          [Highcharts]="Highcharts"
          [constructorType]="chartConstructor"
          [options]="fertilizerConsumptionsReportChart"
          [(update)]="updateFlag"
          [callbackFunction]="chartCallback"
          [oneToOne]="true"
          style="width: 100%;display: block;"
        >
        </highcharts-chart>
      </picture>
    </div>
  </div>
</div>
