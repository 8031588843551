<app-main-header [breadcrumbs]="breadcrumbs"></app-main-header>

<ion-content>
  <ng-container>
    <div class="container-fluid custom-container-fluid my-2">
      <div *ngIf="!isFetchingData && !isPumpsWithoutData">
        <div class="mb-2">
          <div class="row">
            <app-generic-header
              *ngIf="pumps && pump"
              [elements]="pumps"
              [text]="'name'"
              [selected]="pump"
              [placeholder]="translationsLib.get('irrigation_pump_change')"
              [elementSearchId]="'pump'"
              [label]="translationsLib.get('irrigation_pump')"
              (changeSelected)="onChangePagination($event)"
            >
              <div irrigation>
                <div class="row justify-content-end p-1 p-md-0">
                  <div class="col-auto ps-0 dots">
                    <app-mode
                      *ngIf="!isPumpsWithoutData"
                      (changeEventEmitter)="mode = $event"
                    ></app-mode>
                  </div>
                </div>
              </div>
            </app-generic-header>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container>
    <div class="container-fluid custom-container-fluid">
      <app-pumps-form
        [mode]="mode"
        [terminal_vid]="terminal_vid"
        [pump_id]="pump_id"
        (dirtyEventEmitter)="isDirty = $event"
        (pumpEventEmitter)="pump = $event"
        #pumps_form
      ></app-pumps-form>
    </div>
  </ng-container>
</ion-content>

<footer
  *ngIf="!isPumpsWithoutData && !isFetchingEquipmentData"
  class="footer mt-auto px-4 py-3 bg-light-soft border-top-3 border-light border global-irrigation-save-button"
>
  <div
    *ngIf="!terminalData?.authed_user_can_write && saveButtonTimeElapsed"
    class="row"
  >
    <div class="col">
      <app-cannot-write-alert></app-cannot-write-alert>
    </div>
  </div>
  <div class="d-flex justify-content-end" *ngIf="!saveButtonTimeElapsed">
    <div class="spinner-border text-warning" role="status">
      <span class="visually-hidden">{{
        translationsLib.get('climate_loading')
      }}</span>
    </div>
  </div>
  <div
    *ngIf="terminalData?.authed_user_can_write && saveButtonTimeElapsed"
    class="row"
  >
    <div
      class="col irrigation-save-button"
      [ngClass]="{ 'p-0': !hasScrollbar }"
    >
      <button
        (click)="pumps_form.submitForm()"
        [disabled]="
          !pumps_form.pumpsForm.value.isDirty && !pumps_form.pumpsForm.dirty
        "
        class="btn btn-primary float-end"
        [ngClass]="{
          'btn-danger':
            pumps_form.pumpsForm.value.isDirty || pumps_form.pumpsForm.dirty
        }"
      >
        <span
          *ngIf="pumps_form.isFormSubmitted"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        {{
          pumps_form.isFormSubmitted
            ? this.translationsLib.get('saving')
            : translationsLib.get('irrigation_save_pump')
        }}
      </button>
    </div>
  </div>
</footer>
