import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PermissionsService } from '../../../permissions/permissions.service';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-demo-alert',
  templateUrl: './demo-alert.component.html',
  styleUrls: ['./demo-alert.component.scss'],
})
export class DemoAlertComponent implements OnInit {
  constructor(
    private permissionsService: PermissionsService,
    private router: Router,
    public translationsLib: TranslationsLibService
  ) {}

  ngOnInit(): void {}

  detachDemoField() {
    Swal.fire({
      icon: 'warning',
      title: this.translationsLib.get('field_modal_title_dettach_demo'),
      text: this.translationsLib.get('field_modal_text_dettach_demo'),
      confirmButtonColor: '#224261',
      confirmButtonText: this.translationsLib.get('field_dettach_demo_field'),
      showCancelButton: true,
      cancelButtonText: this.translationsLib.get('no_thanks'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.permissionsService
          .deletePairingDetachFromDemo()
          .subscribe((result) => {
            Swal.fire({
              icon: 'success',
              title: this.translationsLib.get(
                'field_modal_title_demo_dettached'
              ),
              html: this.translationsLib.get('field_modal_text_demo_dettached'),
            }).then((result) => {
              this.router
                .navigate(['/fields-general'])
                .then((result) => window.location.reload());
            });
          });
      }
    });
  }
}
