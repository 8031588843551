<mat-tab-group class="mb-4 mt-2" [selectedIndex]="0" slot="center" *ngIf="sensorData" style='min-height:20px' (selectedTabChange)="selected = $event.index">
  <mat-tab [label]="translationsLib.get('DL_24_hours')" >
    <app-charts-component [terminal_vid]="terminal_vid" [time]="1" [equipment]="equipment" [sensorConnected]="sensor24hrs" [elementsDisposition]="elementsDisposition" ></app-charts-component>
  </mat-tab>
  <mat-tab [label]="translationsLib.get('DL_2_days')" >
    <app-charts-component [terminal_vid]="terminal_vid" [time]="2" [equipment]="equipment" [sensorConnected]="sensor2days" *ngIf="selected=== 1" [elementsDisposition]="elementsDisposition" ></app-charts-component>
  </mat-tab>
  <mat-tab [label]="translationsLib.get('DL_7_days')" >
    <app-charts-component [terminal_vid]="terminal_vid" [time]="7" [equipment]="equipment" [sensorConnected]="sensor7days" *ngIf="selected=== 2" [elementsDisposition]="elementsDisposition" ></app-charts-component>
  </mat-tab>
  <mat-tab [label]="translationsLib.get('DL_last_month')" >
    <app-charts-component [terminal_vid]="terminal_vid" [time]="30" [equipment]="equipment" [sensorConnected]="sensor30days" *ngIf="selected=== 3" [elementsDisposition]="elementsDisposition" ></app-charts-component>
  </mat-tab>
</mat-tab-group>
