<lib-loader *ngIf="!equipmentLoaded || !configLoaded"></lib-loader>

<div *ngIf="equipmentLoaded && configLoaded"
     class="card water-systems irrigation-water-systems mb-1"
     [class]="tabs.cardClass">

  <div class="d-flex align-items-center justify-content-between w-100">
    <div class="p-0 fs-5 fw-bold text-uppercase m-3 mb-0 me-0" style="color: var(--primary-color)">
      {{ translationsLib.get('irrigation_ws_watersystemState') }}
    </div>
    <div *ngIf="showLastConnection"
         class="p-0 m-3 ms-0 fs-6 text-muted">
      <div class="fw-bold">{{ translationsLib.get('terminal_last_connection') }}</div>
      <div class="text-center">{{ tabs.lastUpdate | date: 'dd/MM/yy HH:mm' }}</div>
    </div>
  </div>

  <mat-tab-group
    [(selectedIndex)]="visibleTabIndex"
    (selectedTabChange)="onTabChanged($event)"
    class="tab-container pb-3">
    <mat-tab *ngFor="let tab of tabs.tabs">
      <ng-template mat-tab-label>
        {{ translationsLib.get('irrigation_ws_watersystem') + ' ' + (tab.id + 1) }}
        <span [class]="(tab.programState || '') | badgeClass : tabs.terminalType"
              class="ms-3">
          {{ tab.badgeLabel }}
        </span>
      </ng-template>

      <ng-container *ngIf="tab.active; else inactiveContent">
        <ng-container *ngFor="let rowConfig of getFirstVisibleRows(fieldsConfigs.activeConfig, tab, 2)">
          <ng-container [ngTemplateOutlet]="rowTemplate"
                        [ngTemplateOutletContext]="{ rowConfig: rowConfig, tab: tab }">
          </ng-container>
        </ng-container>

        <ng-container *ngIf="hasRemainingVisibleRows(fieldsConfigs.activeConfig, tab, 2)">
          <ng-container *ngIf="showMore">
            <ng-container *ngFor="let rowConfig of getRemainingVisibleRows(fieldsConfigs.activeConfig, tab, 2)">
              <ng-container [ngTemplateOutlet]="rowTemplate"
                            [ngTemplateOutletContext]="{ rowConfig: rowConfig, tab: tab }">
              </ng-container>
            </ng-container>
          </ng-container>

          <div class="see-more-container d-flex align-items-center justify-content-end w-100 mt-3 pe-3">
            <button (click)="toggleShowMore()"
                    class="btn btn-sm btn-link p-0 d-flex align-items-center fw-bold text-uppercase">
              <ion-icon [name]="showMore ? 'remove-circle-outline' : 'add-circle-outline'"
                        class="me-2">
              </ion-icon>
              <span class="fs-5">
                {{ showMore ? translationsLib.get('climate_show_less') : translationsLib.get('view_more') }}
              </span>
            </button>
          </div>
        </ng-container>
      </ng-container>

      <ng-template #inactiveContent>
        <ng-container *ngFor="let rowConfig of fieldsConfigs.inactiveConfig">
          <ng-container *ngIf="isAnyFieldVisibleInRow(rowConfig, tab)">
            <ng-container [ngTemplateOutlet]="rowTemplate"
                          [ngTemplateOutletContext]="{ rowConfig: rowConfig, tab: tab }">
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

</div>

<ng-template #rowTemplate let-rowConfig="rowConfig" let-tab="tab">
  <div *ngIf="isAnyFieldVisibleInRow(toRowConfig(rowConfig), toTab(tab))"
       class="row m-0 mt-2 px-2 w-100">
    <div class="grid-container d-grid gap-2 p-0">
      <ng-container *ngFor="let fieldConfig of toRowConfig(rowConfig)">

        <div *ngIf="toTab(tab).fields[fieldConfig.field]?.visible"
             [style.grid-column]="'span ' + (isMobile ? fieldConfig.colXsSpan : fieldConfig.colSpan)"
             [style.grid-row]="'span ' + (isMobile ? fieldConfig.rowXsSpan : fieldConfig.rowSpan)"
             class="grid-item overflow-hidden h-100">

          <app-field-card *ngIf="toTab(tab).fields[fieldConfig.field].type === 'card'"
                          [field]="toTab(tab).fields[fieldConfig.field]"
                          [isMobile]="isMobile"
          ></app-field-card>

          <app-field-card-double *ngIf="toTab(tab).fields[fieldConfig.field].type === 'cardDouble'"
                                 [field]="toTab(tab).fields[fieldConfig.field]"
                                 [isMobile]="isMobile">
          </app-field-card-double>

          <app-field-card-triple *ngIf="toTab(tab).fields[fieldConfig.field].type === 'cardTriple'"
                                 [field]="toTab(tab).fields[fieldConfig.field]"
                                 [isMobile]="isMobile">
          </app-field-card-triple>

          <app-field-progress *ngIf="toTab(tab).fields[fieldConfig.field].type === 'progressBar'"
                              [field]="toTab(tab).fields[fieldConfig.field]"
                              [isMobile]="isMobile">
          </app-field-progress>

          <app-field-chips *ngIf="toTab(tab).fields[fieldConfig.field].type === 'chips'"
                           [field]="toTab(tab).fields[fieldConfig.field]"
                           [isMobile]="isMobile">
          </app-field-chips>

          <app-field-drainages-table *ngIf="toTab(tab).fields[fieldConfig.field].type === 'drainageTable'"
                                     [field]="toTab(tab).fields[fieldConfig.field]"
                                     [isMobile]="isMobile">
          </app-field-drainages-table>

          <app-field-fertilizer-formula *ngIf="toTab(tab).fields[fieldConfig.field].type === 'cardFertilizer'"
                                        [field]="toTab(tab).fields[fieldConfig.field]"
                                        [isMobile]="isMobile">
          </app-field-fertilizer-formula>

        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
