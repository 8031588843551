import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WeatherService } from '../weather.service';
import { WeatherLibService } from '../../services/libraries/weather-lib.service';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import {
  WeatherPredictionDataModel,
  WeatherStationModel,
} from '../weather.model';

@Component({
  selector: 'app-weather-widget',
  templateUrl: './weather-widget.component.html',
  styleUrls: ['./weather-widget.component.scss'],
})
export class WeatherWidgetComponent implements OnInit {
  @Input() vid;
  weather: WeatherPredictionDataModel;
  moment = 'd';
  windDirection: string;
  stringId;
  weather_station: WeatherStationModel = null;
  forecast: WeatherPredictionDataModel[];
  forecastHoursToShow = 24;
  forecastLoaded = false;
  weatherLoaded = false;
  constructor(
    private route: ActivatedRoute,
    private weatherService: WeatherService,
    public weatherLib: WeatherLibService,
    public translationsLib: TranslationsLibService,
    public router: Router
  ) {}

  ngOnInit() {
    this.stringId = String(Math.random());
    if (this.vid === undefined) {
      this.vid = this.route.snapshot.paramMap.get('vid');
    }
    this.getWeatherCurrent();
    this.getWeatherForecast();
  }

  getWeatherCurrent() {
    this.weatherService.getWeatherCurrentByField(this.vid).subscribe((res) => {
      this.windDirection = this.weatherLib.getWindDirection(res.wind_deg);
      this.weather = res;
      if (res.weather_station !== null) {
        this.weather_station = res.weather_station;
      }
      this.moment = this.weather.weather_icon[3];
      const back = document.getElementById(this.stringId);
      back.style.backgroundImage =
        'url(' +
        this.weatherLib.getBackgroundImage(
          this.weather.weather_id,
          this.weather.weather_icon[3]
        ) +
        ')';
      back.style.backgroundSize = 'cover';

      this.weatherLoaded = true;
    });
  }

  getWeatherForecast() {
    this.weatherService.getForecast(this.vid).subscribe((res) => {
      this.forecast = res.predictions[0].sublevel;

      if (this.forecast.length < this.forecastHoursToShow) {
        const nextForecast = res.predictions[1].sublevel;
        const remainingPredictions =
          this.forecastHoursToShow - this.forecast.length;

        this.forecast = this.forecast.concat(
          nextForecast.slice(0, remainingPredictions)
        );
      }

      this.forecastLoaded = true;
    });
  }
}
