import { Component, OnInit, Input } from '@angular/core';
import { Breadcrumb } from '../../../farming/irrigation/shared/shared.model';
import { Router } from '@angular/router';
import { PlatformLibService } from '@nutricontrol/app360-shared';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})

//@TODO: PENDING TO REFACTOR THIS!
export class BreadcrumbsComponent implements OnInit {
  @Input() breadcrumbs: Breadcrumb[] = [];
  actives = [];
  backButton: Breadcrumb[] = [];
  loading = true;
  isNative = false;
  constructor(
    private router: Router,
    private platformLib: PlatformLibService
  ) {}
  ngOnInit() {
    if (this.platformLib.isNative()) {
      this.isNative = true;
    }
    const timeout = setInterval(() => {
      if (Array.isArray(this.breadcrumbs)) {
        if (this.breadcrumbs.length > 0) {
          this.setBackButton();
          clearInterval(timeout);
        }
        // Force timeout
        setTimeout(() => {
          clearInterval(timeout);
        }, environment.intervalCheckConnectionTimeout);
      }
    }, 100);
  }

  setBackButton() {
    this.breadcrumbs.forEach((breadcrumb) => {
      if (typeof breadcrumb.to === 'object' && breadcrumb.to.length > 1) {
        breadcrumb['newTo'] =
          breadcrumb.to[0] + ';vid=' + breadcrumb.to[1]['vid'];
        if (
          !breadcrumb.active &&
          !this.router.url.includes(String(breadcrumb['newTo']))
        ) {
          this.actives.push(breadcrumb);
          this.backButton[0] = breadcrumb;
        }
      } else {
        if (
          !breadcrumb.active &&
          breadcrumb.to !== '' &&
          this.router.url !== String(breadcrumb.to)
        ) {
          this.actives.push(breadcrumb);
          this.backButton[0] = breadcrumb;
        }
      }
      this.loading = false;
    });
  }
}
