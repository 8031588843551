import { FieldDataModel } from '../fields/fields.model';

export enum UserType {
  owner = 1,
  installer = 2,
  regularUser = 3,
  backofficeSuperAdmin = 20,
  backofficeSuperAdminDeveloper = 21,
  backofficeUser = 22,
  nonVerifiedInstaller = 52,
}

export interface LangDataModel {
  es: string;
  en: string;
  pt: string;
  fr: string;
}

export interface SidebarDataModel {
  layout_configuration: {
    bottom_tabs: [
      {
        icon: string;
        page: string;
        title: string;
        items: FieldDataModel;
      }
    ];
    upper_menu: [
      {
        icon: string;
        page: string;
        title: string;
        items: FieldDataModel;
      }
    ];

    bottom_menu: [
      {
        icon: string;
        page: string;
        title: string;
        items: string;
      }
    ];
    hidden_menu: [
      {
        icon: string;
        page: string;
        title: string;
        items: string;
      }
    ];
    home: [
      {
        icon: string;
        page: string;
        title: string;
        items: string;
      }
    ];
  };
}
export interface LayoutDataModel {
  general: {
    show_attach_to_demo: boolean;
    show_terminals_anomalies: boolean;
    terminals_anomalies: [
      {
        vid: string;
        name: string;
        warp: string;
        pretty_type_name: string;
        enabled: boolean;
        activated: boolean;
        authed_user_can_admin: boolean;
      }
    ];
  };
  home: {
    show_main_dashboard: boolean;
    show_wizard: boolean;
  };
}

export interface TranslationDataModel {
  [key: string]: string;
}

export interface LanguagesDataModel {
  language: string;
  name: string;
}

export interface CountriesDataModel {
  current_page: number;
  first_item_index: number;
  items: CountryDataModel[];
  last_item_index: number;
  page_items_number: number;
  total: number;
  total_pages: number;
}
export interface CountryDataModel {
  id: number;
  name: string;
  alpha: string;
  phone: number;
  currency: string;
  language: string;
  billing_enabled: boolean;
  provinces: ProvinceDataModel[];
}
export interface ProvinceDataModel {
  id: number;
  country_id: number;
  name: string;
  rps_id_county: number;
}

export interface ET0Model {
  ET0: number;
}
