import { Component, Input, OnInit } from '@angular/core';
import { PermissionsService } from '../permissions.service';
import {
  TranslationsLibService,
  TerminalDataModel,
} from '@nutricontrol/app360-shared';
import { FieldDataModel } from '../../fields/fields.model';

@Component({
  selector: 'app-account-permissions',
  templateUrl: './account-permissions.component.html',
  styleUrls: ['./account-permissions.component.scss'],
})
export class AccountPermissionsComponent implements OnInit {
  @Input() showDemoField;
  vid = '';
  loadedFields = false;
  loadedTerminals = false;
  panelOpenState = false;
  panelOpenVid = '';
  fields: FieldDataModel[] = [];
  terminals: TerminalDataModel[] = [];
  validFieldsCount = 0;
  validTerminalsCount = 0;
  constructor(
    private permissionService: PermissionsService,
    public translationsLib: TranslationsLibService
  ) {
    this.panelOpenState = false;
  }

  ngOnInit() {
    this.getFields();
    this.getTerminals();
  }

  getFields() {
    this.permissionService.getFields().subscribe((res) => {
      this.fields = res;
      this.fields.forEach((field) => {
        if (field.is_demo !== true) {
          this.validFieldsCount++;
        }
      });
      this.loadedFields = true;
    });
  }

  getTerminals() {
    this.permissionService.getTerminals().subscribe((res) => {
      this.terminals = res;
      this.terminals.forEach((terminal) => {
        if (terminal.is_demo !== true) {
          this.validTerminalsCount++;
        }
      });
      this.loadedTerminals = true;
    });
  }
}
