<div class="card">
  <div class="card-header">
    <h2 class="text-header-primary m-0">{{translationsLib.get('profile_myucrop_title_text')}}</h2>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-12 col-lg-6">
        <div [innerHTML]="translationsLib.get('profile_myucrop_intro_text')"></div>
        <div class="text-center">
          <h4 class="text-muted" style="font-style: italic" *ngIf="sessionLib.getImpersonated() === 'true'">
            {{translationsLib.get('myucrop_no_permission')}}</h4>
          <button class="btn btn-primary" (click)="goToMyUCROP()" *ngIf="sessionLib.getImpersonated() !== 'true'">{{translationsLib.get('myucrop')}}</button>
        </div>
      </div>
      <div class="col-12 col-lg-6 mt-4 mt-lg-0">
        <img src="../../../assets/img/intro-myucrop.svg" id="my-ucrop-logo" alt="MyUCROP Intro" width="100%" />
      </div>
    </div>
  </div>
</div>
