import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BreadcrumbsComponent } from '../../commons/components/breadcrumbs/breadcrumbs.component';
import { CalendarComponent } from './shared/calendar/calendar.component';
import { GroupsComponent } from './groups/groups.component';
import { SelectPaginationComponent } from './shared/select-pagination/select-pagination.component';
import { TabsPaginationComponent } from './shared/tabs-pagination/tabs-pagination.component';
import { ProgramsTableComponent } from './shared/programs-table/programs-table.component';
import { ProgramFilterPipe } from './pipes/program-filter.pipe';
import { NgxMaskModule } from 'ngx-mask';
import { MatInputModule } from '@angular/material/input';
import { IrrigationTerminalDashboardComponent } from './irrigation-terminal-dashboard/irrigation-terminal-dashboard.component';
import { GroupsTableComponent } from './shared/groups-table/groups-table.component';
import { GroupFilterPipe } from './pipes/group-filter.pipe';
import { FertilizerFormulasTableComponent } from './shared/fertilizer-formulas-table/fertilizer-formulas-table.component';
import { FertilizerFormulasFilterPipe } from './pipes/fertilizer-formulas-filter.pipe';
import { DrainagesTableComponent } from './shared/drainages-table/drainages-table.component';
import { DrainagesFilterPipe } from './pipes/drainages-filter.pipe';
import { MixtureFormulasFilterPipe } from './pipes/mixture-formulas-filter.pipe';
import { MixtureFormulasTableComponent } from './shared/mixture-formulas-table/mixture-formulas-table.component';
import { MeteoAlarmsFilterPipe } from './pipes/meteo-alarms-filter.pipe';
import { MeteoAlarmsTableComponent } from './shared/meteo-alarms-table/meteo-alarms-table.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FertilizerFormulasComponent } from './fertilizer-formulas/fertilizer-formulas.component';
import { GroupsFormComponent } from './groups/groups-form/groups-form.component';
import { ProgramsComponent } from './programs/programs.component';
import { GroupsEditComponent } from './groups/groups-edit/groups-edit.component';
import { FertilizerFormulasEditComponent } from './fertilizer-formulas/fertilizer-formulas-edit/fertilizer-formulas-edit.component';
import { FertilizerFormulasFormComponent } from './fertilizer-formulas/fertilizer-formulas-form/fertilizer-formulas-form.component';
import { DrainagesV4Component } from './drainages/drainages-v4/drainages-v4.component';
import { DrainagesV3Component } from './drainages/drainages-v3/drainages-v3.component';
import { DrainagesV3EditComponent } from './drainages/drainages-v3/drainages-v3-edit/drainages-v3-edit.component';
import { DrainagesV4EditComponent } from './drainages/drainages-v4/drainages-v4-edit/drainages-v4-edit.component';
import { DrainagesV3FormComponent } from './drainages/drainages-v3/drainages-v3-form/drainages-v3-form.component';
import { DrainagesV4FormComponent } from './drainages/drainages-v4/drainages-v4-form/drainages-v4-form.component';
import { SecondsToHmsPipe } from './pipes/seconds-to-hms.pipe';
import { programDate } from './pipes/program-date.pipe';
import { MatTabsModule } from '@angular/material/tabs';
import { ConfigurationGridComponent } from './shared/configuration-grid/configuration-grid.component';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { programTime } from './pipes/program-time.pipe';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ProgramDateTablePipe } from './pipes/program-date-table.pipe';
import { MatMenuModule } from '@angular/material/menu';
import { MeteoAlarmsEditComponent } from './installer/meteo-alarms/meteo-alarms-edit/meteo-alarms-edit.component';
import { MeteoAlarmsFormComponent } from './installer/meteo-alarms/meteo-alarms-form/meteo-alarms-form.component';
import { MeteoAlarmsComponent } from './installer/meteo-alarms/meteo-alarms.component';
import { MixtureFormulasEditComponent } from './mixture-formulas/mixture-formulas-edit/mixture-formulas-edit.component';
import { MixtureFormulasFormComponent } from './mixture-formulas/mixture-formulas-form/mixture-formulas-form.component';
import { SecondsInputsToDatePipe } from './pipes/seconds-inputs-to-date.pipe';
import { MixtureValvesFormComponent } from './installer/mixture-valves/mixture-valves-form/mixture-valves-form.component';
import { MixtureValvesEditComponent } from './installer/mixture-valves/mixture-valves-edit/mixture-valves-edit.component';
import { ValvesFormComponent } from './installer/valves/valves-form/valves-form.component';
import { ValvesEditComponent } from './installer/valves/valves-edit/valves-edit.component';
import { PumpsFormComponent } from './installer/pumps/pumps-form/pumps-form.component';
import { PumpsEditComponent } from './installer/pumps/pumps-edit/pumps-edit.component';
import { DemandsFormComponent } from './installer/demands/demands-form/demands-form.component';
import { DemandsEditComponent } from './installer/demands/demands-edit/demands-edit.component';
import { PhCeRearmAlarmEditComponent } from './installer/ph-ce-rearm-alarm/ph-ce-rearm-alarm-edit/ph-ce-rearm-alarm-edit.component';
import { PhCeRearmAlarmFormComponent } from './installer/ph-ce-rearm-alarm/ph-ce-rearm-alarm-form/ph-ce-rearm-alarm-form.component';
import { DrainageTraysEditComponent } from './installer/drainage-trays/drainage-trays-edit/drainage-trays-edit.component';
import { DrainageTraysFormComponent } from './installer/drainage-trays/drainage-trays-form/drainage-trays-form.component';
import { FiltersEditComponent } from './installer/filters/filters-edit/filters-edit.component';
import { FiltersFormComponent } from './installer/filters/filters-form/filters-form.component';
import { MiscellaneousEditComponent } from './installer/miscellaneous/miscellaneous-edit/miscellaneous-edit.component';
import { MiscellaneousFormComponent } from './installer/miscellaneous/miscellaneous-form/miscellaneous-form.component';
import { FertilizersFormComponent } from './installer/fertilizers/fertilizers-form/fertilizers-form.component';
import { FertilizersEditComponent } from './installer/fertilizers/fertilizers-edit/fertilizers-edit.component';
import { InputOutputComponent } from './installer/input-output/input-output.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TerminalTableWrapperIrrigationComponent } from '../../commons/components/terminal-table-wrapper-irrigation/terminal-table-wrapper-irrigation.component';
import { FilterByPropertyIrrigationPipe } from '../../commons/pipes/filter-by-property-irrigation.pipe';
import { IrrigationRoutingModule } from './irrigation-routing.module';
import { FarmingModule } from '../farming.module';
import { AppCommonsModule } from 'src/app/commons/app-commons.module';
import { SensorsModule } from '../sensors/sensors.module';
import { SensorsScreenComponent } from './installer/sensors/sensors-screen.component';
import { GetProgramStatesPipe } from './pipes/get-program-states.pipe';
import { MatTabGroupGestureModule } from '@angular-material-gesture/mat-tab-group-gesture';
import { NotificationsModule } from '../../notifications/notifications.module';
import { IrrigationTimeSliderComponent } from '../common/irrigation-time-slider/irrigation-time-slider.component';
import { App360BillingSharedModule } from '@nutricontrol/app360-billing-shared';
import {App360SharedModule} from "@nutricontrol/app360-shared";

@NgModule({
  declarations: [
    CalendarComponent,
    GroupsComponent,
    SelectPaginationComponent,
    TabsPaginationComponent,
    ProgramsTableComponent,
    IrrigationTerminalDashboardComponent,
    TerminalTableWrapperIrrigationComponent,
    GroupsTableComponent,
    ProgramFilterPipe,
    GroupFilterPipe,
    FertilizerFormulasTableComponent,
    FertilizerFormulasFilterPipe,
    DrainagesTableComponent,
    DrainagesFilterPipe,
    MixtureFormulasFilterPipe,
    MixtureFormulasTableComponent,
    MeteoAlarmsFilterPipe,
    MeteoAlarmsTableComponent,
    FertilizerFormulasComponent,
    GroupsFormComponent,
    ProgramsComponent,
    GroupsEditComponent,
    FertilizerFormulasEditComponent,
    FertilizerFormulasFormComponent,
    DrainagesV4Component,
    DrainagesV3Component,
    DrainagesV3EditComponent,
    DrainagesV4EditComponent,
    DrainagesV3FormComponent,
    DrainagesV4FormComponent,
    SecondsToHmsPipe,
    programDate,
    ConfigurationGridComponent,
    programTime,
    ProgramDateTablePipe,
    MeteoAlarmsEditComponent,
    MeteoAlarmsFormComponent,
    MeteoAlarmsComponent,
    MixtureFormulasEditComponent,
    MixtureFormulasFormComponent,
    SecondsInputsToDatePipe,
    MixtureValvesFormComponent,
    MixtureValvesEditComponent,
    ValvesFormComponent,
    ValvesEditComponent,
    PumpsFormComponent,
    PumpsEditComponent,
    DemandsFormComponent,
    DemandsEditComponent,
    PhCeRearmAlarmEditComponent,
    PhCeRearmAlarmFormComponent,
    DrainageTraysEditComponent,
    DrainageTraysFormComponent,
    FiltersEditComponent,
    FiltersFormComponent,
    MiscellaneousEditComponent,
    MiscellaneousFormComponent,
    FertilizersFormComponent,
    FertilizersEditComponent,
    InputOutputComponent,
    FilterByPropertyIrrigationPipe,
    SensorsScreenComponent,
    GetProgramStatesPipe,
  ],
    imports: [
        MatTooltipModule,
        MatBadgeModule,
        DragDropModule,
        NgxMaskModule,
        MatInputModule,
        MatAutocompleteModule,
        MatTabsModule,
        MatIconModule,
        MatChipsModule,
        MatDialogModule,
        MatButtonModule,
        MatMenuModule,
        IrrigationRoutingModule,
        FarmingModule,
        AppCommonsModule,
        SensorsModule,
        MatTabGroupGestureModule,
        NotificationsModule,
        App360BillingSharedModule,
        IrrigationTimeSliderComponent,
        App360SharedModule,
    ],
  schemas: [NO_ERRORS_SCHEMA],
  exports: [
    BreadcrumbsComponent,
    DrainagesV3Component,
    TerminalTableWrapperIrrigationComponent,
    programTime,
  ],
})
export class IrrigationModule {}
