import {
  AfterViewInit,
  Component,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DrainageTraysIrrigationInstallerModel } from '../../../irrigation.model';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Breadcrumb } from '../../../shared/shared.model';
import { IrrigationService } from '../../../irrigation.service';
import { FieldsService } from '../../../../../fields/fields.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HelpersLibService } from '../../../../../services/libraries/helpers-lib.service';
import { map, startWith } from 'rxjs/operators';
import {
  TranslationsLibService,
  TerminalDataModel,
} from '@nutricontrol/app360-shared';
import { IonContent } from '@ionic/angular';

@Component({
  selector: 'app-drainage-trays-edit',
  templateUrl: './drainage-trays-edit.component.html',
  styleUrls: ['./drainage-trays-edit.component.scss'],
})
export class DrainageTraysEditComponent implements OnInit, AfterViewInit {
  isFetchingData = false;
  isFormFieldWithErrors = false;
  trays: DrainageTraysIrrigationInstallerModel[];
  trayControl = new FormControl<string | DrainageTraysIrrigationInstallerModel>(
    ''
  );
  filteredOptions: Observable<DrainageTraysIrrigationInstallerModel[]>;

  isDrainageTraysWithoutData = false;
  tray_id: number;
  terminal_vid: string;
  terminalData: TerminalDataModel;
  breadcrumbs: Breadcrumb[] = [];
  trayValue: string;
  isFetchingTitle = false;
  isDirty = false;
  mode = '';
  @ViewChild(IonContent, { static: false }) private content: IonContent;
  drainageTray: any;
  hasScrollbar = false;
  saveButtonTimeElapsed = false;

  constructor(
    private irrigationService: IrrigationService,
    private fieldsService: FieldsService,
    private route: ActivatedRoute,
    private router: Router,
    private helpersLib: HelpersLibService,
    public translationsLib: TranslationsLibService
  ) {}

  /**
   * https://stackoverflow.com/questions/56882873/how-to-detect-if-ion-content-has-a-scrollbar/58579938#58579938
   * Apply CSS class based on vertical scroll (save button)
   */
  ngAfterViewInit() {
    setTimeout(() => {
      this.saveButtonTimeElapsed = true;
      this.checkForScrollbar();
    }, 3000);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkForScrollbar();
  }

  async checkForScrollbar() {
    const scrollElement = await this.content.getScrollElement();
    this.hasScrollbar = scrollElement.scrollHeight > scrollElement.clientHeight;
  }

  isDataSaved(): boolean {
    return this.isDirty;
  }

  hasWritePermissions(): boolean {
    return this.terminalData.authed_user_can_write;
  }

  ngOnInit() {
    this.terminal_vid = this.route.snapshot.paramMap.get('vid');

    this.tray_id = Number(this.route.snapshot.paramMap.get('drainage_tray'));

    if (!this.terminal_vid || this.tray_id === null) {
      this.router.navigate(['/home/dashboard']);
    }

    this.getTraysList();

    this.filteredOptions = this.trayControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const tray = typeof value === 'string' ? value : value?.tray;
        return tray ? this._filter(tray as string) : this.trays.slice();
      })
    );

    this.generateTitleAndBreadcrumbs();
  }

  generateTitleAndBreadcrumbs() {
    this.isFetchingTitle = true;
    this.breadcrumbs = [];
    this.irrigationService.getTerminal(this.terminal_vid).subscribe((res) => {
      this.terminalData = res;
      if (res.field) {
        this.breadcrumbs.push({
          text: res.field.name,
          disabled: false,
          to: ['/field', { vid: res.field.vid }],
          active: false,
        });
      }

      this.breadcrumbs.push({
        text: res.name,
        disabled: false,
        to: `/farming/${this.terminal_vid}/irrigation`,
        active: false,
      });

      this.irrigationService
        .getIrrigationInstallerDrainageTray(this.terminal_vid, this.tray_id)
        .subscribe((_) => {
          this.trayValue = `${this.translationsLib.get(
            'irrigation_drainage_tray'
          )} ${this.tray_id + 1}`;

          this.breadcrumbs.push({
            text: 'Configuración',
            disabled: false,
            to: `/farming/${this.terminal_vid}/irrigation/5`,
            active: false,
          });

          this.breadcrumbs.push({
            text: this.trayValue,
            disabled: false,
            active: true,
          });

          this.helpersLib.setTitle('', this.trayValue, null);

          this.isFetchingTitle = false;
        });
    });
  }

  private _filter(tray: string): DrainageTraysIrrigationInstallerModel[] {
    const filterValue = tray.toLowerCase();

    return this.trays.filter((option) =>
      (
        this.translationsLib.get('irrigation_drainage_tray') +
        ' ' +
        (option.tray + 1).toString()
      )
        .toLowerCase()
        .includes(filterValue)
    );
  }

  onChangePagination(event) {
    // console.log('onChangePagination', event.option.value);
    this.router.navigate([
      `/farming/${this.terminal_vid}/irrigation/irrigation_installer/drainage_trays/${event.tray}`,
    ]);
  }

  autocompleteDisplayFn(tray: DrainageTraysIrrigationInstallerModel) {
    return tray && tray.tray
      ? this.translationsLib.get('irrigation_drainage_tray') +
          ' ' +
          (tray.tray + 1)
      : '';
  }

  getTraysList() {
    this.isFetchingData = true;
    this.irrigationService
      .getIrrigationInstallerDrainageTrays(this.terminal_vid)
      .subscribe((res) => {
        this.trays = res;

        if (this.trays.length === 0) this.isDrainageTraysWithoutData = true;

        this.isFetchingData = false;
      });
  }
}
