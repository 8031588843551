<mat-expansion-panel
  [expanded]="isNew === true"
  (opened)="panelOpenState = true"
  (closed)="panelOpenState = false"
  class="my-4" [ngClass]="{'is-new' : isNew === true}">
  <mat-expansion-panel-header class="ps-3">
    <mat-panel-title>
      <h2 class="my-1">
        <span *ngIf="isNew === true">
          {{ translationsLib.get('reports_report_task_new') }}
        </span>
        <span *ngIf="isNew !== true">
          {{ name }}
        </span>
      </h2>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <section class="mx-2">
    <div class="row align-items-center" *ngIf="ready === true">
      <div class="col-12 col-lg-4 px-1 px-lg-2">
        <mat-form-field appearance="fill" class="reports-name-form-field w-100">
          <mat-label>{{ translationsLib.get('reports_name') }}</mat-label>
          <input matInput type="text" [(ngModel)]="name">
          <button *ngIf="name" matSuffix mat-icon-button aria-label="Clear" (click)="name=''">
            <ion-icon name="close-outline"></ion-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-6 px-1 px-lg-2">
        <div class="pb-4">
          <span>{{ translationsLib.get('reports_send_report_activated') }}&nbsp;</span>
          <mat-slide-toggle [(ngModel)]="active"></mat-slide-toggle>
        </div>
      </div>
      <div class="col-12 col-lg-4 px-1 px-lg-2">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>{{ translationsLib.get('reports_type') }}</mat-label>
          <mat-select [formControl]="report">
            <mat-option *ngFor="let availableReport of availableReports" [value]="availableReport.id">{{ availableReport.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4 px-1 px-lg-2">
        <ng-select [items]="irrigationTerminals"
                   bindLabel="name_vid"
                   groupBy="field_name"
                   placeholder="{{ translationsLib.get('irrigation_terminals') }}"
                   [multiple]="true"
                   [closeOnSelect]="false"
                   [selectableGroup]="true"
                   [selectableGroupAsModel]="false"
                   [compareWith]="compareTerminalReportModel"
                   [(ngModel)]="selectedIrrigationTerminals"
                   (change)="selectedIrrigationTerminalsCallback()"
        >
          <ng-template ng-optgroup-tmp let-item="item">
            {{ item.field_name || 'Unnamed field'}}
          </ng-template>
        </ng-select>
      </div>
      <div class="col-12 col-lg-4 px-1 px-lg-2">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>{{ translationsLib.get('reports_period') }}</mat-label>
          <mat-select [formControl]="period">
            <mat-option *ngFor="let availablePeriod of availablePeriods" [value]="availablePeriod.id">{{ availablePeriod.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 px-1 px-lg-2">
        <div class="card card-inside">
          <div class="card-body p-0 p-lg-4">
            <div class="row align-items-center">
              <div class="col-12">
                <h3 class="mt-0">
                  {{ translationsLib.get('reports_report_task_recipients') }}
                </h3>
              </div>
              <div class="col-12"
                   *ngIf="selectedIrrigationTerminals.length === 0">
                <i>{{ translationsLib.get('reports_report_task_choose_terminal') }}</i>
              </div>
              <aside *ngIf="selectedIrrigationTerminals.length > 0">
                <div class="col-12" *ngFor="let reportTaskAvailableUserPermission of reportTaskAvailablePermissionsResponse.report_task_available_user_permissions; let i = index">
                  <aside *ngIf="reportTaskAvailableUserPermission.report_permission === 1">
                    <h4>{{ translationsLib.get('reports_report_task_owner') }}</h4>
                    <mat-checkbox
                      (change)="userPermissionCheckbox($event, reportTaskAvailableUserPermission, i)"
                      [checked]="reportTaskAvailableUserPermission.active"
                      [disabled]="reportTaskAvailableUserPermission.report_permission === 1"
                    >
                      <b>
                        {{ reportTaskAvailableUserPermission.user_identity.name }}
                        {{ reportTaskAvailableUserPermission.user_identity.surname }}
                      </b>
                      <span><{{ reportTaskAvailableUserPermission.user_identity.email }}></span>
                    </mat-checkbox>
                  </aside>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-12 col-lg-6">
                      <div class="row">
                        <div class="col-12">
                          <h4>{{ translationsLib.get('reports_report_task_platform_users') }}</h4>
                        </div>
                        <div class="col-12 mb-2 mb-lg-2" *ngFor="let reportTaskAvailableUserPermission of reportTaskAvailablePermissionsResponse.report_task_available_user_permissions; let i = index">
                          <aside *ngIf="reportTaskAvailableUserPermission.report_permission !== 1">
                            <mat-checkbox
                              (change)="userPermissionCheckbox($event, reportTaskAvailableUserPermission, i)"
                              [checked]="reportTaskAvailableUserPermission.active"
                            >
                              <div class="user-identity-container">
                                <b>
                                  {{ reportTaskAvailableUserPermission.user_identity.name }}
                                  {{ reportTaskAvailableUserPermission.user_identity.surname }}
                                </b>
                                <span><{{ reportTaskAvailableUserPermission.user_identity.email }}></span>
                              </div>
                            </mat-checkbox>
                          </aside>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <div class="row">
                        <div class="col-12">
                          <h4>
                            {{ translationsLib.get('reports_report_task_non_platform_users') }}
                          </h4>
                        </div>
                        <div class="col-12">
                          <mat-form-field class="w-100" appearance="fill">
                            <mat-label>
                              Emails (max. {{ reportTaskAvailablePermissionsResponse.report_task_available_email_permissions_number }})
                            </mat-label>
                            <mat-chip-list #chipList aria-label="Fruit selection">
                              <mat-chip
                                *ngFor="let reportTaskAvailableEmailPermission of reportTaskAvailablePermissionsResponse.report_task_available_email_permissions; let i = index"
                                (removed)="emailPermissionRemoveEvent(reportTaskAvailableEmailPermission)"
                              >
                                {{ reportTaskAvailableEmailPermission.user_email }}
                                <button matChipRemove>
                                  <ion-icon name="close-outline"></ion-icon>
                                </button>
                              </mat-chip>
                              <input placeholder="Email"
                                     [matChipInputFor]="chipList"
                                     [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                     [matChipInputAddOnBlur]="true"
                                     (matChipInputTokenEnd)="emailPermissionAddEvent($event)">
                            </mat-chip-list>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>

      </div>
      <div class="col-12 px-1 px-lg-2 text-end">
        <button class="btn mb-3" style="background-color: #6f9ad1; color: white;" type="submit" (click)="saveReportTask();">
          {{ translationsLib.get('save') }}
        </button>
      </div>
    </div>
  </section>

</mat-expansion-panel>

