<ion-content>
  <lib-loader *ngIf="ready === false"></lib-loader>
  <div class="container-fluid password-reset-user-invitation-container" *ngIf="ready === true">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8 col-lg-6 col-xl-4 px-lg-6 my-5 align-self-center">

        <div class="row">
          <div class="col-12 text-center">
            <img class="display-4 text-center mb-4 w-75" src="../../../assets/img/ucrop.svg">
          </div>
          <div class="col-12">

            <!-- Heading -->
            <h1 class="text-center mb-3" *ngIf="resetUserInvitation === 1">
              {{ translationsLib.get('auth_reset_user_invitation_title') }}
            </h1>
            <h1 class="text-center mb-3" *ngIf="resetUserInvitation === 2">
              {{ translationsLib.get('auth_forgotten_type_your_new_password') }}
            </h1>
            <!-- Subheading -->
            <p class="text-muted text-center mb-5" *ngIf="resetUserInvitation === 1">
              {{ translationsLib.get('auth_reset_user_invitation_subtitle') }}
            </p>

            <p class="text-muted text-center mb-5" *ngIf="resetUserInvitation === 2">
              {{ translationsLib.get('auth_forgotten_type_your_new_password_text') }}
            </p>

            <!--Code-->
            <div *ngIf="resetUserInvitation === 1">
              <code-input [isCodeHidden]="false"
                          [codeLength]="6"
                          [isCharsCode]="true"
                          (codeCompleted)="setConfirmationCode($event)">
              </code-input>
              <br>
              <button type="submit"class="btn btn-lg w-100 btn-primary mb-3">
                {{ translationsLib.get('auth_forgotten_new_password_validate') }}
              </button>
              <div class="text-center">
                <small class="text-muted text-center">
                  {{ translationsLib.get('auth_forgotten_do_you_remember_your_password') }}
                  <a style="cursor: pointer; color:#3d6493" (click)="router.navigate(['/login']);">{{ translationsLib.get('auth_forgotten_password_login') }}</a>.
                </small>
              </div>
            </div>

            <!-- Form2 -->
            <form [formGroup]="resetPasswordPass" (submit)="onSubmitPassword()" *ngIf="resetUserInvitation === 2">
              <!-- Email address -->
              <div class="form-group">

                <!-- Label -->
                <label class="form-label">
                  {{ translationsLib.get('auth_forgotten_new_password') }}
                </label>

                <!-- Input -->
                <input type="password" class="form-control" placeholder="" formControlName="password">
                <br>
                <div style="height: 70px">
                  <app-password-strength [passwordToCheck]="resetPasswordPass.value.password"></app-password-strength>
                </div>
              </div>

              <!-- Submit -->
              <button type ="submit" class="btn btn-lg w-100 btn-primary mb-3" [disabled]="resetPasswordPass.invalid">
                {{ translationsLib.get('save') }}
              </button>

              <!-- Link -->
              <div class="text-center">
                <small class="text-muted text-center">
                  {{ translationsLib.get('auth_forgotten_do_you_remember_your_password') }}
                  <a style="cursor: pointer; color:#3d6493" (click)="router.navigate(['/login']);">{{ translationsLib.get('auth_forgotten_password_login') }}</a>
                </small>
              </div>
            </form>

          </div>
        </div>

      </div>
      <div class="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">

        <!-- Image -->
        <div class="bg-cover h-100 min-vh-100 mt-n1 me-n3" style="background-image: url(assets/img/covers/Cover_login.jpg)"></div>

      </div>
    </div> <!-- / .row -->
  </div>
</ion-content>
