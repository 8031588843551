import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import { Breadcrumb } from '../../farming/irrigation/shared/shared.model';
import { ReportsService } from '../reports.service';
import { TerminalsLibService } from '../../services/libraries/terminals-lib.service';
import { TerminalsService } from '../../terminals/terminals.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {
  AvailableReportModel,
  CreatedReportModel,
  FieldReportModel,
  RandomReportModel,
  TerminalReportModel,
} from '../reports.model';

import Swal from 'sweetalert2';
import * as moment from 'moment';
import { MatStepper } from '@angular/material/stepper';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  SessionLibService,
  TranslationsLibService,
} from '@nutricontrol/app360-shared';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter
} from "@angular/material-moment-adapter";

@Component({
  selector: 'app-reports',
  templateUrl: './reports-page.component.html',
  styleUrls: ['./reports-page.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class ReportsPageComponent implements OnInit {
  @ViewChild('stepper') private stepper: MatStepper;
  selectedIrrigationTerminals: TerminalReportModel[] = [];
  minDate: Date;
  maxDate: Date;
  ready = false;
  selectedReport = '';
  createdReport: CreatedReportModel = null;
  report = new FormControl('');
  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  tablePageItemNumber = 20;
  breadcrumbs: Breadcrumb[] = [];
  irrigationTerminals: TerminalReportModel[] = [];
  irrigationTerminalsByField: FieldReportModel[] = [];
  availableReports: AvailableReportModel[] = [];
  selectedButtonDates = null;
  showReportStartDate = '';
  showReportDateSplitter = '';
  showReportEndDate = '';
  simpleTable = false;
  dates = ['24h', '48h', '7d'];
  reportSearched = false;
  otherDates = false;
  stepperOrientation: 'horizontal' | 'vertical' = 'horizontal';
  createdReportBeginString = '';
  createdReportEndString = '';
  constructor(
    private route: ActivatedRoute,
    private dateAdapter: DateAdapter<Date>,
    private helpersLib: HelpersLibService,
    public router: Router,
    private reportsService: ReportsService,
    private sessionLib: SessionLibService,
    private terminalsLib: TerminalsLibService,
    private terminalsService: TerminalsService,
    public translationsLib: TranslationsLibService,
    private _formBuilder: FormBuilder,
    private breakpointObserver: BreakpointObserver,
    @Inject(MAT_DATE_LOCALE) private _locale: string ,
  ) {
    this.dateAdapter.setLocale(sessionLib.getLanguage());
    this.dateAdapter.getFirstDayOfWeek = () => 1;
    this._locale = sessionLib.getLanguage();
    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe((result) => {
        this.stepperOrientation = result.matches ? 'vertical' : 'horizontal';
      });
  }

  ngOnInit() {
    this.helpersLib.sendEventPageView('Reports');
    this.helpersLib.setTitle(
      '',
      this.translationsLib.get('reports_reports'),
      null
    );
    this.breadcrumbs = [
      {
        text: this.translationsLib.get('reports_reports'),
        disabled: false,
        to: ' ',
        active: true,
      },
    ];

    this.minDate = moment().subtract(60, 'd').toDate();
    this.maxDate = new Date();

    this.terminalsService.getTerminals().subscribe((terminals) => {
      terminals.forEach((terminal) => {
        if (
          this.terminalsLib.getType(terminal.type) === 'IRRIGATION' &&
          terminal.is_demo !== true
        ) {
          const irrigationTerminal = {
            vid: terminal.vid,
            name_vid: terminal.name + ' - ' + terminal.vid,
            field_name: null,
            field_vid: null,
            completed: false,
          };
          if (terminal.field !== null) {
            irrigationTerminal.field_name = terminal.field.name;
            irrigationTerminal.field_vid = terminal.field.vid;
          }
          this.irrigationTerminals.push(irrigationTerminal);
        }
      });
      this.parseParams();
      this.irrigationTerminalsByField = this.groupByFieldName(
        this.irrigationTerminals
      );
      this.ready = true;
    });

    this.availableReports = this.reportsService.getAvailableReports();
    //this.setIrrigationDevReport();
    //this.setProgramsConsumptionDevReport();
    //this.setGroupingConsumptionDevReport();
    //this.setAlarmsDevReport();
  }
  groupByFieldName(terminals: TerminalReportModel[]): FieldReportModel[] {
    const grouped = terminals.reduce((acc, record) => {
      const key =
        record.field_name || this.translationsLib.get('devices_no_field');
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(record);
      return acc;
    }, {} as { [key: string]: TerminalReportModel[] });

    return Object.keys(grouped).map((key) => ({
      key,
      terminals: grouped[key],
    }));
  }
  updateAllComplete() {
    this.selectedIrrigationTerminals = [];
    this.irrigationTerminals.map((terminal) => {
      if (terminal.completed) {
        this.selectedIrrigationTerminals.push(terminal);
      }
    });
  }

  parseParams() {
    this.route.queryParams.subscribe((params) => {
      if ('selectedIrrigationTerminals' in params) {
        const sit = params.selectedIrrigationTerminals.split(';');
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let x = 0; x < sit.length; x++) {
          this.irrigationTerminals.forEach((irrigationTerminal) => {
            if (sit[x] === irrigationTerminal.vid) {
              this.selectedIrrigationTerminals.push(irrigationTerminal);
            }
          });
        }
      }
      if ('simpleTable' in params) {
        this.simpleTable = params.simpleTable === 'true';
      }
      if ('selectedReport' in params) {
        this.report.setValue(params.selectedReport);
      }
      if ('tablePageItemNumber' in params) {
        this.tablePageItemNumber = parseInt(params.tablePageItemNumber, 10);
      }
      if ('rangeStart' in params && 'rangeEnd' in params) {
        this.showReportStartDate = moment(
          decodeURI(params.rangeStart),
          'YYYY-MM-DD'
        ).format('DD/MM/YYYY');
        const proposedShowReportEndDate = moment(
          decodeURI(params.rangeEnd),
          'YYYY-MM-DD'
        ).format('DD/MM/YYYY');
        if (this.showReportStartDate !== proposedShowReportEndDate) {
          this.showReportDateSplitter = '-';
          this.showReportEndDate = proposedShowReportEndDate;
        }

        this.range.setValue({
          start: moment(decodeURI(params.rangeStart), 'YYYY-MM-DD').toDate(),
          end: moment(decodeURI(params.rangeEnd), 'YYYY-MM-DD').toDate(),
        });
      }
      if ('auto' in params) {
        this.createReport();
      }
    });
  }

  createReport() {
    if (
      this.report.value.length > 0 &&
      this.selectedIrrigationTerminals.length > 0 &&
      this.range.value.start !== null &&
      this.range.value.end !== null
    ) {
      this.reportSearched = true;
      this.selectedReport = 'none';
      const self = this;

      this.createdReportBeginString = moment(this.range.value.start).format(
        'YYYY/MM/DD'
      );
      this.createdReportEndString = moment(this.range.value.end).format(
        'YYYY/MM/DD'
      );

      // Force update on regenerate selectedReport
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      setTimeout(() => {
        self.selectedReport = self.report.value;
        self.createdReport = {
          report: self.report.value,
          terminals: self.selectedIrrigationTerminals,
          dateRange: {
            start: self.range.value.start,
            end: self.range.value.end,
          },
          tablePageItemNumber: this.tablePageItemNumber,
          simpleTable: this.simpleTable,
        };
      }, 100);
    } else {
      Swal.fire({
        text: this.translationsLib.get('fill_in_all_inputs_alert'),
        showConfirmButton: true,
        confirmButtonText: this.translationsLib.get('accept'),
        icon: 'error',
      });
    }
  }

  private setIrrigationDevReport() {
    this.reportSearched = true;
    this.selectedReport = 'irrigations';
    this.createdReport = {
      report: 'irrigations',
      terminals: [
        {
          //vid: '290422072409-234',
          vid: '211218085634-003',
          name_vid: 'FORCED_NAME 211218085634-003',
          field_vid: 'field_vid',
          field_name: 'Tomates 3',
          completed: true,
        },
      ],
      dateRange: {
        start: moment().subtract(7, 'days').toDate(),
        end: moment().subtract(7, 'days').toDate(),
      },
      tablePageItemNumber: 20,
      simpleTable: this.simpleTable,
    };
  }

  private setProgramsConsumptionDevReport() {
    this.reportSearched = true;
    this.selectedReport = 'programs_consumptions';
    this.createdReport = {
      report: 'programs_consumptions',
      terminals: [
        {
          vid: '211218085634-003',
          name_vid: 'FORCED_NAME 211218085634-003',
          field_vid: 'field_vid',
          field_name: 'Tomates 3',
          completed: true,
        },
      ],
      dateRange: {
        start: moment().subtract(1, 'days').toDate(),
        end: moment().subtract(1, 'days').toDate(),
      },
      tablePageItemNumber: 20,
      simpleTable: this.simpleTable,
    };
  }

  private setGroupingConsumptionDevReport() {
    this.reportSearched = true;
    this.selectedReport = 'groups_consumptions';
    this.createdReport = {
      report: 'groups_consumptions',
      terminals: [
        {
          vid: '211218085634-003',
          name_vid: 'FORCED_NAME 211218085634-003',
          field_vid: 'field_vid',
          field_name: 'Tomates 3',
          completed: true,
        },
      ],
      dateRange: {
        start: moment().subtract(1, 'days').toDate(),
        end: moment().subtract(1, 'days').toDate(),
      },
      tablePageItemNumber: 20,
      simpleTable: this.simpleTable,
    };
  }

  private setDrainageDevReport() {
    this.reportSearched = true;
    this.selectedReport = 'drainages';
    this.createdReport = {
      report: 'drainages',
      terminals: [
        {
          vid: '211218085634-003',
          name_vid: 'FORCED_NAME 211218085634-003',
          field_vid: 'field_vid',
          field_name: 'Tomates 3',
          completed: true,
        },
      ],
      dateRange: {
        start: moment().subtract(0, 'days').toDate(),
        end: moment().subtract(0, 'days').toDate(),
      },
      tablePageItemNumber: 20,
      simpleTable: this.simpleTable,
    };
  }
  private setAlarmsDevReport() {
    this.reportSearched = true;
    this.selectedReport = 'alarms';
    this.createdReport = {
      report: 'alarms',
      terminals: [
        {
          vid: '211218085634-003',
          name_vid: 'FORCED_NAME 211218085634-003',
          field_vid: 'field_vid',
          field_name: 'Tomates 3',
          completed: true,
        },
      ],
      dateRange: {
        start: moment().subtract(0, 'days').toDate(),
        end: moment().subtract(0, 'days').toDate(),
      },
      tablePageItemNumber: 20,
      simpleTable: this.simpleTable,
    };
  }
  compareTerminalReportModel = (item, selected) => {
    if (selected.field_vid && item.field_vid) {
      return item.field_vid === selected.field_vid;
    }
    if (item.vid && selected.vid) {
      return item.vid === selected.vid;
    }
    return false;
  };
  resetAll() {
    this.report.setValue(null);
    this.selectedIrrigationTerminals.forEach((terminal) => {
      terminal.completed = false;
    });
    this.reportSearched = false;
    this.selectedReport = '';
    this.range = new FormGroup({
      start: new FormControl<Date | null>(null),
      end: new FormControl<Date | null>(null),
    });
  }
  putDates(time) {
    const end = new Date();
    const start = new Date();
    if (time === null) {
      const oldSelected = document.getElementById(this.selectedButtonDates);
      oldSelected.style.backgroundColor = '#f0f4fa';
      oldSelected.style.borderColor = '#f0f4fa';
      oldSelected.style.color = '#283e59';
      this.selectedButtonDates = null;
    } else {
      if (this.selectedButtonDates === null) {
        const selected = document.getElementById(time);
        selected.style.backgroundColor = '#66B8BE';
        selected.style.color = '#FFFFFF';
        this.selectedButtonDates = time;
      } else {
        const oldSelected = document.getElementById(this.selectedButtonDates);
        oldSelected.style.backgroundColor = '#f0f4fa';
        oldSelected.style.borderColor = '#f0f4fa';
        oldSelected.style.color = '#283e59';
        const selected = document.getElementById(time);
        selected.style.backgroundColor = '#66B8BE';
        selected.style.color = '#FFFFFF';
        this.selectedButtonDates = time;
      }
      switch (time) {
        case '24h':
          start.setDate(end.getDate() - 1);
          break;
        case '48h':
          start.setDate(end.getDate() - 2);
          break;
        case '7d':
          start.setDate(end.getDate() - 7);
          break;
      }
      this.range.patchValue({
        start,
        end,
      });
    }
  }
  putReportData(report: RandomReportModel) {
    this.report.setValue(report.reportType.id);
    this.selectedIrrigationTerminals = [report.terminal];
    this.putDates(report.date);
    this.createReport();
  }
}
