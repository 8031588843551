<app-main-header [breadcrumbs]="breadcrumbs"></app-main-header>

<ion-content>
  <div class="container-fluid p-1 p-lg-2">
    <div class="col-12" *ngIf="this.terminals.length ===0">
      <div class="header-body border-0">
        <div class="row align-items-end" >
          <div class="col text-end">
            <a class="btn add" (click)="router.navigate(['./pair-new-terminal'])">
              {{ translationsLib.get('terminal_add_terminal') }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="mx-lg-4">
      <div class="col-12">
        <lib-loader *ngIf="loading === true"></lib-loader>

        <section *ngIf="this.terminals.length > 0 && !loading">
          <div class="row px-0">
            <!--<div id="mapa"></div>-->
            <!--<app-native-maps *ngIf="platform === true" [fields]="terminals" [coming]="'terminals-general'"></app-native-maps>-->
            <!--<app-webapp-maps *ngIf="platform === false" [fields]="terminals" [coming]="'terminals-general'"></app-webapp-maps>-->
            <lib-leaflet-maps [fields]="terminals" [coming]="'terminals-general'"></lib-leaflet-maps>
          </div>
          <div class="row">
            <div class="col-4">
              <h1 class="consulta">{{ translationsLib.get('terminal_main_table_upper_text') }}</h1>
              <h1  class="mt-0" style="margin-bottom: 0.5em">{{ translationsLib.get('terminal_main_table_bottom_text') }}</h1>
            </div>
            <div class="col-8 align-self-center text-end">
              <a (click)="reloadField()" class="btn btn-primary" *ngIf="loading === false">
                <ion-icon name="refresh-outline"></ion-icon>
              </a>
              &nbsp;
              <a class="btn add" (click)="router.navigate(['./pair-new-terminal'])">
                {{ translationsLib.get('terminal_add_terminal') }}
              </a>
            </div>
            <app-terminals-table></app-terminals-table>
          </div>
        </section>

        <section class="d-none d-sm-block" id="noDevices" *ngIf="this.terminals.length === 0 && !loading">
          <div class="card">
            <div class="row">
              <div class="col-4">

              </div>
              <div class="col-4 text-center">
                <h1 class="img-title">{{ translationsLib.get('terminal_no_terminal_upper_title') }}</h1>
                <h2 class="img-subtitle" [innerHTML]="translationsLib.get('terminal_no_terminal_bottom_text')"></h2>
              </div>
              <div class="col-4" style="padding-right: 7%">
                <img src="assets/img/fields_and_terminals_empty/Flecha%20versión%20pc.svg">
              </div>
            </div>
            <div class="row justify-content-center">
              <img src="assets/img/fields_and_terminals_empty/Sin%20equipos.svg" style="width: 37%">
            </div>
          </div>
        </section>
        <section class="d-sm-none" *ngIf="this.terminals.length === 0 &&  !loading">
          <div class="card">
            <div class="row">
              <div class="col-9" style="text-align-last: center; padding-left: 90px;">
                <h1 class="img-title-small">{{ translationsLib.get('terminal_no_terminal_upper_title') }}</h1>
                <h2 class="img-subtitle-small" [innerHTML]="translationsLib.get('terminal_no_terminal_bottom_text')"></h2>
              </div>
              <div class="col-3">
                <img src="assets/img/fields_and_terminals_empty/Flecha%20versión%20móvil.svg">
              </div>
            </div>
            <div class="row justify-content-center">
              <img src="assets/img/fields_and_terminals_empty/Sin%20equipos%20versión%20móvil.svg">
            </div>
          </div>
        </section>


      </div>
    </div>
  </div>
</ion-content>
