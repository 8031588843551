import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  TranslationsLibService,
  TerminalDataModel,
} from '@nutricontrol/app360-shared';
import { IrrigationService } from '../../irrigation.service';
import { Breadcrumb } from '../../shared/shared.model';

@Component({
  selector: 'sensors-screen',
  templateUrl: './sensors-screen.component.html',
  styleUrls: ['./sensors-screen.component.scss'],
})
export class SensorsScreenComponent implements OnInit {
  breadcrumbs: Breadcrumb[] = [];
  isSensorsWithoutData = false;
  terminal_vid: string;
  terminal: TerminalDataModel;

  constructor(
    public translationsLib: TranslationsLibService,
    private irrigationService: IrrigationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const [, , vid, , , ,] = this.router.url.split('/');
    this.terminal_vid = vid;
    this.irrigationService.getTerminal(vid).subscribe((res) => {
      this.terminal = res;
      if (res.field) {
        this.breadcrumbs.push({
          text: res.field.name,
          disabled: false,
          to: ['/field', { vid: res.field.vid }],
          active: false,
        });
      }
      this.breadcrumbs.push(
        {
          text: res.name,
          disabled: false,
          to: `/farming/${vid}/irrigation`,
          active: false,
        },
        {
          text: this.translationsLib.get('configuration_title'),
          disabled: false,
          to: `/farming/${this.terminal_vid}/irrigation/5`,
          active: false,
        },
        {
          text: this.translationsLib.get('sensors_title'),
          disabled: false,
          active: true,
        }
      );
    });
  }
}
