<div
  class="mb-4 d-flex justify-content-center mt-5"
  *ngIf="isFetchingData || !layoutConfiguration || !valvesList"
>
  <div class="spinner-border text-warning" role="status">
    <span class="visually-hidden">{{
      this.translationsLib.get('irrigation_loading')
    }}</span>
  </div>
</div>

<app-dirty-message
  [conditions]="layoutConfiguration && !isFetchingData && valvesList"
  [dirty]="
    groupsForm.value.dirty || isGroupUpdatedDirty || isAnyGroupDirtyAfterPost
  "
></app-dirty-message>
<ng-template
  [ngIf]="
    mode === 'simple' &&
    layoutConfiguration &&
    !isFetchingData &&
    valvesList &&
    !isGroupWithoutData
  "
>
  <form [formGroup]="groupsForm" class="simple-form">
    <div class="mb-2">
      <div class="card" *ngIf="layoutConfiguration.blocks.group.show">
        <div class="card-body custom-card-body">
          <div class="row">
            <div class="col-sm-4 col-md-4">
              <h6 class="fs-2">
                {{ translationsLib.get('irrigation_group') }}
              </h6>
              <p
                class="fs-6 fw-lighter"
                [innerHTML]="translationsLib.get('irrigation_group_help')"
              ></p>
            </div>
            <div class="col-sm-8 col-md-8 col-inputs">
              <div class="row first-row justify-content-end">
                <div class="col-sm-12 col-md-6">
                  <h6 class="fs-5">
                    {{ translationsLib.get('irrigation_name') }}
                  </h6>
                  <div class="input-group mb-2">
                    <input
                      formControlName="name"
                      type="text"
                      class="form-control"
                      placeholder="{{ translationsLib.get('irrigation_name') }}"
                    />
                  </div>
                </div>
              </div>

              <div class="row justify-content-end">
                <div class="col-sm-12 col-md-9">
                  <app-irrigation-time-slider
                    [preIrrigationTime]="this.groupsForm.get('preIrrigationTime')?.value"
                    [fertilizationTime]="this.groupsForm.get('fertilizationTime')?.value"
                    [postIrrigationTime]="this.groupsForm.get('postIrrigationTime')?.value"
                    (preIrrigationTimeChanged)="onPreIrrigationTimeChanged($event)"
                    (fertilizationTimeChanged)="onFertilizationTimeChanged($event)"
                    (postIrrigationTimeChanged)="onPostIrrigationTimeChanged($event)"
                    (resetIrrigationValues)="onResetIrrigationValues($event)"
                  >
                  </app-irrigation-time-slider>
                </div>
              </div>
              <div class="row justify-content-end">
                <div class="col-sm-4 col-md-3">
                  <h6 class="fs-5">
                    {{ translationsLib.get('irrigation_pre_fertilizer_time') }}
                  </h6>
                  <div class="mb-2">
                    <ng-template #preIrrigationTimePicker>
                      <timepicker
                        appOnlyNumbers
                        [showMeridian]="false"
                        [showSeconds]="true"
                        [minuteStep]="1"
                        [secondsStep]="1"
                        [formControlName]="'preIrrigationTime'"
                      ></timepicker>
                    </ng-template>
                    <input
                      appOnlyNumbers
                      [ngClass]="{
                        'ng-dirty': groupsForm.controls.preIrrigationTime.dirty
                      }"
                      class="form-control text-center"
                      type="text"
                      [popover]="preIrrigationTimePicker"
                      [outsideClick]="true"
                      placement="bottom"
                      [value]="groupsForm.value.preIrrigationTime | inputTime"
                      (change)="
                        onChangeInputTimeWithKeyboard(
                          $event,
                          'preIrrigationTime'
                        )
                      "
                    />
                  </div>
                </div>
                <div class="col-sm-4 col-md-3">
                  <h6 class="fs-5">
                    {{ translationsLib.get('irrigation_irrigation_time') }}
                  </h6>
                  <div class="mb-2">
                    <ng-template #fertilizationTimePicker>
                      <timepicker
                        appOnlyNumbers
                        [showMeridian]="false"
                        [showSeconds]="true"
                        [minuteStep]="1"
                        [secondsStep]="1"
                        [formControlName]="'fertilizationTime'"
                      ></timepicker>
                    </ng-template>
                    <input
                      appOnlyNumbers
                      [ngClass]="{
                        'ng-dirty': groupsForm.controls.fertilizationTime.dirty
                      }"
                      class="form-control text-center"
                      type="text"
                      [popover]="fertilizationTimePicker"
                      [outsideClick]="true"
                      placement="bottom"
                      [value]="groupsForm.value.fertilizationTime | inputTime"
                      (change)="
                        onChangeInputTimeWithKeyboard(
                          $event,
                          'fertilizationTime'
                        )
                      "
                    />
                  </div>
                </div>
                <div class="col-sm-4 col-md-3">
                  <h6 class="fs-5">
                    {{ translationsLib.get('irrigation_post_fertilizer_time') }}
                  </h6>
                  <div class="mb-2">
                    <ng-template #postIrrigationTimePicker>
                      <timepicker
                        appOnlyNumbers
                        [showMeridian]="false"
                        [showSeconds]="true"
                        [minuteStep]="1"
                        [secondsStep]="1"
                        [formControlName]="'postIrrigationTime'"
                      ></timepicker>
                    </ng-template>
                    <input
                      appOnlyNumbers
                      [ngClass]="{
                        'ng-dirty': groupsForm.controls.postIrrigationTime.dirty
                      }"
                      class="form-control text-center"
                      type="text"
                      [popover]="postIrrigationTimePicker"
                      [outsideClick]="true"
                      placement="bottom"
                      [value]="groupsForm.value.postIrrigationTime | inputTime"
                      (change)="
                        onChangeInputTimeWithKeyboard(
                          $event,
                          'postIrrigationTime'
                        )
                      "
                    />
                  </div>
                </div>
              </div>
              <div
                class="row justify-content-end"
                *ngIf="
                  layoutConfiguration.blocks.group.sub_blocks[0].volumes.show
                "
              >
                <div class="col-sm-4 col-md-3">
                  <h6 class="fs-5">
                    {{ translationsLib.get('irrigation_irrigation_volume') }}
                  </h6>
                  <div class="input-group mb-2 number-input">
                    <button
                      class="minus minus-style"
                      (click)="inputNumberService.decrementValue($event)"
                    ></button>
                    <input
                      formControlName="fertilizationVolume"
                      type="number"
                      class="form-control"
                      placeholder="{{
                        translationsLib.get('irrigation_irrigation_volume')
                      }}"
                      min="-9999"
                      max="9999"
                    />
                    <button
                      class="plus border-plus plus-style"
                      (click)="inputNumberService.incrementValue($event)"
                    ></button>
                  </div>
                </div>
                <div class="col-sm-4 col-md-3">
                  <h6 class="fs-5">
                    {{
                      translationsLib.get(
                        'irrigation_irrigation_pre_fertilizer_volume'
                      )
                    }}
                  </h6>
                  <div class="input-group mb-2 number-input">
                    <button
                      class="minus minus-style"
                      (click)="inputNumberService.decrementValue($event)"
                    ></button>
                    <input
                      formControlName="preIrrigationVolume"
                      type="number"
                      class="form-control"
                      placeholder="{{
                        translationsLib.get(
                          'irrigation_irrigation_pre_fertilizer_volume'
                        )
                      }}"
                      min="-9999"
                      max="9999"
                    />
                    <button
                      class="plus border-plus plus-style"
                      (click)="inputNumberService.incrementValue($event)"
                    ></button>
                  </div>
                </div>
                <div class="col-sm-4 col-md-3">
                  <h6 class="fs-5">
                    {{
                      translationsLib.get(
                        'irrigation_irrigation_post_fertilizer_volume'
                      )
                    }}
                  </h6>
                  <div class="input-group mb-2 number-input">
                    <button
                      class="minus minus-style"
                      (click)="inputNumberService.decrementValue($event)"
                    ></button>
                    <input
                      formControlName="postIrrigationVolume"
                      type="number"
                      class="form-control"
                      placeholder="{{
                        translationsLib.get(
                          'irrigation_irrigation_post_fertilizer_volume'
                        )
                      }}"
                      min="-9999"
                      max="9999"
                    />
                    <button
                      class="plus border-plus plus-style"
                      (click)="inputNumberService.incrementValue($event)"
                    ></button>
                  </div>
                </div>
              </div>
              <div
                class="row justify-content-end"
                *ngIf="
                  layoutConfiguration.blocks.group.sub_blocks[0].valves.show
                "
              >
                <div class="col-sm-12 col-md-9">
                  <h6 class="fs-5">
                    {{ translationsLib.get('irrigation_selected_valves') }}
                  </h6>
                  <div class="input-group mb-2">
                    <mat-form-field class="custom-chip-list" appearance="fill">
                      <mat-chip-list #valvesChipList>
                        <mat-chip
                          *ngFor="let valve of valvesSelected"
                          (removed)="removeValve(valve)"
                        >
                          {{
                            valve.name
                              ? valve.name
                              : translationsLib.get('irrigation_valve') +
                                ' ' +
                                (valve.valve * 1 + 1)
                          }}
                          <button matChipRemove>
                            <i [innerHTML]="'REMOVE' | sensorIcon"></i>
                          </button>
                        </mat-chip>
                        <input
                          placeholder="{{
                            translationsLib.get('irrigation_choose_valves')
                          }}"
                          #valvesInput
                          [formControl]="valvesCtrl"
                          [matAutocomplete]="auto"
                          [matChipInputFor]="valvesChipList"
                          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                          (matChipInputTokenEnd)="addValve($event)"
                        />
                      </mat-chip-list>
                      <mat-autocomplete
                        #auto="matAutocomplete"
                        (optionSelected)="selectedValve($event)"
                      >
                        <mat-option
                          *ngFor="let valve of filteredValves | async"
                          [value]="valve"
                        >
                          {{
                            valve.name
                              ? valve.name
                              : translationsLib.get('irrigation_valve') +
                                ' ' +
                                (valve.valve * 1 + 1)
                          }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-12">
                  <div class="row justify-content-end">
                    <div class="col-sm-4 col-md-3">
                      <h6 class="fs-5">
                        {{
                          translationsLib.get('irrigation_fertilizer_formula')
                        }}
                      </h6>
                      <div class="input-group mb-2">
                        <select
                          class="form-select"
                          formControlName="fertilizerFormula"
                        >
                          <option [value]="255">-</option>
                          <option
                            [value]="formula.formula"
                            *ngFor="let formula of fertilizerFormulasList"
                          >
                            {{
                              formula.name
                                ? formula.name
                                : translationsLib.get('irrigation_recipe') +
                                  ' ' +
                                  (formula.formula + 1)
                            }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-4 col-md-3">
                      <h6 class="fs-5">
                        {{
                          translationsLib.get(
                            'irrigation_irrigation_pre_fertilizer_recipe'
                          )
                        }}
                      </h6>
                      <div class="input-group mb-2">
                        <select
                          class="form-select"
                          formControlName="preFertilizerFormula"
                        >
                          <option [value]="255">-</option>
                          <option
                            [value]="formula.formula"
                            *ngFor="let formula of fertilizerFormulasList"
                          >
                            {{
                              formula.name
                                ? formula.name
                                : translationsLib.get('irrigation_recipe') +
                                  ' ' +
                                  (formula.formula + 1)
                            }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-4 col-md-3">
                      <h6 class="fs-5">
                        {{
                          translationsLib.get(
                            'irrigation_irrigation_post_fertilizer_recipe'
                          )
                        }}
                      </h6>
                      <div class="input-group mb-2">
                        <select
                          class="form-select"
                          formControlName="postFertilizerFormula"
                        >
                          <option [value]="255">-</option>
                          <option
                            [value]="formula.formula"
                            *ngFor="let formula of fertilizerFormulasList"
                          >
                            {{
                              formula.name
                                ? formula.name
                                : translationsLib.get('irrigation_recipe') +
                                  ' ' +
                                  (formula.formula + 1)
                            }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card" *ngIf="layoutConfiguration.blocks.ph_control.show">
        <div class="card-body custom-card-body">
          <div class="row">
            <div class="col-sm-12 col-md-4">
              <h6 class="fs-2">
                {{ translationsLib.get('irrigation_ph_control') }}
              </h6>
              <p
                class="fs-6 fw-lighter"
                [innerHTML]="
                  translationsLib.get('irrigation_ph_control_recipe_help')
                "
              ></p>
            </div>
            <div class="col-sm-8 col-md-8 col-inputs">
              <div class="row">
                <div class="col-6 col-md-6">
                  <div class="row">
                    <div class="col-sm-6">
                      <h6 class="fs-5">
                        {{ translationsLib.get('irrigation_pre_fertilize_ph') }}
                      </h6>
                    </div>
                    <div class="col-sm-6">
                      <div class="input-group my-2">
                        <div class="form-check form-switch">
                          <input
                            formControlName="phControlAtPreFertilization"
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-6">
                  <div class="row">
                    <div class="col-sm-6">
                      <h6 class="fs-5">
                        {{
                          translationsLib.get('irrigation_post_fertilize_ph')
                        }}
                      </h6>
                    </div>
                    <div class="col-sm-6">
                      <div class="input-group my-2">
                        <div class="form-check form-switch">
                          <input
                            formControlName="phControlAtPostFertilization"
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="card"
        *ngIf="layoutConfiguration.blocks.instant_radiation_correction.show"
      >
        <div class="card-body custom-card-body">
          <div class="row">
            <div class="col-sm-4 col-md-4">
              <h6 class="fs-2">
                {{ translationsLib.get('irrigation_irrigation_time_percent') }}
              </h6>
              <p
                class="fs-6 fw-lighter"
                [innerHTML]="
                  translationsLib.get('irrigation_irrigation_time_percent_help')
                "
              ></p>
            </div>
            <div class="col-sm-8 col-md-8 col-inputs">
              <div class="row first-row">
                <div class="col-sm-4 col-md-4">
                  <h6 class="fs-5">
                    {{
                      translationsLib.get('irrigation_irrigation_time_percent')
                    }}
                  </h6>
                  <div class="input-group mb-2 number-input">
                    <button
                      class="minus minus-style"
                      (click)="inputNumberService.decrementValue($event)"
                    ></button>
                    <input
                      formControlName="correctionFactor"
                      type="number"
                      class="form-control"
                      placeholder="{{
                        translationsLib.get(
                          'irrigation_irrigation_time_percent'
                        )
                      }}"
                      min="-9999"
                      max="9999"
                    />
                    <button
                      class="plus border-plus plus-style"
                      (click)="inputNumberService.incrementValue($event)"
                    ></button>
                  </div>
                </div>
                <div class="col-sm-4 col-md-4">
                  <h6 class="fs-5">
                    {{ translationsLib.get('irrigation_correction_start') }}
                  </h6>
                  <div class="input-group mb-2 number-input">
                    <button
                      class="minus minus-style"
                      (click)="inputNumberService.decrementValue($event)"
                    ></button>
                    <input
                      formControlName="startRadiation"
                      type="number"
                      class="form-control"
                      placeholder="{{
                        translationsLib.get('irrigation_correction_start')
                      }} (W/m²)"
                      min="-9999"
                      max="9999"
                    />
                    <button
                      class="plus border-plus plus-style"
                      (click)="inputNumberService.incrementValue($event)"
                    ></button>
                  </div>
                </div>
                <div class="col-sm-4 col-md-4">
                  <h6 class="fs-5">
                    {{ translationsLib.get('irrigation_correction_end') }}
                  </h6>
                  <div class="input-group mb-2 number-input">
                    <button
                      class="minus minus-style"
                      (click)="inputNumberService.decrementValue($event)"
                    ></button>
                    <input
                      formControlName="endRadiation"
                      type="number"
                      class="form-control"
                      placeholder="{{
                        translationsLib.get('irrigation_correction_end')
                      }} (W/m²)"
                      min="-9999"
                      max="9999"
                    />
                    <button
                      class="plus border-plus plus-style"
                      (click)="inputNumberService.incrementValue($event)"
                    ></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body custom-card-body">
          <div class="row">
            <div class="col-sm-4 col-md-4">
              <h6 class="fs-2">
                {{ this.translationsLib.get('irrigation_pumps') }}
              </h6>
              <p
                class="fs-6 fw-lighter"
                [innerHTML]="translationsLib.get('irrigation_pumps_group_help')"
              ></p>
            </div>
            <div class="col-sm-8 col-md-8 col-inputs">
              <div class="row first-row">
                <div
                  class="col-12 mt-3"
                  *ngIf="
                    layoutConfiguration.blocks.pumps.available_ids.length === 0
                  "
                >
                  <i>
                    {{ translationsLib.get('irrigation_no_connected_pump') }}
                  </i>
                </div>
                <div
                  class="col-sm-4 col-md-4"
                  *ngFor="
                    let bombId of [].constructor(
                      layoutConfiguration.blocks.pumps.max
                    );
                    let i = index
                  "
                >
                  <h6 class="fs-5">
                    {{ translationsLib.get('irrigation_pump') }}
                    {{ i + 1 }}
                  </h6>
                  <div class="input-group mb-2">
                    <select
                      class="form-select"
                      formControlName="pump{{ (i + 1).toString() }}"
                    >
                      <option [value]="255">-</option>
                      <option
                        [value]="pump.pump"
                        *ngFor="let pump of pumpsList"
                      >
                        {{ translationsLib.get('irrigation_pump') }}
                        {{ pump.pump + 1 }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>

<ng-template
  [ngIf]="
    mode === 'advanced' &&
    layoutConfiguration &&
    !isFetchingData &&
    valvesList &&
    !isGroupWithoutData
  "
>
  <form [formGroup]="groupsForm" class="advanced-form">
    <div class="row">
      <div class="col-lg-6 col-md-6">
        <div class="card" *ngIf="layoutConfiguration.blocks.group.show">
          <div class="card-body custom-card-body">
            <div class="row">
              <div class="col-md-12">
                <h6 class="fs-2">
                  {{ translationsLib.get('irrigation_group') }}
                </h6>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-md-6 col-lg-8">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_name') }}
                    </h6>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="input-group mb-2">
                      <input
                        formControlName="name"
                        type="text"
                        class="form-control"
                        placeholder="{{
                          translationsLib.get('irrigation_name')
                        }}"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="row justify-content-end">
                <div class="col-12">
                  <app-irrigation-time-slider
                    [preIrrigationTime]="this.groupsForm.get('preIrrigationTime')?.value"
                    [fertilizationTime]="this.groupsForm.get('fertilizationTime')?.value"
                    [postIrrigationTime]="this.groupsForm.get('postIrrigationTime')?.value"
                    (preIrrigationTimeChanged)="onPreIrrigationTimeChanged($event)"
                    (fertilizationTimeChanged)="onFertilizationTimeChanged($event)"
                    (postIrrigationTimeChanged)="onPostIrrigationTimeChanged($event)"
                    (resetIrrigationValues)="onResetIrrigationValues($event)"
                  >
                  </app-irrigation-time-slider>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-md-6 col-lg-8">
                    <h6 class="fs-5">
                      {{
                      translationsLib.get('irrigation_pre_fertilizer_time')
                      }}
                    </h6>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="mb-2">
                      <ng-template #preIrrigationTimePicker>
                        <timepicker
                          appOnlyNumbers
                          [showMeridian]="false"
                          [showSeconds]="true"
                          [minuteStep]="1"
                          [secondsStep]="1"
                          [formControlName]="'preIrrigationTime'"
                        ></timepicker>
                      </ng-template>
                      <input
                        appOnlyNumbers
                        [ngClass]="{
                          'ng-dirty':
                            groupsForm.controls.preIrrigationTime.dirty
                        }"
                        class="form-control text-center"
                        type="text"
                        [popover]="preIrrigationTimePicker"
                        [outsideClick]="true"
                        placement="bottom"
                        [value]="groupsForm.value.preIrrigationTime | inputTime"
                        (change)="
                          onChangeInputTimeWithKeyboard(
                            $event,
                            'preIrrigationTime'
                          )
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-md-6 col-lg-8">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_irrigation_time') }}
                    </h6>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="mb-2">
                      <ng-template #fertilizationTimePicker>
                        <timepicker
                          appOnlyNumbers
                          [showMeridian]="false"
                          [showSeconds]="true"
                          [minuteStep]="1"
                          [secondsStep]="1"
                          [formControlName]="'fertilizationTime'"
                        ></timepicker>
                      </ng-template>
                      <input
                        appOnlyNumbers
                        [ngClass]="{
                          'ng-dirty':
                            groupsForm.controls.fertilizationTime.dirty
                        }"
                        class="form-control text-center"
                        type="text"
                        [popover]="fertilizationTimePicker"
                        [outsideClick]="true"
                        placement="bottom"
                        [value]="groupsForm.value.fertilizationTime | inputTime"
                        (change)="
                          onChangeInputTimeWithKeyboard(
                            $event,
                            'fertilizationTime'
                          )
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-md-6 col-lg-8">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get('irrigation_post_fertilizer_time')
                      }}
                    </h6>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="mb-2">
                      <ng-template #postIrrigationTimePicker>
                        <timepicker
                          appOnlyNumbers
                          [showMeridian]="false"
                          [showSeconds]="true"
                          [minuteStep]="1"
                          [secondsStep]="1"
                          [formControlName]="'postIrrigationTime'"
                        ></timepicker>
                      </ng-template>
                      <input
                        appOnlyNumbers
                        [ngClass]="{
                          'ng-dirty':
                            groupsForm.controls.postIrrigationTime.dirty
                        }"
                        class="form-control text-center"
                        type="text"
                        [popover]="postIrrigationTimePicker"
                        [outsideClick]="true"
                        placement="bottom"
                        [value]="
                          groupsForm.value.postIrrigationTime | inputTime
                        "
                        (change)="
                          onChangeInputTimeWithKeyboard(
                            $event,
                            'postIrrigationTime'
                          )
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row"
              *ngIf="
                layoutConfiguration.blocks.group.sub_blocks[0].volumes.show
              "
            >
              <div class="col-sm-12 col-md-12">
                <div class="row">
                  <div class="col-md-6 col-lg-8">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_irrigation_volume') }}
                    </h6>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="input-group mb-2 number-input">
                      <button
                        class="minus minus-style"
                        (click)="inputNumberService.decrementValue($event)"
                      ></button>
                      <input
                        formControlName="fertilizationVolume"
                        type="number"
                        class="form-control"
                        placeholder="{{
                          translationsLib.get('irrigation_irrigation_volume')
                        }}"
                        min="-9999"
                        max="9999"
                      />
                      <button
                        class="plus border-plus plus-style"
                        (click)="inputNumberService.incrementValue($event)"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12">
                <div class="row">
                  <div class="col-md-6 col-lg-8">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get(
                          'irrigation_irrigation_pre_fertilizer_volume'
                        )
                      }}
                    </h6>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="input-group mb-2 number-input">
                      <button
                        class="minus minus-style"
                        (click)="inputNumberService.decrementValue($event)"
                      ></button>
                      <input
                        formControlName="preIrrigationVolume"
                        type="number"
                        class="form-control"
                        placeholder="{{
                          translationsLib.get(
                            'irrigation_irrigation_pre_fertilizer_volume'
                          )
                        }}"
                        min="-9999"
                        max="9999"
                      />
                      <button
                        class="plus border-plus plus-style"
                        (click)="inputNumberService.incrementValue($event)"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12">
                <div class="row">
                  <div class="col-md-6 col-lg-8">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get(
                          'irrigation_irrigation_post_fertilizer_volume'
                        )
                      }}
                    </h6>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="input-group mb-2 number-input">
                      <button
                        class="minus minus-style"
                        (click)="inputNumberService.decrementValue($event)"
                      ></button>
                      <input
                        formControlName="postIrrigationVolume"
                        type="number"
                        class="form-control"
                        placeholder="{{
                          translationsLib.get(
                            'irrigation_irrigation_post_fertilizer_volume'
                          )
                        }}"
                        min="-9999"
                        max="9999"
                      />
                      <button
                        class="plus border-plus plus-style"
                        (click)="inputNumberService.incrementValue($event)"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row"
              *ngIf="layoutConfiguration.blocks.group.sub_blocks[0].valves.show"
            >
              <div class="col-md-12">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_selected_valves') }}
                </h6>
                <div class="input-group mb-2">
                  <mat-form-field class="custom-chip-list" appearance="fill">
                    <mat-chip-list #valvesChipList>
                      <mat-chip
                        *ngFor="let valve of valvesSelected"
                        (removed)="removeValve(valve)"
                      >
                        {{
                          valve.name
                            ? valve.name
                            : translationsLib.get('irrigation_valve') +
                              ' ' +
                              (valve.valve * 1 + 1)
                        }}
                        <button matChipRemove>
                          <i [innerHTML]="'REMOVE' | sensorIcon"></i>
                        </button>
                      </mat-chip>
                      <input
                        placeholder="{{
                          valvesSelected.length === 0
                            ? translationsLib.get(
                                'irrigation_non_attached_valves'
                              )
                            : translationsLib.get('irrigation_choose_valves')
                        }}"
                        #valvesInput
                        [formControl]="valvesCtrl"
                        [matAutocomplete]="auto"
                        [matChipInputFor]="valvesChipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="addValve($event)"
                      />
                    </mat-chip-list>
                    <mat-autocomplete
                      #auto="matAutocomplete"
                      (optionSelected)="selectedValve($event)"
                    >
                      <mat-option
                        *ngFor="let valve of filteredValves | async"
                        [value]="valve"
                      >
                        {{
                          valve.name
                            ? valve.name
                            : translationsLib.get('irrigation_valve') +
                              ' ' +
                              (valve.valve * 1 + 1)
                        }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <!--
                  <ion-select
                    placeholder="{{ translationsLib.get('irrigation_choose_valves') }}"
                    [multiple]="true"
                    class="custom-multiselect form-select"
                    [interfaceOptions]="{
                      cssClass: 'custom-multiselect-alert'
                    }"
                    (ionChange)="handleChangeValves($event)"
                    okText="Ok"
                    cancelText="Cancelar"
                    [ngModel]="valvesSelected"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <ion-select-option
                      *ngFor="let valve of valvesList"
                      [value]="valve.valve"
                    >
                      {{
                        valve.name ? valve.name : 'Válvula ' + (valve.valve + 1)
                      }}
                    </ion-select-option>
                  </ion-select>
                  -->
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-md-6 col-lg-8">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_fertilizer_formula') }}
                    </h6>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="input-group mb-2">
                      <select
                        class="form-select"
                        formControlName="fertilizerFormula"
                      >
                        <option [value]="255">-</option>
                        <option
                          [value]="formula.formula"
                          *ngFor="let formula of fertilizerFormulasList"
                        >
                          {{
                            formula.name
                              ? formula.name
                              : translationsLib.get('irrigation_recipe') +
                                ' ' +
                                (formula.formula + 1)
                          }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-md-6 col-lg-8">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get(
                          'irrigation_irrigation_pre_fertilizer_recipe'
                        )
                      }}
                    </h6>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="input-group mb-2">
                      <select
                        class="form-select"
                        formControlName="preFertilizerFormula"
                      >
                        <option [value]="255">-</option>
                        <option
                          [value]="formula.formula"
                          *ngFor="let formula of fertilizerFormulasList"
                        >
                          {{
                            formula.name
                              ? formula.name
                              : translationsLib.get('irrigation_recipe') +
                                ' ' +
                                (formula.formula + 1)
                          }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-md-6 col-lg-8">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get(
                          'irrigation_irrigation_post_fertilizer_recipe'
                        )
                      }}
                    </h6>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="input-group mb-2">
                      <select
                        class="form-select"
                        formControlName="postFertilizerFormula"
                      >
                        <option [value]="255">-</option>
                        <option
                          [value]="formula.formula"
                          *ngFor="let formula of fertilizerFormulasList"
                        >
                          {{
                            formula.name
                              ? formula.name
                              : translationsLib.get('irrigation_recipe') +
                                ' ' +
                                (formula.formula + 1)
                          }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6">
        <div class="card" *ngIf="layoutConfiguration.blocks.ph_control.show">
          <div class="card-body custom-card-body">
            <div class="row">
              <div class="col-md-12">
                <h6 class="fs-2">
                  {{ translationsLib.get('irrigation_ph_control') }}
                </h6>
              </div>
            </div>
            <div class="row">
              <div class="col-6 col-md-12">
                <div class="row">
                  <div class="col-sm-6">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_pre_fertilize_ph') }}
                    </h6>
                  </div>
                  <div class="col-sm-6">
                    <div class="input-group my-2">
                      <div class="form-check form-switch right-switch">
                        <input
                          formControlName="phControlAtPreFertilization"
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-12">
                <div class="row">
                  <div class="col-sm-6">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_post_fertilize_ph') }}
                    </h6>
                  </div>
                  <div class="col-sm-6">
                    <div class="input-group my-2">
                      <div class="form-check form-switch right-switch">
                        <input
                          formControlName="phControlAtPostFertilization"
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="card"
          *ngIf="layoutConfiguration.blocks.instant_radiation_correction.show"
        >
          <div class="card-body custom-card-body">
            <div class="row">
              <div class="col-md-12">
                <h6 class="fs-2">
                  {{
                    translationsLib.get(
                      'irrigation_instant_radiation_correction'
                    )
                  }}
                </h6>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-12">
                <div class="row">
                  <div class="col-md-6 col-lg-8">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get(
                          'irrigation_irrigation_time_percent'
                        )
                      }}
                    </h6>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="input-group mb-2 number-input">
                      <button
                        class="minus minus-style"
                        (click)="inputNumberService.decrementValue($event)"
                      ></button>
                      <input
                        formControlName="correctionFactor"
                        type="number"
                        class="form-control"
                        placeholder="{{
                          translationsLib.get(
                            'irrigation_irrigation_time_percent'
                          )
                        }}"
                        min="-9999"
                        max="9999"
                      />
                      <button
                        class="plus border-plus plus-style"
                        (click)="inputNumberService.incrementValue($event)"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12">
                <div class="row">
                  <div class="col-md-6 col-lg-8">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_correction_start') }}
                      (W/m²)
                    </h6>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="input-group mb-2 number-input">
                      <button
                        class="minus minus-style"
                        (click)="inputNumberService.decrementValue($event)"
                      ></button>
                      <input
                        formControlName="startRadiation"
                        type="number"
                        class="form-control"
                        placeholder="{{
                          translationsLib.get('irrigation_correction_start')
                        }} (W/m²)"
                        min="-9999"
                        max="9999"
                      />
                      <button
                        class="plus border-plus plus-style"
                        (click)="inputNumberService.incrementValue($event)"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12">
                <div class="row">
                  <div class="col-md-6 col-lg-8">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_correction_end') }}
                      (W/m²)
                    </h6>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="input-group mb-2 number-input">
                      <button
                        class="minus minus-style"
                        (click)="inputNumberService.decrementValue($event)"
                      ></button>
                      <input
                        formControlName="endRadiation"
                        type="number"
                        class="form-control"
                        placeholder="{{
                          translationsLib.get('irrigation_correction_end')
                        }} (W/m²)"
                        min="-9999"
                        max="9999"
                      />
                      <button
                        class="plus border-plus plus-style"
                        (click)="inputNumberService.incrementValue($event)"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body custom-card-body">
            <div class="row">
              <div class="col-md-12">
                <h6 class="fs-2">
                  {{ this.translationsLib.get('irrigation_pumps') }}
                </h6>
              </div>
            </div>
            <div class="row">
              <div
                class="col-12 mt-3"
                *ngIf="
                  layoutConfiguration.blocks.pumps.available_ids.length === 0
                "
              >
                <i>{{ translationsLib.get('irrigation_no_connected_pump') }}</i>
              </div>
              <div
                class="col-sm-4 col-md-4"
                *ngFor="
                  let bombId of [].constructor(
                    layoutConfiguration.blocks.pumps.max
                  );
                  let i = index
                "
              >
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_pump') }} {{ i + 1 }}
                </h6>
                <div class="input-group mb-2">
                  <select
                    class="form-select"
                    formControlName="pump{{ (i + 1).toString() }}"
                  >
                    <option [value]="255">-</option>
                    <option [value]="pump.pump" *ngFor="let pump of pumpsList">
                      {{ translationsLib.get('irrigation_pump') }}
                      {{ pump.pump + 1 }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
