import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'badgeClass',
})
export class BadgesPipe implements PipeTransform {
  private badgeMapping = {
    IRRIGATION: {
      STOPPED: 'bg-secondary',
      IRRIGATING: 'bg-irrigating',
      STANDBY: 'bg-info-outlined',
      TIME_MIN_VERIFYING: 'bg-info-outlined',
      WAITING_VM: 'bg-info',
    },
    CONFIGURABLE: {
      STOPPED: 'bg-secondary',
      IRRIGATING: 'bg-irrigating',
      STANDBY: 'bg-info-outlined',
      WAITING_PUMP_START: 'bg-info-outlined',
      WAITING_DIESEL_START: 'bg-info-outlined',
      WAITING_DIESEL_STOP: 'bg-info-outlined',
      RETAINED: 'bg-ucrop-gray-outlined',
    },
  };

  transform(state: string, screen: string) {
    state = state.toUpperCase();
    screen = screen.toUpperCase();
    let output = '';

    if (this.badgeMapping[screen]?.[state]) {
      output = 'badge ' + this.badgeMapping[screen]?.[state];
    } else {
      output = 'badge bg-gray-outlined';
    }

    return output;
  }
}
