import {
  AfterViewInit,
  Component,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  Progbombas,
  Progfiltros,
  PumpsIrrigationInstallerModel,
  SafeData,
} from '../../../irrigation.model';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Breadcrumb } from '../../../shared/shared.model';
import { ActivatedRoute, Router } from '@angular/router';
import { IrrigationService } from '../../../irrigation.service';
import { HelpersLibService } from '../../../../../services/libraries/helpers-lib.service';
import { map, startWith } from 'rxjs/operators';
import {
  TranslationsLibService,
  TerminalDataModel,
} from '@nutricontrol/app360-shared';
import { IonContent } from '@ionic/angular';

@Component({
  selector: 'app-pumps-edit',
  templateUrl: './pumps-edit.component.html',
  styleUrls: ['./pumps-edit.component.scss'],
})
export class PumpsEditComponent implements OnInit, AfterViewInit, SafeData {
  mode = '';
  isFetchingData = false;
  layoutConfiguration: Progbombas;
  pumps: PumpsIrrigationInstallerModel[];
  isFormFieldWithErrors = false;
  isPumpsWithoutData = false;
  pumpsControl = new FormControl<string | PumpsIrrigationInstallerModel>('');
  filteredOptions: Observable<PumpsIrrigationInstallerModel[]>;
  breadcrumbs: Breadcrumb[] = [];
  pump: PumpsIrrigationInstallerModel;
  terminalData: TerminalDataModel;
  isFetchingEquipmentData = false;
  pump_id: number;
  pumpValue: string;

  terminal_vid: string;
  isDirty = false;
  @ViewChild(IonContent, { static: false }) private content: IonContent;
  hasScrollbar = false;
  saveButtonTimeElapsed = false;

  constructor(
    private router: Router,
    private irrigationService: IrrigationService,
    private helpersLib: HelpersLibService,
    private activatedRoute: ActivatedRoute,
    public translationsLib: TranslationsLibService
  ) {}

  /**
   * https://stackoverflow.com/questions/56882873/how-to-detect-if-ion-content-has-a-scrollbar/58579938#58579938
   * Apply CSS class based on vertical scroll (save button)
   */

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkForScrollbar();
  }

  async checkForScrollbar() {
    const scrollElement = await this.content.getScrollElement();
    this.hasScrollbar = scrollElement.scrollHeight > scrollElement.clientHeight;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.saveButtonTimeElapsed = true;
      this.checkForScrollbar();
    }, 3000);

    this.activatedRoute.paramMap.subscribe((_) => {
      this.helpersLib.setTitle(
        '',
        `${this.translationsLib.get('irrigation_pump')} ${this.pump_id + 1}`,
        null
      );
    });
  }

  isDataSaved(): boolean {
    return this.isDirty;
  }

  hasWritePermissions(): boolean {
    return this.terminalData.authed_user_can_write;
  }

  async ngOnInit() {
    const [, , vid, , , , data] = this.router.url.split('/');
    this.terminal_vid = vid;
    this.pump_id = Number(data);

    await this.getLayoutConfiguration();
    this.generateBreadcrumbs();

    this.filteredOptions = this.pumpsControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const pumpValue = typeof value === 'string' ? value : value?.pump;
        return pumpValue
          ? this._filter(pumpValue as string)
          : this.pumps.slice();
      })
    );

    this.getPumpsList();
  }

  generateBreadcrumbs() {
    this.isFetchingEquipmentData = true;
    this.breadcrumbs = [];
    this.irrigationService.getTerminal(this.terminal_vid).subscribe((res) => {
      this.terminalData = res;
      if (res.field) {
        this.breadcrumbs.push({
          text: res.field.name,
          disabled: false,
          to: ['/field', { vid: res.field.vid }],
          active: false,
        });
      }
      this.breadcrumbs.push(
        {
          text: res.name,
          disabled: false,
          to: `/farming/${this.terminal_vid}/irrigation`,
          active: false,
        },
        {
          text: 'Configuración',
          disabled: false,
          to: `/farming/${this.terminal_vid}/irrigation/5`,
          active: false,
        },
        {
          text: this.pumpValue
            ? this.pumpValue
            : `${this.translationsLib.get('irrigation_pump')} ${
                this.pump_id + 1
              }`,
          disabled: false,
          active: true,
        }
      );

      this.isFetchingEquipmentData = false;
    });
  }

  private _filter(pump: string): PumpsIrrigationInstallerModel[] {
    const filterValue = pump.toLowerCase();
    const self = this;
    return this.pumps.filter((option) =>
      (
        self.translationsLib.get('irrigation_pump') +
        ' ' +
        (option.pump + 1).toString()
      )
        .toLowerCase()
        .includes(filterValue)
    );
  }

  onChangePagination(event) {
    this.router.navigate([
      `/farming/${this.terminal_vid}/irrigation/irrigation_installer/pumps/${event.pump}`,
    ]);
  }

  autocompleteDisplayFn(pump: PumpsIrrigationInstallerModel) {
    return pump && pump.pump
      ? this.translationsLib.get('irrigation_pump') + ' ' + (pump.pump + 1)
      : '';
  }

  getPumpsList() {
    this.isFetchingData = true;

    this.irrigationService
      .getIrrigationInstallerPumps(this.terminal_vid)
      .subscribe((res) => {
        this.pumps = res.filter((pump) =>
          this.layoutConfiguration.available_ids.includes(pump.pump.toString())
        );

        if (this.pumps.length === 0) this.isPumpsWithoutData = true;

        this.isFetchingData = false;
      });
  }

  async getLayoutConfiguration(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.irrigationService
        .getTerminalLayoutConfiguration(this.terminal_vid)
        .subscribe((res) => {
          this.layoutConfiguration = res.screens_configuration.progbombas;

          resolve();
        }, reject);
    });
  }
}
