<app-main-header [breadcrumbs]="breadcrumbs"></app-main-header>

<ion-content>
  <div class="container-fluid custom-container-fluid my-2">
    <div
      class="mb-4 d-flex justify-content-center mt-5"
      *ngIf="tray_form.isFetchingData && !isDrainageTraysWithoutData"
    >
      <div class="spinner-border text-warning" role="status">
        <span class="visually-hidden">{{
          this.translationsLib.get('irrigation_loading')
        }}</span>
      </div>
    </div>
    <div
      *ngIf="
        !isFetchingData &&
        !tray_form.isFetchingData &&
        !isDrainageTraysWithoutData
      "
    >
      <div class="mb-2">
        <div class="row">
          <app-generic-header
            *ngIf="trays && drainageTray"
            [elements]="trays"
            [text]="'name'"
            [selected]="drainageTray"
            [placeholder]="
              translationsLib.get('irrigation_change_drainage_tray')
            "
            [elementSearchId]="'tray'"
            [label]="translationsLib.get('irrigation_drainage_tray')"
            (changeSelected)="onChangePagination($event)"
          >
            <div irrigation>
              <div class="row justify-content-end">
                <div class="col-auto ps-0 dots">
                  <app-mode
                    *ngIf="!isDrainageTraysWithoutData"
                    (changeEventEmitter)="mode = $event"
                  ></app-mode>
                </div>
              </div>
            </div>
          </app-generic-header>
        </div>
      </div>
    </div>
    <div [style.display]="tray_form.isFetchingData ? 'none' : 'block'">
      <app-drainage-trays-form
        [mode]="mode"
        [terminal_vid]="terminal_vid"
        [tray_id]="tray_id"
        (dirtyEventEmitter)="isDirty = $event"
        (drainageTrayEventEmitter)="drainageTray = $event"
        #tray_form
      ></app-drainage-trays-form>
    </div>
  </div>
</ion-content>

<footer
  *ngIf="!isDrainageTraysWithoutData && !isFetchingTitle"
  class="footer mt-auto px-4 py-3 bg-light-soft border-top-3 border-light border global-irrigation-save-button"
>
  <div
    *ngIf="!terminalData?.authed_user_can_write && saveButtonTimeElapsed"
    class="row"
  >
    <div class="col">
      <app-cannot-write-alert></app-cannot-write-alert>
    </div>
  </div>
  <div class="d-flex justify-content-end" *ngIf="!saveButtonTimeElapsed">
    <div class="spinner-border text-warning" role="status">
      <span class="visually-hidden">{{
        translationsLib.get('climate_loading')
      }}</span>
    </div>
  </div>
  <div
    *ngIf="terminalData?.authed_user_can_write && saveButtonTimeElapsed"
    class="row"
  >
    <div
      class="col irrigation-save-button"
      [ngClass]="{ 'p-0': !hasScrollbar }"
    >
      <button
        (click)="tray_form.submitForm()"
        [disabled]="
          !tray_form.traysForm.value.isDirty && !tray_form.traysForm.dirty
        "
        class="btn btn-primary float-end"
        [ngClass]="{
          'btn-danger':
            tray_form.traysForm.value.isDirty || tray_form.traysForm.dirty
        }"
      >
        <span
          *ngIf="tray_form.isFormSubmitted"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        {{
          tray_form.isFormSubmitted
            ? this.translationsLib.get('saving')
            : this.translationsLib.get('irrigation_save_drainage_tray')
        }}
      </button>
    </div>
  </div>
</footer>
