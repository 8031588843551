<div
  class="mb-4 d-flex justify-content-center mt-5"
  *ngIf="isFetchingData || !layoutConfiguration"
>
  <div class="spinner-border text-warning" role="status">
    <span class="visually-hidden">{{
      this.translationsLib.get('irrigation_loading')
    }}</span>
  </div>
</div>

<app-dirty-message
  [conditions]="layoutConfiguration && !isFetchingData"
  [dirty]="drainagesV4Form.value.dirty || isAnyDrainagesV4DirtyAfterPost"
></app-dirty-message>

<ng-container
  *ngIf="
    mode === 'simple' &&
    layoutConfiguration &&
    !isFetchingData &&
    !isDrainagesV4WithoutData
  "
>
  <form [formGroup]="drainagesV4Form" class="simple-form">
    <div class="mx-5 mb-2 w-100 mx-auto d-block">
      <div class="card">
        <div class="card-body custom-card-body">
          <div class="row align-items-center">
            <div class="col-sm-5 col-md-5 order-1 order-md-1">
              <h6 class="fs-2 input-label">
                {{ translationsLib.get('irrigation_drainages') }}
              </h6>
              <p class="fs-5 fw-lighter">
                {{
                  translationsLib.get('irrigation_drainage_help') | sanitizeText
                }}
              </p>
            </div>
            <div class="col-sm-7 col-md-7 order-3 order-md-2 col-inputs">
              <div class="row first-row align-items-center">
                <div class="col">
                  <!-- Bandeja de drenaje -->
                  <div
                    class="row"
                    *ngIf="
                      layoutConfiguration?.blocks.progdren_Band_Dren.fields
                        .progdrenajem3k_banddren.show
                    "
                  >
                    <div class="col-sm-4">
                      <h6 class="fs-5">
                        {{ translationsLib.get('irrigation_drainage_tray') }}
                      </h6>
                      <div class="input-group mb-2">
                        <select
                          id="drainingTraySimple"
                          class="form-select"
                          formControlName="drainingTray"
                        >
                          <option
                            *ngFor="let i of drainageTraysAsNumbersArray"
                            [value]="i"
                          >
                            {{
                              i === 255
                                ? translationsLib.get(
                                    'irrigation_change_drainage_tray'
                                  )
                                : translationsLib.get(
                                    'irrigation_drainage_tray'
                                  ) +
                                  ' ' +
                                  (i + 1)
                            }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <!-- Drenaje ideal -->
                    <div class="col-sm-4">
                      <h6 class="fs-5">
                        {{ translationsLib.get('irrigation_drainage_ideal') }}
                      </h6>
                      <div class="input-group mb-2 number-input">
                        <button
                          class="minus minus-style"
                          (click)="inputNumberService.decrementValue($event)"
                        ></button>
                        <input
                          id="idealDrainingSimple"
                          formControlName="idealDraining"
                          type="number"
                          class="form-control"
                          placeholder="{{
                            translationsLib.get('irrigation_drainage_ideal')
                          }}"
                          min="-9999"
                          max="9999"
                        />
                        <button
                          class="plus border-plus plus-style"
                          (click)="inputNumberService.incrementValue($event)"
                        ></button>
                      </div>
                    </div>

                    <!-- Radiación de corte segundos -->
                    <div class="col-sm-4">
                      <h6 class="fs-5">
                        {{
                          translationsLib.get(
                            'irrigation_drainage_threshold_radiation'
                          )
                        }}
                      </h6>
                      <div class="input-group mb-2 number-input">
                        <button
                          class="minus minus-style"
                          (click)="inputNumberService.decrementValue($event)"
                        ></button>
                        <input
                          id="cutRadiationSecondsSimple"
                          formControlName="cutRadiationSeconds"
                          type="number"
                          class="form-control"
                          placeholder="{{
                            translationsLib.get(
                              'irrigation_drainage_threshold_radiation'
                            )
                          }}"
                          min="-9999"
                          max="9999"
                        />
                        <button
                          class="plus border-plus plus-style"
                          (click)="inputNumberService.incrementValue($event)"
                        ></button>
                      </div>
                    </div>

                    <!--- Radiación de corte porcentaje -->
                    <div class="col-sm-4">
                      <h6 class="fs-5">Factor de corrección</h6>
                      <div class="input-group mb-2 number-input">
                        <button
                          class="minus minus-style"
                          (click)="inputNumberService.decrementValue($event)"
                        ></button>
                        <input
                          id="cutRadiationPercentSimple"
                          formControlName="cutRadiationPercent"
                          type="number"
                          class="form-control"
                          placeholder="Factor de corrección"
                          min="-9999"
                          max="9999"
                        />
                        <button
                          class="plus border-plus plus-style"
                          (click)="inputNumberService.incrementValue($event)"
                        ></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Correcciones de drenaje -->
      <div class="card">
        <div class="card-body custom-card-body">
          <div class="row">
            <div class="col-sm-5 col-md-5 order-1 order-md-1">
              <h6 class="fs-2">
                {{ translationsLib.get('irrigation_drainage_corrections') }}
              </h6>
              <p class="fs-5 fw-lighter">
                {{
                  translationsLib.get('irrigation_drainage_corrections_help')
                    | sanitizeText
                }}
              </p>
            </div>
            <div class="col-sm-7 col-md-7 order-3 order-md-2 col-inputs">
              <div class="row align-items-center">
                <div class="col">
                  <div class="row align-items-center justify-content-between">
                    <div class="col-sm-6">
                      <h6 class="fs-5">
                        {{ translationsLib.get('irrigation_keep_table') }}
                      </h6>
                    </div>
                    <div class="col-sm-6">
                      <div class="input-group">
                        <div class="form-check form-switch">
                          <input
                            id="keepTableSimple"
                            formControlName="keepTable"
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div
                        class="row"
                        *ngFor="let item of correctionKeys; let i = index"
                      >
                        <div class="col-12 col-md-6">
                          <h6 class="fs-5">
                            {{ translationsLib.get('irrigation_correction') }}
                            {{ i + 1 }}
                          </h6>
                          <div class="input-group mb-2 number-input">
                            <button
                              class="minus minus-style"
                              (click)="inputNumberService.decrementValue($event)"
                            ></button>
                            <input
                              id="{{ item.correction }}Simple"
                              formControlName="{{ item.correction }}"
                              type="number"
                              class="form-control"
                              placeholder="{{
                                translationsLib.get('irrigation_correction') +
                                  ' ' +
                                  item.correction
                              }}"
                              min="-9999"
                              max="9999"
                            />
                            <button
                              class="plus border-plus plus-style"
                              (click)="inputNumberService.incrementValue($event)"
                            ></button>
                          </div>
                        </div>
                        <div class="col-12 col-md-6">
                          <h6 class="fs-5">
                            {{ this.translationsLib.get('percentage') }}
                          </h6>
                          <div class="input-group mb-2 number-input">
                            <button
                              class="minus minus-style"
                              (click)="inputNumberService.decrementValue($event)"
                            ></button>
                            <input
                              id="{{ item.percentageCorrection }}Simple"
                              formControlName="{{ item.percentageCorrection }}"
                              type="number"
                              class="form-control"
                              placeholder="{{
                                translationsLib.get(
                                  'irrigation_percentage_correction'
                                ) +
                                  ' ' +
                                  item.correction
                              }}"
                              min="-9999"
                              max="9999"
                            />
                            <button
                              class="plus border-plus plus-style"
                              (click)="inputNumberService.incrementValue($event)"
                            ></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-container>

<ng-container
  *ngIf="
    mode === 'advanced' &&
    layoutConfiguration &&
    !isFetchingData &&
    !isDrainagesV4WithoutData
  "
>
  <form [formGroup]="drainagesV4Form" class="advanced-form">
    <div class="row row-cols-md-2">
      <div class="col-12 col-md-6">
        <div class="card">
          <div class="card-body custom-card-body">
            <div class="row">
              <div class="col-md-12">
                <h6 class="fs-2 input-label">
                  {{ this.translationsLib.get('irrigation_drainage') }}
                </h6>
              </div>
            </div>

            <!-- Bandeja de drenaje -->
            <div
              class="row"
              *ngIf="
                layoutConfiguration?.blocks.progdren_Band_Dren.fields
                  .progdrenajem3k_banddren.show
              "
            >
              <div class="col-lg-6">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_drainage_tray') }}
                </h6>
              </div>
              <div class="col-lg-6">
                <div class="input-group mb-2">
                  <select
                    class="form-select"
                    formControlName="drainingTray"
                    id="drainingTrayAdvanced"
                  >
                    <option
                      *ngFor="let i of drainageTraysAsNumbersArray"
                      [value]="i"
                    >
                      {{
                        i === 255
                          ? this.translationsLib.get(
                              'irrigation_change_drainage_tray'
                            )
                          : translationsLib.get('irrigation_drainage_tray') +
                            ' ' +
                            (i + 1)
                      }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <!-- Drenaje ideal -->
            <div class="row">
              <div class="col-lg-6">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_drainage_ideal') }}
                </h6>
              </div>
              <div class="col-lg-6">
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    id="idealDrainingAdvanced"
                    formControlName="idealDraining"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_drainage_ideal')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
            </div>

            <!-- Radiación de corte segundos -->
            <div class="row">
              <div class="col-lg-6">
                <h6 class="fs-5">
                  {{
                    translationsLib.get(
                      'irrigation_drainage_threshold_radiation'
                    )
                  }}
                </h6>
              </div>
              <div class="col-lg-6">
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    id="cutTradiationSecondsAdvanced"
                    formControlName="cutRadiationSeconds"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get(
                        'irrigation_drainage_threshold_radiation'
                      )
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
            </div>

            <!-- Radiación de corte porcentaje -->
            <div class="row">
              <div class="col-lg-6">
                <h6 class="fs-5">Factor de corrección</h6>
              </div>
              <div class="col-lg-6">
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    id="cutRadiationPercentAdvanced"
                    formControlName="cutRadiationPercent"
                    type="number"
                    class="form-control"
                    placeholder="Factor de corrección"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <!-- Correcciones de drenaje -->
        <div class="card">
          <div class="card-body custom-card-body">
            <div class="row">
              <div class="col-12">
                <h6 class="fs-2">
                  {{ translationsLib.get('irrigation_drainage_corrections') }}
                </h6>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row justify-content-between align-items-center">
                  <div class="col-6">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_keep_table') }}
                    </h6>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <div class="form-check form-switch">
                        <input
                          id="keepTableAdvanced"
                          formControlName="keepTable"
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row align-items-center">
                  <div class="col-12">
                    <div
                      class="row"
                      *ngFor="let item of correctionKeys; let i = index"
                    >
                      <div class="col-12 col-md-6">
                        <h6 class="fs-5">
                          {{ translationsLib.get('irrigation_correction') }}
                          {{ i + 1 }}
                        </h6>
                        <div class="input-group mb-2 number-input">
                          <button
                            class="minus minus-style"
                            (click)="inputNumberService.decrementValue($event)"
                          ></button>
                          <input
                            id="{{ item.correction }}Advanced"
                            formControlName="{{ item.correction }}"
                            type="number"
                            class="form-control"
                            placeholder="{{
                              translationsLib.get('irrigation_correction')
                            }}{{ ' ' + item.correction }}"
                            min="-9999"
                            max="9999"
                          />
                          <button
                            class="plus border-plus plus-style"
                            (click)="inputNumberService.incrementValue($event)"
                          ></button>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <h6 class="fs-5">
                          {{ this.translationsLib.get('percentage') }}
                        </h6>
                        <div class="input-group mb-2 number-input">
                          <button
                            class="minus minus-style"
                            (click)="inputNumberService.decrementValue($event)"
                          ></button>
                          <input
                            id="{{ item.percentageCorrection }}Advanced"
                            formControlName="{{ item.percentageCorrection }}"
                            type="number"
                            class="form-control"
                            placeholder="{{
                              translationsLib.get(
                                'irrigation_percentage_correction'
                              ) +
                                ' ' +
                                item.correction
                            }}"
                            min="-9999"
                            max="9999"
                          />
                          <button
                            class="plus border-plus plus-style"
                            (click)="inputNumberService.incrementValue($event)"
                          ></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-container>
