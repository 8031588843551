import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  AfterViewInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  TranslationsLibService,
  SessionLibService,
  UserIdentityDataModel,
} from '@nutricontrol/app360-shared';
import { PermissionsService } from '../../permissions.service';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { map, startWith } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-permissions-table-regular-users',
  templateUrl: './permissions-table-regular-users.component.html',
  styleUrls: ['./permissions-table-regular-users.component.scss'],
})
export class PermissionsTableRegularUsersComponent
  implements OnChanges, OnInit, AfterViewInit
{
  readonly READ_PERMISSION: number = 4;
  readonly WRITE_PERMISSION: number = 5;
  readonly ADMIN_PERMISSION: number = 3;
  @Input() terminalVid = '';
  @Input() canAdmin = false;
  @Input() addUser = false;
  @Input() mode = '';
  @Input() regularUsers: UserIdentityDataModel[] = [];
  @Input() contacts: UserIdentityDataModel[] = [];
  @Output() getPermissionsList: EventEmitter<any> = new EventEmitter();
  user: UserIdentityDataModel;
  nonInjectedRegularUsers: UserIdentityDataModel[] = [];
  nonInjectedRegularUsersLoading = false;
  sessionUserEmail = '';
  emailForm = new FormControl('');
  filteredOptions: Observable<string[]>;
  contactOptions = [];
  isMobile = false;
  constructor(
    public translationsLib: TranslationsLibService,
    private permissionService: PermissionsService,
    private sessionLib: SessionLibService,
    private breakpointObserver: BreakpointObserver,
    private toastController: ToastController
  ) {}

  ngOnInit() {
    this.addUser = this.addUser || false;
    this.regularUsers = this.regularUsers || [];

    this.sessionUserEmail = this.sessionLib.getSessionEmail();
    this.contacts.forEach((contact) => {
      this.contactOptions.push(contact.email);
    });

    this.filteredOptions = this.emailForm.valueChanges.pipe(
      startWith(''),
      map((value) => this.filter(value || ''))
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('regularUsers' in changes) {
      this.nonInjectedRegularUsersLoading = false;
      this.nonInjectedRegularUsers = [];
      this.getNonInjectedUsers();
    }
  }

  ngAfterViewInit(): void {
    this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .subscribe((result) => {
        this.isMobile = result.matches;
      });
  }

  filter(value) {
    const filterValue = value.toLowerCase();
    return this.contactOptions.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  changeUserPermission(user: UserIdentityDataModel, permission: number) {
    this.nonInjectedRegularUsersLoading = true;
    if (this.mode === 'field') {
      this.changeUserFieldPermission(user.email, permission);
    } else if (this.mode === 'terminal') {
      this.changeUserTerminalPermission(user.email, permission);
    }
  }

  getNonInjectedUsers() {
    this.regularUsers.forEach((regularUser) => {
      const injectionReason =
        regularUser.permission_regular_user.injection_reason || null;
      if (injectionReason === null) {
        this.nonInjectedRegularUsers.push(regularUser);
      }
    });
  }

  detachUserFrom(email) {
    if (this.mode === 'field') {
      this.detachUserFromField(email);
    } else if (this.mode === 'terminal') {
      this.detachUserFromTerminal(email);
    }
  }

  attachUserTo() {
    if (this.mode === 'field') {
      this.attachUserToField();
    } else if (this.mode === 'terminal') {
      this.attachUserToTerminal();
    }
  }

  private changeUserFieldPermission(email, value) {
    this.permissionService
      .changeUserFieldPermissions(email, this.terminalVid, {
        permission: value,
      })
      .subscribe(() => {
        this.getPermissionsList.emit();
      });
  }

  private changeUserTerminalPermission(email, value) {
    this.permissionService
      .changeUserTerminalPermissions(email, this.terminalVid, {
        permission: value,
      })
      .subscribe(() => {
        this.getPermissionsList.emit();
        let permissionTypeString = '';
        switch (value) {
          case this.READ_PERMISSION:
            permissionTypeString = 'permission_read_help';
            break;
          case this.WRITE_PERMISSION:
            permissionTypeString = 'permission_write_help';
            break;
          case this.ADMIN_PERMISSION:
            permissionTypeString = 'permission_admin_help';
            break;
        }
        this.presentToast(
          this.translationsLib.get(permissionTypeString) + '<br>' + email
        ).then();
      });
  }

  private attachUserToField() {
    this.addUser = false;
    this.permissionService
      .pairUsertoField(this.emailForm.value, this.terminalVid, {
        permission: 4,
      })
      .subscribe(
        () => {
          this.emailForm.reset();
          this.getPermissionsList.emit();
        },
        () => {
          Swal.fire({
            text: this.translationsLib.get('permission_cant_add_that_user'),
            icon: 'error',
            confirmButtonColor: '#224261',
          }).then();
        }
      );
  }

  private detachUserFromField(email) {
    this.permissionService
      .detachUserFromField(email, this.terminalVid)
      .subscribe(() => {
        if (email === this.sessionUserEmail) {
          window.location.href = '/';
        } else {
          this.getPermissionsList.emit();
        }
      });
  }

  private detachUserFromTerminal(email) {
    this.permissionService
      .detachUserFromTerminal(email, this.terminalVid)
      .subscribe(() => {
        if (email === this.sessionUserEmail) {
          window.location.href = '/';
        } else {
          this.getPermissionsList.emit();
        }
      });
  }

  private attachUserToTerminal() {
    this.addUser = false;
    this.permissionService
      .pairUsertoTerminal(this.emailForm.value, this.terminalVid, {
        permission: 4,
      })
      .subscribe(
        () => {
          this.emailForm.reset();
          this.getPermissionsList.emit();
        },
        () => {
          Swal.fire({
            text: this.translationsLib.get('permission_cant_add_that_user'),
            icon: 'error',
            confirmButtonColor: '#224261',
          }).then();
        }
      );
  }

  canShowUsers(): boolean {
    return (
      this.nonInjectedRegularUsersLoading === false &&
      this.nonInjectedRegularUsers.length > 0 &&
      this.addUser === false
    );
  }

  hasPermission(user: UserIdentityDataModel, permission: number): boolean {
    return user.permission_regular_user.permission === permission;
  }

  async presentToast(message: string, duration = 3000) {
    const toast = await this.toastController.create({
      message,
      duration,
    });

    await toast.present();
  }
}
