<app-main-header [breadcrumbs]="breadcrumbs"></app-main-header>
<ion-content>
  <div class="container-fluid p-1 p-lg-2">
    <div class="mx-lg-4">
      <div class="card mt-4">
        <div class="row m-3">
          <div class="col-12">
            <h2 class="form-label my-2">
              {{ this.translationsLib.get('field_create_title') }}
            </h2>
            <div class="header-img col-12 p-0 d-md-none">
              <img src="assets/img/covers/create_field_header_mobile.svg">
            </div>
            <div class="header-img col-12 p-0 d-none d-md-block">
              <img src="assets/img/covers/create_field_header.svg">
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mb-5">

        <div class="card p-2 map-edit-card">
          <div class="card-header p-2 p-lg-4">
            <h2 class="card-header-title text-uppercase">
              {{ translationsLib.get('field_create_your_field_step1') }}
            </h2>
          </div>
          <div class="card-body p-2 p-lg-4">
            <form [formGroup]="fieldForm">
              <div class="row justify-content-between">
                <div class="col-12 col-md-6">
                  <div class="form-group row">
                    <div class="col-12 col-md-6 col-xxl-8">
                      <label class="form-label">
                        {{ translationsLib.get('field_name') }}
                      </label>
                      <input
                        type="text"
                        id="nameInput"
                        class="form-control"
                        formControlName="name"
                      />
                    </div>
                    <div class="col-12 col-md-6 col-xxl-4 mt-4 mt-md-0 d-flex align-items-center justify-content-between d-md-block text-end">
                      <label class="form-label">
                        {{ translationsLib.get('field_color') }}
                      </label>
                      <div>
                        <input
                          type="color"
                          class="form-control d-inline-block bg-light"
                          formControlName="color"
                        />
                      </div>
                    </div>
                  </div>
                  <app-multicrop (updateMulticrops)="multicropsEvent($event)"></app-multicrop>
                  <h4 class="help-title">{{ translationsLib.get('field_define_area') }}</h4>
                  <div class="help-text" [innerHTML]="translationsLib.get('field_define_area_text')"></div>
                </div>
                <div class="col-12 col-md-6">
                  <div id="container" style="position: relative">
                    <!--<app-webapp-maps *ngIf="!isNative" [coming]="'new-field'" ></app-webapp-maps>
                    <app-native-maps *ngIf="isNative"  [coming]="'new-field'"></app-native-maps>-->
                    <lib-leaflet-maps [coming]="'new-field'" [colorField]="this.fieldForm.value.color" (fieldsNew)="coordinatesMap = $event"></lib-leaflet-maps>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>

        <swiper
          [preventInteractionOnTransition]="true"
          [preventClicksPropagation]="true"
          [slidesPerView]="1"
          [centeredSlides]="true"
          [keyboard]="{enabled: true}"
          [virtual]="true"
          [autoHeight]="true"
          (navigationNext)="'nextButton'"
          class="my-swiper" #swiper>

          <ng-template swiperSlide>
            <div class="swiper-slide-container">
              <div class="card p-2">
                <div class="card-header p-2 p-lg-4">
                  <h2 class="card-header-title text-uppercase">
                    {{ translationsLib.get('field_create_your_field_step2') }}
                  </h2>
                </div>
                <div class="card-body p-2 p-lg-4">
                  <div class="row">
                    <div class="col-md-6 col-12">
                      <h4 class="help-title">{{ translationsLib.get('field_attach_terminal') }}</h4>
                      <div class="help-text" [innerHTML]="translationsLib.get('field_attach_terminal_text')"></div>
                    </div>
                    <div class="col-md-6 col-12 mt-4">
                      <form>
                        <div class="form-group">
                          <mat-form-field appearance="fill" class="w-100">
                            <mat-label>{{ translationsLib.get('field_attach_terminal_choose') }}</mat-label>
                            <mat-select id="vidNonAttached" [formControl]="selectedNonAttachedTerminal">
                              <mat-option [value]="null"></mat-option>
                              <mat-option *ngFor="let term of nonAttached" [value]="term.vid">{{ term.name }}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="col-12 text-end">
                    <button class="btn btn-terciary" id='searchTerminal' (click)="searchPrePairingNonAttached()">
                      {{ translationsLib.get('search') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>

          <ng-template swiperSlide>
            <div class="card p-3" id="deviceInfo">
              <div class="row">
                <div class="col-2 mb-2 pt-2">
                  <img src="{{image_path}}" style="max-width: 80px">
                </div>
                <div class="col-10 mb-2">
                  <div class="row">
                    <div class="col-12">
                      <label class="form-label-title text-uppercase">
                        {{ translationsLib.get('terminal_model') }}: &nbsp;
                      </label>
                      <h4>{{results.value.nombre_equipo}}</h4>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <label class="form-label-title text-uppercase">
                        {{ translationsLib.get('terminal_serial_number') }}: &nbsp;
                      </label>
                      <h4>{{serialNumberRes}}</h4>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <label class="form-label-title text-uppercase">
                        {{ translationsLib.get('terminal_number') }}: &nbsp;
                      </label>
                      <h4>{{results.value.terminal}}</h4>
                    </div>
                  </div>
                </div>

                <div class="col-6 text-start">
                  <button class="btn btn-secondary" id="backButton" (click)="goToPreviousSlide();">
                    <ion-icon style="position: relative; top:3px" name="arrow-back-outline"></ion-icon>
                    {{translationsLib.get('back')}}
                  </button>
                </div>
                <div class="col-6 text-end">
                  <button class="btn btn-primary " id="saveDataButton" (click)="saveData()" *ngIf="terminalFound === true;" [disabled]="postingData">{{ translationsLib.get('save') }}</button>
                </div>
              </div>
            </div>
          </ng-template>
        </swiper>
      </div>
    </div>

    <footer class="bg-white p-1 p-lg-2 fixed-bottom pe-lg-4">
      <button class="btn btn-danger float-end mx-3 mx-md-5 p"(click)="cancelCreation()">{{translationsLib.get('fields_cancel_creation')}}</button>
    </footer>
  </div>
</ion-content>
