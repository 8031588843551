import { Component, Input, OnInit } from '@angular/core';
import {
  TranslationsLibService,
  TerminalDataModel,
} from '@nutricontrol/app360-shared';
import { ConfigurableEquipmentModel } from '../configurable.model';
import { NgIf } from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-configurable-states',
  templateUrl: './configurable-states.component.html',
  styleUrls: ['./configurable-states.component.scss'],
  imports: [NgIf],
})
export class ConfigurableStatesComponent implements OnInit {
  @Input() equipment: ConfigurableEquipmentModel;
  @Input() terminal: TerminalDataModel;

  constructor(public translationsLib: TranslationsLibService) {}

  ngOnInit(): void {}
}
