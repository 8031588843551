import { Component, OnInit } from '@angular/core';
import { PermissionsService } from '../../permissions/permissions.service';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { Browser } from '@capacitor/browser';

@Component({
  selector: 'app-support-screen',
  templateUrl: './support-screen.component.html',
  styleUrls: ['./support-screen.component.scss'],
})
export class SupportScreenComponent implements OnInit {
  breadcrumbs: { active: boolean; disabled: boolean; text: any; to: string }[];

  constructor(
    public translationsLib: TranslationsLibService,
    private permissionsService: PermissionsService
  ) {}

  ngOnInit(): void {
    this.breadcrumbs = [
      {
        text: this.translationsLib.get('support_title'),
        disabled: false,
        to: ' ',
        active: true,
      },
    ];
  }

  async showFaq() {
    await Browser.open({
      url: this.translationsLib.get('support_faq_url'),
    });
  }
}
