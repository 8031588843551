import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  HostListener,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Breadcrumb } from '../../shared/shared.model';
import { IrrigationService } from '../../irrigation.service';
import { HelpersLibService } from '../../../../services/libraries/helpers-lib.service';
import { FertilizerFormulasModel, SafeData } from '../../irrigation.model';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import {
  TranslationsLibService,
  TerminalDataModel,
} from '@nutricontrol/app360-shared';
import { EquipmentsLibService } from '../../../../services/libraries/equipments-lib.service';
import { IonContent } from '@ionic/angular';
import { environment } from '../../../../../environments/environment';
import { FertilizerFormulasFormComponent } from '../fertilizer-formulas-form/fertilizer-formulas-form.component';

@Component({
  selector: 'app-fertilizer-formulas-edit',
  templateUrl: './fertilizer-formulas-edit.component.html',
  styleUrls: ['./fertilizer-formulas-edit.component.scss'],
})
export class FertilizerFormulasEditComponent
  implements OnInit, AfterViewInit, SafeData, OnDestroy
{
  isFetchingData = false;
  formula: FertilizerFormulasModel;
  formulas: FertilizerFormulasModel[];
  formulasControl = new FormControl<string | FertilizerFormulasModel>('');
  filteredOptions: Observable<FertilizerFormulasModel[]>;
  isFormFieldWithErrors = false;

  mode = '';
  isFertilizerFormulasWithoutData = false;
  breadcrumbs: Breadcrumb[] = [];
  isFetchingEquipmentData = false;
  fertilizerFormulaValue: string;
  isDirty = false;

  fertilizer_formula_id: number;
  terminal_vid: string;
  isConnected: boolean;
  private intervalId: any;
  terminalData: TerminalDataModel;
  @ViewChild(IonContent, { static: false }) private content: IonContent;
  hasScrollbar = false;
  saveButtonTimeElapsed = false;
  @ViewChild(FertilizerFormulasFormComponent)
  fertilizerFormulasForm: FertilizerFormulasFormComponent;

  constructor(
    private router: Router,
    private irrigationService: IrrigationService,
    private helpersLib: HelpersLibService,
    private activatedRoute: ActivatedRoute,
    public translationsLib: TranslationsLibService,
    private equipmentLib: EquipmentsLibService
  ) {}

  /**
   * https://stackoverflow.com/questions/56882873/how-to-detect-if-ion-content-has-a-scrollbar/58579938#58579938
   * Apply CSS class based on vertical scroll (save button)
   */

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkForScrollbar();
  }

  async checkForScrollbar() {
    const scrollElement = await this.content.getScrollElement();
    this.hasScrollbar = scrollElement.scrollHeight > scrollElement.clientHeight;
  }

  isDataSaved(): boolean {
    return this.isDirty;
  }

  hasWritePermissions(): boolean {
    return this.terminalData.authed_user_can_write;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.saveButtonTimeElapsed = true;
      this.checkForScrollbar();
    }, 3000);

    this.activatedRoute.paramMap.subscribe((_) => {
      this.helpersLib.setTitle(
        '',
        `${this.translationsLib.get('irrigation_fertilizer_formula')} ${
          this.fertilizer_formula_id + 1
        }`,
        null
      );
    });
  }

  async ngOnInit() {
    const [, , vid, , , data] = this.router.url.split('/');
    this.terminal_vid = vid;
    this.fertilizer_formula_id = Number(data);

    this.filteredOptions = this.formulasControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const formula = typeof value === 'string' ? value : value?.formula;
        return formula
          ? this._filter(formula as string)
          : this.formulas.slice();
      })
    );

    await this.getFormulasList();
    await this.getIrrigationEquipment();
    await this.generateBreadcrumbs();

    this.intervalId = setInterval(async () => {
      await this.getIrrigationEquipment();

      if (!this.isConnected) {
        this.equipmentLib.showConnectivityAlert();
      }

      this.fertilizerFormulasForm.isFormSubmitted = false;
    }, environment.intervalDefaultTimeout);
  }

  getIrrigationEquipment(): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      this.irrigationService
        .getIrrigationTerminal(this.terminal_vid)
        .subscribe((res) => {
          this.isConnected = res.connected;

          resolve(true);
        });
    });
  }

  generateBreadcrumbs(): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      this.isFetchingEquipmentData = true;
      this.breadcrumbs = [];
      this.irrigationService.getTerminal(this.terminal_vid).subscribe((res) => {
        this.terminalData = res;
        if (res.field) {
          this.breadcrumbs.push({
            text: res.field.name,
            disabled: false,
            to: ['/field', { vid: res.field.vid }],
            active: false,
          });
        }

        this.breadcrumbs.push(
          {
            text: res.name,
            disabled: false,
            to: `/farming/${this.terminal_vid}/irrigation`,
            active: false,
          },
          {
            text: this.fertilizerFormulaValue
              ? this.fertilizerFormulaValue
              : `${this.translationsLib.get('irrigation_fertilizer_formula')} ${
                  this.fertilizer_formula_id + 1
                }`,
            disabled: false,
            active: true,
          }
        );

        this.isFetchingEquipmentData = false;
        resolve(true);
      });
    });
  }

  private _filter(formula: string): FertilizerFormulasModel[] {
    const filterValue = formula.toLowerCase();

    return this.formulas.filter((option) =>
      (
        this.translationsLib.get('irrigation_fertilizer_formula') +
        ' ' +
        (option.formula + 1).toString()
      )
        .toLowerCase()
        .includes(filterValue)
    );
  }

  onChangePagination(event) {
    this.router.navigate([
      `/farming/${this.terminal_vid}/irrigation/fertilizer_formula/${event.formula}`,
    ]);
  }

  autocompleteDisplayFn(formula: FertilizerFormulasModel) {
    return formula
      ? formula.name
        ? formula.name
        : formula.formula
        ? this.translationsLib.get('irrigation_fertilizer_formula') +
          ' ' +
          (formula.formula + 1)
        : ''
      : '';
  }

  updateFormulasList(formulas) {
    this.formulas = formulas;

    this.filteredOptions = this.formulasControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const formula = typeof value === 'string' ? value : value?.formula;
        return formula
          ? this._filter(formula as string)
          : this.formulas.slice();
      })
    );
  }

  getFormulasList(): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      this.isFetchingData = true;
      this.irrigationService
        .getFertilizerFormulas(this.terminal_vid)
        .subscribe((res) => {
          this.formulas = res;

          if (this.formulas.length === 0)
            this.isFertilizerFormulasWithoutData = true;

          this.isFetchingData = false;
          resolve(true);
        });
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }
}
