import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountComponent } from './account/account.component';
import { ConfigMenuComponent } from './config-menu/config-menu.component';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { TextAvatarModule } from '../text-avatar';
import { ColorPickerModule } from 'ngx-color-picker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppCommonsModule } from '../commons/app-commons.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ProfileTabsComponent } from './profile-tabs/profile-tabs.component';
import { MatTabsModule } from '@angular/material/tabs';
import { PermissionsModule } from '../permissions/permissions.module';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { AuthModule } from '../auth/auth.module';
import { OrganizationsModule } from '../organizations/organizations.module';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MyucropComponent } from './myucrop/myucrop.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { App360BillingSharedModule } from '@nutricontrol/app360-billing-shared';
import { App360SharedModule } from '@nutricontrol/app360-shared';

@NgModule({
  declarations: [
    AccountComponent,
    ConfigMenuComponent,
    ProfileTabsComponent,
    MyucropComponent,
    NotificationsComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    TextAvatarModule,
    ColorPickerModule,
    ReactiveFormsModule,
    AppCommonsModule,
    DragDropModule,
    MatTabsModule,
    PermissionsModule,
    MatStepperModule,
    MatIconModule,
    MatChipsModule,
    MatMenuModule,
    FormsModule,
    MatExpansionModule,
    AuthModule,
    OrganizationsModule,
    MatButtonModule,
    MatSelectModule,
    App360BillingSharedModule,
    App360SharedModule,
  ],
  exports: [AccountComponent],
})
export class ProfileModule {}
