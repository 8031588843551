<div class="mb-4 d-flex justify-content-center mt-5" *ngIf="isFetchingData">
  <div class="spinner-border text-warning" role="status">
    <span class="visually-hidden">{{
      this.translationsLib.get('irrigation_loading')
    }}</span>
  </div>
</div>

<app-dirty-message
  [conditions]="!isFetchingData"
  [dirty]="mixtureValvesForm.value.dirty"
></app-dirty-message>

<ng-container
  *ngIf="mode === 'simple'! && !isFetchingData && !isMixtureValvesWithoutData"
>
  <form [formGroup]="mixtureValvesForm" class="simple-form">
    <div class="card">
      <div class="card-body custom-card-body">
        <div class="row align-items-start">
          <div class="col-sm-5 col-md-5 order-1 order-md-1">
            <h6 class="fs-2">
              {{ translationsLib.get('irrigation_mixture_valves') }}
            </h6>
            <p class="fs-5 fw-lighter">
              {{
                translationsLib.get('irrigation_mixture_valves_help')
                  | sanitizeText
              }}
            </p>
          </div>
          <div class="col-sm-7 col-md-7 order-3 order-md-2 col-inputs">
            <div
              class="row first-row align-items-center justify-content-between"
            >
              <!-- Tiempo válvula -->
              <div class="col-12 col-md-6">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_mixture_valve_time') }}
                </h6>
                <div class="mb-2">
                  <ng-template #valveTimeTimePicker>
                    <timepicker
                      appOnlyNumbers
                      [showMeridian]="false"
                      [showSeconds]="true"
                      [minuteStep]="1"
                      [secondsStep]="1"
                      [formControlName]="'valveTime'"
                    ></timepicker>
                  </ng-template>
                  <input
                    appOnlyNumbers
                    [ngClass]="{
                      'ng-dirty': mixtureValvesForm.controls.valveTime.dirty
                    }"
                    class="form-control text-center"
                    type="text"
                    [popover]="valveTimeTimePicker"
                    [outsideClick]="true"
                    placement="bottom"
                    [value]="mixtureValvesForm.value.valveTime | inputTime"
                    (change)="
                      onChangeInputTimeWithKeyboard($event, 'valveTime')
                    "
                  />
                </div>
              </div>

              <!-- Tiempo de verificación -->
              <div class="col-12 col-md-6">
                <h6 class="fs-5">
                  {{
                    translationsLib.get(
                      'irrigation_mixture_valve_verification_time'
                    )
                  }}
                </h6>
                <div class="mb-2">
                  <ng-template #verificationTimeTimePicker>
                    <timepicker
                      appOnlyNumbers
                      [showMeridian]="false"
                      [showSeconds]="true"
                      [minuteStep]="1"
                      [secondsStep]="1"
                      [formControlName]="'verificationTime'"
                    ></timepicker>
                  </ng-template>
                  <input
                    appOnlyNumbers
                    [ngClass]="{
                      'ng-dirty':
                        mixtureValvesForm.controls.verificationTime.dirty
                    }"
                    class="form-control text-center"
                    type="text"
                    [popover]="verificationTimeTimePicker"
                    [outsideClick]="true"
                    placement="bottom"
                    [value]="
                      mixtureValvesForm.value.verificationTime | inputTime
                    "
                    (change)="
                      onChangeInputTimeWithKeyboard($event, 'verificationTime')
                    "
                  />
                </div>
              </div>
            </div>
            <!-- Pulso mínimo -->
            <div class="row align-items-center justify-content-between">
              <div class="col-12 col-md-4">
                <h6 class="fs-5">
                  {{
                    translationsLib.get('irrigation_mixture_valve_min_pulse')
                  }}
                </h6>
                <div class="mb-2">
                  <ng-template #minPulseTimePicker>
                    <timepicker
                      appOnlyNumbers
                      [showMeridian]="false"
                      [showSeconds]="true"
                      [minuteStep]="1"
                      [secondsStep]="1"
                      [formControlName]="'minPulse'"
                    ></timepicker>
                  </ng-template>
                  <input
                    appOnlyNumbers
                    [ngClass]="{
                      'ng-dirty': mixtureValvesForm.controls.minPulse.dirty
                    }"
                    class="form-control text-center"
                    type="text"
                    [popover]="minPulseTimePicker"
                    [outsideClick]="true"
                    placement="bottom"
                    [value]="mixtureValvesForm.value.minPulse | inputTime"
                    (change)="onChangeInputTimeWithKeyboard($event, 'minPulse')"
                  />
                </div>
              </div>
            </div>
            <!-- Factor de corrección -->
            <div class="row align-items-center justify-content-between">
              <div class="col-12 col-md-4">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_correction_factor') }}
                </h6>
                <div class="input-group mb-2 number-input">
                  <button
                    class="minus minus-style"
                    (click)="inputNumberService.decrementValue($event)"
                  ></button>
                  <input
                    formControlName="correctionFactor"
                    type="number"
                    class="form-control"
                    placeholder="{{
                      translationsLib.get('irrigation_correction_factor')
                    }}"
                    min="-9999"
                    max="9999"
                  />
                  <button
                    class="plus border-plus plus-style"
                    (click)="inputNumberService.incrementValue($event)"
                  ></button>
                </div>
              </div>
            </div>
            <!-- Sonda CE -->
            <div class="row align-items-center justify-content-between">
              <div class="col-12 col-md-4">
                <h6 class="fs-5">
                  {{ translationsLib.get('irrigation_ce_sensor') }}
                </h6>
                <div class="input-group mb-2">
                  <select
                    [(ngModel)]="selectedSensor"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-select"
                    (change)="onChangeSensor($event)"
                  >
                    <option [value]="255">-</option>
                    <ng-container *ngFor="let sensor of sensors">
                      <ng-container
                        *ngIf="
                          sensor.type === 0 ||
                          sensor.type === 7 ||
                          sensor.type === 16
                        "
                      >
                        <option [value]="sensor.id">{{ sensor.name }}</option>
                      </ng-container>
                    </ng-container>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-container>

<ng-container
  *ngIf="mode === 'advanced'! && !isFetchingData && !isMixtureValvesWithoutData"
>
  <form [formGroup]="mixtureValvesForm" class="advanced-form">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body custom-card-body">
            <div class="row">
              <div class="col-12">
                <h6 class="fs-2 input-label">
                  {{ translationsLib.get('irrigation_mixture_valves') }}
                </h6>
              </div>
            </div>
            <div class="row align-items-center">
              <!-- Tiempo válvula -->
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="row justify-content-between align-items-center">
                  <div class="col-md-6">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_mixture_valve_time') }}
                    </h6>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-2">
                      <ng-template #valveTimeTimePicker>
                        <timepicker
                          appOnlyNumbers
                          [showMeridian]="false"
                          [showSeconds]="true"
                          [minuteStep]="1"
                          [secondsStep]="1"
                          [formControlName]="'valveTime'"
                        ></timepicker>
                      </ng-template>
                      <input
                        appOnlyNumbers
                        [ngClass]="{
                          'ng-dirty': mixtureValvesForm.controls.valveTime.dirty
                        }"
                        class="form-control text-center"
                        type="text"
                        [popover]="valveTimeTimePicker"
                        [outsideClick]="true"
                        placement="bottom"
                        [value]="mixtureValvesForm.value.valveTime | inputTime"
                        (change)="
                          onChangeInputTimeWithKeyboard($event, 'valveTime')
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!-- Tiempo de verificación -->
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="row justify-content-between align-items-center">
                  <div class="col-md-6">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get(
                          'irrigation_mixture_valve_verification_time'
                        )
                      }}
                    </h6>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-2">
                      <ng-template #verificationTimeTimePicker>
                        <timepicker
                          appOnlyNumbers
                          [showMeridian]="false"
                          [showSeconds]="true"
                          [minuteStep]="1"
                          [secondsStep]="1"
                          [formControlName]="'verificationTime'"
                        ></timepicker>
                      </ng-template>
                      <input
                        appOnlyNumbers
                        [ngClass]="{
                          'ng-dirty':
                            mixtureValvesForm.controls.verificationTime.dirty
                        }"
                        class="form-control text-center"
                        type="text"
                        [popover]="verificationTimeTimePicker"
                        [outsideClick]="true"
                        placement="bottom"
                        [value]="
                          mixtureValvesForm.value.verificationTime | inputTime
                        "
                        (change)="
                          onChangeInputTimeWithKeyboard(
                            $event,
                            'verificationTime'
                          )
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center">
              <!-- Pulso mínimo -->
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="row justify-content-between align-items-center">
                  <div class="col-md-6">
                    <h6 class="fs-5">
                      {{
                        translationsLib.get(
                          'irrigation_mixture_valve_min_pulse'
                        )
                      }}
                    </h6>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-2">
                      <ng-template #minPulseTimePicker>
                        <timepicker
                          appOnlyNumbers
                          [showMeridian]="false"
                          [showSeconds]="true"
                          [minuteStep]="1"
                          [secondsStep]="1"
                          [formControlName]="'minPulse'"
                        ></timepicker>
                      </ng-template>
                      <input
                        appOnlyNumbers
                        [ngClass]="{
                          'ng-dirty': mixtureValvesForm.controls.minPulse.dirty
                        }"
                        class="form-control text-center"
                        type="text"
                        [popover]="minPulseTimePicker"
                        [outsideClick]="true"
                        placement="bottom"
                        [value]="mixtureValvesForm.value.minPulse | inputTime"
                        (change)="
                          onChangeInputTimeWithKeyboard($event, 'minPulse')
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center">
              <!-- Factor de corrección -->
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="row justify-content-between align-items-center">
                  <div class="col-md-6">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_correction_factor') }}
                    </h6>
                  </div>
                  <div class="col-md-6">
                    <div class="input-group mb-2 number-input">
                      <button
                        class="minus minus-style"
                        (click)="inputNumberService.decrementValue($event)"
                      ></button>
                      <input
                        formControlName="correctionFactor"
                        type="number"
                        class="form-control"
                        placeholder="{{
                          translationsLib.get('irrigation_correction_factor')
                        }}"
                        min="-9999"
                        max="9999"
                      />
                      <button
                        class="plus border-plus plus-style"
                        (click)="inputNumberService.incrementValue($event)"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center">
              <!-- Sonda CE -->
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="row justify-content-between align-items-center">
                  <div class="col-md-6">
                    <h6 class="fs-5">
                      {{ translationsLib.get('irrigation_ce_sensor') }}
                    </h6>
                  </div>
                  <div class="col-md-6">
                    <div class="input-group mb-2">
                      <select
                        [(ngModel)]="selectedSensor"
                        [ngModelOptions]="{ standalone: true }"
                        class="form-select"
                        (change)="onChangeSensor($event)"
                      >
                        <option [value]="255">-</option>
                        <ng-container *ngFor="let sensor of sensors">
                          <ng-container
                            *ngIf="
                              sensor.type === 0 ||
                              sensor.type === 7 ||
                              sensor.type === 16
                            "
                          >
                            <option [value]="sensor.id">
                              {{ sensor.name }}
                            </option>
                          </ng-container>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-container>
