import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { InputNumberService } from '../../../farming/libraries/input-number.service';

@Component({
  selector: 'app-week-days-selector',
  templateUrl: './week-days-selector.component.html',
  styleUrls: ['./week-days-selector.component.scss'],
})
export class WeekDaysSelectorComponent implements OnInit {
  @Input() form: UntypedFormGroup;

  dirtyWeek = false;

  constructor(
    public translationsLib: TranslationsLibService,
    public inputNumberService: InputNumberService
  ) {}

  ngOnInit(): void {
    if (!this.form.value.week) {
      this.form.patchValue({
        week: {
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
          sunday: false,
        },
      });
    }
  }

  checkWeekDay(event) {
    this.dirtyWeek = true;
    this.form.markAsDirty();
  }
}
