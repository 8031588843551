import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import { TerminalsService } from '../../terminals/terminals.service';
import { TerminalsLibService } from '../../services/libraries/terminals-lib.service';

@Component({
  selector: 'app-events-calendar-screen',
  templateUrl: './events-calendar-screen.component.html',
  styleUrls: ['./events-calendar-screen.component.scss'],
})
export class EventsCalendarScreenComponent implements OnInit {
  terminalVid: string;
  breadcrumbs: any = [];
  loading;
  constructor(
    private route: ActivatedRoute,
    private helpersLib: HelpersLibService,
    private terminalsLib: TerminalsLibService,
    private terminalsService: TerminalsService,
    public translationsLib: TranslationsLibService
  ) {}

  ngOnInit() {
    this.terminalVid = this.route.snapshot.paramMap.get('vid');
    this.loading = false;
    this.terminalsService
      .getTerminal(this.terminalVid)
      .subscribe((terminal) => {
        this.helpersLib.setTitle(
          this.translationsLib.get('calendar_calendar'),
          terminal.name,
          terminal.vid
        );
        setTimeout(() => {
          this.helpersLib.sendEventTerminalCalendarView(
            terminal.name,
            this.terminalVid
          );
          if (terminal.field) {
            this.breadcrumbs.push({
              text: terminal.field.name,
              disabled: false,
              to: ['/field', { vid: terminal.field_vid }],
              active: false,
            });
          }
          this.breadcrumbs.push({
            text: terminal.name,
            disabled: false,
            to: [this.terminalsLib.generateRoute(terminal.vid, terminal.type)],
            active: false,
          });
          this.breadcrumbs.push({
            text: this.translationsLib.get('calendar_calendar'),
            disabled: false,
            to: ' ',
            active: true,
          });
        }, 300);
      });
  }
}
