<!--
  TODO:
  The two tables are a temporary solution due to the necessity of
  responsive tables in mobile version.
 -->

<div
  class="my-4 d-flex justify-content-center"
  *ngIf="isFetchingData && sortedArray; else notFetching"
>
  <div class="spinner-border text-warning" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>

<ng-template #notFetching>
  <div class="card mt-4" [ngClass]="cardClass">
    <div class="input-group search-container">
      <div class="input-group-prepend">
        <span class="input-group-text search-icon"
          ><i class="bi bi-search"></i
        ></span>
      </div>
      <input
        class="form-control table-search-text"
        type="text"
        placeholder="{{ searchPlaceholder }}"
        [(ngModel)]="searchText"
      />
    </div>
    <div class="card-body fs-5 p-0 table-responsive">
      <div class="elements-table">
        <div
          class="table table-hover mb-0"
          style="display: table"
          *ngIf="name === 'programs' && isMobile; else notMobilePrograms"
        >
          <thead>
            <tr>
              <th
                #sortableHeader
                scope="col"
                class="to-lowercase fs-6 terminal-table-header fix-width arrow"
                *ngFor="let header of tableHeaders"
                [sortable]="header.name"
                (sort)="onSort($event)"
                [ngClass]="{
                  'wrap-word':
                    header.key === translationsLib.get('irrigation_groups'),
                  'responsive-terminal-table-header':
                    screenType === 'irrigation' ||
                    screenType === 'configurable',
                  'header-width':
                    screenType === 'irrigation' || screenType === 'configurable'
                }"
              >
                <div *ngIf="header.name !== 'date'" class="week-container">
                  <span
                    class="to-uppercase"
                    [ngClass]="{ 'date-span': header.name === 'date' }"
                    >{{ header.key }}
                    <span
                      *ngIf="
                        header.key === translationsLib.get('irrigation_volume')
                      "
                      style="text-transform: lowercase !important"
                    >
                      m3
                    </span>
                    <span
                      *ngIf="
                        header.key ===
                        translationsLib.get('irrigation_ws_idealCE')
                      "
                      style="text-transform: lowercase !important"
                    >
                      m<span style="text-transform: uppercase !important"
                        >S</span
                      >/cm
                    </span>
                  </span>
                  <div class="date-arrow-container"></div>
                </div>
                <div class="week-container" *ngIf="header.name === 'date'">
                  <div *ngIf="header.keys" class="header-keys-mobile">
                    <ng-container
                      *ngFor="let key of header.keys; let i = index"
                    >
                      <span class="to-uppercase">
                        {{ key }}
                      </span>
                    </ng-container>
                  </div>
                  <div class="date-arrow-container"></div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody
            class="fs-4"
            [ngClass]="{
              'responsive-body':
                screenType === 'irrigation' || screenType === 'configurable'
            }"
          >
            <ng-container
              *ngFor="
                let elem of sortedArray
                  | filterByPropertyIrrigation
                    : filterProperty
                    : searchText
                    : includeText
                  | paginate : { id: name, itemsPerPage: 10, currentPage: page }
              "
            >
              <ng-container
                *ngTemplateOutlet="dataTemplate; context: { $implicit: elem }"
              ></ng-container>
            </ng-container>
            <tr
              *ngIf="
                (
                  sortedArray
                  | filterByPropertyIrrigation
                    : filterProperty
                    : searchText
                    : includeText
                )?.length === 0
              "
            >
              <td
                colspan="5"
                class="terminal-table-data text-center"
                [ngClass]="{
                  'responsive-terminal-table-data':
                    screenType === 'irrigation' || screenType === 'configurable'
                }"
              >
                {{ translationsLib.get('no_results') }}
              </td>
            </tr>
          </tbody>
        </div>
        <ng-template #notMobilePrograms>
          <div class="table table-hover mb-0" style="display: table">
            <thead>
              <tr>
                <th
                  #sortableHeader
                  scope="col"
                  class="to-lowercase fs-6 terminal-table-header fix-width arrow"
                  *ngFor="let header of tableHeaders"
                  [sortable]="header.name"
                  (sort)="onSort($event)"
                  [ngClass]="{
                    'wrap-word':
                      header.key === translationsLib.get('irrigation_groups'),
                    'responsive-terminal-table-header':
                      screenType === 'irrigation' ||
                      screenType === 'configurable',
                    'header-width':
                      screenType === 'irrigation' ||
                      screenType === 'configurable'
                  }"
                >
                  <div *ngIf="header.name !== 'date'" class="week-container">
                    <span
                      class="to-uppercase"
                      [ngClass]="{ 'date-span': header.name === 'date' }"
                      >{{ header.key }}
                      <span
                        *ngIf="
                          header.key ===
                          translationsLib.get('irrigation_volume')
                        "
                        style="text-transform: lowercase !important"
                      >
                        m3
                      </span>
                      <span
                        *ngIf="
                          header.key ===
                          translationsLib.get('irrigation_ws_idealCE')
                        "
                        style="text-transform: lowercase !important"
                      >
                        m<span style="text-transform: uppercase !important"
                          >S</span
                        >/cm
                      </span>
                    </span>
                    <div class="date-arrow-container"></div>
                  </div>
                  <div class="week-container" *ngIf="header.name === 'date'">
                    <div *ngIf="header.keys" class="header-keys">
                      <ng-container
                        *ngFor="let key of header.keys; let i = index"
                      >
                        <span class="to-uppercase">
                          {{ key }}
                        </span>
                        <span *ngIf="i < header.keys.length - 1"> • </span>
                      </ng-container>
                    </div>
                    <div class="date-arrow-container"></div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody
              class="fs-4"
              [ngClass]="{
                'responsive-body':
                  screenType === 'irrigation' || screenType === 'configurable'
              }"
            >
              <ng-container
                *ngFor="
                  let elem of sortedArray
                    | filterByPropertyIrrigation
                      : filterProperty
                      : searchText
                      : includeText
                    | paginate
                      : { id: name, itemsPerPage: 10, currentPage: page }
                "
              >
                <ng-container
                  *ngTemplateOutlet="dataTemplate; context: { $implicit: elem }"
                ></ng-container>
              </ng-container>
              <tr
                *ngIf="
                  (
                    sortedArray
                    | filterByPropertyIrrigation
                      : filterProperty
                      : searchText
                      : includeText
                  )?.length === 0
                "
              >
                <td colspan="5" class="terminal-table-data text-center">
                  {{ translationsLib.get('no_results') }}
                </td>
              </tr>
            </tbody>
          </div>
        </ng-template>
        <pagination-controls
          id="{{ name }}"
          class="mt-4 text-center"
          (pageChange)="page = $event"
          previousLabel="{{ translationsLib.get('previous') }}"
          nextLabel="{{ translationsLib.get('next') }}"
          [responsive]="true"
        ></pagination-controls>
      </div>
    </div>
  </div>
</ng-template>
