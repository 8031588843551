<lib-loader *ngIf="!equipmentLoaded || !configLoaded"></lib-loader>

<div *ngIf="equipmentLoaded && configLoaded"
     class="card water-systems configurable-water-systems"
     [class]="class">

  <div class="d-flex align-items-center justify-content-between w-100">
    <div class="p-0 fs-5 fw-bold text-uppercase mb-0 m-3 me-0" style="color: var(--primary-color)">
      {{ translationsLib.get('irrigation_ws_watersystemState') }}
    </div>
    <div *ngIf="showLastConnection"
         class="p-0 m-3 ms-0 fs-6 text-muted">
      <div class="fw-bold">{{ translationsLib.get('terminal_last_connection') }}</div>
      <div class="text-center">{{ waterSystem.lastUpdate | date: 'dd/MM/yy HH:mm' }}</div>
    </div>
  </div>

  <ng-container>
    <ng-container *ngFor="let rowConfig of getFirstVisibleRows(fieldsConfigs.activeConfig, 3)">
      <ng-container [ngTemplateOutlet]="rowTemplate"
                    [ngTemplateOutletContext]="{ rowConfig: rowConfig }">
      </ng-container>
    </ng-container>

    <ng-container *ngIf="hasRemainingVisibleRows(fieldsConfigs.activeConfig, 3)">
      <ng-container *ngIf="showMore">
        <ng-container *ngFor="let rowConfig of getRemainingVisibleRows(fieldsConfigs.activeConfig, 3)">
          <ng-container [ngTemplateOutlet]="rowTemplate"
                        [ngTemplateOutletContext]="{ rowConfig: rowConfig }">
          </ng-container>
        </ng-container>
      </ng-container>

      <div class="see-more-container d-flex align-items-center justify-content-end w-100 mt-3 pe-3">
        <button (click)="toggleShowMore()"
                class="btn btn-sm btn-link p-0 d-flex align-items-center fw-bold text-uppercase">
          <ion-icon [name]="showMore ? 'remove-circle-outline' : 'add-circle-outline'"
                    class="me-2">
          </ion-icon>
          <span class="fs-5">
            {{ showMore ? translationsLib.get('climate_show_less') : translationsLib.get('view_more') }}
          </span>
        </button>
      </div>
    </ng-container>
  </ng-container>

</div>

<ng-template #rowTemplate let-rowConfig="rowConfig">
  <div *ngIf="isAnyFieldVisibleInRow(toRowConfig(rowConfig))"
       class="row m-0 mt-2 pb-3 px-2 w-100">
    <div class="grid-container d-grid gap-2 p-0">
      <ng-container *ngFor="let fieldConfig of toRowConfig(rowConfig)">
        <div *ngIf="waterSystem.fields[fieldConfig.field]?.visible"
             [style.grid-column]="'span ' + (isMobile ? fieldConfig.colXsSpan : fieldConfig.colSpan)"
             [style.grid-row]="'span ' + (isMobile ? fieldConfig.rowXsSpan : fieldConfig.rowSpan)"
             class="grid-item overflow-hidden h-100">

          <app-field-card *ngIf="waterSystem.fields[fieldConfig.field].type === 'card'"
                          [field]="waterSystem.fields[fieldConfig.field]"
                          [isMobile]="isMobile"
          ></app-field-card>

          <app-field-card-double *ngIf="waterSystem.fields[fieldConfig.field].type === 'cardDouble'"
                                 [field]="waterSystem.fields[fieldConfig.field]"
                                 [isMobile]="isMobile">
          </app-field-card-double>

          <app-field-card-triple *ngIf="waterSystem.fields[fieldConfig.field].type === 'cardTriple'"
                                 [field]="waterSystem.fields[fieldConfig.field]"
                                 [isMobile]="isMobile">
          </app-field-card-triple>

          <app-field-progress *ngIf="waterSystem.fields[fieldConfig.field].type === 'progressBar'"
                              [field]="waterSystem.fields[fieldConfig.field]"
                              [isMobile]="isMobile">
          </app-field-progress>

          <app-field-chips *ngIf="waterSystem.fields[fieldConfig.field].type === 'chips'"
                           [field]="waterSystem.fields[fieldConfig.field]"
                           [isMobile]="isMobile">
          </app-field-chips>

          <app-field-fertilizer-formula *ngIf="waterSystem.fields[fieldConfig.field].type === 'cardFertilizer'"
                                        [field]="waterSystem.fields[fieldConfig.field]"
                                        [isMobile]="isMobile">
          </app-field-fertilizer-formula>

        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
