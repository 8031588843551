import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { IrrigationService } from '../../../irrigation.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import {
  removeDirtyInputs,
  saveDataAndShowModal,
  saveSubmitWithTerminalStatus,
} from '../../../../../commons/helpers/functions';
import { DrainageTrayModel, Progdren } from '../../../irrigation.model';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { EquipmentsLibService } from '../../../../../services/libraries/equipments-lib.service';
import { FormFieldHandlerService } from '../../../../libraries/form-field-handler.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { InputNumberService } from '../../../../libraries/input-number.service';

@Component({
  selector: 'app-drainages-v4-form',
  templateUrl: './drainages-v4-form.component.html',
  styleUrls: ['./drainages-v4-form.component.scss'],
})
export class DrainagesV4FormComponent implements OnInit, OnDestroy {
  isFetchingData = false;
  isFormSubmitted = false;
  isConnected: boolean;
  private intervalId: any;
  private formFieldErrorSubscription: Subscription;
  drainagesV4Form: UntypedFormGroup;
  selectedPagination;
  drainagesV4Response;
  drainageTraysList: DrainageTrayModel[];
  isDrainagesV4WithoutData = false;
  correctionKeys: any[];

  isAnyDrainagesV4DirtyAfterPost = false;

  isErrorInDrainagesV4Post = false;

  drainageTraysAsNumbersArray: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 255];

  layoutConfiguration: Progdren;
  @Input() mode: string;
  @Input() terminal_vid: string;
  @Input() drainage_v4_id: number;
  @Output() dirtyEventEmitter = new EventEmitter<boolean>();
  @Output() nameEventEmitter = new EventEmitter<number>();
  @Output() drainageEventEmitter = new EventEmitter<any>();
  @Output() formFieldErrorEventEmitter = new EventEmitter<boolean>();

  constructor(
    private fb: UntypedFormBuilder,
    private irrigationService: IrrigationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public translationsLib: TranslationsLibService,
    private equipmentLib: EquipmentsLibService,
    private formFieldHandlerService: FormFieldHandlerService,
    public inputNumberService: InputNumberService
  ) {
    this.drainagesV4Form = this.fb.group({
      drainage: [null],
      dirty: [null],
      drainingTray: [null, Validators.required],
      idealDraining: [null, Validators.required],
      keepTable: [null, Validators.required],
      correction0: [null, Validators.required],
      percentageCorrection0: [null, Validators.required],
      correction1: [null, Validators.required],
      percentageCorrection1: [null, Validators.required],
      correction2: [null, Validators.required],
      percentageCorrection2: [null, Validators.required],
      correction3: [null, Validators.required],
      percentageCorrection3: [null, Validators.required],
      correction4: [null, Validators.required],
      percentageCorrection4: [null, Validators.required],
      correction5: [null, Validators.required],
      percentageCorrection5: [null, Validators.required],
      correction6: [null, Validators.required],
      percentageCorrection6: [null, Validators.required],
      correction7: [null, Validators.required],
      percentageCorrection7: [null, Validators.required],
      cutRadiationSeconds: [null, Validators.required],
      cutRadiationPercent: [null, Validators.required],
      isDirty: false,
    });
  }

  getCorrectionKeys() {
    const keys = Object.keys(this.drainagesV4Form.controls);
    const correctionKeys = keys.filter((key) => key.startsWith('correction'));
    return correctionKeys.map((key) => {
      const index = key.replace('correction', '');
      return {
        correction: key,
        percentageCorrection: 'percentageCorrection' + index,
      };
    });
  }

  async startInterval() {
    this.intervalId = setInterval(() => {
      this.performTasks();
    }, environment.intervalDefaultTimeout);
  }

  async performTasks() {
    this.drainagesV4Form.reset();

    this.getDrainagesV4List();
    await this.getLayoutConfiguration();

    this.getIrrigationEquipment();

    if (!this.isConnected) {
      this.equipmentLib.showConnectivityAlert();
    }

    this.isFormSubmitted = false;
  }

  ngOnInit() {
    this.drainagesV4Form.reset(); // Prevent unsaved data to persist in form inputs
    this.correctionKeys = this.getCorrectionKeys();

    this.activatedRoute.params.subscribe(async (_) => {
      this.getIrrigationEquipment();
      await this.getLayoutConfiguration();
      this.getDrainagesV4List();

      removeDirtyInputs(this.drainagesV4Form);
    });

    this.drainagesV4Form.valueChanges.subscribe((_) => {
      this.dirtyEventEmitter.emit(this.getIsDirty());
      if (this.getIsDirty()) {
        clearInterval(this.intervalId);
      } else {
        clearInterval(this.intervalId);
        this.startInterval();
      }
    });
  }

  getIrrigationEquipment() {
    this.irrigationService
      .getIrrigationTerminal(this.terminal_vid)
      .subscribe((res) => {
        this.isConnected = res.connected;
      });
  }

  getLayoutConfiguration(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.irrigationService
        .getTerminalLayoutConfiguration(this.terminal_vid)
        .subscribe((res) => {
          this.layoutConfiguration = res.screens_configuration.progdren;

          if (!this.layoutConfiguration.enabled) {
            this.router.navigate(['/home/dashboard']);
          }

          resolve(true);
        });
    });
  }

  setDirty(dirty: boolean) {
    this.drainagesV4Form.patchValue({ isDirty: dirty });
  }

  getIsDirty() {
    return this.drainagesV4Form.dirty || this.drainagesV4Form.value.isDirty;
  }

  getDrainageTraysList(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.irrigationService
        .getDrainageTrays(this.terminal_vid)
        .subscribe((res) => {
          this.drainageTraysList = res;

          this.drainageTraysList = this.drainageTraysList.slice(
            0,
            this.layoutConfiguration.blocks.progdren_Band_Dren.fields
              .progdrenajem3k_banddren.max
          );

          resolve(true);
        });
    });
  }

  getDrainagesV4List(isGetAfterPost = false) {
    if (!isGetAfterPost) this.isFetchingData = true;

    this.irrigationService.getDrainages(this.terminal_vid).subscribe((res) => {
      // @ts-ignore
      if (res.drainages.length === 0) {
        this.isDrainagesV4WithoutData = true;
        this.isFetchingData = false;
      } else {
        if (res.version === 4 && this.layoutConfiguration.enabled) {
          this.drainagesV4Response = res.drainages;

          this.isAnyDrainagesV4DirtyAfterPost = this.drainagesV4Response.some(
            (drainage) => drainage.dirty === true
          );

          this.drainagesV4Response.filter((drainage) => {
            if (drainage.drainage === this.drainage_v4_id) {
              this.drainageEventEmitter.emit(drainage);

              this.nameEventEmitter.emit(drainage.drainage);
              this.selectedPagination = drainage.drainage;

              this.drainagesV4Form.patchValue({
                drainage: drainage.drainage,
                dirty: drainage.dirty,
                drainingTray: drainage.drainingTray,
                idealDraining: drainage.idealDraining,
                keepTable: drainage.keepTable,
                correction0: drainage.correction0,
                percentageCorrection0: drainage.percentageCorrection0,
                correction1: drainage.correction1,
                percentageCorrection1: drainage.percentageCorrection1,
                correction2: drainage.correction2,
                percentageCorrection2: drainage.percentageCorrection2,
                correction3: drainage.correction3,
                percentageCorrection3: drainage.percentageCorrection3,
                correction4: drainage.correction4,
                percentageCorrection4: drainage.percentageCorrection4,
                correction5: drainage.correction5,
                percentageCorrection5: drainage.percentageCorrection5,
                correction6: drainage.correction6,
                percentageCorrection6: drainage.percentageCorrection6,
                correction7: drainage.correction7,
                percentageCorrection7: drainage.percentageCorrection7,
                cutRadiationSeconds: drainage.cutRadiationSeconds,
                cutRadiationPercent: drainage.cutRadiationPercent,
              });

              if (!isGetAfterPost) this.isFetchingData = false;
            }
          });
        } else {
          if (!isGetAfterPost) this.isFetchingData = false;
        }
      }
    });
  }

  confirmSave() {
    Swal.fire({
      title: this.translationsLib.get('cannot_be_undone1'),
      text: this.translationsLib.get('irrigation_program_context_warning'),
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: this.translationsLib.get('accept'),
      denyButtonText: this.translationsLib.get('cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.submitForm();
      } else if (result.isDenied) {
        Swal.fire(this.translationsLib.get('something_was_wrong'), '', 'info');
      }
    });
  }

  handlePost(showModal: boolean, connected: boolean) {
    return new Promise<boolean>((resolve, _) => {
      this.isFormSubmitted = true;

      const drainagesV4FormAsArray = [];
      drainagesV4FormAsArray.push({
        drainage: this.drainagesV4Form.value.drainage,
        drainingTray: Number(this.drainagesV4Form.value.drainingTray),
        idealDraining: this.drainagesV4Form.value.idealDraining,
        keepTable: this.drainagesV4Form.value.keepTable,
        correction0: this.drainagesV4Form.value.correction0,
        percentageCorrection0: this.drainagesV4Form.value.percentageCorrection0,
        correction1: this.drainagesV4Form.value.correction1,
        percentageCorrection1: this.drainagesV4Form.value.percentageCorrection1,
        correction2: this.drainagesV4Form.value.correction2,
        percentageCorrection2: this.drainagesV4Form.value.percentageCorrection2,
        correction3: this.drainagesV4Form.value.correction3,
        percentageCorrection3: this.drainagesV4Form.value.percentageCorrection3,
        correction4: this.drainagesV4Form.value.correction4,
        percentageCorrection4: this.drainagesV4Form.value.percentageCorrection4,
        correction5: this.drainagesV4Form.value.correction5,
        percentageCorrection5: this.drainagesV4Form.value.percentageCorrection5,
        correction6: this.drainagesV4Form.value.correction6,
        percentageCorrection6: this.drainagesV4Form.value.percentageCorrection6,
        correction7: this.drainagesV4Form.value.correction7,
        percentageCorrection7: this.drainagesV4Form.value.percentageCorrection7,
        cutRadiationSeconds: this.drainagesV4Form.value.cutRadiationSeconds,
        cutRadiationPercent: this.drainagesV4Form.value.cutRadiationPercent,
      });

      try {
        this.irrigationService
          .postDrainagesV4(this.terminal_vid, drainagesV4FormAsArray)
          .subscribe(
            (res) => {
              if (res.error) this.isErrorInDrainagesV4Post = true;

              if (
                connected &&
                !this.drainagesV4Form.value.dirty &&
                !this.isErrorInDrainagesV4Post
              ) {
                saveDataAndShowModal(
                  res,
                  showModal,
                  this.translationsLib.get('error_updating_data'),
                  this.translationsLib.get('save_changes_success'),
                  this.translationsLib.get('accept'),
                  () => {
                    setTimeout(() => {
                      this.getDrainagesV4List(true);
                    }, 4000);
                  }
                );

                this.isFormSubmitted = false;
                resolve(true);
              } else if (this.isErrorInDrainagesV4Post) {
                Swal.fire({
                  text: this.translationsLib.get(
                    'irrigation_general_program_error'
                  ),
                  showConfirmButton: true,
                  confirmButtonText: this.translationsLib.get('accept'),
                  icon: 'error',
                });

                this.isFormSubmitted = false;
                resolve(true);
              } else {
                saveSubmitWithTerminalStatus(
                  res,
                  showModal,
                  connected,
                  this.translationsLib.get('error_updating_data'),
                  this.translationsLib.get('save_changes_success'),
                  this.translationsLib.get('accept'),
                  this.translationsLib.get(
                    'irrigation_disconnected_terminal_pending_changes'
                  )
                );

                this.isFormSubmitted = false;
                resolve(true);
              }
            },
            (_) => {
              if (showModal) {
                Swal.fire({
                  text: this.translationsLib.get('something_was_wrong'),
                  showConfirmButton: true,
                  confirmButtonText: this.translationsLib.get('accept'),
                  icon: 'error',
                });
              }
              this.isFormSubmitted = false;
              resolve(false);
            }
          );
      } catch (err) {
        throw new Error(err.message);
      }
    });
  }

  submitForm(
    showModal: boolean = true
  ): Promise<{ result: boolean; connected: boolean }> {
    return new Promise<{ result: boolean; connected: boolean }>(
      (resolve, reject) => {
        try {
          this.irrigationService
            .getIrrigationTerminal(this.terminal_vid)
            .subscribe((res) => {
              this.handlePost(showModal, res.connected).then((result) => {
                resolve({ result, connected: res.connected });
              });
            });
        } catch (err) {
          reject({ result: false, connected: false });
        } finally {
          // Remove dirty inputs due to use standalone ngModels
          removeDirtyInputs(this.drainagesV4Form);

          this.drainagesV4Form.markAsUntouched();

          this.drainagesV4Form.markAsPristine();
          this.drainagesV4Form.patchValue({ isDirty: false });
        }
      }
    );
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
    this.formFieldErrorSubscription?.unsubscribe();
  }
}
