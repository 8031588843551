<div class="card text-center mt-4" [id]="stringId" style="cursor: pointer" (click)="this.router.navigate(['./weather', {vid: this.vid}]);">
  <lib-loader *ngIf="!weatherLoaded"></lib-loader>
  <ng-container *ngIf="weatherLoaded">
    <img src="{{weatherLib.getIcon(weather.weather_id, moment)}}" class="icon position-absolute top-50 start-0 translate-middle-y">
    <div class="row align-items-center">
      <div class="col-12 col-md-6 col-xl-5 col-xxl-4 text-center align-self-center" >
        <div class="row align-items-center">
          <div class="col-6">
            <div class="row">
              <div class="col-3"></div>
              <div class="col-9 px-4 widget-shadow">
                <h4 class="skyStatus text-end mt-0">{{this.weather.weather_description}}</h4>
                <h1 class="temp text-end pt-2 mb-0">{{this.weather.main_temp}}º</h1>
              </div>
            </div>
          </div>
          <div class="col-6 sensor-block widget-shadow my-2 my-md-0 text-start d-flex justify-content-center align-items-center" style="color: white">
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-auto text-end pe-0" [innerHTML]="'NAVIGATE' | sensorIcon">
                  </div>
                  <div class="col text-start ps-1">
                    <span class="lh-1">&nbsp;
                    <ng-container *ngIf="weather_station === null">
                      {{ this.weather.weather_station_cid }}
                    </ng-container>
                    <ng-container *ngIf="weather_station !== null">
                      {{ this.weather.weather_station.name }}
                    </ng-container>
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-auto text-end pe-0" [innerHTML]="'TEMPERATURE_OUTLINE' | sensorIcon">
                  </div>
                  <div class="col text-start ps-1">
                    <span>&nbsp;Min. {{weather.main_temp_min}}º | Max. {{weather.main_temp_max}}º</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-auto text-end pe-0">
                    <span [innerHTML]="'WIND' | sensorIcon"></span>
                  </div>
                  <div class="col text-start ps-1">
                    <span>&nbsp;{{ this.translationsLib.get('climate_wind') }}: {{weather.wind_speed}} Km/h</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-auto text-end pe-0" [innerHTML]="'HUMIDITY_OUTLINE'| sensorIcon">
                  </div>
                  <div class="col text-start ps-1">
                    <span>&nbsp;{{ this.translationsLib.get('climate_hum') }}:  {{weather.main_humidity}} %</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-7 col-xxl-8 d-none d-md-block">
        <div class="spinner-border text-gray-700" *ngIf="forecastLoaded === false" role="status"></div>
        <app-meteogram-small [forecast]="forecast" *ngIf="forecastLoaded === true"></app-meteogram-small>
      </div>
    </div>
  </ng-container>
</div>
