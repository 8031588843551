<app-main-header
  [name]="translationsLib.get('support_title')"
  [breadcrumbs]="breadcrumbs"
></app-main-header>
<ion-content>
  <div class="container-fluid p-1 p-lg-2">
    <div class="mx-lg-4">
      <div class="m-4">
        <img src="../../../assets/img/support/support_header.svg" class="header-img">
      </div>
      <div class="col-12">
        <div class="card">
          <div class="card-body px-2 px-sm-4">
            <div class="row mb-6">
              <div class="col-12">
                <div class="h2 text-center">
                  {{ translationsLib.get('support_help_header') }}
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-xl-4 d-flex">
                <div class="card card-hours flex-fill">
                  <img src="../../../assets/img/support/icon_clock.svg" class="card-img-top">
                  <div class="card-body p-5 text-center d-flex flex-column justify-content-between">
                    <h1 class="mt-0 text-uppercase">{{ translationsLib.get('support_help_card_1_title') }}</h1>
                    <p class="card-text m-0">{{ translationsLib.get('support_help_card_1_text_1') }}</p>
                    <h2 class="my-3">{{ translationsLib.get('support_help_card_1_text_2') }}</h2>
                    <p class="card-text m-0">{{ translationsLib.get('support_help_card_1_text_3') }}</p>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 d-flex mt-6 mt-xl-0">
                <div class="card card-mail flex-fill">
                  <img src="../../../assets/img/support/icon_email.svg" class="card-img-top">
                  <div class="card-body p-5 text-center d-flex flex-column justify-content-between">
                    <h1 class="mt-0 text-uppercase">{{ translationsLib.get('support_help_card_2_title') }}</h1>
                    <p class="card-text m-0">{{ translationsLib.get('support_help_card_2_text_1') }}</p>
                    <h2 class="my-3"><a target="_blank" [href]="'mailto:'+translationsLib.get('support_email')">{{ translationsLib.get('support_email') }}</a></h2>
                    <p class="card-text m-0">{{ translationsLib.get('support_help_card_2_text_2') }}</p>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 d-flex mt-6 mt-xl-0">
                <div class="card card-faq flex-fill">
                  <img src="../../../assets/img/support/icon_faq.svg" class="card-img-top">
                  <div class="card-body p-5 text-center d-flex flex-column justify-content-between">
                    <h1 class="mt-0 text-uppercase">{{ translationsLib.get('support_help_card_3_title') }}</h1>
                    <p class="card-text m-0">{{ translationsLib.get('support_help_card_3_text_1') }}</p>
                    <h2 class="my-3"><a style="cursor: pointer" (click)="showFaq()">{{ translationsLib.get('support_help_card_3_text_2') }}</a></h2>
                    <p class="card-text m-0">{{ translationsLib.get('support_help_card_3_text_3') }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="h2 text-center">
                {{ translationsLib.get('support_help_footer') }}
              </div>
            </div>
            <div class="row">
              <div class="text-end">
                <a class="btn btn-warning" target="_blank"
                   [href]="'mailto:'+translationsLib.get('support_email')">{{ translationsLib.get('support_send_email') }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</ion-content>
