import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { GroupsDataModel } from '../irrigation.model';
import { IrrigationService } from '../irrigation.service';
import { Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { GroupsFormComponent } from './groups-form/groups-form.component';
import { TranslationsLibService } from '@nutricontrol/app360-shared';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss'],
})
export class GroupsComponent implements OnInit {
  isFetchingData = false;
  groups: GroupsDataModel[];
  pagination = 0;
  paginationTotalElements = 0;
  group_id: number;

  groupControl = new FormControl<string | GroupsDataModel>('');
  filteredOptions: Observable<GroupsDataModel[]>;
  @Input() mode: string;
  @Input() terminal_vid: string;
  @Input() groupsList: GroupsDataModel[];
  @Input() showAll: boolean;
  @Output() dirtyEventEmitter = new EventEmitter<boolean>();
  @Output() updatedGroupsEventEmitter = new EventEmitter<GroupsDataModel[]>();
  @Input() isProgramsScreen = false;

  @ViewChild(GroupsFormComponent) groupsFormComponent: GroupsFormComponent;

  @Output() clearIntervalEventEmitter = new EventEmitter<boolean>();

  constructor(
    private irrigationService: IrrigationService,
    public translationsLib: TranslationsLibService
  ) {}

  async ngOnInit() {
    this.getGroupsList(this.pagination);

    this.filteredOptions = this.groupControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const group = typeof value === 'string' ? value : value?.name;
        return group ? this._filter(group as string) : this.groups.slice();
      }),
      tap(() =>
        this.groupControl.setValue(this.findGroup(), {
          emitEvent: false,
        })
      )
    );
  }

  handleClearInterval(event: boolean) {
    this.clearIntervalEventEmitter.emit(event);
  }

  async submitGroupForm(
    showModal: boolean = true
  ): Promise<{ result: boolean; connected: boolean; error: boolean }> {
    return await this.groupsFormComponent.submitForm(showModal);
  }

  onChangeGroupDirty(event) {
    this.dirtyEventEmitter.emit(event);
  }

  onChangeGroupList(event) {
    this.updatedGroupsEventEmitter.emit(event);
  }

  private _filter(group: string): GroupsDataModel[] {
    const filterValue = group.toLowerCase();

    return this.groups.filter((option) =>
      option.name?.toLowerCase().includes(filterValue)
    );
  }

  autocompleteDisplayFn = (group: GroupsDataModel) => {
    return group
      ? group.name
        ? group.name
        : this.translationsLib.get('irrigation_group') + ' ' + (group.group + 1)
      : '';
  };

  onChangePagination(event) {
    this.pagination = event?.option?.value || event;
    this.getGroupsList(this.pagination);
  }

  updateGroupsList(groups) {
    this.groupsList = groups;
    this.getGroupsList(this.pagination);
  }

  findGroup() {
    let group = this.groupsList.find(
      // @ts-ignore
      (option) => option.group === this.pagination
    );
    if (group === undefined) {
      this.pagination = 0;
      group = this.groupsList[0];
    }
    return group;
  }

  getGroupsList(pagination: number) {
    this.isFetchingData = true;

    if (this.showAll) {
      this.irrigationService
        .getIrrigationGroups(this.terminal_vid)
        .subscribe((res) => {
          this.paginationTotalElements = Number(res.length + '0');
          this.groups = res;
          this.group_id = res[pagination].group;
          this.isFetchingData = false;
        });
    } else {
      setTimeout(() => {
        this.groups = this.groupsList;
        this.paginationTotalElements = Number(this.groupsList.length + '0');

        const group = this.findGroup();
        this.group_id = group ? group.group : null;

        this.isFetchingData = false;
      }, 200);
    }
  }
}
