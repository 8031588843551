import { Injectable } from '@angular/core';
import { WaterSystemsUtilsService } from './water-systems-utils.service';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import {
  DrainageV3,
  DrainageV4,
  Fertilizer,
  FertilizerFormulasModel,
  GroupsDataModel,
  IrrigationEquipmentProgram,
  IrrigationEquipmentWaterSystem,
  MixtureFormulasModel,
} from '../../../../farming/irrigation/irrigation.model';

@Injectable({
  providedIn: 'root',
})
export class WaterSystemsService {
  constructor(
    private waterSystemsUtilsService: WaterSystemsUtilsService,
    private translationsLib: TranslationsLibService
  ) {}

  public waterSystemIsEnabled(
    waterSystem: IrrigationEquipmentWaterSystem,
    programsStates: IrrigationEquipmentProgram[]
  ): boolean {
    const activeProgramState = programsStates.find((programState) => {
      return programState.waterSystem === waterSystem.waterSystem;
    });
    return (
      !(waterSystem.OutEC === -1 && waterSystem.readPH === -1) ||
      activeProgramState !== undefined
    );
  }

  public waterSystemHasActiveProgram(
    waterSystem: IrrigationEquipmentWaterSystem,
    programsStates: IrrigationEquipmentProgram[]
  ): boolean {
    const activeProgramState = programsStates.find((programState) => {
      return programState.waterSystem === waterSystem.waterSystem;
    });
    return activeProgramState !== undefined;
  }

  public getBadgeLabel(
    waterSystem: IrrigationEquipmentWaterSystem,
    programsStates: IrrigationEquipmentProgram[]
  ): string {
    const program = this.getWaterSystemProgramState(
      waterSystem,
      programsStates
    );

    if (program) {
      const programState = program.state.toLowerCase();
      return `${program.name} - ${this.translationsLib.get(
        'irrigation_state_' + programState
      )}`;
    }
    this.waterSystemIsEnabled(waterSystem, programsStates)
    return 'Sin programa activo';
  }

  public getWaterSystemProgramState(
    waterSystem: IrrigationEquipmentWaterSystem,
    programs: IrrigationEquipmentProgram[]
  ): IrrigationEquipmentProgram | undefined {
    return programs.find((program) => {
      return program.waterSystem === waterSystem.waterSystem;
    });
  }

  public getWaterSystemDrainage(
    waterSystem: IrrigationEquipmentWaterSystem,
    mixtureFormulas: MixtureFormulasModel[],
    drainages: (DrainageV3 | DrainageV4)[]
  ): (DrainageV3 | DrainageV4) | undefined {
    const mixtureFormula = this.getWaterSystemMixtureFormula(
      waterSystem,
      mixtureFormulas
    );
    if (!mixtureFormula) {
      return undefined;
    }
    return drainages.find((drainage) => {
      return (mixtureFormula.drainageSource = drainage.drainage);
    });
  }

  public getWaterSystemGroup(
    waterSystem: IrrigationEquipmentWaterSystem,
    programs: IrrigationEquipmentProgram[],
    groups: GroupsDataModel[]
  ): GroupsDataModel | undefined {
    const program = programs.find((program) => {
      return program.waterSystem === waterSystem.waterSystem;
    });
    if (!program) {
      return undefined;
    }
    return groups.find((group) => {
      return group.group === program.actualGroup;
    });
  }

  public getWaterSystemFertilizerFormula(
    waterSystem: IrrigationEquipmentWaterSystem,
    fertilizerFormulas: FertilizerFormulasModel[]
  ): FertilizerFormulasModel | undefined {
    return fertilizerFormulas.find(
      (fertilizerFormula) =>
        fertilizerFormula.formula === waterSystem.fertilizerFormula
    );
  }

  public getWaterSystemMixtureFormula(
    waterSystem: IrrigationEquipmentWaterSystem,
    mixtureFormulas: MixtureFormulasModel[]
  ): MixtureFormulasModel | undefined {
    return mixtureFormulas.find(
      (mixtureFormula) => mixtureFormula.formula === waterSystem.mixtureFormula
    );
  }

  public fertilizerValue(fertilizer: Fertilizer): string | undefined {
    if (fertilizer.quantity === 0) {
      return undefined;
    }
    switch (fertilizer.fertilizerType) {
      case 'PERCENTAGE':
        return `${fertilizer.quantity} %`;

      case 'SPECIAL_CONTRIBUTION':
        return `${fertilizer.quantity} ${this.translationsLib.get(
          'irrigation_liters'
        )}`;

      case 'DISCREET':
      case 'VOLUME':
        return `${fertilizer.quantity} l/m³`;

      case 'SEQUENTIAL':
      case 'TIME':
        return this.waterSystemsUtilsService.formatSeconds(fertilizer.quantity);

      default:
        return '';
    }
  }
}
