import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { IrrigationService } from '../../../irrigation.service';
import { ActivatedRoute } from '@angular/router';
import {
  removeDirtyInputs,
  saveDataAndShowModal,
  saveSubmitWithTerminalStatus,
} from '../../../../../commons/helpers/functions';
import Swal from 'sweetalert2';
import { PhCeRearmAlarmsIrrigationInstallerModel } from '../../../irrigation.model';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { EquipmentsLibService } from '../../../../../services/libraries/equipments-lib.service';
import { Subscription } from 'rxjs';
import { FormFieldHandlerService } from '../../../../libraries/form-field-handler.service';
import { environment } from '../../../../../../environments/environment';
import { InputNumberService } from '../../../../libraries/input-number.service';

@Component({
  selector: 'app-ph-ce-rearm-alarm-form',
  templateUrl: './ph-ce-rearm-alarm-form.component.html',
  styleUrls: ['./ph-ce-rearm-alarm-form.component.scss'],
})
export class PhCeRearmAlarmFormComponent implements OnInit, OnDestroy {
  isFetchingData = false;
  isFormSubmitted = false;
  phCeRearmAlarmForm: UntypedFormGroup;
  private formFieldErrorSubscription: Subscription;
  isConnected: boolean;
  isPHCERearmAlarmWithoutData = false;
  private intervalId: any;
  phCeRearmAlarmResponse: PhCeRearmAlarmsIrrigationInstallerModel;
  @Input() mode: string;
  @Input() terminal_vid: string;
  @Input() ph_ce_rearm_alarm_id: number;
  @Output() dirtyEventEmitter = new EventEmitter<boolean>();
  @Output() formFieldErrorEventEmitter = new EventEmitter<boolean>();
  selectedStopDoublePH: number;
  selectedStopDoubleCE: number;
  selectedRearmAlarm: number;

  isErrorInPHCERearmAlarmPost = false;

  constructor(
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private irrigationService: IrrigationService,
    public translationsLib: TranslationsLibService,
    private equipmentLib: EquipmentsLibService,
    private formFieldHandlerService: FormFieldHandlerService,
    public inputNumberService: InputNumberService
  ) {
    this.phCeRearmAlarmForm = this.fb.group({
      tolerancePH: [null, Validators.required],
      dirty: [''],
      toleranceCE: [null, Validators.required],
      PHMax: [null, Validators.required],
      CEMin: [null, Validators.required],
      limitDoublePH: [null, Validators.required],
      stopDoublePH: [null, Validators.required],
      rearmAlarm: [null, Validators.required],
      limitDoubleCE: [null, Validators.required],
      stopDoubleCE: [null, Validators.required],
      stopAlarmCE: this.fb.group({
        specialContributions: [null, Validators.required],
        volume: [null, Validators.required],
        percent: [null, Validators.required],
      }),
      isDirty: false,
    });
  }

  async startInterval() {
    this.intervalId = setInterval(() => {
      this.performTasks();
    }, environment.intervalDefaultTimeout);
  }

  async performTasks() {
    this.phCeRearmAlarmForm.reset();

    this.getPhCeRearmAlarms();

    await this.getIrrigationEquipment();

    if (!this.isConnected) {
      this.equipmentLib.showConnectivityAlert();
    }

    this.isFormSubmitted = false;
  }

  ngOnInit() {
    this.phCeRearmAlarmForm.reset(); // Prevent unsaved data to persist in form inputs

    this.activatedRoute.params.subscribe((_) => {
      this.getPhCeRearmAlarms();

      removeDirtyInputs(this.phCeRearmAlarmForm);
    });

    this.phCeRearmAlarmForm.valueChanges.subscribe((_) => {
      this.dirtyEventEmitter.emit(this.getIsDirty());

      if (this.getIsDirty()) {
        clearInterval(this.intervalId);
      } else {
        clearInterval(this.intervalId);
        this.startInterval();
      }
    });

    this.getIrrigationEquipment();
  }

  getIrrigationEquipment() {
    this.irrigationService
      .getIrrigationTerminal(this.terminal_vid)
      .subscribe((res) => {
        this.isConnected = res.connected;
      });
  }

  setDirty(dirty: boolean) {
    this.phCeRearmAlarmForm.patchValue({ isDirty: dirty });
  }

  getIsDirty() {
    return (
      this.phCeRearmAlarmForm.dirty || this.phCeRearmAlarmForm.value.isDirty
    );
  }

  getPhCeRearmAlarms(isGetAfterPost = false) {
    if (!isGetAfterPost) this.isFetchingData = true;

    this.irrigationService
      .getIrrigationInstallerPHCERearmAlarms(this.terminal_vid)
      .subscribe((res) => {
        this.phCeRearmAlarmResponse = res;

        if (Object.keys(this.phCeRearmAlarmResponse).length === 0) {
          this.isPHCERearmAlarmWithoutData = true;
        } else {
          this.selectedStopDoublePH = res.stopDoublePH;
          this.selectedStopDoubleCE = res.stopDoubleCE;
          this.selectedRearmAlarm = res.rearmAlarm;

          this.phCeRearmAlarmForm.patchValue({
            tolerancePH: res.tolerancePH,
            dirty: res.dirty,
            toleranceCE: res.toleranceCE,
            PHMax: res.PHMax,
            CEMin: res.CEMin,
            limitDoublePH: res.limitDoublePH,
            stopDoublePH: res.stopDoublePH,
            rearmAlarm: res.rearmAlarm,
            limitDoubleCE: res.limitDoubleCE,
            stopDoubleCE: res.stopDoubleCE,
            stopAlarmCE: {
              specialContributions: res.stopAlarmCE.specialContributions,
              volume: res.stopAlarmCE.volume,
              percent: res.stopAlarmCE.percent,
            },
          });
        }

        if (!isGetAfterPost) this.isFetchingData = false;
      });
  }

  onChangeStopDoublePH() {
    this.setDirty(true);
  }

  onChangeStopDoubleCE() {
    this.setDirty(true);
  }

  onChangeRearmAlarm() {
    this.setDirty(true);
  }

  handlePost(showModal: boolean, connected: boolean): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      this.isFormSubmitted = true;

      const phCeRearmAlarmAsObj = {
        tolerancePH: this.phCeRearmAlarmForm.value.tolerancePH,
        toleranceCE: this.phCeRearmAlarmForm.value.toleranceCE,
        PHMax: this.phCeRearmAlarmForm.value.PHMax,
        CEMin: this.phCeRearmAlarmForm.value.CEMin,
        limitDoublePH: this.phCeRearmAlarmForm.value.limitDoublePH,
        stopDoublePH: Number(this.selectedStopDoublePH),
        rearmAlarm: Number(this.selectedRearmAlarm),
        limitDoubleCE: this.phCeRearmAlarmForm.value.limitDoubleCE,
        stopDoubleCE: Number(this.selectedStopDoubleCE),
        stopAlarmCE: {
          specialContributions:
            this.phCeRearmAlarmForm.value.stopAlarmCE.specialContributions,
          volume: this.phCeRearmAlarmForm.value.stopAlarmCE.volume,
          percent: this.phCeRearmAlarmForm.value.stopAlarmCE.percent,
        },
      };

      try {
        this.irrigationService
          .postIrrigationInstallerPHCERearmAlarms(
            this.terminal_vid,
            phCeRearmAlarmAsObj
          )
          .subscribe(
            (res) => {
              if (res.error) this.isErrorInPHCERearmAlarmPost = true;

              if (
                connected &&
                !this.phCeRearmAlarmForm.value.dirty &&
                !this.isErrorInPHCERearmAlarmPost
              ) {
                saveDataAndShowModal(
                  res,
                  showModal,
                  this.translationsLib.get('error_updating_data'),
                  this.translationsLib.get('save_changes_success'),
                  this.translationsLib.get('accept'),
                  () => {
                    setTimeout(() => {
                      this.getPhCeRearmAlarms(true);
                    }, 4000);
                  }
                );

                this.isFormSubmitted = false;
                resolve(true);
              } else if (this.isErrorInPHCERearmAlarmPost) {
                Swal.fire({
                  text: this.translationsLib.get(
                    'irrigation_general_program_error'
                  ),
                  showConfirmButton: true,
                  confirmButtonText: this.translationsLib.get('accept'),
                  icon: 'error',
                });

                this.isFormSubmitted = false;
                resolve(true);
              } else {
                saveSubmitWithTerminalStatus(
                  res,
                  showModal,
                  connected,
                  this.translationsLib.get('error_updating_data'),
                  this.translationsLib.get('save_changes_success'),
                  this.translationsLib.get('accept'),
                  this.translationsLib.get(
                    'irrigation_disconnected_terminal_pending_changes'
                  )
                );

                this.isFormSubmitted = false;
                resolve(true);
              }
            },
            (_) => {
              if (showModal) {
                Swal.fire({
                  text: this.translationsLib.get('something_was_wrong'),
                  showConfirmButton: true,
                  confirmButtonText: this.translationsLib.get('accept'),
                  icon: 'error',
                });
                this.isFormSubmitted = false;
                resolve(false);
              }
            }
          );
      } catch (err) {
        throw new Error(err.message);
      }
    });
  }

  submitForm(
    showModal: boolean = true
  ): Promise<{ result: boolean; connected: boolean }> {
    return new Promise<{ result: boolean; connected: boolean }>(
      (resolve, reject) => {
        try {
          this.irrigationService
            .getIrrigationTerminal(this.terminal_vid)
            .subscribe((res) => {
              this.handlePost(showModal, res.connected).then((result) => {
                resolve({ result, connected: res.connected });
              });
            });
        } catch (err) {
          reject({ result: false, connected: false });
        } finally {
          // Remove dirty inputs due to use standalone ngModels
          removeDirtyInputs(this.phCeRearmAlarmForm);

          this.phCeRearmAlarmForm.markAsUntouched();

          this.phCeRearmAlarmForm.markAsPristine();
          this.phCeRearmAlarmForm.patchValue({ isDirty: false });
        }
      }
    );
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
    this.formFieldErrorSubscription?.unsubscribe();
  }
}
