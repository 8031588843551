import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  Progbdren,
  SensorDataModel,
  ValvesDataModel,
} from '../../../irrigation.model';
import { IrrigationService } from '../../../irrigation.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import {
  convertFormattedTimeToSeconds,
  DateToSecondsInput,
  removeDirtyInputs,
  saveDataAndShowModal,
  saveSubmitWithTerminalStatus,
  secondsInputToDate,
} from '../../../../../commons/helpers/functions';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { EquipmentsLibService } from '../../../../../services/libraries/equipments-lib.service';
import { Subscription } from 'rxjs';
import { FormFieldHandlerService } from '../../../../libraries/form-field-handler.service';
import { environment } from '../../../../../../environments/environment';
import { InputNumberService } from '../../../../libraries/input-number.service';

@Component({
  selector: 'app-drainage-trays-form',
  templateUrl: './drainage-trays-form.component.html',
  styleUrls: ['./drainage-trays-form.component.scss'],
})
export class DrainageTraysFormComponent implements OnInit, OnDestroy {
  isFetchingData = false;
  isDrainageTraysWithoutData = false;
  isFormSubmitted = false;
  traysForm: UntypedFormGroup;
  isConnected: boolean;
  private formFieldErrorSubscription: Subscription;
  private intervalId: any;

  valvesList: ValvesDataModel[];
  sensorsList: SensorDataModel[];

  @Input() mode: string;
  @Input() terminal_vid: string;
  @Input() tray_id: number;

  @Output() dirtyEventEmitter = new EventEmitter<boolean>();
  @Output() drainageTrayEventEmitter = new EventEmitter<any>();
  @Output() formFieldErrorEventEmitter = new EventEmitter<boolean>();

  layoutConfiguration: Progbdren;

  isErrorInDrainageTraysPost = false;

  constructor(
    private fb: UntypedFormBuilder,
    private irrigationService: IrrigationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public translationsLib: TranslationsLibService,
    private equipmentLib: EquipmentsLibService,
    private formFieldHandlerService: FormFieldHandlerService,
    public inputNumberService: InputNumberService
  ) {
    this.traysForm = this.fb.group({
      tray: [null],
      dirty: [''],
      valve: [null, Validators.required],
      pickTray: [null, Validators.required],
      pickFlow: [null, Validators.required],
      litresPulse: [null, Validators.required],
      drainageOpeningTime: [null, Validators.required],
      ceSensor: [null],
      isDirty: false,
    });
  }

  async startInterval() {
    this.intervalId = setInterval(() => {
      this.performTasks();
    }, environment.intervalDefaultTimeout);
  }

  async performTasks() {
    this.traysForm.reset();

    this.getValvesList();
    this.getSensorsList();
    this.getLayoutConfiguration();
    this.getTray();

    await this.getIrrigationEquipment();

    if (!this.isConnected) {
      this.equipmentLib.showConnectivityAlert();
    }

    this.isFormSubmitted = false;
  }

  ngOnInit() {
    this.traysForm.reset(); // Prevent unsaved data to persist in form inputs

    this.activatedRoute.params.subscribe((_) => {
      this.getValvesList();
      this.getSensorsList();
      this.getLayoutConfiguration();
      this.getTray();

      removeDirtyInputs(this.traysForm);
    });

    this.traysForm.valueChanges.subscribe((_) => {
      this.dirtyEventEmitter.emit(this.getIsDirty());

      if (this.getIsDirty()) {
        clearInterval(this.intervalId);
      } else {
        clearInterval(this.intervalId);
        this.startInterval();
      }
    });

    this.getIrrigationEquipment();
  }

  getIrrigationEquipment() {
    this.irrigationService
      .getIrrigationTerminal(this.terminal_vid)
      .subscribe((res) => {
        this.isConnected = res.connected;
      });
  }

  getLayoutConfiguration() {
    this.irrigationService
      .getTerminalLayoutConfiguration(this.terminal_vid)
      .subscribe((res) => {
        this.layoutConfiguration = res.screens_configuration.progbdren;
        if (!this.layoutConfiguration.enabled) {
          this.router.navigate(['/home/dashboard']);
        }
      });
  }

  onChangeInputTimeWithKeyboard(event, formInput: string) {
    event.target.classList.add('ng-dirty');
    this.traysForm.markAsDirty();
    const toSeconds = convertFormattedTimeToSeconds(event.target.value);

    const toDate = secondsInputToDate(toSeconds);

    this.traysForm.get(formInput).setValue(toDate);
  }

  setDirty(dirty) {
    this.traysForm.patchValue({ isDirty: dirty });
  }

  getIsDirty() {
    return this.traysForm.dirty || this.traysForm.value.isDirty;
  }

  getValvesList() {
    this.irrigationService.getValves(this.terminal_vid).subscribe((res) => {
      this.valvesList = res;
    });
  }

  getSensorsList() {
    this.irrigationService.getSensors(this.terminal_vid).subscribe((res) => {
      res.forEach((sensor) => {
        sensor.id = sensor.nexp + '_' + sensor.nent + '_' + sensor.physicalType;
      });
      this.sensorsList = res;
    });
  }

  getTray(isGetAfterPost = false) {
    if (!isGetAfterPost) this.isFetchingData = true;
    const pagination = 0;

    this.irrigationService
      .getIrrigationInstallerDrainageTray(this.terminal_vid, this.tray_id)
      .subscribe((res) => {
        this.drainageTrayEventEmitter.emit(res[0]);

        if (res.length === 0) {
          this.isDrainageTraysWithoutData = true;
        } else {
          const ceSensor = res[pagination].ceSensor;
          let ceSensorSelected = null;
          if (ceSensor !== null) {
            ceSensorSelected =
              ceSensor.nexp + '_' + ceSensor.nent + '_' + ceSensor.physicalType;
          }

          this.traysForm.patchValue({
            tray: res[pagination].tray,
            dirty: res[pagination].dirty,
            valve: res[pagination].valve,
            pickTray: res[pagination].pickTray,
            pickFlow: res[pagination].pickFlow,
            litresPulse: res[pagination].litresPulse,
            drainageOpeningTime: secondsInputToDate(
              res[pagination].drainageOpeningTime
            ),
            ceSensor: ceSensorSelected,
          });
        }

        if (!isGetAfterPost) this.isFetchingData = false;
      });
  }

  handlePost(showModal: boolean, connected: boolean): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      this.isFormSubmitted = true;

      let ceSensor = null;
      const ceSensorSelected = this.traysForm.value.ceSensor;
      if (ceSensorSelected !== null) {
        const dS = this.sensorsList.find(
          // @ts-ignore
          (option) => option.id === ceSensorSelected
        );
        if (dS === undefined) {
          ceSensor = null;
        } else {
          const { id, ...drainageSensorObject } = dS;
          ceSensor = drainageSensorObject;
        }
      }

      const drainageTraysAsArray = [];
      drainageTraysAsArray.push({
        tray: this.traysForm.value.tray,
        valve: this.traysForm.value.valve,
        pickTray: this.traysForm.value.pickTray,
        pickFlow: this.traysForm.value.pickFlow,
        litresPulse: this.traysForm.value.litresPulse,
        drainageOpeningTime: DateToSecondsInput(
          this.traysForm.value.drainageOpeningTime
        ),
        ceSensor,
      });

      try {
        this.irrigationService
          .postIrrigationInstallerDrainageTrays(
            this.terminal_vid,
            drainageTraysAsArray
          )
          .subscribe(
            (res) => {
              if (res.error) this.isErrorInDrainageTraysPost = true;

              if (
                connected &&
                !this.traysForm.value.dirty &&
                !this.isErrorInDrainageTraysPost
              ) {
                saveDataAndShowModal(
                  res,
                  showModal,
                  this.translationsLib.get('error_updating_data'),
                  this.translationsLib.get('save_changes_success'),
                  this.translationsLib.get('accept'),
                  () => {
                    setTimeout(() => {
                      this.getTray(true);
                    }, 4000);
                  }
                );

                this.isFormSubmitted = false;
                resolve(true);
              } else if (this.isErrorInDrainageTraysPost) {
                Swal.fire({
                  text: this.translationsLib.get(
                    'irrigation_general_program_error'
                  ),
                  showConfirmButton: true,
                  confirmButtonText: this.translationsLib.get('accept'),
                  icon: 'error',
                });

                this.isFormSubmitted = false;
                resolve(true);
              } else {
                saveSubmitWithTerminalStatus(
                  res,
                  showModal,
                  connected,
                  this.translationsLib.get('error_updating_data'),
                  this.translationsLib.get('save_changes_success'),
                  this.translationsLib.get('accept'),
                  this.translationsLib.get(
                    'irrigation_disconnected_terminal_pending_changes'
                  )
                );

                this.isFormSubmitted = false;
                resolve(true);
              }
            },
            (_) => {
              if (showModal) {
                Swal.fire({
                  text: this.translationsLib.get('something_was_wrong'),
                  showConfirmButton: true,
                  confirmButtonText: this.translationsLib.get('accept'),
                  icon: 'error',
                });
              }
              this.isFormSubmitted = false;
              resolve(false);
            }
          );
      } catch (err) {
        throw new Error(err.message);
      }
    });
  }

  submitForm(
    showModal: boolean = true
  ): Promise<{ result: boolean; connected: boolean }> {
    return new Promise<{ result: boolean; connected: boolean }>(
      (resolve, reject) => {
        try {
          this.irrigationService
            .getIrrigationTerminal(this.terminal_vid)
            .subscribe((res) => {
              this.handlePost(showModal, res.connected).then((result) => {
                resolve({ result, connected: res.connected });
              });
            });
        } catch (err) {
          reject({ result: false, connected: false });
        } finally {
          // Remove dirty inputs due to use standalone ngModels
          removeDirtyInputs(this.traysForm);

          this.traysForm.markAsUntouched();

          this.traysForm.markAsPristine();
          this.traysForm.patchValue({ isDirty: false });
        }
      }
    );
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
    this.formFieldErrorSubscription?.unsubscribe();
  }
}
