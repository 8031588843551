import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { MatTabGroup } from '@angular/material/tabs';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-notifications-tabs',
  templateUrl: './notifications-tabs.component.html',
  styleUrls: ['./notifications-tabs.component.scss'],
})
export class NotificationsTabsComponent implements OnInit, AfterViewInit {
  breadcrumbs: any = [];
  visibleTabs = {
    notifications: 'notifications',
    invitations: 'invitations',
  };
  private tabSelected = this.translationsLib.get('notification_invitations');
  @ViewChild('tabGroup', { static: false }) tab: MatTabGroup;
  constructor(
    private helpersLib: HelpersLibService,
    public translationsLib: TranslationsLibService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.breadcrumbs = [
      {
        text: this.translationsLib.get('notification_notifications'),
        disabled: false,
        to: ' ',
        active: true,
      },
    ];
  }

  ngAfterViewInit() {
    this.helpersLib.focusTab(this.tab);
    this.route.fragment.subscribe((fragment) => {
      if (fragment) {
        // Convert the fragment to a tab index
        const tabIndex = this.getTabIndex(fragment);
        if (tabIndex !== -1) {
          this.tab.selectedIndex = tabIndex;
        }
      }
    });
  }

  private getTabIndex(tabId: string): number {
    return Object.keys(this.visibleTabs).indexOf(tabId);
  }

  onTabChange(event) {
    const tabIndex = event.index;
    const tabId = Object.keys(this.visibleTabs)[tabIndex];

    // Update URL with English identifier
    this.router.navigate(['/notifications'], { fragment: tabId });

    // Update breadcrumbs and other parts of the state
    this.tabSelected = this.translationsLib.get(this.visibleTabs[tabId]);
  }
}
