import { Component, Input, OnInit } from '@angular/core';
import {
  App360SharedModule,
  TranslationsLibService,
} from '@nutricontrol/app360-shared';
import { DataloggerService } from '../datalogger.service';
import * as moment from 'moment';
import { dataloggerEtoBarChartsModel } from '../datalogger-eto/datalogger-eto-charts.model';
import * as Highcharts from 'highcharts';
import theme from 'highcharts/themes/high-contrast-light';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import patternFill from 'highcharts/modules/pattern-fill';
import { ETCLibService } from 'src/app/services/libraries/ETC-lib.service';
import { TimeLibService } from 'src/app/services/libraries/time-lib.service';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgForOf, NgIf } from '@angular/common';
import { AuthModule } from '../../../auth/auth.module';
import { PlatformLibService } from '@nutricontrol/app360-shared';
theme(Highcharts);
Exporting(Highcharts);
ExportData(Highcharts);
patternFill(Highcharts);

@Component({
  standalone: true,
  selector: 'app-datalogger-eto-graph',
  templateUrl: './datalogger-eto-graph.component.html',
  styleUrls: ['./datalogger-eto-graph.component.scss'],
  imports: [
    HighchartsChartModule,
    NgIf,
    NgForOf,
    AuthModule,
    App360SharedModule,
  ],
})
export class DataloggerEtoGraphComponent implements OnInit {
  @Input() terminal_vid;
  @Input() elementsDisposition = 1;
  chart;
  updateFlag = false;
  loading = false;
  chartConstructor = 'chart';
  chartCallback;
  Highcharts = Highcharts;
  @Input() equipment;
  consumptionChart: any = dataloggerEtoBarChartsModel;
  private ET0;
  private ETC = [];
  private latitude: any;
  private longitude: any;
  private predictions = [];
  private predictionsDates = [];
  dataSeriesX;
  ETCs: any[] = [];
  ET0s = [];
  noET0data = false;
  constructor(
    public translationsLib: TranslationsLibService,
    private dataloggerService: DataloggerService,
    public etcLib: ETCLibService,
    public timeLib: TimeLibService,
    private platformLib: PlatformLibService
  ) {}

  ngOnInit() {
    this.getDataloggerPosition();
    this.getET0predictions();
    this.getCalculationsEt0();
  }

  getCalculationsEt0() {
    this.dataloggerService
      .getCalculations(this.terminal_vid)
      .subscribe((res) => {
        res.forEach((calc) => {
          if (calc.type === 'ET0-DAILY') {
            this.ET0 = calc;
          }
          if (calc.type === 'ETC-DAILY') {
            this.ETC.push(calc);
          }
        });
        this.ETCs = Object.assign([], this.ETC);
        //hago esto porque solo hay una ET0 para todos los ETCs y así no hago un foreach del array
        this.ETC.push(this.ET0);
        this.getEt0Data(this.ETC);
      });
  }
  getDataloggerPosition() {
    this.latitude = this.equipment.latitude;
    this.longitude = this.equipment.longitude;
  }
  getET0predictions() {
    const date = new Date();
    const date2 = new Date();
    date2.setDate(date.getDate() + 3);
    date.setDate(date.getDate());
    const data = {
      begin: moment(date).format('YYYY/MM/DD'),
      end: moment(date2).format('YYYY/MM/DD'),
    };

    this.dataloggerService
      .getET0Prediction(this.terminal_vid, data)
      .subscribe((res) => {
        res.data.forEach((pred) => {
          this.predictions.push(pred.ET0);
          this.predictionsDates.push(pred.date);
        });
      });
  }

  getEt0Data(sensors) {
    const date = new Date();
    const date2 = new Date();
    const dataSeries = [];
    const series_x = [];
    const series = [];
    const kc = [];
    let contador;
    let count = sensors.length;
    date2.setDate(date.getDate() - 2);
    for (const sensor of sensors) {
      const data = {
        nexp: sensor.nexp,
        nent: sensor.nent,
        physicalType: sensor.physicalType,
        begin: moment(date2).format('YYYY/MM/DD'),
        end: moment(date).format('YYYY/MM/DD'),
        interval: 300,
      };
      this.dataloggerService
        .getChartsData(this.terminal_vid, data)
        .subscribe(async (res) => {
          contador = res.data.length;
          res.data.forEach((data, index) => {
            dataSeries.push(data.value);
            const fecha = moment(data.timestamp).format('DD/MM');
            series_x.push(fecha);
            this.ET0s[fecha] = data.value;
            contador--;
          });
          /*const timeout = setInterval(() => {
            if (contador === 0) {
              let datos = {};
              if (sensor.type === 'ETC-DAILY') {
                const kcSeries = [];
                kc.push([sensor.etc.constant, sensor.name]);
                for (let i = 0; i < dataSeries.length; i++) {
                  kcSeries.push(dataSeries[i] * sensor.etc.constant);
                }
                datos = {
                  name: sensor.name,
                  data: kcSeries,
                  xAxis: 1,
                  zIndex: 1000,
                  model: 'ETC',
                  type: 'column2',
                };

                series.push(datos);
                count = count - 1;
                if (count === 0) {
                  this.donegraph(series_x, series, kc);
                }
              } else {
                datos = {
                  name: sensor.name,
                  data: dataSeries,
                  xAxis: 0,
                  color: 'transparent',
                  showInLegend: false,
                };

                series.push(datos);
                count = count - 1;
                if (count === 0) {
                  this.donegraph(series_x, series, kc);
                }

              }
              clearInterval(timeout);
            }
          }, 100);*/
          setTimeout(() => {
            let datos = {};
            if (sensor.type === 'ETC-DAILY') {
              const kcSeries = [];
              kc.push([sensor.etc.constant, sensor.name]);
              for (let i = 0; i < dataSeries.length; i++) {
                kcSeries.push(dataSeries[i] * sensor.etc.constant);
              }
              datos = {
                name: sensor.name,
                data: kcSeries,
                xAxis: 1,
                zIndex: 1000,
                model: 'ETC',
                type: 'column2',
              };

              series.push(datos);
              setTimeout(() => {
                count = count - 1;
                if (count === 0 && series.includes(datos)) {
                  this.donegraph(series_x, series, kc);
                }
              }, 50);
            } else {
              datos = {
                name: sensor.name,
                data: dataSeries,
                xAxis: 0,
                color: 'transparent',
                showInLegend: false,
              };

              series.push(datos);
              setTimeout(() => {
                count = count - 1;
                if (count === 0 && series.includes(datos)) {
                  this.donegraph(series_x, series, kc);
                }
              }, 50);
            }
          }, 3000);
        });
    }
  }
  donegraph(series_x, series, kc) {
    if (series_x.length === 0) {
      this.noET0data = true;
    }
    this.loading = true;
    const seriesSize = series_x.length - 1;
    series_x.forEach((serie) => {
      this.predictions.unshift(null);
    });
    kc.forEach((kcSerie) => {
      const etcPredictions = [];
      this.predictions.forEach((serie) => {
        if (serie * kcSerie[0] !== 0) {
          etcPredictions.push(serie * kcSerie[0]);
        } else {
          etcPredictions.push(null);
        }
      });
      const datosPredETC = {
        name: this.translationsLib.get('DL_pred_ETC') + ' ' + kcSerie[1],
        data: etcPredictions,
        xAxis: 1,
        zIndex: 1000,
        model: 'ETC',
        type: 'column2',
        color: {
          pattern: {
            path: {
              d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
              strokeWidth: 3,
            },
            width: 10,
            height: 10,
            opacity: 1,
          },
        } as unknown as Highcharts.PatternObject,
      };

      series.push(datosPredETC);
    });
    this.predictionsDates.forEach((date) => {
      series_x.push(moment(date).format('DD/MM'));
    });
    series_x.forEach((fecha, i) => {
      if (this.predictions[i] != null) {
        this.ET0s[fecha] = this.predictions[i];
      }
    });
    const datosPred = {
      name: this.translationsLib.get('DL_ET0_predicction'),
      data: this.predictions,
      xAxis: 0,
      color: 'transparent',
      showInLegend: false,
    };
    series.push(datosPred);
    if (this.platformLib.isNative()) {
      this.consumptionChart.exporting.enabled = false;
    }
    this.consumptionChart.xAxis[0].categories = series_x;
    this.consumptionChart.xAxis[0].plotLines = [
      {
        color: '#FF0000',
        width: 2,
        value: seriesSize + 0.5,
        label: {
          text: this.translationsLib.get('DL_predictions'),
          align: 'right',
          y: 200,
        },
      },
    ];
    this.dataSeriesX = series_x;
    this.consumptionChart.series = series;
    this.updateFlag = true;
  }
  roundValue(value) {
    let valor = Number(value.toFixed(2));
    if (isNaN(valor) || !Number.isFinite(valor)) {
      valor = null;
    }
    return valor;
  }
}
