import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appOnlyNumbers]',
})
export class OnlyNumbersDirective {
  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const allowedKeys = [
      'Backspace',
      'Tab',
      'End',
      'Home',
      'ArrowLeft',
      'ArrowRight',
      'Delete',
    ];

    if (
      allowedKeys.indexOf(event.key) !== -1 ||
      (event.ctrlKey && (event.key === 'v' || event.key === 'V'))
    ) {
      return;
    }

    const isNumberKey =
      (event.key >= '0' && event.key <= '9') ||
      (event.key >= 'Numpad0' && event.key <= 'Numpad9');
    const isModifierKey = event.ctrlKey || event.altKey || event.metaKey;

    if (!isNumberKey && !isModifierKey) {
      event.preventDefault();
    }
  }
}
