import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestApiService } from '../services/api/rest-api.service';
import {
  AvailablePeriodModel,
  AvailableReportModel,
  MixedReportResponseModel,
} from './reports.model';
import {
  ReportTaskAvailableCountModel,
  ReportTaskAvailablePermissionsRequestModel,
  ReportTaskAvailablePermissionsResponseModel,
  ReportTaskModel,
} from './reports-task.model';
import { TranslationsLibService } from '@nutricontrol/app360-shared';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  private restApiService: RestApiService;
  public translationsLib: TranslationsLibService;

  constructor(
    restApiService: RestApiService,
    translationsLib: TranslationsLibService
  ) {
    this.restApiService = restApiService;
    this.translationsLib = translationsLib;
  }
  public getAvailableReports(): AvailableReportModel[] {
    const availableReports: AvailableReportModel[] = [];
    availableReports.push({
      id: 'irrigations',
      name: this.translationsLib.get('reports_irrigations'),
    });
    availableReports.push({
      id: 'programs_consumptions',
      name: this.translationsLib.get('reports_irrigation_consumptions'),
    });
    availableReports.push({
      id: 'groups_consumptions',
      name: this.translationsLib.get(
        'reports_irrigation_grouping_consumptions'
      ),
    });
    availableReports.push({
      id: 'drainages',
      name: this.translationsLib.get('reports_drainages'),
    });
    //availableReports.push({
    //  id: 'sensors',
    //  name: this.translationsLib.get('reports_sensors'),
    //});
    availableReports.push({
      id: 'alarms',
      name: this.translationsLib.get('reports_alarms'),
    });
    return availableReports;
  }
  public getAvailablePeriods(): AvailablePeriodModel[] {
    const availablePeriods: AvailablePeriodModel[] = [];
    availablePeriods.push({
      id: '1d',
      name: this.translationsLib.get('reports_report_task_period_1d'),
    });
    availablePeriods.push({
      id: '1w',
      name: this.translationsLib.get('reports_report_task_period_1w'),
    });
    availablePeriods.push({
      id: '2w',
      name: this.translationsLib.get('reports_report_task_period_2w'),
    });
    /*
    availablePeriods.push({
      id: '1m',
      name: this.translationsLib.get('reports_report_task_period_1m'),
    });
     */
    return availablePeriods;
  }
  public getReportsTasks(): Observable<ReportTaskModel[]> {
    return new Observable((observer) => {
      this.restApiService.getReportTasks().subscribe(
        (response) => {
          return observer.next(response);
        },
        (error) => {
          observer.next(error);
        }
      );
    });
  }
  public postReportTask(data: ReportTaskModel): Observable<ReportTaskModel> {
    return new Observable((observer) => {
      this.restApiService.postReportTask(data).subscribe(
        (response) => {
          return observer.next(response);
        },
        (error) => {
          return observer.next(error);
        }
      );
    });
  }
  public putReportTask(
    reportTaskVid: string,
    data: ReportTaskModel
  ): Observable<ReportTaskModel> {
    return new Observable((observer) => {
      this.restApiService
        .putReportTaskAvailablePermissions(reportTaskVid, data)
        .subscribe(
          (response) => {
            return observer.next(response);
          },
          (error) => {
            observer.next(error);
          }
        );
    });
  }
  public postReportTaskAvailablePermissions(
    reportTaskVid: string,
    data: ReportTaskAvailablePermissionsRequestModel
  ): Observable<ReportTaskAvailablePermissionsResponseModel> {
    return new Observable((observer) => {
      this.restApiService
        .postReportTaskAvailablePermissions(reportTaskVid, data)
        .subscribe(
          (response) => {
            return observer.next(response);
          },
          (error) => {
            observer.next(error);
          }
        );
    });
  }
  public getReportsTasksAvailableCount(): Observable<ReportTaskAvailableCountModel> {
    return new Observable((observer) => {
      this.restApiService.getReportTasksAvailableCount().subscribe(
        (response) => {
          return observer.next(response);
        },
        (error) => {
          observer.next(error);
        }
      );
    });
  }

  public getActivationTypePrettyName(activation_type: string): string {
    let result = '';
    activation_type
      .trim()
      .split(' ')
      .forEach((at) => {
        result += this.translationsLib.get('reports_activation_' + at) + ' ';
      });
    result = result.trim();

    return result;
  }
  public getIrrigationReport(
    data,
    begin: string,
    end: string,
    user: string
  ): Observable<MixedReportResponseModel> {
    return new Observable((observer) => {
      this.restApiService.getIrrigationReport(data, begin, end, user).subscribe(
        (response) => {
          return observer.next(response);
        },
        (error) => {
          observer.next(error);
        }
      );
    });
  }
  public getConsumptionsProgramReport(
    data,
    begin: string,
    end: string,
    user: string
  ): Observable<MixedReportResponseModel> {
    return new Observable((observer) => {
      this.restApiService
        .getConsumptionsProgramReport(data, begin, end, user)
        .subscribe(
          (response) => {
            return observer.next(response);
          },
          (error) => {
            observer.next(error);
          }
        );
    });
  }
  public getConsumptionsGroupsReport(
    data,
    begin: string,
    end: string,
    user: string
  ): Observable<MixedReportResponseModel> {
    return new Observable((observer) => {
      this.restApiService
        .getConsumptionsGroupsReport(data, begin, end, user)
        .subscribe(
          (response) => {
            return observer.next(response);
          },
          (error) => {
            observer.next(error);
          }
        );
    });
  }
  public getDrainageReport(
    data,
    begin: string,
    end: string,
    user: string
  ): Observable<MixedReportResponseModel> {
    return new Observable((observer) => {
      this.restApiService.getDrainageReport(data, begin, end, user).subscribe(
        (response) => {
          return observer.next(response);
        },
        (error) => {
          observer.next(error);
        }
      );
    });
  }
  public getAlarms(
    data,
    begin: string,
    end: string,
    user: string
  ): Observable<MixedReportResponseModel> {
    return new Observable((observer) => {
      this.restApiService.getAlarmsReport(data, begin, end, user).subscribe(
        (response) => {
          return observer.next(response);
        },
        (error) => {
          observer.next(error);
        }
      );
    });
  }
}
