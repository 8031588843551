<div class="row" *ngIf="loading === false">
   <div class="d-flex px-3 px-lg-0" [ngClass]="{'col-12': !buttons, 'col-10 col-lg-8': buttons}">
    <div class="d-inline-block pe-2">
      <a href="{{ route }}">
      <img src="../../../../assets/img/Icons/Icono_UCROP_Finca.svg" width="100%">
      </a>
    </div>
    <div class="d-inline-block align-content-start id-container">
      <a href="{{ route }}">
      <div class="row">
        <div class="col-auto align-self-center mx-2">
          <a class="mt-2 mb-0" href="{{ route }}">{{field.name}} </a>
        </div>
      </div>
      <div class="row">
        <div class="col-auto align-self-center mx-2 fix-line-height">
          <span style="color: #224261">{{translationsLib.get('field_size_lower')}}: </span>
          <span class="text-muted">{{(field.coordinates_area/10000).toFixed(2)}} Ha</span>
        </div>
      </div>
      <div class="row" *ngIf="field.multicrops.length > 0">
        <div class="col-auto mx-2 fix-line-height">
          <span style="color: #224261">{{translationsLib.get('field_crops')}}: </span>
          <span class="text-muted">{{ field | fieldCropsName }}</span>
        </div>
      </div>
      </a>
    </div>
  </div>
  <div class="col-2 col-lg-4 text-end align-self-center" *ngIf="buttons">
    <div class="row">
      <div class="col-12 col-lg-8 col-xxl-10 text-end text-lg-end mb-2 mb-lg-0" *ngIf="field.authed_user_can_write === true">
        <a (click)="reloadField()" class="btn btn-primary" style="color: white">
          <ion-icon name="refresh-outline"></ion-icon>
        </a>
      </div>
      <div class="col text-end">
        <a (click)="reloadField()" class="btn btn-primary" style="color: white" *ngIf="field.authed_user_can_write === false">
          <ion-icon name="refresh-outline"></ion-icon>
        </a>
        <a (click)="editField()" id="editField" class="btn btn-primary" style="color: white" *ngIf="field.authed_user_can_write === true">
          <ion-icon id="editFieldIcon" [ios]="'settings-outline'" [md]="'settings-outline'"></ion-icon>
        </a>
      </div>
    </div>
  </div>
</div>
