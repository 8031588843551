<div class="card h-100 py-2 px-3 justify-content-center">
  <div class="row p-0">
    <div class="col-3">
      <div class="text-nowrap text-uppercase fs-6 fw-bold"
           style="color: var(--primary-color)">
        {{ field.label }}
      </div>

    </div>
    <ng-container *ngFor="let fertilizer of field.fertilizers">
      <div class="col-3">
        <div class="text-nowrap text-uppercase fs-6 fw-bold" style="color: var(--primary-color)">
          {{ translationsLib.get('irrigation_fertilization_abbreviation') }} {{ fertilizer.fertilizer }}
        </div>
        <div [class]="isMobile ? 'fs-6' : 'fs-5'" class="fw-bold">
          {{ fertilizer.value }}
        </div>
      </div>
    </ng-container>
  </div>
</div>
