import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NgxPaginationModule } from 'ngx-pagination';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TerminalTableWrapperClimateComponent } from '../commons/components/terminal-table-wrapper-climate/terminal-table-wrapper-climate.component';
import { EquipmentDataComponent } from '../commons/components/equipment-data/equipment-data.component';
import { FilterByPropertyClimatePipe } from '../commons/pipes/FilterByPropertyClimatePipe';
import { AppCommonsModule } from '../commons/app-commons.module';
import { TerminalTableDataDirective } from '../commons/directives/terminal-table-data.directive';
import { SelectZoneComponent } from './climate/shared/select-zone/select-zone.component';
import { HelpCardComponent } from './climate/shared/help-card/help-card.component';
import { DesktopMeteoKeyPipe } from './climate/pipes/desktop-meteo-key.pipe';
import { ConfirmLeaveComponent } from './irrigation/shared/confirm-leave/confirm-leave.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { CannotWriteAlertComponent } from '../commons/components/cannot-write-alert/cannot-write-alert.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SensorsModule } from './sensors/sensors.module';
import { NonVerifiedInstallerAlertComponent } from './common/non-verified-installer-alert/non-verified-installer-alert.component';
import { GenericHeaderComponent } from './common/generic-header/generic-header.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NotificationsModule } from '../notifications/notifications.module';
import { ConvertWeekDaysPipe } from './pipes/convert-week-days.pipe';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import {App360SharedModule} from "@nutricontrol/app360-shared";

@NgModule({
  declarations: [
    SelectZoneComponent,
    HelpCardComponent,
    TerminalTableWrapperClimateComponent,
    EquipmentDataComponent,
    FilterByPropertyClimatePipe,
    TerminalTableDataDirective,
    ConvertWeekDaysPipe,
    DesktopMeteoKeyPipe,
    ConfirmLeaveComponent,
    CannotWriteAlertComponent,
    NonVerifiedInstallerAlertComponent,
    GenericHeaderComponent,
    OnlyNumbersDirective,
  ],
    imports: [
        MatAutocompleteModule,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        AppCommonsModule,
        PopoverModule,
        RouterModule,
        NgxPaginationModule,
        TimepickerModule,
        MatTooltipModule,
        SensorsModule,
        NotificationsModule,
        App360SharedModule,
    ],
  exports: [
    HelpCardComponent,
    SelectZoneComponent,
    TerminalTableWrapperClimateComponent,
    EquipmentDataComponent,
    FilterByPropertyClimatePipe,
    TerminalTableDataDirective,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    AppCommonsModule,
    PopoverModule,
    RouterModule,
    NgxPaginationModule,
    TimepickerModule,
    IonicModule,
    DesktopMeteoKeyPipe,
    CannotWriteAlertComponent,
    SensorsModule,
    NonVerifiedInstallerAlertComponent,
    GenericHeaderComponent,
    OnlyNumbersDirective,
    ConvertWeekDaysPipe,
  ],
})
export class FarmingModule {}
