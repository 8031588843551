import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SessionLibService } from '@nutricontrol/app360-shared';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { FieldsService } from '../fields.service';
import { TerminalsService } from '../../terminals/terminals.service';
import { AppCommonsService } from '../../commons/app-commons.service';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import { environment } from '../../../environments/environment';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { PlatformLibService } from '@nutricontrol/app360-shared';
import Swal from 'sweetalert2';
import { MulticropDataModel } from '../fields.model';
import { SwiperComponent } from 'swiper/angular';

@Component({
  selector: 'app-fields-new',
  templateUrl: 'fields-new.component.html',
  styleUrls: ['fields-new.component.scss'],
})
export class FieldsNewComponent implements OnInit, AfterViewInit {
  @ViewChild('modal') modal!: ElementRef;
  @ViewChild('swiper') swiperElement!: SwiperComponent;
  private fb: FormBuilder = new FormBuilder();

  fieldForm = this.fb.group({
    name: ['', Validators.required],
    cultivo: ['', Validators.required],
    color: ['#229e89'],
  });

  multicrops: MulticropDataModel[];

  serialNumber = this.fb.group({
    serial: [''],
  });

  results = this.fb.group({
    pair_owner: [false],
    pair_installer: [false],
    pair_want_installer: [false],
    pair_owner_email: [false],
    pair_installer_email: [false],
    emailprop: [''],
    emailInst: [''],
    nombre_equipo: [''],
    terminal: [''],
  });
  length: number;
  name = '';
  cropId;
  color;
  field;
  crops;
  avatar: string;
  fieldData;
  nonAttached;
  terminalFound: boolean;
  coordinatesMap;
  serialNumberRes;
  drawn;
  fieldSize;
  image_path;
  breadcrumbs: any = [];
  isNative;
  selectedNonAttachedTerminal = new FormControl();
  postingData = false;
  constructor(
    private modalCtrl: ModalController,
    private fieldService: FieldsService,
    private session: SessionLibService,
    private router: Router,
    private terminalsService: TerminalsService,
    private commonsService: AppCommonsService,
    private helpersLib: HelpersLibService,
    public translationsLib: TranslationsLibService,
    private platformLib: PlatformLibService
  ) {
    this.terminalFound = false;
    this.fieldSize = 0;
  }

  ngOnInit(): void {
    this.isNative = this.platformLib.isNative();
    this.breadcrumbs = [
      {
        text: this.translationsLib.get('field_fields'),
        disabled: false,
        to: ['/fields-generals'],
        active: false,
      },
      {
        text: this.translationsLib.get('field_create_your_field'),
        disabled: false,
        to: '',
        active: true,
      },
    ];

    this.avatar = this.session.getAvatar();
    this.getCropsList();
    this.getNonAttachedList();
    this.helpersLib.setTitle(
      '',
      this.translationsLib.get('field_new_field'),
      null
    );
    const that = this;
    this.drawn = false;
    setTimeout(() => {
      this.helpersLib.sendEventFieldCreate();
    }, 200);
  }

  ngAfterViewInit() {
    this.swiperElement.swiperRef.disable();
  }

  getCropsList() {
    this.commonsService.getCrops().subscribe((response) => {
      this.crops = response;
    });
  }
  saveData() {
    this.fieldData = {
      name: this.fieldForm.value.name,
      color: this.fieldForm.value.color,
      multicrops: this.multicrops,
      enabled: true,
      coordinates: this.coordinatesMap,
      config: {
        map_zoom: 10,
      },
    };
    const pairing = this.convertData();
    if (this.fieldData.name === '') {
      const nameInput = document.getElementById('nameInput');
      nameInput.style.borderColor = 'red';
      Swal.fire({
        text: this.translationsLib.get('field_error_creating_new_field1'),
        icon: 'error',
        confirmButtonColor: '#224261',
      });
    } else if (this.fieldData.coordinates === undefined) {
      Swal.fire({
        text: this.translationsLib.get('field_error_creating_new_field2'),
        icon: 'error',
        confirmButtonColor: '#224261',
      });
    } else if (
      this.fieldData.multicrops === undefined ||
      this.fieldData.multicrops.length === 0
    ) {
      Swal.fire({
        text: this.translationsLib.get('field_error_creating_new_field_field3'),
        icon: 'error',
        confirmButtonColor: '#224261',
      });
    } else {
      this.postingData = true;
      this.fieldService.postField(this.fieldData).subscribe((response) => {
        this.terminalsService
          .postAttaching(this.serialNumberRes, response.vid)
          .subscribe(
            (attach) => {
              this.commonsService.getMenu().subscribe(
                async (res) => {
                  await this.router.navigate([
                    './field',
                    { vid: response.vid },
                  ]);
                  location.reload();
                },
                (error) => {
                  this.postingData = false;
                }
              );
            },
            (error) => {
              this.postingData = false;
            }
          );
      });
    }
  }

  convertData() {
    let owner;
    let installer;

    if (this.results.value.pair_owner) {
      owner = 'me';
    } else {
      owner = this.results.value.emailprop;
    }
    if (this.results.value.pair_installer) {
      installer = 'me';
    } else {
      installer = this.results.value.emailInst;
    }

    return {
      pair_owner: owner,
      pair_installer: installer,
    };
  }
  getCropId(cropName) {
    this.crops.filter((crop) => {
      if (crop.name === cropName) {
        this.cropId = crop.id;
      }
    });
  }

  getNonAttachedList() {
    this.terminalsService.getNonAttached().subscribe((response) => {
      this.nonAttached = response;
      if (this.nonAttached.length === 0) {
        this.nonAttached = null;
      }
    });
  }

  searchPrePairingNonAttached() {
    this.serialNumberRes = this.selectedNonAttachedTerminal.value;

    if (this.serialNumberRes !== null) {
      const response = this.nonAttached.filter(
        (term) => term.vid === this.serialNumberRes
      );
      this.swiperElement.swiperRef.enable();
      this.swiperElement.swiperRef.slideNext();
      this.swiperElement.swiperRef.disable();
      this.terminalFound = true;
      this.image_path = environment.backend + '/' + response[0].image_path;
      this.results.patchValue({
        nombre_equipo: response[0].name,
        terminal: response[0].metadata.terminal,
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: this.translationsLib.get('error'),
        text: this.translationsLib.get('pairing_error'),
        confirmButtonColor: '#224261',
      });
    }
  }

  multicropsEvent(multicrops: MulticropDataModel[]) {
    this.multicrops = multicrops;
  }

  goToPreviousSlide() {
    this.swiperElement.swiperRef.enable();
    this.swiperElement.swiperRef.slidePrev();
    this.swiperElement.swiperRef.disable();
  }

  cancelCreation() {
    Swal.fire({
      icon: 'warning',
      text: this.translationsLib.get('fields_cancel_creation_question'),
      confirmButtonColor: '#224261',
      confirmButtonText: this.translationsLib.get('accept'),
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['./fields-general']);
      }
    });
  }
}
