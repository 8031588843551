import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import { TerminalsService } from '../terminals.service';
import { TerminalsLibService } from '../../services/libraries/terminals-lib.service';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { PlatformLibService } from '@nutricontrol/app360-shared';

@Component({
  selector: 'app-terminals-general',
  templateUrl: './terminals-general.component.html',
  styleUrls: ['./terminals-general.component.scss'],
})
export class TerminalsGeneralComponent implements OnInit {
  terminals: any = [];
  breadcrumbs: any = [];
  loading = true;
  platform;
  constructor(
    public router: Router,
    private helpersLib: HelpersLibService,
    private terminalsService: TerminalsService,
    private terminalsLib: TerminalsLibService,
    public translationsLib: TranslationsLibService,
    private platformLib: PlatformLibService
  ) {}
  ngOnInit() {
    this.helpersLib.setTitle(
      this.translationsLib.get('terminal_terminals'),
      this.translationsLib.get('general'),
      null
    );
    this.platform = this.platformLib.isNative();
    this.getTerminals();
    this.breadcrumbs = [
      {
        text: this.translationsLib.get('terminal_terminals'),
        disabled: false,
        to: '/terminals-general',
        active: false,
      },
      {
        text: this.translationsLib.get('general'),
        disabled: false,
        to: ' ',
        active: true,
      },
    ];
  }
  getTerminals() {
    this.terminalsService.getTerminals().subscribe((res) => {
      this.terminals = res;
      this.loading = false;
    });
  }
  reloadField() {
    this.loading = true;
    location.reload();
  }
}
