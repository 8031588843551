import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { IrrigationService } from '../../../irrigation.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  convertFormattedTimeToSeconds,
  DateToSecondsInput,
  removeDirtyInputs,
  saveDataAndShowModal,
  saveSubmitWithTerminalStatus,
  secondsInputToDate,
} from '../../../../../commons/helpers/functions';
import Swal from 'sweetalert2';
import {
  MixtureValvesIrrigationInstallerModel,
  SensorDataModel,
} from '../../../irrigation.model';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { EquipmentsLibService } from '../../../../../services/libraries/equipments-lib.service';
import { Subscription } from 'rxjs';
import { FormFieldHandlerService } from '../../../../libraries/form-field-handler.service';
import { environment } from '../../../../../../environments/environment';
import { InputNumberService } from '../../../../libraries/input-number.service';

@Component({
  selector: 'app-mixture-valves-form',
  templateUrl: './mixture-valves-form.component.html',
  styleUrls: ['./mixture-valves-form.component.scss'],
})
export class MixtureValvesFormComponent implements OnInit, OnDestroy {
  isFetchingData = false;
  isFormSubmitted = false;
  mixtureValvesForm: UntypedFormGroup;
  isConnected: boolean;
  private formFieldErrorSubscription: Subscription;
  private intervalId: any;
  mixtureValvesResponse: MixtureValvesIrrigationInstallerModel[];
  sensors: SensorDataModel[];
  selectedSensor;
  isMixtureValvesWithoutData = false;
  @Input() mode: string;
  @Input() terminal_vid: string;
  @Input() mixture_valve_id: number;
  @Output() dirtyEventEmitter = new EventEmitter<boolean>();
  @Output() mixtureValveEventEmitter = new EventEmitter<any>();
  @Output() formFieldErrorEventEmitter = new EventEmitter<boolean>();

  isErrorInMixtureValvesPost = false;

  constructor(
    private fb: UntypedFormBuilder,
    private irrigationService: IrrigationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public translationsLib: TranslationsLibService,
    private elementRef: ElementRef,
    private equipmentLib: EquipmentsLibService,
    private formFieldHandlerService: FormFieldHandlerService,
    public inputNumberService: InputNumberService
  ) {
    this.mixtureValvesForm = this.fb.group({
      mixtureValve: [null, Validators.required],
      dirty: [''],
      valveTime: [null, Validators.required],
      minPulse: [null, Validators.required],
      verificationTime: [null, Validators.required],
      correctionFactor: [null, Validators.required],
      openEffect: [null, Validators.required],
      ceSensor: [null, Validators.required],
      isDirty: false,
    });
  }

  async startInterval() {
    this.intervalId = setInterval(() => {
      this.performTasks();
    }, environment.intervalDefaultTimeout);
  }

  async performTasks() {
    this.mixtureValvesForm.reset();

    this.getSensors();
    this.getMixtureValves();

    await this.getIrrigationEquipment();

    if (!this.isConnected) {
      this.equipmentLib.showConnectivityAlert();
    }

    this.isFormSubmitted = false;
  }

  ngOnInit() {
    this.mixtureValvesForm.reset(); // Prevent unsaved data to persist in form inputs

    this.activatedRoute.params.subscribe((_) => {
      this.getSensors();
      this.getMixtureValves();

      removeDirtyInputs(this.mixtureValvesForm);
    });

    this.mixtureValvesForm.valueChanges.subscribe((_) => {
      this.dirtyEventEmitter.emit(this.getIsDirty());

      if (this.getIsDirty()) {
        clearInterval(this.intervalId);
      } else {
        clearInterval(this.intervalId);
        this.startInterval();
      }
    });

    this.getIrrigationEquipment();
  }

  getIrrigationEquipment() {
    this.irrigationService
      .getIrrigationTerminal(this.terminal_vid)
      .subscribe((res) => {
        this.isConnected = res.connected;
      });
  }

  setDirty(dirty: boolean) {
    this.mixtureValvesForm.patchValue({ isDirty: dirty });
  }

  getIsDirty() {
    return this.mixtureValvesForm.dirty || this.mixtureValvesForm.value.isDirty;
  }

  getSensors() {
    this.irrigationService.getSensors(this.terminal_vid).subscribe((res) => {
      this.sensors = res;
    });
  }

  onChangeInputTimeWithKeyboard(event, formInput: string) {
    const toSeconds = convertFormattedTimeToSeconds(event.target.value);
    const toDate = secondsInputToDate(toSeconds);
    this.mixtureValvesForm.get(formInput).setValue(toDate);

    this.mixtureValvesForm.markAsDirty();
  }

  getMixtureValves(isGetAfterPost = false) {
    if (!isGetAfterPost) this.isFetchingData = true;

    this.irrigationService
      .getIrrigationInstallerMixtureValves(this.terminal_vid)
      .subscribe((res) => {
        this.mixtureValvesResponse = res;

        if (this.mixtureValvesResponse.length === 0) {
          this.isMixtureValvesWithoutData = true;
          this.isFetchingData = false;
        } else {
          this.mixtureValvesResponse.filter((formula) => {
            if (formula.mixtureValve === this.mixture_valve_id) {
              this.mixtureValveEventEmitter.emit(formula);

              this.mixtureValvesForm.patchValue({
                mixtureValve: formula.mixtureValve,
                dirty: formula.dirty,
                valveTime: secondsInputToDate(formula.valveTime),
                minPulse: secondsInputToDate(formula.minPulse),
                verificationTime: secondsInputToDate(formula.verificationTime),
                correctionFactor: formula.correctionFactor,
                openEffect: formula.openEffect,
                ceSensor:
                  formula.ceSensor === null
                    ? (this.selectedSensor = 255)
                    : (this.selectedSensor = formula.ceSensor),
              });

              if (!isGetAfterPost) this.isFetchingData = false;
            }
          });
        }
      });
  }

  onChangeSensor(event) {
    const element = this.elementRef.nativeElement.querySelector('.form-select');
    element.classList.add('ng-dirty');
    this.setDirty(true);

    this.selectedSensor = event.target.value;
  }

  handlePost(showModal: boolean, connected: boolean): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      this.isFormSubmitted = true;

      const mixtureValvesAsArray = [];

      let filteredSensor: SensorDataModel | number = this.sensors.find(
        (sensor) => sensor.name === this.selectedSensor
      );
      if (filteredSensor === undefined) filteredSensor = null;

      mixtureValvesAsArray.push({
        mixtureValve: this.mixtureValvesForm.value.mixtureValve,
        valveTime: DateToSecondsInput(this.mixtureValvesForm.value.valveTime),
        minPulse: DateToSecondsInput(this.mixtureValvesForm.value.minPulse),
        verificationTime: DateToSecondsInput(
          this.mixtureValvesForm.value.verificationTime
        ),
        correctionFactor: this.mixtureValvesForm.value.correctionFactor,
        openEffect: this.mixtureValvesForm.value.openEffect,
        ceSensor: filteredSensor,
      });

      try {
        this.irrigationService
          .postIrrigationInstallerMixtureValves(
            this.terminal_vid,
            mixtureValvesAsArray
          )
          .subscribe(
            (res) => {
              if (res.error) this.isErrorInMixtureValvesPost = true;

              if (
                connected &&
                !this.mixtureValvesForm.value.dirty &&
                !this.isErrorInMixtureValvesPost
              ) {
                saveDataAndShowModal(
                  res,
                  showModal,
                  this.translationsLib.get('error_updating_data'),
                  this.translationsLib.get('save_changes_success'),
                  this.translationsLib.get('accept'),
                  () => {
                    setTimeout(() => {
                      this.getMixtureValves(true);
                    }, 4000);
                  }
                );

                this.isFormSubmitted = false;
                resolve(true);
              } else if (this.isErrorInMixtureValvesPost) {
                Swal.fire({
                  text: this.translationsLib.get(
                    'irrigation_general_program_error'
                  ),
                  showConfirmButton: true,
                  confirmButtonText: this.translationsLib.get('accept'),
                  icon: 'error',
                });

                this.isFormSubmitted = false;
                resolve(true);
              } else {
                saveSubmitWithTerminalStatus(
                  res,
                  showModal,
                  connected,
                  this.translationsLib.get('error_updating_data'),
                  this.translationsLib.get('save_changes_success'),
                  this.translationsLib.get('accept'),
                  this.translationsLib.get(
                    'irrigation_disconnected_terminal_pending_changes'
                  )
                );

                this.isFormSubmitted = false;
                resolve(true);
              }
            },
            (_) => {
              if (showModal) {
                Swal.fire({
                  text: this.translationsLib.get('something_was_wrong'),
                  showConfirmButton: true,
                  confirmButtonText: this.translationsLib.get('accept'),
                  icon: 'error',
                });
              }
              this.isFormSubmitted = false;
              resolve(false);
            }
          );
      } catch (err) {
        throw new Error(err.message);
      }
    });
  }

  submitForm(
    showModal: boolean = true
  ): Promise<{ result: boolean; connected: boolean }> {
    return new Promise<{ result: boolean; connected: boolean }>(
      (resolve, reject) => {
        try {
          this.irrigationService
            .getIrrigationTerminal(this.terminal_vid)
            .subscribe((res) => {
              this.handlePost(showModal, res.connected).then((result) => {
                resolve({ result, connected: res.connected });
              });
            });
        } catch (err) {
          reject({ result: false, connected: false });
        } finally {
          // Remove dirty inputs due to use standalone ngModels
          removeDirtyInputs(this.mixtureValvesForm);

          this.mixtureValvesForm.markAsUntouched();

          this.mixtureValvesForm.markAsPristine();
          this.mixtureValvesForm.patchValue({ isDirty: false });
        }
      }
    );
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
    this.formFieldErrorSubscription?.unsubscribe();
  }
}
