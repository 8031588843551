import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TerminalsService } from '../../../../terminals/terminals.service';
import { WaterSystemsFieldsConfig } from '../models/water-system-config.model';
import { defaultFieldsConfig } from '../models/water-system-config.model';
import { ConfigurableService } from '../../configurable.service';
import {
  ConfigurableEquipmentModel,
  ConfigurableProgramModel,
} from '../../configurable.model';

@Injectable({
  providedIn: 'root',
})
export class WaterSystemDataService {
  constructor(
    private terminalsService: TerminalsService,
    private configurableService: ConfigurableService
  ) {}

  public fetchEquipment(
    terminalVid: string
  ): Observable<ConfigurableEquipmentModel> {
    return this.configurableService.getEquipment(terminalVid);
  }

  public fetchConfig(): Observable<WaterSystemsFieldsConfig> {
    return new Observable((observer) => {
      observer.next(defaultFieldsConfig);
      observer.complete();
    });
  }

  public fetchPrograms(
    terminalVid: string
  ): Observable<ConfigurableProgramModel[]> {
    return this.configurableService.getPrograms(terminalVid);
  }
}
