import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { RestApiService } from '../services/api/rest-api.service';
import { PermissionListDataModel } from './permission.model';
import {
  AttachDataModel,
  ContactsDataModel,
  DetachDataModel,
} from '../terminals/terminals.model';
import { TerminalDataModel } from '@nutricontrol/app360-shared';
import { FieldDataModel } from '../fields/fields.model';
@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  private restApiService: RestApiService;

  constructor(restApiService: RestApiService) {
    this.restApiService = restApiService;
  }

  public getPermissionsList(): Observable<PermissionListDataModel> {
    return new Observable((observer) => {
      this.restApiService.getPermissionList().subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public pairUsertoField(email, vid, data): Observable<AttachDataModel> {
    return new Observable((observer) => {
      this.restApiService.postAttachUsertoField(email, vid, data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public changeUserFieldPermissions(
    email,
    vid,
    data
  ): Observable<AttachDataModel> {
    return new Observable((observer) => {
      this.restApiService
        .changeUserFieldPermissions(email, vid, data)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }
  public changeUserTerminalPermissions(
    email,
    vid,
    data
  ): Observable<AttachDataModel> {
    return new Observable((observer) => {
      this.restApiService
        .changeUserTerminalPermissions(email, vid, data)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  public pairUsertoTerminal(email, vid, data): Observable<AttachDataModel> {
    return new Observable((observer) => {
      this.restApiService.postAttachUsertoTerminal(email, vid, data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public getUserContacts(vid): Observable<ContactsDataModel> {
    return new Observable((observer) => {
      this.restApiService.getUserContacts(vid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public detachUserFromTerminal(email, vid): Observable<DetachDataModel> {
    return new Observable((observer) => {
      this.restApiService.detachUserFromTerminal(email, vid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public detachInstallerFromTerminal(email, vid): Observable<DetachDataModel> {
    return new Observable((observer) => {
      this.restApiService.detachInstallerFromTerminal(email, vid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public detachUserFromField(email, vid): Observable<DetachDataModel> {
    return new Observable((observer) => {
      this.restApiService.detachUserFromField(email, vid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public getFields(): Observable<FieldDataModel[]> {
    return new Observable((observer) => {
      this.restApiService.getFields().subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public getTerminals(): Observable<[TerminalDataModel]> {
    return new Observable((observer) => {
      this.restApiService.getTerminals().subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public postPairingAttachToDemo(): Observable<AttachDataModel> {
    return new Observable((observer) => {
      this.restApiService.postPairingAttachToDemo().subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public deletePairingDetachFromDemo(): Observable<DetachDataModel> {
    return new Observable((observer) => {
      this.restApiService.deletePairingDetachFromDemo().subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
}
