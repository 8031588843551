<app-main-header [breadcrumbs]="breadcrumbs"></app-main-header>

<ion-content>
  <div *ngIf="!isSensorsWithoutData" class="container-fluid py-4">
    <div class="card">
      <div class="card-body">
        <h2 class="card-title my-2">
          {{ translationsLib.get('sensors_title') }}
        </h2>
        <app-sensors-list
          type="irrigation"
          (isSensorsWithoutDataEventEmitter)="isSensorsWithoutData = $event"
        ></app-sensors-list>
      </div>
    </div>
  </div>
</ion-content>
