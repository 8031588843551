import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormArray,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  pressureAndWashingTimesModel,
  Progfiltros,
  PumpsDataModel,
} from '../../../irrigation.model';
import { IrrigationService } from '../../../irrigation.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import {
  convertFormattedTimeToSeconds,
  DateToSecondsInput,
  removeDirtyInputs,
  saveDataAndShowModal,
  saveSubmitWithTerminalStatus,
  secondsInputToDate,
} from '../../../../../commons/helpers/functions';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { EquipmentsLibService } from '../../../../../services/libraries/equipments-lib.service';
import { Subscription } from 'rxjs';
import { FormFieldHandlerService } from '../../../../libraries/form-field-handler.service';
import { environment } from '../../../../../../environments/environment';
import { InputNumberService } from '../../../../libraries/input-number.service';

@Component({
  selector: 'app-filters-form',
  templateUrl: './filters-form.component.html',
  styleUrls: ['./filters-form.component.scss'],
})
export class FiltersFormComponent implements OnInit, OnDestroy {
  isFetchingData = false;
  isFormSubmitted = false;
  private formFieldErrorSubscription: Subscription;
  isFiltersWithoutData = false;
  isConnected: boolean;
  private intervalId: any;
  filtersForm: UntypedFormGroup;
  filtersResponse;
  layoutConfiguration: Progfiltros;

  isErrorInFiltersPost = false;

  pumpsList: PumpsDataModel[];
  programsStopList = [
    {
      value: 'NOTHING',
      name: 'Nada',
    },
    {
      value: 'IRRIGATION',
      name: this.translationsLib.get('irrigation_fertilized'),
    },
    {
      value: 'TOTAL',
      name: this.translationsLib.get('irrigation_total'),
    },
  ];

  @Input() mode: string;
  @Input() terminal_vid: string;
  @Input() filter_id: number;

  @Output() dirtyEventEmitter = new EventEmitter<boolean>();
  @Output() nameEventEmitter = new EventEmitter<string>();
  @Output() filterEventEmitter = new EventEmitter<any>();
  @Output() formFieldErrorEventEmitter = new EventEmitter<boolean>();

  constructor(
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private irrigationService: IrrigationService,
    private router: Router,
    public translationsLib: TranslationsLibService,
    private equipmentLib: EquipmentsLibService,
    private formFieldHandlerService: FormFieldHandlerService,
    public inputNumberService: InputNumberService
  ) {
    this.filtersForm = this.fb.group({
      waterSystem: [null],
      dirty: [''],
      active: [null, Validators.required],
      pressureSwitchDelay: [null, Validators.required],
      reposeTime: [null, Validators.required],
      rotation: [null, Validators.required],
      programsStop: [null, Validators.required],
      sustainingTime: [null, Validators.required],
      pump1: [null, Validators.required],
      pump2: [null, Validators.required],
      pump3: [null, Validators.required],
      timeBetweenCleanings: [null, Validators.required],
      volumeBetweenCleanings: [null, Validators.required],
      frequentCleanings: [null, Validators.required],
      timeFrequentCleanings: [null, Validators.required],
      pressureAndWashingTimes: this.fb.array([]),
      isDirty: false,
    });
  }

  async startInterval() {
    this.intervalId = setInterval(() => {
      this.performTasks();
    }, environment.intervalDefaultTimeout);
  }

  async performTasks() {
    // I don't know why but in this form, if I uncommented the below line, the form does not get the data
    // this.filtersForm.reset();

    await this.getLayoutConfiguration();
    this.getPumpsList();
    this.getFilters();

    await this.getIrrigationEquipment();

    if (!this.isConnected) {
      this.equipmentLib.showConnectivityAlert();
    }

    this.isFormSubmitted = false;
  }

  ngOnInit() {
    this.filtersForm.reset(); // Prevent unsaved data to persist in form inputs

    this.activatedRoute.params.subscribe(async (_) => {
      await this.getLayoutConfiguration();
      this.getPumpsList();
      this.getFilters();

      removeDirtyInputs(this.filtersForm);
    });

    this.filtersForm.valueChanges.subscribe((_) => {
      this.dirtyEventEmitter.emit(this.getIsDirty());

      if (this.getIsDirty()) {
        clearInterval(this.intervalId);
      } else {
        clearInterval(this.intervalId);
        this.startInterval();
      }
    });

    this.getIrrigationEquipment();
  }

  getIrrigationEquipment() {
    this.irrigationService
      .getIrrigationTerminal(this.terminal_vid)
      .subscribe((res) => {
        this.isConnected = res.connected;
      });
  }

  async getLayoutConfiguration(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.irrigationService
        .getTerminalLayoutConfiguration(this.terminal_vid)
        .subscribe((res) => {
          this.layoutConfiguration = res.screens_configuration.progfiltros;
          if (!this.layoutConfiguration.enabled) {
            this.router.navigate(['/home/dashboard']);
          }

          resolve();
        }, reject);
    });
  }

  setDirty(dirty) {
    this.filtersForm.patchValue({ isDirty: dirty });
  }

  getIsDirty() {
    return this.filtersForm.dirty || this.filtersForm.value.isDirty;
  }

  getPumpsList() {
    this.irrigationService.getPumps(this.terminal_vid).subscribe((res) => {
      this.pumpsList = res;
    });
  }

  get pressureAndWashingTimesArr() {
    return this.filtersForm.get('pressureAndWashingTimes') as FormArray;
  }

  addPressureAndWashingTimesFormGroup(
    pressureAndWashingTimes: pressureAndWashingTimesModel
  ) {
    this.pressureAndWashingTimesArr.push(
      this.fb.group({
        filter: this.fb.control(pressureAndWashingTimes.filter),
        washingTime: this.fb.control(pressureAndWashingTimes.washingTime),
        pressure: this.fb.control(pressureAndWashingTimes.pressure),
        myTime: this.fb.control(
          secondsInputToDate(pressureAndWashingTimes.washingTime)
        ),
      })
    );
  }

  getFilters(isGetAfterPost = false) {
    if (!isGetAfterPost) this.isFetchingData = true;

    this.irrigationService
      .getIrrigationInstallerFilters(this.terminal_vid)
      .subscribe((res) => {
        this.filtersResponse = res;

        if (this.filtersResponse.length === 0) {
          this.isFiltersWithoutData = true;
          this.isFetchingData = false;
        } else {
          this.filtersResponse.filter((data) => {
            if (data.waterSystem === this.filter_id) {
              this.filterEventEmitter.emit(data);

              this.nameEventEmitter.emit(data.filter);

              for (const pressureAndWashingTime of data.pressureAndWashingTimes.slice(
                0,
                this.layoutConfiguration.blocks.progfiltrosm3k_lavados.max
              )) {
                this.addPressureAndWashingTimesFormGroup(
                  pressureAndWashingTime
                );
              }

              this.filtersForm.patchValue({
                waterSystem: data.waterSystem,
                dirty: data.dirty,
                active: data.active,
                pressureSwitchDelay: secondsInputToDate(
                  data.pressureSwitchDelay
                ),
                reposeTime: secondsInputToDate(data.reposeTime),
                rotation: data.rotation,
                programsStop: data.programsStop,
                sustainingTime: secondsInputToDate(data.sustainingTime),
                pump1: data.pump1,
                pump2: data.pump2,
                pump3: data.pump3,
                timeBetweenCleanings: secondsInputToDate(
                  data.timeBetweenCleanings
                ),
                volumeBetweenCleanings: data.volumeBetweenCleanings,
                frequentCleanings: data.frequentCleanings,
                timeFrequentCleanings: secondsInputToDate(
                  data.timeFrequentCleanings
                ),
              });
            }

            if (!isGetAfterPost) this.isFetchingData = false;
          });
        }
      });
  }

  onChangeInputTimeWithKeyboard(event, control, isControlWithString = false) {
    const toSeconds = convertFormattedTimeToSeconds(event.target.value);
    const toDate = secondsInputToDate(toSeconds);

    event.target.classList.add('ng-dirty');
    this.filtersForm.markAsDirty();

    if (isControlWithString) {
      this.filtersForm.get(control).setValue(toDate);
    } else {
      const pressureGroup = this.pressureAndWashingTimesArr.at(
        control.value.filter
      ) as FormGroup;
      pressureGroup.patchValue({
        myTime: toDate,
      });
      control.value.washingTime = DateToSecondsInput(control.value.myTime);
    }
  }

  handlePost(showModal: boolean, connected: boolean): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      this.isFormSubmitted = true;

      const filtersFormAsArray = [];
      filtersFormAsArray.push({
        waterSystem: this.filtersForm.value.waterSystem,
        active: this.filtersForm.value.active,
        pressureSwitchDelay: DateToSecondsInput(
          this.filtersForm.value.pressureSwitchDelay
        ),
        reposeTime: DateToSecondsInput(this.filtersForm.value.reposeTime),
        rotation: this.filtersForm.value.rotation,
        programsStop: this.filtersForm.value.programsStop,
        sustainingTime: DateToSecondsInput(
          this.filtersForm.value.sustainingTime
        ),
        pump1: this.filtersForm.value.pump1,
        pump2: this.filtersForm.value.pump2,
        pump3: this.filtersForm.value.pump3,
        timeBetweenCleanings: DateToSecondsInput(
          this.filtersForm.value.timeBetweenCleanings
        ),
        volumeBetweenCleanings: this.filtersForm.value.volumeBetweenCleanings,
        frequentCleanings: this.filtersForm.value.frequentCleanings,
        timeFrequentCleanings: DateToSecondsInput(
          this.filtersForm.value.timeFrequentCleanings
        ),
        pressureAndWashingTimes: this.pressureAndWashingTimesArr.value.map(
          (data) => {
            const { myTime, ...rest } = data;
            return rest;
          }
        ),
      });

      try {
        this.irrigationService
          .postIrrigationInstallerFilters(this.terminal_vid, filtersFormAsArray)
          .subscribe(
            (res) => {
              if (res.error) this.isErrorInFiltersPost = true;

              if (
                connected &&
                !this.filtersForm.value.dirty &&
                !this.isErrorInFiltersPost
              ) {
                saveDataAndShowModal(
                  res,
                  showModal,
                  this.translationsLib.get('error_updating_data'),
                  this.translationsLib.get('save_changes_success'),
                  this.translationsLib.get('accept'),
                  () => {
                    setTimeout(() => {
                      this.getFilters(true);
                    }, 4000);
                  }
                );

                this.isFormSubmitted = false;
                resolve(true);
              } else if (this.isErrorInFiltersPost) {
                Swal.fire({
                  text: this.translationsLib.get(
                    'irrigation_general_program_error'
                  ),
                  showConfirmButton: true,
                  confirmButtonText: this.translationsLib.get('accept'),
                  icon: 'error',
                });

                this.isFormSubmitted = false;
                resolve(true);
              } else {
                saveSubmitWithTerminalStatus(
                  res,
                  showModal,
                  connected,
                  this.translationsLib.get('error_updating_data'),
                  this.translationsLib.get('save_changes_success'),
                  this.translationsLib.get('accept'),
                  this.translationsLib.get(
                    'irrigation_disconnected_terminal_pending_changes'
                  )
                );

                this.isFormSubmitted = false;
                resolve(true);
              }
            },
            (_) => {
              if (showModal) {
                Swal.fire({
                  text: this.translationsLib.get('something_was_wrong'),
                  showConfirmButton: true,
                  confirmButtonText: this.translationsLib.get('accept'),
                  icon: 'error',
                });
              }
              this.isFormSubmitted = false;
              resolve(false);
            }
          );
      } catch (err) {
        throw new Error(err.message);
      }
    });
  }

  submitForm(
    showModal: boolean = true
  ): Promise<{ result: boolean; connected: boolean }> {
    return new Promise<{ result: boolean; connected: boolean }>(
      (resolve, reject) => {
        try {
          this.irrigationService
            .getIrrigationTerminal(this.terminal_vid)
            .subscribe((res) => {
              this.handlePost(showModal, res.connected).then((result) => {
                resolve({ result, connected: res.connected });
              });
            });
        } catch (err) {
          reject({ result: false, connected: false });
        } finally {
          // Remove dirty inputs due to use standalone ngModels
          removeDirtyInputs(this.filtersForm);

          this.filtersForm.markAsUntouched();

          this.filtersForm.markAsPristine();
          this.filtersForm.patchValue({ isDirty: false });
        }
      }
    );
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
    this.formFieldErrorSubscription?.unsubscribe();
  }
}
