<div class="col float-end">
  <ng-container *ngIf="showSettings">
    <ion-button
      [matMenuTriggerFor]="menu"
      aria-label="Example icon-button with a menu"
      [ngClass]="{
        'btn-height': !activationButtons
      }"
    >
      <ion-icon class="dots-icon" name="ellipsis-vertical-outline"></ion-icon>
    </ion-button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item>
        <button
          class="grid-list button-selector"
          (click)="toggle()"
          [ngClass]="{ active: isToggle, animation: isClicked }"
        >
          <div class="icon">
            <div class="lines"><i></i><i></i><i></i><i></i></div>
            <div class="dots"><i></i><i></i><i></i><i></i></div>
          </div>
          <div class="text">
            <span id="advanced">{{
              translationsLib.get('switch_to_advanced_mode')
            }}</span>
            <span id="simple">{{
              translationsLib.get('switch_to_simple_mode')
            }}</span>
          </div>
        </button>
      </button>
      <button
        class="app-mode-edition"
        mat-menu-item
        *ngIf="allowEditionButton"
        (click)="setEditionLayout()"
      >
        <button class="grid-list button-selector">
          <span class="text">{{
            allowEdition
              ? translationsLib.get('climate_menu_exit_edit_screen')
              : translationsLib.get('climate_menu_edit_screen') +
                ' ' +
                currentScreen
          }}</span>
        </button>
      </button>
      <button class="app-mode-copy" mat-menu-item *ngIf="domain === 'climate'">
        <button class="grid-list button-selector" (click)="generateCopy()">
          <span class="text">{{
            translationsLib.get('climate_menu_copy')
          }}</span>
        </button>
      </button>
    </mat-menu>
  </ng-container>
</div>
