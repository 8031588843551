import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import * as moment from 'moment/moment';
import { LineChartsModel } from '../charts-model';
import SunCalc from 'suncalc';
import * as Highcharts from 'highcharts/highstock';
import HC_accessibility from 'highcharts/modules/accessibility';
import theme from 'highcharts/themes/high-contrast-light';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
theme(Highcharts);
Exporting(Highcharts);
ExportData(Highcharts);
HC_accessibility(Highcharts);

import {
  App360SharedModule,
  TranslationsLibService,
} from '@nutricontrol/app360-shared';
import { HighchartsLibService } from '../../services/libraries/highcharts-lib.service';
import { GraphicsService } from '../graphics.service';
import { GraphicsLibService } from '../../services/libraries/graphics-lib.service';
import { DataloggerService } from '../../farming/datalogger/datalogger.service';
import { PlatformLibService } from '@nutricontrol/app360-shared';
import { AuthModule } from '../../auth/auth.module';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgForOf, NgIf } from '@angular/common';
import { SwiperModule } from 'swiper/angular';
import { AppCommonsModule } from '../../commons/app-commons.module';
import Swal from 'sweetalert2';
import { IonicModule } from '@ionic/angular';

@Component({
  standalone: true,
  selector: 'app-charts-component',
  templateUrl: './charts-component.component.html',
  styleUrls: ['./charts-component.component.scss'],
  imports: [
    AuthModule,
    HighchartsChartModule,
    NgIf,
    NgForOf,
    SwiperModule,
    AppCommonsModule,
    IonicModule,
    App360SharedModule,
  ],
})
export class ChartsComponentComponent implements OnInit {
  @Input() terminal_vid;
  @Input() time = null;
  @Input() sensorConnected;
  @Input() elementsDisposition = 1;
  @Input() equipment;
  @Input() multiDeviceData = null;
  @Input() fields = [];
  @ViewChild('swiper') swiper;
  @Output() isData: EventEmitter<any> = new EventEmitter();
  chartError = false;
  operationInCharts = [];
  chart;
  updateFlag = false;
  chartConstructor = 'chart';
  chartCallback;
  Highcharts = Highcharts;
  consumptionChart: any = LineChartsModel;
  gotData = null;
  multipleCursor: any = {};
  private latitude;
  private longitude;
  loaded = false;
  content;
  magnitudesInChart = 0;
  constructor(
    private highchartsLib: HighchartsLibService,
    private dataloggerService: DataloggerService,
    private graphicsService: GraphicsService,
    private graphicsLib: GraphicsLibService,
    public translationsLib: TranslationsLibService,
    public platformLib: PlatformLibService
  ) {}

  ngOnInit() {
    Highcharts.setOptions({
      colors: this.highchartsLib.getGlobalBluePaletteColors(),
      lang: this.highchartsLib.getLangOptions(),
    });
    this.getChartsData();
  }
  getOrientation(): string {
    return window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';
  }
  getChartsData() {
    setTimeout(() => {
      if (this.multiDeviceData === null) {
        this.getDataloggerPosition();
        if(this.sensorConnected !== null) {
          if (this.sensorConnected.length === 1) {
            this.onlyOneSensordata(this.sensorConnected[0]);
          } else {
            this.variousSensorsData(this.sensorConnected);
          }
        }
      } else {
        this.multiGraph();
      }
    }, 500);
  }
  getDataloggerPosition() {
    this.latitude = this.equipment.latitude;
    this.longitude = this.equipment.longitude;
  }
  onlyOneSensordata(sensorData) {
    this.loaded = false;
    this.gotData = [];
    const date = new Date();
    const date2 = new Date();
    let sunset;
    let sunrise;
    let contador = 1;
    date2.setDate(date.getDate() - this.time);
    const data = {
      nexp: sensorData.nexp,
      nent: sensorData.nent,
      physicalType: sensorData.physicalType,
      begin: moment(date2).format('YYYY/MM/DD HH:MM:ss'),
      end: moment(date).format('YYYY/MM/DD HH:MM:ss'),
      interval: 300,
    };

    const dataSeries = [];
    const series_x = [];
    const plotBands = [];
    let dataAxis;
    let sensorName;
    let sensorUnit;
    this.dataloggerService
      .getChartsData(this.terminal_vid, data)
      .subscribe((res) => {
        let max = null;
        let min = null;
        let media = 0;
        res.data.forEach((data, index) => {
          media = media + data.value;
          if (max === null) {
            max = data.value;
          } else {
            if (max < data.value) {
              max = data.value;
            }
          }
          if (min === null) {
            min = data.value;
          } else {
            if (min > data.value) {
              min = data.value;
            }
          }
          const fecha = moment.utc(data.timestamp).format('DD/MM HH:mm');
          dataSeries.push(data.value);
          const times = SunCalc.getTimes(
            data.timestamp,
            this.latitude,
            this.longitude
          );

          if (
            moment.utc(data.timestamp).format('DD/MM HH:MM') ===
            moment.utc(times.sunrise).format('DD/MM HH:MM')
          ) {
            sunrise = index;
          }
          if (
            moment.utc(data.timestamp).format('DD/MM HH:MM') ===
            moment.utc(times.sunset).format('DD/MM HH:MM')
          ) {
            sunset = index;
          }

          if (
            sunrise > sunset &&
            sunrise !== undefined &&
            sunset !== undefined
          ) {
            const i = plotBands.findIndex((x) => x.from === sunset);
            if (i === -1) {
              plotBands.push({
                color: 'rgba(3,27,128,0.15)',
                fillOpacity: 0.1,
                from: sunset,
                to: sunrise,
              });
            }
          }
          series_x.push(fecha);
        });
        sensorData.dataName = sensorData.name;
        sensorData.min = min;
        sensorData.max = max;
        sensorData.media = Number((media / res.data.length).toFixed(2));
        dataAxis = {
          name: sensorData.name,
          unit: sensorData.unit,
          data: dataSeries,
        };

        sensorName = sensorData.name;
        sensorUnit = sensorData.unit;
        if (this.time >= 7 && this.time < 30) {
          this.consumptionChart.xAxis.tickInterval = this.time * 4;
        }
        if (this.time >= 30) {
          this.consumptionChart.xAxis.tickInterval = this.time * 3;
        }
        contador = contador - 1;
        if (contador === 0) {
          this.paintOneSensorGraphs(
            series_x,
            plotBands,
            dataAxis,
            sensorName,
            sensorUnit,
            sensorData.subtype
          );
        }
      });
  }
  paintOneSensorGraphs(
    series_x,
    plotBands,
    dataAxis,
    sensorName,
    sensorUnit,
    subtype
  ) {
    this.consumptionChart.lang = {
      contextButtonTitle: this.translationsLib.get('graphics_context_menu'),
    };
    this.consumptionChart.plotOptions.series = { lineWidth: 2 };
    this.consumptionChart.xAxis.categories = series_x;
    this.consumptionChart.xAxis.plotBands = plotBands;
    this.consumptionChart.yAxis = [];
    this.consumptionChart.series = dataAxis;
    this.consumptionChart.navigator.series = dataAxis.data;
    this.consumptionChart.navigator.xAxis.categories = series_x;
    this.consumptionChart.yAxis.push({
      title: {
        text: this.translationsLib.get(subtype),
      },
    });
    const self = this;
    this.consumptionChart.tooltip.formatter = function () {
      const points = this.points;
      const pointsLength = points.length;
      let tooltipMarkup = pointsLength
        ? '<span style="font-size: 10px">' + points[0].key + '</span><br/>'
        : '';
      let index;
      let y_value_kwh;
      for (index = 0; index < pointsLength; index += 1) {
        y_value_kwh = (points[index].y / 1000).toFixed(2);
        self.multipleCursor[sensorName] = points[index].y;
        tooltipMarkup +=
          '<span style="color:' +
          points[index].series.color +
          '">\u25CF</span> ' +
          sensorName +
          ': <b>' +
          points[index].y +
          sensorUnit +
          ' </b><br/>';
      }

      return tooltipMarkup;
    };
    if (this.platformLib.isNative()) {
      this.consumptionChart.exporting.enabled = false;
    }
    this.gotData = this.consumptionChart.series.data;
    this.isData.emit(this.gotData);
    this.updateFlag = true;
    this.loaded = true;
  }

  variousSensorsData(sensorsData) {
    this.loaded = false;
    this.gotData = [];
    const series = [];
    let series_x = [];
    let plotBands = [];
    let count = sensorsData.length;
    const date = new Date();
    const date2 = new Date();
    const allSeries = [];
    date2.setDate(date.getDate() - this.time);
    const data = {
      sensors: sensorsData,
      begin: moment(date2).format('YYYY-MM-DD HH:mm:ss'),
      end: moment(date).format('YYYY-MM-DD HH:mm:ss'),
      interval: 300,
    };
    this.dataloggerService
      .postChartsData(this.terminal_vid, data)
      .subscribe((res) => {
        this.sensorConnected = res.sensors;
        res.sensors.forEach((sensor, index) => {
          const sensorCategories = [];
          const dataSeries = [];
          let max = null;
          let min = null;
          let media = 0;
          allSeries.push(sensor.data);
          sensor.data.forEach((data, index) => {
            media = media + data.value;
            if (max === null) {
              max = data.value;
            } else {
              if (max < data.value) {
                max = data.value;
              }
            }
            if (min === null) {
              min = data.value;
            } else {
              if (min > data.value) {
                min = data.value;
              }
            }
            /*data.timestamp = moment.utc(data.timestamp).format('DD/MM HH:mm');*/
            sensorCategories.push(data);
            dataSeries.push(data.value);
          });
          sensor.min = min;
          sensor.max = max;
          sensor.media = Number((media / sensor.data.length).toFixed(2));
          sensor.dataName = sensor.name;
          const data = {
            name: sensor.name,
            unit: sensor.unit,
            subtype: sensor.subtype,
            data: dataSeries,
            categories: sensorCategories,
          };
          series.push(data);
          count = count - 1;
          this.multipleCursor[sensor.name] = '-';
          if (count === 0) {
            const dataTimed = this.graphicsLib.combineAndSortArrays(
              allSeries,
              series
            );
            plotBands = this.graphicsLib.getPlotBands(
              series[0],
              this.latitude,
              this.longitude
            );
            this.donegraph(dataTimed.series_x, plotBands, dataTimed.series);
          }
        });
      });
    /*});*/
  }
  donegraph(series_x, plotBands, series) {
    this.consumptionChart.lang = {
      contextButtonTitle: this.translationsLib.get('graphics_context_menu'),
    };
    if (this.time >= 7 && this.time < 30) {
      this.consumptionChart.xAxis.tickInterval = this.time * 4;
    }
    if (this.time >= 30) {
      this.consumptionChart.xAxis.tickInterval = this.time * 3;
    }
    if (this.time === null && this.multiDeviceData.terminals) {
      this.consumptionChart.xAxis.tickPositions =
        this.graphicsLib.getMuestrasInterval(
          this.multiDeviceData.terminals,
          series_x
        );
      this.consumptionChart.xAxis.type = 'datetime';
      this.consumptionChart.xAxis.labels = { format: '{value: %e/%m %H:%M}' };
    }
    this.consumptionChart.plotOptions.series = { lineWidth: 2 };
    this.consumptionChart.xAxis.categories = series_x;
    this.consumptionChart.xAxis.plotBands = plotBands;
    this.consumptionChart.series = series;
    if (this.platformLib.isNative()) {
      this.consumptionChart.exporting.enabled = false;
    }
    if (this.multiDeviceData) {
      if (
        !this.multiDeviceData.metrics ||
        this.multiDeviceData.metrics.length === 0
      ) {
        if (this.multiDeviceData.terminals[0].coming === 'metrics') {
          //Aqui busco saber si la métrica no está guardada y por lo tanto solo se quiere previsualizar
          this.consumptionChart.series = this.graphicsLib.chooseOperations(
            series,
            this.multiDeviceData.terminals[0].interval
          );
          this.operationInCharts = this.consumptionChart.series;
        } else {
          this.operationInCharts = [];
        }
      } else {
        this.multiDeviceData.metrics.forEach((metric) => {
          const serie = this.graphicsLib.getSerie(metric.payload);
          this.consumptionChart.series = this.consumptionChart.series.concat(
            this.graphicsLib.chooseOperations(
              serie,
              this.multiDeviceData.terminals[0].interval
            )
          );
        });
        this.operationInCharts = this.consumptionChart.series;
      }
    }
    this.consumptionChart.yAxis = [];
    const legends = [];
    const dataNavigator = [];
    let exportingName = '';
    const fechaString =
      moment.utc(series_x[0]).format('DD/MM/YYYY') +
      '_' +
      moment.utc(series_x[series_x.length - 1]).format('DD/MM/YYYY');
    this.consumptionChart.series.forEach((serie, index) => {
      exportingName = exportingName + '(' + serie.name + ')';
      dataNavigator.push({ data: serie.data });
      if (legends.length === 0) {
        legends.push(serie.subtype);
        this.magnitudesInChart++;
        if ((index + 1) % 2 === 0) {
          this.consumptionChart.yAxis.push({
            title: {
              text: this.translationsLib.get(serie.subtype),
            },
            opposite: true,
          });
        } else {
          this.consumptionChart.yAxis.push({
            title: {
              text: this.translationsLib.get(serie.subtype),
            },
          });
        }
      } else {
        if (!legends.includes(serie.subtype)) {
          this.magnitudesInChart++;
          legends.push(serie.subtype);
          if ((index + 1) % 2 === 0) {
            this.consumptionChart.yAxis.push({
              title: {
                text: this.translationsLib.get(serie.subtype),
              },
              opposite: true,
            });
          } else {
            this.consumptionChart.yAxis.push({
              title: {
                text: this.translationsLib.get(serie.subtype),
              },
            });
          }
        }
      }
      if (serie.data.length > 0) {
        this.gotData = serie.data;
      }
      this.consumptionChart.yAxis.forEach((axis, index) => {
        if (axis.title.text === this.translationsLib.get(serie.subtype)) {
          serie.yAxis = index;
        }
      });
    });
    /*if (this.platformLib.isNative()) {
      this.consumptionChart.rangeSelector.enabled = false;
    } else{
      /!*this.consumptionChart.rangeSelector.buttons[0].text =
        this.translationsLib.get('reset_zoom');*!/
    }*/
    this.consumptionChart.exporting.filename =
      fechaString + '_' + exportingName;
    /*if (!(fechaString.length + exportingName.length > 100)) {
      this.consumptionChart.exporting.filename =
        fechaString + '_' + exportingName;
    } else {
      let number = 0;
      for (let i = 0; i < exportingName.length && number === 0; i++){
        if ((fechaString.length + exportingName.length)-i <=100){
          number = i;
          exportingName.slice(0, -i);
        }
      }
    }*/
    this.consumptionChart.navigator.series = dataNavigator;
    this.consumptionChart.navigator.xAxis.categories = series_x;
    this.updateFlag = true;
    const self = this;
    this.consumptionChart.tooltip.formatter = function () {
      const points = this.points;
      const pointsLength = points.length;
      let tooltipMarkup = '';
      if (self.multiDeviceData) {
        tooltipMarkup = pointsLength
          ? '<span style="font-size: 10px">' +
            moment.utc(points[0].key).format('DD/MM HH:mm') +
            '</span><br/>'
          : '';
      } else {
        tooltipMarkup = pointsLength
          ? '<span style="font-size: 10px">' + points[0].key + '</span><br/>'
          : '';
      }
      let index;
      let y_value_kwh;
      for (index = 0; index < pointsLength; index += 1) {
        y_value_kwh = (points[index].y / 1000).toFixed(2);
        self.multipleCursor[points[index].series.name] = points[index].y;
        if (points[index].series.userOptions.unit != null) {
          tooltipMarkup +=
            '<span style="color:' +
            points[index].series.color +
            '">\u25CF</span> ' +
            points[index].series.name +
            ': <b>' +
            points[index].y +
            points[index].series.userOptions.unit +
            ' </b><br/>';
        } else {
          tooltipMarkup +=
            '<span style="color:' +
            points[index].series.color +
            '">\u25CF</span> ' +
            points[index].series.name +
            ': <b>' +
            points[index].y +
            ' </b><br/>';
        }
      }

      return tooltipMarkup;
    };
    this.isData.emit(this.gotData);
    this.loaded = true;
  }

  private multiGraph() {
    this.dataloggerService
      .getDataloggerData(this.multiDeviceData.terminals[0].terminal_vid)
      .subscribe(
        (response) => {
          this.latitude = response.latitude;
          this.longitude = response.longitude;
          this.graphicsService
            .postMultiGraph(this.multiDeviceData)
            .subscribe((res) => {
              this.sensorConnected = [];
              this.multiDeviceData.metrics = res.metrics;
              res.terminals.forEach((device) => {
                if (device.devices && device.devices.length > 0) {
                  device.sensors = device.sensors.concat(device.devices);
                }
                this.sensorConnected = this.sensorConnected.concat(
                  device.sensors
                );
              });
              this.loaded = false;
              this.gotData = [];
              const series = [];
              /*const series_x = [];*/
              let sunset;
              let sunrise;
              const plotBands = [];
              let plotBandsDone = false;
              let count = this.sensorConnected.length;
              if (this.sensorConnected) {
                this.sensorConnected.forEach((sensor) => {
                  const series_x = [];
                  const dataSeries = [];
                  let max = null;
                  let min = null;
                  let media = 0;
                  sensor.data.forEach((data, index) => {
                    data.timestamp = Number(data.timestamp);
                    if (data.value != null) {
                      data.value = Number(data.value);
                      media = media + data.value;
                      if (max === null) {
                        max = data.value;
                      } else {
                        if (max < data.value) {
                          max = data.value;
                        }
                      }
                      if (min === null) {
                        min = data.value;
                      } else {
                        if (min > data.value) {
                          min = data.value;
                        }
                      }
                      /*const fecha = moment(data.timestamp).format('DD/MM HH:mm');*/
                      dataSeries.push([data.timestamp, data.value]);
                      const times = SunCalc.getTimes(
                        data.timestamp,
                        this.latitude,
                        this.longitude
                      );

                      if (
                        moment(data.timestamp).format('DD/MM HH:MM') ===
                        moment(times.sunrise).format('DD/MM HH:MM')
                      ) {
                        sunrise = data.timestamp;
                      }
                      if (
                        moment(data.timestamp).format('DD/MM HH:MM') ===
                        moment(times.sunset).format('DD/MM HH:MM')
                      ) {
                        sunset = data.timestamp;
                      }

                      if (
                        sunrise > sunset &&
                        sunrise !== undefined &&
                        sunset !== undefined &&
                        !plotBandsDone
                      ) {
                        const i = plotBands.findIndex((x) => x.from === sunset);
                        if (i === -1) {
                          plotBands.push({
                            color: 'rgba(3,27,128,0.1)',
                            fillOpacity: 0.1,
                            from: sunset,
                            to: sunrise,
                          });
                        }
                      }
                      series_x.push(data.timestamp);
                    }
                  });
                  plotBandsDone = true;
                  sensor.min = min;
                  sensor.max = max;
                  sensor.media = Number(
                    (media / sensor.data.length).toFixed(2)
                  );
                  let data;
                  if (
                    this.multiDeviceData.terminals.length > 1 ||
                    (this.multiDeviceData.metrics &&
                      this.multiDeviceData.metrics.length > 0)
                  ) {
                    this.multiDeviceData.terminals.forEach((terminal) => {
                      terminal.sensors.forEach((sensores) => {
                        if (
                          sensores.name === sensor.name &&
                          sensores.nent === sensor.nent &&
                          sensores.nexp === sensor.nexp &&
                          sensores.physicalType === sensor.physicalType
                          && sensor.terminal_vid &&
                          String(sensor.terminal_vid) === String(terminal.terminal_vid)
                        ) {
                          sensor.deviceName = sensores.deviceName;
                        }
                      });
                      if (terminal.devices && terminal.devices.length > 0) {
                        terminal.devices.forEach((sensores) => {
                          if (
                            Number(sensores.ref1) === sensor.ref1 &&
                            Number(sensores.ref2) === sensor.ref2 &&
                            Number(sensores.ref3) === sensor.ref3 &&
                            sensores.name ===
                              this.translationsLib.get(sensor.name)
                          ) {
                            sensor.name = this.translationsLib.get(sensor.name);
                            sensor.deviceName = sensores.deviceName;
                          }
                        });
                      }
                    });
                  }
                  if (sensor.zone === 255) {
                    sensor.subtype = sensor.name; //esto es por la meteo
                  }
                  if(this.multiDeviceData.terminals.length > 1){
                    sensor.dataName = sensor.deviceName+ ' - '+ sensor.name;
                  }
                  else{
                    sensor.dataName = sensor.name;
                  }
                  if (sensor.subtype) {
                    data = {
                      name: sensor.dataName,
                      id: String(sensor.terminal_vid) + '_' + String(sensor.nexp)+ '_' + String(sensor.nent)+ '_' + String(sensor.physicalType),
                      deviceName: sensor.deviceName,
                      unit: sensor.unit,
                      subtype: sensor.subtype,
                      operation: this.multiDeviceData.terminals[0].operation,
                      data: dataSeries,
                    };
                  } else {
                    if(this.multiDeviceData.terminals.length > 1){

                    }
                    data = {
                      name: sensor.dataName,
                      id: String(sensor.terminal_vid) + '_' + String(sensor.nexp)+ '_' + String(sensor.nent)+ '_' + String(sensor.physicalType),
                      unit: sensor.unit,
                      deviceName: sensor.deviceName,
                      /*subtype: sensor.magnitude,*/
                      operation: this.multiDeviceData.terminals[0].operation,
                      subtype: sensor.typeName,
                      data: dataSeries,
                    };
                  }
                  series.push(data);
                  count = count - 1;
                  this.multipleCursor[sensor.dataName] = '-';
                  if (count === 0) {
                    this.donegraph(series_x, plotBands, series);
                  }
                });
              } else {
                Swal.fire({
                  icon: 'error',
                  text: this.translationsLib.get('graphics_no_data'),
                });
              }
            });
        },
        (error) => {
          this.loaded = true;
          this.chartError = true;
        }
      );
  }
}
