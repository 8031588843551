import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import * as moment from 'moment';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { NgClass } from '@angular/common';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { FarmingModule } from '../../farming.module';
import {
  secondsInputToDate,
  DateToSecondsInput,
} from '../../../commons/helpers/functions';
import { NouisliderComponent } from 'ng2-nouislider';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-irrigation-time-slider',
  templateUrl: './irrigation-time-slider.component.html',
  styleUrls: ['./irrigation-time-slider.component.scss'],
  standalone: true,
  imports: [NgClass, PopoverModule, FarmingModule, NouisliderComponent],
  encapsulation: ViewEncapsulation.None,
})
export class IrrigationTimeSliderComponent
  implements OnInit, OnChanges, OnDestroy
{
  // Pre Irrigation
  @Input() preIrrigationTime: Date;
  @Input() fertilizationTime: Date;
  @Input() postIrrigationTime: Date;
  @Output() private preIrrigationTimeChanged = new EventEmitter<Date>();
  @Output() private fertilizationTimeChanged = new EventEmitter<Date>();
  @Output() private postIrrigationTimeChanged = new EventEmitter<Date>();
  @Output() private resetIrrigationValues = new EventEmitter<Date[]>();
  protected preIrrigationSeconds: number;
  protected fertilizationSeconds: number;
  protected postIrrigationSeconds: number;
  protected range: Array<number>;
  protected emptyRange: Array<number>;
  protected sliderConfig: object;
  protected emptySliderConfig: object;
  protected totalTime: number;
  protected hasInitialValues = false;
  protected loaded = false;
  private lowValue: number;
  private highValue: number;
  private isSliding = false;
  private isUpdating = false;
  private intervalSubscription: Subscription;
  private initialValues: Date[];

  constructor(public translationsLib: TranslationsLibService) {}

  public ngOnInit(): void {
    this.initialValues = [
      this.preIrrigationTime,
      this.fertilizationTime,
      this.postIrrigationTime,
    ];
    this.preIrrigationSeconds = DateToSecondsInput(this.preIrrigationTime);
    this.fertilizationSeconds = DateToSecondsInput(this.fertilizationTime);
    this.postIrrigationSeconds = DateToSecondsInput(this.postIrrigationTime);
    this.sliderConfig = {
      behaviour: 'snap',
      margin: 0,
    };
    this.emptySliderConfig = {
      margin: 0,
      disabled: true,
    };
    this.updateSlider();
    this.loaded = true;
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (!this.loaded || this.isSliding) {
      return;
    }
    if (changes.preIrrigationTime && this.preIrrigationTime) {
      this.preIrrigationSeconds = DateToSecondsInput(
        changes.preIrrigationTime.currentValue
      );
    }
    if (changes.fertilizationTime && this.fertilizationTime) {
      this.fertilizationSeconds = DateToSecondsInput(
        changes.fertilizationTime.currentValue
      );
    }
    if (changes.postIrrigationTime && this.postIrrigationTime) {
      this.postIrrigationSeconds = DateToSecondsInput(
        changes.postIrrigationTime.currentValue
      );
    }

    Promise.resolve().then(() => {
      this.updateSlider();
    });
  }

  public ngOnDestroy() {
    this.unsubscribeIntervalSubscription();
  }

  protected onSliderStart() {
    this.unsubscribeIntervalSubscription();
    this.isSliding = true;
  }

  protected onSliderSlide(range: Array<number>) {
    if (this.isUpdating) {
      return;
    }
    this.isUpdating = true;
    this.unsubscribeIntervalSubscription();
    this.intervalSubscription = interval().subscribe(() => {
      this.processSliderChange(range);
      this.isUpdating = false;
    });
  }

  protected onSliderEnd(range: Array<number>) {
    this.unsubscribeIntervalSubscription();
    this.processSliderChange(range);
    this.isUpdating = false;
    this.isSliding = false;
  }

  private unsubscribeIntervalSubscription() {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }

  protected formatSeconds(seconds: number): string {
    return moment().startOf('day').add(seconds, 'seconds').format('HH:mm:ss');
  }

  protected resetValues() {
    this.preIrrigationSeconds = DateToSecondsInput(this.initialValues[0]);
    this.fertilizationSeconds = DateToSecondsInput(this.initialValues[1]);
    this.postIrrigationSeconds = DateToSecondsInput(this.initialValues[2]);
    this.resetIrrigationValues.emit(this.initialValues);
    this.hasInitialValues = false;
  }

  private updateSlider() {
    this.lowValue = this.preIrrigationSeconds;
    this.highValue = this.preIrrigationSeconds + this.fertilizationSeconds;
    this.totalTime =
      this.preIrrigationSeconds +
      this.fertilizationSeconds +
      this.postIrrigationSeconds;
    this.range = [this.lowValue, this.highValue];
    this.emptyRange = [this.totalTime];
  }

  private processSliderChange(range: Array<number>) {
    const lowValue = range[0];
    const highValue = range[1];

    let hasChanged = false;

    const newPreIrrigationSeconds = lowValue;
    const newFertilizationSeconds = highValue - lowValue;
    const newPostIrrigationSeconds = this.totalTime - highValue;

    if (newPreIrrigationSeconds !== this.preIrrigationSeconds) {
      this.preIrrigationTimeChanged.emit(
        secondsInputToDate(newPreIrrigationSeconds)
      );
      this.preIrrigationSeconds = newPreIrrigationSeconds;
      hasChanged = true;
    }

    if (newFertilizationSeconds !== this.fertilizationSeconds) {
      this.fertilizationTimeChanged.emit(
        secondsInputToDate(newFertilizationSeconds)
      );
      this.fertilizationSeconds = newFertilizationSeconds;
      hasChanged = true;
    }

    if (newPostIrrigationSeconds !== this.postIrrigationSeconds) {
      this.postIrrigationTimeChanged.emit(
        secondsInputToDate(newPostIrrigationSeconds)
      );
      this.postIrrigationSeconds = newPostIrrigationSeconds;
      hasChanged = true;
    }
    this.hasInitialValues =
      hasChanged ||
      !this.compareWithInitialValues(
        newPreIrrigationSeconds,
        newFertilizationSeconds,
        newPostIrrigationSeconds
      );
  }

  private compareWithInitialValues(
    preIrrigation: number,
    fertilization: number,
    postIrrigation: number
  ): boolean {
    return (
      preIrrigation === DateToSecondsInput(this.initialValues[0]) &&
      fertilization === DateToSecondsInput(this.initialValues[1]) &&
      postIrrigation === DateToSecondsInput(this.initialValues[2])
    );
  }
}
