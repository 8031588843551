<div
  *ngIf="sessionLib.getUserType() === this.nonVerifiedInstaller"
  class="alert alert-warning mb-0 mt-4"
>
  <div class="row">
    <span class="col d-flex align-items-center">
      {{ translationsLib.get('non_verified_installer_alert_message') }}
    </span>
    <button
      (click)="refresh()"
      class="btn btn-primary col-12 col-sm-3 d-flex align-items-center justify-content-center gap-2"
    >
      <ion-icon
        [name]="'RELOAD-OUTLINE' | sensorIcon : '' : '' : true"
      ></ion-icon>
      {{ translationsLib.get('non_verified_installer_alert_button') }}
    </button>
  </div>
</div>
