import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  App360SharedModule,
  TranslationsLibService,
} from '@nutricontrol/app360-shared';
import { environment } from '../../../../environments/environment';
import {
  ClimateEquipmentModel,
  DesktopClimateStatesStructureGroups,
  DesktopClimateStatesStructureMeteo,
  DesktopClimateStatesStructureModel,
  GroupsOrder,
  NewDesktopClimateMeteo,
  NewDesktopClimateModel,
} from '../../../farming/climate/climate.model';
import { Breadcrumb } from '../../../farming/irrigation/shared/shared.model';
import { IonContent } from '@ionic/angular';
import { ClimateService } from '../../../farming/climate/climate.service';
import { ActivatedRoute } from '@angular/router';
import { HelpersLibService } from '../../../services/libraries/helpers-lib.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ClimateInstallerService } from '../../../farming/libraries/climate-installer.service';
import { ScrollDetail } from '@ionic/core';
import * as moment from 'moment/moment';
import { saveDataAndShowModal } from '../../../commons/helpers/functions';
import { AppCommonsModule } from '../../../commons/app-commons.module';
import { ClimateMeteoTableComponent } from '../../../farming/climate/shared/climate-meteo-table/climate-meteo-table.component';

@Component({
  standalone: true,
  selector: 'app-fields-dashboard-climate',
  templateUrl: './fields-dashboard-climate.component.html',
  styleUrls: ['./fields-dashboard-climate.component.scss'],
  imports: [AppCommonsModule, ClimateMeteoTableComponent, App360SharedModule],
})
export class FieldsDashboardClimateComponent implements OnInit {
  @Input() equipment: ClimateEquipmentModel;
  img;
  zonePaginationData: any[] = [];
  currentPage = 0;

  private intervalId: any;
  isMobile = false;

  groupVisibility: { name: string; visible: boolean }[];
  zoneVisibility: { [key: number]: boolean } = {};
  meteoVisibility: { [key: string]: any } = {};
  propVisibility: { [key: number]: DesktopClimateStatesStructureGroups } = {};
  isLoading = false;
  searchByGroupKeyRadioValue = 'group';

  terminalValue: string;
  terminal_vid: string;
  isFetchingEquipmentData = false;
  isFetchingStatesStructure = false;
  isFetchingStates = false;
  statesResponse: NewDesktopClimateModel;
  meteoResponse: NewDesktopClimateMeteo;
  meteoArray: any[];
  meteoStatesStructureArray: any[];
  statesStructureResponse: DesktopClimateStatesStructureModel;
  meteoStructureResponse: DesktopClimateStatesStructureMeteo;
  searchZoneText = '';
  searchMeteoText = '';
  equipmentResponse: ClimateEquipmentModel;
  breadcrumbs: Breadcrumb[] = [];
  isVisibilitySubmitted = false;
  lastUpdateDateTime: Date;
  formattedLastUpdate = this.translationsLib.get('climate_loading');
  connectedTerminalStatus: boolean;
  @ViewChild(IonContent) content: IonContent;
  @ViewChild('tableElement') tableElement: ElementRef;
  scrollPosition = 0;

  private ignoreScrollEvent = false;
  timeOutId: number;

  constructor(
    private climateService: ClimateService,
    private activatedRoute: ActivatedRoute,
    private helpersLib: HelpersLibService,
    public translationsLib: TranslationsLibService,
    private breakpointObserver: BreakpointObserver,
    private checkCurrentTabService: ClimateInstallerService
  ) {}

  handleScroll(ev: CustomEvent<ScrollDetail>) {
    if (!this.ignoreScrollEvent) {
      this.setLocalStorageScrollPosition(ev.detail.scrollTop.toString());
    } else {
      this.ignoreScrollEvent = false;
    }
  }

  ngOnInit() {
    this.img = environment.backend + '/' + this.equipment.img;
    this.terminal_vid = this.equipment.serialNumber;

    this.activatedRoute.params.subscribe(async (_) => {
      this.checkCurrentTabService.checkLocalStorageForCurrentTabIndex();
      this.checkCurrentTabService.removeInstallerOptionSelected();

      this.getEquipmentData();
      this.getTerminalData();

      await this.fetchDataAndUpdateTable();
      this.lastUpdateDateTime = new Date();
      this.formatLastUpdate();

      this.propagatePropFilterVisibility();

      this.intervalId = setInterval(() => {
        this.fetchDataAndUpdateTable();
        this.lastUpdateDateTime = new Date();
        this.formatLastUpdate();
      }, environment.intervalDefaultTimeout);
    });
  }

  async fetchDataAndUpdateTable() {
    try {
      this.isLoading = true;

      const savedScrollPosition = this.getLocalStorageScrollPosition();
      const scrollPosition =
        savedScrollPosition !== null ? parseInt(savedScrollPosition, 10) : 0;

      await this.getStates();
      await this.getClimateStatesStructure();

      this.isLoading = false;
      this.ignoreScrollEvent = true;

      if (this.content !== undefined)
        await this.content.scrollToPoint(0, scrollPosition);
    } catch (err) {
      this.isLoading = false;
      throw new Error(err.message);
    }
  }

  formatLastUpdate() {
    this.formattedLastUpdate = moment(this.lastUpdateDateTime).format(
      'DD/MM/YYYY - HH:mm:ss'
    );
  }

  async forceUpdate() {
    await this.fetchDataAndUpdateTable();
    this.lastUpdateDateTime = new Date();
    this.formatLastUpdate();
  }

  getEquipmentData() {
    this.isFetchingEquipmentData = true;

    this.climateService
      .getClimateEquipment(this.terminal_vid)
      .subscribe((res) => {
        this.equipmentResponse = res;
        this.terminalValue = res.name;
        this.connectedTerminalStatus = res.connected;

        // Subscribe to route event to change title between route navigations
        this.activatedRoute.paramMap.subscribe((_) => {
          this.helpersLib.setTitle('', res.name, null);
        });

        this.isFetchingEquipmentData = false;
      });
  }

  getTerminalData() {
    this.breadcrumbs = [];
    this.climateService.getTerminal(this.terminal_vid).subscribe((res) => {
      if (res.field) {
        this.breadcrumbs.push({
          text: res.field.name,
          disabled: false,
          to: ['/field', { vid: res.field.vid }],
          active: false,
        });
      }

      this.breadcrumbs.push(
        {
          text: res.name,
          disabled: false,
          to: `/farming/${this.terminal_vid}/climate`,
          active: false,
        },
        {
          text: this.translationsLib.get('climate_states'),
          disabled: false,
          active: true,
        }
      );
    });
  }

  getStates(): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      try {
        this.isFetchingStates = true;
        this.climateService
          .getNewDesktopClimateStates(this.terminal_vid)
          .subscribe((res) => {
            this.statesResponse = res;
            this.meteoResponse = res.meteo;
            this.zonePaginationData = res.zones;

            this.meteoArray = Object.keys(this.meteoResponse.fields).map(
              (key) => this.meteoResponse.fields[key]
            );

            this.isFetchingStates = false;
            resolve(true);
          });
      } catch (err) {
        throw new Error(err.message);
      }
    });
  }

  getClimateStatesStructure(): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      try {
        this.isFetchingStatesStructure = true;
        this.climateService
          .getNewDesktopClimateStructureStates(this.terminal_vid)
          .subscribe((res) => {
            this.statesStructureResponse = res;
            this.meteoStructureResponse = res.meteo;

            this.statesStructureResponse.zones.forEach((zoneInfo) => {
              this.zoneVisibility[zoneInfo.zone] = zoneInfo.visible;
              this.propVisibility[0] = zoneInfo.groups;
            });

            this.meteoStatesStructureArray = Object.keys(
              this.statesStructureResponse.meteo.fields
            ).map((key) => this.statesStructureResponse.meteo.fields[key]);

            this.meteoVisibility = this.meteoStatesStructureArray;

            this.groupVisibility = Object.keys(
              this.statesStructureResponse.zones[0].groups
            ).map((key) => ({
              name: key,
              visible: true,
            }));

            this.isFetchingStatesStructure = false;
            resolve(true);
          });
      } catch (err) {
        throw new Error(err.message);
      }
    });
  }

  isGroupVisible(name: string): boolean {
    const foundGroup = this.groupVisibility.find((elem) => elem.name === name);
    return foundGroup ? foundGroup.visible : false;
  }

  getGroupVisibility(groupItem) {
    return this.groupVisibility.find((group) => group.name === groupItem.group)
      ?.visible;
  }

  propagatePropFilterVisibility() {
    const propVisibilityList: { key: string; isVisible: boolean }[] = [];

    this.getZoneKey().forEach((keysArr) =>
      keysArr.keys.forEach((key) =>
        propVisibilityList.push({ key, isVisible: true })
      )
    );

    return propVisibilityList;
  }

  toggleGroupVisibility(data) {
    const foundGroup = this.groupVisibility.find(
      (group) => group.name === data.group
    );
    if (foundGroup) {
      foundGroup.visible = !foundGroup.visible;
    }
  }

  trackByFn(index: number, item: any) {
    return item.key;
  }

  getZoneKey(): { group: string; keys: string[] }[] {
    const keysByGroup: { [key: string]: string[] } = {};

    for (const zone of this.statesResponse.zones) {
      for (const groupKey in zone.groups) {
        if (zone.groups.hasOwnProperty(groupKey)) {
          const group = zone.groups[groupKey];
          const zoneKey = Object.keys(group.fields);
          if (!keysByGroup[groupKey]) {
            keysByGroup[groupKey] = zoneKey;
          } else {
            keysByGroup[groupKey].push(...zoneKey);
          }
        }
      }
    }

    const res = Object.keys(keysByGroup).map((groupKey) => ({
      group: groupKey,
      keys: Array.from(new Set(keysByGroup[groupKey])),
    }));

    return res.sort((a, b) => {
      return GroupsOrder.findIndex((group) => a.group === group) >
        GroupsOrder.findIndex((group) => b.group === group)
        ? 1
        : -1;
    });
  }

  getZoneValue(value: any, key: string): string | boolean {
    if (value) {
      for (const groupKey in value) {
        if (value.hasOwnProperty(groupKey)) {
          const fields = value[groupKey].fields;
          for (const fieldKey in fields) {
            if (fields.hasOwnProperty(fieldKey) && fieldKey === key) {
              const fieldValue = fields[fieldKey].value;
              const fieldUnit = fields[fieldKey].unit;

              const isFalse = fieldValue === 'false';
              const isNull = fieldValue === null;
              const isNumberOrNonEmptyString =
                typeof fieldValue === 'number' ||
                (typeof fieldValue === 'string' && fieldValue.length > 0);

              if (isFalse || isNull) {
                return '-';
              } else if (isNumberOrNonEmptyString) {
                return `${fieldValue} ${fieldUnit}`;
              }
            }
          }
        }
      }
    }
    return '-';
  }

  getKeyClass(item) {
    switch (item.toUpperCase()) {
      case 'VENT':
        return 'vent';
      case 'MISTING':
        return 'misting';
      case 'HEAT':
        return 'heat';
      case 'CURTAIN':
        return 'curtain';
      case 'HAFFAN':
        return 'haf_fans';
      case 'CO2':
        return 'co2';
      case 'SWITCHING':
        return 'switching_programs';
      case 'ALARM':
        return 'alarm';
      case 'COMMON':
        return 'common';
      default:
        return '';
    }
  }

  getValueClass(item) {
    switch (item.toUpperCase()) {
      case 'VENT':
        return 'vent-value';
      case 'MISTING':
        return 'misting-value';
      case 'HEAT':
        return 'heat-value';
      case 'CURTAIN':
        return 'curtain-value';
      case 'HAF_FANS':
        return 'haf_fans-value';
      case 'CO2':
        return 'co2-value';
      case 'SWITCHING_PROGRAMS':
        return 'switching_programs-value';
      case 'ALARM':
        return 'alarm-value';
      case 'COMMON':
        return 'common-value';
      default:
        return '';
    }
  }

  onPropChecked(event: { group: string; prop: string; value: boolean }): void {
    this.propVisibility[0][event.group].fields[event.prop].visible =
      event.value;
  }

  onZoneChecked(event: { zone: number; value: boolean }): void {
    this.zoneVisibility[event.zone] = event.value;
  }

  onMeteoChecked(event: { key: string; value: boolean }): void {
    this.meteoVisibility.forEach((meteo) => {
      if (meteo.key === event.key) meteo.visible = event.value;
    });
  }

  getVisibleMeteoKeys() {
    if (!this.meteoVisibility) {
      return [];
    }

    return this.meteoVisibility.filter((meteo) => meteo.visible === true);
  }

  getVisibleZones(): number[] {
    const visibleZones = Object.keys(this.zoneVisibility)
      .filter((zone) => this.zoneVisibility[zone])
      .map(Number);

    return visibleZones.length > 0
      ? visibleZones
      : this.statesResponse?.zones.map((zone) => zone.zone);
  }

  isVisibleZoneInMobile(zone: number): boolean {
    return this.getVisibleZones().includes(zone);
  }

  getVisibleProps(group: string): string[] {
    if (!this.propVisibility[0] || !this.propVisibility[0][group]) {
      return [];
    }

    const groupProps = this.propVisibility[0][group].fields;
    return Object.keys(groupProps)
      .filter((prop) => groupProps[prop].visible)
      .sort((a, b) => (a > b ? 1 : -1));
  }

  submitVisibility(event, showModal = true): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      this.isVisibilitySubmitted = true;

      try {
        this.climateService
          .postNewDesktopClimateStructureStates(this.terminal_vid, event)
          .subscribe((res) => {
            saveDataAndShowModal(
              res,
              showModal,
              this.translationsLib.get('error_updating_data'),
              this.translationsLib.get('save_changes_success'),
              this.translationsLib.get('accept')
            );

            this.isVisibilitySubmitted = false;
            resolve(true);
          });
      } catch (err) {
        throw new Error(err.message);
      }
    });
  }

  clearSearchZoneText() {
    this.searchZoneText = '';
  }

  clearSearchMeteoText() {
    this.searchMeteoText = '';
  }

  getLocalStorageScrollPosition(): string {
    return localStorage.getItem('scrollPosition');
  }

  setLocalStorageScrollPosition(tabIndex: string) {
    localStorage.setItem('scrollPosition', tabIndex);
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }

    if (this.timeOutId) {
      clearTimeout(this.timeOutId);
      this.timeOutId = null;
    }
  }
}
