import {
  AfterViewInit,
  Component,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Breadcrumb } from '../../../shared/shared.model';
import { ActivatedRoute, Router } from '@angular/router';
import { IrrigationService } from '../../../irrigation.service';
import { HelpersLibService } from '../../../../../services/libraries/helpers-lib.service';
import { SafeData } from '../../../irrigation.model';
import {
  TranslationsLibService,
  TerminalDataModel,
} from '@nutricontrol/app360-shared';
import { IonContent } from '@ionic/angular';

@Component({
  selector: 'app-meteo-alarms-edit',
  templateUrl: './meteo-alarms-edit.component.html',
  styleUrls: ['./meteo-alarms-edit.component.scss'],
})
export class MeteoAlarmsEditComponent
  implements OnInit, AfterViewInit, SafeData
{
  mode = '';
  breadcrumbs: Breadcrumb[] = [];
  terminalData: TerminalDataModel;
  isFormFieldWithErrors = false;
  isFetchingEquipmentData = false;
  meteo_alarm_id: number;
  meteoAlarmValue: string;
  isMeteoAlarmsWithoutData = false;

  terminal_vid: string;
  isDirty = false;
  @ViewChild(IonContent, { static: false }) private content: IonContent;
  hasScrollbar = false;
  saveButtonTimeElapsed = false;
  constructor(
    private router: Router,
    private irrigationService: IrrigationService,
    private helpersLib: HelpersLibService,
    private activatedRoute: ActivatedRoute,
    public translationsLib: TranslationsLibService
  ) {}

  /**
   * https://stackoverflow.com/questions/56882873/how-to-detect-if-ion-content-has-a-scrollbar/58579938#58579938
   * Apply CSS class based on vertical scroll (save button)
   */

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkForScrollbar();
  }

  async checkForScrollbar() {
    const scrollElement = await this.content.getScrollElement();
    this.hasScrollbar = scrollElement.scrollHeight > scrollElement.clientHeight;
  }

  isDataSaved(): boolean {
    return this.isDirty;
  }

  hasWritePermissions(): boolean {
    return this.terminalData.authed_user_can_write;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.saveButtonTimeElapsed = true;
      this.checkForScrollbar();
    }, 3000);

    this.activatedRoute.paramMap.subscribe((_) => {
      this.helpersLib.setTitle(
        '',
        this.translationsLib.get('irrigation_alarms'),
        null
      );
    });
  }

  ngOnInit() {
    const [, , vid, , , data] = this.router.url.split('/');
    this.terminal_vid = vid;
    this.meteo_alarm_id = Number(data);

    this.generateBreadcrumbs();
  }

  generateBreadcrumbs() {
    this.isFetchingEquipmentData = true;
    this.breadcrumbs = [];
    this.irrigationService.getTerminal(this.terminal_vid).subscribe((res) => {
      this.terminalData = res;
      if (res.field) {
        this.breadcrumbs.push({
          text: res.field.name,
          disabled: false,
          to: ['/field', { vid: res.field.vid }],
          active: false,
        });
      }
      this.breadcrumbs.push(
        {
          text: res.name,
          disabled: false,
          to: `/farming/${this.terminal_vid}/irrigation`,
          active: false,
        },
        {
          text: 'Configuración',
          disabled: false,
          to: `/farming/${this.terminal_vid}/irrigation/5`,
          active: false,
        },
        {
          text: this.meteoAlarmValue
            ? this.meteoAlarmValue
            : this.translationsLib.get('irrigation_alarms'),
          disabled: false,
          active: true,
        }
      );

      this.isFetchingEquipmentData = false;
    });
  }
}
