import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../environments/environment';
import { HelpersLibService } from './services/libraries/helpers-lib.service';
import { SessionLibService } from '@nutricontrol/app360-shared';
import { CheckConnectionLibService } from './services/libraries/check-connection-lib.service';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { AuthService } from './auth/auth.service';
import { PlatformLibService } from '@nutricontrol/app360-shared';
import { NotificationsLibService } from './services/libraries/notifications-lib.service';
import { SidebarLibService } from './services/libraries/sidebar-lib.service';
import { RouteLib } from './services/libraries/route-lib';
import { LegalAgreementsLibService } from './services/libraries/legal-agreements-lib.service';
import { TerminalsService } from './terminals/terminals.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  showCookiePolicyPopup = false;
  translationsLoaded = false;
  translationsEvent: any;
  navigationLifecycleIsRunningEvent: any;
  showSidebar = false;
  showQueryIsLoading = false;
  isNative = false;
  isMenuOpen = false;
  terminalsLoaded = false;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private helpers: HelpersLibService,
    private platformLib: PlatformLibService,
    public session: SessionLibService,
    public translationsLib: TranslationsLibService,
    private checkConnection: CheckConnectionLibService,
    public authService: AuthService,
    public legalAgreementsLib: LegalAgreementsLibService,
    public routeLib: RouteLib,
    public sidebarLib: SidebarLibService,
    private notificationsLib: NotificationsLibService,
    private terminalService: TerminalsService
  ) {
    translationsLib.initialize();
    this.translationsEvent = this.translationsLib
      .getTranslationsLoadedEmitter()
      .subscribe((translationsLoaded) =>
        this.changeTranslationsLoaded(translationsLoaded)
      );

    checkConnection.initialize();

    environment.language = this.helpers.getUsersLocale('en');

    this.showCookiePolicyPopup = session.getCookiesPolicy() !== 'true';
    if (this.platformLib.isNative()) {
      this.helpers.initializeApp();
      console.log('[NOTIFICATIONS]: Registering notifications');
      this.notificationsLib.registerNotifications().then(() => {
        console.log('[NOTIFICATIONS]: Adding listeners');
        this.notificationsLib.addListeners().then(() => {
          if (this.session.getSessionToken()) {
            console.log(
              '[NOTIFICATIONS] Registering token at UCROP',
              JSON.parse(sessionStorage.getItem('notifications_data'))
            );
            this.authService
              .postRegisterPushToken(
                JSON.parse(sessionStorage.getItem('notifications_data'))
              )
              .subscribe(
                () => {
                  // eslint-disable-next-line no-console
                  console.info(
                    '[NOTIFICATIONS]: Registration token done',
                    sessionStorage.getItem('notifications_data')
                  );
                },
                (error) => {
                  console.error('[NOTIFICATIONS]: Registration error', error);
                }
              );
          }
        });
      });
      this.isNative = true;
    }

    // Hacks depending on hashes
    this.routeLib.navigationStart();
    this.navigationLifecycleIsRunningEvent = this.routeLib
      .getNavigationLifecycleIsRunningEmitter()
      .subscribe((navigationLifecycleIsRunning) =>
        this.navigationLifecycleIsRunningEventChanger(
          navigationLifecycleIsRunning
        )
      );
  }
  acceptCookiesPolicy() {
    this.showCookiePolicyPopup = false;
    this.session.setCookiesPolicy('true');
  }
  changeTranslationsLoaded(translationsLoaded: boolean) {
    this.translationsLoaded = translationsLoaded;
  }
  navigationLifecycleIsRunningEventChanger(
    navigationLifecycleIsRunning: boolean
  ) {
    this.showQueryIsLoading = navigationLifecycleIsRunning;
  }

  menuOpened() {
    this.isMenuOpen = true;
  }

  menuClosed() {
    this.isMenuOpen = false;
  }

  ngOnInit() {
    const that = this;

    if (
      this.session.getSessionToken() &&
      !this.session.getUserHasNonDemoTerminals()
    ) {
      this.terminalService.getNonDemo().subscribe((res) => {
        this.session.setUserHasNonDemoTerminals(res.length > 0);

        this.terminalsLoaded = true;
      });
    } else {
      this.terminalsLoaded = true;
    }

    this.showSidebar = this.sidebarLib.showSidebar();
    setTimeout(() => {
      that.showSidebar = that.sidebarLib.showSidebar();
    }, 1000);
  }
}
