<div class="mt-4">
  <div class="row g-2 mb-2" *ngFor="let row of rows; let rowIndex = index">
    <ng-container *ngFor="let field of row; let isLast = last">
      <div
        class="col"
        [ngClass]="
          row.length === 1
            ? 'col-12'
            : !authedUserCanAdmin &&
              field.value.ws === 'meteo_alarms' &&
              row.length > 2
            ? 'col-12'
            : row.length === 2
            ? 'col-12 col-md-6'
            : rowIndex === rows.length - 1 && isLast && row.length % 2 !== 0
            ? 'col-12 col-md-12 col-lg-4'
            : row.length === 3
            ? 'col-12 col-md-6 col-lg-4'
            : row.length === 4
            ? 'col-12 col-md-6 col-lg-3'
            : ''
        "
      >
        <div
          class="data-container irrigation-card-border"
          *ngIf="
            (field.value.enabled && authedUserCanAdmin) ||
            (field.value.ws === 'meteo_alarms' && row.length > 2)
          "
        >
          <div class="row">
            <div class="col text-uppercase data-header">
              {{ field.value.name }}
            </div>
          </div>
          <div class="row custom-row">
            <div class="col text-end">
              <!-- Alarmas -->
              <a
                *ngIf="field.value.ws === 'meteo_alarms'"
                [routerLink]="[
                  '/farming/',
                  terminal_vid,
                  'irrigation',
                  'irrigation_installer',
                  field.value.ws
                ]"
              >
                <button class="btn btn-primary terminal-configuration-button">
                  {{ configurationButtonText }}
                </button>
              </a>

              <!-- Válvulas de mezcla -->
              <a
                *ngIf="field.value.ws === 'mixture_valves'"
                [routerLink]="[
                  '/farming/',
                  terminal_vid,
                  'irrigation',
                  'irrigation_installer',
                  field.value.ws,
                  0
                ]"
              >
                <button class="btn btn-primary terminal-configuration-button">
                  {{ configurationButtonText }}
                </button>
              </a>

              <!-- Válvulas -->
              <a
                *ngIf="field.value.ws === 'valves'"
                [routerLink]="[
                  '/farming/',
                  terminal_vid,
                  'irrigation',
                  'irrigation_installer',
                  field.value.ws,
                  0
                ]"
              >
                <button class="btn btn-primary terminal-configuration-button">
                  {{ configurationButtonText }}
                </button>
              </a>

              <!-- Abonos -->
              <a
                *ngIf="field.value.ws === 'fertilizers'"
                [routerLink]="[
                  '/farming/',
                  terminal_vid,
                  'irrigation',
                  'irrigation_installer',
                  field.value.ws
                ]"
              >
                <button class="btn btn-primary terminal-configuration-button">
                  {{ configurationButtonText }}
                </button>
              </a>

              <!-- Bombas -->
              <a
                *ngIf="field.value.ws === 'pumps'"
                [routerLink]="[
                  '/farming/',
                  terminal_vid,
                  'irrigation',
                  'irrigation_installer',
                  field.value.ws,
                  0
                ]"
              >
                <button class="btn btn-primary terminal-configuration-button">
                  {{ configurationButtonText }}
                </button>
              </a>

              <!-- Filtro -->
              <a
                *ngIf="field.value.ws === 'filters'"
                [routerLink]="[
                  '/farming/',
                  terminal_vid,
                  'irrigation',
                  'irrigation_installer',
                  field.value.ws,
                  0
                ]"
              >
                <button class="btn btn-primary terminal-configuration-button">
                  {{ configurationButtonText }}
                </button>
              </a>

              <!-- Demands -->
              <a
                *ngIf="field.value.ws === 'demands'"
                [routerLink]="[
                  '/farming/',
                  terminal_vid,
                  'irrigation',
                  'irrigation_installer',
                  field.value.ws
                ]"
              >
                <button class="btn btn-primary terminal-configuration-button">
                  {{ configurationButtonText }}
                </button>
              </a>

              <!-- Comunes de ph y ce -->
              <a
                *ngIf="field.value.ws === 'ph_ce_rearm_alarm'"
                [routerLink]="[
                  '/farming/',
                  terminal_vid,
                  'irrigation',
                  'irrigation_installer',
                  field.value.ws
                ]"
              >
                <button class="btn btn-primary terminal-configuration-button">
                  {{ configurationButtonText }}
                </button>
              </a>

              <!-- Bandejas de drenaje -->
              <a
                *ngIf="field.value.ws === 'drainage_trays'"
                [routerLink]="[
                  '/farming/',
                  terminal_vid,
                  'irrigation',
                  'irrigation_installer',
                  field.value.ws,
                  0
                ]"
              >
                <button class="btn btn-primary terminal-configuration-button">
                  {{ configurationButtonText }}
                </button>
              </a>

              <!-- Misceláneo -->
              <a
                *ngIf="field.value.ws === 'miscellaneous'"
                [routerLink]="[
                  '/farming/',
                  terminal_vid,
                  'irrigation',
                  'irrigation_installer',
                  field.value.ws
                ]"
              >
                <button class="btn btn-primary terminal-configuration-button">
                  {{ configurationButtonText }}
                </button>
              </a>

              <!-- Informe E/S -->
              <a
                *ngIf="field.value.ws === 'input_output'"
                [routerLink]="[
                  '/farming/',
                  terminal_vid,
                  'irrigation',
                  'irrigation_installer',
                  field.value.ws
                ]"
              >
                <button class="btn btn-primary terminal-configuration-button">
                  {{ configurationButtonText }}
                </button>
              </a>

              <!-- Sondas -->
              <a
                *ngIf="field.value.ws === 'sensors'"
                [routerLink]="[
                  '/farming/',
                  terminal_vid,
                  'irrigation',
                  'irrigation_installer',
                  field.value.ws
                ]"
              >
                <button class="btn btn-primary terminal-configuration-button">
                  {{ configurationButtonText }}
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
