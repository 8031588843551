<div class="card">
  <div class="card-header p-0">
    <div class="card-header-title">
      <div class="row">
        <div class="col-6">
          <h1 class="text-start">{{ translationsLib.get('permission_user') }}</h1>
        </div>
        <div class="col-6 align-self-center">
          <a class="btn btn-sm btn-primary lift float-end"
             *ngIf="canAdmin === true"
             (click)="addUser = true">
            {{ translationsLib.get('permission_addUser') }}
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body p-0">
    <!-- USERS TABLE -->
    <table class="table table-responsive-sm card-table" *ngIf="canShowUsers()">
      <thead>
      <tr>
        <th [class]="!isMobile ? 'col-4 px-0 py-3' : ''">
          {{ translationsLib.get('permission_user_s') }}
        </th>
        <ng-container *ngIf="!isMobile">
          <ng-container *ngTemplateOutlet="userActionsHeader"></ng-container>
        </ng-container>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let user of nonInjectedRegularUsers" [class]="isMobile ? 'border-bottom-5 border-secondary' : ''">
        <td [style]="!isMobile ? 'color: #444444; font-size: 15px' : ''"
            [class]="!isMobile ? 'p-0 text-start' : 'pb-0'">
          <div class="fw-bold">{{ user.name }} {{ user.surname }}</div>
          <div class="fw-normal text-muted text-break mb-3">{{ user.email }}</div>
          <table class="table table-responsive-sm card-table" *ngIf="isMobile">
            <thead>
            <tr>
              <ng-container *ngTemplateOutlet="userActionsHeader"></ng-container>
            </tr>
            </thead>
            <tbody>
            <tr>
              <ng-container *ngTemplateOutlet="userActions; context: { user: user }"></ng-container>
            </tr>
            </tbody>
          </table>
        </td>
        <ng-container *ngIf="!isMobile">
          <ng-container *ngTemplateOutlet="userActions; context: { user: user }"></ng-container>
        </ng-container>
      </tr>
      </tbody>
    </table>

    <!-- ADD USER -->
    <div class="mb-2" *ngIf="addUser === true">
      <form class="ion-justify-content-center">
        <label class="col-sm-5 col-form-label">
          {{ translationsLib.get('permission_invite_user') }}
        </label>
        <div>
          <input type="text"
                 matInput
                 class="form-control"
                 aria-label="Text"
                 [formControl]="emailForm"
                 [matAutocomplete]="auto"
                 required>
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions | async"
                        [value]="option"
                        [formControl]="emailForm"
                        ngDefaultControl>
              {{ option }}
            </mat-option>
          </mat-autocomplete>
        </div>
        <br>
        <button class="btn btn-primary float-end ms-3" (click)="attachUserTo()">
          {{ translationsLib.get('add') }}
        </button>
        <button class="btn btn-danger float-end" (click)="addUser = false">
          {{ translationsLib.get('cancel') }}
        </button>
      </form>
    </div>

    <!-- RELOADING USERS -->
    <lib-loader *ngIf="nonInjectedRegularUsersLoading === true"></lib-loader>

    <!-- NO USERS -->
    <p class="pt-5" style="text-align: center; font-style: oblique"
       *ngIf="nonInjectedRegularUsersLoading === false && nonInjectedRegularUsers.length === 0 && addUser === false">
        <span *ngIf="mode === 'field'">
          {{ translationsLib.get('permission_field_no_users') }}
        </span>
      <span *ngIf="mode === 'terminal'">
          {{ translationsLib.get('permission_terminal_no_users') }}
        </span>
    </p>
  </div>
</div>

<ng-template #userActionsHeader>
  <th class="col-2 text-start text-md-center px-0 py-3">
    <span>
      {{ translationsLib.get('permission_read') }}
      <mat-raised-button
        #tooltip="matTooltip" (click)="tooltip.toggle()"
        matTooltip="{{ translationsLib.get('permission_read_help') }}">
        <ion-icon name="help-circle-outline"></ion-icon>
      </mat-raised-button>
    </span>
  </th>
  <th class="col-2 text-start text-md-center px-0 py-3">
    {{ translationsLib.get('permission_write') }}
    <mat-raised-button
      #tooltip="matTooltip" (click)="tooltip.toggle()"
      matTooltip="{{ translationsLib.get('permission_write_help') }}">
      <ion-icon name="help-circle-outline"></ion-icon>
    </mat-raised-button>
  </th>
  <th class="col-2 text-start text-md-center px-0 py-3">
    {{ translationsLib.get('permission_admin') }}
    <mat-raised-button
      #tooltip="matTooltip" (click)="tooltip.toggle()"
      matTooltip="{{ translationsLib.get('permission_admin_help') }}">
      <ion-icon name="help-circle-outline"></ion-icon>
    </mat-raised-button>
  </th>
  <th class="col-2 text-center px-0 py-3">
    {{ translationsLib.get('permission_action') }}
  </th>
</ng-template>

<ng-template let-user="user" #userActions>
  <td class="text-center position-relative">
    <mat-radio-group
      *ngIf="hasPermission(user, READ_PERMISSION) || sessionUserEmail !== user.email">
      <mat-radio-button [checked]="hasPermission(user, READ_PERMISSION)"
                        [value]="READ_PERMISSION"
                        (click)="changeUserPermission(user, READ_PERMISSION)"
                        value="4">
      </mat-radio-button>
    </mat-radio-group>

    <span class="radio-button-off"
          *ngIf="!hasPermission(user, READ_PERMISSION) && sessionUserEmail === user.email">
      -
    </span>
  </td>
  <td class="text-center position-relative">
    <mat-radio-group
      *ngIf="hasPermission(user, WRITE_PERMISSION) || sessionUserEmail !== user.email">
      <mat-radio-button [checked]="hasPermission(user, WRITE_PERMISSION)"
                        [value]="WRITE_PERMISSION"
                        (click)="changeUserPermission(user, WRITE_PERMISSION)">
      </mat-radio-button>
    </mat-radio-group>

    <span class="radio-button-off"
          *ngIf="!hasPermission(user, WRITE_PERMISSION) && sessionUserEmail === user.email">-</span>
  </td>
  <td class="text-center position-relative">
    <mat-radio-group
      *ngIf="hasPermission(user, ADMIN_PERMISSION) || sessionUserEmail !== user.email">
      <mat-radio-button [checked]="hasPermission(user, ADMIN_PERMISSION)"
                        [value]="ADMIN_PERMISSION"
                        (click)="changeUserPermission(user, ADMIN_PERMISSION)">
      </mat-radio-button>
    </mat-radio-group>

    <span class="radio-button-off"
          *ngIf="!hasPermission(user, ADMIN_PERMISSION) && sessionUserEmail === user.email">-</span>
  </td>
  <td *ngIf="canAdmin===true" class="p-0 text-center">
    <a class="btn btn-sm btn-danger lift" (click)="detachUserFrom(user.email)">
      <ion-icon class="trash-icon" [ios]="'trash-outline'" [md]="'trash-outline'"></ion-icon>
    </a>
  </td>
</ng-template>
